import React from "react";
import Button from "@mui/material/Button";

import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import {
  Alert,
  AlertTitle,
  Badge,
  Card,
  CardHeader,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { readNotification } from "../../Redux/Ducks/NotificationDuck";
import "./bounce.css";

import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../Hoc/withAuth";

const NotificationsPanel = ({ user }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  // const Notifications = useSelector((state) => state.Notifications);
  // const { notifications } = Notifications;

  // useEffect(() => {
  //   dispatch(getNotifications());
  // }, [dispatch, location]);

  const notifications = useQuery(
    ["getNotifications"],
    () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/notifications`,
        config
      );
    },
    {
      refetchOnWindowFocus: true,
      refetchInterval: 10000,
    }
  );

  const clearNotification = (id) => {
    dispatch(readNotification(id));
    notifications.refetch();
  };

  const notificationsPlural =
    notifications.data?.data?.data?.length > 1
      ? "notifications"
      : "notification";

  return (
    <div>
      <Tooltip title="Notifications">
        <IconButton
          onClick={handleClick}
          size="large"
          aria-label="show 17 new notifications"
          color="inherit">
          <Badge
            // className={
            //   notifications && notifications.length ? "animated bounce" : ""
            // }
            badgeContent={
              notifications.data?.data?.data?.length &&
              notifications.data?.data?.data?.length
            }
            color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Card
          sx={{ bgcolor: "background.default", width: "100%", maxWidth: 380 }}>
          <CardHeader
            sx={{ bgcolor: "background.paper" }}
            titleTypographyProps={{ color: "primary" }}
            title="Notifications"
            subheader={`You have ${
              notifications.data?.data?.data &&
              notifications.data?.data?.data?.length
            } ${notificationsPlural}`}
          />

          {notifications.data?.data?.data &&
            notifications.data?.data?.data?.map((note, index) => {
              return (
                <div key={note.id}>
                  <Alert
                    sx={{ width: "100%", m: 0 }}
                    severity={note.type}
                    action={
                      <Button
                        onClick={() => clearNotification(note.id)}
                        color="inherit"
                        variant="outlined"
                        endIcon={<DeleteTwoToneIcon />}
                        size="large">
                        Clear
                      </Button>
                    }>
                    <AlertTitle>{note?.title}</AlertTitle>
                    <Stack spacing={2}>
                      <Typography variant="body2">{note.message}</Typography>
                      <Typography variant="body2">
                        {`${new Date(note?.createdAt).toLocaleDateString(
                          "en-ZA"
                        )} ${new Date(note?.createdAt).toLocaleTimeString(
                          "en-ZA"
                        )}  `}
                      </Typography>
                      {note.link && (
                        <Button
                          onClick={() => {
                            clearNotification(note.id);
                            navigate(`${note.link}`);
                          }}>
                          {note.link}
                        </Button>
                      )}
                    </Stack>
                  </Alert>
                </div>
              );
            })}

          <div>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                fullWidth
                onClick={() => {
                  navigate("/notifications");
                  handleClose();
                }}>
                View All
              </Button>
            </Box>
          </div>
        </Card>
      </Popover>
    </div>
  );
};

export default withAuth(NotificationsPanel);
