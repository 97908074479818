import {
  styled,
  Stack,
  Grid,
  Card,
  CardContent,
  // CardActions,
  // Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import withAuth from "../../../../../Hoc/withAuth";

import FileOpenIcon from "@mui/icons-material/FileOpen";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";

import CampaignSmsFilesPaginatedTable from "./Components/Sms/CampaignSmsFilesPaginatedTable";

import {
  getAdvoiceHealth,
  getAdvoiceWebhooks,
  uploadCampaignAdVoiceSMSFile,
  // getCampaignAdVoiceSMSFiles
} from "./State/CampaignAdVoiceSMSDuck";

import { getSmsProviders } from "./State/SmsProviderDuck";

const AdVoiceSMS = ({ user }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const { adVoiceSMSHealth, campaignAdVoiceSMSFiles } = useSelector(
    (state) => state.adVoice,
  );

  const { campaignLeadSources } = useSelector(
    (state) => state.campaignLeadSourceReducer,
  );

  const { smsProviders } = useSelector((state) => state.smsProvidersReducer);

  useEffect(() => {
    dispatch(getAdvoiceHealth());
    dispatch(getAdvoiceWebhooks());
    dispatch(getSmsProviders());
  }, [dispatch, id, campaignLeadSources]);

  const [document, setDocument] = useState("");

  const [leadSource, setLeadSources] = useState("");

  const [provider, setProvider] = useState("");

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleProvider = (event) => {
    setProvider(event.target.value);
  };

  const handleLeadSource = (event) => {
    setLeadSources(event.target.value);
  };

  const handleFileChange = (event) => {
    setDocument(event.target.files[0]);
  };

  // const Leads = useSelector((state) => state.Leads);

  // const { activeLeadSources, leadFiles, leadUploaded } = Leads;

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("file", document);
    formData.append("lead_source_id", leadSource);
    formData.append("provider_id", provider);
    formData.append("campaign_id", id);
    formData.append("updated_reason", "New Lead File Uploaded");

    dispatch(uploadCampaignAdVoiceSMSFile(id, formData));

    setDocument("");
    setProvider("");
  };

  return (
    <div>
      <Stack>
        <Card>
          <CardContent>
            {StatusColor(
              cdaAdvoiceApiStatus(adVoiceSMSHealth),
              "CDA Webhook Status",
            )}
          </CardContent>
        </Card>
      </Stack>
      {adVoiceSMSHealth.success ? (
        <Stack sx={{ m: 2 }}>
          <FormControl sx={{ padding: 1 }}>
            <InputLabel id="select-sms_provider">Select Provider</InputLabel>
            <Select
              labelId="select-sms_provider"
              id="select-sms_provider"
              value={provider}
              name={"provider"}
              label="Select Sms Provider"
              onChange={handleProvider}
            >
              {/* <MenuItem value={0} key={0}>None</MenuItem> */}
              {smsProviders.data
                ? smsProviders.data.map((provider) => (
                    <MenuItem value={provider.id} key={provider.id}>
                      {provider.name}
                    </MenuItem>
                  ))
                : ["Empty"].map((provider) => (
                    <MenuItem value={0} key={provider}>
                      {provider}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormControl sx={{ padding: 1 }}>
            <InputLabel id="select-lead_source">Select Lead Source</InputLabel>
            <Select
              labelId="select-lead_source"
              id="select-lead_source"
              value={leadSource}
              name={"leadSource"}
              label="Select Lead Source"
              onChange={handleLeadSource}
            >
              {campaignLeadSources.data
                ? campaignLeadSources.data.map((leadSource) => (
                    <MenuItem value={leadSource.id} key={leadSource.id}>
                      {leadSource.LeadSource.name}
                    </MenuItem>
                  ))
                : ["None"].map((leadSource) => (
                    <MenuItem value={0} key={leadSource}>
                      {leadSource}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          {document.name ? (
            <Button
              id="upload"
              variant="contained"
              startIcon={<FileOpenIcon />}
              onClick={handleUpload}
            >
              Save for Processing
            </Button>
          ) : (
            <Button
              id="upload"
              variant="contained"
              startIcon={<FileOpenIcon />}
              component="label"
            >
              Upload Advoice SMS File
              <Input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                id="advoice-sms-file"
                type="file"
                autoFocus
                onChange={handleFileChange}
                hidden
              />
            </Button>
          )}
          {campaignAdVoiceSMSFiles ? (
            <Grid sx={{ pt: 5 }}>
              <CampaignSmsFilesPaginatedTable
                columns={[
                  { id: 1, label: "Id" },
                  { id: 2, label: "File Name" },
                  { id: 3, label: "Status" },
                  { id: 4, label: "Created At" },
                  { id: 5, label: "Updated At" },
                ]}
                rows={campaignAdVoiceSMSFiles.data}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          ) : (
            <Alert severity="info">No Files Found</Alert>
          )}
        </Stack>
      ) : (
        <FormControl>
          <Button
            id="advoice-sms-file"
            variant="contained"
            startIcon={<NetworkCheckIcon />}
            onClick={() => dispatch(getAdvoiceHealth())}
            component="span"
          >
            Poll Webhook
          </Button>
        </FormControl>
      )}
    </div>
  );
};

export default withAuth(AdVoiceSMS);

const Input = styled("input")({
  display: "none",
});

const cdaAdvoiceApiStatus = (apiState) =>
  apiState.success
    ? { success: apiState.success, message: "Online" }
    : { success: apiState.success, message: "Offline" };

const StatusColor = (apiState, label) =>
  apiState.success ? (
    <span>
      {label.toUpperCase()}:
      <span style={{ color: "green" }}> {apiState.message}</span>
    </span>
  ) : (
    <span>
      {label.toUpperCase()}:
      <span style={{ color: "red" }}> {apiState.message}</span>
    </span>
  );
