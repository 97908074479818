import axios from "axios";

const initialState = {
  qaCampaigns: [],
  qaCampaign: { linkedQuestions: {} },
  campaignCreated: false,
  allAnswers: [],
  createdQuestion: {},
  loading: false,
  error: null,
};

const GET_QA_CAMPINGS = "GET_QA_CAMPINGS";
const GET_QA_CAMPINGS_SUCCESS = "GET_QA_CAMPINGS_SUCCESS";
const GET_QA_CAMPINGS_FAILURE = "GET_QA_CAMPINGS_FAILURE";

const GET_QA_CAMPINGS_BY_ID = "GET_QA_CAMPINGS_BY_ID";
const GET_QA_CAMPINGS_BY_ID_SUCCESS = "GET_QA_CAMPINGS_BY_ID_SUCCESS";
const GET_QA_CAMPINGS_BY_ID_FAILURE = "GET_QA_CAMPINGS_BY_ID_FAILURE";

const CREATE_QA_CAMPING = "CREATE_QA_CAMPING";
const CREATE_QA_CAMPING_SUCCESS = "CREATE_QA_CAMPING_SUCCESS";
const CREATE_QA_CAMPING_FAILURE = "CREATE_QA_CAMPING_FAILURE";

const UPDATE_QA_CAMPING = "UPDATE_QA_CAMPING";
const UPDATE_QA_CAMPING_SUCCESS = "UPDATE_QA_CAMPING_SUCCESS";
const UPDATE_QA_CAMPING_FAILURE = "UPDATE_QA_CAMPING_FAILURE";

const DELETE_QA_CAMPING = "DELETE_QA_CAMPING";
const DELETE_QA_CAMPING_SUCCESS = "DELETE_QA_CAMPING_SUCCESS";
const DELETE_QA_CAMPING_FAILURE = "DELETE_QA_CAMPING_FAILURE";

const CREATE_QA_QUESTION = "CREATE_QA_QUESTION";
const CREATE_QA_QUESTION_SUCCESS = "CREATE_QA_QUESTION_SUCCESS";
const CREATE_QA_QUESTION_FAILURE = "CREATE_QA_QUESTION_FAILURE";

const GET_QUESTION_BY_ID = "GET_QUESTION_BY_ID";
const GET_QUESTION_BY_ID_SUCCESS = "GET_QUESTION_BY_ID_SUCCESS";
const GET_QUESTION_BY_ID_FAILURE = "GET_QUESTION_BY_ID_FAILURE";

const EDIT_QUESTION = "EDIT_QA_QUESTION";
const EDIT_QUESTION_SUCCESS = "EDIT_QUESTION_SUCCESS";
const EDIT_QUESTION_FAILURE = "EDIT_QUESTION_FAILURE";

const GET_QA_CAMPINGS_QUESTIONS = "GET_QA_CAMPINGS_QUESTIONS";
const GET_QA_CAMPINGS_QUESTIONS_SUCCESS = "GET_QA_CAMPINGS_QUESTIONS_SUCCESS";
const GET_QA_CAMPINGS_QUESTIONS_FAILURE = "GET_QA_CAMPINGS_QUESTIONS_FAILURE";

const CREATE_ANSWER_SET = "CREATE_ANSWER_SET";
const CREATE_ANSWER_SET_SUCCESS = "CREATE_ANSWER_SET_SUCCESS";
const CREATE_ANSWER_SET_FAILURE = "CREATE_ANSWER_SET_FAILURE";

const GET_ALL_ANSWER_SETS = "GET_ALL_ANSWER_SETS";
const GET_ALL_ANSWER_SETS_SUCCESS = "GET_ALL_ANSWER_SETS_SUCCESS";
const GET_ALL_ANSWER_SETS_FAILURE = "GET_ALL_ANSWER_SETS_FAILURE";

const GET_ANSWER_SETS = "GET_ANSWER_SETS";
const GET_ANSWER_SETS_SUCCESS = "GET_ANSWER_SETS_SUCCESS";
const GET_ANSWER_SETS_FAILURE = "GET_ANSWER_SETS_FAILURE";

const EDIT_ANSWER_SET = "EDIT_ANSWER_SET";
const EDIT_ANSWER_SET_SUCCESS = "EDIT_ANSWER_SET_SUCCESS";
const EDIT_ANSWER_SET_FAILURE = "EDIT_ANSWER_SET_FAILURE";

const SET_ANSWERS_ORDER = "SET_ANSWERS_ORDER";
const SET_ANSWERS_ORDER_SUCCESS = "SET_ANSWERS_ORDER_SUCCESS";
const SET_ANSWERS_ORDER_FAILURE = "SET_ANSWERS_ORDER_FAILURE";

const GET_LINKED_QUESTIONS = "GET_LINKED_QUESTIONS";
const GET_LINKED_QUESTIONS_SUCCESS = "GET_LINKED_QUESTIONS_SUCCESS";
const GET_LINKED_QUESTIONS_FAILURE = "GET_LINKED_QUESTIONS_FAILURE";

const ADD_QUESTIONS_TO_CAMPAIGN = "ADD_QUESTIONS_TO_CAMPAIGN";
const ADD_QUESTIONS_TO_CAMPAIGN_SUCCESS = "ADD_QUESTIONS_TO_CAMPAIGN_SUCCESS";
const ADD_QUESTIONS_TO_CAMPAIGN_FAILURE = "ADD_QUESTIONS_TO_CAMPAIGN_FAILURE";

const REMOVED_QUESTIONS_FROM_CAMPAIGN = "REMOVED_QUESTIONS_FROM_CAMPAIGN";
const REMOVED_QUESTIONS_FROM_CAMPAIGN_SUCCESS =
  "REMOVED_QUESTIONS_FROM_CAMPAIGN_SUCCESS";
const REMOVED_QUESTIONS_FROM_CAMPAIGN_FAILURE =
  "REMOVED_QUESTIONS_FROM_CAMPAIGN_FAILURE";

const SET_QUESTIONS_ORDER = "SET_QUESTIONS_ORDER";
const SET_QUESTIONS_ORDER_SUCCESS = "SET_QUESTIONS_ORDER_SUCCESS";
const SET_QUESTIONS_ORDER_FAILURE = "SET_QUESTIONS_ORDER_FAILURE";

export const EDIT_ANSWER_RESET = "EDIT_ANSWER_RESET";

export const RESET_QA_CAMPINGS = "RESET_QA_CAMPINGS";

const qaCampaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LINKED_QUESTIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_LINKED_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        qaCampaign: {
          ...state.qaCampaign,
          linkedQuestions: action.payload,
        },
      };
    case GET_LINKED_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_QUESTIONS_TO_CAMPAIGN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_QUESTIONS_TO_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        qaCampaign: {
          ...state.qaCampaign,
          linkedQuestions: [
            ...action.payload,
            ...state.qaCampaign.linkedQuestions,
          ],
        },
        error: null,
      };
    case ADD_QUESTIONS_TO_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REMOVED_QUESTIONS_FROM_CAMPAIGN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVED_QUESTIONS_FROM_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        qaCampaign: {
          ...state.qaCampaign,
          linkedQuestions: action.payload,
        },
        removedLinkedQuestionsSuccess: true,
        error: null,
      };
    case REMOVED_QUESTIONS_FROM_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_QA_CAMPINGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_QA_CAMPINGS_SUCCESS:
      return {
        ...state,
        qaCampaigns: action.payload,
        loading: false,
        error: null,
      };
    case GET_QA_CAMPINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_QA_CAMPINGS_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_QA_CAMPINGS_BY_ID_SUCCESS:
      return {
        ...state,
        qaCampaign: action.payload,
        loading: false,
        error: null,
      };
    case GET_QA_CAMPINGS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_QA_CAMPING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_QA_CAMPING_SUCCESS:
      return {
        ...state,
        campaignCreated: true,
        createdCampaign: action.payload,
        loading: false,
        error: null,
      };
    case CREATE_QA_CAMPING_FAILURE:
      return {
        ...state,
        loading: false,
        campaignCreated: false,
        error: action.payload,
      };
    case UPDATE_QA_CAMPING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_QA_CAMPING_SUCCESS:
      return {
        ...state,
        qaCampaign: {
          ...state.qaCampaign,
          ...action.payload,
        },
        updatedCampaign: true,
        loading: false,
        error: null,
      };
    case UPDATE_QA_CAMPING_FAILURE:
      return {
        ...state,
        updatedCampaign: false,
        loading: false,
        error: action.payload,
      };
    case DELETE_QA_CAMPING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_QA_CAMPING_SUCCESS:
      return {
        ...state,
        qaCampaigns: state.qaCampaigns.filter(
          (qaCampaign) => qaCampaign.id !== action.payload
        ),
        loading: false,
        error: null,
      };
    case DELETE_QA_CAMPING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_QA_QUESTION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_QA_QUESTION_SUCCESS:
      return {
        ...state,
        qaCampaign: {
          ...state.qaCampaign,
          questions: [...state.qaCampaign.questions, action.payload],
        },
        createdQuestion: action.payload,
        loading: false,
        error: null,
      };
    case CREATE_QA_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_QA_CAMPINGS_QUESTIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_QA_CAMPINGS_QUESTIONS_SUCCESS:
      return {
        ...state,
        qaCampaign: {
          ...state.qaCampaign,
          questions: action.payload,
        },
        loading: false,
        error: null,
      };
    case GET_QA_CAMPINGS_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_QUESTION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        qaCampaign: {
          ...state.qaCampaign,
          questions: state.qaCampaign.questions.filter(
            (question) => question.id !== action.payload
          ),
        },
        deleteQuestion: true,
        loading: false,
        error: null,
      };
    case DELETE_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_ANSWER_SET:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_ANSWER_SET_SUCCESS:
      return {
        ...state,
        qaCampaign: {
          ...state.qaCampaign,
          answerSets: [...state.qaCampaign.answerSets, action.payload],
        },
        createAnswerSuccess: true,
        loading: false,
        error: null,
      };
    case CREATE_ANSWER_SET_FAILURE:
      return {
        ...state,

        loading: false,
        error: action.payload,
      };
    case GET_ANSWER_SETS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ANSWER_SETS_SUCCESS:
      return {
        ...state,
        qaCampaign: {
          ...state.qaCampaign,
          answerSets: action.payload,
        },
        loading: false,
        error: null,
      };
    case GET_ANSWER_SETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_ALL_ANSWER_SETS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_ANSWER_SETS_SUCCESS:
      return {
        ...state,
        allAnswers: action.payload,
        loading: false,
        error: null,
      };
    case GET_ALL_ANSWER_SETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EDIT_ANSWER_SET:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EDIT_ANSWER_SET_SUCCESS:
      return {
        ...state,
        allAnswers: state.allAnswers.map((answer) =>
          answer.id === action.payload.id ? action.payload : answer
        ),
        editAnswerSuccess: true,
        loading: false,
        error: null,
      };
    case EDIT_ANSWER_SET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EDIT_ANSWER_RESET:
      return {
        ...state,
        loading: false,
        editAnswerSuccess: false,
        createAnswerSuccess: false,
        error: null,
      };

    case GET_QUESTION_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_QUESTION_BY_ID_SUCCESS:
      return {
        ...state,
        question: action.payload,
        loading: false,
        error: null,
      };
    case GET_QUESTION_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EDIT_QUESTION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EDIT_QUESTION_SUCCESS:
      return {
        ...state,
        question: action.payload,
        loading: false,
        error: null,
      };
    case EDIT_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_QUESTIONS_ORDER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SET_QUESTIONS_ORDER_SUCCESS:
      return {
        ...state,
        orderSaved: true,
        loading: false,
        error: null,
      };
    case SET_QUESTIONS_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_ANSWERS_ORDER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SET_ANSWERS_ORDER_SUCCESS:
      return {
        ...state,
        orderSaved: true,
        loading: false,
        error: null,
      };
    case SET_ANSWERS_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_QA_CAMPINGS:
      return {
        ...state,
        qaCampaign: {},
        orderSaved: false,
        allAnswers: [],
        campaignCreated: false,
        updatedCampaign: false,
        createdQuestion: {},
        deleteQuestion: false,
        removedLinkedQuestionsSuccess: false,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default qaCampaignsReducer;

export const getQaCampaigns = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_QA_CAMPINGS });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns`,

      config
    );

    dispatch({
      type: GET_QA_CAMPINGS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_QA_CAMPINGS_FAILURE,
      payload: err.response.data,
    });
  }
};

export const QaCampaignById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_QA_CAMPINGS_BY_ID });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/${id}`,
      config
    );

    dispatch({
      type: GET_QA_CAMPINGS_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_QA_CAMPINGS_BY_ID_FAILURE,
      payload: err.response.data,
    });
  }
};

export const createQaCampaign = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_QA_CAMPING });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns`,
      formData,
      config
    );

    dispatch({
      type: CREATE_QA_CAMPING_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_QA_CAMPING_FAILURE,
      payload: err.response.data,
    });
  }
};

export const updateQaCampaign =
  (formData, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_QA_CAMPING });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/${id}`,
        formData,
        config
      );

      dispatch({
        type: UPDATE_QA_CAMPING_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_QA_CAMPING_FAILURE,
        payload: err.response.data,
      });
    }
  };

export const deleteQaCampaign = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_QA_CAMPING });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.delete(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/${id}`,
      config
    );

    dispatch({
      type: DELETE_QA_CAMPING_SUCCESS,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: DELETE_QA_CAMPING_FAILURE,
      payload: err.response.data,
    });
  }
};

export const createQaCampaignQuestion =
  (formData, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_QA_QUESTION });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/questions/${id}`,
        formData,
        config
      );

      dispatch({
        type: CREATE_QA_QUESTION_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_QA_QUESTION_FAILURE,
        payload: err.response.data,
      });
    }
  };

export const GetQaCampaignQuestions = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_QA_CAMPINGS_QUESTIONS });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/questions/${id}`,
      config
    );

    dispatch({
      type: GET_QA_CAMPINGS_QUESTIONS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_QA_CAMPINGS_QUESTIONS_FAILURE,
      payload: err.response.data,
    });
  }
};

export const createAnswerSet = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_ANSWER_SET });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/answers`,
      formData,
      config
    );

    dispatch({
      type: CREATE_ANSWER_SET_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_ANSWER_SET_FAILURE,
      payload: err.response,
    });
  }
};

export const GetQaCampaignAnswers = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ANSWER_SETS });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/answers/${id}`,
      config
    );

    dispatch({
      type: GET_ANSWER_SETS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ANSWER_SETS_FAILURE,
      payload: err.response.data,
    });
  }
};

export const editAnswerSet = (id, formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: EDIT_ANSWER_SET });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/answers/${id}`,
      formData,
      config
    );

    dispatch({
      type: EDIT_ANSWER_SET_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_ANSWER_SET_FAILURE,
      payload: err.response,
    });
  }
};

export const getAllAnswers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ALL_ANSWER_SETS });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/answers/all`,
      config
    );

    dispatch({
      type: GET_ALL_ANSWER_SETS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_ANSWER_SETS_FAILURE,
      payload: err.response,
    });
  }
};

export const getQuestionById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_QUESTION_BY_ID });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/question/${id}`,
      config
    );

    dispatch({
      type: GET_QUESTION_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_QUESTION_BY_ID_FAILURE,
      payload: err.response,
    });
  }
};

export const editQuestion = (id, formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: EDIT_QUESTION });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/questions/${id}`,
      formData,
      config
    );

    dispatch({
      type: EDIT_QUESTION_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_QUESTION_FAILURE,
      payload: err.response,
    });
  }
};

const DELETE_QUESTION = "DELETE_QUESTION";
const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
const DELETE_QUESTION_FAILURE = "DELETE_QUESTION_FAILURE";

export const deleteQuestion = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_QUESTION });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/questions/${id}`,
      config
    );

    dispatch({
      type: DELETE_QUESTION_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_QUESTION_FAILURE,
      payload: err.response,
    });
  }
};

export const AddQuestionsToCampaign =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_QUESTIONS_TO_CAMPAIGN });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/addQuestions`,
        formData,
        config
      );

      dispatch({
        type: ADD_QUESTIONS_TO_CAMPAIGN_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: ADD_QUESTIONS_TO_CAMPAIGN_FAILURE,
        payload: err.response,
      });
    }
  };

export const getLinkedQuestion = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_LINKED_QUESTIONS });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/linkedQuestions/${id}`,
      config
    );

    dispatch({
      type: GET_LINKED_QUESTIONS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_LINKED_QUESTIONS_FAILURE,
      payload: err.response,
    });
  }
};

export const removeLinkedQuestion =
  ({ campaign_id, question_id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: REMOVED_QUESTIONS_FROM_CAMPAIGN });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/removeQuestions`,
        { campaign_id, question_id },
        config
      );

      dispatch({
        type: REMOVED_QUESTIONS_FROM_CAMPAIGN_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: REMOVED_QUESTIONS_FROM_CAMPAIGN_FAILURE,
        payload: err.response,
      });
    }
  };

export const setQuestionsOrder = (indexes) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_QUESTIONS_ORDER });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/questionsOrder`,
      indexes,
      config
    );

    dispatch({
      type: SET_QUESTIONS_ORDER_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: SET_QUESTIONS_ORDER_FAILURE,
      payload: err.response,
    });
  }
};

export const setAnswersOrder = (indexes) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ANSWERS_ORDER });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/CDA/qa/campaigns/answersOrder`,
      indexes,
      config
    );

    dispatch({
      type: SET_ANSWERS_ORDER_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: SET_ANSWERS_ORDER_FAILURE,
      payload: err.response,
    });
  }
};
