import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DeleteUserfromNotifications = ({ open, onClose, onDelete, row }) => {
  const handleDelete = () => {
    if (row && row.email) {
      onDelete(row.email);
    }
    onClose();
  };

  return (
    <Stack>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={onClose}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Deactivate Team Member
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to deactivate this team member with email{" "}
            <br />
            <strong>{row?.email}</strong>
          </Typography>

          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button color="primary" sx={{ mr: 2 }} onClick={onClose}>
              cancel
            </Button>

            <Button color="error" sx={{ mr: 2 }} onClick={handleDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Stack>
  );
};

export default DeleteUserfromNotifications;