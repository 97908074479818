import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import withAuth from "../../../Hoc/withAuth";
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import { useSelector } from "react-redux";

const EditPositionModal = ({ user, position, text, modules }) => {
  const queryClient = useQueryClient()
  
  const [mutationError, setMutationError] = useState("");
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const mutation = useMutation({
    mutationFn: (values) => {
      if (values.id) {
        return axios.put(
          `${process.env.REACT_APP_API_URL}/onboarding/positions/${values.id}`,
          values,
          config
        );
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["getPositions"]);
    },
    onError: async (error) => {
      setMutationError(error.response.data.message);
    }
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitHandler = (values) => {
    mutation.mutate(values);
    setTimeout(() => {
      handleClose();
    }
    , 2000);
  };

  return (
    <Stack>
      <Stack spacing={0}>
        <Button color="info" variant="outlined" onClick={handleClickOpen}>
          {text}
        </Button>
      </Stack>

      
      
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="responsive-dialog-title"
        sx={{ borderRadius: 0 }}
      >
        {mutation.isLoading && (
        <AlertPopup open={true} severity={"info"} message={"Updating Position..."} />
      )}
      {mutation.isError && (
        
        <AlertPopup
          open={true}
          message={mutation.error}
          severity={"error"}
        />
      )}

      { mutation.isSuccess && (
        <AlertPopup open={true} message={"Position updated successfully!"} />
      )}

      { mutationError && (
            <AlertPopup open={true} message={mutationError} severity={"error"} />
          ) }
        <DialogTitle id="responsive-dialog-title">
              { modules.includes('oe-ceo') &&  position && "Edit Position" }
            { modules.includes('oe-hr') && 'View Position' }
            { modules.includes('oe-manager') && 'View Position'}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              id: position?.id || "",
              job_title: position?.job_title || "",
              rem_channel_grade: position?.rem_channel_grade || "",
              job_description_url: position?.job_description_url || "",
              job_advertisment: position?.job_advertisment || "",
              performance_contract: position?.performance_contract || "",
            }}
            validationSchema={yup.object().shape({
              job_title: yup.string().required("Job title required"),
              rem_channel_grade: yup
                .string()
                .required("Rem channel grade required"),
            })}

            onSubmit={(values) => {onSubmitHandler(values)}}
            enableReinitialize={true}
          >
            {(values, errors) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <TextfieldWrapper
                        name={"job_title"}
                        label="Job Title"
                        sx={{ mt: 2 }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextfieldWrapper
                        name={"rem_channel_grade"}
                        label="Rem Grade"
                      />
                    </Grid>
                    {position.job_description_url && (
                      <>
                        <Grid item xs={6} md={6}>
                          <Button fullWidth sx={{ border: "none" }} disabled>
                            Job Description File
                          </Button>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Button
                            variant="outlined"
                            download
                            fullWidth
                            sx={{ height: "100%" }}
                          >
                            <Link
                              sx={{ textDecoration: "none" }}
                              href={`${process.env.REACT_APP_API_URL}/onboarding/downloadDocument/${position?.job_description_url}`}
                            >
                              <Typography variant="button">
                                Download File
                              </Typography>
                            </Link>
                          </Button>
                        </Grid>
                      </>
                    )}
                    {position.job_advertisment_filename && (
                      <>
                        <Grid item xs={6} md={6}>
                          <Button fullWidth sx={{ border: "none" }} disabled>
                            Job Advertisement File
                          </Button>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Button
                            variant="outlined"
                            download
                            fullWidth
                            sx={{ height: "100%" }}
                          >
                            <Link
                              sx={{ textDecoration: "none" }}
                              href={`${process.env.REACT_APP_API_URL}/onboarding/downloadDocument/${position?.job_advertisment_filename}`}
                            >
                              <Typography variant="button">
                                Download File
                              </Typography>
                            </Link>
                          </Button>
                        </Grid>
                      </>
                    )}
                    {position.performance_contract_filename && (
                      <>
                        <Grid item xs={6} md={6}>
                          <Button fullWidth sx={{ border: "none" }} disabled>
                            Performance COntract File
                          </Button>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Button
                            variant="outlined"
                            download
                            fullWidth
                            sx={{ height: "100%" }}
                          >
                            <Link
                              sx={{ textDecoration: "none" }}
                              href={`${process.env.REACT_APP_API_URL}/onboarding/downloadDocument/${position?.performance_contract_filename}`}
                            >
                              <Typography variant="button">
                                Download File
                              </Typography>
                            </Link>
                          </Button>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} md={12}>
                      <Box textAlign="end">
                        <Button
                          autoFocus
                          onClick={handleClose}
                          variant="contained"
                        >
                          Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        {modules.includes("oe-ceo") && (
                          <Button
                            type="submit"
                            color="secondary"
                            autoFocus
                            variant="contained"
                          >
                            Save
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default withAuth(EditPositionModal);
