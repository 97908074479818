import React, { useEffect } from "react";

import { useField, useFormikContext } from "formik";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

const SelectLanguagesComp = ({ name, ...otherProps }) => {
  const [field, mata] = useField(name);
  const { setFieldValue } = useFormikContext();

  const [languagesSelect, setLanguages] = React.useState(field.value);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  //Sets Local State to the value of the field
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    setLanguages(typeof value === "string" ? value.split(",") : value);
  };

  // Sets the value of the field to Formik context

  useEffect(() => {
    setFieldValue(
      "languages",

      languagesSelect
    );
  }, [languagesSelect, setFieldValue]);

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="select_languages_l_id">Select Languages</InputLabel>
      <Select
        labelId="select_languages_l_id"
        id="select_languages"
        multiple
        value={languagesSelect}
        name="languages"
        label="Select Languages"
        onChange={handleChange}
        input={<OutlinedInput label="Select Languages" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}>
        {languages.map((name) => {
          return (
            <MenuItem key={name} value={name}>
              <Checkbox checked={languagesSelect.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectLanguagesComp;

const languages = [
  "English",
  "Afrikaans",
  "isiZulu",
  "SeSotho",
  "Tswana",
  "Tspedi",
  "TshiVenda",
  "isNdebele",
  "siSwati",
  "isiXhosa",
  "XiTsonga",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
