import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";
import { DialogContent, Stack } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createDisposition } from "../../State/DispostionsDuck";
import SelectWrapper from "../../../../../../../Components/FormComponents/Select";

export default function AddDispositionsDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button fullWidth variant="contained" onClick={handleClickOpen}>
        Add New Disposition
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle> Add New Disposition</DialogTitle>

        <Formik
          initialValues={{
            name: "",
            type: "",
            active: true,
            updated_reason: "Created New Disposition",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            type: Yup.string().required("Type is required"),
            active: Yup.boolean().required("Active is required"),
          })}
          onSubmit={(values) => {
            dispatch(createDisposition(values));
            setTimeout(() => {
              handleClose();
            }, 1000);
          }}>
          {() => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <TextfieldWrapper label="Disposition" name="name" />
                  <SelectWrapper
                    label="Disposition Type"
                    name="type"
                    options={[
                      { value: "contact", label: "Contact" },
                      { value: "not contacted", label: "Not contacted" },
                      { value: "not contactable", label: "Not contactable" },
                    ]}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Save</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
