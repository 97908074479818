import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import SliderWrapper from "../../../../../../../Components/FormComponents/SliderWrapper";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";
import { Form } from "formik";
import { Stack } from "@mui/system";
const AddAnswerToQaQueueQuestion = ({
  id,
  queue_id,
  addAnswer,
  data_capture,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="secondary" onClick={handleClickOpen}>
        Add New Answer
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Create New Question Answer</DialogTitle>
        <Formik
          initialValues={{
            question_id: id,
            answer_type: "standard",
            answer: "",
            value: 0,
            data_capture: false,
            active: true,
            updated_reason: "Created New Answer",
          }}
          validationSchema={Yup.object({
            answer_type: Yup.string().required("Required"),
            answer: Yup.string().required("Required"),
          })}
          onSubmit={(values) => {
            values.value = values?.value / 10;
            addAnswer.mutate(values);
            handleClose();
          }}>
          {({ values, setFieldValue }) => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <TextfieldWrapper
                    name="answer"
                    label="answer"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  />

                  <Stack
                    sx={{
                      border: 1,
                      borderColor: "divider",
                      p: 2,
                    }}>
                    <SliderWrapper
                      inputs={{
                        min: 0,
                        max: 10,
                      }}
                      name="value"
                      label="Value"
                      min={0}
                      max={1}
                    />

                    {values.value >= 9 && (
                      <Typography variant="body1" color="secondary">
                        Correct Answer
                      </Typography>
                    )}

                    {values.value >= 6 && values.value <= 8 && (
                      <Typography variant="body1" color="warning.main">
                        Partially Correct Answer (5-7)
                      </Typography>
                    )}

                    {values.value < 6 && (
                      <Typography variant="body1" color="error">
                        Incorrect Answer
                      </Typography>
                    )}

                    <Typography variant="body1">
                      if the value is 8 or above the answer is correct, if the
                      value is 5-7 the answer is partially correct (will
                      decrease the overall score), if the value is below 6 this
                      will fail the question
                    </Typography>
                  </Stack>

                  {data_capture && (
                    <Stack
                      sx={{
                        border: 1,
                        borderColor: "divider",
                        p: 2,
                      }}>
                      <Typography variant="body1">
                        this will mark the answer as an error if the data
                        capture is incorrect.
                      </Typography>

                      <FormControlLabel
                        control={
                          <Switch
                            name="data_capture"
                            label="Data Capture"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            color="error"
                            checked={values.data_capture}
                            onChange={(e) => {
                              setFieldValue("data_capture", e.target.checked);
                            }}
                          />
                        }
                        label="Data Capture Answer Error"
                      />
                    </Stack>
                  )}
                </Stack>
              </DialogContent>
              <Button variant="contained" fullWidth type="submit">
                Save
              </Button>
              <Button fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default AddAnswerToQaQueueQuestion;
