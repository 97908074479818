import React from "react";
import { Outlet } from "react-router-dom";
import PageHeader from "../../../../../Components/Bits/PageHeader";

const CoachingHome = () => {
  return (
    <>
      <PageHeader
        title="Coaching Home"
        subheader="For All Your Call Center Coaching Needs"
        breadcrumbs={[
          {
            title: "Home",
            href: "/",
          },
          {
            title: "Call Center/CoachingHome",
            href: "/CDA/CallCenter/Coaching/CoachingHome",
          },
        ]}
      />

      <Outlet />
    </>
  );
};

export default CoachingHome;
