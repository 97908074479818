import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { Button, Stack } from "@mui/material"; // Add CircularProgress back

import withAuth from "../../../../../../../Hoc/withAuth";
import PageHeader from "../../../../../../../Components/Bits/PageHeader";

import { getCampaignPlanByClientId } from "../../State/CampaignPlansDuck";
import ClientPlansPaginatedTable from "./ClientPlansPaginatedTable";

const CampaignPlan = () => {
    
    const { client_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { campaignPlans } = useSelector(state => state);
    const { clientPlans } = campaignPlans;
    const { data, success } = clientPlans;

    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [page, setPage] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    useEffect(() => {
        dispatch(getCampaignPlanByClientId(client_id));
    }, [dispatch, client_id]);

    return success ? (
        <div>
            <PageHeader 
                title="Campaign Plan" subheader="Manage Campaign Plans "
                breadcrumbs={[
                  {
                    title: "Dashboard",
                    href: "/",
                  },
                  {
                    title: "Manage Call Center",
                    href: "/CDA/callCenter",
                  },
                  {
                    title: "Client Campaign Plans",
                    href: "/CDA/callCenter/Plans",
                  }]
            }/>
            <Stack sx={{ mb: 3 }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate("/CDA/callCenter/Plans/create")}>
                    Create Plan
                </Button>
            </Stack>
            <ClientPlansPaginatedTable
                columns={[
                    { id: "0", label: "Id"},
                    { id: "1", label: "Plan" },
                    { id: "2", label: "View"},
                    { id: "3", label: "Edit" },	
                    { id: "4", label: "Start Date" },
                    { id: "5", label: "End Date" },
                    { id: "6", label: "Active" }
                ]}
                rows={data}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    ) : <div>No Client with that Id...</div>
}

export default withAuth(CampaignPlan);