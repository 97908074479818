import { FormControl, TextField } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { useField, useFormikContext } from "formik";

const CustomDatePicker = ({ name, label, ...otherProps }) => {
  const [field, mata] = useField(name);
  const { setFieldValue } = useFormikContext();

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl fullWidth>
        <DatePicker
          openTo="year"
          views={["year", "month", "day"]}
          label={label}
          name={name}
          {...configTextfield}
          variant="inline"
          onChange={(date) => setFieldValue(name, date)}
          inputVariant="outlined"
          fullWidth
          renderInput={(params) => <TextField {...params} />}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
