import React from "react";
import { useField, useFormikContext } from "formik";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";

const SelectWrapperWithInfo = ({ name, label, options, ...otherProps }) => {
  const { values, setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    variant: "outlined",
    fullWidth: true,
    onChange: handleChange,
  };

  return (
    <FormControl
      error={meta && meta.touched && meta.error ? true : null}
      fullWidth
    >
      <InputLabel id="select">{label}</InputLabel>
      <Select
        {...configSelect}
        labelId="select"
        id="select_id"
        value={values[name]}
        label={label}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Typography>{item.label}</Typography>
                {item?.hint && (
                  <Tooltip title={item.hint}>
                    <IconButton sx={{ float: "right" }} color="secondary">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default SelectWrapperWithInfo;

SelectWrapperWithInfo.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};
