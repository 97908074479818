import {
  Grid,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import React from "react";
import axios from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import withAuth from "../../../../../../Hoc/withAuth";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";
import LinkCampaignModal from "./Components/LinkCampaignModal";
import DeleteCampaignFromList from "./Components/DeleteCampaignFromList";
import { DataGridPremium } from "@mui/x-data-grid-premium";

const CampaignLinkingMain = ({ user }) => {
  const { id } = useParams();

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getCampaigns = useQuery({
    queryKey: ["campaigns"],
    queryFn: () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/QaInbound/admin/campaign/callcentercampaign`,
          config
        )
        .then((res) => res.data.data),
  });

  const campaigns = getCampaigns.data || [];


  const getCampaignsById = useQuery({
    queryKey: [`campaignsById`, id],
    queryFn: () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/QaInbound/admin/campaign/${id}/callcentercampaign/cc`
        )
        .then((res) => res.data.data),
    enabled: !!id,
  });

  const campaigns2 = getCampaignsById.data || [];
  

  const existingCampaigns = campaigns2.map(campaign => campaign.name) || []; 

  const addCampaign = useMutation({
    mutationFn: (campaign) =>
      axios.post(
        `${process.env.REACT_APP_API_URL}/QaInbound/admin/campaign/${id}/callcentercampaign`,
        campaign,
        config
      ),
    onSuccess: () => {
      getCampaignsById.refetch();
    },
  });

  const deleteCampaign = useMutation({
    mutationFn: (campaignId) =>
      axios.delete(
        `${process.env.REACT_APP_API_URL}/QaInbound/admin/campaign/${id}/callcentercampaign/cc/${campaignId}`
      ),
    onSuccess: () => {
      console.log("refetch")
      getCampaignsById.refetch();
    },
  });



  if(getCampaignsById.isLoading){
    return <LinearProgress />
  }

  return (
    <Grid container spacing={2} sx={{ mt: 4 }}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ mb: 2, ml: 2 }}>
          Call Center Campaigns
        </Typography>

        <Typography variant="h6" sx={{ mb: 2, ml: 2, mt: 3 }}>
          List of call center campaigns that will provide calls for this QA
        </Typography>
        <LinkCampaignModal campaigns={campaigns} addCampaign={addCampaign}  existingCampaigns ={existingCampaigns} />
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ height: "60vh" }}>
      

          <DataGridPremium
            columns={[
              {
                field: "id",
                headerName: "id",
                width: 200,
              },
              {
                field: "name",
                headerName: "Name",
                width: 200,
              },
              {
                field: "Delete",
                headerName: "Delete",
                width: 200,
                renderCell: (row) => {
                  return (
                    <DeleteCampaignFromList
                      deleteCampaign={deleteCampaign}
                      campaignToDelete={row.row}
                    />
                  );
                },
              },
            ]}
              rows={Array.isArray(getCampaignsById.data) ? getCampaignsById.data : []}
          />
        </Box>
      </Grid>

      <AlertPopup
        open={deleteCampaign.isSuccess}
        message="Campaign Deleted Successfully"
        severity="success"
      />
          <AlertPopup
        open={deleteCampaign.isError}
        message="Campaign Delete Error"
        severity="error"
      />
      <AlertPopup
        open={addCampaign.isSuccess}
        message="Campaign Addded Successfully"
        severity="success"
      />
      <AlertPopup
        open={addCampaign.isError}
        message="Error adding campaign"
        severity="error"
      />
  
    </Grid>
  );
};

export default withAuth(CampaignLinkingMain);
