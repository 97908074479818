import axios from "axios";

const initialState = {
  notifications: [],
  isLoading: false,
  error: null,
};

const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

const READ_NOTIFICATION = "READ_NOTIFICATION";
const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
const READ_NOTIFICATION_FAIL = "READ_NOTIFICATION_FAIL";

const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
const CREATE_NOTIFICATION_FAIL = "CREATE_NOTIFICATION_FAIL";

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        isLoading: false,
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case READ_NOTIFICATION:
      return {
        ...state,
        isLoading: true,
      };
    case READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload.id
        ),
        isLoading: false,
      };
    case READ_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case CREATE_NOTIFICATION:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,

        isLoading: false,
      };
    case CREATE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
export default notificationReducer;

export const getNotifications = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_NOTIFICATIONS,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/notifications`,
      config
    );

    dispatch({
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: data.data,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: GET_NOTIFICATIONS_FAIL,
      payload: error.response,
    });
  }
};

export const readNotification = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: READ_NOTIFICATION,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/notifications/${id}`,
      config
    );

    dispatch({
      type: READ_NOTIFICATION_SUCCESS,
      payload: data.data,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: READ_NOTIFICATION_FAIL,
      payload: error.response,
    });
  }
};

export const CreateNotification =
  (notification) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_NOTIFICATION,
      });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/notifications`,
        notification,
        config
      );

      dispatch({
        type: CREATE_NOTIFICATION_SUCCESS,
        payload: data.data,
      });

      // Storing the User Info in the local storage
    } catch (error) {
      dispatch({
        type: CREATE_NOTIFICATION_FAIL,
        payload: error.response,
      });
    }
  };
