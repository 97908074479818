import { Grid } from "@mui/material";
import React from "react";
import FeatureCard from "../../Components/Containers/FeatureCard";
import MainAppContainer from "../../Components/Containers/MainAppContainer";
import ConnectWithoutContactSharpIcon from "@mui/icons-material/ConnectWithoutContactSharp";
import ContactsSharpIcon from "@mui/icons-material/ContactsSharp";
import ContentPasteSearchSharpIcon from "@mui/icons-material/ContentPasteSearchSharp";
import PageHeader from "../../Components/Bits/PageHeader";
import withAuth from "../../Hoc/withAuth";

const CustomerRelationshipManagement = ({ user }) => {
  console.log(user);

  return (
    <MainAppContainer>
      <PageHeader
        title="CRM"
        subheader="Customer Relationship Management"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "CRM",
            href: "/crm",
          },
        ]}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FeatureCard
            Icon={ContactsSharpIcon}
            title="Clients"
            link="/CRM/clients/clientsList"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FeatureCard
            Icon={ConnectWithoutContactSharpIcon}
            title="Prospects"
            link="/crm/prospects"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FeatureCard
            Icon={ContentPasteSearchSharpIcon}
            title="Campaigns"
            link="/crm/campaigns"
          />
        </Grid>
      </Grid>
    </MainAppContainer>
  );
};

export default withAuth(CustomerRelationshipManagement);
