import { Alert, Box, LinearProgress, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

import React from "react";
import withAuth from "../../../../../../Hoc/withAuth";

const IncompleteEvaluationsReport = ({ user }) => {
  const { token } = user;

  const { data, isLoading, isRefetching } = useQuery(
    ["IncompleteEvaluationsPerQaAgent"],
    () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/reports/QaReports/IncompleteEvaluationsPerQaAgent`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
  );

  const valueFormatter = (value) => `${value}`;

  let newArray = [];
  let series = [];

  // always fun creating charts
  let campaigns = data?.data?.data.map((data, index) => {
    // search for queuenames in newArray
    let indexVal = newArray.findIndex(
      (item) => item.queue_name === data.queue_name,
    );
    if (indexVal === -1) {
      // if not found, add it to newArray with email:evaluations key pair
      newArray.push({
        queue_name: data.queue_name,
        [data.email]: parseInt(data.evaluations),
      });
    } else {
      // if found, add email:evaluations key pair to the existing object
      newArray[indexVal][data.email] = parseInt(data.evaluations);
    }

    // search for email in series
    let seriesIndex = series.findIndex((item) => item.dataKey === data.email);

    if (seriesIndex === -1) {
      // if not found, add it to series
      series.push({
        dataKey: data.email,
        // if email is @cdasolutions.co.za and contains a . replace @cdasolutions.co.za with '' and replace . with ' ' and capitalize
        label: data.email
          .replace("@cdasolutions.co.za", "")
          .replace(".", " ")
          .toUpperCase(),
        valueFormatter,
      });
    }

    return {
      ...data,
      id: index + 1,
      user: data.email
        .replace("@cdasolutions.co.za", "")
        .replace(".", " ")
        .toUpperCase(),
    };
  });

  const columns = [
    {
      field: "queue_name",
      headerName: "Queue Name",
      flex: 1,
      minWidth: 350,
    },
    {
      field: "user",
      headerName: "user",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "evaluations",
      headerName: "Total Incomplete",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "new",
      headerName: "New",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "busy",
      headerName: "Busy",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "re_evaluate",
      headerName: "Re-Evaluate",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "dispute",
      headerName: "Dispute",
      flex: 1,
      minWidth: 100,
    },
  ];

  const chartSetting = {
    yAxis: [
      {
        label: "Evaluations",
      },
    ],
    // width: 500,
    // height: 300,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-20px, 0)",
      },
    },
  };

  // console.log(newArray);

  return (
    <Stack sx={{ mt: 3 }}>
      {(isRefetching || isLoading) && <LinearProgress />}

      <Box sx={{ mt: 4 }}>
        <Grid container>
          {campaigns && campaigns.length > 0 ? (
            <>
              <div
                style={{
                  height: 750,
                  width: "94vw",
                }}
              >
                <BarChart
                  dataset={newArray}
                  xAxis={[{ scaleType: "band", dataKey: "queue_name" }]}
                  series={series}
                  {...chartSetting}
                />
                <DataGridPremium
                  rows={campaigns}
                  columns={columns}
                  loading={isLoading}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  pagination
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                      },
                    },
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                />
              </div>
            </>
          ) : (
            <Alert severity="info">No Data Found</Alert>
          )}
        </Grid>
      </Box>
    </Stack>
  );
};

export default withAuth(IncompleteEvaluationsReport);
