import * as React from "react";
import Button from "@mui/material/Button";
import { Box, Typography, Switch, Slider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { Stack } from "@mui/system";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import SelectWrapper from "../../../../../../Components/FormComponents/Select";

export default function CreateQuestion({ createQuestion, group }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleWheel = (event, setFieldValue, currentValue) => {
    event.preventDefault();
    const newValue = currentValue + (event.deltaY < 0 ? 5 : -5);
    setFieldValue("value", Math.max(0, newValue));
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{ marginRight: 1, marginLeft: 2 }}
      >
        Create Question
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create QA Question </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: "",
              description: "",
              question_type: group.group_type || "",
              value: group.value !== undefined ? group.value : 0,
              notification_group: false,
              auto_fail: false,
              group_id: group.id,
            }}
            enableReinitialize={true}
            onSubmit={(values) => {
              console.log(values);
              createQuestion.mutate(values);
              handleClose();
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <Stack sx={{ py: 2 }} spacing={2}>
                  <SelectWrapper
                    disabled
                    name="question_type"
                    label="Question Type"
                    options={[
                      {
                        value: "Score",
                        label: "Score Question",
                      },
                      {
                        value: "Text Input",
                        label: "Text Input Question",
                      },
                      {
                        value: "Yes/No",
                        label: "Yes/No Question",
                      },
                    ]}
                  />

                  {values?.question_type === "Text Input" && (
                    <>
                      <TextfieldWrapper name="name" label="Name" />
                      <TextfieldWrapper
                        name="description"
                        label="Description"
                      />
                    </>
                  )}

                  {values?.question_type === "Score" && (
                    <>
                      <TextfieldWrapper name="name" label="Name" />
                      <TextfieldWrapper
                        name="description"
                        label="Description"
                      />

                      <Stack direction="row" spacing={2}>
                        <Slider
                          defaultValue={0}
                          valueLabelDisplay="auto"
                          shiftStep={5}
                          step={5}
                          marks
                          min={0}
                          max={100}
                          value={values.value}
                          onChange={(event) => {
                            setFieldValue("value", event.target.value);
                          }}
                        />

                        <TextfieldWrapper
                          name="value"
                          type="number" 
                          label="Weight (number)"
                          step={5} 
                          min={0}
                          onWheel={(event) =>
                            handleWheel(event, setFieldValue, values.value)
                          }
                        />
                      </Stack>
                    </>
                  )}

                  {values?.question_type === "Yes/No" && (
                    <>
                      <TextfieldWrapper name="name" label="Name" />
                      <TextfieldWrapper
                        name="description"
                        label="Description"
                      />

                      {/* Auto Fail Section */}
                      <Box
                        sx={{
                          border: "0.1px solid #ccc",
                          borderRadius: "5px",
                          padding: "16px",
                        }}
                      >
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          Mark as an Auto Fail question
                        </Typography>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ alignItems: "center" }}
                        >
                          <Typography>No</Typography>
                          <Switch
                            checked={values.auto_fail}
                            onChange={(event) =>
                              setFieldValue("auto_fail", event.target.checked)
                            }
                          />
                          <Typography>Yes</Typography>
                        </Stack>
                        <Typography variant="caption" color="textSecondary">
                          If the question is marked as Auto Fail, a failed
                          status for the evaluation will be updated if the
                          question answer is no.
                        </Typography>
                      </Box>

                      {/* Notification Group Section */}
                      <Box
                        sx={{
                          border: "0.1px solid #ccc",
                          borderRadius: "5px",
                          padding: "16px",
                          mt: 2,
                        }}
                      >
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          Mark as notification Question
                        </Typography>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ alignItems: "center" }}
                        >
                          <Typography>No</Typography>
                          <Switch
                            checked={values.notification_group}
                            onChange={(event) =>
                              setFieldValue(
                                "notification_group",
                                event.target.checked
                              )
                            }
                          />
                          <Typography>Yes</Typography>
                        </Stack>
                        <Typography variant="caption" color="textSecondary">
                          If the question is failed, a notification will be sent
                          to the marked Team Leaders/Managers (whoever is in the
                          notification fields).
                        </Typography>
                      </Box>
                    </>
                  )}
                </Stack>

                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" type="submit" color="secondary">
                    Save
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
