import { Button } from "@mui/material";
import React from "react";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import styled from "@emotion/styled";
import { read, utils } from "xlsx";

const SalaryDeductionFileUploader = ({
  setDocument,
  setErrors,
  errors,
  setUploaded,
}) => {
  //   const handleFileChange = (event) => {
  //     setDocument(event.target.files[0]);
  //   };

  async function handleFileChange(e) {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    /* data is an ArrayBuffer */
    const workbook = read(data);
    const ToJson = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

    const salaryDeductionsList = ToJson.map((salaryDeductions, index) => {
      if (salaryDeductions.Name === undefined || salaryDeductions.Name === "") {
        setErrors([
          ...errors,
          "Name is required in Account Number " +
            salaryDeductions.AccountNumber,
        ]);
      }

      return {
        id: salaryDeductions.AccountNumber,
        company_name: salaryDeductions.Name,
        deduction_allowed:
          salaryDeductions.iemas_STIExclude === 0 ? true : false,
      };
    });

    setDocument(salaryDeductionsList);
    setUploaded(true);

    /* DO SOMETHING WITH workbook HERE */
  }

  //   console.log(document);

  //   const data =
  //     document &&
  //     utils.sheet_to_json(document, {
  //       header: 1,
  //     });

  //   console.log(data);

  return (
    <>
      <label htmlFor="contained-button-file">
        <Input
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          id="contained-button-file"
          type="file"
          autoFocus
          onChange={handleFileChange}
        />
        <Button
          variant="contained"
          size="large"
          sx={{ px: 5, py: 2 }}
          startIcon={<FileOpenIcon />}
          fullWidth
          component="span">
          Upload New List
        </Button>
      </label>
    </>
  );
};

export default SalaryDeductionFileUploader;

const Input = styled("input")({
  display: "none",
});
