import React, { useEffect } from "react";
import AgentCampaignList from "./components/AgentCampaignList";
import { getSalesAgentCampaigns } from "./State/SalesAgentCampaignsDuck";
import { useDispatch, useSelector } from "react-redux";

const CampaignList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesAgentCampaigns());
  }, [dispatch]);

  const salesAgent = useSelector((state) => state.salesAgent);
  const { campaigns } = salesAgent;

  return <AgentCampaignList list={campaigns} />;
};

export default CampaignList;
