import { Alert, LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AGENT_SALES_RESET, getLead } from "./State/AgentSalesReducer";
import EditLead from "./components/EditLead";
import { RESET_SALES_AGENT } from "./State/SalesAgentCampaignsDuck";
import withAuth from "../../../../../Hoc/withAuth";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";

const Lead = ({ user }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch({ type: RESET_SALES_AGENT });
    dispatch({ type: AGENT_SALES_RESET });
    dispatch(getLead(id));
  }, [dispatch, id]);

  const AgentSales = useSelector((state) => state.AgentSales);

  const { lead, loading, error, leadUpdated } = AgentSales;

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Alert severity="error">{JSON.stringify(error)}</Alert>;
  }

  return (
    <>
      <EditLead lead={lead} />
      <AlertPopup open={leadUpdated} severity="info" message="Lead Updated" />
    </>
  );
};

export default withAuth(Lead);
