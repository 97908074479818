import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import {
  Button,
  Stack,
  LinearProgress,
  Alert,
  Typography,
} from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import withAuth from "../../../Hoc/withAuth";

const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
};

const SMSList = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [mutationError, setMutationError] = useState("");

  const requisitionId = search.split("=")[1] || "";

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const mutation = useMutation({
    mutationFn: (values) => {
      return axios.put(
        `${process.env.REACT_APP_API_URL}/onboarding/employees/basicInfo/${values.id}`,
        {
          deletedAt: new Date(),
          cell_number: `${values.cell_number}_${values.id}`,
        },
        config
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["getHRSMSList"]);
    },
    onError: async (error) => {
      setMutationError(error.response.data.message);
    },
  });

  const onDeleteEmployeeRequisitionHandler = (id, cell_number) => {
    mutation.mutate({ id, cell_number });
  };

  const columns = [
    {
      field: "id",
      headerName: "Actions",
      width: 270,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          onClick={() =>
            onDeleteEmployeeRequisitionHandler(
              params.row.id,
              params.row.cell_number
            )
          }
          disabled={params.row.visible}>
          Delete
        </Button>
      ),
    },
    {
      field: "cell_number",
      headerName: "Mobile Number",
      width: 270,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const cell_number = params.row.cell_number;
        const id = params.row.id;

        return cell_number.replace(`_${id}`, "");
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      width: 270,
      renderCell: (params) => {
        const completed = params.row.completed;

        if (completed) {
          return <Typography color="success.main">Completed</Typography>;
        } else {
          return <Typography color="warning.main">In Progress</Typography>;
        }
      },
    },
    {
      field: "visible",
      headerName: "Visible",
      headerAlign: "center",
      align: "center",
      width: 270,
      renderCell: (params) => {
        const visible = params.row.visible;

        if (visible) {
          return <Typography color="error.main">No</Typography>;
        } else {
          return <Typography color="success.main">Yes</Typography>;
        }
      },
    },
  ];

  // get requisitions
  const { isLoading, isError, data, error } = useQuery(
    ["getHRSMSList"],
    async () =>
      await fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/employees/basicInfo/${requisitionId}`,
        config
      )
  );

  if (isLoading) return <LinearProgress />;

  if (isError) return <Alert severity="error">{error.message}</Alert>;

  return (
    <>
      <Stack spacing={3} sx={{ mb: 3 }}>
        <Button
          variant="outlined"
          color="info"
          onClick={() => navigate("/onboarding/hr/requisition")}>
          View Requisitions
        </Button>

        {mutation.isLoading && (
          <AlertPopup
            open={true}
            severity={"info"}
            message={"Updating SMS List..."}
          />
        )}
        {mutation.isError && (
          <AlertPopup open={true} message={mutation.error} severity={"error"} />
        )}

        {mutation.isSuccess && (
          <AlertPopup open={true} message={"SMS List updated successfully!"} />
        )}

        {mutationError && (
          <AlertPopup open={true} message={mutationError} severity={"error"} />
        )}

        <div style={{ height: "70vh", width: "100%", mt: 3 }}>
          <DataGridPremium
            rows={data?.data?.map((smslist) => ({
              id: smslist.id,
              cell_number: smslist.cell_number,
              status: smslist.completed,
              visible: smslist.deleted,
            }))}
            columns={columns}
            density="comfortable"
            slots={{ toolbar: GridToolbar }}
          />
        </div>
      </Stack>
    </>
  );
};

export default withAuth(SMSList);
