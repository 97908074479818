import { Stack, Chip, LinearProgress, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import AddLeadSource from "./Components/Leads/AddLeadSource";
import { getLeadSourceList, RESET_LEADS_SOURCES } from "./State/LeadsDuck";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import DeleteLeadSource from "./Components/Leads/DeleteLeadSource";

const LeadSourcesManage = () => {
  const dispatch = useDispatch();

  const Leads = useSelector((state) => state.Leads);
  const { allLeadSources, editSuccess, addSuccess, loading, success } = Leads;

  useEffect(() => {
    dispatch(getLeadSourceList());
  }, [dispatch]);

  useEffect(() => {
    if (addSuccess || editSuccess) {
      dispatch(getLeadSourceList());
      dispatch({ type: RESET_LEADS_SOURCES });
    }
  }, [dispatch, addSuccess, editSuccess]);

  if (success) {
    // order allLeadSources by updatedAt desc
    allLeadSources.sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });
  }

  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Source", width: 250 },
    {
      field: "activeStatus",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Chip
          label={params.row.activeStatus}
          variant="outlined"
          color={params.row.activeStatus === "Active" ? "success" : "error"}
        />
      ),
    },
    {
      field: "api",
      headerName: "API Source Indicator",
      width: 200,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 180,
      renderCell: (params) => <DeleteLeadSource lead={params.row} />,
    },
  ];

  // console.log("result", success && allLeadSources.length);

  const rows =
    success && allLeadSources && allLeadSources?.length > 0
      ? allLeadSources?.map((leadSource) => {
          return {
            id: leadSource.id,
            name: leadSource.name,
            active: leadSource.active,
            activeStatus: leadSource.active ? "Active" : "Inactive",
            api: leadSource.active && leadSource.api_flag ? leadSource.id : "",
          };
        })
      : [];

  return (
    <div>
      <Stack sx={{ mb: 3 }}>
        <AddLeadSource />
        {
          // link to https://api.cdasolutions.co.za/docs/
        }
        <Typography variant="body2" color="text.secondary">
          API Source Indicator: If the API Source Indicator is set, the lead
          source will be available for API integration.{" "}
          <a
            href="https://api.cdasolutions.co.za/docs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            API Documentation
          </a>
        </Typography>
      </Stack>
      {loading && <LinearProgress />}
      <div style={{ height: "70vh", width: "90%" }}>
        <DataGridPremium
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          initialState={{
            filter: {
              filterModel: {
                items: [
                  {
                    field: "activeStatus",
                    operator: "equals",
                    value: "Active",
                  },
                ],
              },
            },
          }}
        />
      </div>
      <AlertPopup
        open={editSuccess}
        severity="success"
        message="updated Successfully"
      />
      <AlertPopup
        open={addSuccess}
        severity="success"
        message="Lead added Successfully"
      />
    </div>
  );
};

export default LeadSourcesManage;
