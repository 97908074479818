import { Container } from "@mui/material";
import React from "react";

const MainAppContainer = (props) => {
  const { children } = props;

  return (
    <Container disableGutters maxWidth="xl">
      {children}
    </Container>
  );
};

export default MainAppContainer;
