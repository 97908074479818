import {
  List,
  Stack,
  IconButton,
  Collapse,
  Divider,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  tableCellClasses,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainAppContainer from "../../../../../Components/Containers/MainAppContainer";
import { useSelector, useDispatch } from "react-redux";
import { getQuestionnaireDetails } from "./State/QuestionnaireResultsDuck";
import { StyledListItem } from "../../../../../Components/Containers/StyledListItem";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import styled from "@emotion/styled";

const ViewQuestionnaireDetails = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuestionnaireDetails(id));
  }, [dispatch, id]);

  const QuestionnaireResultsDuck = useSelector(
    (state) => state.QuestionnaireResultsDuck
  );
  const { loading, error, campaignResult } = QuestionnaireResultsDuck;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <MainAppContainer>
      <AlertPopup
        severity="error"
        message="Something Went wrong"
        open={error}
      />

      <Typography variant="h4" sx={{ my: 4 }}>
        Questionnaire Results
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Test Name</StyledTableCell>
              <StyledTableCell>Score</StyledTableCell>
              <StyledTableCell align="right">Result</StyledTableCell>
              <StyledTableCell align="right">User</StyledTableCell>
              <StyledTableCell align="right">Employee No</StyledTableCell>
              <StyledTableCell align="right">ID Number</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>
                {campaignResult?.questionnaire_campaign?.name}
              </StyledTableCell>
              <StyledTableCell>{campaignResult?.score}%</StyledTableCell>
              <StyledTableCell
                sx={
                  campaignResult?.passed
                    ? { color: "secondary.main", fontWeight: "bold" }
                    : { color: "error.main", fontWeight: "bold" }
                }
                align="right">
                {campaignResult?.passed ? "passed." : "failed."}
              </StyledTableCell>
              <StyledTableCell align="right">
                {campaignResult?.questionnaire_user?.User?.name}
                {campaignResult?.questionnaire_user?.User?.surname}
              </StyledTableCell>
              <StyledTableCell align="right">
                {" "}
                {campaignResult?.questionnaire_user?.User?.employee_no}
              </StyledTableCell>
              <StyledTableCell align="right">
                {" "}
                {campaignResult?.questionnaire_user?.User?.id_number}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* 
      <List>
        {campaignResult?.questionnaire_results?.map((result, index) => (
          <TheList result={result} key={index} />
        ))}
      </List> */}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Question</StyledTableCell>
              <StyledTableCell>Correct Answer</StyledTableCell>
              <StyledTableCell>Chosen Answer</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaignResult?.questionnaire_results?.map((row) => (
              <TheList result={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <pre>{JSON.stringify(campaignResult, null, 2)}</pre> */}
    </MainAppContainer>
  );
};

const TheList = ({ result }) => {
  const [open, setOpen] = useState(false);

  const displayCorrectedAnswer = (correct_answer) => {
    let answer = result?.questionnaire_question?.questionnaire_answers.find(
      (answer) => answer.id === Number(correct_answer)
    );
    return answer;
  };
  const displaySelectedAnswer = (correct_answer) => {
    let answer = result?.questionnaire_question?.questionnaire_answers.find(
      (answer) => answer.id === Number(correct_answer)
    );
    return answer;
  };

  return (
    <>
      <TableRow key={result.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
        <StyledTableCell component="th" scope="row">
          {result?.questionnaire_question?.question}
        </StyledTableCell>
        <StyledTableCell>
          {
            displayCorrectedAnswer(
              result?.questionnaire_question?.correct_answer
            ).answer
          }
        </StyledTableCell>
        <StyledTableCell
          sx={
            displayCorrectedAnswer(
              result?.questionnaire_question?.correct_answer
            )?.id === displaySelectedAnswer(result?.answer_id)?.id
              ? { color: "secondary.main", fontWeight: "bold" }
              : { color: "error.main", fontWeight: "bold" }
          }>
          {displaySelectedAnswer(result?.answer_id)?.answer}
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Stack>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {result?.questionnaire_question?.questionnaire_answers?.map(
                  (answer, index) => (
                    <div key={index}>
                      <StyledListItem
                        secondaryAction={
                          <Stack direction="row" spacing={1}></Stack>
                        }>
                        {answer.answer}
                      </StyledListItem>
                      <Divider />
                    </div>
                  )
                )}
              </List>
            </Collapse>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ViewQuestionnaireDetails;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
