import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import LogoDark from "../../assets/TheNodeDark.png";
import LogoLight from "../../assets/TheNodeLight.png";
import axios from "axios";
import { Alert, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import * as yup from "yup";
import { useFormik } from "formik";

const validationSchema = yup.object({
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Confirm Password is required"),
});

export default function ResetPassword() {
  const theme = useTheme();

  const { id } = useParams();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [response, setResponse] = React.useState("");

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/resetPassword/${id}`,
          { password: values.password }
        );
        setResponse("Success, Please login with your new password");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    },
  });

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
      <img
        style={{ width: "40%" }}
        src={theme.palette.mode === "light" ? LogoDark : LogoLight}
        alt=""
      />
      <Typography component="h1" variant="h5">
        Reset Password
      </Typography>

      <Box style={{ width: "40%" }} noValidate sx={{ mt: 5 }}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            minLength={10}
            type="password"
            id="Confirm Password"
            label="Confirm Password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.password && Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.password && formik.errors.confirmPassword
            }
          />

          {response ? (
            <>
              <Alert severity="success">
                {JSON.stringify(response, null, 2)}
              </Alert>
              <Link
                style={{ textDecoration: "none" }}
                to="/sign-in"
                variant="body2">
                <Button fullWidth color="secondary" variant="contained">
                  back to Login?
                </Button>
              </Link>
            </>
          ) : (
            <>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={
                    formik.values.password !== formik.values.confirmPassword
                  }
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}>
                  Reset Password
                </Button>
              )}
            </>
          )}
        </form>

        {error && (
          <Alert severity="error"> {JSON.stringify(error?.message)}</Alert>
        )}
        <Grid container justifyContent="center">
          <Grid item xs>
            <Link
              style={{ textDecoration: "none" }}
              to="/sign-in"
              variant="body2">
              <Button color="info">
                <Typography color="textPrimary">back to Login?</Typography>
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Box>
  );
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {"Copyright © "}
      <Link
        style={{ textDecoration: "none" }}
        color="inherit"
        to="https://cdasolutions.co.za">
        CDA Solutions
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
