import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { editTarget } from "../State/TargetsDuck";
import { Stack } from "@mui/material";
import DateSelectorWrapper from "../../../../../../Components/FormComponents/DateSelectorWrapper";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";

export default function EditTargets({ target }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>Edit Target</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Target</DialogTitle>
        <>
          <Formik
            initialValues={{
              user_id: target.user_id || "",
              date_from: dayjs(target.date_from) || "",
              date_to: dayjs(target.date_to) || "",
              sales_target: target.sales_target || "",
              ci_target: target.ci_target || "",
              scr_target: target.scr_target || "",
              hours_target: target.hours_target || "",
              pending_target: target.pending_target || "",
              manager_id: target.manager_id || "",
            }}
            validationSchema={Yup.object({
              user_id: Yup.string().required("Required"),
              manager_id: Yup.string().required(
                "User does not have a manager Contact Admin to assign a manager"
              ),
              sales_target: Yup.number().required("Required"),
              ci_target: Yup.number().required("Required"),
              scr_target: Yup.number().required("Required"),
              hours_target: Yup.number().required("Required"),
              pending_target: Yup.number().required("Required"),
            })}
            onSubmit={(values) => {
              values.date_from = values.date_from.format("YYYY-MM-DD");
              values.date_to = values.date_to.format("YYYY-MM-DD");
              dispatch(editTarget(target.id, values));
              handleClose();
            }}>
            {({ errors }) => {
              return (
                <Form>
                  <Stack sx={{ pt: 2, px: 3 }} spacing={2}>
                    <Stack direction="row" spacing={2}>
                      <DateSelectorWrapper name="date_from" label="Date From" />
                      <DateSelectorWrapper name="date_to" label="Date To" />
                    </Stack>
                    <TextfieldWrapper
                      type="number"
                      name="sales_target"
                      label="Sales Target"
                    />
                    <TextfieldWrapper
                      type="number"
                      name="ci_target"
                      label="CI Target"
                    />
                    <TextfieldWrapper
                      type="number"
                      name="scr_target"
                      label="SCR Target"
                    />
                    <TextfieldWrapper
                      type="number"
                      name="hours_target"
                      label="Hours Target"
                    />
                    <TextfieldWrapper
                      name="pending_target"
                      label="Pending Target"
                    />

                    <Button type="submit" color="secondary" variant="contained">
                      Save
                    </Button>
                    <Button color="inherit" onClick={handleClose}>
                      Cancel
                    </Button>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </>
      </Dialog>
    </div>
  );
}
