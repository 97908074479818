import {
  Card,

  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLead } from "./State/AgentSalesReducer";
import { getSaleByLead } from "./State/SalesAgentCampaignsDuck";

const SaleQa = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLead(id));
    dispatch(getSaleByLead(id));
  }, [dispatch, id]);

  const salesAgent = useSelector((state) => state.salesAgent);

  const { leadSales, saleQA } = salesAgent;

  const findQa = (reference_no) => {
    let qa = saleQA?.filter((qa) => qa.uni_id !== reference_no);
    return qa;
  };

  return (
    <>
      <Grid container spacing={2}>
        {leadSales.map((item) => {
          return (
            <Grid item xs={4} mt={4} key={item.id}>
              <Card>
                <List
                  dense
                  subheader={<ListSubheader>Evaluation</ListSubheader>}></List>
                <ContentItem title="Reference No" value={item.reference_no} />
              </Card>

              <Stack>
                {findQa(item.reference_no) &&
                findQa(item.reference_no).length > 0
                  ? findQa(item.reference_no).map((qa) => {
                      return (
                        <Card key={qa.id}>
                          <List
                            dense
                            subheader={
                              <ListSubheader>Evaluation</ListSubheader>
                            }></List>
                          <ContentItem
                            title="Reference No"
                            value={qa.reference_no}
                          />
                        </Card>
                      );
                    })
                  : null}
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SaleQa;

const ContentItem = ({ title, value }) => {
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.secondary">
                {title}
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body1"
                color="text.primary">
                {value}
              </Typography>
            </>
          }
        />
      </ListItem>
    </>
  );
};

