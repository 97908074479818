import React from "react";
import { Button, Dialog, AppBar, Toolbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ReportWidget = ({ title, url, description }) => {
  const [viewState, setView] = React.useState(false);

  const viewModal = () => {
    setView(!viewState);
  };

  return (
    <>
      <Button
        sx={{ mt: 4 }}
        variant="contained"
        onClick={() => setView(!viewState)}>{`View ${
        title ?? ""
      } Report`}</Button>
      <Dialog
        fullScreen
        open={viewState}
        onClose={() => viewModal()}
        aria-labelledby={`modal-${title}`}
        aria-describedby={description}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={viewModal}
              aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <iframe
          height="100%"
          width="100%"
          title={title}
          src={url}
          allowFullScreen={true}></iframe>
      </Dialog>
    </>
  );
};

export default ReportWidget;
