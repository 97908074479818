import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import Store from "./Redux/Store";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
// const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

// if (process.env.NODE_ENV !== "development") console.log = () => {};

root.render(
  <React.StrictMode>
    <Provider store={Store}>
      {/* <MsalProvider instance={msalInstance}> */}
      <App />
      {/* </MsalProvider> */}
    </Provider>
  </React.StrictMode>
);
