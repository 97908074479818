import {
  Alert,
  Button,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextfieldWrapper from "../../../../../Components/FormComponents/TextfieldWrapper";
import * as Yup from "yup";
import { captureAbsent, getAbsents, RESET_ABSENT_STATE } from "./state";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import { getUsersOfModule } from "../../../../../Redux/Ducks/AdminUserManagement";
import SelectUserComponent from "../../../../../Components/Bits/SelectUserComponent";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import SelectWrapper from "../../../../../Components/FormComponents/Select";

const CaptureAbsenteeism = () => {
  const dispatch = useDispatch();

  // const Teams = useSelector((state) => state.Teams);
  const Absenteeism = useSelector((state) => state.Absenteeism);

  useEffect(() => {
    dispatch(getUsersOfModule("call-center-user"));
    dispatch(getAbsents());
    dispatch({ type: RESET_ABSENT_STATE });
  }, [dispatch]);

  const { isLoading, message, error } = Absenteeism;

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Stack spacing={2}>
      {error?.addAbsent === false && (
        <AlertPopup open={true} message={message} />
      )}
      {error?.addAbsent === true && (
        <AlertPopup open={true} message={message} severity={"error"} />
      )}

      {/* <FormControl fullWidth>
        <InputLabel id="select-team-label">Select User</InputLabel>
        <Select
          labelId="select-team-label"
          id="select-team"
          value={selectedUser}
          label="Select User"
          onChange={(e) => setSelectedUser(e.target.value)}>
          {usersOfModule[0]?.Modules_users.map((user) => {
            return (
              <MenuItem key={user.user_id} value={user}>
                {user.User.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl> */}

      <Formik
        initialValues={{
          date_from: new Date(),
          date_to: new Date(),
          update_reason: "",
          user: "",
          manager: "",
          manager_id: "",
          absent_type: "",
          user_id: "",
        }}
        validationSchema={Yup.object().shape({
          update_reason: Yup.string().required("Please provide reason"),
          user: Yup.object().required("Please select user"),
        })}
        onSubmit={(values) => {
          console.log(values);
          values.manager_id = values?.user?.manager
            ? values?.user?.manager
            : values?.manager?.id;
          values.user_id = values.user?.id;

          dispatch(captureAbsent(values));
        }}>
        {({ errors, values, setFieldValue }) => {
          return (
            <Form>
              <Stack direction="column" spacing={2}>
                {errors.user && <Alert severity="warning">Select a User</Alert>}
                <SelectUserComponent
                  module="call-center-user"
                  label="Select User"
                  name="user"
                />

                {values.user && !values.user.manager && (
                  <SelectUserComponent
                    module="call-center-manager"
                    name="manager"
                    label="Select Manager"
                  />
                )}

                <Typography textAlign="center" fontSize={20}>
                  Absent
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <>
                    <DateRangePicker
                      format="DD/MM/YYYY"
                      localeText={{ start: "From", end: "To" }}
                      onChange={(value) => {
                        setFieldValue("date_from", value[0]);
                        setFieldValue("date_to", value[1]);
                      }}
                    />
                  </>
                </LocalizationProvider>

                <SelectWrapper
                  name="absent_type"
                  label="Absent Type"
                  options={[
                    { value: "annual", label: "Annual" },
                    {
                      value: "family_responsibility",
                      label: "Family Responsibility",
                    },
                    { value: "sick", label: "Sick" },
                    {
                      value: "study",
                      label: "Study",
                    },
                    { value: "unpaid_planned", label: "Unpaid Planned" },
                    { value: "unpaid_unplanned", label: "Unpaid Unplanned" },
                  ]}
                />

                <TextfieldWrapper
                  name="update_reason"
                  label="Reason for absenteeism"
                  multiline
                  rows={2}
                />
                {values.user && !values.user.manager && (
                  <Alert severity="warning">User needs the manager.</Alert>
                )}

                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default CaptureAbsenteeism;
