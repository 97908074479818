import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainAppContainer from "../../../../../../Components/Containers/MainAppContainer";
import {
  editQuestionnaireQuestion,
  getQuestionnaireById,
  RESET_MESSAGES,
} from "../State/QuestionnairsDuck";
import { useParams } from "react-router-dom";
import CreateQuestionForm from "./CreateQuestionsForm";
import styled from "@emotion/styled";
import CreateAnswersForm from "./CreateAnswersForm";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DeleteQuestion from "./DeleteQuestion";
import EditQuestion from "./EditQuestion";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";
import AddUsersToQuestionnaire from "./AddUsersToQuestionnaire";
import withAuth from "../../../../../../Hoc/withAuth";
import { getCompanyModules } from "../../../../../../Redux/Ducks/AdminCompniesDuck";
import DeleteQuestionnaireUser from "./DeleteQuestionnaireUser";
import EditQuestionnaire from "./EditQuestionnaire";
import EditQuestionnaireUser from "./EditQuestionnaireUser";
import EditQuestionnaireAnswer from "./EditQuestionnaireAnswer";
import DeleteQuestionAnswer from "./DeleteQuestionAnswer";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PageHeader from "../../../../../../Components/Bits/PageHeader";

const ViewQuestionnaire = ({ companyDetails }) => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuestionnaireById(id));
    dispatch(getCompanyModules(companyDetails.companyId));
    dispatch({ type: RESET_MESSAGES });
  }, [dispatch, id, companyDetails]);

  const Companies = useSelector((state) => state.Companies);
  const { companyModules } = Companies;

  const moduleUsers = companyModules?.find(
    (module) => module?.name === "questionnaire"
  );

  const QuestionnairesDuck = useSelector((state) => state.QuestionnairesDuck);
  const { questionnaire, success, updateEditQuestionnaire } =
    QuestionnairesDuck;

  // filter out the users that are already in the questionnaire
  const users = [];

  for (let i = 0; i < questionnaire?.questionnaire_users?.length; i++) {
    for (let j = 0; j < moduleUsers?.Modules_users?.length; j++) {
      if (
        questionnaire?.questionnaire_users[i].user_id ===
        moduleUsers?.Modules_users[j].user_id
      ) {
        users.push(moduleUsers?.Modules_users[j]);
      }
    }
  }

  return (
    <MainAppContainer>
      <PageHeader
        title="View Questionnaire"
        subheader="View and Manage Company questionnaire"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Questionnaire Admin",
            href: "/CDA/questionnaireAdmin",
          },
          {
            title: "View Questionnaire",
            href: `/CDA/questionnaire/${id}`,
          },
        ]}
      />
      <Grid container sx={{ mb: 10 }}>
        <Grid item xl={12} lg={21} md={12} sm={12} xs={12}>
          <Stack spacing={2}>
            <Card>
              <CardHeader
                title={questionnaire?.name}
                subheader={questionnaire.description}
              />
              <EditQuestionnaire questionnaire={questionnaire} />
            </Card>

            <Card>
              <CardHeader
                title="Users"
                subheader="Add Users to your questionnaire"
              />

              <CardContent>
                <AddUsersToQuestionnaire
                  campaign_id={id}
                  users={moduleUsers?.Modules_users.filter(
                    (user) => !users.includes(user)
                  )}
                />

                <Stack mt={2}>
                  <List>
                    {questionnaire?.questionnaire_users?.map((user, index) => {
                      return (
                        <StyledListItem
                          key={index}
                          secondaryAction={
                            <Stack direction="row">
                              {user?.can_retake ? (
                                <Button color="warning">close test</Button>
                              ) : (
                                <EditQuestionnaireUser user={user} />
                              )}

                              <DeleteQuestionnaireUser
                                user={user.name}
                                id={user.id}
                              />
                            </Stack>
                          }>
                          <ListItemText primary={user?.User?.name} />
                        </StyledListItem>
                      );
                    })}
                  </List>
                </Stack>
              </CardContent>
            </Card>

            <Card>
              <CardHeader
                title="Questions"
                subheader="Add Questions to your questionnaire"
              />

              <CardContent>
                <CreateQuestionForm questionnaireCampaign={questionnaire} />
                {questionnaire?.questionnaire_questions?.length < 5 && (
                  <Alert sx={{ mt: 1 }} severity="warning">
                    questionnaire must have at least 5 questions
                  </Alert>
                )}

                <List>
                  {questionnaire?.questionnaire_questions?.map(
                    (data, index) => {
                      return (
                        <ListThing data={data} index={index} key={index} />
                      );
                    }
                  )}
                </List>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>
      <AlertPopup open={success} severity="success" message="Updated" />
      <AlertPopup
        open={updateEditQuestionnaire}
        severity="success"
        message="Updated"
      />
    </MainAppContainer>
  );
};

export default withAuth(ViewQuestionnaire);

const ListThing = ({ data, index }) => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleClick = () => {
    setOpen(!open);
  };

  let alphabet = "abcdefghijklmnopqrstuvwxyz";

  let alphabetArray = alphabet.split("");

  const setAsAnswer = (question_id, answer_id) => {
    let question = { correct_answer: answer_id };
    dispatch(editQuestionnaireQuestion(question_id, question));
  };

  const displayCorrectedAnswer = (correct_answer) => {
    let answer = data?.questionnaire_answers?.find(
      (answer) => answer.id === Number(correct_answer) // find the answer that matches the correct answer
    );
    return answer;
  };

  return (
    <>
      <StyledListItem
        sx={
          data?.questionnaire_answers?.length > 0
            ? { p: 2, border: "1px dashed grey" }
            : { p: 2, border: "1px dashed red" }
        }
        secondaryAction={
          <Stack direction="row" spacing={2}>
            {data?.questionnaire_answers?.length > 0 ? (
              <>
                <IconButton onClick={handleClick}>
                  <Typography variant="body2">
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </Typography>
                </IconButton>
                {data?.questionnaire_answers?.length === 1 && (
                  <Alert severity="warning" variant="outlined">
                    2 answers required!
                  </Alert>
                )}
              </>
            ) : (
              <>
                <Alert severity="warning">Question needs answers!</Alert>
              </>
            )}
            <CreateAnswersForm question_id={data.id} />
            <EditQuestion question={data} />
            <DeleteQuestion id={data.id} question={data.question} />
          </Stack>
        }>
        <ListItemText sx={{ maxWidth: "80%" }}>
          <>
            <Stack>
              <Typography>
                {index + 1}:{data.question}
              </Typography>
              <Typography sx={{ mt: 2 }} variant="caption">
                Correct Answer
              </Typography>

              {data.correct_answer ? (
                <Typography variant="caption" color="secondary">
                  {displayCorrectedAnswer(data.correct_answer)?.answer}
                </Typography>
              ) : (
                <Typography variant="h6" color="error">
                  No Correct Answer
                </Typography>
              )}
            </Stack>
          </>
        </ListItemText>
      </StyledListItem>
      <Divider />
      <Stack sx={{ mx: 3 }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {data?.questionnaire_answers?.map((answer, index) => (
              <div key={index}>
                <StyledListItem
                  secondaryAction={
                    <Stack direction="row" spacing={1}>
                      {displayCorrectedAnswer(data.correct_answer)?.answer ===
                      answer.answer ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center">
                          <Stack direction="row" spacing={1}>
                            <CheckCircleOutlineIcon color="secondary" />
                            <Typography color="secondary">
                              Correct Answer
                            </Typography>
                          </Stack>
                        </Box>
                      ) : (
                        <Button onClick={() => setAsAnswer(data.id, answer.id)}>
                          set as correct answer
                        </Button>
                      )}

                      <EditQuestionnaireAnswer answer={answer} />
                      <DeleteQuestionAnswer
                        id={answer.id}
                        answer={answer.answer}
                      />
                    </Stack>
                  }>
                  <ListItemText
                    primary={
                      <Stack direction="row" justifyContent="space-between">
                        <Typography>
                          {alphabetArray[index] + " : " + answer.answer}
                        </Typography>
                      </Stack>
                    }
                  />
                </StyledListItem>
                <Divider />
              </div>
            ))}
          </List>
        </Collapse>
      </Stack>
    </>
  );
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
