import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Typography, IconButton } from "@mui/material";
import { Form, Formik } from "formik";
import { Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";


const DeActivateQuestionGroup = ({ deactivateQuestionGroups, group }) => {








  const [open, setOpen] = React.useState(false);




  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {group.active ? (
        <Tooltip title="Delete Group">
        <IconButton
          variant="contained"
          onClick={handleClickOpen}
          color="error"
        >
          <DeleteIcon />
        </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          onClick={handleClickOpen}
          color="primary"
        >
          Activate
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Formik
            initialValues={{
              ...group,
              active: !group.active,
            }}
            onSubmit={(values) => {
              console.log(values);
              deactivateQuestionGroups.mutate(values);
              handleClose();
            }}
          >
            {() => (
              <Form>
                <Typography variant="h6" gutterBottom>
                  {group.name}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {group.active
                    ? "Are you sure you want to deactivate Question Group?"
                    : "Activate Question Group"}
                </Typography>

                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    color={group.active ? "error" : "primary"}
                    variant="contained"
                    type="submit"
                  >
                    {group.active ? "Deactivate" : "Activate"}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DeActivateQuestionGroup;
