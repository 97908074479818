import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { StageColors } from "./StageColors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EvaluationStageHistory = ({ stage_reason_history }) => {
  // get Latest history

  if (stage_reason_history?.length > 0) {
    return (
      <div>
        <HistoryCard history={stage_reason_history[0]} />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>View More History</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {stage_reason_history.length > 1 &&
              stage_reason_history.map((history, index) => {
                return (
                  <HistoryCard key={index} index={index} history={history} />
                );
              })}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  } else {
    return <div>No History</div>;
  }
};

export default EvaluationStageHistory;

const HistoryCard = ({ history, index }) => {
  return (
    <Card key={index ? index : 1}>
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="caption" color="text-secondary">
              {history?.user?.name}
            </Typography>
            <Typography variant="caption" color="text-secondary">
              {new Date(history?.date).toLocaleDateString()} -
              {new Date(history?.date).toLocaleTimeString()}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="body1" color="text-secondary">
              {history?.reason}
            </Typography>
          </Stack>
          <>
            {history?.stage && (
              <Chip
                variant="outlined"
                label={history?.stage}
                color={StageColors(history?.stage)}
              />
            )}
          </>
        </Stack>
      </CardContent>
    </Card>
  );
};
