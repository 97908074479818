import * as React from "react";
import { Modal, Box, Typography, Button, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { updateTeamMemberStatus } from "../Services/api";
import { useQueryClient } from "@tanstack/react-query";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ReactivationModal = ({ open, onClose, row, campaignId }) => {

  const queryClient = useQueryClient();
  
  const reactivationMutation = useMutation(
    () => updateTeamMemberStatus(campaignId, row.id, true),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("team");
        onClose();
      },
      onError: (error) => {
        console.error("Error reactivating member:", error);
      },
    }
  );

  const handleReactivate = () => {
    reactivationMutation.mutate();
  };

  return (
    <Stack>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={onClose}
      >
        <Box sx={style}>
          <Typography variant="h6">Reactivate Member</Typography>
          <Typography variant="body1">
            Are you sure you want to reactivate the member with email{" "}
            <strong>{row?.email}</strong>?
          </Typography>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onClose} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button
              onClick={handleReactivate}
              color="primary"
              disabled={reactivationMutation.isLoading}
            >
              {reactivationMutation.isLoading
                ? "Reactivating..."
                : "Reactivate"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <AlertPopup
        open={reactivationMutation.isSuccess}
        severity="success"
        message="Member restored successfully"
      />
      <AlertPopup
        open={reactivationMutation.isError}
        severity="error"
        message="Failed to restore mamber"
      />
    </Stack>
  );
};

export default ReactivationModal;
