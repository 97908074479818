import { Autocomplete, TextField, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useFormikContext } from "formik";
import React from "react";
import withAuth from "../../Hoc/withAuth";

// PROPS

// module  = "call-center-user"

// const [user, setUser] = useState("")

const SelectUserComponent = ({ module, name, label, user }) => {
  const { setFieldValue } = useFormikContext();
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getUsers = useQuery([`getUsersOfModule${module}`], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/admin/UserOfModules/${module}`,
      config
    );
  });

  let users = getUsers?.data?.data?.data[0]?.Modules_users;

  const options = users?.map((option) => {
    const firstLetter = option?.User?.name[0].toUpperCase();
    return {
      id: option.id,
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      person: `${option.User?.name} ${option.User?.surname}`,
      user: option.User,
    };
  });

  return (
    <div>
      {users && users.length > 0 ? (
        <Autocomplete
          name="user_id"
          id="checkboxes-select-questions"
          groupBy={(option) => option.firstLetter}
          options={options.sort((a, b) => a.person.localeCompare(b.person))}
          isOptionEqualToValue={(option, value) =>
            option.person === value.person
          }
          getOptionLabel={(option) => option.person}
          onChange={(event, newValue) => {
            setFieldValue(name, newValue.user);
          }}
          renderOption={(props, option, { selected }) => {
            return <li {...props}>{option.person}</li>;
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label={label}
              placeholder={label}
            />
          )}
        />
      ) : (
        <Typography>Give Users {module} module</Typography>
      )}
    </div>
  );
};

export default withAuth(SelectUserComponent);
