import axios from "axios";

const initialState = {
  fromEvals: [],
  toEvals: [],
  loading: false,
  error: null,
  moveSuccess: false,
  allEvaluations: [],
  deleteSuccess: false,
  qaAdmin: {},
};

const GET_ALL_EVALS_REQUEST = "GET_ALL_EVALS_REQUEST";
const GET_ALL_EVALS_SUCCESS = "GET_ALL_EVALS_SUCCESS";
const GET_ALL_EVALS_FAILURE = "GET_ALL_EVALS_FAILURE";

const GET_EVALS_FROM_USER_REQUEST = "GET_EVALS_FROM_USER_REQUEST";
const GET_EVALS_FROM_USER_SUCCESS = "GET_EVALS_FROM_USER_SUCCESS";
const GET_EVALS_FROM_USER_FAILURE = "GET_EVALS_FROM_USER_FAILURE";

const GET_EVALS_TO_USER_REQUEST = "GET_EVALS_TO_USER_REQUEST";
const GET_EVALS_TO_USER_SUCCESS = "GET_EVALS_TO_USER_SUCCESS";
const GET_EVALS_TO_USER_FAILURE = "GET_EVALS_TO_USER_FAILURE";

const MOVE_EVALUATION_REQUEST = "MOVE_EVALUATION_REQUEST";
const MOVE_EVALUATION_SUCCESS = "MOVE_EVALUATION_SUCCESS";
const MOVE_EVALUATION_FAILURE = "MOVE_EVALUATION_FAILURE";

const DELETE_EVALUATION_REQUEST = "DELETE_EVALUATION_REQUEST";
const DELETE_EVALUATION_SUCCESS = "DELETE_EVALUATION_SUCCESS";
const DELETE_EVALUATION_FAILURE = "DELETE_EVALUATION_FAILURE";

const ADMIN_QA_RESULTS_REQUEST = "ADMIN_QA_RESULTS_REQUEST";
const ADMIN_QA_RESULTS_SUCCESS = "ADMIN_QA_RESULTS_SUCCESS";
const ADMIN_QA_RESULTS_FAILURE = "ADMIN_QA_RESULTS_FAILURE";

const ADMIN_POST_RESULTS_REQUEST = "ADMIN_POST_RESULTS_REQUEST";
const ADMIN_POST_RESULTS_SUCCESS = "ADMIN_POST_RESULTS_SUCCESS";
const ADMIN_POST_RESULTS_FAILURE = "ADMIN_POST_RESULTS_FAILURE";

export const MOVE_RESET = "MOVE_RESET";

const QaEvaluationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EVALS_REQUEST:
      return {
        ...state,
        loading: true,
        moveSuccess: false,
      };
    case GET_ALL_EVALS_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
        allEvaluations: action.payload,
      };

    case GET_ALL_EVALS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_EVALUATION_REQUEST:
      return {
        ...state,
        loading: true,
        deleteSuccess: false,
      };
    case DELETE_EVALUATION_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSuccess: true,
        allEvaluations: action.payload,
      };
    case DELETE_EVALUATION_FAILURE:
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
        error: action.payload,
      };

    case GET_EVALS_FROM_USER_REQUEST:
      return {
        ...state,
        loading: true,
        moveSuccess: false,
      };
    case GET_EVALS_FROM_USER_SUCCESS:
      return {
        ...state,

        loading: false,
        fromEvals: action.payload,
      };
    case GET_EVALS_FROM_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_EVALS_TO_USER_REQUEST:
      return {
        ...state,
        loading: true,
        moveSuccess: false,
      };
    case GET_EVALS_TO_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        toEvals: action.payload,
      };
    case GET_EVALS_TO_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MOVE_EVALUATION_REQUEST:
      return {
        ...state,
        loading: true,
        moveSuccess: false,
      };
    case MOVE_EVALUATION_SUCCESS:
      return {
        ...state,
        loading: false,
        moveSuccess: true,
      };
    case MOVE_EVALUATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MOVE_RESET:
      return {
        ...state,
        moveSuccess: false,
      };

    case ADMIN_QA_RESULTS_REQUEST:
      return {
        ...state,
        savedResults: false,
        loading: true,
      };
    case ADMIN_QA_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        savedResults: true,
        qaAdmin: {
          ...state.qaAdmin,
          adminEvaluationResults: action.payload.data,
        },
      };
    case ADMIN_QA_RESULTS_FAILURE:
      return {
        ...state,
        savedResults: false,
        loading: false,
        error: action.payload,
      };

    case ADMIN_POST_RESULTS_REQUEST:
      return {
        ...state,
        savedResults: false,
        loading: true,
      };

    case ADMIN_POST_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        savedResults: true,
        qaAdmin: {
          ...state.qaAdmin,
          adminEvaluationResults: action.payload.data,
        },
      };
    case ADMIN_POST_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        savedResults: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default QaEvaluationsReducer;

export const getFromUserEvals =
  (fromEval, queue_id) => async (dispatch, getState) => {
    dispatch({
      type: GET_EVALS_FROM_USER_REQUEST,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/qa/evaluations/user/${fromEval}/queue/${queue_id}`,

        config
      );
      dispatch({
        type: GET_EVALS_FROM_USER_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_EVALS_FROM_USER_FAILURE,
        payload: error.message,
      });
    }
  };

export const getToUserEvals =
  (toEvals, queue_id) => async (dispatch, getState) => {
    dispatch({
      type: GET_EVALS_TO_USER_REQUEST,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/qa/evaluations/user/${toEvals}/queue/${queue_id}`,
        config
      );
      dispatch({
        type: GET_EVALS_TO_USER_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_EVALS_TO_USER_FAILURE,
        payload: error.message,
      });
    }
  };

export const moveEvaluations = (values) => async (dispatch, getState) => {
  dispatch({
    type: MOVE_EVALUATION_REQUEST,
  });

  const { AuthDuck } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${AuthDuck.userInfo.token}`,
    },
  };
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/qa/evaluations/move`,
      values,
      config
    );
    dispatch({
      type: MOVE_EVALUATION_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: MOVE_EVALUATION_FAILURE,
      payload: error.message,
    });
  }
};

export const getAllEvaluations = () => async (dispatch, getState) => {
  dispatch({
    type: GET_ALL_EVALS_REQUEST,
  });

  const { AuthDuck } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${AuthDuck.userInfo.token}`,
    },
  };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/evaluations`,

      config
    );
    dispatch({
      type: GET_ALL_EVALS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_EVALS_FAILURE,
      payload: error.message,
    });
  }
};

export const deleteEvaluation =
  ({ id }) =>
  async (dispatch, getState) => {
    dispatch({
      type: DELETE_EVALUATION_REQUEST,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/qa/evaluations/${id}`,

        config
      );
      dispatch({
        type: DELETE_EVALUATION_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_EVALUATION_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAdminEvaluationResults =
  ({ id, eval_id }) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADMIN_QA_RESULTS_REQUEST,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/qa/admin/evaluations/${id}/eval/${eval_id}`,

        config
      );
      dispatch({
        type: ADMIN_QA_RESULTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_QA_RESULTS_FAILURE,
        payload: error.message,
      });
    }
  };

export const AdminAnswerEvaluationQuestions =
  ({ questionAnswers }) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADMIN_POST_RESULTS_REQUEST,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/qa/admin/evaluations`,
        questionAnswers,
        config
      );
      dispatch({
        type: ADMIN_POST_RESULTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_POST_RESULTS_FAILURE,
        payload: error.message,
      });
    }
  };
