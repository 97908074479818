import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getEvalSaleByUniId,
  getEvaluationResults,
} from "./State/AgentEvaluationsDuck";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import withAuth from "../../../../../Hoc/withAuth";
import {
  getEvaluationRecordings,
  getQaEvaluationById,
} from "../QAManagement/State/QaDuck";
import EvaluationHistory from "./Components/EvaluationHistory";
import MediaPlayerComp from "../../../../../Components/Bits/MediaPlayerComp";
import { StageColors } from "./Components/StageColors";

const EvaluationResults = ({ modules, user }) => {
  const { id, uni_id } = useParams();
  const [totalCallTime, setTotalCallTime] = React.useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEvaluationResults(id));
    dispatch(getEvalSaleByUniId(uni_id));
    dispatch(getQaEvaluationById(id));
    dispatch(getEvaluationRecordings(id));
  }, [id, uni_id, dispatch]);

  const { AgentEvaluations } = useSelector((state) => state.QAAgent);

  const { evaluationsResults, error } = AgentEvaluations;

  const Qa = useSelector((state) => state.Qa);
  const { queueEvaluation, evaluationRecordings } = Qa;

  return (
    <>
      <PageHeader
        title="Evaluation Results"
        subheader="View the results of the evaluation"
      />
      {error && (
        <Alert severity="error">Error Getting Evaluation results</Alert>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            onClick={() => navigate("/CDA/QaAgentHome")}
            fullWidth
            variant="outlined"
            color="inherit"
          >
            Back to Evaluation list
          </Button>
        </Grid>
        <Grid item xs={6}>
          <QuestionsAnswersList Evaluation={evaluationsResults} />
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Grid container spacing={2}>
              <CardHeader title="Call Recordings" />
              {evaluationRecordings?.map((recording, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Stack>
                      <MediaPlayerComp
                        recording={recording}
                        totalCallTime={totalCallTime}
                        setTotalCallTime={setTotalCallTime}
                        src={`${process.env.REACT_APP_API_URL}/qa/evaluations/${recording.id}/recording/${recording.id}`}
                      />
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          {queueEvaluation.qa_evaluation_progresses &&
            queueEvaluation.qa_evaluation_progresses.length > 0 && (
              <EvaluationHistory
                progress={queueEvaluation.qa_evaluation_progresses}
              />
            )}{" "}
        </Grid>
      </Grid>
    </>
  );
};

export default withAuth(EvaluationResults);

export const QuestionsAnswersList = ({ Evaluation }) => {
  return (
    <>
      <CardHeader
        title={`Total Score : ${Evaluation?.score?.total} / 10`}
        action={
          <Chip
            color={StageColors(Evaluation?.Evaluation?.stage)}
            label={Evaluation?.Evaluation?.stage}
          />
        }
      />
      <CardContent>
        <List dense>
          <ListItem>
            <ListItemText
              primary="Professionalism"
              secondary={`${Evaluation?.score?.professionalism}%`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Compliance"
              secondary={`${Evaluation?.score?.compliance}%`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Product Knowledge"
              secondary={`${Evaluation?.score?.product_knowledge}%`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="TCF"
              secondary={`${Evaluation?.score?.tcf}%`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Sales Skills"
              secondary={`${Evaluation?.score?.sales_skills}%`}
            />
          </ListItem>
        </List>
      </CardContent>

      <Divider sx={{ my: 4 }} />

      <CardHeader title="Failed Questions" />

      {Evaluation?.score?.autoFailQuestion?.map((question, index) => {
        return (
          <Card key={index}>
            <CardHeader title={question.qa_queues_question.question} />
            <CardContent>
              <RadioGroup
                // onChange={handleAdminAnswerChange}
                value={question?.value}
                disabled
                aria-labelledby="demo-radio-buttons-group-label"
                name={question.id}
              >
                {question.qa_queues_question.qa_queue_question_answers.map(
                  (answer, index) => {
                    return (
                      <React.Fragment key={answer.id}>
                        <FormControlLabel
                          id={answer.id}
                          value={answer.value}
                          control={<Radio />}
                          label={answer.answer}
                        />
                      </React.Fragment>
                    );
                  },
                )}
              </RadioGroup>
              <Stack>
                <Typography variant="h6" gutterBottom color="GrayText">
                  Comment
                </Typography>
                <Typography variant="body1">{question?.comment}</Typography>
              </Stack>
              <Divider sx={{ my: 1 }} />
              <Stack direction="row">
                {/* {answer.value <= 0.5 && answer.qa_queues_question.auto_fail && (
                <Chip variant="outlined" label="Auto Fail" color="error" />
              )} */}
                {question.qa_queues_question.call_back_request && (
                  <Chip
                    variant="outlined"
                    label="call back request"
                    color="warning"
                  />
                )}
                {question.qa_queues_question.auto_fail && (
                  <Chip variant="outlined" label="Auto Fail" color="warning" />
                )}
                {question.qa_queues_question.compliance && (
                  <Chip variant="outlined" label="compliance" />
                )}
                {question.qa_queues_question.product_knowledge && (
                  <Chip variant="outlined" label="product_knowledge" />
                )}
                {question.qa_queues_question.professionalism && (
                  <Chip variant="outlined" label="professionalism" />
                )}
                {question.qa_queues_question.sales_skills && (
                  <Chip variant="outlined" label="sales_skills" />
                )}
                {question.qa_queues_question.tcf && (
                  <Chip variant="outlined" label="tcf" />
                )}
              </Stack>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};
