import PageHeader from "../../../../../../Components/Bits/PageHeader";
import withAuth from "../../../../../../Hoc/withAuth";
// import ViewEvaluationMetrics from "../../Admin/Evaluations/components/viewEvaluationMetrics";
import { Stack } from "@mui/material";
import InboundReEvaluation from "../../../CallCenter/CallCenterAgents/components/InboundReEvaluation";
import InboundDisputeEvaluation from "../../../CallCenter/CallCenterAgents/components/InboundDisputeEvaluation";
import AgentInboundResults from "../../../CallCenter/CallCenterAgents/components/AgentInboundResults";
import { useQuery,useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import axios from "axios";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";

const ViewEvaluationCampaignsManagerById = ({user}) => {


  const { evalID } = useParams();

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };


  const getEvaluationByID = useQuery({
    queryKey: ["getEvaluationByID", evalID],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/user/${evalID}`,
        config
      ),
  });

  const evaluation = getEvaluationByID?.data?.data?.data || [];

 

  const updateEvaluation = useMutation({
    mutationFn: (data) =>
      axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Evaluations/${evalID}`,
        data,
        config
      ),
    onSuccess: () => {
      getEvaluationByID.refetch();
    },
  });
  return (
    <div>
      <PageHeader
        title="QA Inbound User"
        subheader="Manage your QA Inbound evaluations"
        breadcrumbs={[
          { title: "Home", href: "/" },
          { title: "QA Inbound Manager", href: "/CDA/inbound/manager" },
        ]}
      />
      <Stack direction="row" sx={{ justifyContent: "space-between", mb: 2 }}>
        <Stack direction="row">
        <InboundReEvaluation
          user={user}
          updateEvaluation={updateEvaluation}
          evaluation={evaluation}
        />
        <InboundDisputeEvaluation
          user={user}
          updateEvaluation={updateEvaluation}
          evaluation={evaluation}
        />
        </Stack>
      </Stack>

      
      <AlertPopup
        open={updateEvaluation.isSuccess}
        severity="success"
        message="Success"
      />

      <AlertPopup
        open={updateEvaluation.isError}
        severity="error"
        message="Failed"
      />

      <AgentInboundResults />

    </div>
  );
};

export default withAuth(ViewEvaluationCampaignsManagerById);
