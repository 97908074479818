import {
  Button,
  Card,
  CardHeader,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const NotesForms = ({ campaign_notes, setCampaignNotes }) => {
  const [new_campaign_note, setNew_campaign_note] = useState("");
  const [note_type, setNote_type] = useState("");
  const [note_date, setNote_date] = useState(new Date());

  return (
    <>
      <div>
        {campaign_notes.map((note, index) => (
          <Card key={index}>
            <CardHeader
              title={note.note}
              subheader={new Date(note.note_date).toLocaleDateString()}
              action={<Chip label={note.note_type} />}
            />
          </Card>
        ))}
      </div>
      <Stack mt={2} border={1} p={2}>
        <Typography>Add Notes</Typography>
        <TextField
          margin="dense"
          id="note_type"
          label="Note Type"
          variant="outlined"
          value={note_type}
          onChange={(e) => setNote_type(e.target.value)}
        />

        <TextField
          id="note"
          label="Note"
          fullWidth
          margin="normal"
          variant="outlined"
          value={new_campaign_note}
          onChange={(e) => setNew_campaign_note(e.target.value)}
        />
      </Stack>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setCampaignNotes([
              ...campaign_notes,
              {
                id: campaign_notes.length + 1,
                note: new_campaign_note,
                note_type: note_type,
                note_date: note_date,
              },
            ]);
            setNew_campaign_note("");
            setNote_type("");
            setNote_date(new Date());
          }}>
          Add Note
        </Button>
      </div>
    </>
  );
};

export default NotesForms;
