import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  AddUserRole,
  EditUserRole,
} from "../../../Redux/Ducks/AdminUserManagement";
const AddUserType = ({ edit }) => {
  const AdminUsers = useSelector((state) => state.AdminUsers);
  const { User, addUserRoleLoading, addUserRoleError } = AdminUsers;

  const dispatch = useDispatch();

  const [userRole, setUserRole] = useState("");

  const handleAddUserRole = () => {
    edit
      ? dispatch(EditUserRole({ user_id: User.id, type: userRole }))
      : dispatch(AddUserRole({ user_id: User.id, type: userRole }));
  };

  return (
    <>
      <FormControl fullWidth margin="normal">
        <InputLabel id="demo-simple-select-label">Overall Role</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={userRole}
          label="Overall Role"
          onChange={(event) => setUserRole(event.target.value)}>
          <MenuItem value="user">User</MenuItem>
          {/* <MenuItem value="super">Super</MenuItem> */}
          <MenuItem value="admin">Admin</MenuItem>
          {/* <MenuItem value="super_admin">Super Admin</MenuItem> */}
        </Select>
      </FormControl>

      {addUserRoleLoading ? (
        <CircularProgress />
      ) : (
        <Button
          variant="contained"
          onClick={() => {
            handleAddUserRole();
          }}>
          Save
        </Button>
      )}
      <AlertPopup
        severity="error"
        message="Failed to add user"
        open={addUserRoleError}
      />
    </>
  );
};

export default AddUserType;
