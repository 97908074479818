import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AdminModules from "./AdminModules";
import MuiDrawer from "@mui/material/Drawer";
import CDAModules from "./CDAModules";
import withAuth from "../../../Hoc/withAuth";
import RMAModule from "./RMAModule";

const DrawerMain = ({ open, setOpen, user, theme, companyDetails }) => {
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let userType = user?.user_type?.type;

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ backgroundColor: "background.paper" }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <List>
          <Tooltip title="DashBoard">
            <ListItem
              selected={window.location.pathname === "/"}
              button
              onClick={() => navigate("/")}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography>DashBoard</Typography>
              </ListItemText>
            </ListItem>
          </Tooltip>

          {/* 
            RMA 
            RMA 
            RMA 
            RMA 
            RMA  */}

          <Divider />

          {companyDetails?.company === "RMA" && <RMAModule />}

          <Divider />

          {/* 
            CDA
            CDA
            CDA
            CDA
            CDA */}

          {companyDetails?.company === "CDA Solutions" && <CDAModules />}

          <Divider />
          {/* 
            ADMIN
            ADMIN
            ADMIN
            ADMIN
            ADMIN */}

          <Divider />

          {userType === "admin" && <AdminModules userInfo={user} />}
        </List>
      </Drawer>
    </>
  );
};

export default withAuth(DrawerMain);

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "background.default",
  boxSizing: "border-box",
  elevation: 0,
  border: "none",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  boxSizing: "border-box",
  elevation: 0,
  backgroundColor: "background.default",
  border: "none",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,

  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  elevation: 0,
  border: "none",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
