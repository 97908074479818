import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import React from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import PropTypes from "prop-types";
import LastPageIcon from "@mui/icons-material/LastPage";
import styled from "@emotion/styled";
// import { useNavigate } from "react-router-dom";
import { Chip } from "@mui/material";

const ClientsPaginatedTable = (props) => {
  const {
    rows,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    totalCount,
    columns,
    link,
  } = props;

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - rows.length) : 0;

  //const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
      <TableHead sx={{ backgroundColor: "background.paper" }}>
        <TableRow>
          {columns.map((col) => {
            return (
              <TableCell align="left" key={col.id} sx={{ fontWeight: "bold" }}>
                {col.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.map((row) => (
          <TableRow key={row.id}>
            <TableCell component="th" scope="row">
              {row.id}
            </TableCell>
            <TableCell component="th" scope="row">
                {row.name}
            </TableCell>
            <TableCell component="th" scope="row">
              <Button
                onClick={() => link(row.id)}
                color="primary"
                size="small">
                Manage Plans
              </Button>
            </TableCell>
            <StyledTableCell align="left">
                <Chip
                  color={row?.active ? "success" : "default"}
                  label={row?.active ? "Active" : "Disabled"}
                />
            </StyledTableCell>
          </TableRow>
        ))}
        {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={5} align="left" />
          </TableRow>
        )}
      </TableBody>
      <TableFooter>
        <StyledTableRow>
          <TablePagination
            rowsPerPageOptions={[20, 35, 50]}
            colSpan={6}
            count={totalCount || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </StyledTableRow>
      </TableFooter>
    </Table>
    </TableContainer>
  );
};

export default ClientsPaginatedTable;

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

ClientsPaginatedTable.propTypes = {
  props: PropTypes.shape({
    rows: PropTypes.array,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    totalCount: PropTypes.number,
    columns: PropTypes.array,
    link: PropTypes.func,
  }),
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.background.paper,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

// const yearMonthDay = dateTimeStamp => {
//     if(dateTimeStamp != null) {
//         const date = new Date(dateTimeStamp)
//         const year = date.getFullYear()
//         const month = date.getMonth() + 1
//         const day = date.getDate()

//      return [year,month,day].join('-')

//     } else {
        
//      return ["0000","00","00"].join('-')
//     }
// }