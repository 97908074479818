import { Alert, Button, LinearProgress, Stack } from "@mui/material";
import React, { useEffect } from "react";
import CreateQaQueues from "./Components/Queues/CreateQaQueues";
import { useDispatch, useSelector } from "react-redux";
import { getQaQueuesList } from "./State/QaDuck";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import DisableQaQueue from "./Components/Queues/DisableQaQueue";
import EnableQaQueue from "./Components/Queues/EnableQaQueue";
import { useNavigate } from "react-router-dom";

const QaQueues = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getQaQueuesList());
  }, [dispatch]);

  const Qa = useSelector((state) => state.Qa);
  const { qaQueuesList, qaQueuesListLoading, qaQueuesListError } = Qa;

  const inactiveQ = qaQueuesList?.filter((q) => q.active === false);

  const activeQ = qaQueuesList?.filter((q) => q.active === true);

  const [viewInactive, setViewInactive] = React.useState(false);

  const columns = [
    {
      field: "View Queue",
      headerName: "View Qa Queue",
      width: 130,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() =>
              navigate(`/CDA/QaQueuesSetup/${params.row.id}/qaQueue`)
            }
            color="primary"
          >
            Manage
          </Button>
        );
      },
    },
    { field: "id", headerName: "ID", width: 70 },
    { field: "queue_name", headerName: "Queue Name", width: 130 },
    { field: "queue_type", headerName: "Queue Type", width: 130 },
    {
      field: "required",
      headerName: "Qa Validation Percentage",
      width: 130,
    },
    { field: "minEvaluation", headerName: "minEvaluation", width: 130 },
    { field: "maxEvaluation", headerName: "maxEvaluation", width: 130 },
    { field: "score_card", headerName: "Score Card", width: 130 },
    {
      field: "Disabled/Enable",
      headerName: "Disable/enable",
      width: 130,
      renderCell: (params) => {
        return (
          <>
            {params.row.active ? (
              <DisableQaQueue Q={params.row} />
            ) : (
              <EnableQaQueue Q={params.row} />
            )}
          </>
        );
      },
    },
  ];

  if (qaQueuesListError) {
    return <Alert>Error</Alert>;
  }

  if (qaQueuesListLoading) {
    return <LinearProgress />;
  }

  return (
    <Stack spacing={2}>
      <CreateQaQueues />

      <DataGridPremium rows={activeQ} columns={columns} />

      <Button
        onClick={() => {
          setViewInactive(!viewInactive);
        }}>
        {viewInactive ? "Hide Disabled Queues" : "View Disabled Queues"}
      </Button>

      {viewInactive && <DataGridPremium rows={inactiveQ} columns={columns} />}

      {/* 
      <Button onClick={() => setOpen(!open)}>
        {open ? "hide Disabled Queues" : "view Disabled Queues"}
      </Button>

      {open && <QaQueuesListTable list={inactiveQ} />}

      <AlertPopup
        open={qaQueuesListSuccess}
        message="Success"
        severity="success"
      /> */}
    </Stack>
  );
};

export default QaQueues;
