import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import SelectTeamUser from "./Components/Teams/SelectTeamUser";
import { useSelector, useDispatch } from "react-redux";
import {
  MOVE_RESET,
  getFromUserEvals,
  getToUserEvals,
  moveEvaluations,
} from "./State/QaEvaluations";
import { getQaQueueTeams } from "./State/QaDuck";
import { useParams } from "react-router-dom";
import { Card, Chip, Divider, Stack, Typography } from "@mui/material";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import { StageColors } from "../QaAgent/Components/StageColors";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const MoveEvaluationsAdmin = ({ teams }) => {
  const [fromUser, setFromUser] = React.useState("");
  const [toUser, setToUser] = React.useState("");

  const [needsSaving, setNeedsSaving] = React.useState(false);

  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: MOVE_RESET });
    dispatch(getQaQueueTeams(id));
  }, [dispatch, id]);

  const Qa = useSelector((state) => state.Qa);
  const { qaQueueTeams } = Qa;

  useEffect(() => {
    if (fromUser) {
      dispatch(getFromUserEvals(fromUser, id));
    }
  }, [dispatch, fromUser, id]);

  useEffect(() => {
    if (toUser) {
      dispatch(getToUserEvals(toUser, id));
    }
  }, [dispatch, toUser, id]);

  const QAEvals = useSelector((state) => state.QAEvals);

  const { fromEvals, toEvals, moveSuccess } = QAEvals;

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [isMoveDisabled, setIsMoveDisabled] = React.useState(false);

  useEffect(() => {
    // only include "New" evaluations
    const filterLeft = fromEvals.filter((item) =>
      ["New", "Busy", "Re-evaluate"].includes(item.stage),
    );

    setLeft(filterLeft);
  }, [fromEvals]);

  useEffect(() => {
    // only show "Incomplete" evaluations
    // const filterIncomplete = toEvals.filter(
    //   (item) =>
    //     !["Complete", "Cancelled", "Bypass", "Fail"].includes(item.stage),
    // );
    const filterIncomplete = toEvals.filter((item) =>
      ["New", "Busy", "Re-evaluate"].includes(item.stage),
    );

    setRight(filterIncomplete);
  }, [toEvals]);

  const leftChecked = intersection(checked, left);
  // const rightChecked = intersection(checked, right);
  // console.log(leftChecked);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setIsMoveDisabled(true);
    // console.log("leftChecked", leftChecked);
    setRight(right.concat(leftChecked));
    // console.log("right", right);
    setLeft(not(left, leftChecked));
    // console.log("left", left);
    setChecked(not(checked, leftChecked));
    setNeedsSaving(true);
  };

  const SaveChanges = () => {
    needsSaving && setNeedsSaving(false);

    let toUpdate = right.map((item) => {
      return {
        id: item.id,
        user_id: toUser,
      };
    });

    // console.log("toUpdate", toUpdate);

    dispatch(moveEvaluations(toUpdate));
    dispatch(getFromUserEvals(fromUser, id));
    dispatch(getToUserEvals(toUser, id));

    // Refresh the page after saving changes
    // Show the popup and then refresh the page after a delay

    setTimeout(() => {
      window.location.reload();
    }, 8000); // 3 seconds delay
  };

  const customList = (items) => {
    return (
      <Paper>
        <List sx={{ height: "100%" }} dense component="div" role="list">
          {items?.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <React.Fragment key={value.id}>
                <Divider />
                <ListItem role="listitem" button onClick={handleToggle(value)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={value.uni_id}
                    secondary={
                      <Stack direction="row" justifyContent="space-between">
                        <Chip
                          variant="outlined"
                          label={`Eval Status ${value?.stage}`}
                          color={StageColors(value?.stage)}
                        />
                        <Chip
                          variant="outlined"
                          label={`Callbi Status ${value?.callbi_result?.status}`}
                          color={StageColors(value?.callbi_result?.status)}
                        />
                      </Stack>
                    }
                  />
                  <Divider />
                </ListItem>
              </React.Fragment>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    );
  };

  return (
    <>
      <AlertPopup
        open={moveSuccess}
        message="Evaluations moved successfully"
        severity="success"
      />
      <Card>
        <Stack
          sx={{ border: 1, p: 2 }}
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <Typography gutterBottom variant="h6">
            Move Evaluations
          </Typography>
        </Stack>
      </Card>
      <Stack sx={{ my: 2 }}>
        {needsSaving && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => SaveChanges()}
            fullWidth
          >
            Save
          </Button>
        )}
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <SelectTeamUser
            user={fromUser}
            setUser={setFromUser}
            teams={qaQueueTeams}
          />
          {customList(left)}
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="contained"
              onClick={handleCheckedRight}
              disabled={
                isMoveDisabled ||
                leftChecked.length === 0 ||
                (fromUser && toUser ? false : true)
              }
              aria-label="move selected right"
            >
              &gt;
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <SelectTeamUser
            user={toUser}
            setUser={setToUser}
            teams={qaQueueTeams}
          />
          {customList(right)}
        </Grid>
      </Grid>
    </>
  );
};

export default MoveEvaluationsAdmin;
