import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";
import { DialogContent, Stack } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import SelectWrapper from "../../../../../../../Components/FormComponents/Select";
import { createQaQueue, RESET_QA_QUEUES } from "../../State/QaDuck";
import ScoreCardSelector from "../../../../../../Admin/components/CallbiSelector";

export default function CreateQaQueues() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({ type: RESET_QA_QUEUES });
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button color="secondary" variant="contained" onClick={handleClickOpen}>
        Create New Queue
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Create New Queue</DialogTitle>

        <Formik
          initialValues={{
            queue_name: "",
            queue_type: "",
            score_card: "",
            required: 70,
            minEvaluation: 2,
            maxEvaluation: 6,
            updated_reason: "New Queue Created",
            active: true,
          }}
          validationSchema={Yup.object({
            queue_name: Yup.string().required("Name is required"),
            queue_type: Yup.string().required("Queue Type is required"),
            // score_card: Yup.string().required("Score Card is required"),
            required: Yup.number()
              .max(100)
              .required("Verification % is required"),
            active: Yup.boolean().required("Active is required"),
            maxEvaluation: Yup.number().max(10),
            minEvaluation: Yup.number().max(2),
          })}
          onSubmit={(values) => {
            dispatch(createQaQueue(values));
            setTimeout(() => {
              handleClose();
            }, 1000);
          }}
        >
          {() => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <TextfieldWrapper label="Queue Name" name="queue_name" />
                  <SelectWrapper
                    label="Queue Type"
                    name="queue_type"
                    options={[
                      { value: "sale", label: "Sale" },
                      { value: "call", label: "Call" },
                      { value: "client", label: "Client" },
                      { value: "services", label: "Services" },
                    ]}
                  />
                  <ScoreCardSelector name="score_card" />

                  <TextfieldWrapper
                    label="Total Verification % per of campaign"
                    name="required"
                    type="number"
                  />

                  <TextfieldWrapper
                    label="Minimum Evaluations per Agent"
                    name="minEvaluation"
                    type="number"
                  />
                  <TextfieldWrapper
                    label="Maximum Evaluations per Agent"
                    name="maxEvaluation"
                    type="number"
                  />
                </Stack>
              </DialogContent>
              <Button fullWidth variant="contained" type="submit">
                Save
              </Button>
              <Button fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
