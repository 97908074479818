import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  Grid,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Chip,
  IconButton,
  Collapse,
  Box,
  Stack,
} from "@mui/material";
import { useParams } from "react-router-dom";
import withAuth from "../../../../../../../Hoc/withAuth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ReactAudioPlayer from "react-audio-player";

const ResultTable = ({ user }) => {
  const { id, evalID } = useParams();
  const [open, setOpen] = React.useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const { data: evaluationResults } = useQuery({
    queryKey: ["getEvaluationResults", evalID],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/results/${evalID}/results/one`,
        config
      );
      return response.data;
    },
    onError: (error) =>
      console.error("Error fetching evaluation results:", error),
  });

  const evals = Array.isArray(evaluationResults?.data?.result_evaluation)
    ? evaluationResults.data.result_evaluation
    : [];

  const otherQuestions = evals?.filter(
    (question) => question.result_question.question_type === "Yes/No"
  );

  const TextInputQuestions = evals?.filter(
    (question) => question.result_question.question_type === "Text Input"
  );

  const getActiveQuestionGroups = useQuery({
    queryKey: ["getActiveQuestionGroups", id, evalID],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/group/${evalID}`,
        config
      ),
  });

  const Evaluationsgroups = getActiveQuestionGroups.data?.data?.data.filter(
    (group) => group.group_type === "Score"
  );

  const calculateTotalScoreForGroup = (group) => {
    const scoreQuestionsInGroup =
      evaluationResults?.data?.result_evaluation.filter(
        (question) => question?.result_question.question_type === "Score"
      );

    let totalScore = 0;
    let totalWeight = 0;

    group?.question?.forEach((question) => {
      const result = scoreQuestionsInGroup?.find(
        (r) => r.question_id === question.id
      );
      const answerValue = parseFloat(result?.answer_value) || 0;
      const weight = question.value || 1;
      const TotalValues = answerValue * 0.2 * weight;

      totalScore += TotalValues;
      totalWeight += weight;
    });

    return {
      totalScore: totalScore.toFixed(2),
      totalWeight: totalWeight.toFixed(2),
    };
  };

  // Score Pagination
  const [groupPagination, setGroupPagination] = React.useState(
    Evaluationsgroups?.map(() => ({ page: 0, rowsPerPage: 5 })) || []
  );

  const handleChangePage = (event, newPage, groupIndex) => {
    setGroupPagination((prev) =>
      prev.map((pagination, index) =>
        index === groupIndex ? { ...pagination, page: newPage } : pagination
      )
    );
  };

  const handleChangeRowsPerPage = (event, groupIndex) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setGroupPagination((prev) =>
      prev.map((pagination, index) =>
        index === groupIndex
          ? { ...pagination, rowsPerPage: newRowsPerPage, page: 0 }
          : pagination
      )
    );
  };

  // Yes/no Pagination 
  const [otherQuestionsPagination, setOtherQuestionsPagination] =
    React.useState({
      page: 0,
      rowsPerPage: 5,
    });

  const handleOtherQuestionsPageChange = (event, newPage) => {
    setOtherQuestionsPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleOtherQuestionsRowsPerPageChange = (event) => {
    setOtherQuestionsPagination((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    }));
  };

  //textinput pagination
  const [textQuestionsPagination, setTextQuestionsPagination] =
    React.useState({
      page: 0,
      rowsPerPage: 5,
    });

  const handleTextQuestionsPageChange = (event, newPage) => {
    setTextQuestionsPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleTextQuestionsRowsPerPageChange = (event) => {
    setTextQuestionsPagination((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    }));
  };

  const getCallRecordings = useQuery([`Recordings${evalID}`], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/QAInbound/admin/${evalID}/recording`
    );
  });

  const Recordings = getCallRecordings.data?.data?.data || [];

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <Stack spacing={2}>
            {Recordings.map((recording, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <ReactAudioPlayer
                  style={{ width: "800px" }}
                  src={`${process.env.REACT_APP_API_URL}/QAInbound/admin/${recording.storage_id}/recording/download`}
                  controls
                  onPlay={() => {
                    const interval = setInterval(() => {
                      // updateListeningTime(recording.storage_id, 1);
                    }, 1000);
                    recording.intervalId = interval;
                  }}
                  onPause={() => {
                    clearInterval(recording.intervalId);
                  }}
                  onEnded={() => {
                    clearInterval(recording.intervalId);
                  }}
                />
                <Typography>{recording.name}</Typography>
              </Box>
            ))}
          </Stack>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <CardHeader title="Evaluation Results" />
          <CardContent>
            <Typography variant="h5">Total Score:</Typography>
            {(() => {
              const totalScoresSum = Evaluationsgroups?.reduce((acc, group) => {
                const { totalScore } = calculateTotalScoreForGroup(group);
                return acc + parseFloat(totalScore) || 0;
              }, 0);

              const totalWeightSum = Evaluationsgroups?.reduce((acc, group) => {
                const { totalWeight } = calculateTotalScoreForGroup(group);
                return acc + parseFloat(totalWeight) || 0;
              }, 0);

              const Score = totalScoresSum;
              const Weight = totalWeightSum;
              const Percent = (Score / Weight) * 100;

              return (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography fontSize={20} color="primary">
                    {Score} / {Weight}
                  </Typography>

                  <Typography
                    fontSize={20}
                    color={Percent >= 50 ? "#4ef037" : "red"}
                  >
                    {Percent.toFixed(0)}%
                  </Typography>
                </Stack>
              );
            })()}

            <Divider style={{ margin: "10px 0" }} />
            {/* Display Question Groups and their Totals */}
            <Grid container spacing={1}>
              {Evaluationsgroups?.map((group, index) => {
                const { totalScore, totalWeight } =
                  calculateTotalScoreForGroup(group);

                return (
                  <Grid item xs={12} key={index}>
                    {index > 0 && <Divider style={{ margin: "5px 0" }} />}

                    <Grid container alignItems="center">
                      <Grid item xs={5}>
                        <Typography variant="body1">{group.name}</Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Divider orientation="vertical" flexItem />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body1" align="right">
                          {totalScore} / {totalWeight}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Divider style={{ margin: "10px 0" }} />
          </CardContent>
        </CardContent>

        {/* Table for Score Questions */}
        {Evaluationsgroups?.length > 0 && (
          <CardContent>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              {Evaluationsgroups?.map((group, groupIndex) => {
                const { page, rowsPerPage } = groupPagination[groupIndex] || {
                  page: 0,
                  rowsPerPage: 5,
                };

                return (
                  <TableContainer
                    key={groupIndex}
                    sx={{ maxHeight: 440, mb: 2 }}
                  >
                    <Table stickyHeader aria-label="evaluation table">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>{group.name}</TableCell>
                          <TableCell align="right">Score</TableCell>
                          <TableCell align="right">Percentage (%)</TableCell>
                          <TableCell align="right">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {group.question
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((q, questionIndex) => {
                            const resultQuestion = q.result_question[0] || {};
                            const answerValue =
                              resultQuestion.answer_value || 0;
                            const questionValue = q.value || 1;
                            const Total = answerValue * 0.2 * questionValue;
                            const percentage = (
                              (Total / questionValue) *
                              100
                            ).toFixed(0);
                            const uniqueKey = `${groupIndex}-${questionIndex}`;
                            const isOpen = open === uniqueKey;

                            return (
                              <React.Fragment key={q.id}>
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  onClick={() =>
                                    setOpen(isOpen ? null : uniqueKey)
                                  }
                                >
                                  <TableCell>
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                    >
                                      {isOpen ? (
                                        <KeyboardArrowUpIcon />
                                      ) : (
                                        <KeyboardArrowDownIcon />
                                      )}
                                    </IconButton>
                                  </TableCell>
                                  <TableCell>{q.name}</TableCell>
                                  <TableCell align="right">
                                    {Total.toFixed(0)} / {questionValue}
                                  </TableCell>
                                  <TableCell align="right">
                                    {percentage}%
                                  </TableCell>
                                  <TableCell align="right">
                                    <Chip
                                      variant="outlined"
                                      label={percentage < 50 ? "Fail" : "Pass"}
                                      color={
                                        percentage < 50 ? "error" : "success"
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    colSpan={5}
                                    style={{
                                      paddingBottom: 0,
                                      paddingTop: 0,
                                    }}
                                  >
                                    <Collapse
                                      in={isOpen}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box margin={1}>
                                        <Typography
                                          fontSize={17}
                                          fontWeight={5}
                                          color="text.secondary"
                                        >
                                          Question Comments
                                        </Typography>
                                        <Typography>
                                          {resultQuestion.comments ||
                                            "No comments available."}
                                        </Typography>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={group.question.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={(event, newPage) =>
                        handleChangePage(event, newPage, groupIndex)
                      }
                      onRowsPerPageChange={(event) =>
                        handleChangeRowsPerPage(event, groupIndex)
                      }
                    />
                  </TableContainer>
                );
              })}
            </Paper>
          </CardContent>
        )}

        {/* Table for Other Questions */}
        {otherQuestions?.length > 0 && (
          <CardContent>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="evaluation table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Yes/No Question</TableCell>
                      <TableCell align="right">Answer</TableCell>
                      <TableCell align="right">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {otherQuestions
                      .slice(
                        otherQuestionsPagination.page *
                          otherQuestionsPagination.rowsPerPage,
                        otherQuestionsPagination.page *
                          otherQuestionsPagination.rowsPerPage +
                          otherQuestionsPagination.rowsPerPage
                      )
                      .map((q, index) => {
                        const uniqueKey = `other-${index}`;
                        const isOpen = open === uniqueKey;
                        const response = q.answer_value || "N/A";
                        const status =
                          q.answer_value === "Yes"
                            ? "Pass"
                            : q.answer_value === "No"
                            ? "Fail"
                            : null;
                        const chipColor =
                          status === "Pass"
                            ? "secondary"
                            : status === "Fail"
                            ? "error"
                            : "default";

                        return (
                          <React.Fragment key={q.id}>
                            <TableRow
                              hover
                              onClick={() => setOpen(isOpen ? null : uniqueKey)}
                            >
                              <TableCell>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                >
                                  {isOpen ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell>{q.result_question.name}</TableCell>
                              <TableCell align="right">
                                {response || "N/A"}
                              </TableCell>
                              <TableCell align="right">
                                <Chip
                                  label={status}
                                  color={chipColor}
                                  variant="outlined"
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                colSpan={5}
                                style={{
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                }}
                              >
                                <Collapse
                                  in={isOpen}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box margin={1}>
                                    <Typography
                                      fontSize={17}
                                      fontWeight={5}
                                      color="text.secondary"
                                    >
                                      Question Comments
                                    </Typography>
                                    <Typography>
                                      {q.comments || "No comments available."}
                                    </Typography>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={otherQuestions.length}
                rowsPerPage={otherQuestionsPagination.rowsPerPage}
                page={otherQuestionsPagination.page}
                onPageChange={handleOtherQuestionsPageChange}
                onRowsPerPageChange={handleOtherQuestionsRowsPerPageChange}
              />
            </Paper>
          </CardContent>
        )}

        {/* Table for Text Input Questions */}
        {TextInputQuestions?.length > 0 && (
          <CardContent>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="evaluation table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Text Input Questions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TextInputQuestions.slice(
                      textQuestionsPagination.page *
                      textQuestionsPagination.rowsPerPage,
                      textQuestionsPagination.page *
                      textQuestionsPagination.rowsPerPage +
                      textQuestionsPagination.rowsPerPage
                    ).map((q) => {
                      const response = q.answer_value || "N/A";
                      return (
                        <TableRow key={q.id}>
                          <TableCell>
                            <Typography variant="subtitle1" fontWeight="bold">
                              {q.result_question.name}
                            </Typography>
                            <Typography variant="body2">{response}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={TextInputQuestions.length} 
                rowsPerPage={textQuestionsPagination.rowsPerPage}
                page={textQuestionsPagination.page}
                onPageChange={handleTextQuestionsPageChange}
                onRowsPerPageChange={handleTextQuestionsRowsPerPageChange}
              />
            </Paper>
          </CardContent>
        )}
      </Card>
    </Stack>
  );
};

export default withAuth(ResultTable);
