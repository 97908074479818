import {
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Link,
} from "@mui/material";
import React from "react";
import UserTypeChips from "../../../Components/Bits/UserTypeChips";

const WelcomeCard = ({ user, companyDetails }) => {
  return (
    <Card variant="outlined" sx={{ mb: 3 }}>
      <CardHeader
        title={`Welcome ${user.name}`}
        subheader={`You're working in ${companyDetails?.company} as a ${user?.userType}`}
        action={<UserTypeChips user={user} />}
      />

      <CardContent>
        <Breadcrumbs aria-label="breadcrumb">
          <Link href="/" color="inherit" underline="none" variant="body2">
            Home
          </Link>
        </Breadcrumbs>
      </CardContent>
    </Card>
  );
};

export default WelcomeCard;
