import { Alert, Chip, Button, Stack, Typography } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import withAuth from "../../../../../../Hoc/withAuth";
import { useNavigate } from "react-router-dom";

const AgentInboundEvaluations = ({ user }) => {
  const userID = user.id;

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const [viewCompleted, setViewCompleted] = useState(false);

  const [completedEvals, setCompletedEvals] = useState([]);
  const [otherEvals, setOtherEvals] = useState([]);

  const getEvaluationByID = useQuery({
    queryKey: ["getEvaluationByID"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/evaluations/${userID}`,
        config
      ),
  });

  useEffect(() => {
    if (getEvaluationByID?.data?.data?.data) {
      const completedEvals = [];
      const otherEvals = [];

      getEvaluationByID?.data?.data?.data?.forEach((evaluation) => {
        if (
          evaluation.status === "Completed" ||
          (evaluation?.history?.length > 0 &&
            evaluation?.status === "Done")
        ) {
          completedEvals.push(evaluation);
        } else {
          otherEvals.push(evaluation);
        }
      });

      setCompletedEvals(completedEvals);
      setOtherEvals(otherEvals);
    }
  }, [getEvaluationByID?.data?.data?.data]);

  const navigate = useNavigate();

  let columns = [
    {
      field: "View",
      headerName: "View",
      width: 130,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              navigate(

                `/CDA/AgentHome/AgentInboundEvaluations/${params.row.campaign_id}/${params.row.id}`
              );
            }}
          >
            View
          </Button>
        );
      },
    },
    { field: "unique_id", headerName: "Uni ID", width: 200 },
    {
      field: "campaignname",
      headerName: "QA Campaign Name",
      width: 150,
      valueGetter: (params) => {
        return params.row.campaign?.name;
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.status}
            color={
              params.row.status === "Completed"
                ? "secondary"
                : params.row.status === "Busy"
                ? "warning"
                : params.row.status === "New" ||
                  params.row.status === "Re-Evaluate"
                ? "primary"
                : params.row.status === "Failed"
                ? "error"
                : params.row.status === "Done"
                ? "info"
                : "info"
            }
          />
        );
      },
    },

    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 200,
      valueGetter: (params) => {
        return new Date(params.row.updatedAt).toLocaleString();
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      valueGetter: (params) => {
        return new Date(params.row.createdAt).toLocaleString();
      },
    },
  ];

  return (
    <>
      <Typography variant="caption" align="right">
        Completed evaluation is Below this list
      </Typography>
      <Stack sx={{ height: "90vh" }}>
        {otherEvals && otherEvals.length > 0 ? (
          <DataGridPremium rows={otherEvals} columns={columns} />
        ) : (
          <Alert severity="info">No Evaluations Found</Alert>
        )}
      </Stack>

      <Stack sx={{ my: 2 }}>
        <Button
          onClick={() => {
            setViewCompleted(!viewCompleted);
          }}
        >
          {viewCompleted
            ? "View Other Evaluations"
            : "View Completed Evaluations"}
        </Button>
      </Stack>

      {viewCompleted && (
        <Stack sx={{ height: "90vh" }}>
          {completedEvals && completedEvals.length > 0 ? (
            <DataGridPremium rows={completedEvals} columns={columns} />
          ) : (
            <Alert severity="info">No Evaluations Found</Alert>
          )}
        </Stack>
      )}
    </>
  );
};

export default withAuth(AgentInboundEvaluations);
