import axios from "axios";

const initialState = {
  allSales: [],
  loading: false,
  error: null,
};

const GET_ALL_SALES = "GET_ALL_SALES";
const GET_ALL_SALES_SUCCESS = "GET_ALL_SALES_SUCCESS";
const GET_ALL_SALES_FAIL = "GET_ALL_SALES_FAIL";

const GET_SALE_BY_ID = "GET_SALE_BY_ID";
const GET_SALE_BY_ID_SUCCESS = "GET_SALE_BY_ID_SUCCESS";
const GET_SALE_BY_ID_FAIL = "GET_SALE_BY_ID_FAIL";

const SalesDuck = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SALES:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SALES_SUCCESS:
      return {
        ...state,
        loading: false,
        allSales: action.payload,
      };
    case GET_ALL_SALES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SALE_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_SALE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        sale: action.payload,
      };
    case GET_SALE_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default SalesDuck;

export const getAllSales =
  ({ rowsPerPage, page }) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_ALL_SALES,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/salesman/sales?pageNumber=${page}&limit=${rowsPerPage}`,

        config
      );
      dispatch({
        type: GET_ALL_SALES_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ALL_SALES_FAIL,
        payload: err.response,
      });
    }
  };
