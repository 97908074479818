import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Form, Formik, useField, useFormikContext } from "formik";
import { useCallback, useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";
import SelectWrapper from "../../../../../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";
import {
  availableDispositionsOptions,
  getCampaignDispositions,
  getCampaignTeams,
} from "../../../CampaignsManagement/State/CampaingsDuck";
import { RESET_LEAD_SALE_EVAL } from "../../../QaAgent/State/AgentEvaluationsDuck";
import { getQaTeams } from "../../State/QaTeamsDuck";
import {
  CreateQueueEvaluation,
  getQaQueueCampaigns,
  getQaQueueTeams,
  getQaQueuesList,
} from "../../State/QaDuck";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../../../../../../Hoc/withAuth";

const CreateEvaluationsFromLeadSale = ({ user }) => {
  const [open, setOpen] = React.useState(false);

  const [qaQueueId, setQaQueueId] = React.useState("");

  const [selectedSale, setSelectedSale] = React.useState("");
  const [selectedCampaign, setSelectedCampaign] = React.useState("");

  const [LeadId, setLeadId] = React.useState("");

  const GetLeadById = useQuery(
    [`GetLeadByID${LeadId}`],
    () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/salesman/leads/${LeadId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    },
    {
      enabled: !!LeadId,
      onSuccess: (response) => {
        setSelectedCampaign(response?.data?.data?.cc_campaign?.id);
      },
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  //   useEffect(() => {
  //     if (idActiveSale && activeUniqueId) {
  //       dispatch(getEvalSaleByUniId(activeUniqueId));
  //       setIdActiveSale(false);
  //     }
  //   }, [idActiveSale, activeUniqueId, dispatch]);

  useEffect(() => {
    dispatch({ type: RESET_LEAD_SALE_EVAL });
    dispatch(getQaTeams());

    dispatch(getQaQueuesList());
  }, [dispatch]);

  const Qa = useSelector((state) => state.Qa);
  const { qaQueueTeams, CreateQEvalSuccess, qaQueuesList } = Qa;

  const { AgentEvaluations } = useSelector((state) => state.QAAgent);
  const { leadAndSale } = AgentEvaluations;

  let activeQaTeams = qaQueueTeams.filter((team) => team.active === true);

  useEffect(
    () => setSelectedCampaign(leadAndSale?.cm_lead?.cc_campaign?.id || ""),
    [leadAndSale]
  );

  const GetCampaignDispo = useCallback(() => {
    if (selectedCampaign) {
      dispatch(getCampaignDispositions(selectedCampaign));
      dispatch(availableDispositionsOptions(selectedCampaign));
      dispatch(getCampaignTeams(selectedCampaign));
    }
  }, [dispatch, selectedCampaign]);

  useEffect(() => {
    GetCampaignDispo();
  }, [GetCampaignDispo]);

  const teamMembers = [];
  activeQaTeams?.forEach((team) => {
    return (
      team?.qa_team?.qa_teams_users?.length > 0 &&
      teamMembers.push(...team?.qa_team?.qa_teams_users)
    );
  });

  const Campaigns = useSelector((state) => state.Campaigns);

  const { availableDispositions: disList, campaignTeams } = Campaigns;

  let { cc_disposition_lists } = disList;

  const [campaignTeamsList, setCampaignTeamsList] = React.useState([]);

  useEffect(() => {
    if (campaignTeams && campaignTeams?.length > 0) {
      setCampaignTeamsList(
        campaignTeams.map((team) => team?.cc_team?.cc_teams_users)
      );
    }
  }, [campaignTeams]);

  // Get Dispositions out of list

  const availableDispositions = [];

  cc_disposition_lists &&
    cc_disposition_lists?.map((cc_disposition_lists) => {
      return availableDispositions.push(
        ...cc_disposition_lists?.cc_dispositions
      );
    });

  const findDisposition = (id) => {
    return availableDispositions?.find((disposition) => disposition?.id === id);
  };

  return (
    <div>
      <div>
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>
          Create New Evaluation
        </Button>
        <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
          <DialogTitle>Create New Evaluation</DialogTitle>
          <DialogContent>
            <AlertPopup
              open={CreateQEvalSuccess}
              message="Evaluation Created Successfully"
              severity="success"
            />

            <TextField
              sx={{
                mt: 2,
              }}
              fullWidth
              label="Enter Lead ID"
              variant="outlined"
              value={LeadId}
              onChange={(e) => setLeadId(e.target.value)}
            />

            {GetLeadById?.data?.data?.data && (
              <Stack sx={{ mt: 2 }}>
                <Card>
                  <CardHeader
                    title={GetLeadById?.data?.data?.data?.cc_campaign?.name}
                  />
                  <CardContent>
                    {GetLeadById?.data?.data?.data?.cm_sales?.length > 0 ? (
                      <>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Sale for evaluation
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedSale}
                            label="Select Sale for evaluation"
                            onChange={(event) =>
                              setSelectedSale(event.target.value)
                            }>
                            {GetLeadById?.data?.data?.data?.cm_sales?.map(
                              (sale) => {
                                return (
                                  <MenuItem key={sale.id} value={sale}>
                                    {sale.reference_no}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </>
                    ) : (
                      <Alert severity="error">No Sales Found</Alert>
                    )}
                  </CardContent>
                </Card>
              </Stack>
            )}

            {selectedSale && (
              <Formik
                initialValues={{
                  sale_id: selectedSale.id || "",
                  disposition_id:
                    (selectedSale?.cm_sales_dispositions?.length > 0 &&
                      selectedSale?.cm_sales_dispositions[0]?.disposition_id) ||
                    "",
                  disposition_detail_id:
                    (selectedSale?.cm_sales_dispositions?.length > 0 &&
                      selectedSale?.cm_sales_dispositions[0]
                        ?.disposition_detail_id) ||
                    "",
                  queue_id: qaQueueId || "",
                  user_id: "",
                  agent_id: selectedSale?.User?.id || "",
                  uni_id: selectedSale?.reference_no || "",
                  stage: "",
                  note1: "",
                  note2: "",
                  note3: "",
                  note4: "",
                  note5: "",
                  updated_reason: "Manually Created Evaluation",
                }}
                enableReinitialize={true}
                validationSchema={Yup.object({
                  disposition_id: Yup.string().required("Required"),
                  queue_id: Yup.string().required("Required"),
                  user_id: Yup.string().required("Required"),
                  agent_id: Yup.string().required("Required"),
                  stage: Yup.string().required("Required"),
                })}
                onSubmit={(values) => {
                  values.disposition_detail_id =
                    values.disposition_detail_id !== ""
                      ? values.disposition_detail_id
                      : null;
                  dispatch(CreateQueueEvaluation(values.queue_id, values));
                  setTimeout(() => {
                    setOpen(false);
                  }, 1000);
                }}>
                {({ values, setFieldValue, errors }) => {
                  return (
                    <Form>
                      <Stack mt={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {campaignTeamsList && campaignTeamsList[0] && (
                              <SelectWrapper
                                name="agent_id"
                                label="Agent (Person Being Evaluated)"
                                fullWidth
                                variant="outlined"
                                options={campaignTeamsList[0]?.map((team) => {
                                  return {
                                    value: team.user_id,
                                    label: team?.User?.name,
                                  };
                                })}
                              />
                            )}
                          </Grid>

                          <Grid item xs={6}>
                            <SelectDisposition
                              availableDispositions={availableDispositions}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            {findDisposition(values?.disposition_id)
                              ?.cc_disposition_details &&
                              findDisposition(values?.disposition_id)
                                ?.cc_disposition_details?.length > 0 && (
                                <SelectDispositionDetail
                                  availableDispositionsDetails={
                                    findDisposition(values?.disposition_id)
                                      ?.cc_disposition_details
                                  }
                                />
                              )}
                          </Grid>

                          {/* <Grid item xs={6}>
                            <Stack spacing={2}>
                              <FormControl fullWidth>
                                <InputLabel id="select_campaign_label">
                                  Select Sale Campaign
                                </InputLabel>
                                <Select
                                  labelId="select_campaign_label"
                                  id="select_campaign"
                                  value={selectedCampaign}
                                  label="Select Sale Campaign"
                                  onChange={(event) => {
                                    setSelectedCampaign(event.target.value);
                                  }}>
                                  {linkedCampaigns?.map((campaign) => {
                                    return (
                                      <MenuItem
                                        key={campaign.id}
                                        value={campaign.campaign_id}>
                                        {campaign.cc_campaign.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Stack>
                          </Grid> */}

                          <Grid item xs={12}>
                            {qaQueuesList && (
                              <SelectQaQueueTeam
                                {...{ qaQueueId, setQaQueueId, qaQueuesList }}
                              />
                            )}
                          </Grid>

                          <Grid item xs={12}>
                            <Stack spacing={2}>
                              <SelectWrapper
                                name="user_id"
                                label="Select User (Person doing the evaluation)"
                                fullWidth
                                variant="outlined"
                                options={teamMembers.map((team) => {
                                  return {
                                    value: team.user_id,
                                    label: team?.User?.name,
                                  };
                                })}
                              />
                            </Stack>
                          </Grid>

                          <Grid item xs={12}>
                            <SelectWrapper
                              name="stage"
                              label="stage"
                              fullWidth
                              variant="outlined"
                              options={[
                                { value: "New", label: "New" },
                                { value: "Re-evaluate", label: "Re-evaluate" },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextfieldWrapper
                              name="note1"
                              label="Note 1"
                              fullWidth
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextfieldWrapper
                              name="note2"
                              label="Note 2"
                              fullWidth
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextfieldWrapper
                              name="note3"
                              label="Note 3"
                              fullWidth
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextfieldWrapper
                              name="note4"
                              label="Note 4"
                              fullWidth
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Stack>

                      <Stack sx={{ mt: 2 }}>
                        <Button onClick={() => setOpen(!open)}>Cancel</Button>
                        <Button fullWidth variant="contained" type="submit">
                          Save
                        </Button>
                      </Stack>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default withAuth(CreateEvaluationsFromLeadSale);

const SelectDisposition = ({ availableDispositions }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const handleChange = (e) => {
    let id = e.target.value;

    setFieldValue("disposition_detail_id", "");
    setFieldValue("disposition_id", id);
  };

  return (
    <FormControl error={errors["disposition_id"] ? true : false} fullWidth>
      <InputLabel id="disposition_label_id">Disposition</InputLabel>
      <Select
        labelId="disposition_label_id"
        id="disposition_id"
        value={values["disposition_id"]}
        label="Disposition"
        onChange={handleChange}>
        {availableDispositions?.map((disposition) => (
          <MenuItem key={disposition.id} value={disposition.id}>
            {disposition.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const SelectDispositionDetail = ({ availableDispositionsDetails }) => {
  const { values, setFieldValue, setErrors, errors } = useFormikContext();

  const [dispositionDetail, setDispositionDetail] = React.useState(
    values["disposition_detail_id"]
  );

  useEffect(() => {
    if (!dispositionDetail && availableDispositionsDetails.length > 0) {
      setErrors({
        ...errors,
        disposition_detail_id: "Disposition Detail is required",
      });
    }
  }, [setErrors, errors, dispositionDetail, availableDispositionsDetails]);

  const handleChange = (e) => {
    let id = e.target.value;
    setDispositionDetail(id);

    setFieldValue("disposition_detail_id", id);
  };

  return (
    <FormControl
      error={errors["disposition_detail_id"] ? true : false}
      fullWidth>
      <InputLabel id="disposition_detail_id_label">
        disposition_detail_id
      </InputLabel>
      <Select
        labelId="disposition_detail_id_label"
        id="disposition_detail_id"
        value={dispositionDetail}
        label="disposition_detail_id"
        onChange={handleChange}>
        {availableDispositionsDetails.map((disposition) => (
          <MenuItem key={disposition.id} value={disposition.id}>
            {disposition.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const SelectQaQueueTeam = ({ qaQueueId, setQaQueueId, qaQueuesList }) => {
  const { values, setFieldValue } = useFormikContext();
  const [field, meta] = useField("queue_id");

  const handleChange = (event) => {
    setQaQueueId(event.target.value);

    setFieldValue("queue_id", event.target.value);
  };

  const configSelect = {
    ...field,

    variant: "outlined",
    fullWidth: true,
    onChange: handleChange,
  };

  const dispatch = useDispatch();

  const getQueueDetails = useCallback(() => {
    if (qaQueueId) {
      dispatch(getQaQueueTeams(qaQueueId));
      dispatch(getQaQueueCampaigns(qaQueueId));
    }
  }, [dispatch, qaQueueId]);

  useEffect(() => {
    getQueueDetails();
  }, [getQueueDetails]);

  return (
    <FormControl
      error={meta && meta.touched && meta.error ? true : null}
      fullWidth>
      <InputLabel id="select">Select Qa Queue</InputLabel>
      <Select
        {...configSelect}
        labelId="select"
        id="select_id"
        value={values["queue_id"]}
        label="Select Qa Queue">
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {qaQueuesList.map((item, index) => {
          return (
            <MenuItem key={index} value={item?.id}>
              {item?.queue_name}
            </MenuItem>
          );
        })}
      </Select>
      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};
