import * as React from "react";

import TextField from "@mui/material/TextField";
import { Stack } from "@mui/material";

export default function SurveyTextField({
  question,
  QuestionAnswers,
  setQuestionAnswers,
  questionNumber,
}) {
  const [value, setValue] = React.useState({
    question_id: question.id,
    answer_id: "",
    notes: "",
  });

  const handleNotesChange = (event) => {
    setValue({
      ...value,
      notes: event.target.value,
    });

    setQuestionAnswers(
      QuestionAnswers?.map((question) => {
        if (question.question_id === value.question_id) {
          return {
            ...question,
            notes: event.target.value,
          };
        } else {
          return question;
        }
      })
    );
  };

  return (
    <Stack my={2}>
      <TextField
        margin="normal"
        id="notes"
        label={`${questionNumber}:${question}`}
        multiline
        rows={2}
        name="notes"
        value={value.notes}
        onChange={handleNotesChange}
      />
    </Stack>
  );
}
