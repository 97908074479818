import {
  Card,
  CardContent,
  CardActionArea,
  CardHeader,
  Grid,
  Typography,
  Stack,
  CircularProgress,
  Chip,
  Alert,
  CardActions,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import withAuth from "../../../../../../../Hoc/withAuth";
import {
  GetCompanySurveys,
  RESET_SURVEY_CAMPAIGN,
} from "../../State/SurveysDuck";
import { useNavigate } from "react-router-dom";
import DeleteSurveyDialog from "./DeleteSurveyDialog";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";

import { getCompanyModules } from "../../../../../../../Redux/Ducks/AdminCompniesDuck";

const SurveyList = ({ companyDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const Companies = useSelector((state) => state.Companies);
  // const { companyModules } = Companies;

  const Surveys = useSelector((state) => state.Surveys);
  const { loading, surveyCampaigns, error, surveyDelete } = Surveys;

  useEffect(() => {
    dispatch({ type: RESET_SURVEY_CAMPAIGN });
    dispatch(GetCompanySurveys(companyDetails?.companyId));
    dispatch(getCompanyModules(companyDetails?.companyId));
  }, [dispatch, surveyDelete, companyDetails]);

  if (loading) {
    return (
      <Stack display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Grid container mt={3} spacing={2}>
      {surveyCampaigns &&
        surveyCampaigns?.map((survey, index) => {
          return (
            <Grid key={index} item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card>
                <CardActionArea
                  onClick={() =>
                    navigate(`/CDA/Survey/surveyItem/${survey.id}`)
                  }
                >
                  <CardHeader
                    title={survey.name}
                    subheader={survey.client}
                    action={
                      <Chip
                        variant="outlined"
                        label={survey.status ? "Active" : "Disabled"}
                        color={survey.status ? "secondary" : "warning"}
                      />
                    }
                  />
                  <CardContent>
                    <Typography variant="body1">
                      {survey.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <DeleteSurveyDialog name={survey.name} id={survey.id} />
                </CardActions>
              </Card>
            </Grid>
          );
        })}

      {surveyCampaigns?.length === 0 && (
        <Alert severity="info">No Surveys Found</Alert>
      )}

      {error && <Alert severity="info">{JSON.stringify(error)}</Alert>}

      <AlertPopup
        open={surveyDelete}
        severity="success"
        message="survey Deleted"
      />
    </Grid>
  );
};

export default withAuth(SurveyList);
