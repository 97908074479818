import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
export default function DeleteCampaignFromList({
  campaignToDelete,
  deleteCampaign,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    deleteCampaign.mutate(campaignToDelete.id);
    handleClose();
  };

  return (
    <React.Fragment>
      <IconButton color="error" variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure you want to delete this campaign?"}
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ my: 2 }}>
            <DialogContentText>{campaignToDelete?.name}</DialogContentText>
          </Stack>

          <Stack sx={{ mt: 2 }} direction="row" justifyContent="flex-end">
            <Button onClick={handleClose}>Close</Button>
            <Button variant="contained" color="error" onClick={handleSubmit}>
              Delete
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
