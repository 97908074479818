import { combineReducers } from "redux";
import employeeReducer from "./Reducers/employeeReducer";
import managerReducer, {
  departmentReducer,
  positionReducer,
} from "./Reducers/managerReducer";

const paySpaceReducer = combineReducers({
  manager: managerReducer,
  employee: employeeReducer,
  position: positionReducer,
  department: departmentReducer,
});

export default paySpaceReducer;
