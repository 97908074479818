import {
  Button,
  Divider,
  Grid,
  List,
  ListSubheader,
  Stack,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import DOBPicker from "../../../../../../Components/FormComponents/DobPicker";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import { useDispatch } from "react-redux";
import { updateLead } from "../State/AgentSalesReducer";
import dayjs from "dayjs";

const EditLead = ({ lead }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={{
          _id: lead._id || "",
          source: lead.source || "",
          external_ref: lead.external_ref || "",
          old_system_id: lead.old_system_id || "",
          system_id: lead.system_id || "",
          campaign_id: lead.campaign_id || "",
          title: lead.title || "",
          initials: lead.initials || "",
          first_name: lead.first_name || "",
          surname: lead.surname || "",
          id_no: lead.id_no || "",
          dob: dayjs(lead?.dob) || dayjs(),
          gender: lead.gender || "",
          marital_status: lead.marital_status || "",
          mobile_no: lead.mobile_no || "",
          home_no: lead.home_no || "",
          work_no: lead.work_no || "",
          email: lead.email || "",
          addr1: lead.addr1 || "",
          addr2: lead.addr2 || "",
          addr3: lead.addr3 || "",
          addr4: lead.addr4 || "",
          addr5: lead.addr5 || "",
          postal_code: lead.postal_code || "",
          addr1_res: lead.addr1_res || "",
          addr2_res: lead.addr2_res || "",
          addr3_res: lead.addr3_res || "",
          addr4_res: lead.addr4_res || "",
          addr5_res: lead.addr5_res || "",
          postal_code_res: lead.postal_code_res || "",
          acc_holder_name: lead.acc_holder_name || "",
          acc_no: lead.acc_no || "",
          acc_type: lead.acc_type || "",
          branch_code: lead.branch_code || "",
          bank: lead.bank || "",
          full_note: lead.full_note || "",
          note1: lead.note1 || "",
          note1_heading: lead.note1_heading || "",
          note2: lead.note2 || "",
          note2_heading: lead.note2_heading || "",
          note3: lead.note3 || "",
          note3_heading: lead.note3_heading || "",
          note4: lead.note4 || "",
          note4_heading: lead.note4_heading || "",
          note5: lead.note5 || "",
          note5_heading: lead.note5_heading || "",
          note6: lead.note6 || "",
          note6_heading: lead.note6_heading || "",
          note7: lead.note7 || "",
          note7_heading: lead.note7_heading || "",
          note8: lead.note8 || "",
          note8_heading: lead.note8_heading || "",
          platform: lead.platform || "",
        }}
        onSubmit={(values) => {
          values.dob = dayjs(values.dob).format("YYYY-MM-DD").toString();

          dispatch(updateLead({ id: values._id, values }));
        }}>
        {({ values, handleChange, dirty }) => {
          return (
            <Form>
              {dirty && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary">
                  Save
                </Button>
              )}

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <List
                    dense
                    sx={{
                      width: "100%",
                      borderColor: "divider",
                      borderWidth: 0.1,
                      borderStyle: "solid",
                      my: 2,
                      bgcolor: "background.paper",
                    }}
                    subheader={<ListSubheader>Profile</ListSubheader>}>
                    <Divider />
                    <Stack sx={{ p: 2 }}>
                      <TextfieldWrapper
                        margin="normal"
                        name="id_no"
                        label="ID Number"
                        onChange={handleChange}
                      />
                      <Stack direction="row">
                        <TextfieldWrapper
                          margin="normal"
                          name="title"
                          sx={{ mr: 1 }}
                          label="Title"
                          onChange={handleChange}
                        />
                        <TextfieldWrapper
                          margin="normal"
                          name="initials"
                          label="Initials"
                          onChange={handleChange}
                        />
                      </Stack>
                      <Stack direction="row">
                        <TextfieldWrapper
                          margin="normal"
                          sx={{ mr: 1 }}
                          name="gender"
                          label="Gender"
                          onChange={handleChange}
                        />
                        <TextfieldWrapper
                          margin="normal"
                          name="marital_status"
                          label="Marital Status"
                          onChange={handleChange}
                        />
                      </Stack>
                      <TextfieldWrapper
                        margin="normal"
                        name="first_name"
                        label="First Name"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        name="surname"
                        label="Last Name"
                        onChange={handleChange}
                      />
                      <DOBPicker name="dob" />
                    </Stack>
                  </List>
                  <List
                    dense
                    sx={{
                      width: "100%",
                      my: 2,
                      borderColor: "divider",
                      borderWidth: 0.1,
                      borderStyle: "solid",
                      bgcolor: "background.paper",
                    }}
                    subheader={<ListSubheader>Account</ListSubheader>}>
                    <Divider />
                    <Stack sx={{ p: 2 }}>
                      <TextfieldWrapper
                        margin="normal"
                        name="acc_holder_name"
                        label="Acc Holder Name"
                        onChange={handleChange}
                      />

                      <TextfieldWrapper
                        margin="normal"
                        name="acc_no"
                        sx={{ mr: 1 }}
                        label="Acc No"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        name="acc_type"
                        label="Acc Type"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="bank"
                        label="Bank"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="branch_code"
                        label="Branch Code"
                        onChange={handleChange}
                      />
                    </Stack>
                  </List>
                  <List
                    dense
                    sx={{
                      width: "100%",
                      my: 2,
                      borderColor: "divider",
                      borderWidth: 0.1,
                      borderStyle: "solid",
                      bgcolor: "background.paper",
                    }}
                    subheader={<ListSubheader>Note</ListSubheader>}>
                    <Divider />
                    <Stack sx={{ p: 2 }}>
                      <TextfieldWrapper
                        margin="normal"
                        name="full_note"
                        label="Full Note"
                        onChange={handleChange}
                      />
                    </Stack>
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <List
                    dense
                    sx={{
                      width: "100%",
                      my: 2,
                      borderColor: "divider",
                      borderWidth: 0.1,
                      borderStyle: "solid",
                      bgcolor: "background.paper",
                    }}
                    subheader={<ListSubheader>Contact Details</ListSubheader>}>
                    <Divider />
                    <Stack sx={{ p: 2 }}>
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="mobile_no"
                        label="Mobile No"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="work_no"
                        label="Work No"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="home_no"
                        label="Home No"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="email"
                        label="Email"
                        onChange={handleChange}
                      />
                    </Stack>
                  </List>
                  <List
                    dense
                    sx={{
                      width: "100%",
                      my: 2,
                      borderColor: "divider",
                      borderWidth: 0.1,
                      borderStyle: "solid",
                      bgcolor: "background.paper",
                    }}
                    subheader={<ListSubheader>Postal Address</ListSubheader>}>
                    <Divider />
                    <Stack sx={{ p: 2 }}>
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="addr1"
                        label="Postal Address 1"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="addr2"
                        label="Postal Address 2"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="addr3"
                        label="Postal Address 3"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="addr4"
                        label="Postal Address 4"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="addr5"
                        label="Postal Address 5"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="postal_code"
                        label="Postal Code"
                        onChange={handleChange}
                      />
                    </Stack>
                  </List>
                  <List
                    dense
                    sx={{
                      width: "100%",
                      my: 2,
                      borderColor: "divider",
                      borderWidth: 0.1,
                      borderStyle: "solid",
                      bgcolor: "background.paper",
                    }}
                    subheader={
                      <ListSubheader>Residential Address</ListSubheader>
                    }>
                    <Divider />
                    <Stack sx={{ p: 2 }}>
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="addr1_res"
                        label="Residential Address 1"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="addr2_res"
                        label="Residential Address 2"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="addr3_res"
                        label="Residential Address 3"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="addr4_res"
                        label="Residential Address 4"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="addr5_res"
                        label="Residential Address 5"
                        onChange={handleChange}
                      />
                      <TextfieldWrapper
                        margin="normal"
                        sx={{ mr: 1 }}
                        name="postal_code_res"
                        label="Residential Address Postal Code"
                        onChange={handleChange}
                      />
                    </Stack>
                  </List>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditLead;
