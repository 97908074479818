import {
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import React from "react";
import withAuth from "../../../../../../Hoc/withAuth";
import CreateNewNote from "./CreateNewNote";

const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
};

const EvaluationNotesList = ({ evaluation_id, user }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const notes = useQuery([`$evaluation_notes`, evaluation_id], () => {
    return fetchData(
      `${process.env.REACT_APP_API_URL}/qa/evaluationNotes/${evaluation_id}`,
      config
    );
  });

  return (
    <div>
      {notes.isLoading && <LinearProgress />}

      {notes?.data?.data && notes?.data?.data?.length > 0 ? (
        notes?.data?.data?.map((note) => {
          return (
            <Card key={note?.id}>
              <CardContent>
                <Stack>
                  <Typography variant="caption" color="text-secondary">
                    {note?.User?.name}
                  </Typography>
                  <Typography variant="caption" color="text-secondary">
                    {new Date(note?.createdAt).toLocaleDateString()} -
                    {new Date(note?.createdAt).toLocaleTimeString()}
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="body1" color="text-secondary">
                    {note?.note}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          );
        })
      ) : (
        <p>No notes</p>
      )}
      <CreateNewNote evaluation_id={evaluation_id} user={user} />
    </div>
  );
};

export default withAuth(EvaluationNotesList);
