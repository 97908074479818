import axios from "axios";

const initialState = {
  clients: [],
  client: {},
  loading: false,
  success: true,
  error: null,
};

const GET_CLIENTS_BY_USER_ID = "GET_CLIENTS_BY_USER_ID";
const GET_CLIENTS_BY_USER_ID_SUCCESS = "GET_CLIENTS_BY_USER_ID_SUCCESS";
const GET_CLIENTS_BY_USER_ID_FAILURE = "GET_CLIENTS_BY_USER_ID_FAILURE";

const CREATE_CLIENT = "CREATE_CLIENT";
const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
const CREATE_CLIENT_FAILURE = "CREATE_CLIENT_FAILURE";

const GET_CLIENT_BY_ID = "GET_CLIENT_BY_ID";
const GET_CLIENT_BY_ID_SUCCESS = "GET_CLIENT_BY_ID_SUCCESS";
const GET_CLIENT_BY_ID_FAILURE = "GET_CLIENT_BY_ID_FAILURE";

const ADD_CLIENT_CONTACT = "ADD_CLIENT_CONTACT";
const ADD_CLIENT_CONTACT_SUCCESS = "ADD_CLIENT_CONTACT_SUCCESS";
const ADD_CLIENT_CONTACT_FAILURE = "ADD_CLIENT_CONTACT_FAILURE";

const EDIT_CLIENT_CONTACT = "EDIT_CLIENT_CONTACT";
const EDIT_CLIENT_CONTACT_SUCCESS = "EDIT_CLIENT_CONTACT_SUCCESS";
const EDIT_CLIENT_CONTACT_FAILURE = "EDIT_CLIENT_CONTACT_FAILURE";

const DELETE_CLIENT_CONTACT = "DELETE_CLIENT_CONTACT";
const DELETE_CLIENT_CONTACT_SUCCESS = "DELETE_CLIENT_CONTACT_SUCCESS";
const DELETE_CLIENT_CONTACT_FAILURE = "DELETE_CLIENT_CONTACT_FAILURE";

const ClientsDuck = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENTS_BY_USER_ID:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_CLIENTS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case GET_CLIENTS_BY_USER_ID_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case CREATE_CLIENT:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: [...state.clients, action.payload],
        loading: false,
        success: true,
        error: null,
      };
    case CREATE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case GET_CLIENT_BY_ID:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_CLIENT_BY_ID_SUCCESS:
      return {
        ...state,
        client: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case GET_CLIENT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ADD_CLIENT_CONTACT:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case ADD_CLIENT_CONTACT_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          crm_client_contacts: [
            ...state.client.crm_client_contacts,
            { ...action.payload },
          ],
        },
        loading: false,
        success: true,
        error: null,
      };
    case ADD_CLIENT_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case EDIT_CLIENT_CONTACT:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case EDIT_CLIENT_CONTACT_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          crm_client_contacts: state.client.crm_client_contacts.map(
            (contact) => {
              if (contact.id === action.payload.id) {
                return { ...action.payload };
              }
              return contact;
            }
          ),
        },
        loading: false,
        success: true,
        error: null,
      };
    case EDIT_CLIENT_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case DELETE_CLIENT_CONTACT:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case DELETE_CLIENT_CONTACT_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          crm_client_contacts: state.client.crm_client_contacts.filter(
            (contact) => (contact.id !== action.payload.id ? contact : null)
          ),
        },
        loading: false,
        success: true,
        error: null,
      };
    case DELETE_CLIENT_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ClientsDuck;

export const getClientsByUser = () => async (dispatch, getState) => {
  dispatch({ type: GET_CLIENTS_BY_USER_ID });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/crm/clients`,
      config
    );

    dispatch({ type: GET_CLIENTS_BY_USER_ID_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: GET_CLIENTS_BY_USER_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const CreateClient = (client) => async (dispatch, getState) => {
  dispatch({ type: CREATE_CLIENT });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/crm/client`,
      client,
      config
    );

    dispatch({ type: CREATE_CLIENT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_CLIENT_FAILURE,
      payload: error.message,
    });
  }
};

export const getClientById = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_CLIENT_BY_ID });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/crm/client/${id}`,
      config
    );

    dispatch({ type: GET_CLIENT_BY_ID_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: GET_CLIENT_BY_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const addClientContact = (contact) => async (dispatch, getState) => {
  dispatch({ type: ADD_CLIENT_CONTACT });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/crm/contacts`,
      contact,
      config
    );

    dispatch({ type: ADD_CLIENT_CONTACT_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: ADD_CLIENT_CONTACT_FAILURE,
      payload: error.message,
    });
  }
};

export const editClientContactAction =
  (id, contact) => async (dispatch, getState) => {
    dispatch({ type: EDIT_CLIENT_CONTACT });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/crm/contacts/${id}`,
        contact,
        config
      );

      dispatch({ type: EDIT_CLIENT_CONTACT_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({
        type: EDIT_CLIENT_CONTACT_FAILURE,
        payload: error.message,
      });
    }
  };

export const deleteClientContact = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_CLIENT_CONTACT });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/crm/contacts/${id}`,
      config
    );

    dispatch({ type: DELETE_CLIENT_CONTACT_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: DELETE_CLIENT_CONTACT_FAILURE,
      payload: error.message,
    });
  }
};
