import { Grid, Typography } from "@mui/material";
import React from "react";
import FeatureCard from "../../../Components/Containers/FeatureCard";
import MainAppContainer from "../../../Components/Containers/MainAppContainer";
import PlaylistAddCircleSharpIcon from "@mui/icons-material/PlaylistAddCircleSharp";

const Index = () => {
  return (
    <MainAppContainer>
      <Typography align="center" variant="h4" sx={{ my: 4 }}>
        Santam
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FeatureCard
            company="CDA Solutions"
            Icon={PlaylistAddCircleSharpIcon}
            title="Santam Salary Deductions"
            link="/santam/SalaryDeductionsList"
          />
        </Grid>
      </Grid>
    </MainAppContainer>
  );
};

export default Index;
