import React from "react";

import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import HeadsetTwoToneIcon from "@mui/icons-material/HeadsetTwoTone";
import SchoolIcon from "@mui/icons-material/School";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import SupportAgentTwoToneIcon from "@mui/icons-material/SupportAgentTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import PollIcon from "@mui/icons-material/Poll";
import PollTwoToneIcon from "@mui/icons-material/PollTwoTone";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import withAuth from "../../../Hoc/withAuth";
import ModuleItem from "./ModuleItem";
import HeadphonesBatteryIcon from "@mui/icons-material/HeadphonesBattery";

const CDAModules = ({ modules }) => {
  return (
    <>
      {modules && modules.includes("crm") && (
        <ModuleItem Icon={AppRegistrationIcon} link="/crm" title="CRM" />
      )}
      {modules && modules.includes("qa") && (
        <ModuleItem
          Icon={HeadsetTwoToneIcon}
          link="/CDA/QaAgentHome"
          title="QA"
        />
      )}
      {modules && modules.includes("qa_admin") && (
        <ModuleItem Icon={HeadphonesBatteryIcon} link="/CDA/QA" title="QA" />
      )}
      {modules && modules.includes("questionnaire") && (
        <ModuleItem
          Icon={SchoolIcon}
          title="Questionnaire"
          link="/CDA/UserQuestionnaires"
        />
      )}
      {modules && modules.includes("questionnaire_admin") && (
        <ModuleItem
          Icon={SchoolTwoToneIcon}
          title="Questionnaire Admin"
          link="/CDA/questionnaireAdmin"
        />
      )}
      {modules && modules.includes("call-center-user") && (
        <ModuleItem
          Icon={SupportAgentTwoToneIcon}
          title="Call Center"
          link="/CDA/AgentHome/CampaignList"
        />
      )}
      {modules && modules.includes("call-center-admin") && (
        <ModuleItem
          Icon={ManageAccountsTwoToneIcon}
          title="Call Center Admin"
          link="/CDA/callCenter/clients"
        />
      )}

      {modules && modules.includes("survey") && (
        <ModuleItem Icon={PollIcon} title="Surveys" link="/CDA/survey" />
      )}
      {modules && modules.includes("survey_admin") && (
        <ModuleItem
          Icon={PollTwoToneIcon}
          title="Surveys Admin"
          link="/CDA/surveyAdmin"
        />
      )}
      {modules && modules.includes("survey_admin") && (
        <ModuleItem Icon={BeachAccessIcon} title="Santam" link="/santam" />
      )}
    </>
  );
};

export default withAuth(CDAModules);
