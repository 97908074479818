import { Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import ChatBubble1 from "./ChatBubble1";
import SendMessageComponent from "./SendMessageComponent";

const Chat = ({ socket, user }) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    socket.on("receiveMessage", (data) => {
      setMessages((list) => [...list, data]);
    });
    console.log("Socketting");
  }, [socket]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div>
      <Typography variant="h4">Live Chat</Typography>

      {messages &&
        messages.length > 0 &&
        messages.map((message, index) => {
          return <ChatBubble1 key={index} msg={message} user={user} />;
        })}

      <div ref={messagesEndRef} />

      <SendMessageComponent
        setMessages={setMessages}
        socket={socket}
        user={user}
      />
    </div>
  );
};

export default Chat;
