import { Grid } from "@mui/material";
import React from "react";
import FeatureCard from "../../../../../Components/Containers/FeatureCard";
import MainAppContainer from "../../../../../Components/Containers/MainAppContainer";
import PollIcon from "@mui/icons-material/Poll";
import DynamicFormTwoToneIcon from "@mui/icons-material/DynamicFormTwoTone";
// import UserSurveyList from "./Components/SurveyUserComponents/UserSurveyList";
import SurveyList from "./Components/Survey/SurveyList";

const Survey = () => {
  return (
    <MainAppContainer>
      <Grid container>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <FeatureCard
            Icon={DynamicFormTwoToneIcon}
            title="Create Survey"
            link="/CDA/Survey/Create"
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <FeatureCard
            Icon={PollIcon}
            title="Results "
            link="/CDA/survey/results"
          />
        </Grid>
      </Grid>
      {/* <UserSurveyList /> */}
      <SurveyList />
    </MainAppContainer>
  );
};

export default Survey;
