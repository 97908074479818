import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Form, Formik } from "formik";
import { Grid } from "@mui/material";
import DateSelectorWrapper from "../FormComponents/DateSelectorWrapper";
import TextfieldWrapper from "../FormComponents/TextfieldWrapper";
import SlateEditor from "../FormComponents/SlateEditor";
import dayjs from "dayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddPatchNotes({ createPatchNotes }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add Patch Notes
      </Button>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Add Patch notes</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              date: dayjs(),
              title: "",
              version: "",
              description: "",
              content: [{ type: "paragraph", children: [{ text: "" }] }],
            }}
            onSubmit={(values) => {
              createPatchNotes.mutate(values);
              handleClose();
            }}>
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <Grid container spacing={2} sx={{ my: 3 }}>
                    <Grid item xs={6}>
                      <TextfieldWrapper name="title" label="Title" />
                    </Grid>
                    <Grid item xs={6}>
                      <DateSelectorWrapper name="date" />
                    </Grid>
                    <Grid item xs={6}>
                      <TextfieldWrapper name="version" label="Version" />
                    </Grid>
                    <Grid item xs={6}>
                      <TextfieldWrapper
                        name="description"
                        label="Description"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SlateEditor
                        value={values.content}
                        onChange={(value) => setFieldValue("content", value)}
                      />
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleClose}>
                      Save
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
