import { Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

const DashboardsMain = () => {
  return (
    <div>
      <Typography variant="h2">Dashboards</Typography>
      <Outlet />
    </div>
  );
};

export default DashboardsMain;
