import React from "react";
import ViewEvaluationMetrics from "../../../InboundQA/Admin/Evaluations/components/viewEvaluationMetrics";



const AgentInboundResults = () => {
 
  return (
    <ViewEvaluationMetrics/>
  );
};

export default AgentInboundResults;
