import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Tooltip, Typography, IconButton } from "@mui/material";

import { useDispatch } from "react-redux";
import { deleteQuestionnaireQuestion } from "../State/QuestionnairsDuck";
import DeleteIcon from "@mui/icons-material/Delete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteQuestion({ id, question }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    console.log(id);
    dispatch(deleteQuestionnaireQuestion(id));
  };

  return (
    <div>
      <Tooltip title={"Delete Question"}>
        <IconButton onClick={handleClickOpen} color="error" edge="end">
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        sx={{
          border: "3px solid red",
        }}
        open={open}
        fullWidth
        maxWidth="md"
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Delete Survey</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
          </DialogContentText>
          <Typography sx={{ my: 4 }} align="center" variant="h6">
            {question}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="error" variant="outlined" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
