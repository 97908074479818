import axios from "axios";

const initialState = {
  lead: {},
  loading: false,
  error: null,
  leadUpdated: false,
};

const GET_LEAD = "GET_LEAD";
const GET_LEAD_SUCCESS = "GET_LEAD_SUCCESS";
const GET_LEAD_FAIL = "GET_LEAD_FAIL";

const UPDATE_LEAD = "UPDATE_LEAD";
const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS";

const UPDATE_LEAD_FAIL = "UPDATE_LEAD_FAIL";

const GET_SALE_EVALS_BY_REF = "GET_SALE_EVALS_BY_REF";
const GET_SALE_EVALS_BY_REF_SUCCESS = "GET_SALE_EVALS_BY_REF_SUCCESS";
const GET_SALE_EVALS_BY_REF_FAIL = "GET_SALE_EVALS_BY_REF_FAIL";

export const AGENT_SALES_RESET = "AGENT_SALES_RESET";

const AgentSalesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEAD:
      return {
        ...state,
        loading: true,
      };
    case GET_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        lead: action.payload,
      };
    case GET_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_LEAD:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        leadUpdated: true,
        lead: action.payload,
      };
    case UPDATE_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_SALE_EVALS_BY_REF:
      return {
        ...state,
        loading: true,
      };

    case GET_SALE_EVALS_BY_REF_SUCCESS:
      return {
        ...state,
        loading: false,
        evals: action.payload,
      };
    case GET_SALE_EVALS_BY_REF_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AGENT_SALES_RESET:
      return {
        ...state,
        leadUpdated: false,
      };

    default:
      return state;
  }
};

export default AgentSalesReducer;

export const getLead = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_LEAD });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/salesman/leads/${id}`,
      config
    );
    dispatch({ type: GET_LEAD_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_LEAD_FAIL, payload: error });
  }
};

export const updateLead =
  ({ id, values }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_LEAD });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/salesman/leads/${id}`,
        values,
        config
      );
      dispatch({ type: UPDATE_LEAD_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({ type: UPDATE_LEAD_FAIL, payload: error });
    }
  };

export const getSaleEvalsByRef = (ref) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SALE_EVALS_BY_REF });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/evaluations/${ref}/unique`,
      config
    );
    dispatch({ type: GET_SALE_EVALS_BY_REF_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_SALE_EVALS_BY_REF_FAIL, payload: error });
  }
};
