import { Button, Stack, LinearProgress, Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from 'react'

import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import withAuth from "../../../Hoc/withAuth";
import axios from "axios";

import AddEditDepartmentModal from "../modals/AddEditDepartmentModal";
import AddBoxIcon from '@mui/icons-material/AddBox';
import AlertPopup from "../../../Components/Bits/AlertPopup";

const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
};

const OECDepartments = () => {
  const queryClient = useQueryClient()
  
  const [mutationError, setMutationError] = useState('')
  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const mutation = useMutation({
    mutationFn: async (department_id) => {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/onboarding/departments/${department_id}`,
        { is_active: false },
        config
      );
      return response.data;
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(['getDepartments'])
    },
    onError: async (error) => {
      setMutationError(error.response.data.message)
    }
  })

  const onDeleteHandler = (department_id) => {
    mutation.mutate(department_id)
  } 

  const columns = [
    {
      field: "department_name",
      headerName: "Department",
      width: 350,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "manager",
      headerName: "Manager",
      width: 350,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <AddEditDepartmentModal
            text={"Edit/View"}
            department={{
              id: params.row.id,
              department_name: params.row.department_name,
              manager_node_id: params.row.actions,
            }}
            color="primary"
          />

          <Button variant="outlined" color="error" onClick={() => onDeleteHandler(params.row.id)}>
            Delete
          </Button>
        </Stack>
      ),
    },
  ];

  // get departments
  const { isLoading, isError, data, error } = useQuery(
    ["getDepartments"],
    async () =>
      await fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/departments`,
        config
      ),
    {
      enabled: true,
    }
  );

  if (isLoading) return <LinearProgress />;

  if (isError) return <Alert severity="error">{error.message}</Alert>;

  return (
    <>
      <Stack spacing={3} sx={{ mb: 3 }}>
        <AddEditDepartmentModal
          text="Add Department"
          variant={"outlined"}
          startIcon={<AddBoxIcon />}
          color="secondary"
          fullWidth
        />

      {mutation.isLoading && (
        <AlertPopup open={true} message={"Updating Department..."} severity={"info"} />
      )}
      {mutation.isError && (
        
        <AlertPopup
          open={true}
          message={mutation.error}
          severity={"error"}
        />
      )}

      { mutation.isSuccess && (
        <AlertPopup open={true} message={"Department(s) updated successfully!"} />
      )}

      { mutationError && (
            <AlertPopup open={true} message={mutationError} severity={"error"} />
          ) }

        <div style={{ height: "70vh", width: "100%", mt: 3 }}>
          <DataGridPremium
            rows={data?.departments?.map((dept) => ({
              id: dept.id,
              department_name: dept.department_name,
              manager: `${dept?.User?.name || ""} ${dept?.User?.surname || ""}`,
              actions: dept?.User?.id,
            }))}
            columns={columns}
            density="comfortable"
            slots={{ toolbar: GridToolbar }}
          />
        </div>
      </Stack>
    </>
  );
};

export default withAuth(OECDepartments);
