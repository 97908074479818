import React from 'react';
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import dayjs from "dayjs";
import { Stack } from '@mui/material';

const chartSetting = {
    yAxis: [
      {
        label: "Number of Evaluations",
      },
    ],
    width: 500,
    height: 400,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-10px, 0)",
      },
    },
  };

const BarGraph = ({validRows}) => {

    const transformedData = () => {
        const today = dayjs();
        const startOfWeek = today.startOf("week");
        const startOfMonth = today.startOf("month");
        const startOfYear = today.startOf("year");
    
        let todayCount = 0;
        let thisWeekCount = 0;
        let thisMonthCount = 0;
        let thisYearCount = 0;
    
        validRows.forEach((evaluation) => {
          const createdAt = dayjs(evaluation.updatedAt);
          if (createdAt.isSame(today, "day")) {
            todayCount++;
          }
          if (createdAt.isAfter(startOfWeek)) {
            thisWeekCount++;
          }
          if (createdAt.isAfter(startOfMonth)) {
            thisMonthCount++;
          }
          if (createdAt.isAfter(startOfYear)) {
            thisYearCount++;
          }
        });
    
        return [
          {
            Evaluations: "Evaluations",
            Today: todayCount,
            thisWeek: thisWeekCount,
            thisMonth: thisMonthCount,
            thisYear: thisYearCount,
          },
        ];
      };
    
      const dataForChart = transformedData();


  return (
    <Stack sx={{ml: 15}}>
    <BarChart
          dataset={dataForChart}
          xAxis={[{ scaleType: "band", dataKey: "Evaluations" }]}
          series={[
            { dataKey: "Today", label: "Today" },
            { dataKey: "thisWeek", label: "This Week" },
            { dataKey: "thisMonth", label: "This Month" },
            { dataKey: "thisYear", label: "This Year" },
          ]}
          {...chartSetting}
        />
        </Stack>
  )
}

export default BarGraph;
