import axios from "axios";

const initialState = {
  activeLeadSources: [],
  allLeadSources: [],
  inactiveLeadSources: [],
  leadsSource: {},
  campaignLeads: [],
  leadFiles: [],

  editSuccess: null,
  addSuccess: null,
  loading: false,
  success: false,
  error: null,
};

const GET_CAMPAIGN_LEADS = "GET_CAMPAIGN_LEADS";
const GET_CAMPAIGN_LEADS_SUCCESS = "GET_CAMPAIGN_LEADS_SUCCESS";
const GET_CAMPAIGN_LEADS_FAIL = "GET_CAMPAIGN_LEADS_FAIL";

const ADD_NEW_LEAD = "ADD_NEW_LEAD";
const ADD_NEW_LEAD_SUCCESS = "ADD_NEW_LEAD_SUCCESS";
const ADD_NEW_LEAD_FAIL = "ADD_NEW_LEAD_FAIL";

const UPLOAD_LEAD_FILE = "UPLOAD_LEAD_FILE";
const UPLOAD_LEAD_FILE_SUCCESS = "UPLOAD_LEAD_FILE_SUCCESS";
const UPLOAD_LEAD_FAIL_FILE = "UPLOAD_LEAD_FAIL_FILE";

const GET_UPLOADED_LEADS_FILES = "GET_UPLOADED_LEADS_FILE";
const GET_UPLOADED_LEADS_FILES_SUCCESS = "GET_UPLOADED_LEADS_FILES_SUCCESS";
const GET_UPLOADED_LEADS_FILES_FAIL = "GET_UPLOADED_LEADS_FILES_FAIL";

const GET_LEADS_SOURCES = "GET_LEADS_SOURCES";
const GET_LEADS_SOURCES_SUCCESS = "GET_LEADS_SOURCES_SUCCESS";
const GET_LEADS_SOURCES_FAIL = "GET_LEADS_SOURCES_FAIL";

const EDIT_LEADS_SOURCE = "EDIT_LEADS_SOURCE";
const EDIT_LEADS_SOURCE_SUCCESS = "EDIT_LEADS_SOURCE_SUCCESS";
const EDIT_LEADS_SOURCE_FAIL = "EDIT_LEADS_SOURCE_FAIL";

const ADD_LEADS_SOURCE = "ADD_LEADS_SOURCE";
const ADD_LEADS_SOURCE_SUCCESS = "ADD_LEADS_SOURCE_SUCCESS";
const ADD_LEADS_SOURCE_FAIL = "ADD_LEADS_SOURCE_FAIL";

const DELETE_LEADS_SOURCE = "DELETE_LEADS_SOURCE";
const DELETE_LEADS_SOURCE_SUCCESS = "DELETE_LEADS_SOURCE_SUCCESS";
const DELETE_LEADS_SOURCE_FAIL = "DELETE_LEADS_SOURCE_FAIL";

export const RESET_LEADS_SOURCES = "RESET_LEADS_SOURCES";

const LeadsDuck = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_LEADS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_CAMPAIGN_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        campaignLeads: action.payload,
      };

    case GET_CAMPAIGN_LEADS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };

    case ADD_NEW_LEAD:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case ADD_NEW_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        leadAdded: true,
        error: null,
        campaignLeads: [...state.campaignLeads, action.payload],
      };
    case ADD_NEW_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case UPLOAD_LEAD_FILE:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case UPLOAD_LEAD_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        leadFiles: [...state.leadFiles, action.payload],
        leadUploaded: true,
      };
    case UPLOAD_LEAD_FAIL_FILE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_UPLOADED_LEADS_FILES:
      return {
        ...state,
        leadUploaded: false,
        loading: true,
        success: false,
        error: null,
      };

    case GET_UPLOADED_LEADS_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        leadFiles: action.payload,
      };
    case GET_UPLOADED_LEADS_FILES_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_LEADS_SOURCES:
      return {
        ...state,
      };
    case GET_LEADS_SOURCES_SUCCESS:
      return {
        ...state,

        success: true,
        error: null,
        activeLeadSources: action.payload.filter((lead) => lead.active),
        inactiveLeadSources: action.payload.filter((lead) => !lead.active),
        allLeadSources: action.payload,
        leadsSource: {},
      };

    case GET_LEADS_SOURCES_FAIL:
      return {
        ...state,

        success: false,
        error: action.payload,

        leadsSource: {},
      };

    case ADD_LEADS_SOURCE:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case ADD_LEADS_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        activeLeadSources: [...state.activeLeadSources, action.payload],
        allLeadSources: [...state.allLeadSources, action.payload],
        leadsSource: {},
        addSuccess: true,
      };
    case ADD_LEADS_SOURCE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case EDIT_LEADS_SOURCE:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case EDIT_LEADS_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        activeLeadSources: state.activeLeadSources.map((lead) => {
          if (lead.id === action.payload.id) {
            return action.payload;
          }
          return lead;
        }),
        inactiveLeadSources: state.inactiveLeadSources.map((lead) => {
          if (lead.id === action.payload.id) {
            return action.payload;
          }
          return lead;
        }),

        leadsSource: {},
        editSuccess: true,
      };
    case EDIT_LEADS_SOURCE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        activeLeadSources: [],
        inactiveLeadSources: [],
        leadsSource: {},
      };

    case DELETE_LEADS_SOURCE:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
        activeLeadSources: [],
        inactiveLeadSources: [],
        leadsSource: {},
      };
    case DELETE_LEADS_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      };
    case DELETE_LEADS_SOURCE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        leadsSourcesList: [],
        leadsSource: {},
      };
    case RESET_LEADS_SOURCES:
      return {
        ...state,
        loading: false,
        success: false,
        error: null,
        leadAdded: null,
        addSuccess: null,
        editSuccess: null,
      };
    default:
      return state;
  }
};

export default LeadsDuck;

export const getLeadSourceList = () => async (dispatch, getState) => {
  dispatch({
    type: GET_LEADS_SOURCES,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/lead_sources`,
      config,
    );
    dispatch({
      type: GET_LEADS_SOURCES_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_LEADS_SOURCES_FAIL,
      payload: err.response,
    });
  }
};

export const addLeadSource = (leadSource) => async (dispatch, getState) => {
  dispatch({
    type: ADD_LEADS_SOURCE,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/callCentre/lead_sources`,
      leadSource,
      config,
    );

    dispatch({
      type: ADD_LEADS_SOURCE_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_LEADS_SOURCE_FAIL,
      payload: err.response,
    });
  }
};

export const deleteLeadSource = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_LEADS_SOURCE,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/callCentre/lead_sources/${id}`,
      config,
    );
    dispatch({
      type: DELETE_LEADS_SOURCE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_LEADS_SOURCE_FAIL,
      payload: err.response,
    });
  }
};

export const editLeadSource = (leadSource) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_LEADS_SOURCE,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/callCentre/lead_sources/${leadSource.id}`,
      leadSource,
      config,
    );
    dispatch({
      type: EDIT_LEADS_SOURCE_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_LEADS_SOURCE_FAIL,
      payload: err.response,
    });
  }
};
export const UploadLeadFileToCampaign =
  (campaignId, values) => async (dispatch, getState) => {
    dispatch({
      type: UPLOAD_LEAD_FILE,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${campaignId}/lead_files`,
        values,
        config,
      );
      dispatch({
        type: UPLOAD_LEAD_FILE_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: EDIT_LEADS_SOURCE_FAIL,
        payload: err.response,
      });
    }
  };
export const getUploadedLeadFiles =
  (campaignId) => async (dispatch, getState) => {
    dispatch({
      type: GET_UPLOADED_LEADS_FILES,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${campaignId}/lead_files`,

        config,
      );
      dispatch({
        type: GET_UPLOADED_LEADS_FILES_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_UPLOADED_LEADS_FILES_FAIL,
        payload: err.response,
      });
    }
  };

export const getCampaignLeads =
  (id, limit, page, values) => async (dispatch, getState) => {
    dispatch({
      type: GET_CAMPAIGN_LEADS,
    });
    try {
      const { AuthDuck } = getState();

      let S = values ? "&search=" + values.search : "";

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/leads?pageNumber=${page}&limit=${limit}${S}`,
        config,
      );
      dispatch({
        type: GET_CAMPAIGN_LEADS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_CAMPAIGN_LEADS_FAIL,
        payload: err.response,
      });
    }
  };
export const AddNewLeadRequest = (values) => async (dispatch, getState) => {
  dispatch({
    type: ADD_NEW_LEAD,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${values.campaign_id}/leads`,
      values,
      config,
    );
    dispatch({
      type: ADD_NEW_LEAD_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_NEW_LEAD_FAIL,
      payload: err.response,
    });
  }
};
