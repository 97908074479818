import axios from "axios";

const initialState = {
    clientPlans: {},
    plan: {},
    loading: false,
    error: false,
    success: false,
}

/**
 * SYNC ACTIONS
 */


/**
 * THUNK ACTIONS
 * 
 */
const GET_CAMPAIGN_PLANS = "GET_CAMPAIGN_PLANS";
const GET_CAMPAIGN_PLANS_SUCCESS = "GET_CAMPAIGN_PLANS_SUCCESS";
const GET_CAMPAIGN_PLANS_ERROR = "GET_CAMPAIGN_PLANS_ERROR";

const GET_CAMPAIGN_PLANS_BY_CLIENT_ID = "GET_CAMPAIGN_PLANS_BY_CLIENT_ID";
const GET_CAMPAIGN_PLANS_BY_CLIENT_ID_SUCCESS = "GET_CAMPAIGN_PLANS_BY_CLIENT_ID_SUCCESS";
const GET_CAMPAIGN_PLANS_BY_CLIENT_ID_ERROR = "GET_CAMPAIGN_PLANS_BY_CLIENT_ID_ERROR";

const EDIT_CAMPAIGN_PLAN = "EDIT_CAMPAIGN_PLAN";
const EDIT_CAMPAIGN_PLAN_SUCCESS = "EDIT_CAMPAIGN_PLAN_SUCCESS";
const EDIT_CAMPAIGN_PLAN_ERROR = "EDIT_CAMPAIGN_PLAN_ERROR";

const campaignPlansReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CAMPAIGN_PLANS:
            return {
                ...state,
                loading: true,
            };
        case GET_CAMPAIGN_PLANS_SUCCESS: {
            return {
                ...state,
                plans: action.payload,
            };
        }
        case GET_CAMPAIGN_PLANS_ERROR: {
            return {
                ...state,
                error: true,
            };
        }
        case GET_CAMPAIGN_PLANS_BY_CLIENT_ID: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_CAMPAIGN_PLANS_BY_CLIENT_ID_SUCCESS: {
            return {
                ...state,
                clientPlans: action.payload,
            };
        }
        case GET_CAMPAIGN_PLANS_BY_CLIENT_ID_ERROR: {
            return {
                ...state,
                clientPlans: {},
                error: true,
            };
        }
        case EDIT_CAMPAIGN_PLAN: {
            return {
                ...state,
                loading: true,
            };
        }
        case EDIT_CAMPAIGN_PLAN_SUCCESS: {
            return {
                ...state,
                clientPlans: { ...state.clientPlans, data:[...state.clientPlans.data, action.payload]},
            };
        }
        case EDIT_CAMPAIGN_PLAN_ERROR: {
            return {
                ...state,
                error: true,
            };
        }
        default:
            return state;
    }
};

export default campaignPlansReducer;

/**
 * ASYNC THUNKS
 */

export const getCampaignPlans = () => 
    async(dispatch, getState) => {
        dispatch({
            type: GET_CAMPAIGN_PLANS,
        });
        try {
            const { AuthDuck } = getState();

            const config = {
                headers: {
                Authorization: `Bearer ${AuthDuck.userInfo.token}`,
                },
            };

            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/callCentre/plans`,
                config
            );
            dispatch({
                type: GET_CAMPAIGN_PLANS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: GET_CAMPAIGN_PLANS_ERROR,
            });
        }
    }

export const getCampaignPlanByClientId = (client_id) =>
    async(dispatch, getState) => {
        dispatch({
            type: GET_CAMPAIGN_PLANS_BY_CLIENT_ID,
        });
        try {
            const { AuthDuck } = getState();

            const config = {
                headers: {
                Authorization: `Bearer ${AuthDuck.userInfo.token}`,
                },
            };

           
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/callCentre/clients/${client_id}/plans`,
                config
            );
            dispatch({
                type: GET_CAMPAIGN_PLANS_BY_CLIENT_ID_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: GET_CAMPAIGN_PLANS_BY_CLIENT_ID_ERROR,
            });
        }
    }

export const editCampaignPlan = (plan) =>
    async(dispatch, getState) => {
        dispatch({
            type: EDIT_CAMPAIGN_PLAN,
        });
        try {
            const { AuthDuck } = getState();

            const config = {
                headers: {
                Authorization: `Bearer ${AuthDuck.userInfo.token}`,
                },
            };

            const { data } = await axios.patch(
                `${process.env.REACT_APP_API_URL}/callCentre/plans/${plan.id}`,
                plan,
                config
            );
            dispatch({
                type: EDIT_CAMPAIGN_PLAN_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: EDIT_CAMPAIGN_PLAN_ERROR,
            });
        }
    }




/**
 * SELECTORS
 */

export const selectClientPlanById = (state, id) => {
    const { clientPlans } = state.campaignPlans;
    const { data, success } = clientPlans;
    return success ? data.filter((plan) => plan.id === id)[0] : {}
}
