import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";
import { DialogContent } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addClient, RESET_CLIENT_EDIT } from "../../State/ClientsDuck";

export default function AddNewClient() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({ type: RESET_CLIENT_EDIT });
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button fullWidth variant="outlined" onClick={handleClickOpen}>
        Add New Client
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle> Add New Client</DialogTitle>

        <Formik
          initialValues={{
            name: "",
            active: true,
            updated_reason: "Created New Team",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            active: Yup.boolean().required("Active is required"),
          })}
          onSubmit={(values) => {
            dispatch(addClient(values));
            setTimeout(() => {
              handleClose();
            }, 1000);
          }}>
          {() => (
            <Form>
              <DialogContent>
                <TextfieldWrapper label="Name" name="name" />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Save</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
