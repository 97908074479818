import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { addTeamToQueue, getQaQueueTeams } from "./State/QaDuck";
import * as Yup from "yup";
import QueuesTeamsList from "./Components/Queues/QueuesTeamsList";
import { useDispatch, useSelector } from "react-redux";
import SelectWrapper from "../../../../../Components/FormComponents/Select";
import { useEffect } from "react";
import { getQaTeams } from "./State/QaTeamsDuck";
import { useParams } from "react-router-dom";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import { useNavigate } from "react-router-dom";

const QaQueueTeams = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: "RESET_QA_QUEUES",
    });
    dispatch(getQaTeams());
    dispatch(getQaQueueTeams(id));
  }, [dispatch, id]);

  const Qa = useSelector((state) => state.Qa);
  const { qaQueue, qaQueueTeams, addedTeamToQaQueueSuc } = Qa;

  const QaTeamsDuck = useSelector((state) => state.QaTeamsDuck);
  const { qaTeamsList } = QaTeamsDuck;

  const activeTeams = qaTeamsList.filter((team) => team.active);

  let activeQaTeams = qaQueueTeams.filter((team) => team.active === true);
  let inactiveQATeams = qaQueueTeams.filter((team) => team.active === false);

  return (
    <div>
      <AlertPopup
        open={addedTeamToQaQueueSuc}
        message="Team added to queue"
        severity="success"
      />
      <Card>
        <Stack
          sx={{ border: 1, p: 2 }}
          display="flex"
          justifyContent="center"
          alignContent="center">
          <Typography gutterBottom variant="h6">
            Teams
          </Typography>
          <Stack direction="row">
            <AddTeamToQueue
              id={id}
              queue={qaQueue}
              activeTeams={activeTeams}
              qaQueueTeams={qaQueueTeams}
            />
            <Button onClick={() => navigate("/CDA/QaConsole/QATeams")}>
              Create Teams
            </Button>
          </Stack>
        </Stack>
      </Card>

      {qaQueueTeams && (
        <QueuesTeamsList
          activeQaTeams={activeQaTeams}
          inactiveQATeams={inactiveQATeams}
        />
      )}
    </div>
  );
};

export default QaQueueTeams;

const AddTeamToQueue = ({ id, queue, activeTeams, qaQueueTeams }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  let availableTeams = activeTeams.filter(
    (team) => !qaQueueTeams.some((t) => t.team_id === team.id)
  );

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Add Team
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Activate Team</DialogTitle>

        <Formik
          initialValues={{
            team_id: "",
            active: true,
            languages: [],
          }}
          validationSchema={Yup.object({
            active: Yup.boolean().required("Active is required"),
          })}
          onSubmit={(values) => {
            dispatch(addTeamToQueue(id, values));
            dispatch({
              type: "RESET_QA_QUEUES",
            });
          }}>
          {() => (
            <Form>
              <DialogContent>
                <Stack sx={{ my: 4 }} spacing={2}>
                  <SelectWrapper
                    label="Team"
                    name="team_id"
                    options={availableTeams.map((team) => {
                      return {
                        value: team.id || "",
                        label: team.name || "",
                      };
                    })}
                  />
                </Stack>
              </DialogContent>
              <Button fullWidth variant="contained" type="submit">
                Add Team To Queue
              </Button>
              <Button fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
