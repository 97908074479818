import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import SelectWrapper from "../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import * as yup from "yup";
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import withAuth from "../../../Hoc/withAuth";
import countryList from "../../../countryList";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import InfoIcon from "@mui/icons-material/Info";
import CustomDatePicker from "../Components/CustomDatePicker";
import axios from 'axios'

const OETaxInfo = ({ employee_id, TaxInfo, submitted, modules, handleTabChange, disabled }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [mutationError, setMutationError] = useState("");
  const [successMessage, setSuccessMessage] = useState("Tax Information saved successfully!");

  const switchTab = (newTabIndex) => {
    handleTabChange(newTabIndex);
  };


  if (!employee_id) {
      navigate("/otp");
  }
  
  const idTypes = [
    { value: "RSA Identity", label: "RSA Identity" },
    { value: "Work Permit / Passport", label: "Work Permit / Passport" },
  ];

  const natureOfPersonOptions = [
    {
      value: "Individual with ID or passport no.",
      label: "Individual with ID or passport no.",
    },
    {
      value: "Individual without ID or Passport no.",
      label: "Individual without ID or Passport no.",
    },
    {
      value: "Director of private company/Member of CC/Sole Proprietor",
      label: "Director of private company/Member of CC/Sole Proprietor",
    },
  ];
  
  const mutation = useMutation({
    mutationFn: (values) => {
      if(values.id){
        setSuccessMessage("Tax Information updated successfully!")
        return axios.put(
          `${process.env.REACT_APP_API_URL}/onboarding/employees/taxInfo/${values.id}`,
          { ...values }
        );
      }else{
         return axios.post(
          `${process.env.REACT_APP_API_URL}/onboarding/employees/taxInfo/`,
          { ...values }
        );
      }
 
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["employeeInfo"]);
    },
    onError: async (error) => {
      setMutationError(error.response.data.message);
    }
  });

  const onSaveTaxInfoHandler = (values) => {
    mutation.mutate(values);
  }

  const warningText = "It is your responsibility to ensure that the information you provide below is accurate and complete.";

  return (
    <Box component="div">
      <Stack spacing={2} mb={2} mt={2}>
        <Typography variant="span">TAX INFORMATION</Typography>
        {
          <Typography color={"error"} fontSize={15}>
            {warningText}
          </Typography>
        }
      </Stack>
      
      {mutation.isLoading && (
        <AlertPopup open={true} message={"Saving Tax Information..."} severity={"info"} />
      )}

      {mutation.isSuccess && (
        <AlertPopup open={true} message={successMessage} />
      )}

      { mutationError && (
          <AlertPopup open={true} message={mutationError} severity={"error"} />
      ) }
      
      <Formik
        initialValues={{
          taxInfoId: TaxInfo?.id || "",
          employee_id,
          nature_of_person: TaxInfo?.nature_of_person || "",
          id_type: TaxInfo?.id_type || "identity",
          id_number: TaxInfo?.id_number || "",
          passport_number: TaxInfo?.passport_number || "",
          passport_country: TaxInfo?.passport_country || "",
          tax_reference_number: TaxInfo?.tax_reference_number || "",
          work_permit_issue_date:
            TaxInfo?.work_permit_issue_date || "01/07/2017",
          work_permit_expiry_date:
            TaxInfo?.work_permit_expiry_date || "01/07/2017",
          passport_issue_date: TaxInfo?.passport_issue_date || "01/07/2017",
          passport_expiry_date: TaxInfo?.passport_expiry_date || "01/07/2017",
        }}
        validationSchema={yup.object().shape({
          nature_of_person: yup.string().required("Nature of person required"),
          id_type: yup.string().required("Please choose ID Type"),
          id_number: yup.string().when("id_type", {
            is: "identity",
            then: yup
              .string()
              .required("ID Number is a required field")
              .test(
                "id_number",
                "Please provide valid ID number",
                function (num) {
                  let idNumber = num?.toString();
                  var correct = true;
                  if (idNumber?.length !== 13 || !!isNaN(parseFloat(num))) {
                    correct = false;
                  }

                  var tempDate = new Date(
                    idNumber?.substring(0, 2),
                    idNumber?.substring(2, 4) - 1,
                    idNumber?.substring(4, 6)
                  );
                  if (tempDate instanceof Date) {
                    correct = true;
                  } else {
                    correct = false;
                  }

                  var tempTotal = 0;
                  var checkSum = 0;
                  var multiplier = 1;

                  for (var i = 0; i < 13; ++i) {
                    tempTotal = parseInt(idNumber?.charAt(i)) * multiplier;
                    if (tempTotal > 9) {
                      tempTotal =
                        parseInt(tempTotal.toString().charAt(0)) +
                        parseInt(tempTotal.toString().charAt(1));
                    }
                    checkSum = checkSum + tempTotal;
                    multiplier = multiplier % 2 === 0 ? 1 : 2;
                  }
                  if (checkSum % 10 !== 0) {
                    correct = false;
                  }

                  if (correct) {
                    return true;
                  } else {
                    return false;
                  }
                }
              ),
          }),
          passport_number: yup.string().when("id_type", {
            is: "Work Permit / Passport",
            then: yup.string().required("Passport Number is a required field"),
          }),
          passport_country: yup.string().when("id_type", {
            is: "Work Permit / Passport",
            then: yup.string().required("Passport country is a required field"),
          }),
          work_permit_issue_date: yup.string().when("id_type", {
            is: "Work Permit / Passport",
            then: yup
              .string()
              .required(" Work permit issue date is a required field"),
          }),
          work_permit_expiry_date: yup.string().when("id_type", {
            is: "Work Permit / Passport",
            then: yup
              .string()
              .required(" Work permit expiry date is a required field"),
          }),
          passport_issue_date: yup.string().when("id_type", {
            is: "Work Permit / Passport",
            then: yup
              .string()
              .required("passport_issue_date is a required field"),
          }),
          passport_expiry_date: yup.string().when("id_type", {
            is: "Work Permit / Passport",
            then: yup
              .string()
              .required("passport_expiry_date is a required field"),
          }),
          tax_reference_number: yup
            .string()
            .min(10, "Tax number should be atleast 10 digits")
            .max(10, "Tax number cannot have more than 10 digits")
            .matches(/^\d+$/, {
              message: "Tax number should be only numbers",
            })
            .required("Tax number is required"),
        })}
        onSubmit={(values) => {
          onSaveTaxInfoHandler(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, initialValues, setFieldValue, handleSubmit }) => {
          return (
            <Form
              onSubmit={(jsEvent) => {
                const { name, value } = jsEvent.nativeEvent.submitter;
                setFieldValue(name, value);

                return handleSubmit(jsEvent);
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="nature_of_person"
                    label="Nature of person"
                    options={natureOfPersonOptions}
                    autoComplete={"off"}
                    disabled={disabled}
                  />
                </Grid>
                
                {!(values.nature_of_person === "Individual without ID or Passport no.") && (
                  <>
                    <Grid item xs={12} md={6}>
                      <SelectWrapper
                        name="id_type"
                        label="ID Type"
                        options={idTypes}
                        autoComplete={"off"}
                        disabled={disabled}
                      />
                    </Grid>

                    {values.id_type === "Work Permit / Passport" ? (
                      <>
                        <Grid item xs={12} md={6}>
                          <CustomDatePicker
                            name={"work_permit_issue_date"}
                            label={"Work Permit Issue Date"}
                            disabled={disabled}
                            inputFormat="dd/MM/yyyy"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomDatePicker
                            name={"work_permit_expiry_date"}
                            label={"Work Permit Expiry Date"}
                            disabled={disabled}
                            inputFormat="dd/MM/yyyy"
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            name="passport_country"
                            disablePortal
                            id="combo-box-demo"
                            options={countryList}
                            value={values.passport_country || ""}
                            onChange={(e, value) => {
                              console.log(value);
                              setFieldValue(
                                "passport_country",
                                value !== null
                                  ? value
                                  : initialValues.passport_country || ""
                              );
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Passport Country" />
                            )}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextfieldWrapper
                            name={"passport_number"}
                            placeholder={
                              "Complete if you DO NOT have a South African ID number."
                            }
                            label="Passport Number"
                            autoComplete={"off"}
                            disabled={disabled}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="Complete if you DO NOT have a South African ID number.">
                                    <IconButton color="secondary">
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomDatePicker
                            name={"passport_issue_date"}
                            label={"Passport Issue Date"}
                            disabled={disabled}
                            inputFormat="dd/MM/yyyy"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomDatePicker
                            name={"passport_expiry_date"}
                            label={"Passport Expiry Date"}
                            disabled={disabled}
                            inputFormat="dd/MM/yyyy"
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                          name={"id_number"}
                          placeholder={
                            "Complete if you have a South African ID number."
                          }
                          label="ID Number"
                          autoComplete={"off"}
                          disabled={disabled}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Complete if you have a South African ID number.">
                                  <IconButton color="secondary">
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    )}
                  </>
                )}

                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"tax_reference_number"}
                    placeholder={"Tax Reference Number"}
                    label="Tax Reference Number"
                    autoComplete={"off"}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Stack direction={"row"} justifyContent="center" spacing={2}>
                    <Button
                      variant="contained"
                      sx={{
                        width: 170,
                      }}
                      color="primary"
                      onClick={() => switchTab(1)}
                    >
                      Back
                    </Button>

                    {TaxInfo ? (
                      <>
                        <Button
                          variant="contained"
                          sx={{
                            width: 170,
                          }}
                          color="primary"
                          type="submit"
                          name="submitAction"
                          value="0"
                          disabled={disabled}
                        >
                          Update
                        </Button>
                      </>
                    ) : (
                        <Button
                          variant="contained"
                          sx={{
                            width: 170,
                          }}
                          type="submit"
                          color="primary"
                          name="submitAction"
                          value="0"
                          disabled={disabled}
                        >
                          Save
                        </Button>
                    )}
                    <Button
                      variant="contained"
                      sx={{
                        width: 170,
                      }}
                      color="primary"
                      onClick={() => switchTab(3)}
                    >
                      Next
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default withAuth(OETaxInfo);
