import axios from "axios";

const initialState = {
  salaryDeductionsList: [],
  salaryDeductionsLoading: false,
  salaryDeductionsError: null,
  salaryDeductionsSuccess: null,
  salaryDeductionsForm: {
    id: null,
    company_name: null,
    deduction_allowed: null,
  },
  salaryDeductionsFormLoading: false,
  salaryDeductionsFormError: null,
  salaryDeductionsFormSuccess: null,
};

const GET_SALARY_DEDUCTIONS_LIST = "GET_SALARY_DEDUCTIONS_LIST";
const GET_SALARY_DEDUCTIONS_LIST_SUCCESS =
  "GET_SALARY_DEDUCTIONS__LIST_SUCCESS";
const GET_SALARY_DEDUCTIONS_LIST_ERROR = "GET_SALARY_DEDUCTIONS_LIST_ERROR";

const UPLOAD_SALARY_DEDUCTIONS_LIST = "UPLOAD_SALARY_DEDUCTIONS_LIST";
const UPLOAD_SALARY_DEDUCTIONS_LIST_SUCCESS =
  "UPLOAD_SALARY_DEDUCTIONS_LIST_SUCCESS";
const UPLOAD_SALARY_DEDUCTIONS_LIST_ERROR =
  "UPLOAD_SALARY_DEDUCTIONS_LIST_ERROR";

export const RESET_SALARY_DEDUCTIONS_STATE = "RESET_SALARY_DEDUCTIONS_STATE";

const SalaryDeductionsDuck = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALARY_DEDUCTIONS_LIST:
      return {
        ...state,
        salaryDeductionsLoading: true,
        salaryDeductionsError: null,
        salaryDeductionsSuccess: null,
      };
    case GET_SALARY_DEDUCTIONS_LIST_SUCCESS:
      return {
        ...state,
        salaryDeductionsLoading: false,
        salaryDeductionsError: null,

        salaryDeductionsList: action.payload,
      };
    case GET_SALARY_DEDUCTIONS_LIST_ERROR:
      return {
        ...state,
        salaryDeductionsLoading: false,
        salaryDeductionsError: action.payload,
        salaryDeductionsSuccess: null,
      };

    case UPLOAD_SALARY_DEDUCTIONS_LIST:
      return {
        ...state,
        salaryDeductionsLoading: true,
        salaryDeductionsError: null,
        salaryDeductionsSuccess: null,
      };
    case UPLOAD_SALARY_DEDUCTIONS_LIST_SUCCESS:
      return {
        ...state,
        salaryDeductionsLoading: false,
        salaryDeductionsError: null,
        salaryDeductionsSuccess: true,
        salaryDeductionsList: action.payload,
      };
    case UPLOAD_SALARY_DEDUCTIONS_LIST_ERROR:
      return {
        ...state,
        salaryDeductionsLoading: false,
        salaryDeductionsError: action.payload,
        salaryDeductionsSuccess: null,
      };

    case RESET_SALARY_DEDUCTIONS_STATE:
      return {
        ...state,
        salaryDeductionsLoading: false,
        salaryDeductionsError: null,
        salaryDeductionsSuccess: null,
      };

    default:
      return state;
  }
};

export default SalaryDeductionsDuck;

export const getSalaryDeductionsList = () => async (dispatch, getState) => {
  dispatch({ type: GET_SALARY_DEDUCTIONS_LIST });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/santam/salaryDeductions`,
      config
    );

    dispatch({ type: GET_SALARY_DEDUCTIONS_LIST_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: GET_SALARY_DEDUCTIONS_LIST_ERROR,
      payload: error.message,
    });
  }
};

export const addSalaryDeductionsList =
  (addSalaryDeductionsList) => async (dispatch, getState) => {
    dispatch({ type: UPLOAD_SALARY_DEDUCTIONS_LIST });
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/santam/replaceSalaryDeductionsList`,
        addSalaryDeductionsList,
        config
      );

      dispatch({
        type: UPLOAD_SALARY_DEDUCTIONS_LIST_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: UPLOAD_SALARY_DEDUCTIONS_LIST_ERROR,
        payload: error.message,
      });
    }
  };
