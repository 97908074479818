import { Alert, Box, LinearProgress, Typography, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import React, { useEffect } from "react";
import DateRangeSelector from "../../../../../../Components/FormComponents/DateRangeSelector";
import withAuth from "../../../../../../Hoc/withAuth";

const QaAgentReport = ({ user }) => {
  const { token } = user;

  const [value, setValue] = React.useState([
    dayjs().startOf("month"),
    dayjs().endOf("month"),
  ]);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { data, isLoading, refetch, isRefetching, isError, error } = useQuery(
    ["getAgents"],
    () => {
      // format value[0] to disregard gmt offset
      const start = dayjs(value[0])
        .tz("Africa/Johannesburg")
        .format("YYYY-MM-DD");
      // format value[1] to disregard gmt offset
      const end = dayjs(value[1])
        .tz("Africa/Johannesburg")
        .format("YYYY-MM-DD");
      return axios.get(
        `${process.env.REACT_APP_API_URL}/reports/QaReports/QaAgentReports?startDate=${start}&endDate=${end}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  // console.log("AgentData", data?.data?.data);

  let evaluations = data?.data?.data.map((data, index) => {
    return {
      ...data,
      id: index + 1,
    };
  });

  useEffect(() => {
    refetch();
  }, [value, refetch]);

  const columns = [
    {
      field: "qa_agent",
      headerName: "QA Agent Name",
      width: 200,
      align: "left",
    },
    {
      field: "from",
      headerName: "From (Start Date)",

      width: 150,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD-MMM-YYYY");
      },
    },
    {
      field: "to",
      headerName: "To (End Date)",
      width: 150,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD-MMM-YYYY");
      },
    },
    {
      field: "evaluations",
      headerName: "Evaluations",
      width: 100,
    },
    {
      field: "incomplete",
      headerName: "Incomplete",
      width: 100,
    },
    {
      field: "fail",
      headerName: "Failed",
      width: 100,
    },
    {
      field: "dispute_rejected",
      headerName: "Dispute - Rejected",
      width: 120,
    },
    {
      field: "complete",
      headerName: "Completed",
      width: 100,
    },
    {
      field: "previous_failed",
      headerName: "Previous Fail %",
      width: 120,
      renderCell: (params) => {
        return (
          <Typography>
            {
              // round to decimal value 2 decimal places on the parameter value
              parseFloat(
                (Number(params.row.previous_fail) /
                  Number(params.row.complete)) *
                  100
              ).toFixed(2)
            }
          </Typography>
        );
      },
    },
    {
      field: "evaluation_hours",
      type: "number",
      headerName: "Evaluation Hours",
      aggregationFunction: "average",
      width: 150,
      renderCell: (params) => {
        return (
          <Typography>
            {
              // round to decimal value 2 decimal places on the parameter value
              parseFloat(params.row.evaluation_hours).toFixed(2)
            }
          </Typography>
        );
      },
    },
    {
      field: "talktime",
      type: "number",
      headerName: "Talktime Hours",
      aggregationFunction: "average",
      width: 150,
      renderCell: (params) => {
        return (
          <Typography>
            {
              // round to decimal value 2 decimal places on the parameter value
              parseFloat(params.row.talktime).toFixed(2)
            }
          </Typography>
        );
      },
    },
    // {
    //   field: "Busy",
    //   headerName: "Total Busy",
    //   width: 100,
    // },
    // {
    //   field: "Disputed",
    //   headerName: "Total Disputed (Current Status)",
    //   width: 100,
    // },
    // {
    //   field: "failureRate",
    //   headerName: "Failure Rate % (Pending Ratio)",
    //   width: 100,
    //   valueFormatter: (params) => {
    //     return params.value + "%";
    //   },
    // },
    // {
    //   field: "failed",
    //   headerName: "Current Failed (Pending)",
    //   width: 100,
    // },
    // {
    //   field: "failedPercentage",
    //   headerName: "Current Pending %",
    //   width: 100,
    //   type: "number",

    //   valueFormatter: (params) => {
    //     return params.value + "%";
    //   },
    // },
    // {
    //   field: "averageResults",
    //   type: "number",
    //   headerName: "Average Qa Score ",
    //   aggregationFunction: "average",
    //   width: 100,
    //   renderCell: (params) => {
    //     return (
    //       <Typography
    //         color={Number(params.row.averageResults) < 9 ? "error" : "success"}
    //       >
    //         {params.row.averageResults} / 10
    //       </Typography>
    //     );
    //   },
    // },
    // {
    //   field: "totalTime",
    //   type: "number",
    //   headerName: "Total Time spent on Evaluations",
    //   aggregationFunction: "average",
    //   width: 100,
    //   renderCell: (params) => {
    //     return (
    //       <Typography>
    //         {dayjs(params.row.totalTime).format("HH:mm:ss")}
    //       </Typography>
    //     );
    //   },
    // },
    // {
    //   field: "averageTime",
    //   type: "number",
    //   headerName: "Average Time spent on Evaluations (HH:mm:ss)",
    //   aggregationFunction: "average",
    //   width: 100,
    //   renderCell: (params) => {
    //     return (
    //       <Typography>
    //         {dayjs(params.row.averageTime).format("HH:mm:ss")}
    //       </Typography>
    //     );
    //   },
    // },
  ];

  return (
    <Stack sx={{ mt: 3 }}>
      <DateRangeSelector value={value} setValue={setValue} />

      {(isRefetching || isLoading) && <LinearProgress />}

      {isError && (
        <Alert severity="error">
          {error?.response?.data?.message || "Something went wrong"}
        </Alert>
      )}

      {
        <Box sx={{ mt: 4 }}>
          <Grid container>
            {evaluations && evaluations.length > 0 ? (
              <div
                style={{
                  height: 750,
                  width: "94vw",
                }}>
                <DataGridPremium
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  rows={evaluations}
                  columns={columns}
                  pagination
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                      },
                    },
                    pagination: { paginationModel: { pageSize: 5 } },
                  }}
                  pageSizeOptions={[5, 10, 15, 25]}
                />
              </div>
            ) : (
              <Alert severity="info">No Data Found</Alert>
            )}
          </Grid>
        </Box>
      }
    </Stack>
  );
};

export default withAuth(QaAgentReport);
