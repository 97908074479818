import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

import { useNavigate } from "react-router-dom";

import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import BusinessIcon from "@mui/icons-material/Business";

const AdminModules = () => {
  const navigate = useNavigate();
  return (
    <>
      <Tooltip title="User Management">
        <ListItem
          selected={window.location.pathname === "/UserManagement"}
          button
          onClick={() => navigate("UserManagement")}>
          <ListItemIcon>
            <SupervisedUserCircleIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>User Management</Typography>
          </ListItemText>
        </ListItem>
      </Tooltip>
      <Tooltip title="Companies Management">
        <ListItem
          selected={window.location.pathname === "/CompaniesManagement"}
          button
          onClick={() => navigate("CompaniesManagement")}>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>Company Management</Typography>
          </ListItemText>
        </ListItem>
      </Tooltip>
    </>
  );
};

export default AdminModules;
