import { Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { getCampaignSales } from "./State/CampaingsDuck";
import withAuth from "../../../../../Hoc/withAuth";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CampaignSales = ({ user }) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [value, setValue] = React.useState([dayjs().startOf("month"), dayjs()]);

  let rows = [];

  const dispatch = useDispatch();

  const Campaigns = useSelector((state) => state.Campaigns);

  const { campaignSales } = Campaigns;

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const [search, setSearch] = React.useState("");
  const [doSearch, setDoSearch] = React.useState(false);

  const getAllCampaigns = useQuery(
    ["Leads", search],
    () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/leads/search?search=${search}`,
        config
      );
    },
    {
      enabled: doSearch && search.length > 0,
      onSuccess: () => setDoSearch(false),
    }
  );

  let searchedSales = getAllCampaigns.data?.data?.data;

  if (searchedSales) {
    rows = searchedSales.map((sale, index) => {
      return {
        ...sale,
        id: index,
      };
    });
  }
  if (campaignSales) {
    rows = campaignSales.map((sale, index) => {
      return {
        ...sale,
        id: index,
      };
    });
  }

  const HandleRequest = () => {
    dispatch(getCampaignSales({ id, value }));
  };

  const handleRowClick = (params) => {
    navigate(`/CDA/CampaignLeads/Lead/${params.row._id}/lead`);
  };

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <TextField
          fullWidth
          id="search"
          label="Search Lead ID"
          variant="outlined"
          type="number"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setDoSearch(true)}>
          Search
        </Button>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Select Date Range
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <>
            <DateRangePicker
              value={value}
              onChange={(newValue) => setValue(newValue)}
            />
            <Button onClick={HandleRequest} sx={{ mt: 4 }} variant="contained">
              Get Stats
            </Button>
          </>
        </LocalizationProvider>
      </Stack>

      <Stack mt={2}>
        <Typography>
          Search Results{" "}
          {searchedSales && searchedSales.length > 0 ? searchedSales.length : 0}
        </Typography>
        {searchedSales && searchedSales.length > 0 && (
          <DataGridPremium
            onRowClick={handleRowClick}
            getRowId={(row) => row._id}
            rows={searchedSales}
            columns={columns}
          />
        )}
      </Stack>

      <Box sx={{ width: "100%", mt: 4 }}>
        {campaignSales && campaignSales?.length > 0 && (
          <DataGridPremium
            autoHeight
            onRowClick={handleRowClick}
            columns={columns}
            rows={rows}
            getRowId={(row) => row?.id}
            slots={{ toolbar: GridToolbar }}
          />
        )}
      </Box>
    </>
  );
};

export default withAuth(CampaignSales);

const columns = [
  { field: "cm_sales.id", headerName: "Sale Id", width: 200 },
  { field: "cm_sales.lead_id", headerName: "Lead Id", width: 100 },
  { field: "cm_sales.reference_no", headerName: "Reference No", width: 200 },
  { field: "cm_sales.premium", headerName: "Premium", width: 200 },
  { field: "cm_sales.debit_date", headerName: "Debit Date", width: 200 },
  { field: "cm_sales.note1", headerName: "Sale Note1", width: 200 },
  { field: "cm_sales.note2", headerName: "Sale Note2", width: 200 },
  { field: "cm_sales.status", headerName: "Status", width: 200 },
  { field: "cm_sales.update_reason", headerName: "Update Reason", width: 200 },
  { field: "cm_sales.createdAt", headerName: "Sale Created At", width: 200 },
  { field: "cm_sales.updatedAt", headerName: "Sale Updated At", width: 200 },
  { field: "cm_sales.User.id", headerName: "Sale Agent ID", width: 200 },
  { field: "cm_sales.User.name", headerName: "Sale Agent Name", width: 200 },
  {
    field: "cm_sales.User.surname",
    headerName: "Sale Agent Surname",
    width: 200,
  },
  { field: "cm_sales.User.email", headerName: "Sale Agent Email", width: 200 },

  {
    field: "cm_sales.cm_sales_dispositions.cc_disposition.name",
    headerName: "Disposition Name",
    width: 200,
  },

  {
    field: "cm_sales.cm_sales_dispositions.cc_disposition_detail.name",
    headerName: "Disposition Detail Name",
    width: 200,
  },

  {
    field: "_id",
    headerName: "Lead ID",
    width: 200,
  },
  {
    field: "source",
    headerName: "Lead Source",
    width: 200,
  },
  {
    field: "external_ref",
    headerName: "Lead External Ref",
    width: 200,
  },
  {
    field: "old_system_id",
    headerName: "Lead Old System ID",
    width: 200,
  },
  {
    field: "system_id",
    headerName: "Lead System ID",
    width: 200,
  },
  {
    field: "campaign_id",
    headerName: "Lead Campaign ID",
    width: 200,
  },
  {
    field: "title",
    headerName: "Lead Title",
    width: 200,
  },
  {
    field: "initials",
    headerName: "Lead Initials",
    width: 200,
  },
  {
    field: "first_name",
    headerName: "Lead First Name",
    width: 200,
  },
  {
    field: "surname",
    headerName: "Lead Surname",
    width: 200,
  },
  {
    field: "id_no",
    headerName: "Lead ID No",
    width: 200,
  },
  {
    field: "dob",
    headerName: "Lead DOB",
    width: 200,
  },
  {
    field: "gender",
    headerName: "Lead Gender",
    width: 200,
  },
  {
    field: "marital_status",
    headerName: "Lead Marital Status",
    width: 200,
  },
  {
    field: "mobile_no",
    headerName: "Lead Mobile No",
    width: 200,
  },
  {
    field: "home_no",
    headerName: "Lead Home No",
    width: 200,
  },
  {
    field: "work_no",
    headerName: "Lead Work No",
    width: 200,
  },
  {
    field: "email",
    headerName: "Lead Email",
    width: 200,
  },
  {
    field: "addr1",
    headerName: "Lead Addr1",
    width: 200,
  },
  {
    field: "addr2",
    headerName: "Lead Addr2",
    width: 200,
  },
  {
    field: "addr3",
    headerName: "Lead Addr3",
    width: 200,
  },
  {
    field: "addr4",
    headerName: "Lead Addr4",
    width: 200,
  },
  {
    field: "addr5",
    headerName: "Lead Addr5",
    width: 200,
  },
  {
    field: "postal_code",
    headerName: "Lead Postal Code",
    width: 200,
  },
  {
    field: "addr1_res",
    headerName: "Lead Addr1 Res",
    width: 200,
  },
  {
    field: "addr2_res",
    headerName: "Lead Addr2 Res",
    width: 200,
  },
  {
    field: "addr3_res",
    headerName: "Lead Addr3 Res",
    width: 200,
  },
  {
    field: "addr4_res",
    headerName: "Lead Addr4 Res",
    width: 200,
  },
  {
    field: "addr5_res",
    headerName: "Lead Addr5 Res",
    width: 200,
  },
  {
    field: "postal_code_res",
    headerName: "Lead Postal Code Res",
    width: 200,
  },
  {
    field: "acc_holder_name",
    headerName: "Lead Acc Holder Name",
    width: 200,
  },
  {
    field: "bank_name",
    headerName: "Lead Bank Name",
    width: 200,
  },
  {
    field: "branch_code",
    headerName: "Lead Branch Code",
    width: 200,
  },
  {
    field: "acc_no",
    headerName: "Lead Acc No",
    width: 200,
  },
  {
    field: "acc_type",
    headerName: "Lead Acc Type",
    width: 200,
  },
  {
    field: "bank",
    headerName: "Lead Bank",
    width: 200,
  },
  { field: "full_note", headerName: "Lead full_note", width: 200 },
  { field: "note1", headerName: "Lead note1", width: 200 },
  { field: "note1_heading", headerName: "Lead note1_heading", width: 200 },
  { field: "note2", headerName: "Lead note2", width: 200 },
  { field: "note2_heading", headerName: "Lead note2_heading", width: 200 },
  { field: "note3", headerName: "Lead note3", width: 200 },
  { field: "note3_heading", headerName: "Lead note3_heading", width: 200 },
  { field: "note4", headerName: "Lead note4", width: 200 },
  { field: "note4_heading", headerName: "Lead note4_heading", width: 200 },
  { field: "note5", headerName: "Lead note5", width: 200 },
  { field: "note5_heading", headerName: "Lead note5_heading", width: 200 },
  { field: "note6", headerName: "Lead note6", width: 200 },
  { field: "note6_heading", headerName: "Lead note6_heading", width: 200 },
  { field: "note7", headerName: "Lead note7", width: 200 },
  { field: "note7_heading", headerName: "Lead note7_heading", width: 200 },
  { field: "note8", headerName: "Lead note8", width: 200 },
  { field: "note8_heading", headerName: "Lead note8_heading", width: 200 },
  { field: "note9", headerName: "Lead note9", width: 200 },
  { field: "note9_heading", headerName: "Lead note9_heading", width: 200 },
  { field: "note10", headerName: "Lead note10", width: 200 },
  { field: "note10_heading", headerName: "Lead note10_heading", width: 200 },
  { field: "note11", headerName: "Lead note11", width: 200 },
  { field: "note11_heading", headerName: "Lead note11_heading", width: 200 },
  { field: "note12", headerName: "Lead note12", width: 200 },
  { field: "note12_heading", headerName: "Lead note12_heading", width: 200 },
  { field: "note13", headerName: "Lead note13", width: 200 },
  { field: "note13_heading", headerName: "Lead note13_heading", width: 200 },
  { field: "note14", headerName: "Lead note14", width: 200 },
  { field: "note14_heading", headerName: "Lead note14_heading", width: 200 },
  { field: "note15", headerName: "Lead note15", width: 200 },
  { field: "note15_heading", headerName: "Lead note15_heading", width: 200 },
  { field: "check_date", headerName: "Lead check_date", width: 200 },
  { field: "upload_date", headerName: "Lead upload_date", width: 200 },
  { field: "rejection_date", headerName: "Lead rejection_date", width: 200 },
  {
    field: "rejection_reason",
    headerName: "Lead rejection_reason",
    width: 200,
  },
  { field: "removed_date", headerName: "Lead removed_date", width: 200 },
  { field: "platform", headerName: "Lead platform", width: 200 },
  { field: "lead_type", headerName: "Lead lead_type", width: 200 },
  { field: "text_search", headerName: "Lead text_search", width: 200 },
  { field: "createdAt", headerName: "Lead createdAt", width: 200 },
  { field: "updatedAt", headerName: "Lead updatedAt", width: 200 },
];
