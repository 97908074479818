import React from "react";
import ModuleItem from "./ModuleItem";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const RMAModule = () => {
  return (
    <>
      <ModuleItem
        Icon={UploadFileIcon}
        link="/RMA/FileUploader"
        title="Upload File"
      />
    </>
  );
};

export default RMAModule;
