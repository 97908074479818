import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";

import { useDispatch } from "react-redux";
import AlertPopup from "../../../Components/Bits/AlertPopup";

import * as yup from "yup";
import { useFormik } from "formik";
import { editProfile } from "../../../Redux/Ducks/UserProfileDuck";
// import UserManager from "../../Admin/components/UserManager";

const UserProfileForm = ({ id, User, success, error, loading }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    surname: "",
    contact_number: "",
    department: "",
    manager: "",
    employee_no: "",
    id_number: "",
  });

  useEffect(() => {
    setFormData({
      name: User.name || "",
      email: User.email || "",
      surname: User.surname || "",
      contact_number: User.contact_number || "",
      department: User.department || "",
      manager: User.manager || "",
      employee_no: User.employee_no || "",
      id_number: User.id_number || "",
    });
  }, [User]);

  const formik = useFormik({
    initialValues: formData,
    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
      surname: yup.string().required("Surname is required"),
      email: yup.string().email("Invalid email").required("Email is required"),
      department: yup.string().required("Department is required"),
      contact_number: yup
        .string()
        .min(9, "Too Short")
        .max(11, "Too Long")
        .required("Contact number is required"),
      manager: yup.string().required("Manager is required"),
      employee_no: yup.string().required("Employee number is required"),
      id_number: yup
        .string()
        .min(13, "Too Short")
        .max(13, "Too Long")
        .required("ID number is required"),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(editProfile(values));
    },
  });

  return (
    <>
      <Stack my={4}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.errors.name && formik.touched.name}
            helperText={
              formik.errors.name && formik.touched.name
                ? "Name is required"
                : ""
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            id="surname"
            name="surname"
            label="Surname"
            type="text"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.surname}
            error={formik.errors.surname && formik.touched.surname}
            helperText={
              formik.errors.surname && formik.touched.surname
                ? "Surname is required"
                : ""
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            id="emailAddress"
            label="Email Address"
            name="email"
            type="email"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.errors.email && formik.touched.email}
            helperText={
              formik.errors.email && formik.touched.email ? "Invalid email" : ""
            }
          />

          <TextField
            variant="outlined"
            margin="normal"
            id="contact_number"
            label="Contact Number"
            name="contact_number"
            type="number"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contact_number}
            error={
              formik.errors.contact_number && formik.touched.contact_number
            }
            helperText={
              formik.touched.contact_number && formik.errors.contact_number
                ? formik.errors.contact_number
                : ""
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            id="department"
            label="Department"
            name="department"
            type="text"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.department}
            error={formik.errors.department && formik.touched.department}
            helperText={
              formik.errors.department && formik.touched.department
                ? "Department is required"
                : ""
            }
          />

          {/* <UserManager
            formData={formik.values.manager}
            handleChange={formik.handleChange}
            error={formik.errors.manager && formik.touched.manager}
            helperText={formik.errors.manager && formik.touched.manager}
            onBlur={formik.handleBlur}
          /> */}

          <TextField
            variant="outlined"
            margin="normal"
            id="employee_no"
            label="Employee No"
            name="employee_no"
            type="text"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.employee_no}
            error={formik.errors.employee_no && formik.touched.employee_no}
            helperText={
              formik.errors.employee_no && formik.touched.employee_no
                ? "Employee number is required"
                : ""
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            id="id_number"
            label="ID Number"
            name="id_number"
            type="number"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.id_number}
            error={formik.errors.id_number && formik.touched.id_number}
            helperText={
              formik.touched.id_number && formik.errors.id_number
                ? formik.errors.id_number
                : ""
            }
          />

          <Stack mt={4} direction="row" justifyContent="space-between">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" type="submit">
                Save
              </Button>
            )}
          </Stack>
        </form>
        <Box mt={4}>
          <AlertPopup
            severity="success"
            open={success}
            message="Updated successfully"
          />
          <AlertPopup
            severity="error"
            open={error}
            message="Error updating user"
          />

          <AlertPopup severity="error" open={error}>
            error
          </AlertPopup>
        </Box>
      </Stack>
    </>
  );
};

export default UserProfileForm;
