import { FormControl } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers'
import "dayjs/locale/en-gb";
import { useField, useFormikContext } from "formik";
import dayjs from "dayjs";

const DOBPicker = ({ name, ...otherProps }) => {
  const [field, mata] = useField(name);
  const { setFieldValue } = useFormikContext();

  const configTextfield = {
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  const handleChange = (e) => {
    setFieldValue(name, e);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <FormControl fullWidth>
        <DatePicker
          openTo="year"
          views={["year", "month", "day"]}
          label="Date Of Birth"
          variant="inline"
          value={dayjs(field.value)}
          {...configTextfield}
          inputVariant="outlined"
          onChange={handleChange}
          fullWidth
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default DOBPicker;
