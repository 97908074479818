import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import DateSelectorWrapper from "../../../../../Components/FormComponents/DateSelectorWrapper";
import dayjs from "dayjs";
import TextfieldWrapper from "../../../../../Components/FormComponents/TextfieldWrapper";
import { createTarget, getAllTargets } from "./State/TargetsDuck";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { getUsersOfModule } from "../../../../../Redux/Ducks/AdminUserManagement";

const AddNewTargets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedUser, setSelectedUser, success] = useState("");

  useEffect(() => {
    dispatch(getUsersOfModule("call-center-user"));
  }, [dispatch]);

  const AdminUsers = useSelector((state) => state.AdminUsers);
  const { usersOfModule } = AdminUsers;

  console.log(usersOfModule, "usersOfModule");

  return (
    <Stack spacing={2}>
      {success && usersOfModule.length > 0 && (
        <>
          <FormControl fullWidth>
            <InputLabel id="select-team-label">Select User</InputLabel>
            <Select
              labelId="select-team-label"
              id="select-team"
              value={selectedUser}
              label="Select User"
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              {usersOfModule[0]?.Modules_users.map((user) => {
                return (
                  <MenuItem key={user?.user_id} value={user}>
                    {user?.User?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </>
      )}

      {selectedUser && (
        <Formik
          initialValues={{
            user_id: selectedUser.user_id,
            date_from: dayjs(),
            date_to: dayjs(),
            sales_target: "",
            ci_target: "",
            scr_target: "",
            hours_target: "",
            pending_target: "",
            manager_id: selectedUser.User.manager || "",
          }}
          validationSchema={Yup.object({
            user_id: Yup.string().required("Required"),
            manager_id: Yup.string().required(
              "User does not have a manager Contact Admin to assign a manager",
            ),
            sales_target: Yup.number().required("Required"),
            ci_target: Yup.number().required("Required"),
            scr_target: Yup.number().required("Required"),
            hours_target: Yup.number().required("Required"),
            pending_target: Yup.number().required("Required"),
          })}
          onSubmit={(values) => {
            values.date_from = values.date_from.format("YYYY-MM-DD");
            values.date_to = values.date_to.format("YYYY-MM-DD");
            dispatch(createTarget(values));
            dispatch(getAllTargets());
            setTimeout(() => {
              navigate("/CDA/CallCenter/TargetsMain/UserList");
            }, 1000);
          }}
        >
          {({ errors }) => {
            return (
              <Form>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <DateSelectorWrapper name="date_from" label="Date From" />
                    <DateSelectorWrapper name="date_to" label="Date To" />
                  </Stack>
                  <TextfieldWrapper
                    type="number"
                    name="sales_target"
                    label="Sales Target"
                  />
                  <TextfieldWrapper
                    type="number"
                    name="ci_target"
                    label="CI Target"
                  />
                  <TextfieldWrapper
                    type="number"
                    name="scr_target"
                    label="SCR Target"
                  />
                  <TextfieldWrapper
                    type="number"
                    name="hours_target"
                    label="Hours Target"
                  />
                  <TextfieldWrapper
                    name="pending_target"
                    label="Pending Target"
                  />

                  {errors && errors.manager_id && (
                    <Alert severity="error">{errors.manager_id}</Alert>
                  )}

                  <Button type="submit" color="secondary" variant="contained">
                    Save
                  </Button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      )}
    </Stack>
  );
};

export default AddNewTargets;
