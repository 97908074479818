import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useMutation } from "@tanstack/react-query";
import { updateTeamMemberStatus } from "../Services/api";
import { useQueryClient } from "@tanstack/react-query";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";
import { Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DeactivateModal({ open, onClose, row, campaignId }) {
  const queryClient = useQueryClient();

  const updateStatusMutation = useMutation(
    ({ userId, active }) => updateTeamMemberStatus(campaignId, userId, !active),
    {
      onSuccess: () => {
        console.log("Status updated successfully");
        onClose();
        queryClient.invalidateQueries("team");
      },
      onError: (error) => {
        console.error("Error updating status:", error);
      },
    }
  );

  const handleStatusToggle = () => {
    if (row) {
      console.log(
        `Toggling status for userId: ${row.id}, current active: ${row.active}`
      );
      updateStatusMutation.mutate({ userId: row.id, active: row.active });
    } else {
      console.error("No row data available");
    }
  };

  return (
    <Stack>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={onClose}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Deactivate Team Member
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to deactivate this team member with email{" "}
            <strong>{row?.email}</strong>?
          </Typography>

          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              color="error"
              onClick={handleStatusToggle}
              disabled={updateStatusMutation.isLoading}
              sx={{ mr: 2 }}
            >
              Deactivate
            </Button>
          </Box>
        </Box>
      </Modal>

      <AlertPopup
        open={updateStatusMutation.isSuccess}
        severity="success"
        message="Member Deactivated successfully"
      />
      <AlertPopup
        open={updateStatusMutation.isError}
        severity="error"
        message="Failed to deactivate mamber"
      />
    </Stack>
  );
}
