import axios from "axios";

const UPLOAD_FILE = "UPLOAD_FILE";
const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

const GET_ALL_MY_FILES = "GET_ALL_MY_FILES";
const GET_ALL_MY_FILES_SUCCESS = "GET_ALL_MY_FILES_SUCCESS";
const GET_ALL_MY_FILES_FAILURE = "GET_ALL_MY_FILES_FAILURE";

export const RESET_FILE_UPLOAD = "RESET_FILE_UPLOAD";

const defaultState = {
  isLoading: false,
  isLoaded: false,
  isError: false,
  error: null,
  allFiles: [],
};

export default function FileUpload(state = defaultState, action) {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        error: null,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        uploadedFile: true,
        isError: false,
        error: null,
        // Add to all files
        files: [...state.files, action.payload],
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: true,
        error: action.payload,
      };

    case GET_ALL_MY_FILES:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        error: null,
      };
    case GET_ALL_MY_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        error: null,
        files: action.payload,
      };
    case GET_ALL_MY_FILES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: true,
        error: action.payload,
      };

    case RESET_FILE_UPLOAD:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: false,
        uploadedFile: false,
        error: null,
      };

    default:
      return state;
  }
}

export const UploadFile =
  ({ data }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: UPLOAD_FILE,
      });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/RMA/fileUpload`,
        data,
        config
      );

      dispatch({
        type: UPLOAD_FILE_SUCCESS,
        payload: res?.data,
      });

      // Storing the User Info in the local storage
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: UPLOAD_FILE_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getAllMyFiles = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_MY_FILES,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/RMA/fileList`,
      config
    );

    dispatch({
      type: GET_ALL_MY_FILES_SUCCESS,
      payload: data?.data,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: GET_ALL_MY_FILES_FAILURE,
      payload: error.response.data.message,
    });
  }
};
