export const branchCodes = [
  { name: "FNB", branches: [{ branch: "Universal", code: "250655" }] },
  { name: "RMB", branches: [{ branch: "Universal", code: "250655" }] },
  {
    name: "Absa Bank Limited",
    branches: [{ branch: "Universal", code: "632005" }],
  },
  {
    name: "Capitec Bank",
    branches: [{ branch: "Universal", code: "470010" }],
  },
  {
    name: "Nedbank Limited",
    branches: [{ branch: "Universal", code: "198765" }],
  },
  {
    name: "Standard Bank Limited",
    branches: [{ branch: "Universal", code: "051001" }],
  },
  {
    name: "Access Bank (South Africa) Ltd",
    branches: [{ branch: "Universal", code: "410105" }],
  },
  {
    name: "African Bank",
    branches: [{ branch: "Universal", code: "430000" }],
  },
  {
    name: "Albaraka Bank",
    branches: [{ branch: "Universal", code: "800000" }],
  },
  {
    name: "Bank Zero",
    branches: [{ branch: "Universal", code: "888000" }],
  },
  {
    name: "BNP Paribas",
    branches: [{ branch: "Universal", code: "688000" }],
  },
  {
    name: "Bidvest Bank",
    branches: [{ branch: "Universal", code: "462005" }],
  },
  {
    name: "CitiBank",
    branches: [{ branch: "Universal", code: "350005" }],
  },
  {
    name: "Discovery Bank",
    branches: [{ branch: "Universal", code: "679000" }],
  },
  {
    name: "Finbond Mutual Bank",
    branches: [{ branch: "Universal", code: "589000" }],
  },
  {
    name: "Finbond Net1",
    branches: [{ branch: "Universal", code: "591000" }],
  },
  {
    name: "Grindrod Bank",
    branches: [{ branch: "Universal", code: "584000" }],
  },
  {
    name: "HBZ Bank",
    branches: [
      { branch: "Boksburg", code: "570100" },
      { branch: "Fordsburg", code: "570105" },
      { branch: "Grey Street, Durban", code: "570126" },
      { branch: "Laudium, Pretoria", code: "570145" },
      { branch: "Lenasia", code: "570137" },
      { branch: "Pietermaritzburg", code: "570326" },
      { branch: "Polokwane", code: "570110" },
      { branch: "Rosebank", code: "570112" },
      { branch: "Vereeniging", code: "570101" },
      { branch: "Westville", code: "570226" },
    ],
  },
  {
    name: "HSBC Bank",
    branches: [{ branch: "Universal", code: "587000" }],
  },
  {
    name: "Habib Overseas Bank",
    branches: [
      { branch: "Cape Town", code: "700066" },
      { branch: "Durban", code: "700026" },
      { branch: "Johannesburg", code: "700005" },
      { branch: "Johannesburg", code: "701505" },
      { branch: "Laudium, Pretoria", code: "700045" },
      { branch: "Lenasia", code: "700037" },
    ],
  },
  {
    name: "Investec Bank",
    branches: [{ branch: "Universal", code: "580105" }],
  },
  {
    name: "Ithala",
    branches: [
      { branch: "Albert Street- Dbn", code: "755026" },
      { branch: "Bulwer", code: "753725" },
      { branch: "Central Card Branch", code: "757026" },
      { branch: "Corporate Branch", code: "757826" },
      { branch: "Development CSC", code: "759126" },
      { branch: "Empangeni", code: "751030" },
      { branch: "Eshowe", code: "752630" },
      { branch: "Estcourt", code: "753825" },
      { branch: "Harding", code: "754826" },
      { branch: "Howick", code: "753425" },
      { branch: "Ingwavuma", code: "751924" },
      { branch: "Inkandla", code: "754024" },
      { branch: "Ithala Bergville", code: "756926" },
      { branch: "Ithala Dlaton", code: "756226" },
      { branch: "Ithala Gardiner Street", code: "754126" },
      { branch: "Ithala Greytown", code: "756526" },
      { branch: "Itahala Hluhluwe", code: "756726" },
      { branch: "Ithala Phlani Valley", code: "756826" },
      { branch: "Ithala Pinewalk", code: "756626" },
      { branch: "Ithala The Galleria Mall", code: "754226" },
      { branch: "Ithala Zwelethu", code: "755626" },
      { branch: "Ixopo", code: "754626" },
      { branch: "Izingolweni", code: "755728" },
      { branch: "Jozini", code: "751824" },
      { branch: "Kokstad Agency", code: "758226" },
      { branch: "Kwa Mashu", code: "756126" },
      { branch: "Ladysmith", code: "753025" },
      { branch: "Longmarket Street (pmbg)", code: "756325" },
      { branch: "Madadeni", code: "753524" },
      { branch: "Mandini", code: "751129" },
      { branch: "Mangusi", code: "751424" },
      { branch: "Matatiele Agency", code: "758126" },
      { branch: "Maxwell Street- Empangeni", code: "752530" },
      { branch: "Mbazwana", code: "752124" },
      { branch: "Mega City", code: "756426" },
      { branch: "Mkuze", code: "752324" },
      { branch: "Moorton Agency", code: "758026" },
      { branch: "Mtubatuba", code: "751230" },
      { branch: "Ndumo", code: "752024" },
      { branch: "Newcastle", code: "753124" },
      { branch: "New Germany", code: "755226" },
      { branch: "Nongoma", code: "751630" },
      { branch: "Ngutu", code: "753624" },
      { branch: "Pine Town", code: "755126" },
      { branch: "Pine Town Operations Centre", code: "757999" },
      { branch: "Pongola", code: "752724" },
      { branch: "Port Shepstone", code: "755828" },
      { branch: "Richards Bay", code: "752430" },
      { branch: "Selgro- Pietermaritzburg", code: "755425" },
      { branch: "Stanger", code: "751529" },
      { branch: "Tongaat", code: "751729" },
      { branch: "Tugela Ferry", code: "753924" },
      { branch: "Ulundi", code: "751330" },
      { branch: "Umgeni Road", code: "756026" },
      { branch: "Umlazi", code: "755526" },
      { branch: "Umzimto", code: "754726" },
      { branch: "Vryheid", code: "753324" },
    ],
  },
  {
    name: "JP Morgan Chase",
    branches: [{ branch: "Universal", code: "432000" }],
  },
  {
    name: "Mercantile Bank",
    branches: [{ branch: "Universal", code: "450105" }],
  },
  {
    name: "Nedbank Incorp FBC",
    branches: [
      { branch: "Fbc Fidelity Bank Pensions", code: "780117" },
      { branch: "Port Elizabeth", code: "780017" },
    ],
  },
  {
    name: "Nedbank Limited BOE",
    branches: [
      { branch: "Albertinia", code: "440113" },
      { branch: "Bellville", code: "440510" },
      { branch: "Benoni", code: "440142" },
      { branch: "Bloemfontein", code: "440134" },
      { branch: "Brackenfell", code: "440810" },
      { branch: "Bredasdorp", code: "440112" },
      { branch: "Bredastraat Paarl", code: "440910" },
      { branch: "Business Div of BOE Bank", code: "440305" },
      { branch: "Caledon", code: "440212" },
      { branch: "Ceres", code: "440107" },
      { branch: "Clanwilliam", code: "440106" },
      { branch: "Cradock", code: "440118" },
      { branch: "De Aar", code: "440208" },
      { branch: "De Doorns", code: "440207" },
      { branch: "Durban", code: "440126" },
      { branch: "Ermelo", code: "440144" },
      { branch: "Franschoek", code: "440310" },
      { branch: "George", code: "440114" },
      { branch: "Grabouw", code: "440312" },
      { branch: "Heidelberg wes Kaap", code: "440313" },
      { branch: "Helderberg Kommersiele Sentrum", code: "441012" },
      { branch: "Helderberg (voorheen Strand)", code: "440712" },
      { branch: "Hermanus", code: "440412" },
      { branch: "Hopefield", code: "440211" },
      { branch: "Humansdorp", code: "440115" },
      { branch: "Jeffreysbaai", code: "440215" },
      { branch: "Johannesburg", code: "440105" },
      { branch: "Kaap Kommersiel", code: "441010" },
      { branch: "Kaapstad", code: "440309" },
      { branch: "Kaart Uitreking / card Issuing", code: "449510" },
      { branch: "Kaart Verkryging / card Acquiring", code: "449410" },
      { branch: "Kimberley", code: "440102" },
      { branch: "Kirkwood", code: "440117" },
      { branch: "Kleinmond", code: "440512" },
      { branch: "Knysna", code: "440414" },
      { branch: "Kraaifontein", code: "440710" },
      { branch: "Kroonstad", code: "440136" },
      { branch: "Kuilsrivier", code: "440410" },
      { branch: "Ladismith (wes Kaap)", code: "440413" },
      { branch: "Malmesbury", code: "440307" },
      { branch: "Middelburg Mpumalanga", code: "440150" },
      { branch: "Montagu", code: "440513" },
      { branch: "Moorreesburg", code: "440311" },
      { branch: "Mosselbaai", code: "440214" },
      { branch: "Nelspruit", code: "440152" },
      { branch: "Newcastle", code: "440124" },
      { branch: "Oos-London", code: "440121" },
      { branch: "Oudtshoorn", code: "440314" },
      { branch: "Paarl", code: "440210" },
      { branch: "Paarl Kommersieel", code: "441210" },
      { branch: "Parow", code: "440610" },
      { branch: "Pep Bank", code: "445010" },
      { branch: "Pick N Pay Financial Services", code: "440409" },
      { branch: "Pick N Pay Saver Association", code: "440209" },
      { branch: "Pietermaritzburg", code: "440125" },
      { branch: "Piet Retief", code: "440244" },
      { branch: "Piketberg", code: "440411" },
      { branch: "Pinetown", code: "440226" },
      { branch: "Pks Mikro 1", code: "441110" },
      { branch: "Polokwane (previously Pietersburg)", code: "440148" },
      { branch: "Pongola", code: "440224" },
      { branch: "Port Elizabeth", code: "440217" },
      { branch: "Porterville", code: "440407" },
      { branch: "Pretoria", code: "440145" },
      { branch: "Pretoria Kommersieel", code: "440345" },
      { branch: "Private Bank Gauteng Region", code: "446205" },
      { branch: "Private Bank Kzn Natal Region", code: "446326" },
      { branch: "Private Bank Southern &", code: "446014" },
      { branch: "Private Bank Western Cape", code: "446110" },
      { branch: "Prosesserings Sentrum Durban", code: "440326" },
      { branch: "Prosesserings Sentrum Kaapstad", code: "440109" },
      { branch: "Richardsbaai", code: "440130" },
      { branch: "Riversdal", code: "440613" },
      { branch: "Riviersonderend", code: "440612" },
      { branch: "Robertson", code: "440713" },
      { branch: "Rustenburg", code: "440146" },
      { branch: "Secunda", code: "440344" },
      { branch: "Shoprite Checkers Club 2000", code: "441310" },
      { branch: "Silverton", code: "440245" },
      { branch: "Somerset-oos", code: "440218" },
      { branch: "Somerset-wes", code: "440912" },
      { branch: "Standerton", code: "440143" },
      { branch: "Stellenbosch", code: "440110" },
      { branch: "Stilbaai", code: "440913" },
      { branch: "Swellendam", code: "440813" },
      { branch: "Tarkastad", code: "440120" },
      { branch: "Tulbagh", code: "440507" },
      { branch: "Uitenhage", code: "440116" },
      { branch: "Upington", code: "440104" },
      { branch: "Villiersdorp", code: "440812" },
      { branch: "Vredenburg", code: "440511" },
      { branch: "Vredendal", code: "440206" },
      { branch: "Welkom", code: "440135" },
      { branch: "Witbank", code: "440250" },
      { branch: "Worcester", code: "440707" },
    ],
  },
  {
    name: "Nedbank PEP Bank",
    branches: [{ branch: "Universal", code: "400001" }],
  },
  {
    name: "Olympus Mobile",
    branches: [{ branch: "Universal", code: "585001" }],
  },
  {
    name: "Peoples Bank Ltd Inc NBS",
    branches: [{ branch: "Universal", code: "720026" }],
  },
  {
    name: "Sasfin Bank",
    branches: [
      { branch: "Momentum Money", code: "683001" },
      { branch: "Sasfin Bank Limited", code: "683000" },
    ],
  },
  {
    name: "South African Postbank SOC Ltd",
    branches: [{ branch: "Universal", code: "460005" }],
  },
  {
    name: "Standard Chartered Bank",
    branches: [
      { branch: "Standard Chartered Bank", code: "730020" },
      { branch: "Standard Chartered Home Loan", code: "730027" },
      { branch: "Standard Chartered Pers Loan", code: "730030" },
    ],
  },
  {
    name: "State Bank of India",
    branches: [{ branch: "Universal", code: "801000" }],
  },
  {
    name: "TymeBank",
    branches: [{ branch: "Universal", code: "678910" }],
  },
  {
    name: "UBank Limited",
    branches: [{ branch: "Universal", code: "431010" }],
  },
  {
    name: "Unibank",
    branches: [{ branch: "Universal", code: "790005" }],
  },
  {
    name: "VBS Mutual Bank",
    branches: [{ branch: "Universal", code: "588000" }],
  },
];
