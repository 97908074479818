import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import SelectWrapper from "../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import * as yup from "yup";
import withAuth from "../../../Hoc/withAuth";
import countryList from "../../../countryList";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import InfoIcon from "@mui/icons-material/Info";
import DOBPicker from "../../../Components/FormComponents/DobPicker";
import SelectWrapperWithInfo from "../Components/SelectWrapperWithInfo";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

const OEBasicInfo = ({ employee_id, BasicInfo, modules, submitted, handleTabChange, disabled }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [mutationError, setMutationError] = useState("");
  const [mutationSuccess, setMutationSuccess] = useState('Basic Info Saved successfully!')

  const switchTab = (newTabIndex) => {
    handleTabChange(newTabIndex);
  };

  if (!employee_id) {
    navigate("/otp");
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const disabilities = [
    "No Disability",
    "Communication (speech impairment)",
    "Hearing (deaf, profoundly hard of hearing)",
    "Intellectual (serious difficulties in learning)",
    "Mental - Emotional (behavioural, psychological)",
    "Sight (blind/severe visual limitation)",
    "Other than Above",
  ];

  const languages = [
    {
      value: "Zulu",
      label: "Zulu",
    },
    {
      value: "Xhosa",
      label: "Xhosa",
    },
    {
      value: "Tswana",
      label: "Tswana",
    },
    {
      value: "Sotho",
      label: "Sotho",
    },
    {
      value: "Sepedi",
      label: "Sepedi",
    },
    {
      value: "Tsonga",
      label: "Tsonga",
    },
    {
      value: "Venda",
      label: "Venda",
    },
    {
      value: "Ndebele",
      label: "Ndebele",
    },
    {
      value: "Seswati",
      label: "Seswati",
    },
    {
      value: "Afrikaans",
      label: "Afrikaans",
    },
    {
      value: "English",
      label: "English",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];
  

  const genders = [
    {
      value: "Female",
      label: "Female",
    },
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  const titles = [
    {
      value: "Miss",
      label: "Miss",
    },
    {
      value: "Mr",
      label: "Mr",
    },
    {
      value: "Mrs",
      label: "Mrs",
    },
    {
      value: "Ms",
      label: "Ms",
    },
  ];

  const races = [
    {
      value: "Black",
      label: "Black",
    },
    {
      value: "Coloured",
      label: "Coloured",
    },
    {
      value: "Indian",
      label: "Indian",
    },
    {
      value: "Other",
      label: "Other",
    },
    {
      value: "White",
      label: "White",
    },
  ];
  

  const maritalStatus = [
    {
      value: "Civil union",
      label: "Civil union",
      hint: "A marriage entered into between 2 persons and include persons of the same sex.",
    },
    {
      value: "Co-habiting",
      label: "Co-habiting",
      hint: "Unmarried couples who live together.",
    },
    {
      value: "Customary Married",
      label: "Customary Married",
      hint: "A marriage entered into between a man and a woman, negotiated and celebrated according to the prevailing customary law in their community.",
    },
    {
      value: "Domestic",
      label: "Domestic",
      hint: "In a relationship but not living together.",
    },
    {
      value: "Married",
      label: "Married",
    },
    {
      value: "Religious/Traditional",
      label: "Religious/Traditional",
      hint: "A religious marriage is a marriage entered into in terms of religion such as the Islamic or Hindu faith",
    },
    {
      value: "Single",
      label: "Single",
      hint: "Not in a relationship.",
    },
  ];
  

  const warningText = "It is your responsibility to ensure that the information you provide below is accurate and complete.";

  const mutation = useMutation({
    mutationFn: (values) => {
      setMutationSuccess('Basic Info updated successfully!')
      return axios.put(
        `${process.env.REACT_APP_API_URL}/onboarding/employees/basicInfo/${employee_id}`,
        { ...values }
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["employeeInfo"]);
    },
    onError: async (error) => {
      setMutationError(error.response.data.message);
    }
  });

  const onSaveBasicInfoHandler = (values) => {
    values['manager_node_id'] = BasicInfo?.Requisition?.User?.id
    values['position_id'] = BasicInfo?.Requisition?.Position?.id
    mutation.mutate(values);
    values['manager_node_id'] = `${BasicInfo?.Requisition?.User?.name} ${BasicInfo?.Requisition?.User?.surname}`
    values['position_id'] = BasicInfo?.Requisition?.Position?.job_title
  };

  return (
    <Box component="div">
      <Stack spacing={2} mb={2} mt={2}>
        <Typography>BASIC INFORMATION</Typography>

        <Typography color={"error"} fontSize={15}>
          {warningText}
        </Typography>
      </Stack>

      {mutation.isLoading && <AlertPopup open={true} message={"Saving Basic Information..."} severity={"info"} /> }

      {mutation.isSuccess && <AlertPopup open={true} message={mutationSuccess} /> }

      {mutationError && <AlertPopup open={true} message={mutationError} severity={"error"} /> }

      <Formik
        initialValues={{
          id: employee_id || "",
          firstname: BasicInfo?.firstname || "",
          lastname: BasicInfo?.lastname || "",
          middle_name: BasicInfo?.middle_name || "",
          initials: BasicInfo?.initials || "",
          preferred_name: BasicInfo?.preferred_name || "",
          maiden_name: BasicInfo?.maiden_name || "",
          title: BasicInfo?.title || "",
          language: BasicInfo?.language || "",
          race: BasicInfo?.race || "",
          nationality: BasicInfo?.nationality || "South Africa",
          gender: BasicInfo?.gender || "",
          citizenship: BasicInfo?.citizenship || "South Africa",
          marital_status: BasicInfo?.marital_status || "",
          birth_date: BasicInfo?.birth_date || "2017/02/07",
          emergency_contact_name: BasicInfo?.emergency_contact_name || "",
          emergency_contact_number: BasicInfo?.emergency_contact_number || "",
          disabled: BasicInfo?.disabled || [],
          cell_number: BasicInfo?.cell_number || "",
          manager_node_id: `${BasicInfo?.Requisition?.User?.name} ${BasicInfo?.Requisition?.User?.surname}` || "",
          position_id: BasicInfo?.Requisition?.Position?.job_title || "",
        }}
        validationSchema={yup.object().shape({
          firstname: yup.string().required("Firstname is required"),
          lastname: yup.string().required("Lastname is required"),
          initials: yup.string().required("Initals are required"),
          title: yup.string().required("Title is required"),
          language: yup.string().required("Language is required"),
          race: yup.string().required("Race is required"),
          nationality: yup.string().required("Nationality is required"),
          gender: yup.string().required("Gender is required"),
          citizenship: yup.string().required("Citizen ship is required"),
          marital_status: yup.string().required("Marital status required"),
          birth_date: yup.string().required("Birth date required"),
          emergency_contact_name: yup
            .string()
            .required("Emergency name required"),
          emergency_contact_number: yup
            .string()
            .required("Emergency contact required"),
          disabled: yup.array().required("Disbled required"),
        })}
        onSubmit={(values) => {
          onSaveBasicInfoHandler(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, initialValues, setFieldValue, handleSubmit }) => {
          return (
            <Form
            disabled={disabled}
              onSubmit={(jsEvent) => {
                // This is a bit of a hack to copy the name and value of
                // the submitter into the Formik form
                const { name, value } = jsEvent.nativeEvent.submitter;
                setFieldValue(name, value);

                return handleSubmit(jsEvent);
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"firstname"}
                    placeholder={"First Name"}
                    label="First Name"
                    autoComplete={"off"}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"lastname"}
                    placeholder={"Last Name"}
                    label="Last Name"
                    autoComplete={"off"}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"middle_name"}
                    placeholder={"Middle Name"}
                    label="Middle Name"
                    autoComplete={"off"}
                    disabled={disabled}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Enter only if you have a middle name">
                            <IconButton color="secondary">
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"initials"}
                    placeholder={"Initials"}
                    label="Initials"
                    autoComplete={"off"}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"preferred_name"}
                    placeholder={"Preferred Name"}
                    label="Preferred Name"
                    autoComplete={"off"}
                    disabled={disabled}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Enter the name you would prefer to be known by">
                            <IconButton color="secondary">
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"maiden_name"}
                    placeholder={"Maiden Name"}
                    label="Maiden Name"
                    autoComplete={"off"}
                    disabled={disabled}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Enter your maiden name if applicable">
                            <IconButton color="secondary">
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="title"
                    label="Title"
                    options={titles}
                    autoComplete={"off"}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="language"
                    label="Language"
                    options={languages}
                    autoComplete={"off"}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="gender"
                    label="Gender"
                    options={genders}
                    autoComplete={"off"}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="race"
                    label="Race"
                    options={races}
                    autoComplete={"off"}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={countryList}
                    name="nationality"
                    value={values.nationality || "South Africa"}
                    onChange={(e, value) => {
                      setFieldValue(
                        "nationality",
                        value || initialValues.nationality
                      );
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label="Nationality" />
                    )}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    name="citizenship"
                    disablePortal
                    id="combo-box-demo"
                    options={countryList}
                    value={values.citizenship || "South Africa"}
                    onChange={(e, value) => {
                      console.log(value);
                      setFieldValue(
                        "citizenship",
                        value !== null
                          ? value
                          : initialValues.citizenship || "South Africa"
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Citizenship" />
                    )}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Any Disability
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      disabled={disabled}
                      value={values.disabled}
                      onChange={(e) => {
                        setFieldValue("disabled", e.target.value);
                      }}
                      input={<OutlinedInput label="Any Disability" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {disabilities.map((disability) => (
                        <MenuItem key={disability} value={disability}>
                          <Checkbox
                            checked={values.disabled.indexOf(disability) > -1}
                          />
                          <ListItemText primary={disability} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWrapperWithInfo
                    name="marital_status"
                    label="Marital Status"
                    options={maritalStatus}
                    autoComplete={"off"}
                    disabled={disabled}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <DOBPicker
                    name={"birth_date"}
                    label={"Birth Date"}
                    inputFormat="dd/MM/yyyy"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"emergency_contact_name"}
                    placeholder={"Emergency Contact Name"}
                    label="Emergency Contact Name"
                    autoComplete={"off"}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"emergency_contact_number"}
                    placeholder={"Emergency Contact Number"}
                    label="Emergency Contact Number"
                    autoComplete={"off"}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name="manager_node_id"
                    label="Manager"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"cell_number"}
                    label="Cell Number"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name="position_id"
                    label="Position"
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Stack direction={"row"} justifyContent="center" spacing={2}>
                    {BasicInfo?.firstname?.length > 0 ? (
                      <>
                        <Button
                          variant="contained"
                          sx={{
                            width: 170,
                          }}
                          color="primary"
                          type="submit"
                          name="submitAction"
                          disabled={disabled}
                          value="0"
                        >
                          Update
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          sx={{
                            width: 170,
                          }}
                          type="submit"
                          name="submitAction"
                          value="0"
                          color="primary"
                          disabled={disabled}
                          fullWidth
                        >
                          Save
                        </Button>
                      </>
                    )}
                    <Button
                          variant="contained"
                          sx={{
                            width: 170,
                          }}
                          color="primary"
                          type="submit"
                          name="submitAction"
                          onClick={() => switchTab(1)}
                        >
                          Next
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default withAuth(OEBasicInfo);
