import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const PageHeader = ({ title, subheader, breadcrumbs, backLink }) => {
  const navigate = useNavigate();

  return (
    <Card variant="outlined" sx={{ mb: 3 }}>
      <CardHeader
        title={title}
        subheader={subheader && subheader}
        action={
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(backLink ? backLink : -1)}>
            Back
          </Button>
        }
      />

      <CardContent>
        <Breadcrumbs aria-label="breadcrumb">
          {breadcrumbs &&
            breadcrumbs.length > 0 &&
            breadcrumbs?.map((breadcrumb, index) => (
              <Link
                key={index}
                href={breadcrumb.href}
                color="inherit"
                underline="none"
                variant="body2">
                {breadcrumb.title}
              </Link>
            ))}
        </Breadcrumbs>
      </CardContent>
    </Card>
  );
};

export default PageHeader;
