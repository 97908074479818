import { useState } from 'react';
import { Button, Stack } from '@mui/material';

const SaveMovedEvals = ({ onSave, disabled }) => {
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true); 
    await onSave();    
    setIsSaving(false); 
  };

  return (
    <Stack>
      <Button 
        variant='contained' 
        color="secondary" 
        fullWidth 
        sx={{ mt: 2, mb: 1 }} 
        onClick={handleSave} 
        disabled={isSaving || disabled} 
      >
        Save
      </Button>
    </Stack>
  );
};

export default SaveMovedEvals;
