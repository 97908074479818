import {
  Button,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { EditQuestionnaireAnswerReq } from "../State/QuestionnairsDuck";

export default function EditQuestionnaireAnswer({ answer }) {
  const [open, setOpen] = React.useState(false);

  const [values, setValues] = React.useState({
    answer: "",
  });

  useEffect(() => {
    setValues({
      answer: answer?.answer || "",
    });
  }, [answer]);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: values,
    enableReinitialize: true,
    validationSchema: Yup.object({
      answer: Yup.string().required("A Answer is required"),
    }),

    onSubmit: async (values) => {
      dispatch(EditQuestionnaireAnswerReq(answer.id, values));
      handleClose();
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={"Edit Answer"}>
        <IconButton
          size="small"
          onClick={handleClickOpen}
          color="warning"
          edge="end">
          <EditTwoToneIcon />
        </IconButton>
      </Tooltip>

      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Edit Answer</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Stack sx={{ p: 2 }}>
              <TextField
                margin="normal"
                label="Answer"
                name="answer"
                value={formik.values.answer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.answer && formik.errors.answer ? true : false
                }
                helperText={formik.touched.answer && formik.errors.answer}
              />
            </Stack>
            <Stack>
              <Button sx={{ mt: 4 }} type="submit" variant="outlined">
                Save
              </Button>
              <Button color="inherit" onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
