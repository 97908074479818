import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignCompany } from "../../../Redux/Ducks/AdminCompniesDuck";

const AddUserCompany = ({ id, setUserSelectedCompany }) => {
  const [userCompany, setUserCompany] = useState("");
  const dispatch = useDispatch();
  const Companies = useSelector((state) => state.Companies);
  const { allCompanies, loading } = Companies;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddUserToCompany = () => {
    setUserSelectedCompany(userCompany);
    dispatch(
      assignCompany({
        company_id: userCompany,
        user_id: id,
        user_type: "user",
      })
    );
    window.location.reload();
    handleClose();
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: "1px solid #f44336",
        }}>
        <CardActionArea onClick={handleClickOpen}>
          <CardContent>
            <Typography variant="h4" color="error">
              Add User To Company
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="add-user-to-company">User Company</InputLabel>
            <Select
              labelId="add-user-to-company"
              id="add-user-to-company-id"
              value={userCompany}
              label="User Company"
              onChange={(event) => setUserCompany(event.target.value)}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              {allCompanies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack direction="row">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  handleAddUserToCompany();
                }}>
                Save
              </Button>
            )}
          </Stack>
        </DialogContent>
        <Button color="warning" onClick={handleClose}>
          Cancel
        </Button>
      </Dialog>
    </>
  );
};

export default AddUserCompany;
