import {
  Button,
  Card,
  Grid,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
  Link,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { useParams } from "react-router-dom";
import {
  getLeadSourceList,
  getUploadedLeadFiles,
  UploadLeadFileToCampaign,
} from "./State/LeadsDuck";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import { getCampaignById } from "./State/CampaingsDuck";
import withAuth from "../../../../../Hoc/withAuth";

const CampaignLeadFileUploader = ({ user }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getCampaignById(id));
    dispatch(getLeadSourceList());
    dispatch(getUploadedLeadFiles(id));
  }, [dispatch, id]);

  const [document, setDocument] = useState("");

  const [leadSources, setLeadSources] = useState("");

  const handleChange = (event) => {
    setLeadSources(event.target.value);
  };

  const handleFileChange = (event) => {
    setDocument(event.target.files[0]);
  };

  const Leads = useSelector((state) => state.Leads);

  const { leadFiles, leadUploaded, allLeadSources, success } = Leads;

  // remove inactive lead sources
  let filteredLeadSources = [];
  if (success && allLeadSources.length > 0) {
    filteredLeadSources = allLeadSources.filter((lead_source) => {
      return lead_source.active && !lead_source.api_flag;
    });

    // order allLeadSources by updatedAt desc
    filteredLeadSources.sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });
  }

  const handleUpload = () => {
    const values = new FormData();
    values.append("file", document);

    values.append("lead_source_id", leadSources);
    values.append("campaign_id", id);
    values.append("updated_reason", "New Lead File Uploaded");

    let campaignId = id;

    dispatch(UploadLeadFileToCampaign(campaignId, values));

    setDocument("");
    setLeadSources("");
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          Select Leads Source
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={leadSources}
          label="Select Leads Source"
          onChange={handleChange}
        >
          {filteredLeadSources.map((lead) => (
            <MenuItem key={lead.id} value={lead.id}>
              {lead.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Stack sx={{ my: 1 }} spacing={2}>
        {document?.name ? (
          <Typography color="secondary" variant="h6">
            {document?.name}
          </Typography>
        ) : (
          <>
            <Typography variant="body1">Click To upload file</Typography>
          </>
        )}
        {!document ? (
          <label htmlFor="contained-button-file">
            <Input
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              id="contained-button-file"
              type="file"
              autoFocus
              onChange={handleFileChange}
            />
            <Button
              variant="contained"
              startIcon={<FileOpenIcon />}
              component="span"
            >
              Upload
            </Button>
          </label>
        ) : (
          <>
            {!leadSources && <Alert severity="error">Select Lead Source</Alert>}
            <Button color="warning" onClick={() => setDocument("")}>
              {" "}
              Cancel
            </Button>
          </>
        )}
      </Stack>

      <Stack>
        {leadSources && document && id && (
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleUpload}
          >
            Save For Processing
          </Button>
        )}
      </Stack>
      <Stack sx={{ mt: 3 }}>
        {leadFiles &&
          leadFiles.length > 0 &&
          leadFiles.map((file) => (
            <Card key={file.id}>
              <CardContent>{displayFileUploadStatus(file)}</CardContent>
              <CardActions>
                <Button variant="outlined" disabled={!file.storage_id} download>
                  <Link
                    sx={{ textDecoration: "none" }}
                    href={`${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/lead_files/${file.storage_id}`}
                  >
                    <Typography variant="button">Download File</Typography>
                  </Link>
                </Button>
              </CardActions>
            </Card>
          ))}
      </Stack>
      <AlertPopup
        open={leadUploaded}
        severity="success"
        message="Lead Uploaded"
      />
    </div>
  );
};

export default withAuth(CampaignLeadFileUploader);

const Input = styled("input")({
  display: "none",
});

const displayFileUploadStatus = (file) =>
  file.updated_reason == null ? (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="span">{file.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="span">
          Processing Status:{" "}
          <Typography variant="span" sx={{ color: "warning.dark" }}>
            No Reason Provided
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="span">{file.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="span">
          Processing Status:{" "}
          <Typography variant="span" sx={{ color: "info.light" }}>
            {file.updated_reason}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
