import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import {
  Autocomplete,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { addUsertoTeam } from "../../State/TeamsDuck";

export default function AddUsersToTeamDialog({ users, teamId }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const options =
    users &&
    users?.map((option) => {
      const firstLetter =
        option?.name && option?.name.length > 0
          ? option?.name[0]?.toUpperCase()
          : "";
      return {
        id: option.id,
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        person: `${option.name} ${option.surname}` || "",
      };
    });

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add Users To Team
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Add Users To Team</DialogTitle>

        <Formik
          initialValues={{
            teamId: teamId,
            user_id: "",
          }}
          onSubmit={(values) => {
            // console.log(values);
            dispatch(addUsertoTeam(values));
            handleClose();
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <DialogContent>
                {users && users.length > 0 ? (
                  <Autocomplete
                    name="user_id"
                    id="checkboxes-select-questions"
                    groupBy={(option) => option.firstLetter}
                    options={options.sort((a, b) =>
                      a.person.localeCompare(b.person),
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.person === value.person
                    }
                    getOptionLabel={(option) => option.person}
                    onChange={(event, newValue) => {
                      setFieldValue("user_id", newValue.id);
                    }}
                    renderOption={(props, option, { selected }) => {
                      return <li {...props}>{option.person}</li>;
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        label="Select Team Members"
                        placeholder="Select Team Members"
                      />
                    )}
                  />
                ) : (
                  <Typography>Give Users call-center module</Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Save</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
