import React, { useEffect } from "react";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  arrayMove,
} from "@dnd-kit/sortable";
import { Card, Typography, Stack } from "@mui/material";
import { CSS } from "@dnd-kit/utilities";
import axios from "axios";
import EditQuestions from "../components/EditQuestions";
import DeActivateQuestions from "../components/DeActivateQuestions";

const SortableItem = ({ question, editQuestions, deactivateQuestions }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: question.id });

  return (
    <Card
      sx={{
        borderRadius: 1,
        p: 1,
        mb: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isDragging ? "grabbing" : "grab",
        pointerEvents: isDragging ? "none" : "auto",
      }}
    >
      <div
        style={{ cursor: "grab", flexGrow: 1 }}
        {...attributes}
        {...listeners}
      >
        <Typography fontSize={20}>{question.name}</Typography>
      </div>
      <Stack direction="row" spacing={1}>
        <div ref={setNodeRef} {...attributes} {...listeners}>
          {question.question_type === "Score" ? (
            <Card
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              px: 2,
              py: 1,
              borderRadius: 1,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            Weight: {question.value}
          </Card>
          ) : (
            <Typography
            sx={{ flexGrow: 1, visibility: "hidden" }}
            component="div"
          >
            {question.question_type}
          </Typography>
            
            )}
        </div>
        <div style={{ pointerEvents: "auto" }}>
          <EditQuestions question={question} editQuestions={editQuestions} />
        </div>
        <div style={{ pointerEvents: "auto" }}>
          <DeActivateQuestions
            deactivateQuestions={deactivateQuestions}
            question={question}
          />
        </div>
      </Stack>
    </Card>
  );
};

const QuestionList = ({ group, editQuestions, deactivateQuestions }) => {
  const [questions, setQuestions] = React.useState(group?.question || []);

  useEffect(() => {
    if (group && group.question) {
      setQuestions(group.question);
    }
  }, [group]);

  const handleOpenSnackbar = (message, severity) => {
    console.log(`${severity.toUpperCase()}: ${message}`);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over) return;

    if (active.id !== over.id) {
      setQuestions((prevQuestions) => {
        const oldIndex = prevQuestions.findIndex((q) => q.id === active.id);
        const newIndex = prevQuestions.findIndex((q) => q.id === over.id);

        if (oldIndex === -1 || newIndex === -1) {
          console.error("Question not found in the list");
          return prevQuestions;
        }

        const reorderedQuestions = arrayMove(prevQuestions, oldIndex, newIndex);

        const updatedQuestions = reorderedQuestions.map((question, index) => ({
          ...question,
          order: index,
        }));

        updateQuestionsInDatabase(updatedQuestions);

        return updatedQuestions;
      });
    }
  };

  const updateQuestionsInDatabase = async (questions) => {
    console.log("These are the questions", questions);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${group.campaign_id}/group/${group.id}/question/order`,
        {
          updatedQuestions: questions,
        }
      );
      handleOpenSnackbar("Questions reordered successfully!", "success");
    } catch (error) {
      console.error("Error updating questions:", error);
      handleOpenSnackbar("Failed to reorder questions", "error");
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext
        items={questions.map((q) => q.id)}
        strategy={verticalListSortingStrategy}
      >
        {questions.map((question) => (
          <SortableItem
            key={question.id}
            question={question}
            id={group.id}
            editQuestions={editQuestions}
            deactivateQuestions={deactivateQuestions}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default QuestionList;
