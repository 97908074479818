import { Stack } from "@mui/system";
import React from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import SubjectIcon from "@mui/icons-material/Subject";
import GroupsIcon from "@mui/icons-material/Groups";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ElevatorIcon from "@mui/icons-material/Elevator";
import {
  Card,
  CardActionArea,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";

import { CampaignContext } from "./components/CampaignContext";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const ViewInboundCampaign = () => {
  const { id } = useParams();

  const [campaign, setCampaign] = React.useState({});

  let getCampaignById = useQuery({
    queryKey: ["getInboundCampaigns", id],
    queryFn: async () =>
      await axios.get(`${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}`),
    onSuccess: (data) => {
      setCampaign(data?.data?.data);
    },
  });

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const currentPath = pathname.split("/")[5];

  const Paths = [
    {
      name: "Campaign Details",
      location: "ViewInboundCampaign",
      icon: <SubjectIcon />,
    },
    {
      name: "Evaluations",
      location: "Evaluations",
      icon: <ElevatorIcon />,
    },
    {
      name: "Teams",
      location: "Teams",
      icon: <GroupsIcon />,
    },
    {
      name: "Questions Groups",
      location: "QuestionsGroups",
      icon: <QuestionMarkIcon />,
    },
    {
      name: "Campaign Linking",
      location: "CampaignLinking",
      icon: <QuestionMarkIcon />,
    },
    {
      name: "QA Reports",
      location: "reports",
    },
    {
      name: "Move Evaluations",
      location: "MoveEvaluations",
      icon: <QuestionMarkIcon />,
    },
   

  ];

  if (getCampaignById?.isLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <div>
      <PageHeader
        title="QA Inbound Admin"
        subheader="Manage your QA Inbound campaigns"
        breadcrumbs={[
          { title: "Home", href: "/" },
          { title: "QA Inbound Admin", href: "/CDA/inbound/admin" },
          { title: `QA Inbound Admin ${id}`, href: `/CDA/inbound/admin/${id}` },
        ]}
      />

      <Grid container spacing={1}>
        {Paths.map((path, index) => {
          return (
            <Grid item xs={2} key={index}>
              <Card
                sx={
                  currentPath === path.location
                    ? { borderStyle: "solid", borderColor: "secondary.main" }
                    : null
                }>
                <CardActionArea
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    py: 5,
                  }}
                  onClick={() => {
                    navigate(`${path.location}`);
                  }}>
                  <Typography color="textPrimary">{path.name}</Typography>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Stack>
        <CampaignContext.Provider value={campaign}>
          <Outlet />
        </CampaignContext.Provider>
      </Stack>
    </div>
  );
};

export default ViewInboundCampaign;
