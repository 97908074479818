export const Question = {
  section1: [
    {
      title: "Was the agent enthusiastic?",
      type: "radio",
      value: 0,
      toolTipText:
        "Does the agent sound the part & do they come across as having energy before the presentation has started? Is the agent call ready, alert & ready to do business?",
    },
    {
      title: "Was the agent's pace adequate?",
      type: "radio",
      value: 0,
      toolTipText:
        "Pace refers to the pace at which the agent presents. A slow pace results in less objections & improves confidence. Pace creates clarity which improves the decision making of the customer.",
    },
    {
      title: "Was the agent relaxed and confident?",
      type: "radio",
      value: 0,
      toolTipText:
        "Confidence upfront is critical for making a good impression & ultimately the success of the call. Nobody wants to do business with an agent that does not have confidence. Confidence controls the sales process. It is often not what you say but how you say it.",
    },
    {
      title: "Was the agent's tone excited, energetic and professional?",
      type: "radio",
      value: 0,
      toolTipText:
        "Tone creates the atmosphere in a call. The agent's tone must create excitement, trust & project professionalism. Does the agent sound like they are reading or do they sound very boring upfront? Agents must sound excited & energetic upfront. Their tone influences how they sound to the customer.",
    },
    {
      title: "Did the agent listen to the customer?",
      type: "radio",
      value: 0,
      toolTipText:
        "Listening to the customer from the beginning often sets up the presentation for success. The agent cannot listen if they are always talking. Be aware of agents interrupting the customer whilst they are talking. That is an unacceptable in the sales process.",
    },
  ],
  section2: [
    {
      title: "Did the agent handle the upfront objections effectively?",
      type: "radio",
      value: 0,
      toolTipText:
        "Effectively handling upfront objections involves skillfully addressing concerns or doubts that the customer may express early in the conversation, with the goal of building rapport and moving the interaction forward positively.",
    },

    {
      title: "Was the agent's pace adequate?",
      type: "radio",
      value: 0,
      toolTipText:
        "A slow pace is critical as the agent moves into presenting the benefits of the product. Does the agent sound clear & consider if you where the customer, would you understand what the agent is saying? A pace that is too fast indicates uncertainty & a lack of confidence in the various benefits on offer. This leads to poor closing & a low conversion ratio.",
    },

    {
      title: "Did the agent speak with clarity?",
      type: "radio",
      value: 0,
      toolTipText:
        "Agents must be clear at all times in their calls, especially upfront as the customer is not expecting the phone call. Ensure the agent pronounces words clearly & that the speech is audible.",
    },

    {
      title: "Did the agent build rapport?",
      type: "radio",
      value: 0,
      toolTipText:
        "Building rapport is the 'X' factor in selling. It is about connecting with  customers & having a mutual conversation where the customer feels engaged & understood. Great rapport builders can win over any customer & get them to enagage. Building rapport also builds trust.",
    },

    {
      title: "Did the agent's tone remain excited, energetic & professional?",
      type: "radio",
      value: 0,
      toolTipText:
        "The correct tone is critical when connecting with potential customers. Being energetic & having a friendly tone is important. Tone allows the agent to set the scene of the entire sales conversation.",
    },

    {
      title:
        "Was the conversational flow in line with the masterclass sales call model?",
      type: "radio",
      value: 0,
      toolTipText:
        "Conversational flow refers to how the agent is using the critical selling skills & behaviours during the call. You should only score conversational flow once the entire call has been evaluated, as it considers all the sales behaviours.",
    },

    {
      title: "Was the agent relaxed & confident?",
      type: "radio",
      value: 0,
      toolTipText:
        "All great sales performers are confident. Confidence starts with the agent sounding relaxed & in control. This should be a constant throughout the presentation. Even when objections & questions arise, a confident agent will remain relaxed.",
    },

    {
      title: "Did the agent demonstrate the benefits of the product?",
      type: "radio",
      value: 0,
      toolTipText:
        "It is a critical element of an agent's job to clearly define the benefits of each product. Simply mentioning the features of the product does not mean the customer understands 'what is in it for me' (WIIIFM). An agent can convert a feature to a benefit, by adding the words 'which means' to the end of the feature. For example: Mr Naidoo, we cover Covid immediately, which means you will be covered for a very real risk that we are facing in South Africa at the moment & you will not have a waiting period. Great news isn't it?",
    },

    {
      title: "Did the agent listen to the customer?",
      type: "radio",
      value: 0,
      toolTipText:
        "Listening is one of the toughest skills to master in selling. Agents must listen to customers in order to deal with their questions, objections & buying signals. If an agent is always talking, they cannot listen. Agents must consistently listen to customers  throughout the call. Consider also from a selling perspective whether the agent closes effectively on the customer's buying signals?",
    },

    {
      title: "Was the agent enthusiastic?",
      type: "radio",
      value: 0,
      toolTipText:
        "Enthusiasm is often not a real feeling but rather the agent acting the part. Great sales people have enthusiasm in abundance. If the agent doesn't have a good degree of enthusiasm, they will struggle to sell. The agent needs to act the part & they will become the part.",
    },

    {
      title: "Does the agent make good use of power words?",
      type: "radio",
      value: 0,
      toolTipText:
        "Power words emphasise the unique benefits &/or phrases about the product or service. Used correctly they create emphasis & planned pauses, which also has the effect of slowing down the pace of the conversation. Power words make the agent sound good & excited about the product.",
    },

    {
      title: "Did the agent use linking phrases effectively?",
      type: "radio",
      value: 0,
      toolTipText:
        "Linking phrases are used to bridge the gap between a customer's question or objection, before the agent answers them. For example: 'that is a very good question Mr X', or 'many of my customers raise that question Mr X'. The technique ensures the engagement sounds good & allows the conversational flow to be smooth & professional.",
    },
  ],

  section3: [
    {
      title: "Were qualifying questions asked correctly?",
      type: "radio",
      value: 0,
      toolTipText:
        "Many campaigns ask qualifying questions upfront. This is to ensure the customer can be covered by the product or service. Ensure the agent asks the relevant & correct qualifying questions in the call.",
    },

    {
      title: "Were probing questions used to setup the sale?",
      type: "radio",
      value: 0,
      toolTipText:
        "A probing question is a type of sales question to probe into the customers areas of interest. For example: 'will you be looking to cover your entire family?' When an agent probes, they are looking for more informatation to help them with their closing technique & the cover option that is going to be offered to the customer. Probing allows the agent to build to their close.",
    },
  ],
  objection_handling: [
    {
      title:
        "Does the agent use the Tom Hopkins process to overcome objections?",
      type: "radio",
      value: 0,
      toolTipText:
        "The Tom Hopkins process is a 5 step process the agent uses to overcome objections: 1 HEAR THEM OUT 2 FEED IT BACK 3 QUESTION IT 4 ANSWER IT 5 CLOSE",
    },

    {
      title: "Did the agent remain confident whilst handling objections?",
      type: "radio",
      value: 0,
      toolTipText:
        "Assess whether the agent sounds confident when overcoming objections. Confidence is key.",
    },

    {
      title: "Uses relevant product benefits to handle objections",
      type: "radio",
      value: 0,
      toolTipText:
        "Consider how the agent uses the relevant product benefits to overcome a specific objection. An agent must use a product benefit that answers the customer's objection & not just mention a random benefit. Does the agent always close as the final step of objection handling?",
    },

    {
      title: "Closes effectively as the final step of objection handling",
      type: "radio",
      value: 0,
      toolTipText:
        "Does the agent ask the direct closing question, or an alternative choice closing question.",
    },
  ],
  closing_skills: [
    {
      title: "Recognises and responds to positive to buying signals",
      type: "radio",
      value: 0,
      toolTipText:
        "Does the agent pick up on positive signs that the customer wants to buy, or that they are showing interest. The correct response to a buying signal is either to ask a closing question or to get a minor close or agreement & sell the next benefit. An agent should always be closing.",
    },

    {
      title: "Uses the alternative close effectively",
      type: "radio",
      value: 0,
      toolTipText:
        "The alternative choice close is a close whereby the customer is given 2 options to choose from, & asked which one they prefer. The agent needs to be careful not to give too many options,as this confuses the customer. Most people can remember 2 options only, especially when premiums are included. Closing must be 'as smooth as silk'.",
    },

    {
      title: "Uses the direct close effectively",
      type: "radio",
      value: 0,
      toolTipText:
        "This specific technique simply asks the customer to buy. The golden rule when closing is to ensure the agent keeps quiet after asking the closing question. Remember closing must be 'as smooth as silk'.",
    },
  ],

  section6: [
    {
      title: "What goals did you set for the coaching session?",
      type: "text",
      value: "",
    },

    {
      title: "Is the current reality based on your assessment of the agent?",

      type: "text",

      value: "",
    },

    {
      title: "What options or choices were given to the agent to assist them?",

      type: "text",

      value: "",
    },

    {
      title:
        "Wind up (document the actions for the next week and schedule the next coaching session)",

      type: "text",

      value: "",
    },

    {
      title: "Is the agent ready to sell?",

      type: "text",

      value: "",
    },
  ],
};
