import {
  Button,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import React from "react";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import AddTaskTwoToneIcon from "@mui/icons-material/AddTaskTwoTone";
import { CreateQuestionAnswer } from "../State/QuestionnairsDuck";

export default function CreateAnswersForm({ question_id }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      answer: "",
      active: true,
      questions_id: question_id,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      answer: Yup.string().required("A Answer is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      setTimeout(() => {
        resetForm();
        handleClose();
      }, 1000);
      dispatch(CreateQuestionAnswer(values));
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={"Add answers"}>
        <IconButton onClick={handleClickOpen} color="primary" edge="end">
          <AddTaskTwoToneIcon />
        </IconButton>
      </Tooltip>

      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Create Answer</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Stack sx={{ p: 2 }}>
              <TextField
                margin="normal"
                label="Answer"
                name="answer"
                value={formik.values.answer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.answer && formik.errors.answer ? true : false
                }
                helperText={formik.touched.answer && formik.errors.answer}
              />
            </Stack>
            <Stack>
              <Button sx={{ mt: 4 }} type="submit" variant="contained">
                Create
              </Button>
              <Button color="inherit" onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
