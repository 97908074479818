import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
// import io from "socket.io-client";
import MainAppContainer from "../Components/Containers/MainAppContainer";
import withAuth from "../Hoc/withAuth";
import Chat from "./Chat";

const socket = {};

const ChatApp = ({ user: loggedInUser }) => {
  useEffect(() => {
    setUser({ ...user, name: loggedInUser.name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [user, setUser] = React.useState({
    name: "",
    room: "",
  });

  const [roomJoined, setRoomJoined] = React.useState(false);

  const joinRoom = () => {
    console.log("Joining Room");
    if ((user.name !== "", user.room !== "")) {
      socket.emit("join", { name: user.name, room: user.room }, (error) => {
        if (error) {
          console.log(error);
        }
      });
      setRoomJoined(true);
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <MainAppContainer>
      {!roomJoined ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Username"
              fullWidth
              margin="normal"
              onChange={handleChange}
              name="name"
              value={user.name}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Room"
              fullWidth
              margin="normal"
              onChange={handleChange}
              name="room"
              value={user.room}
            />
          </Grid>

          <Grid item xs={12}>
            <Button onClick={() => joinRoom()}>join</Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={8}>
            <Chat socket={socket} user={user} />
          </Grid>
        </Grid>
      )}
    </MainAppContainer>
  );
};

export default withAuth(ChatApp);
