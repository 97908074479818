import axios from "axios";

const initialState = {
  surveyCampaigns: [],
  loading: false,
  error: null,
  success: null,
  surveyCampaign: {},
  surveyQuestions: [],
  surveyUsers: [],
  rmaSurveyCompany: [],
  companySurveys: [],
  surveyContacts: [],
};

const CREATE_SURVEY_CAMPAIGN = "CREATE_SURVEY_CAMPAIGN";
const CREATE_SURVEY_CAMPAIGN_SUCCESS = "CREATE_SURVEY_CAMPAIGN_SUCCESS";
const CREATE_SURVEY_CAMPAIGN_FAILURE = "CREATE_SURVEY_CAMPAIGN_FAILURE";

const GET_SURVEY_CAMPAIGNS = "GET_SURVEY_CAMPAIGNS";
const GET_SURVEY_CAMPAIGNS_SUCCESS = "GET_SURVEY_CAMPAIGNS_SUCCESS";
const GET_SURVEY_CAMPAIGNS_FAILURE = "GET_SURVEY_CAMPAIGNS_FAILURE";

const Edit_SURVEY_CAMPAIGN = "Edit_SURVEY_CAMPAIGN";
const Edit_SURVEY_CAMPAIGN_SUCCESS = "Edit_SURVEY_CAMPAIGN_SUCCESS";
const Edit_SURVEY_CAMPAIGN_FAILURE = "Edit_SURVEY_CAMPAIGN_FAILURE";

const DELETE_SURVEY_CAMPAIGN = "DELETE_SURVEY_CAMPAIGN";
const DELETE_SURVEY_CAMPAIGN_SUCCESS = "DELETE_SURVEY_CAMPAIGN_SUCCESS";
const DELETE_SURVEY_CAMPAIGN_FAILURE = "DELETE_SURVEY_CAMPAIGN_FAILURE";

const SURVEY_CAMPAIGN_BY_ID = "SURVEY_CAMPAIGN_BY_ID";
const SURVEY_CAMPAIGN_BY_ID_SUCCESS = "SURVEY_CAMPAIGN_BY_ID_SUCCESS";
const SURVEY_CAMPAIGN_BY_ID_FAILURE = "SURVEY_CAMPAIGN_BY_ID_FAILURE";

const CREATE_SURVEY_QUESTION = "CREATE_SURVEY_QUESTION";
const CREATE_SURVEY_QUESTION_SUCCESS = "CREATE_SURVEY_QUESTION_SUCCESS";
const CREATE_SURVEY_QUESTION_FAILURE = "CREATE_SURVEY_QUESTION_FAILURE";

const GET_SURVEY_QUESTIONS = "GET_SURVEY_QUESTIONS";
const GET_SURVEY_QUESTIONS_SUCCESS = "GET_SURVEY_QUESTIONS_SUCCESS";
const GET_SURVEY_QUESTIONS_FAILURE = "GET_SURVEY_QUESTIONS_FAILURE";

const EDIT_SURVEY_QUESTION = "EDIT_SURVEY_QUESTION";
const EDIT_SURVEY_QUESTION_SUCCESS = "EDIT_SURVEY_QUESTION_SUCCESS";
const EDIT_SURVEY_QUESTION_FAILURE = "EDIT_SURVEY_QUESTION_FAILURE";

const DELETE_SURVEY_QUESTION = "DELETE_SURVEY_QUESTION";
const DELETE_SURVEY_QUESTION_SUCCESS = "DELETE_SURVEY_QUESTION_SUCCESS";
const DELETE_SURVEY_QUESTION_FAILURE = "DELETE_SURVEY_QUESTION_FAILURE";

const CREATE_SURVEY_ANSWER = "CREATE_SURVEY_ANSWER";
const CREATE_SURVEY_ANSWER_SUCCESS = "CREATE_SURVEY_ANSWER_SUCCESS";
const CREATE_SURVEY_ANSWER_FAILURE = "CREATE_SURVEY_ANSWER_FAILURE";

const GET_SURVEY_ANSWERS = "GET_SURVEY_ANSWERS";
const GET_SURVEY_ANSWERS_SUCCESS = "GET_SURVEY_ANSWERS_SUCCESS";
const GET_SURVEY_ANSWERS_FAILURE = "GET_SURVEY_ANSWERS_FAILURE";

const EDIT_SURVEY_ANSWER = "EDIT_SURVEY_ANSWER";
const EDIT_SURVEY_ANSWER_SUCCESS = "EDIT_SURVEY_ANSWER_SUCCESS";
const EDIT_SURVEY_ANSWER_FAILURE = "EDIT_SURVEY_ANSWER_FAILURE";

const DELETE_SURVEY_ANSWER = "DELETE_SURVEY_ANSWER";
const DELETE_SURVEY_ANSWER_SUCCESS = "DELETE_SURVEY_ANSWER_SUCCESS";
const DELETE_SURVEY_ANSWER_FAILURE = "DELETE_SURVEY_ANSWER_FAILURE";

const QUESTION_ORDER = "QUESTION_ORDER";
const QUESTION_ORDER_SUCCESS = "QUESTION_ORDER_SUCCESS";
const QUESTION_ORDER_FAILURE = "QUESTION_ORDER_FAILURE";

const GET_SURVEY_USERS = "GET_SURVEY_USERS";
const GET_SURVEY_USERS_SUCCESS = "GET_SURVEY_USERS_SUCCESS";
const GET_SURVEY_USERS_FAILURE = "GET_SURVEY_USERS_FAILURE";

const ADD_SURVEY_USER = "ADD_SURVEY_USER";
const ADD_SURVEY_USER_SUCCESS = "ADD_SURVEY_USER_SUCCESS";
const ADD_SURVEY_USER_FAILURE = "ADD_SURVEY_USER_FAILURE";

const GET_SURVEY_USERS_BY_ID = "GET_SURVEY_USERS_BY_ID";
const GET_SURVEY_USERS_BY_ID_SUCCESS = "GET_SURVEY_USERS_BY_ID_SUCCESS";
const GET_SURVEY_USERS_BY_ID_FAILURE = "GET_SURVEY_USERS_BY_ID_FAILURE";

const REMOVE_SURVEY_USER = "REMOVE_SURVEY_USER";
const REMOVE_SURVEY_USER_SUCCESS = "REMOVE_SURVEY_USER_SUCCESS";
const REMOVE_SURVEY_USER_FAILURE = "REMOVE_SURVEY_USER_FAILURE";

export const RESET_SURVEY_CAMPAIGN = "RESET_SURVEY_CAMPAIGN";

const SurveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SURVEY_CAMPAIGN:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case CREATE_SURVEY_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        surveyCampaign: action.payload,
      };
    case CREATE_SURVEY_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case GET_SURVEY_CAMPAIGNS:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case GET_SURVEY_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        surveyCampaigns: action.payload,
      };
    case GET_SURVEY_CAMPAIGNS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case Edit_SURVEY_CAMPAIGN:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case Edit_SURVEY_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        surveyCampaign: action.payload,
        success: true,
      };
    case Edit_SURVEY_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case DELETE_SURVEY_CAMPAIGN:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case DELETE_SURVEY_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        surveyDelete: true,
      };
    case DELETE_SURVEY_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case SURVEY_CAMPAIGN_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case SURVEY_CAMPAIGN_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        surveyCampaign: action.payload,
      };
    case SURVEY_CAMPAIGN_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case CREATE_SURVEY_QUESTION:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case CREATE_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        questionCreated: true,
        surveyQuestions: [...state.surveyQuestions, action.payload],
      };
    case CREATE_SURVEY_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case GET_SURVEY_QUESTIONS:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case GET_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        surveyQuestions: action.payload,
      };
    case GET_SURVEY_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case EDIT_SURVEY_QUESTION:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case EDIT_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload,
      };
    case EDIT_SURVEY_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case DELETE_SURVEY_QUESTION:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case DELETE_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        surveyQuestions: state.surveyQuestions.filter(
          (question) => question.id !== action.payload,
        ),
      };
    case DELETE_SURVEY_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case CREATE_SURVEY_ANSWER:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case CREATE_SURVEY_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload,
      };
    case CREATE_SURVEY_ANSWER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case GET_SURVEY_ANSWERS:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case GET_SURVEY_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        surveyAnswers: action.payload,
      };
    case GET_SURVEY_ANSWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case EDIT_SURVEY_ANSWER:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case EDIT_SURVEY_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload,
      };
    case EDIT_SURVEY_ANSWER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case DELETE_SURVEY_ANSWER:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case DELETE_SURVEY_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload,
      };
    case DELETE_SURVEY_ANSWER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    case QUESTION_ORDER:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case QUESTION_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        surveyQuestions: action.payload,
      };
    case QUESTION_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    case RESET_SURVEY_CAMPAIGN:
      return {
        ...state,
        surveyCampaign: null,
        surveyQuestions: null,
        surveyAnswers: null,
        questionCreated: null,
        contactUpdateSuccess: null,
        contactAdded: null,
        loading: false,
        error: null,
        success: null,
        surveyDelete: false,
      };

    case GET_SURVEY_USERS:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case GET_SURVEY_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        surveyUsers: action.payload,
      };
    case GET_SURVEY_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    case ADD_SURVEY_USER:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case ADD_SURVEY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        surveyUsers: [...state.surveyUsers, action.payload],
      };
    case ADD_SURVEY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    case GET_SURVEY_USERS_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case GET_SURVEY_USERS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        surveyUsers: action.payload,
      };
    case GET_SURVEY_USERS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case REMOVE_SURVEY_USER:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case REMOVE_SURVEY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        surveyUsers: state.surveyUsers.filter(
          (user) => user.id !== action.payload,
        ),
      };
    case REMOVE_SURVEY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case GET_RMA_SURVEY_COMPANIES:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case GET_RMA_SURVEY_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        rmaSurveyCompany: action.payload,
      };
    case GET_RMA_SURVEY_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    case CREATE_RMA_SURVEY_COMPANY:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case CREATE_RMA_SURVEY_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        contactAdded: true,
        rmaSurveyCompany: [...state.rmaSurveyCompany, action.payload],
      };
    case CREATE_RMA_SURVEY_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    case EDIT_RMA_SURVEY_COMPANY:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case EDIT_RMA_SURVEY_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        contactUpdateSuccess: true,
        rmaSurveyCompany: state.rmaSurveyCompany.map((company) =>
          company.id === action.payload.id
            ? { ...company, ...action.payload }
            : company,
        ),
      };
    case EDIT_RMA_SURVEY_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case GET_SURVEY_RESPONSES_BY_COMPANY:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case GET_SURVEY_RESPONSES_BY_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        companySurveys: action.payload,
      };
    case GET_SURVEY_RESPONSES_BY_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    case CREATE_SURVEY_CONTACT:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };

    case CREATE_SURVEY_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        contactAdded: true,
        surveyContacts: [...state.surveyContacts, action.payload],
      };

    case CREATE_SURVEY_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    case GET_SURVEY_CONTACTS:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };

    case GET_SURVEY_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        surveyContacts: action.payload,
      };

    case GET_SURVEY_CONTACTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    default:
      return state;
  }
};

export default SurveyReducer;

export const CreateSurveyRequest = (values) => async (dispatch, getState) => {
  dispatch({ type: CREATE_SURVEY_CAMPAIGN });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/survey`,
      values,
      config,
    );
    dispatch({ type: CREATE_SURVEY_CAMPAIGN_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: CREATE_SURVEY_CAMPAIGN_FAILURE, payload: err.response });
  }
};

export const GetCompanySurveys = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_SURVEY_CAMPAIGNS });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/survey/company/${id}`,
      config,
    );
    dispatch({ type: GET_SURVEY_CAMPAIGNS_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: GET_SURVEY_CAMPAIGNS_FAILURE, payload: err.response });
  }
};

export const getSurveyById = (id) => async (dispatch, getState) => {
  dispatch({ type: SURVEY_CAMPAIGN_BY_ID });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/survey/${id}`,
      config,
    );
    dispatch({ type: SURVEY_CAMPAIGN_BY_ID_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: SURVEY_CAMPAIGN_BY_ID_FAILURE, payload: err.response });
  }
};

export const editSurvey = (id, values) => async (dispatch, getState) => {
  dispatch({ type: Edit_SURVEY_CAMPAIGN });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/survey/${id}`,
      values,
      config,
    );

    dispatch({ type: Edit_SURVEY_CAMPAIGN_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: Edit_SURVEY_CAMPAIGN_FAILURE, payload: err.response });
  }
};

export const deleteSurvey = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_SURVEY_CAMPAIGN });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/survey/${id}`,
      config,
    );
    dispatch({ type: DELETE_SURVEY_CAMPAIGN_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: DELETE_SURVEY_CAMPAIGN_FAILURE, payload: err.response });
  }
};

export const getSurveyQuestions = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_SURVEY_QUESTIONS });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/survey/questions/${id}`,
      config,
    );
    dispatch({ type: GET_SURVEY_QUESTIONS_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: GET_SURVEY_QUESTIONS_FAILURE, payload: err.response });
  }
};

export const createQuestion = (values) => async (dispatch, getState) => {
  dispatch({ type: CREATE_SURVEY_QUESTION });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/survey/questions`,
      values,
      config,
    );
    dispatch({ type: CREATE_SURVEY_QUESTION_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: CREATE_SURVEY_QUESTION_FAILURE, payload: err.response });
  }
};

export const deleteQuestion = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_SURVEY_QUESTION });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/survey/questions/${id}`,
      config,
    );
    dispatch({ type: DELETE_SURVEY_QUESTION_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: DELETE_SURVEY_QUESTION_FAILURE, payload: err.response });
  }
};

export const questionOrder = (id, values) => async (dispatch, getState) => {
  dispatch({ type: QUESTION_ORDER });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/survey/questions/${id}`,
      values,
      config,
    );
    dispatch({ type: QUESTION_ORDER_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: QUESTION_ORDER_FAILURE, payload: err.response });
  }
};

export const CreateSurveyAnswer = (values) => async (dispatch, getState) => {
  dispatch({ type: CREATE_SURVEY_ANSWER });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/survey/answers`,
      values,
      config,
    );
    dispatch({ type: CREATE_SURVEY_ANSWER_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: CREATE_SURVEY_ANSWER_FAILURE, payload: err.response });
  }
};

export const EditSurveyAnswer = (id, values) => async (dispatch, getState) => {
  dispatch({ type: EDIT_SURVEY_ANSWER });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/survey/answers/${id}`,
      values,
      config,
    );
    dispatch({ type: EDIT_SURVEY_ANSWER_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: EDIT_SURVEY_ANSWER_FAILURE, payload: err.response });
  }
};

export const DeleteSurveyAnswer = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_SURVEY_ANSWER });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/survey/answers/${id}`,
      config,
    );
    dispatch({ type: DELETE_SURVEY_ANSWER_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: DELETE_SURVEY_ANSWER_FAILURE, payload: err.response });
  }
};

export const editSurveyQuestion =
  (id, values) => async (dispatch, getState) => {
    dispatch({ type: EDIT_SURVEY_QUESTION });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `${process.env.REACT_APP_API_URL}/survey/questions/${id}`,
        values,
        config,
      );
      dispatch({ type: EDIT_SURVEY_QUESTION_SUCCESS, payload: data.data });
    } catch (err) {
      dispatch({ type: EDIT_SURVEY_QUESTION_FAILURE, payload: err.response });
    }
  };

export const getSurveyUsers = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_SURVEY_USERS });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/survey/users/${id}`,
      config,
    );
    dispatch({ type: GET_SURVEY_USERS_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: GET_SURVEY_USERS_FAILURE, payload: err.response });
  }
};

export const addSurveyUser = (values) => async (dispatch, getState) => {
  dispatch({ type: ADD_SURVEY_USER });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/survey/users`,
      values,
      config,
    );
    dispatch({ type: ADD_SURVEY_USER_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: ADD_SURVEY_USER_FAILURE, payload: err.response });
  }
};

export const removeSurveyUser = (id) => async (dispatch, getState) => {
  dispatch({ type: REMOVE_SURVEY_USER });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/survey/users/${id}`,
      config,
    );
    dispatch({ type: REMOVE_SURVEY_USER_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: REMOVE_SURVEY_USER_FAILURE, payload: err.response });
  }
};

const GET_RMA_SURVEY_COMPANIES = "GET_RMA_SURVEY_COMPANIES";
const GET_RMA_SURVEY_COMPANIES_SUCCESS = "GET_RMA_SURVEY_COMPANIES_SUCCESS";
const GET_RMA_SURVEY_COMPANIES_FAILURE = "GET_RMA_SURVEY_COMPANIES_FAILURE";

export const getRmaSurveyCompanies = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_RMA_SURVEY_COMPANIES });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/RMA/rmaCompanies/${id}`,
      config,
    );
    dispatch({ type: GET_RMA_SURVEY_COMPANIES_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: GET_RMA_SURVEY_COMPANIES_FAILURE, payload: err.response });
  }
};

const CREATE_SURVEY_CONTACT = "CREATE_SURVEY_CONTACT";
const CREATE_SURVEY_CONTACT_SUCCESS = "CREATE_SURVEY_CONTACT_SUCCESS";
const CREATE_SURVEY_CONTACT_FAILURE = "CREATE_SURVEY_CONTACT_FAILURE";

export const createSurveyContact = (formData) => async (dispatch, getState) => {
  dispatch({ type: CREATE_SURVEY_CONTACT });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/survey/contacts`,
      formData,
      config,
    );
    dispatch({ type: CREATE_SURVEY_CONTACT_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: CREATE_SURVEY_CONTACT_FAILURE, payload: err.response });
  }
};

const GET_SURVEY_CONTACTS = "GET_SURVEY_CONTACTS";
const GET_SURVEY_CONTACTS_SUCCESS = "GET_SURVEY_CONTACTS_SUCCESS";
const GET_SURVEY_CONTACTS_FAILURE = "GET_SURVEY_CONTACTS_FAILURE";

export const getSurveyContacts = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_SURVEY_CONTACTS });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/survey/contacts/${id}`,
      config,
    );

    dispatch({ type: GET_SURVEY_CONTACTS_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: GET_SURVEY_CONTACTS_FAILURE, payload: err.response });
  }
};

const CREATE_RMA_SURVEY_COMPANY = "CREATE_RMA_SURVEY_COMPANY";
const CREATE_RMA_SURVEY_COMPANY_SUCCESS = "CREATE_RMA_SURVEY_COMPANY_SUCCESS";
const CREATE_RMA_SURVEY_COMPANY_FAILURE = "CREATE_RMA_SURVEY_COMPANY_FAILURE";

export const createRmaSurveyCompanyContact =
  (formData) => async (dispatch, getState) => {
    dispatch({ type: CREATE_RMA_SURVEY_COMPANY });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/RMA/rmaCompanies`,
        formData,
        config,
      );
      dispatch({ type: CREATE_RMA_SURVEY_COMPANY_SUCCESS, payload: data.data });
    } catch (err) {
      dispatch({
        type: CREATE_RMA_SURVEY_COMPANY_FAILURE,
        payload: err.response,
      });
    }
  };

const EDIT_RMA_SURVEY_COMPANY = "EDIT_RMA_SURVEY_COMPANY";
const EDIT_RMA_SURVEY_COMPANY_SUCCESS = "EDIT_RMA_SURVEY_COMPANY_SUCCESS";
const EDIT_RMA_SURVEY_COMPANY_FAILURE = "EDIT_RMA_SURVEY_COMPANY_FAILURE";

export const EditRmaSurveyCompanyContact =
  (id, values) => async (dispatch, getState) => {
    dispatch({ type: EDIT_RMA_SURVEY_COMPANY });
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/RMA/rmaCompanies/${id}`,
        values,
        config,
      );
      dispatch({ type: EDIT_RMA_SURVEY_COMPANY_SUCCESS, payload: data.data });
    } catch (err) {
      dispatch({
        type: EDIT_RMA_SURVEY_COMPANY_FAILURE,
        payload: err.response,
      });
    }
  };

const GET_SURVEY_RESPONSES_BY_COMPANY = "GET_SURVEY_RESPONSES_BY_COMPANY";
const GET_SURVEY_RESPONSES_BY_COMPANY_SUCCESS =
  "GET_SURVEY_RESPONSES_BY_COMPANY_SUCCESS";
const GET_SURVEY_RESPONSES_BY_COMPANY_FAILURE =
  "GET_SURVEY_RESPONSES_BY_COMPANY_FAILURE";

export const getSurveyResponsesByCompanyId =
  (id) => async (dispatch, getState) => {
    dispatch({ type: GET_SURVEY_RESPONSES_BY_COMPANY });
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/survey/companySurveys/${id}`,

        config,
      );
      dispatch({
        type: GET_SURVEY_RESPONSES_BY_COMPANY_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_SURVEY_RESPONSES_BY_COMPANY_FAILURE,
        payload: err.response,
      });
    }
  };
