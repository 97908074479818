import axios from "axios";

const initialState = {
  allQuestionnaires: [],
  usersQuestionnaire: {},
  usersQuestionnairesLoading: false,
  usersQuestionnairesError: null,
  usersQuestionnairesSuccess: null,
  questionnaireCompleted: {},
  currentQuestionnaire: {},
};

const GET_USER_QUESTIONNAIRES_START = "GET_USER_QUESTIONNAIRES_START";
const GET_USER_QUESTIONNAIRES_SUCCESS = "GET_USER_QUESTIONNAIRES_SUCCESS";

const GET_QUESTIONNAIRE_BY_ID = "GET_QUESTIONNAIRE_BY_ID";

const GET_USER_QUESTIONNAIRES_FAIL = "GET_USER_QUESTIONNAIRES_FAIL";

const QUESTIONNAIRE_START = "QUESTIONNAIRE_START";

const REQUEST_RETAKE = "REQUEST_RETAKE";

export const QUESTIONNAIRE_COMPLETED = "QUESTIONNAIRE_COMPLETED";

export const RESET_USERS_QUESTIONNAIRE_DUCK = "RESET_USERS_QUESTIONNAIRE_DUCK";

export const UsersQuestionnairesDuck = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_QUESTIONNAIRES_START:
      return {
        ...state,
        usersQuestionnairesLoading: true,
        usersQuestionnairesError: null,
        usersQuestionnairesSuccess: null,
      };
    case GET_USER_QUESTIONNAIRES_SUCCESS:
      return {
        ...state,
        usersQuestionnairesLoading: false,
        usersQuestionnairesError: null,
        usersQuestionnairesSuccess: true,
        allQuestionnaires: action.payload,
      };

    case GET_QUESTIONNAIRE_BY_ID:
      return {
        ...state,
        usersQuestionnaire: action.payload,
        usersQuestionnairesLoading: false,
        usersQuestionnairesError: null,
        usersQuestionnairesSuccess: true,
      };

    case GET_USER_QUESTIONNAIRES_FAIL:
      return {
        ...state,
        usersQuestionnairesLoading: false,
        usersQuestionnairesError: action.payload,
        usersQuestionnairesSuccess: null,
      };

    case QUESTIONNAIRE_COMPLETED:
      return {
        ...state,
        currentQuestionnaire: action.payload,
        usersQuestionnairesLoading: false,
        usersQuestionnairesError: null,
        usersQuestionnairesSuccess: true,
      };

    case QUESTIONNAIRE_START:
      return {
        ...state,
        currentQuestionnaire: action.payload,
        usersQuestionnairesLoading: false,
        usersQuestionnairesError: null,
      };

    case REQUEST_RETAKE:
      return {
        ...state,
        requestRetakeSuccess: action.payload,
      };

    case RESET_USERS_QUESTIONNAIRE_DUCK:
      return {
        allQuestionnaires: [],
        usersQuestionnaire: {},
        usersQuestionnairesLoading: false,
        usersQuestionnairesError: null,
        usersQuestionnairesSuccess: null,
        questionnaireCompleted: {},
        currentQuestionnaire: {},
      };

    default:
      return state;
  }
};

export const getAllUserQuestionnaires = () => async (dispatch, getState) => {
  dispatch({ type: GET_USER_QUESTIONNAIRES_START });
  try {
    const { AuthDuck } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/userQuestionnaires`,
      config
    );
    dispatch({
      type: GET_USER_QUESTIONNAIRES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_QUESTIONNAIRES_FAIL,
      payload: error.message,
    });
  }
};

export const GetQuestionnaireById = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_USER_QUESTIONNAIRES_START });
  try {
    const { AuthDuck } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/userQuestionnaires/${id}`,
      config
    );
    dispatch({
      type: GET_QUESTIONNAIRE_BY_ID,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_QUESTIONNAIRES_FAIL,
      payload: error.message,
    });
  }
};

export const createResponseStart =
  (id, startData) => async (dispatch, getState) => {
    dispatch({ type: GET_USER_QUESTIONNAIRES_START });
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/questionnaireResponses/${id}`,
        startData,
        config
      );
      dispatch({
        type: QUESTIONNAIRE_START,
        payload: data.data,
      });

      data.data.expiry =
        new Date().getTime() + new Date(data.data.endTime).getTime();

      localStorage.setItem("currentQuestionnaire", JSON.stringify(data.data));
    } catch (error) {
      dispatch({
        type: GET_USER_QUESTIONNAIRES_FAIL,
        payload: error.message,
      });
    }
  };

export const completeQuestionnaire =
  (completeData) => async (dispatch, getState) => {
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/questionnaireResponses/completed`,
        completeData,
        config
      );
      dispatch({
        type: QUESTIONNAIRE_COMPLETED,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_QUESTIONNAIRES_FAIL,
        payload: error.message,
      });
    }
  };

export const RequestRetake = (id) => async (dispatch, getState) => {
  try {
    const { AuthDuck } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/questionnaireResponses/retake/${id}`,
      {},
      config
    );
    dispatch({
      type: REQUEST_RETAKE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_QUESTIONNAIRES_FAIL,
      payload: error.message,
    });
  }
};
