import { Grid, Card, CardActionArea, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';

const TabRouter = props => {
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const currentPath = pathname.split("/")[3]

    return (
      <Grid container>
        {props.paths.map((path, index) => {
          const selectedLocation =
            Array.isArray(path.location) ? path.location[0] : path.location;
    
          return (
            <Grid key={index} item xs={3}>
              <Card
                sx={
                  selectedLocation.includes(currentPath)
                    ? { borderStyle: "solid", borderColor: "primary.main" }
                    : null
                }
              >
                <CardActionArea
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    py: 5,
                  }}
                  onClick={() => {
                    navigate(`${selectedLocation}`);
                  }}
                >
                  <Typography color="textPrimary">{path.name}</Typography>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
    
}

export default TabRouter