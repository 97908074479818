import React, { useState, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Grid, Button, Alert, LinearProgress } from "@mui/material";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import withAuth from "../../../../Hoc/withAuth";
import ListSaleOrders from "./ListSaleOrders";

const TaskFlow = ({ platform, user }) => {
  const [page, setPage] = useState(0);

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const taskFlowSalesOrders = useQuery(
    [`getTaskFlowSaleOrders`, page],
    () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/taskflow/sales_orders?pageNumber=${page}&limit=1000`,
        config
      );
    },
    {
      keepPreviousData: true,
    }
  );

  const {
    data: taskFlow,
    isLoading,
    isSuccess,
    error,
    isError,
  } = taskFlowSalesOrders;

  const goToPage = (page) => () => setPage(page);

  const nextPageHandler = goToPage(taskFlow?.data?.data?.nextPage);
  const previousPageHandler = goToPage(taskFlow?.data?.data?.previousPage);

  if (isLoading) {
    return <LinearProgress sx={{ mt: 2 }} />;
  } else if (isError) {
    return (
      <Alert severity="error" sx={{ my: 2 }}>
        Error: {error?.response?.data?.message}{" "}
      </Alert>
    );
  } else if (isSuccess) {
    return (
      <>
        <Grid>
          <Button value={page} onClick={previousPageHandler}>
            Previous
          </Button>
          <Button value={page} onClick={nextPageHandler}>
            Next
          </Button>
          <Suspense fallback={<FetchingData />}>
            <ListSaleOrders
              platform={platform}
              filteredRows={taskFlow?.data?.data?.rows}
              isLoading={isLoading}
            />
          </Suspense>
        </Grid>
        <Outlet />
      </>
    );
  }
};

export default withAuth(TaskFlow);

function FetchingData() {
  return <div>Fetching Sales Orders...</div>;
}
