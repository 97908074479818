import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import EditCompanyContactDialog from "./EditCompanyContactDialog";

const CompletedUserWSurveys = ({ company, getUserCompletedSurveys }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const theme = useTheme();

  console.log(company);

  return (
    <>
      <List>
        <StyledListItem
          secondaryAction={
            <Stack direction="row">
              <EditCompanyContactDialog rmaSurveyCompany={company} />
              {getUserCompletedSurveys(company.Contact_Name).length > 0 ? (
                <>
                  <Button color="secondary" onClick={handleClick}>
                    <Typography variant="body2">completed</Typography>
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </Button>{" "}
                </>
              ) : (
                <Button disabled onClick={handleClick}>
                  <Typography variant="body2">!completed</Typography>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </Button>
              )}
            </Stack>
          }>
          <Stack direction="row" spacing={5}>
            <Typography>{company.Contact_Name}</Typography>
            <Typography>{<Chip label={company.Designation} />}</Typography>
          </Stack>
        </StyledListItem>
        <Stack>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {getUserCompletedSurveys(company.Contact_Name).map(
                (survey, index) => {
                  return (
                    <Grid
                      key={index}
                      sx={{ my: 1 }}
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}>
                      <Card
                        variant="contained"
                        sx={{
                          border: `1px solid ${theme.palette.secondary.dark}`,
                        }}>
                        <CardHeader
                          title="Completed Survey"
                          subheader={survey?.survey_contact_person}
                        />
                        <CardContent>
                          {survey?.survey_campaign?.name}
                          <Typography variant="body1">
                            {survey?.survey_campaign?.client}
                          </Typography>
                          <Typography variant="caption">
                            {survey?.note1}
                          </Typography>
                          <Typography variant="caption">
                            {survey?.note2}
                          </Typography>
                          <Typography variant="caption">
                            {survey?.note3}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            onClick={() =>
                              navigate(
                                `/CDA/ViewSurvey/${survey?.id}/${survey?.survey_user_id}`
                              )
                            }>
                            View Survey
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                }
              )}
            </List>
          </Collapse>
        </Stack>
      </List>
    </>
  );
};

export default CompletedUserWSurveys;

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
