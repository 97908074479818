import { Card, CardActionArea, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import MainAppContainer from "../../../../../Components/Containers/MainAppContainer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import withAuth from "../../../../../Hoc/withAuth";
import { useParams } from "react-router-dom";
import CampaignIcon from "@mui/icons-material/Campaign";
import SegmentIcon from "@mui/icons-material/Segment";
import GroupsIcon from "@mui/icons-material/Groups";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PostAddIcon from "@mui/icons-material/PostAdd";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SourceIcon from "@mui/icons-material/Source";
// import SmsIcon from "@mui/icons-material/Sms";
import { useEffect } from "react";
import { useDispatch /*useSelector*/ } from "react-redux";
import { getCampaignLeadSourcesByCampaignId } from "./State/CampaignLeadSourceDuck";
import { getCampaignAdVoiceSMSFiles } from "./State/CampaignAdVoiceSMSDuck";
// import AdsClickIcon from "@mui/icons-material/AdsClick";

const CampaignManageTabs = ({ user, modules }) => {
  const navigate = useNavigate();

  const { id } = useParams();
  const dispatch = useDispatch();

  if (
    modules &&
    modules.includes("call-center-admin") &&
    !user.userType === "admin"
  ) {
    navigate("/");
  }

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getCampaignLeadSourcesByCampaignId(id));
    dispatch(getCampaignAdVoiceSMSFiles(id));
  }, [dispatch, id]);
  // useEffect(() => {
  //   dispatch({ type: RESET_CAMPAIGNS });
  // }, [dispatch]);

  const currentPath = pathname.split("/")[5];

  const Paths = [
    {
      name: "Campaign",
      location: "Campaign",
      icon: <CampaignIcon />,
    },
    {
      name: "Dispositions",
      location: "dispositions",
      icon: <SegmentIcon />,
    },
    {
      name: "Teams",
      location: "teams",
      icon: <GroupsIcon />,
    },
    {
      name: "Sales",
      location: "CampaignSales",
      icon: <TrendingUpIcon />,
    },
    {
      name: "Qa Queues",
      location: "QaQueueManage",
      icon: <PersonSearchIcon />,
    },
    {
      name: "Leads Management",
      location: "LeadsManage",
      icon: <QueryStatsIcon />,
    },
    {
      name: "Lead File Uploader",
      location: "LeadFileUploader",
      icon: <PostAddIcon />,
    },
    {
      name: "API Linking",
      location: "CampaignLeadSources",
      icon: <SourceIcon />,
    },
    // {
    //   name: "Ad Voice SMS",
    //   location: "AdvoiceSMS",
    //   icon: <SmsIcon />,
    // },
  ];

  return (
    <MainAppContainer maxWidth="xl" disableGutters>
      <PageHeader
        title={`Campaign Management ${id}`}
        subheader="Manage Call Center Campaign"
        backLink={`/CDA/callCenter/campaigns`}
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Call Center Campaigns List",
            href: `/CDA/callCenter/campaigns`,
          },
          {
            title: "Campaign Management",
            href: `/ManageCampaign/${id}/edit/Campaign`,
          },
        ]}
      />

      <Grid container>
        {Paths.map((path, index) => {
          return (
            <Grid key={index} item xs={3}>
              <Card
                sx={
                  currentPath === path.location
                    ? { borderStyle: "solid", borderColor: "secondary.main" }
                    : null
                }
              >
                <CardActionArea
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    py: 5,
                  }}
                  onClick={() => {
                    navigate(`${path.location}`);
                  }}
                >
                  <Typography color="textPrimary">{path.name}</Typography>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Stack sx={{ mt: 4, mb: 18 }}>
        <Outlet />
      </Stack>
    </MainAppContainer>
  );
};

export default withAuth(CampaignManageTabs);
