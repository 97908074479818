import MainAppContainer from "../../../Components/Containers/MainAppContainer"
import PageHeader from "../../../Components/Bits/PageHeader"
import TabRouter from "../../../Components/Navigation/TabRouter"
import { Stack } from "@mui/material"
import { Outlet } from 'react-router-dom'
import withAuth from "../../../Hoc/withAuth"
import { useNavigate } from "react-router-dom"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const OnboardingHR = ({ user, modules }) => {
    const navigate = useNavigate()

    if(!modules?.includes("oe-hr")){
        navigate('/')
    }

    const paths = [
        {
            name: 'Employees',
            location: 'employees',
        },
        {
            name: 'Requisition',
            location: ['requisition', 'AddRequisition'],
        },
        {
            name: 'Positions',
            location: 'positions',
        },
        {
            name: 'Departments',
            location: 'departments',
        },
    ]
    
    return (
        <QueryClientProvider client={queryClient}>
            <MainAppContainer maxWidth="xl" disableGutters>
                <PageHeader
                    title="Onboarding Employee"
                    subheader="HR"
                    breadcrumbs={[
                    {
                        title: "Dashboard",
                        href: "/",
                    },
                    {
                        title: "Onboarding Employee - HR",
                        href: "/onboarding/hr",
                    },
                    ]}
                />
                
                <TabRouter paths={paths} />

                <Stack sx={{ mt: 4}}>
                    <Outlet />
                </Stack>
            </MainAppContainer>
        </QueryClientProvider>
    )
}

export default withAuth(OnboardingHR)