import React, { useEffect } from "react";
import { FormControl, MenuItem, Select, InputLabel, Grid } from "@mui/material";
import { useFormikContext } from "formik";



const SaleOrderStatusDropDown = ({ name, label, disabled  }) => {

    // thunk this from somewhere..
    const states = ['draft','cancel', 'sale', 'done']

    const { values, setFieldValue } = useFormikContext()

    const { state } = values

    useEffect(() => {
        setFieldValue(name, state)
    }, [name, setFieldValue, state])

    const handleStateSelect = (event) =>
        states.includes(event.target.value) ? 
            setFieldValue(name, event.target.value) :
            setFieldValue(name, state)      
    
    return (
        <Grid>
            <FormControl fullWidth>
                    <InputLabel id={name}>State</InputLabel>
                    <Select
                        defaultValue={states[3]}
                        disabled={disabled}
                        id={name} 
                        value={state}
                        label={label}
                        onChange={handleStateSelect}
                    >
                        {states && states.map((item, i) => (
                            <MenuItem 
                                key={i} 
                                value={item}
                                >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
            </FormControl>
        </Grid>
    );
}

export default SaleOrderStatusDropDown;