import React from "react";
import {
  Typography,
  Stack,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CardContent,
  Collapse,
  CardHeader,
  Box,
  Chip,
  IconButton,
} from "@mui/material";
import withAuth from "../../../../../../../Hoc/withAuth";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import ResultTable from "./ResultTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ViewPastEvaluations from "./ViewPastEvaluations";

const ViewEvaluationMetrics = ({ user }) => {
  const { evalID} = useParams();
  const [openNotes, setOpenNotes] = React.useState(false);

  const handleOpenNotesClick = () => {
    setOpenNotes(!openNotes);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const { data: evaluationResults } = useQuery({
    queryKey: ["getActiveQuestionGroups", evalID],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/results/${evalID}/results/one`,
        config
      );
      return response.data;
    },
    onError: (error) =>
      console.error("Error fetching evaluation results:", error),
  });

  const evals = evaluationResults?.data || [];


  const isResults = evaluationResults?.data?.status !== 'New' && evaluationResults?.data?.status !== 'Busy';


  const formatTimeCall = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} minute(s) ${seconds} seconds`;
  };

  const formatTimeTracking = (timeString) => {
    const timeRegex = /(\d+)h (\d+)m (\d+)s/; 
    const matches = timeString.match(timeRegex);
  
    if (!matches) {
      return "0 minutes 0 seconds"; 
    }
  
    const hours = parseInt(matches[1], 10);
    const minutes = parseInt(matches[2], 10);
    const seconds = parseInt(matches[3], 10);
  
    // Only show hours if it's greater than 0
    if (hours > 0) {
      return `${hours} hours ${minutes} minutes ${seconds} seconds`;
    }
  
    return `${minutes} minutes ${seconds} seconds`;
  };

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <Stack direction="row" sx={{ justifyContent: "space-between" }}>
            <Typography fontSize={20}>General Notes</Typography>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleOpenNotesClick}
            >
              {openNotes ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Stack>
        </CardContent>
        <Collapse in={openNotes}>
          <CardContent>
            <Stack spacing={1}>
              {evals?.notes?.length > 0 ? (
                evals.notes.map((note, index) => (
                  <Card key={index} sx={{ mb: 1 }} variant="outlined">
                    <CardHeader
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            width: "100%", 
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography variant="body2">
                              {`Note ${index + 1}: ${note?.note}`}
                            </Typography>
                            <Typography variant="body2" >
                            Created By: {note?.createdBy} 
                          </Typography>
                           
                          </Box>
                          <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {note?.date
                                ? new Date(note.date).toLocaleDateString()
                                : ""}
                            </Typography>
                        </Box>
                      }
                    />
                  </Card>
                ))
              ) : (
                <Typography>No notes available</Typography>
              )}
            </Stack>
          </CardContent>
        </Collapse>
      </Card>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 300 }} aria-label="evaluation table">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body1" fontSize={18}>
                  Evaluation status:
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Chip
                  label={
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {evals?.status}
                    </span>
                  }
                  color={
                    evals?.status === "Completed"
                      ? "secondary"
                      : evals?.status === "Busy"
                      ? "warning"
                      : evals?.status === "New" ||
                        evals?.status === "Re-Evaluate"
                      ? "primary"
                      : evals?.status === "Failed"
                      ? "error"
                      : "info"
                  }
                />
              </TableCell>
            </TableRow>

            {/* Time took to listen to the call */}
            <TableRow>
              <TableCell>
                <Typography variant="body1" fontSize={18}>
                  Time took to listen to the call:
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" sx={{ color: "primary.main" }}>
                  {evals?.call_listening_time
                    ? formatTimeCall(evals.call_listening_time)
                    : "0 minutes 0 seconds"}
                </Typography>
              </TableCell>
            </TableRow>

            {/* Time took for the evaluation */}
            <TableRow>
              <TableCell>
                <Typography variant="body1" fontSize={18}>
                  Time took for the evaluation:
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" sx={{ color: "primary.main" }}>
                  {evals?.time_tracking?.time
                    ? formatTimeTracking(evals?.time_tracking?.time)
                    : "0 minutes 0 seconds"}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ViewPastEvaluations evaluation={evals}/>
      {isResults && <ResultTable />}


    </Stack>
  );
};

export default withAuth(ViewEvaluationMetrics);

