import { Card, CardHeader, Grid, Typography, Button } from "@mui/material";
import { Stack } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BarPlotChart from "../../../../../Components/Charts/BarPlotChart";
import SelectWrapper from "../../../../../Components/FormComponents/Select";
import { getSalesAgentCampaigns } from "./State/SalesAgentCampaignsDuck";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import StormIcon from "@mui/icons-material/Storm";
import ContentItem from "../QaAgent/Components/ContentItem";
import PieChartWithCenterLabel from "../../../../../Components/Charts/PieChartWithCenterLabel";

const AgentReportsDashBoard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesAgentCampaigns());
  }, [dispatch]);

  const salesAgent = useSelector((state) => state.salesAgent);
  const { campaigns } = salesAgent;

  console.log(campaigns);

  const [allCampaigns, setAllCampaigns] = useState(false);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Agent Reports DashBoard" />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={{
              agent_id: "",
              campaign_id: "",
              from_date: "",
              to_date: "",
            }}
            onSubmit={(values) => {
              console.log(values);
            }}>
            {({ setFieldValue }) => {
              return (
                <Form>
                  <Grid container>
                    <Grid sx={{ mt: 3 }} item xs={12}>
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant={allCampaigns ? "contained" : "outlined"}
                          onClick={() => setAllCampaigns(!allCampaigns)}>
                          {allCampaigns ? "All Campaigns" : "Select Campaign"}
                        </Button>
                        <SelectWrapper
                          name="campaign_id"
                          disabled={allCampaigns}
                          label="Select Campaign"
                          options={campaigns.map((campaign) => ({
                            label: campaign.name,
                            value: campaign.id,
                          }))}
                        />
                      </Stack>
                    </Grid>
                    <Grid sx={{ mt: 2 }} item xs={12}>
                      <Typography align="center" sx={{ mb: 2 }} variant="h6">
                        Select Date Range
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                          format="DD/MM/YYYY"
                          localeText={{ start: "From", end: "To" }}
                          onChange={(value) => {
                            setFieldValue("start_date", value[0]);
                            setFieldValue("end_date", value[1]);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid sx={{ mt: 2 }} item xs={12}>
                      <Button variant="contained">Submit</Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>

        <Grid item xs={6}>
          <BarPlotChart />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card variant="outlined">
                <CardHeader
                  title="CI"
                  subheader=" Month To Day"
                  action={<ModeStandbyIcon />}
                />
                <Stack direction="row">
                  <ContentItem title="Target" value="230K" />
                  <ContentItem title="Actual" value="246K" />
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card variant="outlined">
                <CardHeader
                  title="CI"
                  subheader="Forecast"
                  action={<StormIcon />}
                />
                <ContentItem title="Forecast" value="230K" />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card variant="outlined">
                <CardHeader
                  title="Sales"
                  subheader="Month To Day"
                  action={<ModeStandbyIcon />}
                />
                <Stack direction="row">
                  <ContentItem title="Target" value="3 000" />
                  <ContentItem title="Actual" value="2683" />
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card variant="outlined">
                <CardHeader
                  title="Sales"
                  subheader="Forecast"
                  action={<StormIcon />}
                />
                <ContentItem title="Forecast" value="12 100" />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <PieChartWithCenterLabel label="CI % Tgt" />
            </Grid>
            <Grid item xs={6}>
              <PieChartWithCenterLabel label="Sales % Tgt" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <BarPlotChart />
        </Grid>
        <Grid item xs={6}>
          <BarPlotChart />
        </Grid>
      </Grid>
    </>
  );
};

export default AgentReportsDashBoard;
