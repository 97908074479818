import { Alert, Box, LinearProgress, Typography, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import DateRangeSelector from "../../../../../../Components/FormComponents/DateRangeSelector";
import withAuth from "../../../../../../Hoc/withAuth";

const AgentQaReport = ({ user }) => {
  const { token } = user;

  const [value, setValue] = React.useState([
    dayjs().startOf("month"),
    dayjs().endOf("month"),
  ]);

  const { data, isLoading, refetch, isRefetching, isError, error } = useQuery(
    ["getAgents"],
    () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/reports/QaReports/agents?startDate=${value[0]}&endDate=${value[1]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    refetch();
  }, [value, refetch]);

  const columns = [
    {
      field: "name",
      headerName: "CC Agent Name",
      flex: 1,
      minWidth: 200,
      align: "left",
      // rendercell that combines name and surname
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.name} {params.row.surname}
          </Typography>
        );
      },
    },
    {
      field: "startDate",
      headerName: "From",

      minWidth: 100,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD-MMM-YYYY");
      },
    },
    {
      field: "endDate",
      headerName: "To",
      minWidth: 100,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD-MMM-YYYY");
      },
    },
    {
      field: "totalEvaluations",
      headerName: "Total Evaluations",
      minWidth: 200,

      align: "center",
    },

    {
      field: "failureRate",
      headerName: "Failure Rate % (Pending Ratio)",
      minWidth: 200,
      align: "center",
      valueFormatter: (params) => {
        return params.value + "%";
      },
    },
    {
      field: "failed",
      headerName: "Current Failed (Pending)",
      minWidth: 200,
      align: "center",
    },
    {
      field: "failedPercentage",
      headerName: "Current Pending %",
      minWidth: 200,
      type: "number",
      align: "center",
      valueFormatter: (params) => {
        return params.value + "%";
      },
    },
    {
      field: "totalScore",
      type: "number",
      headerName: "Total Qa Score",
      aggregationFunction: "average",
      align: "center",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            color={Number(params.row.totalScore) < 9 ? "error" : "success"}
          >
            {params.row.totalScore} / 10
          </Typography>
        );
      },
    },
    {
      field: "totalCompleted",
      headerName: "Total Completed",
      align: "center",
      minWidth: 200,
    },
  ];

  return (
    <Stack sx={{ mt: 3 }}>
      <DateRangeSelector value={value} setValue={setValue} />

      {(isRefetching || isLoading) && <LinearProgress />}

      {isError && (
        <Alert severity="error">
          {error?.response?.data?.message || "Something went wrong"}
        </Alert>
      )}

      {
        <Box sx={{ mt: 4 }}>
          <Grid container>
            {data?.data?.data && data?.data?.data?.length > 0 ? (
              <div
                style={{
                  height: 750,
                  width: "94vw",
                }}
              >
                <DataGridPremium
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  rows={data?.data?.data}
                  columns={columns}
                  pagination
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                      },
                    },
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                />
              </div>
            ) : (
              <Alert severity="info">No Data Found</Alert>
            )}
          </Grid>
        </Box>
      }
    </Stack>
  );
};

export default withAuth(AgentQaReport);
