import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { DialogContent, Typography } from "@mui/material";

import { useDispatch } from "react-redux";

import SelectWrapper from "../../../../../../../Components/FormComponents/Select";
import { addUserToQaTeams } from "../../State/QaTeamsDuck";
import SelectLanguagesComp from "../../../../../../../Components/FormComponents/SelectLanguagesComp";

export default function AddUsersToTeamDialog({ users, teamId }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add Users To Team
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Add Users To Team</DialogTitle>

        <Formik
          initialValues={{
            teamId: teamId,
            user_id: "",
            languages: [],
          }}
          onSubmit={(values) => {
            dispatch(addUserToQaTeams({ values }));
            handleClose();
          }}>
          {({ values }) => (
            <Form>
              <DialogContent>
                {users && users.length > 0 ? (
                  <>
                    <SelectWrapper
                      label="User"
                      name="user_id"
                      options={users?.map((user) => {
                        return {
                          value: user.user_id,
                          label: user.User.name,
                        };
                      })}
                    />
                    <SelectLanguagesComp name="languages" />
                  </>
                ) : (
                  <Typography>
                    Give Users QA module module to add them to Qa Teams
                  </Typography>
                )}
              </DialogContent>

              <Button variant="contained" type="submit" fullWidth>
                Save
              </Button>
              <Button onClick={handleClose} fullWidth>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
