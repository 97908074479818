import { Button, Stack, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import React from "react";
import { useCampaignContext } from "./CampaignContext";

import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";
import NotificationsGroup from "../Notificationsgroup/NotificationsGroup";


const InboundCampaignForm = () => {
  const campaign = useCampaignContext();

  const EditInboundCampaign = useMutation({
    mutationFn: async (data) =>
      await axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${data.id}`,
        data
      ),
  });



  return (
    <div>
      <Typography variant="h6" sx={{ my: 3 }}>
        Updated Campaign Details
      </Typography>

      <Formik
        initialValues={{
          ...campaign,
          name: campaign.name || "",
          description: campaign.description || "",
          type: campaign.type || "",
          callbi_scorecard: campaign.callbi_scorecard || "",
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          EditInboundCampaign.mutate(values);
        }}>
        {() => {
          return (
            <Form>
              <Stack sx={{ py: 2 }} spacing={2}>
                <TextfieldWrapper name="name" label="Name" />
                <TextfieldWrapper name="description" label="Description" />
                <TextfieldWrapper name="type" label="Type" />
                <TextfieldWrapper name="callbi_scorecard" label="Scorecard" />
              </Stack>

              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button variant="contained" type="submit" color="secondary">
                  save
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
      <AlertPopup
        open={EditInboundCampaign.isSuccess}
        severity="success"
        message="Campaign Updated"
      />
      <AlertPopup
        open={EditInboundCampaign.isError}
        severity="success"
        message="Error Updating campaign, Please contact administrator"
      />
      <NotificationsGroup/>
    </div>
  );
};

export default InboundCampaignForm;
