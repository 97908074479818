import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  List,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import MainAppContainer from "../../../Components/Containers/MainAppContainer";
import { useParams } from "react-router-dom";
import CampaignsList from "../Campaigns/CampaignsList";
import { useDispatch, useSelector } from "react-redux";
import { getClientById } from "../StateManager/Clients/ClientsDuck";
import ClientsContactList from "../components/ClientsContactList";
import CreateClientContactForm from "../components/CreateClientContact";
import CreateClientCampaignForm from "../Campaigns/CreateCampaign";
import { StyledListItem } from "../../../Components/Containers/StyledListItem";
import PageHeader from "../../../Components/Bits/PageHeader";

const ClientView = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientById(id));
  }, [dispatch, id]);

  const ClientDuck = useSelector((state) => state.ClientsDuck);
  const { client, loading, error } = ClientDuck;

  if (loading) {
    <Stack display="flex" justifyContent="center" alignContent="center">
      <CircularProgress />
    </Stack>;
  }
  if (error) {
    <Stack display="flex" justifyContent="center" alignContent="center">
      <Alert severity="error">{JSON.stringify(error)}</Alert>
    </Stack>;
  }

  return (
    <MainAppContainer>
      <PageHeader
        title="Client View"
        subheader="View Client Details"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "CRM",
            href: `/crm`,
          },
          {
            title: "Clients",
            href: `/CRM/clients/clientsList`,
          },
          {
            title: "Client View",
            href: `/crm/clients/clientView/${id}`,
          },
        ]}
      />

      <Card variant="outlined">
        <CardHeader
          title={client.company_name}
          subheader={client.company_business}
        />
        <CardContent>
          <List dense>
            <StyledListItem>
              <ListItemText
                primary="Industry Segment"
                secondary={client.industry_segment}
              />
            </StyledListItem>
            <StyledListItem>
              <ListItemText
                primary="Industry Sub Segment"
                secondary={client.industry_sub_segment}
              />
            </StyledListItem>

            <StyledListItem>
              <ListItemText
                primary="Responsibility"
                secondary={
                  <>
                    {client.User?.name} {client.User?.surname}
                  </>
                }
              />
            </StyledListItem>
          </List>
        </CardContent>
      </Card>

      <Typography variant="h4" sx={{ my: 4 }} align="center">
        Contacts
      </Typography>

      <CreateClientContactForm id={id} />
      {client?.crm_client_contacts && (
        <ClientsContactList clientContacts={client?.crm_client_contacts} />
      )}

      <Typography variant="h4" sx={{ my: 4 }} align="center">
        Campaigns
      </Typography>
      <CreateClientCampaignForm id={id} />
      <CampaignsList campaigns={client?.crm_campaigns} />
    </MainAppContainer>
  );
};

// Id, company_name,industry_segment,industry_sub_segment,company_business,possible_opportunities,meeting_schedule,responsibility

// Id, company_id,contact_name, contact_position,landline_no,cell_no,email_address
// 321,123,John Doe,sales,0115554789,0826574569,john@samtam.co.za
// 322,123,jane Doe,sales,0115554789,0826574569,jane@samtam.co.za

export default ClientView;
