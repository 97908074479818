import ReactAudioPlayer from "react-audio-player";
import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

const MediaPlayerComp = ({ src, setTotalCallTime }) => {
  const handleSetTotalCallTime = (e) => {
    setTotalCallTime((initial) => e.target.duration + initial);
  };

  const theme = useTheme();

  const [listenTime, setListenTime] = useState(0);

  const recordListenTime = (e) => {
    setListenTime(e);
  };

  useEffect(() => {
    console.log(`Total listening time: ${listenTime} seconds`);
  }, [listenTime]);

  return (
    <>
      <ReactAudioPlayer
        style={{
          width: "100%",
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
        // onTimeUpdate={recordListenTime}
        src={src}
        onListen={recordListenTime}
        onLoadedMetadata={handleSetTotalCallTime}
        controls
      />
    </>
  );
};

export default MediaPlayerComp;
