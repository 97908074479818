import React from "react";
import MainAppContainer from "../../../../../Components/Containers/MainAppContainer";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import withAuth from "../../../../../Hoc/withAuth";
import { Grid, Card, CardActionArea, Typography } from "@mui/material";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

const ReportsMain = ({ user, modules }) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const currentPath = pathname.split("/")[2];

  const Paths = [
    // {
    //   name: "Agent Reports",
    //   location: "agents",
    // },
    // {
    //   name: "Development Reports",
    //   location: "development",
    // },
    {
      name: "QA Reports",
      location: "QaMain",
    },
  ];

  if (
    modules &&
    modules.includes("call-center-admin") &&
    !user.userType === "admin"
  ) {
    navigate("/");
  } else {
    return (
      <MainAppContainer>
        <PageHeader
          title="Call Center Reports"
          subheader="Reports"
          breadcrumbs={[
            {
              title: "Dashboard",
              href: "/",
            },
            {
              title: "Call Center Reports",
              href: "/reports",
            },
          ]}
        />
        <Grid container>
          {Paths.map((path, index) => {
            return (
              <Grid key={index} item xs={3}>
                <Card
                  sx={
                    currentPath === path.location
                      ? { borderStyle: "solid", borderColor: "primary.main" }
                      : null
                  }
                >
                  <CardActionArea
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      py: 5,
                    }}
                    onClick={() => {
                      navigate(`${path.location}`);
                    }}
                  >
                    <Typography color="textPrimary">{path.name}</Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        <Outlet />
      </MainAppContainer>
    );
  }
};
export default withAuth(ReportsMain);
