import axios from "axios";

const initialState = {
  campaign: {},
  campaigns: [],
  loading: false,
  success: true,
  error: null,
};

const CREATE_CLIENT_CAMPAIGN = "CREATE_CLIENT_CAMPAIGN";
const CREATE_CLIENT_CAMPAIGN_SUCCESS = "CREATE_CLIENT_CAMPAIGN_SUCCESS";
const CREATE_CLIENT_CAMPAIGN_FAILURE = "CREATE_CLIENT_CAMPAIGN_FAILURE";

const GET_ALL_CAMPAIGNS = "GET_ALL_CAMPAIGNS";
const GET_ALL_CAMPAIGNS_SUCCESS = "GET_ALL_CAMPAIGNS_SUCCESS";
const GET_ALL_CAMPAIGNS_FAILURE = "GET_ALL_CAMPAIGNS_FAILURE";

const GET_CAMPAIGN = "GET_CAMPAIGN";
const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
const GET_CAMPAIGN_FAILURE = "GET_CAMPAIGN_FAILURE";

const CRMCampaignDuck = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CLIENT_CAMPAIGN:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case CREATE_CLIENT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, action.payload],
        loading: false,
        success: true,
        error: null,
      };
    case CREATE_CLIENT_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case GET_ALL_CAMPAIGNS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_ALL_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case GET_ALL_CAMPAIGNS_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case GET_CAMPAIGN:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case GET_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default CRMCampaignDuck;

export const createClientCampaign =
  (campaign) => async (dispatch, getState) => {
    dispatch({ type: CREATE_CLIENT_CAMPAIGN });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/crm/campaign`,
        campaign,
        config
      );

      dispatch({ type: CREATE_CLIENT_CAMPAIGN_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({
        type: CREATE_CLIENT_CAMPAIGN_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAllCampaigns = () => async (dispatch, getState) => {
  dispatch({ type: GET_ALL_CAMPAIGNS });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/crm/campaign`,
      config
    );

    dispatch({ type: GET_ALL_CAMPAIGNS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: GET_ALL_CAMPAIGNS_FAILURE,
      payload: error.message,
    });
  }
};

export const getCampaignById = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_CAMPAIGN });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/crm/campaign/${id}`,
      config
    );

    dispatch({ type: GET_CAMPAIGN_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: GET_CAMPAIGN_FAILURE,
      payload: error.message,
    });
  }
};
