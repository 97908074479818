import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import MainAppContainer from "../../../../../Components/Containers/MainAppContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  createResponseStart,
  getAllUserQuestionnaires,
} from "./State/UsersQuestionnairesDuck";
import { useNavigate } from "react-router-dom";
import withAuth from "../../../../../Hoc/withAuth";

import PageHeader from "../../../../../Components/Bits/PageHeader";
import RequestQuestionnaireReset from "./Components/RequestQuestionnaireReset";
import QuestionnaireHistory from "./Components/QuestionnaireHistory";

const UserQuestionnaires = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllUserQuestionnaires());
  }, [dispatch]);

  const UsersQuestionnairesDuck = useSelector(
    (state) => state.UsersQuestionnairesDuck
  );
  const {
    allQuestionnaires,
    usersQuestionnairesLoading,
    usersQuestionnairesError,
    currentQuestionnaire,
  } = UsersQuestionnairesDuck;

  const testTimeCalc = (testTime) => {
    const TOTAL = testTime * 60 * 1000;
    const NOW_IN_MS = new Date().getTime();
    return NOW_IN_MS + TOTAL;
  };

  const StartQuestionnaire = (questionnaire) => {
    dispatch(
      createResponseStart(questionnaire.campaign_id, {
        user_id: questionnaire.id,
        totalTime: questionnaire?.questionnaire_campaign?.test_time,
        startTime: new Date().getTime(),
        pass_percentage: questionnaire?.questionnaire_campaign?.pass_percentage,
        endTime: testTimeCalc(questionnaire?.questionnaire_campaign?.test_time),
        attempts: questionnaire?.questionnaire_responses?.attempts
          ? questionnaire?.questionnaire_responses?.attempts + 1
          : 1,
      })
    );
    navigate(`/CDA/TakeQuestionnaire/${questionnaire.campaign_id}`);
  };

  let enabledQuestionnaires = [];
  let disabledQuestionnaires = [];

  allQuestionnaires?.forEach((element) => {
    if (element.questionnaire_campaign.status) {
      enabledQuestionnaires.push(element);
    } else {
      disabledQuestionnaires.push(element);
    }
  });

  if (usersQuestionnairesLoading) {
    return (
      <MainAppContainer>
        <Typography variant="h4" sx={{ my: 4 }} align="center">
          Loading...
        </Typography>
      </MainAppContainer>
    );
  }

  return (
    <MainAppContainer>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PageHeader
            title="Questionnaires"
            subheader="Manage your questionnaires"
            breadcrumbs={[
              {
                title: "Dashboard",
                href: "/",
              },
              {
                title: "Questionnaires",
                href: "/CDA/UserQuestionnaires",
              },
            ]}
          />
          {allQuestionnaires.length > 0 ? (
            enabledQuestionnaires.map((questionnaire, index) => {
              return (
                <Card key={index}>
                  <CardHeader
                    sx={{ mb: 0, pb: 0 }}
                    title={questionnaire.questionnaire_campaign.name}
                    subheader={questionnaire.questionnaire_campaign.description}
                  />
                  <CardContent sx={{ mt: 0, pt: 0 }}>
                    <List dense>
                      <ListItem>
                        <ListItemText
                          primary="Type of Test"
                          secondary={questionnaire.questionnaire_campaign.type}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Total Time "
                          secondary={`${questionnaire.questionnaire_campaign.test_time} minutes`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Total Question"
                          secondary={
                            questionnaire.questionnaire_campaign.question_amount
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Complete By"
                          secondary={new Date(
                            questionnaire.complete_by
                          ).toLocaleDateString("en-ZA")}
                        />
                      </ListItem>
                    </List>

                    <QuestionnaireHistory questionnaire={questionnaire} />

                    {currentQuestionnaire &&
                    currentQuestionnaire?.campaign_id ===
                      questionnaire.questionnaire_campaign.id ? (
                      <>
                        <Stack mt={3}>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              navigate("/CDA/TakeQuestionnaire/2")
                            }>
                            continue Questionnaire
                          </Button>
                        </Stack>
                      </>
                    ) : (
                      <Stack mt={3}>
                        {questionnaire.can_retake ? (
                          <Button
                            variant="contained"
                            onClick={() => StartQuestionnaire(questionnaire)}>
                            Start Questionnaire
                          </Button>
                        ) : (
                          <RequestQuestionnaireReset
                            questionnaireId={
                              questionnaire.questionnaire_campaign.id
                            }
                          />
                        )}
                      </Stack>
                    )}
                  </CardContent>
                </Card>
              );
            })
          ) : (
            <Alert severity="info">No Questionnaires Found</Alert>
          )}
        </Grid>
      </Grid>
      {usersQuestionnairesError && (
        <Alert severity="error">{usersQuestionnairesError}</Alert>
      )}
    </MainAppContainer>
  );
};

export default withAuth(UserQuestionnaires);
