import axios from "axios";

const initialState = {
  dispositionList: {},
  disposition_list: [],
  listAdded: false,
  dispositions: [],
  loading: null,
  success: null,
  error: null,
  disposition_details: [],
  addedDisposition: false,
  addedDispositionOption: false,
  detailAdded: false,
  deleteDispositionSuccess: false,
  detailsDeleteSuccess: false,
  dispositionsAddedToList: false,
};

const GET_DISPOSITIONS_LISTS = "GET_DISPOSITIONS_LISTS";
const GET_DISPOSITIONS_LISTS_SUCCESS = "GET_DISPOSITIONS_LISTS_SUCCESS";
const GET_DISPOSITIONS_LISTS_ERROR = "GET_DISPOSITIONS_LISTS_ERROR";

const EDIT_DISPOSITION_IN_LIST = "EDIT_DISPOSITION_IN_LIST";
const EDIT_DISPOSITION_IN_LIST_SUCCESS = "EDIT_DISPOSITION_IN_LIST_SUCCESS";
const EDIT_DISPOSITION_IN_LIST_ERROR = "EDIT_DISPOSITION_IN_LIST_ERROR";

const CREATE_DISPOSITION_LIST = "CREATE_DISPOSITION_LIST";
const CREATE_DISPOSITION_LIST_SUCCESS = "CREATE_DISPOSITION_LIST_SUCCESS";
const CREATE_DISPOSITION_LIST_ERROR = "CREATE_DISPOSITION_LIST_ERROR";

const EDIT_DISPOSITION_LIST = "EDIT_DISPOSITION_LIST";
const EDIT_DISPOSITION_LIST_SUCCESS = "EDIT_DISPOSITION_LIST_SUCCESS";
const EDIT_DISPOSITION_LIST_ERROR = "EDIT_DISPOSITION_LIST_ERROR";

const GET_DISPOSITION_LIST_BY_ID = "GET_DISPOSITION_LIST_BY_ID";
const GET_DISPOSITION_LIST_BY_ID_SUCCESS = "GET_DISPOSITION_LIST_BY_ID_SUCCESS";
const GET_DISPOSITION_LIST_BY_ID_ERROR = "GET_DISPOSITION_LIST_BY_ID_ERROR";

const GET_DISPOSITIONS = "GET_DISPOSITIONS";
const GET_DISPOSITIONS_SUCCESS = "GET_DISPOSITIONS_SUCCESS";
const GET_DISPOSITIONS_FAIL = "GET_DISPOSITIONS_FAIL";

const CREATE_DISPOSITION = "CREATE_DISPOSITION";
const CREATE_DISPOSITION_SUCCESS = "CREATE_DISPOSITION_SUCCESS";
const CREATE_DISPOSITION_FAIL = "CREATE_DISPOSITION_FAIL";

const EDIT_DISPOSITION = "EDIT_DISPOSITION";
const EDIT_DISPOSITION_SUCCESS = "EDIT_DISPOSITION_SUCCESS";
const EDIT_DISPOSITION_FAIL = "EDIT_DISPOSITION_FAIL";

const DELETE_DISPOSITION = "DELETE_DISPOSITION";
const DELETE_DISPOSITION_SUCCESS = "DELETE_DISPOSITION_SUCCESS";
const DELETE_DISPOSITION_FAIL = "DELETE_DISPOSITION_FAIL";

const GET_DISPOSITION_DETAILS = "GET_DISPOSITION_DETAILS";
const GET_DISPOSITION_DETAILS_SUCCESS = "GET_DISPOSITION_DETAILS_SUCCESS";
const GET_DISPOSITION_DETAILS_FAIL = "GET_DISPOSITION_DETAILS_FAIL";

const CREATE_DISPOSITION_DETAILS = "CREATE_DISPOSITION_DETAILS";
const CREATE_DISPOSITION_DETAILS_SUCCESS = "CREATE_DISPOSITION_DETAILS_SUCCESS";
const CREATE_DISPOSITION_DETAILS_FAIL = "CREATE_DISPOSITION_DETAILS_FAIL";

const EDIT_DISPOSITION_DETAILS = "EDIT_DISPOSITION_DETAILS";
const EDIT_DISPOSITION_DETAILS_SUCCESS = "EDIT_DISPOSITION_DETAILS_SUCCESS";
const EDIT_DISPOSITION_DETAILS_FAIL = "EDIT_DISPOSITION_DETAILS_FAIL";

const DELETE_DISPOSITION_DETAILS = "DELETE_DISPOSITION_DETAILS";
const DELETE_DISPOSITION_DETAILS_SUCCESS = "DELETE_DISPOSITION_DETAILS_SUCCESS";
const DELETE_DISPOSITION_DETAILS_FAIL = "DELETE_DISPOSITION_DETAILS_FAIL";

const LINK_DISPOSITION_DETAILS = "LINK_DISPOSITION_DETAILS";
const LINK_DISPOSITION_DETAILS_SUCCESS = "LINK_DISPOSITION_DETAILS_SUCCESS";
const LINK_DISPOSITION_DETAILS_FAIL = "LINK_DISPOSITION_DETAILS_FAIL";

const LINK_DISPOSITION_DETAILS_IN_LIST = "LINK_DISPOSITION_DETAILS_IN_LIST";
const LINK_DISPOSITION_DETAILS_IN_LIST_SUCCESS =
  "LINK_DISPOSITION_DETAILS_IN_LIST_SUCCESS";
const LINK_DISPOSITION_DETAILS_IN_LIST_FAIL =
  "LINK_DISPOSITION_DETAILS_IN_LIST_FAIL";

const REMOVE_LINK_DISPOSITION_DETAILS = "REMOVE_LINK_DISPOSITION_DETAILS";
const REMOVE_LINK_DISPOSITION_DETAILS_SUCCESS =
  "REMOVE_LINK_DISPOSITION_DETAILS_SUCCESS";
const REMOVE_LINK_DISPOSITION_DETAILS_FAIL =
  "REMOVE_LINK_DISPOSITION_DETAILS_FAIL";

const ADD_DISPOSITIONS_TO_DISPOSITION_LIST =
  "ADD_DISPOSITIONS_TO_DISPOSITION_LIST";
const ADD_DISPOSITIONS_TO_DISPOSITION_LIST_SUCCESS =
  "ADD_DISPOSITIONS_TO_DISPOSITION_LIST_SUCCESS";
const ADD_DISPOSITIONS_TO_DISPOSITION_LIST_ERROR =
  "ADD_DISPOSITIONS_TO_DISPOSITION_LIST_ERROR";

export const REMOVE_LINK_DISPOSITION_DETAILS_FILTER =
  "REMOVE_LINK_DISPOSITION_DETAILS_FILTER";

export const RESET_DISPOSITIONS = "RESET_DISPOSITION";

const DispositionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISPOSITIONS_LISTS:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case GET_DISPOSITIONS_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        disposition_list: action.payload,
      };
    case GET_DISPOSITIONS_LISTS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case EDIT_DISPOSITION_IN_LIST:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };

    case EDIT_DISPOSITION_IN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        disposition_list: state.disposition_list.map((disposition) =>
          disposition.id === action.payload.id ? action.payload : disposition
        ),
      };

    case EDIT_DISPOSITION_IN_LIST_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case CREATE_DISPOSITION_LIST:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case CREATE_DISPOSITION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listAdded: true,
        disposition_list: [
          ...state.disposition_list,
          { ...action.payload, cc_disposition_details: [] },
        ],
      };
    case CREATE_DISPOSITION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case EDIT_DISPOSITION_LIST:
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
        editList: false,
      };
    case EDIT_DISPOSITION_LIST_SUCCESS:
      return {
        ...state,
        editList: true,
        loading: false,
        success: true,
        disposition_list: state.disposition_list.map((list) =>
          list.id === action.payload.id ? action.payload : list
        ),
      };
    case EDIT_DISPOSITION_LIST_ERROR:
      return {
        ...state,
        editList: false,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_DISPOSITIONS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_DISPOSITIONS_SUCCESS:
      return {
        ...state,
        dispositions: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case GET_DISPOSITIONS_FAIL:
      return {
        ...state,
        dispositions: [],
        loading: false,
        success: false,
        error: action.payload,
      };
    case CREATE_DISPOSITION:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case CREATE_DISPOSITION_SUCCESS:
      let newDispositions = {
        ...action.payload,
        cc_disposition_disposition_details: [],
      };
      return {
        ...state,
        dispositions: [...state.dispositions, newDispositions],
        addedDisposition: true,
        loading: false,
        success: true,
        error: null,
      };
    case CREATE_DISPOSITION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case EDIT_DISPOSITION:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case EDIT_DISPOSITION_SUCCESS:
      return {
        ...state,
        dispositions: state.dispositions.map((disposition) => {
          if (disposition.id === action.payload.id) {
            return action.payload;
          }
          return disposition;
        }),
        loading: false,
        success: true,
        error: null,
      };
    case EDIT_DISPOSITION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case DELETE_DISPOSITION:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case DELETE_DISPOSITION_SUCCESS:
      return {
        ...state,
        dispositions: state.dispositions.filter(
          (disposition) => disposition.id !== action.payload
        ),
        loading: false,
        success: true,
        error: null,
      };
    case DELETE_DISPOSITION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_DISPOSITION_DETAILS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_DISPOSITION_DETAILS_SUCCESS:
      return {
        ...state,
        disposition_details: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case GET_DISPOSITION_DETAILS_FAIL:
      return {
        ...state,
        disposition_details: [],
        loading: false,
        success: false,
        error: action.payload,
      };
    case CREATE_DISPOSITION_DETAILS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case CREATE_DISPOSITION_DETAILS_SUCCESS:
      return {
        ...state,
        disposition_details: [...state.disposition_details, action.payload],
        loading: false,
        addedDispositionOption: true,
        success: true,
        error: null,
      };
    case CREATE_DISPOSITION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case EDIT_DISPOSITION_DETAILS:
      return {
        ...state,
        loading: true,
        success: false,
        editList: false,
        error: null,
      };
    case EDIT_DISPOSITION_DETAILS_SUCCESS:
      return {
        ...state,

        disposition_details: state.disposition_details.map((disposition) => {
          if (disposition.id === action.payload.id) {
            return action.payload;
          }
          return disposition;
        }),
        loading: false,
        success: true,
        error: null,
      };
    case EDIT_DISPOSITION_DETAILS_FAIL:
      return {
        ...state,
        editList: false,
        loading: false,
        success: false,
        error: action.payload,
      };

    case DELETE_DISPOSITION_DETAILS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case DELETE_DISPOSITION_DETAILS_SUCCESS:
      return {
        ...state,
        disposition_details: state.disposition_details.filter(
          (disposition) => disposition.id !== action.payload
        ),
        loading: false,
        detailsDeleteSuccess: true,
        success: true,
        error: null,
      };
    case DELETE_DISPOSITION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case LINK_DISPOSITION_DETAILS_IN_LIST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case LINK_DISPOSITION_DETAILS_IN_LIST_SUCCESS:
      return {
        ...state,
        dispositionList: {
          ...state.dispositionList,
          cc_dispositions: state?.dispositionList?.cc_dispositions?.map(
            (disposition) => {
              if (disposition.id === action.payload.disposition_id) {
                return {
                  ...disposition,
                  cc_disposition_details: [
                    ...disposition.cc_disposition_details,
                    action.payload,
                  ],
                };
              }
              return disposition;
            }
          ),
        },
        detailAdded: true,
        loading: false,
        success: true,
        error: null,
      };
    case LINK_DISPOSITION_DETAILS_IN_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case LINK_DISPOSITION_DETAILS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case LINK_DISPOSITION_DETAILS_SUCCESS:
      return {
        ...state,
        dispositions: state.dispositions.map((disposition) => {
          if (disposition.id === action.payload.disposition_id) {
            return {
              ...disposition,
              gl_disposition_details: [
                ...disposition.gl_disposition_details,
                action.payload,
              ],
            };
          }
          return disposition;
        }),
        detailAdded: true,
        loading: false,
        success: true,
        error: null,
      };
    case LINK_DISPOSITION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case REMOVE_LINK_DISPOSITION_DETAILS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case REMOVE_LINK_DISPOSITION_DETAILS_SUCCESS:
      return {
        ...state,
        dispositions: state.dispositions.map((disposition) => {
          if (disposition.id === action.payload.id) {
            return action.payload;
          }
          return disposition;
        }),
        detailRemoved: false,
        loading: false,
        success: true,
        error: null,
      };

    case REMOVE_LINK_DISPOSITION_DETAILS_FILTER:
      return {
        ...state,
        dispositions: state.dispositions.map((disposition) => {
          if (disposition.id === action.payload.disposition_id) {
            return {
              ...disposition,
              cc_disposition_disposition_details:
                disposition.cc_disposition_disposition_details.filter(
                  (detail) =>
                    detail.disposition_detail_id !== action.payload.detail
                ),
            };
          }
          return disposition;
        }),
      };

    case REMOVE_LINK_DISPOSITION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,

        error: action.payload,
      };

    case GET_DISPOSITION_LIST_BY_ID:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_DISPOSITION_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        dispositionList: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case GET_DISPOSITION_LIST_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case ADD_DISPOSITIONS_TO_DISPOSITION_LIST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
        dispositionsAddedToList: false,
      };

    case ADD_DISPOSITIONS_TO_DISPOSITION_LIST_SUCCESS:
      return {
        ...state,

        dispositionsAddedToList: true,

        loading: false,
        success: true,
        error: null,
      };
    case ADD_DISPOSITIONS_TO_DISPOSITION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        dispositionsAddedToList: false,
        success: false,
        error: action.payload,
      };

    case RESET_DISPOSITIONS:
      return {
        ...state,
        editList: false,
        addedDisposition: false,
        addedDispositionOption: false,
        detailAdded: false,
        detailRemoved: false,
        deleteDispositionSuccess: false,
        detailsDeleteSuccess: false,
        listAdded: false,
        dispositionsAddedToList: false,
      };

    default:
      return state;
  }
};

export default DispositionsReducer;

export const getDispositionListById = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_DISPOSITION_LIST_BY_ID,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/dispositions/lists/${id}`,
      config
    );
    dispatch({
      type: GET_DISPOSITION_LIST_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_DISPOSITION_LIST_BY_ID_ERROR,
      payload: err.response,
    });
  }
};

export const editDispositionList =
  (id, values) => async (dispatch, getState) => {
    dispatch({
      type: EDIT_DISPOSITION_LIST,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/callCentre/dispositions/lists/${id}`,
        values,
        config
      );
      dispatch({
        type: EDIT_DISPOSITION_LIST_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: EDIT_DISPOSITION_LIST_ERROR,
        payload: err.response,
      });
    }
  };

export const editDispositionInList = (values) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_DISPOSITION_LIST,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/callCentre/dispositions/${values.id}`,
      values,
      config
    );
    dispatch({
      type: EDIT_DISPOSITION_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_DISPOSITION_LIST_ERROR,
      payload: err.response,
    });
  }
};

export const getDispositions = () => async (dispatch, getState) => {
  dispatch({
    type: GET_DISPOSITIONS,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/global/dispositions`,
      config
    );
    dispatch({
      type: GET_DISPOSITIONS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_DISPOSITIONS_FAIL,
      payload: err.response,
    });
  }
};

export const createDisposition =
  (disposition) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_DISPOSITION,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/global/dispositions/:id`,
        disposition,
        config
      );
      dispatch({
        type: CREATE_DISPOSITION_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_DISPOSITION_FAIL,
        payload: err.response,
      });
    }
  };

export const deleteDisposition = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_DISPOSITION,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.delete(
      `${process.env.REACT_APP_API_URL}/callCentre/dispositions/${id}`,
      config
    );
    dispatch({
      type: DELETE_DISPOSITION_SUCCESS,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: DELETE_DISPOSITION_FAIL,
      payload: err.response,
    });
  }
};

export const deleteDispositionDetails = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_DISPOSITION_DETAILS,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/callCentre/dispositionDetails/${id}`,
      config
    );
    dispatch({
      type: DELETE_DISPOSITION_DETAILS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_DISPOSITION_DETAILS_FAIL,
      payload: err.response,
    });
  }
};

export const getDispositionDetailsList = () => async (dispatch, getState) => {
  dispatch({
    type: GET_DISPOSITION_DETAILS,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/global/dispositionDetails`,
      config
    );
    dispatch({
      type: GET_DISPOSITION_DETAILS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_DISPOSITION_DETAILS_FAIL,
      payload: err.response,
    });
  }
};

export const createGlobalDispositionDetails =
  (dispositionDetails) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_DISPOSITION_DETAILS,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/global/dispositionDetails`,
        dispositionDetails,
        config
      );
      dispatch({
        type: CREATE_DISPOSITION_DETAILS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_DISPOSITION_DETAILS_FAIL,
        payload: err.response,
      });
    }
  };
export const createDispositionDetails =
  (dispositionDetails) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_DISPOSITION_DETAILS,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/dispositionDetails`,
        dispositionDetails,
        config
      );
      dispatch({
        type: CREATE_DISPOSITION_DETAILS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_DISPOSITION_DETAILS_FAIL,
        payload: err.response,
      });
    }
  };

export const linkDispositionDetails =
  (values) => async (dispatch, getState) => {
    dispatch({
      type: LINK_DISPOSITION_DETAILS,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/global/dispositions/${values.disposition_id}/detail`,
        values,
        config
      );
      dispatch({
        type: LINK_DISPOSITION_DETAILS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: LINK_DISPOSITION_DETAILS_FAIL,
        payload: err.response,
      });
    }
  };

export const removeDispositionLinkToDetail =
  ({ disposition_id, disposition_detail_id }) =>
  async (dispatch, getState) => {
    dispatch({
      type: REMOVE_LINK_DISPOSITION_DETAILS,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/callCentre/dispositions/${disposition_id}/detail/${disposition_detail_id}`,
        config
      );
      dispatch({
        type: REMOVE_LINK_DISPOSITION_DETAILS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: REMOVE_LINK_DISPOSITION_DETAILS_FAIL,
        payload: err.response,
      });
    }
  };

export const editDisposition =
  ({ id, dis }) =>
  async (dispatch, getState) => {
    dispatch({
      type: EDIT_DISPOSITION,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `${process.env.REACT_APP_API_URL}/global/dispositions/${id}`,
        dis,
        config
      );
      dispatch({
        type: EDIT_DISPOSITION_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: EDIT_DISPOSITION_FAIL,
        payload: err.response,
      });
    }
  };

export const editDispositionDetails =
  ({ id, dis }) =>
  async (dispatch, getState) => {
    dispatch({
      type: EDIT_DISPOSITION_DETAILS,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `${process.env.REACT_APP_API_URL}/global/dispositions/${id}/detail`,
        dis,
        config
      );
      dispatch({
        type: EDIT_DISPOSITION_DETAILS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: EDIT_DISPOSITION_DETAILS_FAIL,
        payload: err.response,
      });
    }
  };

export const EditLinkedDetail =
  ({ dis_id, detail_id, dis }) =>
  async (dispatch, getState) => {
    dispatch({
      type: EDIT_DISPOSITION_DETAILS,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `${process.env.REACT_APP_API_URL}/callCentre/dispositionDetails/${detail_id}`,
        dis,
        config
      );
      dispatch({
        type: EDIT_DISPOSITION_DETAILS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: EDIT_DISPOSITION_DETAILS_FAIL,
        payload: err.response,
      });
    }
  };

export const getDispositionListList = () => async (dispatch, getState) => {
  dispatch({
    type: GET_DISPOSITIONS_LISTS,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/dispositions/lists`,
      config
    );
    dispatch({
      type: GET_DISPOSITIONS_LISTS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_DISPOSITIONS_LISTS_ERROR,
      payload: err.response,
    });
  }
};

export const createDispositionList =
  (dispositionList) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_DISPOSITION_LIST,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/dispositions/lists`,
        dispositionList,
        config
      );
      dispatch({
        type: CREATE_DISPOSITION_LIST_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_DISPOSITION_LIST_ERROR,
        payload: err.response,
      });
    }
  };

export const PostAddDispositionsToDispositionList =
  (list_id, values) => async (dispatch, getState) => {
    dispatch({
      type: ADD_DISPOSITIONS_TO_DISPOSITION_LIST,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/dispositions/lists/${list_id}/disposition`,
        values,
        config
      );
      dispatch({
        type: ADD_DISPOSITIONS_TO_DISPOSITION_LIST_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: ADD_DISPOSITIONS_TO_DISPOSITION_LIST_ERROR,
        payload: err.response,
      });
    }
  };

export const linkDetailToDispositionInList =
  ({ disposition_id, values }) =>
  async (dispatch, getState) => {
    dispatch({
      type: LINK_DISPOSITION_DETAILS_IN_LIST,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/dispositions/${disposition_id}/detail`,
        values,
        config
      );
      dispatch({
        type: LINK_DISPOSITION_DETAILS_IN_LIST_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: LINK_DISPOSITION_DETAILS_IN_LIST_FAIL,
        payload: err.response,
      });
    }
  };
