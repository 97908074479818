import axios from "axios";

const GET_USER_SURVEYS = "GET_USER_SURVEYS";
const GET_USER_SURVEYS_SUCCESS = "GET_USER_SURVEYS_SUCCESS";
const GET_USER_SURVEYS_FAILURE = "GET_USER_SURVEYS_FAILURE";

const GET_USER_SURVEY_DETAILS_BY_ID = "GET_USER_SURVEY_DETAILS_BY_ID";
const GET_USER_SURVEY_DETAILS_BY_ID_SUCCESS =
  "GET_USER_SURVEY_DETAILS_BY_ID_SUCCESS";
const GET_USER_SURVEY_DETAILS_BY_ID_FAILURE =
  "GET_USER_SURVEY_DETAILS_BY_ID_FAILURE";

const GET_SURVEYS_USER = "GET_SURVEYS";
const GET_SURVEYS_USER_SUCCESS = "GET_SURVEYS_USER_SUCCESS";
const GET_SURVEYS_USER_FAILURE = "GET_SURVEYS_USER_FAILURE";

export const RESET_USER_SURVEY = "RESET_USER_SURVEY";

const SurveyUsersReducer = (
  state = {
    surveys: [],
    surveyDetails: {},
    isLoading: false,
    error: null,
    formData: [],
    completedSurvey: [],
    surveyUser: {},
    completedSurveysList: [],
  },
  action,
) => {
  switch (action.type) {
    case GET_USER_SURVEYS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_USER_SURVEYS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        surveys: action.payload,
      };
    case GET_USER_SURVEYS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_USER_SURVEY_DETAILS_BY_ID:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_USER_SURVEY_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        surveyDetails: action.payload,
      };
    case GET_USER_SURVEY_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case COMPLETE_SURVEY:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case COMPLETE_SURVEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        completedSurvey: action.payload,
        completedSurveySuccess: true,
      };
    case COMPLETE_SURVEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_SURVEYS_USER:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_SURVEYS_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        surveyUser: action.payload,
      };
    case GET_SURVEYS_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_COMPLETED_SURVEYS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_COMPLETED_SURVEYS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        completedSurveysList: action.payload.survey_responses,
      };
    case GET_COMPLETED_SURVEYS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EDIT_QUESTION_ANSWER:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case EDIT_QUESTION_ANSWER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case EDIT_QUESTION_ANSWER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case RESET_USER_SURVEY:
      return {
        ...state,
        completedSurveySuccess: false,
      };

    default:
      return state;
  }
};

export default SurveyUsersReducer;

export const getUsersSurveys = () => async (dispatch, getState) => {
  dispatch({ type: GET_USER_SURVEYS });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/survey/list`,
      config,
    );
    dispatch({ type: GET_USER_SURVEYS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_USER_SURVEYS_FAILURE, payload: error.message });
  }
};

export const getUserSurveyDetailsById =
  (id, survey_user_id) => async (dispatch, getState) => {
    dispatch({ type: GET_USER_SURVEY_DETAILS_BY_ID });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/survey/completed/${id}/${survey_user_id}`,
        config,
      );
      dispatch({
        type: GET_USER_SURVEY_DETAILS_BY_ID_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_SURVEY_DETAILS_BY_ID_FAILURE,
        payload: error.message,
      });
    }
  };

const COMPLETE_SURVEY = "COMPLETE_SURVEY";
const COMPLETE_SURVEY_SUCCESS = "COMPLETE_SURVEY_SUCCESS";
const COMPLETE_SURVEY_FAILURE = "COMPLETE_SURVEY_FAILURE";

export const completeSurvey = (formData) => async (dispatch, getState) => {
  dispatch({ type: COMPLETE_SURVEY });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/survey/completed`,
      formData,
      config,
    );
    dispatch({
      type: COMPLETE_SURVEY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: COMPLETE_SURVEY_FAILURE,
      payload: error.message,
    });
  }
};

export const getSurveyUser = () => async (dispatch, getState) => {
  dispatch({ type: GET_SURVEYS_USER });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/survey/user`,
      config,
    );
    dispatch({
      type: GET_SURVEYS_USER_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SURVEYS_USER_FAILURE,
      payload: error.message,
    });
  }
};

const GET_COMPLETED_SURVEYS = "GET_COMPLETED_SURVEYS";
const GET_COMPLETED_SURVEYS_SUCCESS = "GET_COMPLETED_SURVEYS_SUCCESS";
const GET_COMPLETED_SURVEYS_FAILURE = "GET_COMPLETED_SURVEYS_FAILURE";

export const getCompletedSurveys = () => async (dispatch, getState) => {
  dispatch({ type: GET_COMPLETED_SURVEYS });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/survey/completed`,
      config,
    );
    dispatch({
      type: GET_COMPLETED_SURVEYS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_COMPLETED_SURVEYS_FAILURE,
      payload: error.message,
    });
  }
};

const EDIT_QUESTION_ANSWER = "EDIT_QUESTION_ANSWER";
const EDIT_QUESTION_ANSWER_SUCCESS = "EDIT_QUESTION_ANSWER_SUCCESS";
const EDIT_QUESTION_ANSWER_FAILURE = "EDIT_QUESTION_ANSWER_FAILURE";

export const editQuestionAnswer =
  (id, formData) => async (dispatch, getState) => {
    dispatch({ type: EDIT_QUESTION_ANSWER });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/survey/completed/answer/${id}`,
        formData,
        config,
      );
      dispatch({
        type: EDIT_QUESTION_ANSWER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_QUESTION_ANSWER_FAILURE,
        payload: error.message,
      });
    }
  };
