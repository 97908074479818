import axios from "axios";
import { POPULATE_USER } from "../ActionNames/AllActions";

const initialState = {
  allCompanies: [],
  moduleOptions: [],
  User: {},
  userCompany: {},
  moduleUsers: [],
  success: false,
  addCompanySuccess: false,
  addCompanyError: false,
  deleteCompanySuccess: false,
  deleteCompanyError: false,
  assignCompanySuccess: false,
  assignCompanyError: false,
  moduleAdded: false,
  moduleRemoved: false,
  companyEmployees: [],
};

const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
const GET_ALL_COMPANIES_FAILURE = "GET_ALL_COMPANIES_FAILURE";

const GET_SINGLE_COMPANY = "GET_SINGLE_COMPANY";
const GET_SINGLE_COMPANY_SUCCESS = "GET_SINGLE_COMPANY_SUCCESS";
const GET_SINGLE_COMPANY_FAILURE = "GET_SINGLE_COMPANY_FAILURE";

const GET_USER_COMPANY = "GET_USER_COMPANY";
const GET_USER_COMPANY_SUCCESS = "GET_USER_COMPANY_SUCCESS";
const GET_USER_COMPANY_FAILURE = "GET_USER_COMPANY_FAILURE";

const ADD_COMPANY = "ADD_COMPANY";
const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";

const DELETE_COMPANY = "DELETE_COMPANY";
const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

const ASSIGN_COMPANY = "ASSIGN_COMPANY";
const ASSIGN_COMPANY_SUCCESS = "ASSIGN_COMPANY_SUCCESS";
const ASSIGN_COMPANY_FAILURE = "ASSIGN_COMPANY_FAILURE";

const GET_COMPANY_MODULES = "GET_COMPANY_MODULES";
const GET_COMPANY_MODULES_SUCCESS = "GET_COMPANY_MODULES_SUCCESS";
const GET_COMPANY_MODULES_FAILURE = "GET_COMPANY_MODULES_FAILURE";

const ASSIGN_COMPANY_MODULES = "ASSIGN_COMPANY_MODULES";
const ASSIGN_COMPANY_MODULES_SUCCESS = "ASSIGN_COMPANY_MODULES_SUCCESS";
const ASSIGN_COMPANY_MODULES_FAILURE = "ASSIGN_COMPANY_MODULES_FAILURE";
export const ASSIGN_COMPANY_MODULES_RESET = "ASSIGN_COMPANY_MODULES_RESET";

const REMOVE_MODULE = "REMOVE_MODULE";
const REMOVE_MODULE_SUCCESS = "REMOVE_MODULE_SUCCESS";
const REMOVE_MODULE_FAILURE = "REMOVE_MODULE_FAILURE";
export const REMOVE_MODULE_RESET = "REMOVE_MODULE_RESET";

const MOD_COMPANY_MODULES = "MOD_COMPANY_MODULES";
const MOD_COMPANY_MODULES_SUCCESS = "MOD_COMPANY_MODULES_SUCCESS";
const MOD_COMPANY_MODULES_FAILURE = "MOD_COMPANY_MODULES_FAILURE";

const CREATE_COMPANY = "CREATE_COMPANY";
const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
const CREATE_COMPANY_FAILURE = "CREATE_COMPANY_FAILURE";

const CREATE_NEW_MODULE = "CREATE_NEW_MODULE";
const CREATE_NEW_MODULE_SUCCESS = "CREATE_NEW_MODULE_SUCCESS";
const CREATE_NEW_MODULE_FAILURE = "CREATE_NEW_MODULE_FAILURE";

const GET_ALL_MODULE_OPTIONS = "GET_ALL_MODULES";
const GET_ALL_MODULE_OPTIONS_SUCCESS = "GET_ALL_MODULE_OPTIONS_SUCCESS";
const GET_ALL_MODULE_OPTIONS_FAILURE = "GET_ALL_MODULE_OPTIONS_FAILURE";

const DELETE_MODULE_OPTION = "DELETE_MODULE_OPTION";
const DELETE_MODULE_OPTION_SUCCESS = "DELETE_MODULE_OPTION_SUCCESS";
const DELETE_MODULE_OPTION_FAILURE = "DELETE_MODULE_OPTION_FAILURE";

const GET_COMPANY_EMPLOYEES = "GET_COMPANY_EMPLOYEES";
const GET_COMPANY_EMPLOYEES_SUCCESS = "GET_COMPANY_EMPLOYEES_SUCCESS";
const GET_COMPANY_EMPLOYEES_FAILURE = "GET_COMPANY_EMPLOYEES_FAILURE";

const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
const UPDATE_EMPLOYEE_FAIL = "UPDATE_EMPLOYEE_FAIL";

const ADD_NEW_EMPLOYEE = "ADD_NEW_EMPLOYEE";
const ADD_NEW_EMPLOYEE_SUCCESS = "ADD_NEW_EMPLOYEE_SUCCESS";
const ADD_NEW_EMPLOYEE_FAIL = "ADD_NEW_EMPLOYEE_FAIL";

export const RESET_COMPANY_STATE = "RESET_COMPANY_STATE";

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_MODULE:
      return {
        ...state,
        success: false,
      };
    case CREATE_NEW_MODULE_SUCCESS:
      return {
        ...state,
        success: true,
        moduleOptions: [...state.moduleOptions, action.payload],
        moduleAdded: true,
      };
    case CREATE_NEW_MODULE_FAILURE:
      return {
        ...state,
        success: false,
      };
    case GET_ALL_MODULE_OPTIONS:
      return {
        ...state,
        success: false,
      };
    case GET_ALL_MODULE_OPTIONS_SUCCESS:
      return {
        ...state,
        success: true,
        moduleOptions: action.payload,
      };
    case GET_ALL_MODULE_OPTIONS_FAILURE:
      return {
        ...state,
        success: false,
      };
    case DELETE_MODULE_OPTION:
      return {
        ...state,
        success: false,
      };
    case DELETE_MODULE_OPTION_SUCCESS:
      return {
        ...state,
        success: true,
        moduleOptions: state.moduleOptions.filter(
          (module) => +module.id !== +action.payload,
        ),
        moduleRemoved: true,
      };
    case DELETE_MODULE_OPTION_FAILURE:
      return {
        ...state,
        success: false,
      };

    case GET_ALL_COMPANIES:
      return {
        ...state,
        allCompanies: [],
      };
    case GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        allCompanies: action.payload,
      };
    case GET_ALL_COMPANIES_FAILURE:
      return {
        ...state,
        allCompanies: [],
      };

    case GET_SINGLE_COMPANY:
      return {
        ...state,
        getCompanyLoading: true,
        company: {},
      };
    case GET_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        getCompanyLoading: false,
        company: action.payload,
      };
    case GET_SINGLE_COMPANY_FAILURE:
      return {
        ...state,
        getCompanyLoading: false,
        company: {},
      };

    case GET_USER_COMPANY:
      return {
        ...state,
        userCompany: {},
      };
    case GET_USER_COMPANY_SUCCESS:
      return {
        ...state,
        userCompany: action.payload,
      };
    case GET_USER_COMPANY_FAILURE:
      return {
        ...state,
        userCompany: {},
      };
    case ADD_COMPANY:
      return {
        ...state,
        addCompanySuccess: false,
        addCompanyError: false,
      };
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        addCompanySuccess: true,
        addCompanyError: false,
      };
    case ADD_COMPANY_FAILURE:
      return {
        ...state,
        addCompanySuccess: false,
        addCompanyError: true,
      };
    case DELETE_COMPANY:
      return {
        ...state,
        deleteCompanySuccess: false,
        deleteCompanyError: false,
      };
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        deleteCompanySuccess: true,
        deleteCompanyError: false,
      };
    case DELETE_COMPANY_FAILURE:
      return {
        ...state,
        deleteCompanySuccess: false,
        deleteCompanyError: true,
      };
    case ASSIGN_COMPANY:
      return {
        ...state,
        assignCompanySuccess: false,
        assignCompanyError: false,
      };
    case ASSIGN_COMPANY_SUCCESS:
      return {
        ...state,

        assignCompanySuccess: true,
        assignCompanyError: false,
      };
    case ASSIGN_COMPANY_FAILURE:
      return {
        ...state,
        assignCompanySuccess: false,
        assignCompanyError: true,
      };

    case CREATE_COMPANY:
      return {
        ...state,
        CreateCompanySuccess: false,
        addCompanyError: false,
      };
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        CreateCompanySuccess: true,
        addCompanyError: false,
      };
    case CREATE_COMPANY_FAILURE:
      return {
        ...state,
        CreateCompanySuccess: false,
        addCompanyError: true,
      };

    case GET_COMPANY_MODULES:
      return {
        ...state,
        companyModules: [],
      };
    case GET_COMPANY_MODULES_SUCCESS:
      return {
        ...state,
        companyModules: action.payload,
      };
    case GET_COMPANY_MODULES_FAILURE:
      return {
        ...state,
        companyModules: [],
      };

    case REMOVE_MODULE:
      return {
        ...state,
        removeModuleSuccess: false,
        removeModuleError: false,
        removeModuleLoading: true,
      };
    case REMOVE_MODULE_SUCCESS:
      return {
        ...state,
        removeModuleSuccess: true,
        removeModuleError: false,
        removeModuleLoading: false,
      };
    case REMOVE_MODULE_FAILURE:
      return {
        ...state,
        removeModuleSuccess: false,
        removeModuleError: true,
        removeModuleLoading: false,
      };

    case REMOVE_MODULE_RESET:
      return {
        ...state,
        removeModuleSuccess: false,
        removeModuleError: false,
        removeModuleLoading: false,
      };

    case ASSIGN_COMPANY_MODULES:
      return {
        ...state,
        assignCompanyModulesSuccess: false,
        assignCompanyModulesError: false,
      };
    case ASSIGN_COMPANY_MODULES_SUCCESS:
      return {
        ...state,
        assignCompanyModulesSuccess: true,
        assignCompanyModulesError: false,
      };
    case ASSIGN_COMPANY_MODULES_FAILURE:
      return {
        ...state,
        assignCompanyModulesSuccess: false,
        assignCompanyModulesError: true,
      };

    case ASSIGN_COMPANY_MODULES_RESET:
      return {
        ...state,
        assignCompanyModulesSuccess: false,
        assignCompanyModulesError: false,
      };

    case MOD_COMPANY_MODULES:
      return {
        ...state,
        modCompanyModulesSuccess: false,
        modCompanyModulesError: false,
        modCompanyModulesLoading: true,
      };
    case MOD_COMPANY_MODULES_SUCCESS:
      return {
        ...state,
        modCompanyModulesSuccess: true,
        modCompanyModulesError: false,
        modCompanyModulesLoading: false,
      };
    case MOD_COMPANY_MODULES_FAILURE:
      return {
        ...state,
        modCompanyModulesSuccess: false,
        modCompanyModulesLoading: false,
        modCompanyModulesError: true,
      };

    case GET_MODULES_USERS:
      return {
        ...state,
        moduleUsers: [],
      };
    case GET_MODULES_USERS_SUCCESS:
      return {
        ...state,
        moduleUsers: action.payload,
      };
    case GET_MODULES_USERS_FAILURE:
      return {
        ...state,
        moduleUsers: [],
      };

    case RESET_COMPANY_STATE:
      return {
        ...state,
        addCompanySuccess: false,
        addCompanyError: false,
        moduleRemoved: false,
        moduleAdded: false,
        deleteCompanySuccess: false,
        deleteCompanyError: false,
        assignCompanySuccess: false,
        assignCompanyError: false,
        CreateCompanySuccess: false,
        modCompanyModulesSuccess: false,
        modCompanyModulesError: false,
        updateEmployeeSuccess: false,
        updateEmployeeError: true,
      };

    case GET_COMPANY_EMPLOYEES:
      return {
        ...state,
        companyEmployees: [],
      };
    case GET_COMPANY_EMPLOYEES_SUCCESS:
      return {
        ...state,
        companyEmployees: action.payload,
      };
    case GET_COMPANY_EMPLOYEES_FAILURE:
      return {
        ...state,
        companyEmployees: [],
      };

    case UPDATE_EMPLOYEE:
      return {
        ...state,
        updateEmployeeSuccess: false,
        updateEmployeeError: false,
      };
    case UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        companyEmployees: state.companyEmployees.map((employee) => {
          if (employee.id === action.payload.id) {
            return action.payload;
          }
          return employee;
        }),

        updateEmployeeSuccess: true,
        updateEmployeeError: false,
      };
    case UPDATE_EMPLOYEE_FAIL:
      return {
        ...state,
        updateEmployeeSuccess: false,
        updateEmployeeError: true,
      };

    case ADD_NEW_EMPLOYEE:
      return {
        ...state,
        addNewEmployeeSuccess: false,
        addNewEmployeeError: false,
      };
    case ADD_NEW_EMPLOYEE_SUCCESS:
      return {
        ...state,
        companyEmployees: [...state.companyEmployees, action.payload],
        addNewEmployeeSuccess: true,
        addNewEmployeeError: false,
      };
    case ADD_NEW_EMPLOYEE_FAIL:
      return {
        ...state,
        addNewEmployeeSuccess: false,
        addNewEmployeeError: true,
      };

    default:
      return state;
  }
};

export default companyReducer;

export const getCompanies = () => async (dispatch, getState) => {
  dispatch({ type: GET_ALL_COMPANIES });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/companies`,
      config,
    );

    dispatch({ type: GET_ALL_COMPANIES_SUCCESS, payload: data.AllCompanies });
  } catch (err) {
    dispatch({ type: GET_ALL_COMPANIES_FAILURE });
  }
};

export const getCompany =
  ({ id }) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_SINGLE_COMPANY });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/companies/${id}`,
        config,
      );

      dispatch({
        type: GET_SINGLE_COMPANY_SUCCESS,
        payload: data.company,
      });
    } catch (err) {
      dispatch({ type: GET_SINGLE_COMPANY_FAILURE });
    }
  };

export const editCompany =
  ({ id, formData }) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_SINGLE_COMPANY });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/companies/${id}`,
        formData,
        config,
      );

      dispatch({
        type: GET_SINGLE_COMPANY_SUCCESS,
        payload: data.company,
      });
    } catch (err) {
      dispatch({ type: GET_SINGLE_COMPANY_FAILURE });
    }
  };

export const assignCompany =
  ({ company_id, user_id, user_type }) =>
  async (dispatch, getState) => {
    dispatch({ type: ASSIGN_COMPANY });
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/company/assign`,
        {
          company_id,
          user_id,
          user_type,
        },
        config,
      );
      dispatch({ type: ASSIGN_COMPANY_SUCCESS, payload: data.companyUsers });

      dispatch({ type: POPULATE_USER, payload: data.companyUsers });
    } catch (err) {
      dispatch({ type: ASSIGN_COMPANY_FAILURE });
    }
  };

export const getCompanyModules = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_COMPANY_MODULES });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/modules/company/${id}`,
      config,
    );

    dispatch({
      type: GET_COMPANY_MODULES_SUCCESS,
      payload: data.modules,
    });
  } catch (err) {
    dispatch({ type: GET_COMPANY_MODULES_FAILURE });
  }
};

export const assignCompanyModuleToUser =
  ({ user_id, user_type, module_id }) =>
  async (dispatch, getState) => {
    dispatch({ type: ASSIGN_COMPANY_MODULES });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/userModules`,
        {
          user_id,
          user_type,
          module_id,
        },
        config,
      );

      dispatch({
        type: ASSIGN_COMPANY_MODULES_SUCCESS,
        payload: data.modulesUser,
      });

      dispatch({ type: POPULATE_USER, payload: data.modulesUser });
    } catch (err) {
      dispatch({ type: ASSIGN_COMPANY_MODULES_FAILURE });
    }
  };
export const RemoveModuleRequest =
  ({ user_id, module_id }) =>
  async (dispatch, getState) => {
    dispatch({ type: REMOVE_MODULE });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/userModules/${user_id}/${module_id}`,
        config,
      );

      dispatch({
        type: REMOVE_MODULE_SUCCESS,
        payload: data.modulesUser,
      });
    } catch (err) {
      dispatch({ type: REMOVE_MODULE_FAILURE });
    }
  };

export const AddNewCompany =
  ({ name, status }) =>
  async (dispatch, getState) => {
    dispatch({ type: CREATE_COMPANY });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/createCompany`,
        {
          name,
          status,
        },
        config,
      );

      dispatch({
        type: CREATE_COMPANY_SUCCESS,
        payload: data.company,
      });
    } catch (err) {
      dispatch({ type: CREATE_COMPANY_FAILURE });
    }
  };

export const AddModuleToCompany =
  ({ form }) =>
  async (dispatch, getState) => {
    console.log(form);

    dispatch({ type: MOD_COMPANY_MODULES });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/modules`,
        form,

        config,
      );

      dispatch({
        type: MOD_COMPANY_MODULES_SUCCESS,
        payload: data.company,
      });
    } catch (err) {
      dispatch({ type: MOD_COMPANY_MODULES_FAILURE });
    }
  };

export const EditCompanyModule =
  ({ name, info, company_id, status }) =>
  async (dispatch, getState) => {
    dispatch({ type: MOD_COMPANY_MODULES });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/modules`,
        {
          name,
          info,
          company_id,
          status,
        },
        config,
      );

      dispatch({
        type: MOD_COMPANY_MODULES_SUCCESS,
        payload: data.company,
      });
    } catch (err) {
      dispatch({ type: MOD_COMPANY_MODULES_FAILURE });
    }
  };

export const DeleteCompanyModule =
  ({ id }) =>
  async (dispatch, getState) => {
    dispatch({ type: MOD_COMPANY_MODULES });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/modules/${id}`,

        config,
      );

      dispatch({
        type: MOD_COMPANY_MODULES_SUCCESS,
        payload: data.company,
      });
    } catch (err) {
      dispatch({ type: MOD_COMPANY_MODULES_FAILURE });
    }
  };

const GET_MODULES_USERS = "GET_MODULES_USERS";
const GET_MODULES_USERS_SUCCESS = "GET_MODULES_USERS_SUCCESS";
const GET_MODULES_USERS_FAILURE = "GET_MODULES_USERS_FAILURE";

export const getModulesUsers = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_MODULES_USERS,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/userModules/users/${id}`,
      config,
    );

    dispatch({
      type: GET_MODULES_USERS_SUCCESS,
      payload: data.content,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: GET_MODULES_USERS_FAILURE,
      payload: error.response.data.message,
    });
  }
};

export const createNewModuleOptions =
  (values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_NEW_MODULE,
      });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/moduleOption`,
        values,
        config,
      );

      dispatch({
        type: CREATE_NEW_MODULE_SUCCESS,
        payload: data.data,
      });

      // Storing the User Info in the local storage
    } catch (error) {
      dispatch({
        type: CREATE_NEW_MODULE_FAILURE,
        payload: error.response.data.message,
      });
    }
  };

export const getAllModuleOptions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_MODULE_OPTIONS,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/moduleOption`,
      config,
    );

    dispatch({
      type: GET_ALL_MODULE_OPTIONS_SUCCESS,
      payload: data.data,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: GET_ALL_MODULE_OPTIONS_FAILURE,
      payload: error.response.data.message,
    });
  }
};

export const deleteModuleOption = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_MODULE_OPTION,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/admin/moduleOption/${id}`,
      config,
    );

    dispatch({
      type: DELETE_MODULE_OPTION_SUCCESS,
      payload: data.data,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: DELETE_MODULE_OPTION_FAILURE,
      payload: error.response.data.message,
    });
  }
};

export const getCompanyEmployees = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_COMPANY_EMPLOYEES,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/company/employees`,
      config,
    );

    dispatch({
      type: GET_COMPANY_EMPLOYEES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_COMPANY_EMPLOYEES_FAILURE,
      payload: error.response.data.message,
    });
  }
};

export const updateEmployee = (values) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_EMPLOYEE,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/company/employees/${values.id}`,
      values,
      config,
    );

    dispatch({
      type: UPDATE_EMPLOYEE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_EMPLOYEE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const createNewEmployee = (values) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_NEW_EMPLOYEE,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/company/employees`,
      values,
      config,
    );

    dispatch({
      type: ADD_NEW_EMPLOYEE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: ADD_NEW_EMPLOYEE_FAIL,
      payload: error.response.data.message,
    });
  }
};
