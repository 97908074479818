import axios from "axios";

const GET_SURVEYS_RESPONSES = "GET_USER_SURVEYS";
const GET_SURVEYS_RESPONSES_SUCCESS = "GET_USER_SURVEYS_SUCCESS";
const GET_SURVEYS_RESPONSES_FAILURE = "GET_USER_SURVEYS_FAILURE";

const GET_QUESTIONS_RESPONSES = "GET_QUESTIONS_RESPONSES";
const GET_QUESTIONS_RESPONSES_SUCCESS = "GET_QUESTIONS_RESPONSES_SUCCESS";
const GET_QUESTIONS_RESPONSES_FAILURE = "GET_QUESTIONS_RESPONSES_FAILURE";

const initialState = {
  allResponses: [],
  loading: false,
  success: false,
  error: null,
  questionAnswers: [],
};

const SurveyReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SURVEYS_RESPONSES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SURVEYS_RESPONSES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        allResponses: action.payload,
      };
    case GET_SURVEYS_RESPONSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_QUESTIONS_RESPONSES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_QUESTIONS_RESPONSES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        questionAnswers: action.payload,
      };
    case GET_QUESTIONS_RESPONSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default SurveyReportsReducer;

export const getSurveyResponses = () => async (dispatch, getState) => {
  dispatch({ type: GET_SURVEYS_RESPONSES });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/SurveyReports`,

      config
    );
    dispatch({
      type: GET_SURVEYS_RESPONSES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SURVEYS_RESPONSES_FAILURE,
      payload: error.message,
    });
  }
};

export const getQuestionsResponses = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_QUESTIONS_RESPONSES });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/SurveyReports/questionsAnswers/${id}`,
      config
    );
    dispatch({
      type: GET_QUESTIONS_RESPONSES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_QUESTIONS_RESPONSES_FAILURE,
      payload: error.message,
    });
  }
};
