import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
  CardHeader,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { useParams } from "react-router-dom";
import { getLeadSourceList, getUploadedLeadFiles } from "./State/LeadsDuck";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import { getCampaignById } from "./State/CampaingsDuck";
import { useNavigate } from "react-router-dom";
import withAuth from "../../../../../Hoc/withAuth";
import { read, utils } from "xlsx";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { useDrag } from "react-dnd";
import { useDrop } from "react-dnd";

const LeadFileUploader = ({ user }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getCampaignById(id));
    dispatch(getLeadSourceList());
    dispatch(getUploadedLeadFiles(id));
  }, [dispatch, id]);

  const Campaigns = useSelector((state) => state.Campaigns);

  const { campaign } = Campaigns;

  const [document, setDocument] = useState("");

  const [fileData, setFileData] = useState([]);
  const [fileHeaders, setFileHeaders] = useState([]);

  // console.log(fileData);

  const [leadSources, setLeadSources] = useState("");

  const handleChange = (event) => {
    setLeadSources(event.target.value);
  };

  const handleFileChange = async (event) => {
    setDocument(event.target.files[0]);

    const data = await event.target.files[0].arrayBuffer();
    const workbook = read(data);

    const Headers = utils.sheet_to_json(
      workbook.Sheets[workbook.SheetNames[0]],
      { raw: false, defval: "", header: 1 }
    );

    const Data = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
      raw: false,
      defval: "",
    });
    setFileHeaders(Headers[0]);
    setFileData(Data);
  };

  const Leads = useSelector((state) => state.Leads);

  const { activeLeadSources, leadUploaded } = Leads;

  function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
      return { [newKeys]: obj[key] };
    });
    return keyValues;
  }
  const obj = { AAAAAAA: "Data" };
  const newKeys = "newKey";

  const renamedObj = renameKeys(obj, newKeys);
  console.log(renamedObj);

  const handelSave = () => {
    // rename keys in fileData

    console.log(ListBoy[1]);

    const renamedFileData = fileData.map((item, index) => {
      const keyValues = Object.keys(item).map((key) => {
        return { [ListBoy[index]]: obj[key] };
      });
      return keyValues;
    });

    console.log(renamedFileData);
  };

  // display keys of array object

  return (
    <div>
      <PageHeader
        title="Campaign Lead Uploader"
        subheader="Upload Leads to campaign"
      />
      <Card sx={{ mb: 2 }}>
        <CardHeader title={campaign?.name} />

        <>
          <Button onClick={() => navigate("/CDA/callCenter/campaigns")}>
            Back
          </Button>
        </>
      </Card>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          Select Leads Source
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={leadSources}
          label="Select Leads Source"
          onChange={handleChange}>
          {activeLeadSources.map((lead) => (
            <MenuItem key={lead.id} value={lead.id}>
              {lead.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ my: 3 }}
        spacing={2}>
        {document?.name ? (
          <Typography color="secondary" variant="h6">
            {document?.name}
          </Typography>
        ) : (
          <>
            <Typography variant="span">Click To upload file</Typography>
          </>
        )}
        {!document ? (
          <label htmlFor="contained-button-file">
            <Input
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              id="contained-button-file"
              type="file"
              autoFocus
              onChange={handleFileChange}
            />
            <Button
              variant="contained"
              size="large"
              startIcon={<FileOpenIcon />}
              component="span">
              Upload
            </Button>
          </label>
        ) : (
          <Button color="warning" onClick={() => setDocument("")}>
            {" "}
            Clear?
          </Button>
        )}
      </Stack>

      <Button color="success" variant="contained" onClick={handelSave}>
        SAve
      </Button>

      <Stack sx={{ mt: 3 }}>
        {fileHeaders && (
          <DndProvider backend={HTML5Backend}>
            <UploadedData
              setFileHeaders={setFileHeaders}
              fileData={fileData}
              fileHeaders={fileHeaders}
            />
          </DndProvider>
        )}
      </Stack>

      <AlertPopup
        open={leadUploaded}
        severity="success"
        message="Lead Uploaded"
      />
    </div>
  );
};

export default withAuth(LeadFileUploader);

const Input = styled("input")({
  display: "none",
});

const UploadedData = ({ fileHeaders, fileData, setFileHeaders }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650, height: " 50vh" }} aria-label="simple table2">
        <TableHead>
          <TableRow>
            {ListBoy.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <TableCell
                    sx={{
                      width: "100px",
                      maxWidth: "100px",
                      border: 1,
                      borderColor: "secondary.main",
                      m: 0,
                      p: 0,
                    }}>
                    <DraggableColumn index={index} data={item}>
                      {item}
                    </DraggableColumn>
                  </TableCell>
                </React.Fragment>
              );
            })}
          </TableRow>
          <TableRow>
            {fileHeaders.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <TableCell
                    sx={{
                      width: "100px",
                      maxWidth: "100px",
                      border: 1,
                      borderColor: "primary.main",
                      m: 0,
                      p: 0,
                    }}>
                    <DroppableCell
                      index={index}
                      setFileHeaders={setFileHeaders}
                      fileHeaders={fileHeaders}>
                      <Card
                        sx={{
                          p: 2,
                          "&:hover": {
                            backgroundColor: "action.hover",
                            cursor: "pointer",
                          },
                        }}>
                        {item}
                      </Card>
                    </DroppableCell>
                  </TableCell>
                </React.Fragment>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {fileData.slice(0, 5).map((row, index) => {
            return (
              <TableRow key={index}>
                {fileHeaders.map((item, index) => {
                  return (
                    <TableCell
                      sx={{
                        width: "100px",
                        maxWidth: "100px",
                      }}
                      key={index}>
                      {row[item]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DraggableColumn = ({ data, index, children }) => {
  const [drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { data, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Card
      ref={drag}
      sx={{
        p: 2,
        "&:hover": {
          backgroundColor: "action.hover",
          cursor: "pointer",
        },
      }}>
      {children}
    </Card>
  );
};

function DroppableCell({ children, setFileHeaders, fileHeaders, index }) {
  const [drop] = useDrop(() => {
    return {
      accept: ItemTypes.CARD,
      drop: (item) => {
        // console.log(item);
        // console.log("index", index, fileHeaders[index]);
      },
    };
  });

  return <div ref={drop}>{children}</div>;
}

const ListBoy = [
  "source",
  "external_ref",
  "old_system_id",
  "system_id",
  "campaign_id",
  "title",
  "initials",
  "first_name",
  "surname",
  "id_no",
  "dob",
  "gender",
  "marital_status",
  "mobile_no",
  "home_no",
  "work_no",
  "email",
  "addr1",
  "addr2",
  "addr3",
  "addr4",
  "addr5",
  "postal_code",
  "addr1_res",
  "addr2_res",
  "addr3_res",
  "addr4_res",
  "addr5_res",
  "postal_code_res",
  "acc_holder_name",
  "acc_no",
  "acc_type",
  "branch_code",
  "bank",
  "full_note",
  "note1",
  "note1_heading",
  "note2",
  "note2_heading",
  "note3",
  "note3_heading",
  "note4",
  "note4_heading",
  "note5",
  "note5_heading",
  "note6",
  "note6_heading",
  "note7",
  "note7_heading",
  "note8",
  "note8_heading",
  "note9",
  "note9_heading",
  "note10",
  "note10_heading",
  "note11",
  "note11_heading",
  "note12",
  "note12_heading",
  "note13",
  "note13_heading",
  "note14",
  "note14_heading",
  "note15",
  "note15_heading",
  "check_date",
  "upload_date",
  "rejection_date",
  "rejection_reason",
  "removed_date",
  "platform",
  "createdAt",
  "updatedAt",
];

const ItemTypes = {
  CARD: "card",
};
