import { Grid } from "@mui/material";
import React from "react";
import BarPlotChart from "../../../../../Components/Charts/BarPlotChart";

const ExcoMain = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4} md={6}>
        <BarPlotChart />
      </Grid>
      <Grid item xs={4} md={6}>
        <BarPlotChart />
      </Grid>
      <Grid item xs={4} md={6}>
        <BarPlotChart />
      </Grid>
    </Grid>
  );
};

export default ExcoMain;
