import {
    Autocomplete,
    Button,
    Grid,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import { Form, Formik } from "formik";
  import React from "react";
  import SelectWrapper from "../../../Components/FormComponents/Select";
  import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
  import * as yup from "yup";
  import AlertPopup from "../../../Components/Bits/AlertPopup";
  import withAuth from "../../../Hoc/withAuth";
  import { branchCodes } from "../../../bankCodes";
  import SelectWrapperWithInfo from "../Components/SelectWrapperWithInfo";
  import { useNavigate } from 'react-router-dom'
  import { useMutation, useQueryClient } from "@tanstack/react-query";
  import axios from 'axios' 
  import { useState } from "react";

  const BankInfo = ({ employee_id, setValue, BankInfo, submitted, modules, handleTabChange, disabled }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [mutationError, setMutationError] = useState("");
    const [successMessage, setSuccessMessage] = useState("Basic Information saved successfully!");

    if (!employee_id) {
      navigate("/otp");
    }

    const switchTab = (newTabIndex) => {
      handleTabChange(newTabIndex);
    };

    const mutation = useMutation({
        mutationFn: (values) => {
          if(BankInfo){
            setSuccessMessage("Basic Information updated successfully!")
            return axios.put(
              `${process.env.REACT_APP_API_URL}/onboarding/employees/bankInfo/${values.employee_id}`,
              { ...values }
            );
          }else{
             return axios.post(
              `${process.env.REACT_APP_API_URL}/onboarding/employees/bankInfo/`,
              { ...values }
            );
          }
        },
        onSuccess: async () => {
          await queryClient.invalidateQueries(["employeeInfo"]);
        },
        onError: async (error) => {
          setMutationError(error.response.data.message);
        }
    });
    
    const onSaveBankInfoHandler = (values) => {
        mutation.mutate(values);
    }; 

    const accountOwner = [
      {
        value: "Own",
        label: "Own",
        hint: "The bank account is your own.",
      },
      {
        value: "Joint",
        label: "Joint",
        hint: "You share a joint bank account with someone.",
      },
      {
        value: "Third Party/Someone Else",
        label: "Third Party/Someone Else",
        hint: "You use someone else's bank account.",
      },
    ];

    const accountTypes = [
      { value: "Current/Cheque", label: "Current/Cheque" },
      { value: "Savings", label: "Savings" },
      { value: "Transmission", label: "Transmission" },
    ];
  
    const warningText =
      "It is your responsibility to ensure that the information you provide below is accurate and complete. If the information is incorrect and your salary is paid into the incorrect bank account, CDA takes no responsibility and will not pay you your salary again. Similarly, if the payment of your salary is delayed due to incorrect or incomplete information provided, CDA takes no responsibility.";
  
    return (
      <Box component="div">
        <Stack spacing={2} mb={2} mt={2}>
          <Typography variant="span">BANK INFORMATION</Typography>

          <Typography color={"error"} fontSize={15}>
            {warningText}
          </Typography>
        </Stack>
  
       {mutation.isLoading && (
        <AlertPopup open={true} severity={"info"} message={"Saving Basic Information..."} />
        )}
        
        {mutation.isSuccess && (
            <AlertPopup open={true} message={successMessage} />
        )}
        
        { mutationError && (
            <AlertPopup open={true} message={mutationError} severity={"error"} />
          ) }
        <Formik
          initialValues={{
            employee_id,
            bank_account_owner: BankInfo?.bank_account_owner || "",
            bank_account_owner_name: BankInfo?.bank_account_owner_name || "",
            account_type: BankInfo?.account_type || "",
            bank_name: BankInfo?.bank_name || "Capitec Bank",
            branch_name: BankInfo?.branch_name || "Universal",
            branch_code: BankInfo?.branch_code ? BankInfo?.branch_code : "470010",
            account_number: BankInfo?.account_number || "",
          }}
          validationSchema={yup.object().shape({
            bank_account_owner: yup
              .string()
              .required("Please choose account owner"),
            bank_account_owner_name: yup.string().required("Owner name required"),
            account_type: yup.string().required("Please choose account type"),
            bank_name: yup.string().required("Bank name required"),
            branch_name: yup.string().required("Branch name required"),
            branch_code: yup
              .string()
              .min(6, "Branch code should be atleast 6 digits")
              .max(11, "Branch code cannot have more than 11 digits")
              .matches(/^\d+$/, { message: "Branch code should be only numbers" })
              .required("Branch code is required"),
            account_number: yup
              .string()
              .min(10, "Account number should be atleast 10 digits")
              .max(16, "Account number cannot have more than 16 digits")
              .matches(/^\d+$/, {
                message: "Account number should be only numbers",
              })
              .required("Account number is required"),
          })}
          onSubmit={(values) => {
            onSaveBankInfoHandler(values)
          }}
          enableReinitialize={true}
        >
          {({ values, errors, setFieldValue, handleSubmit }) => {
            return (
              <Form
                onSubmit={(jsEvent) => {
                  const { name, value } = jsEvent.nativeEvent.submitter;
                  setFieldValue(name, value);
  
                  return handleSubmit(jsEvent);
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <SelectWrapperWithInfo
                      name="bank_account_owner"
                      label="Bank Account Owner"
                      options={accountOwner}
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name={"bank_account_owner_name"}
                      placeholder={"eg. Mr OW Owen"}
                      label="Bank Owner Name"
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectWrapper
                      name="account_type"
                      label="Account Type"
                      options={accountTypes}
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>
  
                  <Grid item xs={12} md={6}>
                  <Autocomplete
                    disabled={disabled}
                    value={
                      values.bank_name
                        ? branchCodes.find((option) => option.name === values.bank_name) || null
                        : null
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setFieldValue("bank_name", newValue.name || "");
                        setFieldValue("branch_name", "");
                      } else {
                        setFieldValue("bank_name", "");
                        setFieldValue("branch_name", "");
                      }
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={branchCodes}
                    getOptionLabel={(option) => {
                      return option.name;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Bank Name" />}
                  />
                  </Grid>
  
                  <Grid item xs={12} md={6}>
                  <Autocomplete
                    disabled={disabled}
                    value={
                      branchCodes?.find((option) => option.name === values.bank_name)?.branches?.find(
                        (opt) => opt.branch === values.branch_name,
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setFieldValue("branch_name", newValue.branch || ""); // Ensure 'branch_name' is not undefined or null
                        setFieldValue("branch_code", newValue.code || ""); // Ensure 'branch_code' is not undefined or null
                      }
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={
                      branchCodes.find((option) => option.name === values.bank_name)?.branches || [] // Ensure 'options' is an array
                    }
                    getOptionLabel={(option) => {
                      return option?.branch || ""; // Ensure 'option' is not undefined or null
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option?.branch || ""}</li> // Ensure 'option' is not undefined or null
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="Branch Name" />
                    )}
                  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name={"branch_code"}
                      label={"Branch Code"}
                      type="text"
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name={"account_number"}
                      placeholder={"Account Number"}
                      label="Account Number"
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack direction={"row"} justifyContent="center" spacing={2}>
                      <Button
                        variant="contained"
                        sx={{
                          width: 170,
                        }}
                        onClick={() => switchTab(2)}
                        color="primary"
                      >
                        Back
                      </Button>
  
                      {BankInfo ? (
                        <>
                          <Button
                            variant="contained"
                            type="submit"
                            name="submitAction"
                            value="0"
                            disabled={disabled}
                            color="primary"
                            sx={{
                              width: 170,
                            }}
                          >
                            Update
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            type="submit"
                            name="submitAction"
                            value="0"
                            disabled={
                              submitted &&
                              !modules?.includes("oe-hr")
                            }
                            sx={{
                              width: 170,
                            }}
                            color="primary"
                          >
                            Save
                          </Button>
                        </>
                      )}
                      <Button
                        variant="contained"
                        type="submit"
                        name="submitAction"
                        onClick={() => switchTab(4)}
                        sx={{
                          width: 170,
                        }}
                        color="primary"
                      >
                        Next
                      </Button> 
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    );
  };
  
  export default withAuth(BankInfo);
  