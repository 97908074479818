import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { DialogContent, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { removeUserFromTeam } from "../../State/TeamsDuck";

export default function RemoveUserFromTeam({ user, teamId }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="error" onClick={handleClickOpen}>
        Remove User From Team
      </Button>
      <Dialog
        sx={{ border: "3px solid #F44336 " }}
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}>
        <DialogTitle> Remove User From Team</DialogTitle>

        <Formik
          initialValues={{
            teamId: teamId,
            userId: user?.user_id,
          }}
          onSubmit={(values) => {
            dispatch(removeUserFromTeam(values));

            handleClose();
          }}>
          {() => (
            <Form>
              <DialogContent>
                <Typography variant="body1">
                  Are you sure you want to remove{" "}
                  <span
                    style={{
                      color: "#F44336",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}>
                    {user?.name}
                  </span>{" "}
                  from this team?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button color="error" variant="contained" type="submit">
                  remove
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
