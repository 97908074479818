import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";

import { useNavigate } from "react-router-dom";
import { editDispositionList } from "../../State/DispostionsDuck";

const DispositionListTable = ({ disposition_list }) => {
  const Dispositions = useSelector((state) => state.Dispositions);

  const navigate = useNavigate();

  const { listAdded } = Dispositions;

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderWidth: 1,
          borderColor: "warning.main",
        }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>

              <StyledTableCell align="right">Edit Dispositions</StyledTableCell>
              <StyledTableCell align="right">Remove/Activate</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {disposition_list?.map((row) => {
              return (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate(`/CDA/callCenter/dispositions/${row.id}`);
                      }}>
                      Edit List
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <DeleteDispositionList data={row} />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertPopup open={listAdded} message="Disposition List Added" />
    </>
  );
};

export default DispositionListTable;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DeleteDispositionList = ({ data }) => {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(editDispositionList(data.id, { ...data, active: !data.active }));
  };

  return (
    <div>
      <Button
        color={!data.active ? "success" : "error"}
        onClick={handleClickOpen}>
        {!data.active ? "Activate" : "Disable"}
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          {!data.active ? "Activate" : "Disable"} this Disposition List? <br />
        </DialogTitle>
        <DialogTitle>{data?.name}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color={!data.active ? "success" : "error"}
            onClick={handleSubmit}>
            {!data.active ? "Activate" : "Disable"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
