import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import {
  Button,
  Stack,
  LinearProgress,
  Typography,
  Alert,
} from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

import EditNoteIcon from "@mui/icons-material/EditNote";
import withAuth from "../../../Hoc/withAuth";
import AlertPopup from "../../../Components/Bits/AlertPopup";

import AddEmployeeModal from "../modals/AddEmployeeModal";

const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
};

const OEHRRequisition = () => {
  const navigate = useNavigate();

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;
  const queryClient = useQueryClient();

  const [mutationError, setMutationError] = useState("");

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const mutation = useMutation({
    mutationFn: (values) => {
      if (values.type === "delete") {
        // delete
        return axios.put(
          `${process.env.REACT_APP_API_URL}/onboarding/requisitions/${values.id}`,
          { deleted: true },
          config
        );
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["getHRRequisitions"]);
    },
    onError: async (error) => {
      setMutationError(error.response.data.message);
    },
  });

  const onDeleteRequisitionHandler = (id, reqType = "delete") => {
    mutation.mutate({ id, type: reqType });
  };

  const columns = [
    {
      field: "id",
      headerName: "View/Edit",
      width: 220,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            color="info"
            onClick={() =>
              navigate(
                `/onboarding/hr/AddRequisition?requisitionId=${params.row.id}`
              )
            }>
            View/Edit
          </Button>
          {params.row.number_of_employees_added > 0 && (
            <Button
              variant="outlined"
              color="info"
              onClick={() =>
                navigate(
                  `/onboarding/hr/SMSList?requisitionId=${params.row.id}`
                )
              }>
              SMS List
            </Button>
          )}
        </Stack>
      ),
    },
    {
      field: "position",
      headerName: "Requisition Job Title",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Manager",
      headerAlign: "center",
      align: "center",
      width: 130,
    },
    {
      field: "number_of_employees",
      headerName: "Number of Employees",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "number_of_employees_added",
      headerName: "Employees Added",
      headerAlign: "center",
      align: "center",
      width: 130,
    },
    {
      field: "approve",
      headerName: "Status",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const approve = params.value;

        if (approve === true) {
          return <Typography color="success.main">Approved</Typography>;
        } else if (approve === false) {
          return <Typography color="error.main">Rejected</Typography>;
        } else {
          return <Typography color="warning.main">Waiting Approval</Typography>;
        }
      },
    },
    {
      field: "reject_reason",
      headerName: "Reject Reason",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const reject_reason = params.row.requisition.reject_reason;
        if (reject_reason) {
          return reject_reason;
        } else {
          return "N/A";
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 230,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const id = params.row.id;
        const approve = params.row.approve;
        const number_of_employees_added = params.row.number_of_employees_added;

        return (
          <Stack direction="row" spacing={1}>
            {number_of_employees_added === 0 && (
              <Button
                color="error"
                variant="outlined"
                onClick={() => onDeleteRequisitionHandler(id)}>
                Delete
              </Button>
            )}
            {approve && (
              <AddEmployeeModal
                text={"Add"}
                color="secondary"
                requisitionId={id}
              />
            )}
          </Stack>
        );
      },
    },
  ];

  // get requisitions
  const { isLoading, isError, data, error } = useQuery(
    ["getHRRequisitions"],
    async () =>
      await fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/requisitions`,
        config
      )
  );

  if (isLoading) return <LinearProgress />;

  if (isError) return <Alert severity="error">{error.message}</Alert>;

  return (
    <>
      <Stack spacing={3} sx={{ mb: 3 }}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<EditNoteIcon />}
          onClick={() => navigate("/onboarding/hr/AddRequisition")}>
          Add Requisition
        </Button>

        {mutation.isLoading && (
          <AlertPopup
            open={true}
            message={"Updating Requisition..."}
            severity={"info"}
          />
        )}

        {mutation.isSuccess && (
          <AlertPopup
            open={true}
            message={"Requisition(s) updated successfully!"}
          />
        )}

        {mutationError && (
          <AlertPopup open={true} message={mutationError} severity={"error"} />
        )}

        <div style={{ height: "70vh", width: "100%", mt: 3 }}>
          <DataGridPremium
            rows={data?.requisitions?.map((requisition) => ({
              id: requisition?.id,
              position: requisition?.Position?.job_title,
              name: `${requisition?.User?.name} ${requisition?.User?.surname}`,
              number_of_employees: requisition?.number_of_employees,
              number_of_employees_added: requisition?.number_of_employees_added,
              approve: requisition?.approved,
              requisition: requisition,
            }))}
            columns={columns}
            density="comfortable"
            slots={{ toolbar: GridToolbar }}
          />
        </div>
      </Stack>
    </>
  );
};

export default withAuth(OEHRRequisition);
