import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Stack,
  Skeleton,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";

import SelectWrapper from "../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import CustomDatePicker from "../Components/CustomDatePicker";
import AddPositionModal from "../modals/AddPositionModal";
import AlertPopup from "../../../Components/Bits/AlertPopup";

import axios from "axios";
import withAuth from "../../../Hoc/withAuth";

import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
};

const OEMAddRequisition = ({ modules }) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [mutationError, setMutationError] = useState("");

  const requisitionId = search.split("=")[1] || "";

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const { data: managersList } = useQuery(
    ["getManagers"],
    async () =>
      await fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/managers`,
        config
      )
  );

  const { data: requisition } = useQuery(
    ["getRequisitionById"],
    async () =>
      await fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/requisitions/${requisitionId}`,
        config
      )
  );

  const positionList = useQuery(
    ["getPositions"],
    async () =>
      await fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/positions`,
        config
      )
  );

  const { data: departmentsList } = useQuery(
    ["getDepartments"],
    async () =>
      await fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/departments`,
        config
      )
  );

  const mutation = useMutation({
    mutationFn: (values) => {
      if (values.requisitionId) {
        // update
        return axios.put(
          `${process.env.REACT_APP_API_URL}/onboarding/requisitions/${values.requisitionId}`,
          { ...values },
          config
        );
      } else {
        // insert
        return axios.post(
          `${process.env.REACT_APP_API_URL}/onboarding/requisitions/`,
          { ...values },
          config
        );
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("getManagerRequisitions");
      setTimeout(() => {
        navigate("/onboarding/manager/requisition");
      }, 2000);
    },
    onError: async (error) => {
      setMutationError(error.response.data.message);
    },
  });

  const onAddRequisitionHandler = (values) => {
    mutation.mutate(values);
  };

  const onEditRequisitionHandler = (values) => {
    mutation.mutate(values);
  };

  const salaryTypes = [
    {
      label: "TFCE",
      value: "TFCE",
    },
    {
      label: "TFCE + Commission",
      value: "TFCE + Commission",
    },
    {
      label: "Hourly Basic + Commission",
      value: "Hourly Basic + Commission",
    },
    {
      label: "Commission",
      value: "Commission",
    },
  ];

  const natureOfEmployments = [
    {
      value: "Permanent",
      label: "Permanent",
    },
    {
      value: "LDC",
      label: "LDC",
    },
    {
      value: "Temporary",
      label: "Temporary",
    },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const targetStatuses = ["Any", "African", "Indian", "Coloured", "White"];

  const targetGenders = [
    {
      value: "Any",
      label: "Any",
    },
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];

  const targetDisables = [
    {
      value: "Any",
      label: "Any",
    },
    {
      value: "Disabled",
      label: "Disabled",
    },
  ];

  const targetLanguages = [
    "Any",
    "Xhosa",
    "Zulu",
    "English",
    "Afrikaans",
    "N Sotho",
    "S Sotho",
    "Tsonga",
    "Swati",
    "Ndebele",
    "Tswana",
    "Venda",
  ];

  return (
    <Stack spacing={2}>
      {requisition?.approved === false && (
        <Typography color="error">
          Rejected : {requisition?.reject_reason}
        </Typography>
      )}

      {mutation.isLoading && (
        <AlertPopup
          open={true}
          message={"Adding Requisition..."}
          severity={"info"}
        />
      )}
      {mutation.isError && (
        <AlertPopup
          open={true}
          message={
            "There was an error while adding the requisition. Please try again later."
          }
        />
      )}

      {!requisitionId && mutation.isSuccess && (
        <AlertPopup open={true} message={"Requisition sent for approval!"} />
      )}

      {requisitionId && mutation.isSuccess && (
        <AlertPopup open={true} message={"Requisition updated!"} />
      )}

      {mutationError && (
        <AlertPopup open={true} message={mutationError} severity={"error"} />
      )}

      <Grid container>
        <Grid item xs={12} md={12} mb={2}>
          <Button
            variant="outlined"
            color="info"
            fullWidth
            onClick={() => navigate(`/onboarding/manager/requisition`)}>
            View Requisitions
          </Button>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          requisitionId: requisitionId || "",
          department_id: requisition?.data?.department_id || "",
          manager_node_id: requisition?.data?.manager_node_id || "",
          position_id: requisition?.data?.position_id || "",
          number_of_employees: requisition?.data?.number_of_employees || "",
          nature_of_employment: requisition?.data?.nature_of_employment || "",
          salary_type: requisition?.data?.salary_type || "",
          salary_min: requisition?.data?.salary_min || "",
          salary_max: requisition?.data?.salary_max || "",
          duration: requisition?.data?.duration || 0,
          start_date: requisition?.data?.start_date || "01/07/2017" || "",
          target_ee_status: requisition?.data?.target_ee_status || [],
          target_gender: requisition?.data?.target_gender || "",
          target_disability: requisition?.data?.target_disability || "",
          target_language: requisition?.data?.target_language || [],
          reason_for_employment: requisition?.data?.reason_for_employment || "",
          approved: requisition?.data?.approved || null,
        }}
        validationSchema={yup.object().shape({
          department_id: yup.string().required("Please select department"),
          manager_node_id: yup.string().required("Please select manager"),
          position_id: yup.string().required("Please select position"),
          number_of_employees: yup
            .number()
            .required("Please enter number of employees"),
          nature_of_employment: yup
            .string()
            .required("Please select nature of employment"),
          salary_type: yup.string().required("Please select salary type"),
          salary_min: yup.string().required("Please enter salary min"),
          salary_max: yup.string().required("Please enter salary max"),
          duration: yup.string().when("nature_of_employment", {
            is: "LDC",
            then: yup
              .string()
              .required("Please enter emploment duration in months"),
          }),
          start_date: yup.string().required("Please select start date"),
          target_ee_status: yup
            .array()
            .required("Please select target ee status"),
          target_gender: yup.string().required("Please select target gender"),
          target_disability: yup
            .string()
            .required("Please select target disability"),
          target_language: yup
            .array()
            .required("Please select target languages"),
          reason_for_employment: yup
            .string()
            .required("Please enter reason of employment"),
        })}
        onSubmit={(values) => {
          if (requisitionId) {
            onEditRequisitionHandler(values);
          } else {
            onAddRequisitionHandler(values);
          }
        }}
        enableReinitialize={true}>
        {({ values, errors, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="department_id"
                    label="Department"
                    options={
                      departmentsList
                        ? departmentsList?.departments
                            .filter((department) => department.is_active) // Filter departments where is_active is true
                            .map((department) => ({
                              label: department.department_name,
                              value: department.id,
                            }))
                        : []
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="manager_node_id"
                    label="Manager"
                    options={
                      managersList
                        ? managersList?.managers?.map((manager) => ({
                            label: manager.name + " " + manager.surname,
                            value: manager.id,
                          }))
                        : []
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {positionList.isLoading ? (
                    <>
                      <Skeleton variant="text" width="100%" height={40} />
                    </>
                  ) : (
                    <SelectWrapper
                      name="position_id"
                      label="Position"
                      options={positionList?.data?.positions
                        .filter((position) => position.approved)
                        .map((position) => ({
                          value: position?.id,
                          label: position?.job_title,
                        }))}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: values.position_id === "Other" ? "block" : "none",
                  }}>
                  <AddPositionModal modules={modules} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name="number_of_employees"
                    label="Number Of Employees"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="nature_of_employment"
                    label="Nature Of Employment"
                    options={natureOfEmployments}
                  />
                </Grid>
                {values.nature_of_employment === "LDC" && (
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name="duration"
                      label="Duration in months"
                      type="number"
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="salary_type"
                    label="Salary Type"
                    options={salaryTypes}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper name="salary_min" label="Salary Min" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper name="salary_max" label="Salary Max" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomDatePicker
                    name={"start_date"}
                    label={"Start Date"}
                    inputFormat="dd/MM/yyyy"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Target EE Status
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={values.target_ee_status}
                      onChange={(e) => {
                        setFieldValue("target_ee_status", e.target.value);
                      }}
                      input={<OutlinedInput label="Target EE Status" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}>
                      {targetStatuses.map((target) => (
                        <MenuItem key={target} value={target}>
                          <Checkbox
                            checked={
                              values.target_ee_status.indexOf(target) > -1
                            }
                          />
                          <ListItemText primary={target} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="target_gender"
                    label="Target Gender"
                    options={targetGenders}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="target_disability"
                    label="Target Disability"
                    options={targetDisables}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Target Language(s)
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={values.target_language}
                      onChange={(e) => {
                        setFieldValue("target_language", e.target.value);
                      }}
                      input={<OutlinedInput label="Target Language(s)" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}>
                      {targetLanguages.map((language) => (
                        <MenuItem key={language} value={language}>
                          <Checkbox
                            checked={
                              values.target_language.indexOf(language) > -1
                            }
                          />
                          <ListItemText primary={language} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name="reason_for_employment"
                    label="Reason For Employment"
                  />
                </Grid>
                <Grid item xs={12} md={12} mb={2} mt={2}>
                  {!values.approved && (
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      type="submit">
                      Save
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default withAuth(OEMAddRequisition);
