import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import React from "react";
import ContentItem from "./ContentItem";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack } from "@mui/system";

const EvaluationHistory = ({ progress }) => {
  return (
    <Accordion sx={{ mt: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Stack direction="row" justifyContent="space-between">
          <Typography>View Evaluation Work History</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {progress?.map((pro, index) => {
            return (
              <Grid item key={index}>
                <Card>
                  <List
                    dense
                    subheader={
                      <ListSubheader>Evaluation History</ListSubheader>
                    }>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary">
                              Qa Agent
                            </Typography>
                          </>
                        }
                        secondary={
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body1"
                              color="text.primary">
                              {pro?.User?.name} {pro?.User?.surname}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    <Divider />
                    <ContentItem
                      title="Start Time"
                      value={dayjs(pro?.evaluation_start).format("llll")}
                    />
                    <ContentItem
                      title="End Time"
                      value={dayjs(pro?.evaluation_end).format("llll")}
                    />
                    <ContentItem
                      title="Total Time"
                      value={
                        dayjs(
                          dayjs(pro?.evaluation_end).diff(
                            dayjs(pro?.evaluation_start)
                          )
                        ).get("second") + "seconds"
                      }
                    />
                  </List>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default EvaluationHistory;
