import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainAppContainer from "../../../Components/Containers/MainAppContainer";
import { getAllCampaigns } from "../StateManager/Campaigns/CRMCampaignDuck";
import CampaignsList from "./CampaignsList";

const CRMCampaigns = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCampaigns());
  }, [dispatch]);

  const CRMCampaignDuck = useSelector((state) => state.CRMCampaignDuck);
  const { campaigns } = CRMCampaignDuck;

  return (
    <MainAppContainer>
      <Typography variant="h4" align="center">
        Campaigns
      </Typography>

      {campaigns && <CampaignsList campaigns={campaigns} />}
    </MainAppContainer>
  );
};

export default CRMCampaigns;
