import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { Stack, Typography } from "@mui/material";

export default function SurveyRadioQuestion({
  question,
  QuestionAnswers,
  setQuestionAnswers,
  questionNumber,
  questionDependencies,
}) {
  const [value, setValue] = React.useState({
    question_id: question.id,
    answer_id: "",
    notes: "",
  });

  const handleRadioChange = (event) => {
    setQuestionAnswers(
      QuestionAnswers?.map((question) => {
        if (question.question_id === value.question_id) {
          return {
            ...question,
            answer_id: event.target.value,
            notes: value.notes,
          };
        } else {
          return question;
        }
      }),
    );
  };

  const handleNotesChange = (event) => {
    setValue({
      ...value,
      notes: event.target.value,
    });

    setQuestionAnswers(
      QuestionAnswers?.map((question) => {
        if (question.question_id === value.question_id) {
          return {
            ...question,
            notes: event.target.value,
          };
        } else {
          return question;
        }
      }),
    );
  };

  return (
    <Stack my={2}>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          <Typography color="primary" variant="h6">
            {questionNumber} : {question?.question}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          onChange={handleRadioChange}
          name={question?.question}
        >
          {question?.survey_answers.map((answer) => {
            return (
              <FormControlLabel
                key={answer.id}
                value={answer?.id}
                control={<Radio />}
                label={answer?.answer}
              />
            );
          })}
        </RadioGroup>
      </FormControl>

      <TextField
        margin="normal"
        id="notes"
        label="Notes"
        multiline
        rows={2}
        name="notes"
        value={value.notes}
        onChange={handleNotesChange}
      />
    </Stack>
  );
}
