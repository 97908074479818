import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//REDUX
// import { getCampaignPlans } from "./State/CampaignPlansDuck";
import { getClientsList } from "./State/ClientsDuck";

//COMPONENTS
import withAuth from "../../../../../Hoc/withAuth";
import ClientsPaginatedTable from "./Components/Plans/ClientsPaginatedTable";

const CampaignPlans = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { Clients } = useSelector(state => state)
    const { clientsList } = Clients

    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [page, setPage] = React.useState(0);

    const link = (client_id) => {
        navigate(`/CDA/callCenter/Plans/${client_id}`)
    }

    useEffect(() => {
        dispatch(getClientsList())
    }, [dispatch])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    return clientsList ? (
            <div>
                <ClientsPaginatedTable
                     columns={[
                        { id: 1, label: "Id" },
                        { id: "2", label: "Client"},
                        { id: "3", label: "Plans"},
                        { id: "4", label: "Active"}
                     ]}
                     link={link}
                     rows={clientsList}
                     rowsPerPage={rowsPerPage}
                     page={page}
                     handleChangePage={handleChangePage}
                     handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        ) 
    : (<div><h1>Nothing</h1></div>)
}
export default withAuth(CampaignPlans)