import React from "react";
import withAuth from "../../../../../../Hoc/withAuth";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import InboundReEvaluation from "./InboundReEvaluation";
import InboundDisputeEvaluation from "./InboundDisputeEvaluation";
import AgentInboundResults from "./AgentInboundResults";
import axios from "axios";
import InboundSetToDone from "./InboundSetToDone";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";

const AgentInboundEvaluationBYID = ({ user }) => {
  const { evalID } = useParams();

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getEvaluationByID = useQuery({
    queryKey: ["getEvaluationByID", evalID],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/user/${evalID}`,
        config
      ),
  });

  const evaluation = getEvaluationByID?.data?.data?.data || [];

  const updateEvaluation = useMutation({
    mutationFn: (data) =>
      axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Evaluations/${evalID}`,
        data,
        config
      ),
    onSuccess: () => {
      getEvaluationByID.refetch();
    },
  });

  return (
    <>
      <Stack direction="row" sx={{ justifyContent: "space-between", mb: 2 }}>
        <Stack direction="row">
          <InboundReEvaluation
            user={user}
            updateEvaluation={updateEvaluation}
            evaluation={evaluation}
          />
          <InboundDisputeEvaluation
            user={user}
            updateEvaluation={updateEvaluation}
            evaluation={evaluation}
          />
        </Stack>
        <Stack>
          <InboundSetToDone
            user={user}
            updateEvaluation={updateEvaluation}
            evaluation={evaluation}
          />
        </Stack>
      </Stack>
      <AlertPopup
        open={updateEvaluation.isSuccess}
        severity="success"
        message="Success"
      />

      <AlertPopup
        open={updateEvaluation.isError}
        severity="error"
        message="Failed"
      />

      <AgentInboundResults />
    </>
  );
};

export default withAuth(AgentInboundEvaluationBYID);
