import { Button, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { EditSurveyAnswer } from "../../State/SurveysDuck";

export default function EditAnswer({ answer }) {
  const [open, setOpen] = React.useState(false);

  const [values, setValues] = React.useState({
    answer: "",
    description: "",
    value: "",
  });

  useEffect(() => {
    setValues({
      answer: answer?.answer || "",
      description: answer?.description || "",
      value: answer?.value || "",
    });
  }, [answer]);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: values,
    enableReinitialize: true,
    validationSchema: Yup.object({
      answer: Yup.string().required("A Answer is required"),
      description: Yup.string().required("A Description is required"),
      value: Yup.string().required("A Value is required"),
    }),

    onSubmit: async (values) => {
      dispatch(EditSurveyAnswer(answer.id, values));
    },
  });

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
    <div>
      <Tooltip title={"Edit Answer"}>
        <IconButton
          size="small"
          onClick={handleClickOpen}
          color="warning"
          edge="end">
          <EditTwoToneIcon />
        </IconButton>
      </Tooltip>

      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Edit Answer</DialogTitle>

        <form onSubmit={formik.handleSubmit}>
          <Stack sx={{ p: 2 }}>
            <TextField
              margin="normal"
              label="Answer"
              name="answer"
              value={formik.values.answer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.answer && formik.errors.answer ? true : false
              }
              helperText={formik.touched.answer && formik.errors.answer}
            />
            <TextField
              margin="normal"
              label="Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && formik.errors.description
                  ? true
                  : false
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            <TextField
              margin="normal"
              label="Value"
              name="value"
              value={formik.values.value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.value && formik.errors.value ? true : false}
              helperText={formik.touched.value && formik.errors.value}
            />
          </Stack>
          <Stack>
            <Button sx={{ mt: 4 }} type="submit" variant="outlined">
              Save
            </Button>
            <Button color="inherit" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </form>
      </Dialog>
    </div>
  );
}
