import { Stack, Typography, LinearProgress, Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import withAuth from "../../../Hoc/withAuth";
import EditPositionModal from "../modals/EditPositionModal";

import axios from 'axios'

const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
}

const OEHRPositions = ({ user, modules }) => {

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const columns = [
    {
      field: "job_title",
      headerAlign: "center",
      align: "center",
      headerName: "Job Title",
      width: 300,
    },
    {
      field: "rem_channel_grade",
      headerAlign: "center",
      align: "center",
      headerName: "Rem Channel Grade",
      width: 250,
    },
    {
      field: "approved",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      width: 300,
      renderCell: (params) => {
        const approve = params.value;

        if (approve === true) {
          return <Typography color="success.main">Approved</Typography>;
        } else if (approve === false) {
          return <Typography color="error.main">Rejected</Typography>;
        } else {
          return <Typography color="warning.main">Waiting Approval</Typography>;
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (params) => (
        <EditPositionModal
          position={params.value}
          modules={modules}
          text="View"
        />
      ),
    },
  ];

  const { isLoading, isError, data, error } = useQuery(
    ["getPositions"],
    async () =>
      await fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/positions/`,
        config
      )
  );
  

  if (isLoading) return <LinearProgress />

  if (isError) return <Alert severity="error">{error.message}</Alert>

  return (
    <>
      <Stack spacing={3} sx={{ mb: 3 }}>
        <div style={{ height: "70vh", width: "100%", mt: 3 }}>
          <DataGridPremium
            rows={data.positions.map((position) => {
              return {
                id: position.id,
                job_title: position.job_title,
                rem_channel_grade: position.rem_channel_grade,
                approved: position.approved,
                actions: position,
              };
            })}
            columns={columns}
            density="comfortable"
            slots={{ toolbar: GridToolbar }}
          />
        </div>
      </Stack>
    </>
  );
};

export default withAuth(OEHRPositions);
