import { Typography, Button, Stack } from "@mui/material";
import React, { useEffect } from "react";
import MainAppContainer from "../../../../../Components/Containers/MainAppContainer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  getQuestionnaireCampaignResult,
  RESET_QUESTIONNAIRE_RESULT_DUCK,
} from "./State/QuestionnaireResultsDuck";
import EmojiEventsTwoToneIcon from "@mui/icons-material/EmojiEventsTwoTone";
import StarBorderPurple500TwoToneIcon from "@mui/icons-material/StarBorderPurple500TwoTone";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import { RESET_USERS_QUESTIONNAIRE_DUCK } from "./State/UsersQuestionnairesDuck";
import { RESET_QUESTIONNAIRE_DUCK } from "./State/QuestionnairsDuck";
import PageHeader from "../../../../../Components/Bits/PageHeader";

const QuestionnaireEndPage = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch({ type: RESET_QUESTIONNAIRE_RESULT_DUCK });
    dispatch({ type: RESET_USERS_QUESTIONNAIRE_DUCK });
    dispatch({ type: RESET_QUESTIONNAIRE_DUCK });

    localStorage.removeItem("currentQuestionnaire");
    dispatch(getQuestionnaireCampaignResult(id));
  }, [dispatch, id]);

  const QuestionnaireResultsDuck = useSelector(
    (state) => state.QuestionnaireResultsDuck
  );
  const { campaignResult, loading, error } = QuestionnaireResultsDuck;

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error...</div>;
  }
  if (!campaignResult) {
    return <div>No Result...</div>;
  }

  return (
    <MainAppContainer>
      <PageHeader
        title="Questionnaire Results"
        subheader="View your results"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Questionnaires",
            href: `/CDA/UserQuestionnaires`,
          },
          {
            title: "Questionnaire Results",
            href: `/CDA/QuestionnaireEndPage/${id}`,
          },
        ]}
      />

      {campaignResult.passed ? (
        <Success campaignResult={campaignResult} />
      ) : (
        <Failed campaignResult={campaignResult} />
      )}

      <Stack
        sx={{ mt: 4 }}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Button onClick={() => navigate("/CDA/UserQuestionnaires")}>
          Back To questionnaires
        </Button>
      </Stack>
    </MainAppContainer>
  );
};

export default QuestionnaireEndPage;

const Success = ({ campaignResult }) => {
  return (
    <MainAppContainer>
      <Typography variant="h4" align="center" sx={{ my: 4 }}>
        Congratulations!
      </Typography>
      <Stack
        sx={{ mt: 4 }}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <StarBorderPurple500TwoToneIcon
          color="warning"
          sx={{ mt: 1 }}
          style={{ fontSize: 50 }}
        />
        <StarBorderPurple500TwoToneIcon
          color="warning"
          style={{ fontSize: 50 }}
        />
        <StarBorderPurple500TwoToneIcon
          color="warning"
          sx={{ mt: 1 }}
          style={{ fontSize: 50 }}
        />
      </Stack>
      <Stack display="flex" justifyContent="center" alignItems="center">
        <EmojiEventsTwoToneIcon sx={{ fontSize: 200 }} />
      </Stack>

      <Typography variant="h4" align="center" sx={{ my: 4 }}>
        {campaignResult?.questionnaire_campaign?.name}
      </Typography>
      <Typography variant="h6" align="center">
        You have scored {campaignResult?.score}%
      </Typography>
      <Typography
        align="center"
        variant="h6"
        color={campaignResult?.passed ? "success" : "error"}>
        {campaignResult?.passed ? "You have passed." : "You have failed."}
      </Typography>
    </MainAppContainer>
  );
};
const Failed = ({ campaignResult }) => {
  return (
    <MainAppContainer>
      <Stack display="flex" justifyContent="center" alignItems="center">
        <ErrorTwoToneIcon sx={{ fontSize: 200 }} />
      </Stack>

      <Typography variant="h4" align="center" sx={{ my: 4 }}>
        {campaignResult?.questionnaire_campaign?.name}
      </Typography>
      <Typography variant="h6" align="center">
        You have scored {campaignResult?.score}%
      </Typography>
      <Typography
        align="center"
        variant="h6"
        color={campaignResult?.passed ? "success" : "error"}>
        {campaignResult?.passed ? "You have passed." : "You have failed."}
      </Typography>
    </MainAppContainer>
  );
};
