import * as React from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";

export default function DeActivateCampaign({ editInboundCampaign, campaign }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        color={campaign.active ? "error" : "primary"}>
        {campaign.active ? <DeleteIcon /> : "Activate Campaign"}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create QA Inbound Campaign</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              ...campaign,
              active: !campaign.active,
            }}
            onSubmit={(values) => {
              console.log(values);
              editInboundCampaign.mutate(values);
              handleClose();
            }}>
            {() => {
              return (
                <Form>
                  <Typography variant="h6" gutterBottom>
                    {campaign.name}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {campaign.active
                      ? "Deactivate Campaign"
                      : "Activate Campaign"}
                  </Typography>

                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}>
                      cancel
                    </Button>
                    <Button
                      color={campaign.active ? "error" : "primary"}
                      variant="contained"
                      type="submit">
                      {campaign.active ? "Deactivate" : "Activate"}
                    </Button>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
