import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { Stack } from "@mui/system";

const DeActivateQuestions = ({ deactivateQuestions, question }) => {
  const [open, setOpen] = React.useState(false);





  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {question.active ? (
        <Button variant="contained" color="error" onClick={handleClickOpen}>
          DeActivate
        </Button>
      ) : (
        <Button variant="contained" onClick={handleClickOpen} color="primary">
          Activate
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Formik
            initialValues={{
              ...question,
              active: !question.active,
            }}
            onSubmit={(values) => {
        
              deactivateQuestions.mutate(values);
              handleClose();
            }}
          >
            {() => (
              <Form>
                <Typography variant="h6" gutterBottom>
                  {question.name}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {question.active
                    ? "Are you sure you want to deactivate Question?"
                    : "Activate Question"}
                </Typography>

                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    color={question.active ? "error" : "primary"}
                    variant="contained"
                    type="submit"
                  >
                    {question.active ? "Deactivate" : "Activate"}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DeActivateQuestions;
