import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import { getQaQueuesList } from "../../../QAManagement/State/QaDuck";
import { Form, Formik } from "formik";
import { Typography } from "@mui/material";
import SelectWrapper from "../../../../../../../Components/FormComponents/Select";
import { addQaCampaignQueue } from "../../State/CampaignQueuesDuck";

export default function AddQaQueueToCampaign({ id, campaignQueuesList }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getQaQueuesList());
  }, [dispatch]);

  const Qa = useSelector((state) => state.Qa);
  const { qaQueuesList, qaQueuesListLoading } = Qa;

  if (qaQueuesListLoading) {
    return <p>...loading</p>;
  }

  const activeCampaignQueues =
    qaQueuesList &&
    qaQueuesList?.length > 0 &&
    qaQueuesList?.filter((queue) => queue.active === true);

  const filterUsedQueues =
    activeCampaignQueues &&
    activeCampaignQueues.length > 0 &&
    activeCampaignQueues?.filter((queues) => {
      return !campaignQueuesList?.find(
        (campaignDisposition) => campaignDisposition?.queue_id === queues?.id
      );
    });

  return (
    <div>
      <Button variant="contained" fullWidth onClick={handleClickOpen}>
        Add Qa Queue to Campaign
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Add Qa Queue to Campaign</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              queue_id: "",
              active: true,
            }}
            onSubmit={(values) => {
              dispatch(
                addQaCampaignQueue({
                  id: id,
                  campaignQueue: values,
                })
              );
            }}>
            {() => (
              <Form>
                <DialogContent>
                  {qaQueuesList && qaQueuesList.length > 0 ? (
                    <SelectWrapper
                      label="Qa Queue"
                      name="queue_id"
                      options={filterUsedQueues?.map((qaQueue) => {
                        return {
                          value: qaQueue.id,
                          label: qaQueue.queue_name,
                        };
                      })}
                    />
                  ) : (
                    <Typography>Give Qa Queue call-center module</Typography>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
