import { Box, Stack, Typography, Button, Grid } from '@mui/material'
import { useState } from 'react';

import withAuth from "../../../Hoc/withAuth"
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'
import AlertPopup from "../../../Components/Bits/AlertPopup";


const Finish = ({ employee_id, submitted, modules, disabled, completed }) => {
    const navigate = useNavigate()
    const [successMessage, setSuccessMessage] = useState('')

    if (!employee_id) {
        navigate("/otp");
    }
   
    const mutation = useMutation(() => {
        return axios.put(
            `${process.env.REACT_APP_API_URL}/onboarding/employees/submit/${employee_id}`
        );
    }, {});

    const onSubmitHandler = () => {
        mutation.mutate()
        localStorage.clear();
        setSuccessMessage('Application submitted successfully!')
    }
    
    let warningText = 'To complete the onboarding process press the button below'
    
    return (
        <Box component="div">
            <Stack spacing={2} mb={2} mt={2}>
                <Typography>FINISH</Typography>
                
                <Typography color={"error"} fontSize={15}>
                    {warningText}
                </Typography>
            </Stack>
           
            {successMessage && (
                <AlertPopup open={true} message={successMessage} />
            )}

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={onSubmitHandler}
                        name="submit"
                        disabled={disabled} 
                    > Send to CDA </Button>
                </Grid>
              </Grid>
        </Box>
    )
}

export default withAuth(Finish);