import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";

import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { Stack } from "@mui/material";
import { createSurveyContact } from "../../State/SurveysDuck";

export default function AddSurveyContact({ SurveyId }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const [data, setData] = React.useState({});

  const [errors, setErrors] = React.useState([]);

  React.useEffect(() => {
    if (createSurveyContact) {
      setData({
        survey_id: SurveyId || null,
        name: null,
        email: null,
        phone: null,
        link: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SurveyId]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (values) => {
    if (data.name === "") {
      setErrors([...errors, "Name"]);

      return;
    }

    dispatch(createSurveyContact(data));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth variant="outlined" onClick={handleClickOpen}>
        Add New Survey Contact
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Add New Contact</DialogTitle>
        <>
          <Stack sx={{ p: 3 }}>
            <TextField
              fullWidth
              sx={{ m: 1 }}
              margin="normal"
              name="name"
              label="Contact Name"
              variant="outlined"
              value={data.name}
              onChange={handleChange}
              error={errors.includes("name")}
            />
            <TextField
              fullWidth
              sx={{ m: 1 }}
              margin="normal"
              name="email"
              label="Email"
              variant="outlined"
              value={data.email}
              onChange={handleChange}
              error={errors.includes("email")}
            />
            <TextField
              fullWidth
              sx={{ m: 1 }}
              margin="normal"
              name="phone"
              label="Phone"
              variant="outlined"
              value={data.phone}
              onChange={handleChange}
              error={errors.includes("phone")}
            />
          </Stack>
          {errors.length > 0 && (
            <div>
              <h3>Errors</h3>
              <ul>
                {errors.map((error) => (
                  <li key={error}>{JSON.stringify(error)}</li>
                ))}
              </ul>
            </div>
          )}

          <Stack>
            <Button color="inherit" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Create
            </Button>
          </Stack>
        </>
      </Dialog>
    </div>
  );
}
