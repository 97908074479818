import React from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

import { useDispatch } from "react-redux";
import { deleteModuleOption } from "../../../../Redux/Ducks/AdminCompniesDuck";
import { DialogActions, Typography } from "@mui/material";

export default function DeleteModuleOptionDialog({ module }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(deleteModuleOption(module.id));
  };

  return (
    <div>
      <Button color="error" onClick={handleClickOpen}>
        Delete Module Option
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        sx={{ border: "4px solid red" }}
        open={open}
        onClose={handleClose}>
        <DialogTitle>Delete Module Option</DialogTitle>
        <DialogContent>
          <Typography gutterBottom variant="h6">
            {module?.name}
          </Typography>
          <Typography gutterBottom variant="body1">
            {module?.description}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            cancel
          </Button>
          <Button onClick={handleSubmit} color="error" variant="contained">
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
