import * as React from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { editDispositionDetails } from "../../State/DispostionsDuck";

export default function DeleteDispositionDetail({ data }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    data.active = false;
    dispatch(editDispositionDetails({ id: data.id, dis: data }));
    setOpen(false);
  };

  return (
    <div>
      <Button color="error" onClick={handleClickOpen}>
        Delete
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          Are you sure you want to delete this Disposition Detail? <br />
        </DialogTitle>
        <DialogTitle color="error">{data?.name}</DialogTitle>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="error" onClick={handleSubmit}>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
