import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";
import SelectWrapper from "../../../../../../../Components/FormComponents/Select";
import { createCampaign, updateCampaign } from "../../State/CampaingsDuck";
import { getClientsList } from "../../State/ClientsDuck";
import { useSelector } from "react-redux";

const CampaignFormCreateEdit = ({ campaignData }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientsList());
  }, [dispatch]);

  const Clients = useSelector((state) => state.Clients);

  const { clientsList } = Clients;

  return (
    <>
      <Formik
        initialValues={{
          name: campaignData?.name || "",
          client: campaignData?.client || "",
          client_id: campaignData?.client_id || "",
          campaign_type: campaignData?.campaign_type || "",
          sales_platform: campaignData?.sales_platform || "",
          policy_prefix: campaignData?.policy_prefix || "",
          policy_suffix: campaignData?.policy_suffix || "",
          bi_calc_type: campaignData?.bi_calc_type || "",
          bi_calc_rate: campaignData?.bi_calc_rate || "",
          ci_calc_type: campaignData?.ci_calc_type || "",
          ci_calc_rate: campaignData?.ci_calc_rate || "",
          note_1: campaignData?.note_1 || "",
          note_2: campaignData?.note_2 || "",
          note_3: campaignData?.note_3 || "",
          note_4: campaignData?.note_4 || "",
          note_5: campaignData?.note_5 || "",
          state: campaignData?.state || "",

          update_reason: campaignData.id ? "update" : "create",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Campaign name is required"),
          client_id: Yup.string().required("Client ID is required"),
          campaign_type: Yup.string().required("Type is required"),
          sales_platform: Yup.string().required("Sales platform is required"),

          bi_calc_type: Yup.string().required("Bi calc type is required"),
          bi_calc_rate: Yup.number().required("Bi calc rate is required"),
          ci_calc_type: Yup.string().required("Ci calc type is required"),
          ci_calc_rate: Yup.number().required("Ci calc rate is required"),
        })}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          if (campaignData.id) {
            dispatch(updateCampaign(values, campaignData.id));
          } else {
            dispatch(createCampaign(values));
          }

          setSubmitting(false);
        }}>
        {() => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextfieldWrapper name="name" label="Campaign Name" />
                </Grid>
                <Grid item xs={12}>
                  <SelectWrapper
                    name="client_id"
                    label="Select Client"
                    options={clientsList.map((client) => {
                      return {
                        value: client.id,
                        label: client.name,
                      };
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectWrapper
                    name="campaign_type"
                    label="Campaign Type"
                    options={[
                      { value: "voice-inbound", label: "Voice Inbound" },
                      { value: "voice-outbound", label: "Voice Outbound" },
                      { value: "voice", label: "Voice" },
                      { value: "avm", label: "AVM" },
                      { value: "communication", label: "Communication" },
                      { value: "Other", label: "Other" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectWrapper
                    name="sales_platform"
                    label="Sales Platform"
                    options={[
                      { value: "none", label: "none" },
                      { value: "taskflow", label: "Taskflow" },
                      { value: "mip", label: "MIP" },
                      { value: "node", label: "NODE" },
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="policy_prefix"
                    label="Policy Prefix"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="policy_suffix"
                    label="Policy Suffix"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectWrapper
                    name="bi_calc_type"
                    label="Bi Calc Type"
                    options={[
                      { value: "average premium", label: "Average Premium" },
                      { value: "cost per sale", label: "Cost per sale" },
                      { value: "hours", label: "Hours" },
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="bi_calc_rate"
                    label="Bi Calc Rate"
                    type="number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectWrapper
                    name="ci_calc_type"
                    label="Ci Calc Type"
                    options={[
                      { value: "cost per sale", label: "cost per sale" },
                      { value: "hours", label: "hours" },
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="ci_calc_rate"
                    label="Ci Calc Rate"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper name="note_1" label="Note No: 1" />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper
                    multiline
                    name="note_2"
                    label="Note No: 2"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper
                    multiline
                    name="note_3"
                    label="Note No: 3"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper
                    multiline
                    name="note_4"
                    label="Note No: 4"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper
                    multiline
                    name="note_5"
                    label="Note No: 5"
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectWrapper
                    name="state"
                    label="Campaign State"
                    options={[
                      { value: "draft", label: "Draft" },
                      { value: "stopped", label: "stopped" },
                      { value: "running", label: "running" },
                    ]}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button type="submit" fullWidth variant="contained">
                    Save
                  </Button>
                </Grid>

                <Grid item xs={6}></Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CampaignFormCreateEdit;

// "id": 11000,
// "campaign_name": "Direct Pre-NTU",
// "client": "Hollard",
// "campaign_type": "voice - outbound",
// "sales_platform": {
//   "id": 1,
//   "name": "MIP"
// },
// "bi_calc_type": null,
// "bi_calc_rate": 0,
// "ci_calc_type": "cost per sale",
// "ci_calc_rate": 5,
// "policy_prefix": null,
// "policy_suffix": null,
// "note_1": null,
// "note_2": null,
// "note_3": null,
// "note_4": null,
// "note_5": null,
