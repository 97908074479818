import { Alert, Button } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSalesAgentCampaigns } from "../CallCenterAgents/State/SalesAgentCampaignsDuck";

const CampaignTeamLeaderList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSalesAgentCampaigns());
  }, [dispatch]);

  const salesAgent = useSelector((state) => state.salesAgent);
  const { campaigns } = salesAgent;

  return (
    <>
      {campaigns && campaigns.length > 0 ? (
        <DataGridPremium
          rows={campaigns}
          columns={[
            { field: "id", headerName: "ID", width: 70 },
            { field: "name", headerName: "Campaign Name", width: 200 },
            { field: "campaign_type", headerName: "campaign_type", width: 200 },

            {
              field: "View Stats",
              headerName: "View Stats",
              width: 200,
              renderCell: (params) => (
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(
                      `/CDA/CallCenter/TeamLeader/CampaignSalesStats/${params.row.id}`
                    )
                  }>
                  View Stats
                </Button>
              ),
            },
          ]}
        />
      ) : (
        <Alert>No Campaigns. You need to be added to a call center team</Alert>
      )}
    </>
  );
};

export default CampaignTeamLeaderList;
