import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import MainAppContainer from "../../../Components/Containers/MainAppContainer";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignById } from "../StateManager/Campaigns/CRMCampaignDuck";
import { Box } from "@mui/system";

const CRMCampaign = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCampaignById(id));
  }, [dispatch, id]);

  const CRMCampaignDuck = useSelector((state) => state.CRMCampaignDuck);
  const { campaign, loading } = CRMCampaignDuck;

  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <MainAppContainer>
      <h1>CRMCampaign</h1>
      <h1>{id}</h1>

      <Card variant="outlined">
        <CardHeader
          title={campaign.campaign_name}
          subheader={campaign.campaign_type}
          action={<Chip label={campaign.campaign_status} />}
        />
        <CardContent>
          <Typography color="textSecondary" variant="caption">
            Lead Type
          </Typography>
          <Typography gutterBottom variant="body1">
            {campaign.lead_type}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Expected Start Date
          </Typography>
          <Typography variant="body1">
            {new Date(campaign.campaign_start_date).toLocaleDateString()}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Duration
          </Typography>
          <Typography variant="body1">
            {campaign.expected_duration} Months
          </Typography>
        </CardContent>
      </Card>
    </MainAppContainer>
  );
};

export default CRMCampaign;
