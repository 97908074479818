import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getQaTeamById } from "./State/QaTeamsDuck";
import { Container, Stack } from "@mui/material";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import withAuth from "../../../../../Hoc/withAuth";
import { getCompanyModules } from "../../../../../Redux/Ducks/AdminCompniesDuck";
import AddUsersToTeamDialog from "./Components/Teams/AddUserToQATeams";
import QaTeamUsersList from "./Components/Teams/QaTeamUsersList";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";

const QaTeamEdit = ({ companyDetails }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getQaTeamById(id));
    dispatch(getCompanyModules(companyDetails?.companyId));
  }, [dispatch, id, companyDetails]);

  const QaTeamsDuck = useSelector((state) => state.QaTeamsDuck);

  const { QaTeam, qaTeamsListSuccess } = QaTeamsDuck;

  const Companies = useSelector((state) => state.Companies);
  const { companyModules } = Companies;

  const moduleUsers = companyModules?.find((module) => module?.name === "qa");
  const users = [];

  for (let i = 0; i < QaTeam?.qa_teams_users?.length; i++) {
    for (let j = 0; j < moduleUsers?.Modules_users?.length; j++) {
      if (
        QaTeam?.qa_teams_users[i].user_id ===
        moduleUsers?.Modules_users[j].user_id
      ) {
        users.push(moduleUsers?.Modules_users[j]);
      }
    }
  }

  let availableUsers = moduleUsers?.Modules_users.filter(
    (user) => !users.includes(user)
  );

  return (
    <Container>
      <PageHeader
        title={`Team ${QaTeam.name}`}
        subheader="EDIT QA TEAM"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Team Management",
            href: "/CDA/QATeams",
          },
          {
            title: "Edit Teams",
            href: `/CDA/QATeams/${QaTeam.id}`,
          },
        ]}
      />

      <Stack spacing={2}>
        <AddUsersToTeamDialog users={availableUsers} teamId={id} />

        <QaTeamUsersList list={QaTeam} />
      </Stack>

      <AlertPopup
        open={qaTeamsListSuccess}
        message="QA Team Updated"
        severity="success"
      />
    </Container>
  );
};

export default withAuth(QaTeamEdit);
