import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import withAuth from "../../../../../Hoc/withAuth";
import { useDispatch, useSelector } from "react-redux";
import { getSalesAgentSales } from "./State/SalesAgentCampaignsDuck";
import { Chip } from "@mui/material";
import EditSale from "./components/EditSale";
import { useNavigate } from "react-router-dom";

const AgentSales = ({ user }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [sales, setSales] = React.useState([]);

  useEffect(() => {
    dispatch(getSalesAgentSales(user.id));
  }, [dispatch, user]);

  const salesAgent = useSelector((state) => state.salesAgent);
  const { agentSales } = salesAgent;

  useEffect(() => {
    if (agentSales) {
      setSales(agentSales);
    }
  }, [agentSales]);

  const handleSearch = (e) => {
    const search = e.target.value;
    const filtered = agentSales.filter((sale) => {
      return (
        sale.lead_id.toString().toLowerCase().includes(search.toLowerCase()) ||
        sale.id.toString().toLowerCase().includes(search.toLowerCase())
      );
    });
    setSales(filtered);
  };

  return (
    <>
      <Stack direction="row" sx={{ mb: 2 }}>
        <TextField
          fullWidth
          id="standard-basic"
          label="Search Sales"
          onChange={handleSearch}
        />
      </Stack>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "background.paper",
            }}>
            <TableCell>Lead</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="center">Actions</TableCell>
            <TableCell align="center">View Lead</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sales &&
            sales.length > 0 &&
            sales.map((sale, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{sale.lead_id}</TableCell>

                  <TableCell>{sale.premium}</TableCell>
                  <TableCell>
                    <Chip
                      variant="outlined"
                      color={
                        sale.status === "pending"
                          ? "error"
                          : sale.status === "completed"
                          ? "success"
                          : sale.status === "staging"
                          ? "warning"
                          : sale.status === "cancelled"
                          ? "default"
                          : "info"
                      }
                      label={sale.status}
                    />
                  </TableCell>
                  <TableCell>
                    <EditSale sale={sale} user={user} />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() =>
                        navigate(`/CDA/CampaignLeads/Lead/${sale.lead_id}`, {
                          replace: true,
                        })
                      }>
                      View Lead
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
};

export default withAuth(AgentSales);
