import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Stack,
  CircularProgress,
  Chip,
  // Alert,
  CardActions,
  Button,
  // TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import withAuth from "../../../../../../../Hoc/withAuth";

import { useNavigate } from "react-router-dom";
import {
  getCompletedSurveys,
  getUsersSurveys,
} from "../../State/SurveyUsersDuck";
import { RESET_SURVEY_CAMPAIGN } from "../../State/SurveysDuck";

const UserSurveyList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const UserSurveys = useSelector((state) => state.UserSurveys);
  const { loading, surveys } = UserSurveys;

  useEffect(() => {
    dispatch({ type: RESET_SURVEY_CAMPAIGN });
    dispatch(getUsersSurveys());
    dispatch(getCompletedSurveys());
  }, [dispatch]);

  // const [search, setSearch] = React.useState("");

  // const handleSearch = (e) => {
  //   setSearch(e.target.value);
  // };

  // const filteredSurveys = surveys.filter((survey) => {
  //   return survey.survey_company
  //     ?.toLowerCase()
  //     ?.includes(search?.toLowerCase());
  // });

  if (loading) {
    return (
      <Stack display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Grid container mt={3} spacing={2}>
      {surveys &&
        surveys?.map((campaign, index) => {
          let { survey_campaign: survey } = campaign;
          if (survey?.status) {
            return (
              <Grid key={index} item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Card>
                  <CardHeader
                    title={survey?.name}
                    subheader={survey?.client}
                    action={
                      <Chip
                        variant="outlined"
                        label={survey?.status ? "Active" : "Disabled"}
                        color={survey?.status ? "secondary" : "warning"}
                      />
                    }
                  />
                  <CardContent>
                    <Typography variant="body1">
                      {survey?.description}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => navigate(`/CDA/Survey/${survey?.id}`)}
                    >
                      Start Survey
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          } else {
            return <div key={index}></div>;
          }
        })}

      {/* {filteredSurveys?.length === 0 && (
        <Alert severity="info">No Surveys Found</Alert>
      )}

      {error && <Alert severity="info">{JSON.stringify(error)}</Alert>}

      <Grid item xl={12} lg={12} md={12}>
        <TextField
          fullWidth
          label="Search"
          value={search}
          onChange={handleSearch}
          margin="normal"
          variant="outlined"
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12}>
        {filteredSurveys &&
          filteredSurveys.map((survey, index) => {
            return (
              <Grid
                key={index}
                sx={{ mt: 2 }}
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Card variant="contained">
                  <CardHeader
                    title={survey?.survey_company}
                    subheader={survey?.survey_contact_person}
                  />
                  <CardContent>
                    {survey?.survey_campaign?.name}
                    <Typography variant="body1">
                      {survey?.survey_campaign?.client}
                    </Typography>
                    <Typography variant="caption">{survey?.note1}</Typography>
                    <Typography variant="caption">{survey?.note2}</Typography>
                    <Typography variant="caption">{survey?.note3}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={() =>
                        navigate(
                          `/CDA/ViewSurvey/${survey?.id}/${survey?.survey_user_id}`,
                        )
                      }
                    >
                      View Survey
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid> */}
    </Grid>
  );
};

export default withAuth(UserSurveyList);
