import {
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainAppContainer from "../../../../../../../Components/Containers/MainAppContainer";
import { getQuestionsResponses } from "../../State/SurveyReportsDuck";
import { useParams } from "react-router-dom";

import { Box } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getSurveyQuestions } from "../../State/SurveysDuck";

const ResultsQuestionsAnswers = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getQuestionsResponses(id));
    dispatch(getSurveyQuestions(id));
  }, [dispatch, id]);

  const SurveyReports = useSelector((state) => state.SurveyReports);
  const { questionAnswers } = SurveyReports;

  const Surveys = useSelector((state) => state.Surveys);
  const { surveyQuestions } = Surveys;

  // Get answers to questions from the survey

  const getSurveyResults = [];

  questionAnswers.forEach((QA, index) => {
    QA.survey_results.forEach((result) => {
      getSurveyResults.push(result);
    });
  });

  let question = surveyQuestions?.map((question, index) => {
    return {
      id: question.id,
      question: question.question,
      type: question.question_type,
      answers: question.survey_answers,
      data: question,

      AnswersData: getSurveyResults.filter((result) => {
        return result.question_id === question.id;
      }),
    };
  });

  const getCount = () => {
    // let count = 0;
    return;
  };

  const GetValueAve = (answer) => {
    const answerCount = [];

    getSurveyResults.forEach((result) => {
      if (result.answer_id === 10000) {
        answerCount.push(result);
      } else {
        return;
      }
    });
    return answerCount.length;
  };

  return (
    <MainAppContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Question</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {question.map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    getCount={getCount}
                    GetValueAve={GetValueAve}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </MainAppContainer>
  );
};

export default ResultsQuestionsAnswers;

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  console.log(row);

  let TotalCountSlider = 0;

  let totalCountOfEachAnswer = [];

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id} : {row.question} {row.type}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>answer</TableCell>
                    <TableCell>Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.answers.length > 0 ? (
                    <>
                      {row.answers.map((historyRow, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {historyRow.answer}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {
                                row.AnswersData.filter((result) => {
                                  return result.answer_id === historyRow.id;
                                }).length
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {row.AnswersData.map((data, index) => {
                            if (data.question_id === row.id) {
                              TotalCountSlider = TotalCountSlider + 1;

                              totalCountOfEachAnswer = [
                                ...totalCountOfEachAnswer,
                                data.answer_id,
                              ];

                              return <>{}</>;
                            }
                            return null;
                          })}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {TotalCountSlider}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
