import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { DialogContent, Stack, Typography } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { RESET_QA_QUEUES, updateQaQueue } from "../../State/QaDuck";

export default function EnableQaQueue({ Q }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({ type: RESET_QA_QUEUES });
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button color="success" onClick={handleClickOpen}>
        Enable Queue
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Enable Queue</DialogTitle>

        <Formik
          initialValues={{
            id: Q.id,
            queue_name: Q.queue_name,
            queue_type: Q.queue_type,
            required: Q.required,
            updated_reason: "Queue Enabled",
            active: true,
          }}
          validationSchema={Yup.object({
            queue_name: Yup.string().required("Name is required"),
            queue_type: Yup.string().required("Queue Type is required"),
            required: Yup.number()
              .max(100)
              .required("Verification % is required"),
            active: Yup.boolean().required("Active is required"),
          })}
          onSubmit={(values) => {
            dispatch(updateQaQueue(values));
          }}>
          {() => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <Typography variant="h6" gutterBottom>
                    {Q.queue_name}
                  </Typography>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" type="submit">
                  Enable
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
