import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  Paper,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainAppContainer from "../../Components/Containers/MainAppContainer";
import withAuth from "../../Hoc/withAuth";
import { GetAllUsers } from "../../Redux/Ducks/AdminUserManagement";
import { RESET_ADMIN_USER_STATE } from "../../Redux/ActionNames/AllActions";
import { getCompanies } from "../../Redux/Ducks/AdminCompniesDuck";
import AlertPopup from "../../Components/Bits/AlertPopup";
import DeleteUser from "./components/DeleteUserDialog";
import RestoreUser from "./components/RestoreUserDialog";
import { GridToolbar } from "@mui/x-data-grid";

const UserManagement = ({ user, companyDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const handleSearch = (e) => setSearch(e.target.value);

  useEffect(() => {
    dispatch(getCompanies());
    dispatch(GetAllUsers());
    return () => dispatch({ type: RESET_ADMIN_USER_STATE });
  }, [dispatch]);
  

  const AdminUsers = useSelector((state) => state.AdminUsers);
  const { allUsers, deleteUser, restoreUser } = AdminUsers;

  const users =
    user.userType === "admin"
      ? allUsers
      : user.userType === "super_admin"
      ? allUsers.filter(
          (u) => u?.companies_users[0]?.company_id === companyDetails.companyId
        )
      : [];

  const searchedUser = users?.filter((u) => {
    const fullName = `${u.name} ${u.surname}`.toLowerCase();
    return fullName.includes(search.toLowerCase());

  });

  // DataGrid column definitions
  const columns = [
    {
      field: "name",
      headerName: "User",
      width: 200,
      valueGetter: (params) => `${params.row.name} ${params.row.surname}`,
    },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "company",
      headerName: "Company",
      width: 200,
      valueGetter: (params) =>
        params.row.companies_users?.[0]?.company?.name || "",
    },
    {
      field: "userType",
      headerName: "User Type",
      width: 150,
      renderCell: (params) => (
        <Chip
          onClick={() => navigate(`/admin/users/${params.row.id}`)}
          variant="outlined"
          color={
            params.row.deletedAt
              ? "error"
              : params.row.user_type?.type === "admin"
              ? "primary"
              : params.row.user_type?.type === "super"
              ? "error"
              : params.row.user_type?.type === "super_admin"
              ? "warning"
              : "secondary"
          }
          label={params.row.deletedAt ? "Inactive" : params.row.user_type?.type}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <Box display="flex" justifyContent="flex-end">
          {params.row.deletedAt ? (
            <RestoreUser id={params.row.id} />
          ) : (
            <>
              <Button onClick={() => navigate(`/EditUser/${params.row.id}`)}>
                Edit
              </Button>
              <DeleteUser name={params.row.name} id={params.row.id} />
            </>
          )}
        </Box>
      ),
    },
  ];

  const rows = searchedUser.map((user) => ({
    ...user,
    id: user.id,
  }));

  return (
    <MainAppContainer>
      <Typography sx={{ mb: 4 }} variant="h4" align="center">
        User Management
      </Typography>
      <Stack
        sx={{ mb: 4 }}
        spacing={4}
        direction="row"
        justifyContent="space-evenly">
        <Button variant="contained" onClick={() => navigate("/AddNewUser")}>
          Add User
        </Button>
      </Stack>
      <Stack mb={3}>
        <TextField
          name="search"
          label="Search"
          variant="outlined"
          onChange={handleSearch}
          fullWidth
        />
      </Stack>
      <Paper sx={{ height: 600, width: "100%" }}>
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          disableSelectionOnClick
        />
      </Paper>
      <AlertPopup
        severity="success"
        message="User Deleted Successfully"
        open={deleteUser}
      />
      <AlertPopup
        severity="success"
        message="User Restored Successfully"
        open={restoreUser}
      />
    </MainAppContainer>
  );
};

export default withAuth(UserManagement);
