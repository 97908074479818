import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddNewLeadDialog from "./Components/Leads/AddNewLeadDialog";
import { getLeadSourceList } from "./State/LeadsDuck";

import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import axios from "axios";
import FileSaver from "file-saver";
import dayjs from "dayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AllowAgentToCaptureLeads from "./Components/Leads/AllowAgentToCaptureLeads";
import withAuth from "../../../../../Hoc/withAuth";
import { useQuery } from "@tanstack/react-query";

const CampaignLeadsManage = ({ user }) => {
  const { id } = useParams();

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const currentCampaignSettings = useQuery(
    ["currentCampaignSettings", id],
    (data) => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/campaignSettings`,
        data,
        config,
      );
    },
    {
      enabled: !!user.token,
    },
  );

  const [value, setValue] = React.useState([dayjs().startOf("month"), dayjs()]);

  const [loading, setLoading] = React.useState(false);

  const downloadDocButton = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/downloadLeads?start_date=${value[0]}&end_date=${value[1]}`,
        { responseType: "arraybuffer" },
      );

      var blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(
        blob,
        `campaign_${id}_${value[0].format("DD-MM-YYYY")}_${value[1].format(
          "DD-MM-YYYY",
        )}.xlsx`,
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getCampaignLeads(id, rowsPerPage, page));
    dispatch(getLeadSourceList());
  }, [dispatch]);

  const Leads = useSelector((state) => state.Leads);

  const { activeLeadSources, leadAdded } = Leads;

  return (
    <>
      <AlertPopup open={leadAdded} message="lead Added" severity="success" />
      <Stack direction="row">
        {currentCampaignSettings.isFetched && (
          <AllowAgentToCaptureLeads
            id={id}
            current={
              currentCampaignSettings &&
              currentCampaignSettings?.data?.data?.data
            }
          />
        )}
      </Stack>

      <Stack direction="row" spacing={4} sx={{ my: 3 }}>
        <Button
          onClick={() => {
            navigate(`/CDA/ManageCampaign/${id}/edit/LeadFileUploader`);
          }}
          color="secondary"
          variant="contained"
        >
          Upload Lead Files
        </Button>

        <AddNewLeadDialog id={id} activeLeadSources={activeLeadSources} />
      </Stack>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography sx={{ mb: 3 }} variant="h6">
          Download Leads
        </Typography>
        <Typography sx={{ mb: 1 }} variant="caption">
          Select Date Range
        </Typography>
        <>
          <DateRangePicker
            value={value}
            onChange={(newValue) => setValue(newValue)}
          />

          {loading ? (
            <Button
              sx={{ mt: 4 }}
              disabled
              variant="contained"
              onClick={downloadDocButton}
            >
              Loading
            </Button>
          ) : (
            <Button
              sx={{ mt: 4 }}
              variant="contained"
              onClick={downloadDocButton}
            >
              Download Leads
            </Button>
          )}
        </>
      </LocalizationProvider>

      {/* <Stack>
        {campaignLeads.rows && campaignLeads.rows.length > 0 ? (
          <LeadsDataGrid
            columnBuffer={2}
            columnThreshold={2}
            total={campaignLeads.count}
            loading={loading}
            data={campaignLeads.rows}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        ) : (
          <Alert severity="info">No Leads Found</Alert>
        )} */}
      {/* </Stack> */}
    </>
  );
};

export default withAuth(CampaignLeadsManage);
