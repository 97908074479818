import {
  Card,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const SaleCard = ({ sale }) => {
  return (
    <Card>
      <List dense subheader={<ListSubheader>Sale</ListSubheader>}>
        <Divider />
        <ListItem
          secondaryAction={
            <Chip
              variant="outlined"
              color={
                sale.status === "pending"
                  ? "error"
                  : sale.status === "completed"
                  ? "success"
                  : sale.status === "staging"
                  ? "warning"
                  : sale.status === "cancelled"
                  ? "default"
                  : "info"
              }
              label={sale.status}
            />
          }>
          <ListItemText
            primary={
              <>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.secondary">
                  Sales Agent
                </Typography>
              </>
            }
            secondary={
              <>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body1"
                  color="text.primary">
                  {sale?.User?.name}
                </Typography>
              </>
            }
          />
        </ListItem>
        <Divider />
        <ContentItem title="Reference No" value={sale?.reference_no} />
        <ContentItem title="premium" value={sale?.premium} />
        <ContentItem title="Note 1" value={sale?.note1} />
        <ContentItem title="Note 2" value={sale?.note2} />
        {sale?.cm_sales_dispositions?.map((dis) => {
          return (
            <Stack key={dis.id}>
              <ContentItem
                title="Disposition"
                value={dis?.cc_disposition?.name}
              />

              <ContentItem
                title="Disposition Detail"
                value={dis?.cc_disposition_detail?.name}
              />
            </Stack>
          );
        })}
      </List>
    </Card>
  );
};

export default SaleCard;

const ContentItem = ({ title, value }) => {
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.secondary">
                {title}
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body1"
                color="text.primary">
                {value}
              </Typography>
            </>
          }
        />
      </ListItem>
    </>
  );
};
