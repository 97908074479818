import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addNewSale } from "../State/SalesAgentCampaignsDuck";
import {
  availableDispositionsOptions,
  getCampaignDispositions,
} from "../../CampaignsManagement/State/CampaingsDuck";

import DateSelectorWrapper from "../../../../../../Components/FormComponents/DateSelectorWrapper";

export default function NewSale({ lead, user }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let id = lead?.campaign_id;

  useEffect(() => {
    if (id) {
      dispatch(getCampaignDispositions(id));
      dispatch(availableDispositionsOptions(id));
    }
  }, [dispatch, id]);

  const Campaigns = useSelector((state) => state.Campaigns);

  const { availableDispositions: disList } = Campaigns;

  let { cc_disposition_lists } = disList;

  // Get Dispositions out of list

  const availableDispositions = [];

  cc_disposition_lists &&
    cc_disposition_lists?.forEach((cc_disposition_lists) => {
      cc_disposition_lists?.cc_dispositions.forEach((disposition) => {
        if (disposition && disposition.active === true) {
          return availableDispositions.push(disposition);
        }
      });
    });

  const findDisposition = (id) => {
    return availableDispositions?.find((disposition) => disposition?.id === id);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        New Sale
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>New Sale {lead._id}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              reference_no: lead.external_reference_no || "",
              premium: "",
              debit_date: new Date(),
              note1: "",
              note2: "",
              agent_id: user.id,
              disposition_id: "",

              disposition_detail_id: "",
              status: "new",
              updated_reason: "new",
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              premium: Yup.number().required("Premium is required"),
              agent_id: Yup.string().required("Agent ID is required"),
              disposition_id: Yup.string().required("Disposition is required"),
              reference_no: Yup.string().required("Reference No is required"),
            })}
            onSubmit={(values) => {
              dispatch(addNewSale({ id: lead._id, values }));
              handleClose();
            }}>
            {({ values, errors }) => {
              return (
                <Form>
                  <Stack spacing={2} sx={{ my: 2 }}>
                    <TextfieldWrapper
                      name="premium"
                      label="Premium"
                      type="number"
                      placeholder="Premium"
                    />

                    <SelectDisposition
                      availableDispositions={availableDispositions}
                    />

                    {findDisposition(values?.disposition_id)
                      ?.cc_disposition_details &&
                      findDisposition(values?.disposition_id)
                        ?.cc_disposition_details?.length > 0 && (
                        <SelectDispositionDetail
                          availableDispositionsDetails={
                            findDisposition(values?.disposition_id)
                              ?.cc_disposition_details
                          }
                        />
                      )}

                    <TextfieldWrapper
                      name="reference_no"
                      label="Policy No"
                      type="text"
                      placeholder="Policy No"
                    />

                    <DateSelectorWrapper
                      name="debit_date"
                      label="Payment Date"
                    />

                    <TextfieldWrapper
                      name="note1"
                      label="Note 1"
                      multiline
                      type="text"
                      placeholder="Note 1"
                    />
                    <TextfieldWrapper
                      name="note2"
                      label="Note 2"
                      multiline
                      type="text"
                      placeholder="Note 2"
                    />
                    <DialogActions>
                      <Button
                        variant="outlined"
                        color="warning"
                        onClick={handleClose}>
                        Cancel
                      </Button>

                      <Button
                        // disabled={
                        //   values.disposition_detail_id === "" &&
                        //   findDisposition(values?.disposition_id)
                        //     ?.cc_disposition_disposition_details?.length > 0
                        // }
                        color="primary"
                        variant="contained"
                        type="submit">
                        Save
                      </Button>
                    </DialogActions>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const SelectDisposition = ({ availableDispositions }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const handleChange = (e) => {
    let id = e.target.value;

    setFieldValue("disposition_detail_id", "");
    setFieldValue("disposition_id", id);
  };

  return (
    <FormControl error={errors["disposition_id"] ? true : false} fullWidth>
      <InputLabel id="disposition_label_id">Disposition</InputLabel>
      <Select
        labelId="disposition_label_id"
        id="disposition_id"
        value={values["disposition_id"]}
        label="Disposition"
        onChange={handleChange}>
        {availableDispositions?.map((disposition) => (
          <MenuItem key={disposition.id} value={disposition.id}>
            {disposition.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const SelectDispositionDetail = ({ availableDispositionsDetails }) => {
  const { values, setFieldValue, setErrors, errors } = useFormikContext();

  const [dispositionDetail, setDispositionDetail] = React.useState(
    values["disposition_detail_id"]
  );

  useEffect(() => {
    if (!dispositionDetail && availableDispositionsDetails.length > 0) {
      setErrors({
        ...errors,
        disposition_detail_id: "Disposition Detail is required",
      });
    }
  }, [setErrors, errors, dispositionDetail, availableDispositionsDetails]);

  const handleChange = (e) => {
    let id = e.target.value;
    setDispositionDetail(id);

    setFieldValue("disposition_detail_id", id);
  };

  return (
    <FormControl
      error={errors["disposition_detail_id"] ? true : false}
      fullWidth>
      <InputLabel id="disposition_detail_id_label">
        disposition_detail_id
      </InputLabel>
      <Select
        labelId="disposition_detail_id_label"
        id="disposition_detail_id"
        value={dispositionDetail}
        label="Disposition Detail"
        onChange={handleChange}>
        {availableDispositionsDetails.map((disposition) => (
          <MenuItem key={disposition.id} value={disposition.id}>
            {disposition.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
