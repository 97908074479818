import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import MainAppContainer from "../../../../../../../Components/Containers/MainAppContainer";
import { useDispatch, useSelector } from "react-redux";
import { getUserSurveyDetailsById } from "../../State/SurveyUsersDuck";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Box } from "@mui/system";
import EditSurveyAnswer from "./EditSurveyAnswer";

const ViewSurvey = () => {
  const { id, survey_user_id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserSurveyDetailsById(id, survey_user_id));
  }, [id, survey_user_id, dispatch]);

  const UserSurveys = useSelector((state) => state.UserSurveys);
  const { surveyDetails, loading } = UserSurveys;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <MainAppContainer>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography sx={{ my: 4 }} align="center" variant="h4">
            View Survey
          </Typography>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <List>
                  <StyledListItem>
                    <TextBlock
                      title="Surveyed Company"
                      value={surveyDetails?.survey_company}
                    />
                  </StyledListItem>
                  <StyledListItem>
                    <TextBlock
                      title="Contact Person"
                      value={surveyDetails?.survey_contact_person}
                    />
                  </StyledListItem>
                  <StyledListItem>
                    {" "}
                    <TextBlock
                      title="Note 1"
                      value={surveyDetails?.survey_note_1}
                    />
                  </StyledListItem>
                  <StyledListItem>
                    <TextBlock
                      title="Note 2"
                      value={surveyDetails?.survey_note_2}
                    />
                  </StyledListItem>
                  <StyledListItem>
                    {" "}
                    <TextBlock
                      title="Note 3"
                      value={surveyDetails?.survey_note_3}
                    />
                  </StyledListItem>
                  <StyledListItem>
                    {" "}
                    <TextBlock
                      title="Note 4"
                      value={surveyDetails?.survey_note_4}
                    />
                  </StyledListItem>
                  <StyledListItem>
                    {" "}
                    <TextBlock
                      title="Note 5"
                      value={surveyDetails?.survey_note_5}
                    />
                  </StyledListItem>
                  <StyledListItem>
                    <TextBlock
                      title="Created At"
                      value={new Date(
                        surveyDetails?.createdAt
                      ).toLocaleString()}
                    />
                  </StyledListItem>
                </List>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 5 }} item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h4" sx={{ my: 4 }}>
              Survey Results
            </Typography>
            {/* {JSON.stringify(surveyDetails?.survey_results, null, 2)} */}
            <List>
              {surveyDetails?.survey_results &&
                surveyDetails?.survey_results
                  .sort(
                    (a, b) =>
                      a?.survey_campaigns_question?.orderId -
                      b?.survey_campaigns_question?.orderId
                  )
                  .map((result, index) => {
                    return (
                      <StyledListItem
                        key={index}
                        secondaryAction={<EditSurveyAnswer result={result} />}>
                        <Stack>
                          <Typography gutterBottom variant="body1">
                            Question {index + 1} :{"  "}
                            {result.survey_campaigns_question.question}
                          </Typography>
                          <div>
                            {result.answer_id === 10000 ? (
                              <Typography color="primary" variant="h6">
                                Value :{" "}
                                {result._value === "10000"
                                  ? "NA"
                                  : result._value}
                              </Typography>
                            ) : (
                              <>
                                {result.survey_campaigns_question
                                  ?.survey_answers &&
                                  result.survey_campaigns_question?.survey_answers?.map(
                                    (answer, index) => {
                                      if (answer.id === result.answer_id) {
                                        return (
                                          <div key={index}>
                                            <Typography
                                              color="primary"
                                              variant="h6">
                                              {answer.answer}
                                            </Typography>
                                          </div>
                                        );
                                      } else {
                                        return null;
                                      }
                                    }
                                  )}
                              </>
                            )}
                          </div>
                          <Box my={1}>
                            <Typography gutterBottom variant="caption">
                              Note : {result?.note}
                            </Typography>
                          </Box>
                        </Stack>
                      </StyledListItem>
                    );
                  })}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </MainAppContainer>
  );
};

export default ViewSurvey;

const TextBlock = ({ title, value }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Typography>{title}</Typography>
      <Typography>:</Typography>
      <Typography>{value}</Typography>
    </Stack>
  );
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
