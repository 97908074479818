import { Alert, Box, LinearProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import DateRangeSelector from "../../../../../../Components/FormComponents/DateRangeSelector";
import withAuth from "../../../../../../Hoc/withAuth";

const QueueReports = ({ user }) => {
  const { token } = user;
  const [value, setValue] = React.useState([
    dayjs().startOf("month"),
    dayjs().endOf("month"),
  ]);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ["GetQaStats"],
    () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/reports/QaReports?startDate=${value[0]}&endDate=${value[1]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  );

  useEffect(() => {
    refetch();
  }, [value, refetch]);

  const columns = [
    {
      field: "Qa_Name",
      headerName: "QA Queue Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "startDate",
      headerName: "From",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD-MMM-YYYY");
      },
    },
    {
      field: "endDate",
      headerName: "To",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD-MMM-YYYY");
      },
    },
    {
      field: "TotalEvaluationsInQueue",
      headerName: "Evaluations In Queue",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "TotalNewEvaluations",
      headerName: "New",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "FailedEvaluations",
      headerName: "Failed",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "TotalEvaluationBusy",
      headerName: "Busy",

      flex: 1,
      minWidth: 200,
    },

    {
      field: "TotalEvaluationDispute",
      headerName: "Disputes",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "TotalEvaluationRe_evaluate",
      headerName: "Re-evaluate",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "TotalCompletedEvaluations",
      headerName: "Completed",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "TotalFailPercentage",
      headerName: "Fail Percentage",
      flex: 1,
      minWidth: 200,

      renderCell: (params) => {
        return (
          <Typography
            sx={{
              color:
                params.row.TotalFailPercentage > 0
                  ? "error.main"
                  : "success.main",
            }}>
            {params.row.TotalFailPercentage}%
          </Typography>
        );
      },
    },
    {
      field: "TotalTimeSpentOnEvaluations",
      headerName: "Total Time Spent On Evaluations",
      flex: 1,
      minWidth: 200,
    },

    {
      field: "AverageTimeSpentPerEvaluation",
      headerName: "Average Time Spent Per Evaluation",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "AverageTcfScore",
      headerName: "Average Tcf Score",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "AverageTotalScore",
      headerName: "Average Total Score",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "AverageComplianceScore",
      headerName: "Average Compliance Score",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "AverageProductKnowledgeScore",
      headerName: "Average Product Knowledge Score",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "AverageProfessionalismScore",
      headerName: "Average Professionalism Score",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "AverageSalesSkillsScore",
      headerName: "Average Sales Skills Score",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "NumberOfTimesEvaluationsHasBeenDisputed",
      headerName: "Number Of Times Evaluations Has Been Disputed",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "NumberOfTimesEvaluationsHasBeenRe_evaluated",

      headerName: "Number Of Times Evaluations Has Been Re-evaluated",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "NumberOfTimesEvaluationsHasFailed",
      headerName: "Number Of Times Evaluations Has Failed",
      flex: 1,
      minWidth: 200,
    },
  ];

  return (
    <Stack sx={{ mt: 3 }}>
      <DateRangeSelector value={value} setValue={setValue} />
      {(isRefetching || isLoading) && <LinearProgress />}
      <Box sx={{ height: "60vh", mt: 4, width: "80vw" }}>
        {data?.data?.data && data?.data?.data?.length > 0 ? (
          <DataGridPremium
            rows={data?.data?.data}
            columns={columns}
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: {
                sx: {
                  backgroundColor: "background.paper",
                },
              },
            }}
          />
        ) : (
          <Alert severity="info">No Data Found</Alert>
        )}
      </Box>
    </Stack>
  );
};

export default withAuth(QueueReports);
