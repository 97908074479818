import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";
import { DialogContent, Stack } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { createQaTeam, RESET_QA_TEAMS } from "../../State/QaTeamsDuck";

export default function CreateQaTeam() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({ type: RESET_QA_TEAMS });
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create New Team
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Create New Queue</DialogTitle>

        <Formik
          initialValues={{
            name: "",
            updated_reason: "New Queue Created",
            active: true,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),

            active: Yup.boolean().required("Active is required"),
          })}
          onSubmit={(values) => {
            dispatch(createQaTeam(values));
            setTimeout(() => {
              handleClose();
            }, 1000);
          }}>
          {() => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <TextfieldWrapper label="Team Name" name="name" />
                </Stack>
              </DialogContent>
              <Button fullWidth variant="contained" type="submit">
                Save
              </Button>
              <Button fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
