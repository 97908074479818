import React from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import {
  createNewModuleOptions,
  getAllModuleOptions,
} from "../../../Redux/Ducks/AdminCompniesDuck";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import CloseIcon from "@mui/icons-material/Close";

import Slide from "@mui/material/Slide";
import { useEffect } from "react";
import ModulesOptionList from "./containers/ModulesOptionList";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddModuleOptions() {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllModuleOptions());
  }, [dispatch]);

  const Companies = useSelector((state) => state.Companies);

  const { moduleOptions, loading } = Companies;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Manage modules
      </Button>
      <Dialog
        TransitionComponent={Transition}
        fullScreen
        open={open}
        onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Manage modules
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Typography variant="h6" sx={{ my: 3 }}>
            Create New Modules options for Companies
          </Typography>

          <Formik
            initialValues={{
              name: "",
              description: "",
            }}
            onSubmit={(values, { setSubmitting, setValues }) => {
              setSubmitting(true);
              dispatch(createNewModuleOptions(values));
              setSubmitting(false);
              setValues({
                name: "",
                description: "",
              });
            }}>
            {() => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {" "}
                      <TextfieldWrapper label="Name" name="name" />
                    </Grid>
                    <Grid item xs={12}>
                      {" "}
                      <TextfieldWrapper
                        label="Description"
                        name="description"
                      />
                    </Grid>
                  </Grid>

                  <Button
                    sx={{ mt: 3 }}
                    type="submit"
                    variant="contained"
                    color="primary">
                    Add Module
                  </Button>
                </Form>
              );
            }}
          </Formik>

          {loading && <p>Loading...</p>}
          <Stack mt={3}>
            <ModulesOptionList list={moduleOptions} />
          </Stack>
        </DialogContent>
        <Stack></Stack>
      </Dialog>
    </div>
  );
}
