import axios from "axios";

const initialState = {
  campaigns: [],
  campaign: {},
  error: null,
  loading: false,
  success: false,
  campaignDispositions: [],
  campaignTeams: [],
  campaignSales: [],
  availableDispositions: [],
  removedList: false,
  addedSuccess: false,
};

const GET_CAMPAIGNS = "GET_CAMPAIGNS";
const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";
const GET_CAMPAIGNS_FAIL = "GET_CAMPAIGNS_FAIL";

const GET_CAMPAIGN_BY_ID = "GET_CAMPAIGN_BY_ID";
const GET_CAMPAIGN_BY_ID_SUCCESS = "GET_CAMPAIGN_BY_ID_SUCCESS";
const GET_CAMPAIGN_BY_ID_FAIL = "GET_CAMPAIGN_BY_ID_FAIL";

const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
const CREATE_CAMPAIGN_FAIL = "CREATE_CAMPAIGN_FAIL";

const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL";

const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
const DELETE_CAMPAIGN_FAIL = "DELETE_CAMPAIGN_FAIL";

const GET_CAMPAIGN_DISPOSITIONS = "GET_CAMPAIGN_DISPOSITIONS";
const GET_CAMPAIGN_DISPOSITIONS_SUCCESS = "GET_CAMPAIGN_DISPOSITIONS_SUCCESS";
const GET_CAMPAIGN_DISPOSITIONS_FAIL = "GET_CAMPAIGN_DISPOSITIONS_FAIL";

const ADD_DISPOSITION_TO_CAMPAIGN = "ADD_DISPOSITION_TO_CAMPAIGN";
const ADD_DISPOSITION_TO_CAMPAIGN_SUCCESS =
  "ADD_DISPOSITION_TO_CAMPAIGN_SUCCESS";
const ADD_DISPOSITION_TO_CAMPAIGN_FAIL = "ADD_DISPOSITION_TO_CAMPAIGN_FAIL";

const REMOVED_DISPOSITION_FROM_CAMPAIGN = "REMOVED_DISPOSITION_FROM_CAMPAIGN";
const REMOVED_DISPOSITION_FROM_CAMPAIGN_SUCCESS =
  "REMOVED_DISPOSITION_FROM_CAMPAIGN_SUCCESS";
const REMOVED_DISPOSITION_FROM_CAMPAIGN_FAIL =
  "REMOVED_DISPOSITION_FROM_CAMPAIGN_FAIL";

const GET_CAMPAIGN_TEAMS = "GET_CAMPAIGN_TEAMS";
const GET_CAMPAIGN_TEAMS_SUCCESS = "GET_CAMPAIGN_TEAMS_SUCCESS";
const GET_CAMPAIGN_TEAMS_FAIL = "GET_CAMPAIGN_TEAMS_FAIL";

const ADD_TEAM_TO_CAMPAIGN = "ADD_TEAM_TO_CAMPAIGN";
const ADD_TEAM_TO_CAMPAIGN_SUCCESS = "ADD_TEAM_TO_CAMPAIGN_SUCCESS";
const ADD_TEAM_TO_CAMPAIGN_FAIL = "ADD_TEAM_TO_CAMPAIGN_FAIL";

const REMOVE_TEAM_FROM_CAMPAIGN = "REMOVE_TEAM_FROM_CAMPAIGN";
const REMOVE_TEAM_FROM_CAMPAIGN_SUCCESS = "REMOVE_TEAM_FROM_CAMPAIGN_SUCCESS";
const REMOVE_TEAM_FROM_CAMPAIGN_FAIL = "REMOVE_TEAM_FROM_CAMPAIGN_FAIL";

const GET_CAMPAIGN_SALES = "GET_CAMPAIGN_SALES";
const GET_CAMPAIGN_SALES_SUCCESS = "GET_CAMPAIGN_SALES_SUCCESS";
const GET_CAMPAIGN_SALES_FAIL = "GET_CAMPAIGN_SALES_FAIL";

const GET_AVAILABLE_DISPOSITIONS = "GET_AVAILABLE_DISPOSITIONS";
const GET_AVAILABLE_DISPOSITIONS_SUCCESS = "GET_AVAILABLE_DISPOSITIONS_SUCCESS";
const GET_AVAILABLE_DISPOSITIONS_FAIL = "GET_AVAILABLE_DISPOSITIONS_FAIL";

const REMOVE_DISPOSITION_LIST_FROM_CAMPAIGN =
  "REMOVE_DISPOSITION_LIST_FROM_CAMPAIGN";
const REMOVE_DISPOSITION_LIST_FROM_CAMPAIGN_SUCCESS =
  "REMOVE_DISPOSITION_LIST_FROM_CAMPAIGN_SUCCESS";
const REMOVE_DISPOSITION_LIST_FROM_CAMPAIGN_FAIL =
  "REMOVE_DISPOSITION_LIST_FROM_CAMPAIGN_FAIL";

export const RESET_CAMPAIGNS = "RESET_CAMPAIGNS";

const campaignsDuck = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
        loading: false,
        error: null,
      };
    case GET_CAMPAIGNS_FAIL:
      return {
        ...state,
        campaigns: [],
        loading: false,
        success: false,
        error: action.payload,
      };
    case GET_CAMPAIGN_BY_ID:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_CAMPAIGN_BY_ID_SUCCESS:
      return {
        ...state,
        campaign: action.payload,
        loading: false,

        error: null,
      };
    case GET_CAMPAIGN_BY_ID_FAIL:
      return {
        ...state,
        campaign: {},
        loading: false,
        success: false,
        error: action.payload,
      };
    case CREATE_CAMPAIGN:
      return {
        ...state,
        loading: true,
        successCampaignCreate: false,
        error: null,
      };
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,

        campaign: action.payload,

        loading: false,
        successCampaignCreate: true,
        error: null,
      };
    case CREATE_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        successCampaignCreate: false,
        error: action.payload,
      };
    case UPDATE_CAMPAIGN:
      return {
        ...state,
        loading: true,
        successCampaignCreate: true,
        error: null,
      };
    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload,
        loading: false,
        successUpdateCampaign: true,
        error: null,
      };
    case UPDATE_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        successUpdateCampaign: false,
        error: action.payload,
      };
    case DELETE_CAMPAIGN:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.filter(
          (campaign) => campaign.id !== action.payload
        ),
        loading: false,
        success: true,
        error: null,
      };
    case DELETE_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_CAMPAIGN_DISPOSITIONS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_CAMPAIGN_DISPOSITIONS_SUCCESS:
      return {
        ...state,
        campaignDispositions: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case GET_CAMPAIGN_DISPOSITIONS_FAIL:
      return {
        ...state,
        campaign: {},
        loading: false,
        success: false,
        error: action.payload,
      };

    case ADD_DISPOSITION_TO_CAMPAIGN:
      return {
        ...state,
        loading: true,
        success: false,
        addedSuccess: false,
        error: null,
      };
    case ADD_DISPOSITION_TO_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignDispositions: action.payload,
        addedSuccess: true,
        loading: false,
        success: true,
        error: null,
      };

    case ADD_DISPOSITION_TO_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        addedSuccess: false,
        success: false,
        error: action.payload,
      };

    case REMOVE_DISPOSITION_LIST_FROM_CAMPAIGN:
      return {
        ...state,
        loading: true,
        success: false,
        removedList: false,
        error: null,
      };
    case REMOVE_DISPOSITION_LIST_FROM_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignDispositions: action.payload,
        loading: false,
        removedList: true,
        success: true,
        error: null,
      };
    case REMOVE_DISPOSITION_LIST_FROM_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        removedList: false,
        error: action.payload,
      };

    case REMOVED_DISPOSITION_FROM_CAMPAIGN:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case REMOVED_DISPOSITION_FROM_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignDispositions: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case REMOVED_DISPOSITION_FROM_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_CAMPAIGN_TEAMS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_CAMPAIGN_TEAMS_SUCCESS:
      return {
        ...state,
        campaignTeams: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case GET_CAMPAIGN_TEAMS_FAIL:
      return {
        ...state,
        campaignTeams: [],
        loading: false,
        success: false,
        error: action.payload,
      };
    case ADD_TEAM_TO_CAMPAIGN:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case ADD_TEAM_TO_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignTeams: [...state.campaignTeams, action.payload],
        loading: false,
        success: true,
        error: null,
      };
    case ADD_TEAM_TO_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case REMOVE_TEAM_FROM_CAMPAIGN:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case REMOVE_TEAM_FROM_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignTeams: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case REMOVE_TEAM_FROM_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case RESET_CAMPAIGNS:
      return {
        ...state,
        removedList: false,
        addedSuccess: false,
        error: null,
        loading: false,
        success: false,
        successUpdateCampaign: false,
        successCampaignCreate: false,
      };

    case GET_CAMPAIGN_SALES:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_CAMPAIGN_SALES_SUCCESS:
      return {
        ...state,
        campaignSales: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case GET_CAMPAIGN_SALES_FAIL:
      return {
        ...state,
        campaignSales: [],
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_AVAILABLE_DISPOSITIONS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_AVAILABLE_DISPOSITIONS_SUCCESS:
      return {
        ...state,
        availableDispositions: action.payload,
        loading: false,
        success: true,
        error: null,
      };
    case GET_AVAILABLE_DISPOSITIONS_FAIL:
      return {
        ...state,
        availableDispositions: [],
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default campaignsDuck;

export const getCampaigns =
  ({ rowsPerPage, page, values }) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_CAMPAIGNS,
    });
    try {
      const { AuthDuck } = getState();
      let S = values ? "&search=" + values.search : "";
      let P = rowsPerPage ? rowsPerPage : 20;
      let PA = page ? page : 0;
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns?pageNumber=${PA}&limit=${P}${S}`,
        config
      );
      dispatch({
        type: GET_CAMPAIGNS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_CAMPAIGNS_FAIL,
        payload: err.response,
      });
    }
  };

export const getCampaignById = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_CAMPAIGN_BY_ID,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}`,
      config
    );
    dispatch({
      type: GET_CAMPAIGN_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CAMPAIGN_BY_ID_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const createCampaign = (campaign) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_CAMPAIGN,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/callCentre/campaigns`,
      campaign,
      config
    );
    dispatch({
      type: CREATE_CAMPAIGN_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_CAMPAIGN_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const updateCampaign = (campaign, id) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_CAMPAIGN,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}`,
      campaign,
      config
    );
    dispatch({
      type: UPDATE_CAMPAIGN_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_CAMPAIGN_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const getCampaignDispositions = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_CAMPAIGN_DISPOSITIONS,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/lists`,
      config
    );
    dispatch({
      type: GET_CAMPAIGN_DISPOSITIONS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CAMPAIGN_DISPOSITIONS_FAIL,
      payload: err,
    });
  }
};

export const addDispositionToCampaign =
  (list_id, id) => async (dispatch, getState) => {
    dispatch({
      type: ADD_DISPOSITION_TO_CAMPAIGN,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/lists/${list_id}`,
        {},
        config
      );
      dispatch({
        type: ADD_DISPOSITION_TO_CAMPAIGN_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: ADD_DISPOSITION_TO_CAMPAIGN_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const RemoveDispositionListFromCampaign =
  ({ id, list_id }) =>
  async (dispatch, getState) => {
    dispatch({
      type: REMOVE_DISPOSITION_LIST_FROM_CAMPAIGN,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/lists/${list_id}`,
        config
      );
      dispatch({
        type: REMOVE_DISPOSITION_LIST_FROM_CAMPAIGN_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: REMOVE_DISPOSITION_LIST_FROM_CAMPAIGN_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const removeDispositionFromCampaign =
  ({ id, disposition }) =>
  async (dispatch, getState) => {
    dispatch({
      type: REMOVED_DISPOSITION_FROM_CAMPAIGN,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/dispositions/${disposition}`,
        config
      );
      dispatch({
        type: REMOVED_DISPOSITION_FROM_CAMPAIGN_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: REMOVED_DISPOSITION_FROM_CAMPAIGN_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const getCampaignTeams = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_CAMPAIGN_TEAMS,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/teams`,
      config
    );
    dispatch({
      type: GET_CAMPAIGN_TEAMS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CAMPAIGN_TEAMS_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const addTeamToCampaign =
  ({ team_id, id }) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADD_TEAM_TO_CAMPAIGN,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/teams`,
        { team_id },
        config
      );
      dispatch({
        type: ADD_TEAM_TO_CAMPAIGN_SUCCESS,
        payload: data?.data,
      });
    } catch (err) {
      dispatch({
        type: ADD_TEAM_TO_CAMPAIGN_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const removeTeamsFromCampaign =
  ({ id, team_id }) =>
  async (dispatch, getState) => {
    dispatch({
      type: REMOVE_TEAM_FROM_CAMPAIGN,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/teams/${team_id}`,
        config
      );
      dispatch({
        type: REMOVE_TEAM_FROM_CAMPAIGN_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: REMOVE_TEAM_FROM_CAMPAIGN_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const getCampaignSales =
  ({ id, value }) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_CAMPAIGN_SALES,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/LeadsAndSalesByCampaignId?start_date=${value[0]}&end_date=${value[1]}`,
        config
      );
      dispatch({
        type: GET_CAMPAIGN_SALES_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_CAMPAIGN_SALES_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const availableDispositionsOptions =
  (id) => async (dispatch, getState) => {
    dispatch({
      type: GET_AVAILABLE_DISPOSITIONS,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/listsAndDispositions`,
        config
      );
      dispatch({
        type: GET_AVAILABLE_DISPOSITIONS_SUCCESS,
        payload: data?.data,
      });
    } catch (err) {
      dispatch({
        type: GET_AVAILABLE_DISPOSITIONS_FAIL,
        payload: err.response.data.message,
      });
    }
  };
