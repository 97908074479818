import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvaluations } from "./State/QaEvaluations";
import { Alert, LinearProgress, Stack } from "@mui/material";
import QueueEvaluationsList from "./Components/Evaluations/QueueEvaluationsList";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import CreateEvaluationsFromLeadSale from "./Components/Evaluations/CreateEvaluationsFromLeadSale";

const AllEvaluations = () => {
  const dispatch = useDispatch();

  const QAEvals = useSelector((state) => state.QAEvals);

  const { allEvaluations, loading, error, deleteSuccess } = QAEvals;

  useEffect(() => {
    dispatch(getAllEvaluations());
  }, [dispatch]);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Alert severity="error">{JSON.stringify(error)}</Alert>;
  }

  return (
    <Stack>
      <AlertPopup
        open={deleteSuccess}
        severity="success"
        message="Evaluation deleted successfully"
      />

      <CreateEvaluationsFromLeadSale />

      {allEvaluations && allEvaluations.length > 0 && (
        <QueueEvaluationsList queueEvaluations={allEvaluations} />
      )}
    </Stack>
  );
};

export default AllEvaluations;
