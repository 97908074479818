import * as React from "react";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddCircleOutline } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
// import { set } from "lodash";

// setFieldValue updates the formik form state and initialEmails is the initial value of the cc_notifications
export default function AddEmailCC({ setFieldValue, initialEmails }) {
  const [emails, setEmails] = React.useState(initialEmails || "");
  const [inputValue, setInputValue] = React.useState("");
  const [error, setError] = React.useState("");

  // updates the formik form state
  useEffect(() => {
    setFieldValue("cc_notifications", emails);
  }, [emails, setFieldValue]);

  // updates the emails state with the initialEmails
  useEffect(() => {
    if (initialEmails) {
      setEmails(initialEmails);
    }
  }, [initialEmails]);

  const validateEmail = (email) => {
    const domain = "@cdasolutions.co.za";
    const regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    return regex.test(email) && email.endsWith(domain);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue) {
      if (validateEmail(inputValue)) {
      setEmails((prev) => (prev ? `${prev}; ${inputValue}` : inputValue));
      setInputValue("");
      setError("");
    } else {
      setError("Please enter a CDA email address");
    }
    event.preventDefault();
    }
  };

  const handleDelete = (indexToDelete) => () => {
    setEmails((prev) => {
      const emailArray = prev
        .split("; ")
        .filter((_, index) => index !== indexToDelete);
      return emailArray.join("; ");
    });
  };

  const handleAddEmail = () => {
    if (validateEmail(inputValue)) {
      setEmails((prev) => (prev ? `${prev}; ${inputValue}` : inputValue));
      setInputValue("");
      setError("");
    } else {
      setError("Please enter a CDA email address");
    }
    
  };

  return (
    <Stack>
      <>
        {/* {emails.split(", ").map((email, index) => (
          <Chip
            key={index}
            label={email}
            onDelete={handleDelete(index)}
            variant="outlined"
          />
        ))} */}
        <Typography variant="h6" gutterBottom>
          List of CC Emails
        </Typography>
        <List sx={{ width: '100%', maxWidth: 580,}}>
          {emails.split("; ").map((email, index) => (
            <ListItem key={index}sx={{
              borderBottom: '1px solid #e0e0e0',
              '&:hover': {
                bgcolor: '#8080ff',
              },
            }}>
              <ListItemText primary={email} />
              <ListItemIcon>
                <IconButton>
                  <DeleteIcon onClick={handleDelete(index)} />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </>
      <Stack direction="row" spacing={2}>
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          variant="outlined"
          size="medium"
          type="email"
          placeholder="Enter email"
          fullWidth
          label="CC Emails"
          error={!!error}
          helperText={error}
        />
        <Button
          // validation to check if inputVale is emmail (Regex)
          disabled={ !validateEmail(inputValue)
            // !inputValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            //   ? true
            //   : false
          }
          startIcon={<AddCircleOutline />}
          color="primary"
          variant="contained"
          onClick={handleAddEmail}
        >
          Add
        </Button>
      </Stack>
    </Stack>
  );
}
