import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import AddNewClient from "./Components/Clients/AddNewClient";
import ClientsList from "./Components/Clients/ClientsList";
import { getClientsList } from "./State/ClientsDuck";

const Clients = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientsList());
  }, [dispatch]);

  const [viewInactiveClients, setViewInactiveClients] = useState(false);

  const [searched, setSearched] = useState([]);

  const Clients = useSelector((state) => state.Clients);
  const { clientsList, editSuccess, addSuccess, loading } = Clients;

  useEffect(() => {
    setSearched(clientsList);
  }, [clientsList]);

  const inactiveClient = clientsList.filter(
    (client) => client.active === false
  );

  const handleSearch = (e) => {
    const { value } = e.target;
    const filtered = clientsList.filter((client) => {
      return client.name.toLowerCase().includes(value.toLowerCase());
    });
    setSearched(filtered);
  };

  if (loading) {
    return (
      <Stack justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <div>
      <Stack sx={{ mb: 3 }} spacing={2}>
        <AddNewClient />
        <TextField
          fullWidth
          name="search"
          label="Search"
          onChange={handleSearch}
        />
      </Stack>

      <ClientsList list={searched} />

      <Stack sx={{ my: 3 }}>
        <Button
          color="inherit"
          onClick={() => setViewInactiveClients(!viewInactiveClients)}>
          View Inactive Clients
        </Button>
      </Stack>

      {viewInactiveClients && <ClientsList list={inactiveClient} />}

      <AlertPopup
        open={editSuccess}
        message="Client updated successfully"
        severity="success"
      />
      <AlertPopup
        open={addSuccess}
        message="Client created successfully"
        severity="success"
      />
    </div>
  );
};

export default Clients;
