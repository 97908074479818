import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignById } from "./State/CampaingsDuck";
import CampaignFormCreateEdit from "./Components/FormComponents/CampaignFormCreateEdit";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";

const ManageCampaign = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCampaignById(id));
  }, [id, dispatch]);

  const Campaigns = useSelector((state) => state.Campaigns);

  const { campaign, successUpdateCampaign } = Campaigns;

  return (
    <div>
      <CampaignFormCreateEdit campaignData={campaign} />
      <AlertPopup
        open={successUpdateCampaign}
        message="Campaign Updated Successfully"
      />
    </div>
  );
};

export default ManageCampaign;
