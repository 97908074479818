import { Card, CardActionArea, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import MainAppContainer from "../../../../../Components/Containers/MainAppContainer";
import { useNavigate } from "react-router-dom";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ReceiptIcon from "@mui/icons-material/Receipt";

import withAuth from "../../../../../Hoc/withAuth";

const LeadHome = ({ user }) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { id } = useParams();

  const currentPath = pathname.split("/")[5];

  const Paths = [
    {
      name: "Lead",
      location: `${id}/lead`,
      icon: <ReceiptLongIcon />,
    },
    {
      name: "Sales",
      location: `${id}/sales`,
      icon: <ReceiptIcon />,
    },
  ];

  return (
    <MainAppContainer maxWidth="xl" disableGutters>
      <PageHeader
        title="Call Center"
        subheader="Manage Lead, Sales and QA"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Manage Call Center",
            href: "/CDA/callCenter",
          },
        ]}
      />

      <Typography variant="h6">Lead ID : {id}</Typography>

      <Grid container>
        {Paths.map((path, index) => {
          return (
            <Grid key={index} item xs={3}>
              <Card
                sx={
                  `${id}/${currentPath}` === path.location
                    ? { borderStyle: "solid", borderColor: "primary.main" }
                    : null
                }>
                <CardActionArea
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    py: 5,
                  }}
                  onClick={() => {
                    navigate(`${path.location}`);
                  }}>
                  {path.icon}
                  <Typography color="textPrimary">{path.name}</Typography>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Stack sx={{ mt: 4 }}>
        <Outlet />
      </Stack>
    </MainAppContainer>
  );
};

export default withAuth(LeadHome);
