import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { DialogContent, Stack, Typography } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { RESET_QA_TEAMS, updateQaTeam } from "../../State/QaTeamsDuck";

export default function DisableQaTeam({ team }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({ type: RESET_QA_TEAMS });
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button color="error" onClick={handleClickOpen}>
        Disable Team
      </Button>
      <Dialog
        sx={{ border: "3px solid red" }}
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}>
        <DialogTitle>Disable Team</DialogTitle>

        <Formik
          initialValues={{
            ...team,
            active: false,
          }}
          validationSchema={Yup.object({
            active: Yup.boolean().required("Active is required"),
          })}
          onSubmit={(values) => {
            dispatch(updateQaTeam(values));
            handleClose();
          }}>
          {() => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <Typography variant="h6" gutterBottom>
                    {team.name}
                  </Typography>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" color="error" type="submit">
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
