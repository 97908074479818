import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const CampaignsList = ({ campaigns }) => {
  const navigate = useNavigate();
  return (
    <Stack my={1}>
      {campaigns?.map((campaign) => (
        <Card key={campaign.id}>
          <CardHeader title={campaign.campaign_name} />
          <CardActionArea
            onClick={() => navigate(`/crm/crmCampaign/${campaign.id}`)}>
            <CardContent>
              <Typography variant="body1">
                Lead Type : {campaign.lead_type}
              </Typography>
              <Typography variant="body1">
                Campaign Status {campaign.campaign_status}
              </Typography>
              <Typography variant="body1">
                Start Date :
                {new Date(campaign.campaign_start_date).toLocaleDateString()}
              </Typography>
              <Typography variant="body1">
                Duration Months: {campaign.expected_duration}
              </Typography>

              <Typography variant="body1">Notes :</Typography>
              <Typography variant="caption">
                {campaign.campaign_notes}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Stack>
  );
};

export default CampaignsList;
