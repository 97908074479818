import {
  Alert,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import MainAppContainer from "../../../../../Components/Containers/MainAppContainer";
import {
  completeQuestionnaire,
  GetQuestionnaireById,
} from "./State/UsersQuestionnairesDuck";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useCountdown } from "../../../../../Hooks/useCountdown";

import NavigationAlert from "../../../../../Components/Bits/NavigationAlert";
import { getPercentage } from "./Components/ResultFunctions";
import QuestionItem from "./Components/QuestionnaireComps/QuestionItem";

// import NavigationAlert from "../../../Components/Bits/NavigationAlert";
const TakeQuestionnaire = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //check if back button is pressed
  // Prevent Navigation to previous page

  const [prevent, setPrevent] = React.useState(false);

  const { id } = useParams();
  useEffect(() => {
    dispatch(GetQuestionnaireById(id));
  }, [dispatch, id]);

  const UsersQuestionnairesDuck = useSelector(
    (state) => state.UsersQuestionnairesDuck
  );
  const {
    usersQuestionnaire,
    usersQuestionnairesLoading,
    usersQuestionnairesError,
  } = UsersQuestionnairesDuck;

  let currentQuestionnaire = JSON.parse(
    localStorage.getItem("currentQuestionnaire")
  );

  // TODO : Set Result test start time and end time.

  const [QuestionAnswers, setQuestionAnswers] = React.useState([]);

  useEffect(() => {
    if (usersQuestionnaire.QuestionAnswers?.length > 0) {
      setQuestionAnswers(
        usersQuestionnaire.QuestionAnswers.map((question) => ({
          question_id: question.id,
          answer_id: 0,
          _value: null,
          correct_answer_id: question.correct_answer || "",
        }))
      );
    }
  }, [usersQuestionnaire.QuestionAnswers]);

  const handleFinish = () => {
    let passBoy = getPercentage(QuestionAnswers);
    const response = {
      id: currentQuestionnaire.id,
      passed: passBoy >= currentQuestionnaire.pass_percentage ? true : false,
      score: Math.floor(passBoy),
      QuestionAnswers,
    };
    dispatch(completeQuestionnaire(response));
    navigate(`/CDA/QuestionnaireEndPage/${currentQuestionnaire.id}`);
  };

  const testTimeCalc = (currentQuestionnaire) => {
    // Check if test time is set.
    const TOTAL =
      new Date(currentQuestionnaire.endTime).getTime() - new Date().getTime();
    const NOW_IN_MS = new Date().getTime();
    return NOW_IN_MS + TOTAL;
  };

  const CountdownTimer = ({ targetDate }) => {
    const [minutes, seconds] = useCountdown(targetDate);
    if (minutes + seconds <= 0) {
      return (
        <>
          <Typography color="error" variant="h4" gutterBottom align="center">
            Time Expired
          </Typography>
        </>
      );
    } else {
      return (
        <Stack direction="row" justifyContent="center">
          <Typography color="yellow" variant="h4" gutterBottom align="center">
            {minutes}:{seconds}
          </Typography>
        </Stack>
      );
    }
  };

  if (usersQuestionnairesLoading) {
    return (
      <Stack display="flex" justifyContent="center" alignContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (
    !currentQuestionnaire ||
    new Date(currentQuestionnaire?.endTime).getTime() < new Date().getTime()
  ) {
    localStorage.removeItem("currentQuestionnaire");
    return (
      <Stack display="flex" justifyContent="center" alignContent="center">
        <Typography color="error" variant="h4" gutterBottom align="center">
          Time Expired
        </Typography>
      </Stack>
    );
  }

  return (
    <MainAppContainer>
      {prevent && <NavigationAlert setPrevent={setPrevent} />}

      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Stack spacing={2}>
            <Card>
              <CardHeader
                title={
                  usersQuestionnaire?.usersQuestionnaires
                    ?.questionnaire_campaign?.name
                }
                subheader={
                  usersQuestionnaire?.usersQuestionnaires
                    ?.questionnaire_campaign?.description
                }
              />
              <CountdownTimer
                targetDate={
                  currentQuestionnaire && testTimeCalc(currentQuestionnaire)
                }
              />
            </Card>

            {usersQuestionnaire?.QuestionAnswers?.map((question, index) => {
              return (
                <Card key={index} sx={{ px: 2 }}>
                  <QuestionItem
                    index={index}
                    question={question}
                    QuestionAnswers={QuestionAnswers}
                    setQuestionAnswers={setQuestionAnswers}
                  />
                </Card>
              );
            })}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleFinish}>
              Finish
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {usersQuestionnairesError && (
        <Alert severity="error" message={usersQuestionnairesError} />
      )}
    </MainAppContainer>
  );
};

export default TakeQuestionnaire;
