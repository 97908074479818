import { Button, Stack } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import CreateQaTeam from "./Components/Teams/CreateQaTeam";
import QaTeamsList from "./Components/Teams/QaTeamsList";
import { RESET_QA_TEAMS, getQaTeams } from "./State/QaTeamsDuck";

const QATeams = () => {
  const dispatch = useDispatch();

  const [view, setView] = React.useState(false);

  useEffect(() => {
    dispatch({ type: RESET_QA_TEAMS });
    dispatch(getQaTeams());
  }, [dispatch]);

  const QaTeamsDuck = useSelector((state) => state.QaTeamsDuck);
  const { qaTeamsList, qaTeamsListSuccess } = QaTeamsDuck;

  const activeTeams = qaTeamsList.filter((team) => team.active);
  const inactiveTeams = qaTeamsList.filter((team) => !team.active);

  return (
    <Stack spacing={2}>
      <CreateQaTeam />
      <QaTeamsList list={activeTeams} />
      <Button onClick={() => setView(!view)}>
        {view ? "Hide Inactive Teams" : "Show Inactive Teams"}
      </Button>

      {view && <QaTeamsList list={inactiveTeams} />}
      <AlertPopup
        open={qaTeamsListSuccess}
        message="Updated"
        severity="success"
      />
    </Stack>
  );
};

export default QATeams;
