import React, {useState, useMemo} from 'react';
import withAuth from '../../../../../../../../Hoc/withAuth';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Box,
  TextField,
} from "@mui/material";
import { startOfDay } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LineChartt from '../Statistics/LineChart';

const CardsManager = ({CampaignRows, validRows}) => {
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(startOfDay(new Date()));

  const memoizedEvaluations = useMemo(() => validRows, [validRows]);


  // Filter evaluations within the selected date range
  const filteredRows = memoizedEvaluations.filter((row) => {
    const rowDate = new Date(row.updatedAt);
    return rowDate >= startDate && rowDate <= endDate;
  });

  const filteredCampaigns = CampaignRows?.filter((row) => {
    const rowDate = new Date(row.updatedAt);
    return rowDate >= startDate && rowDate <= endDate;
  });



  const totalEvaluations = filteredRows?.length;
  const totalCampaigns = filteredCampaigns?.length;


  const failedCount = filteredRows.filter(
    (row) => row.status === "Failed"
  ).length;
  const failedPercentage =
    totalEvaluations > 0 ? (failedCount / totalEvaluations) * 100 : 0;

  const passedCount = filteredRows.filter(
    (row) => row.status === "Completed"
  ).length;
  const passedPercentage =
    totalEvaluations > 0 ? (passedCount / totalEvaluations) * 100 : 0;

 

  return (
    <div>
      {/* Date Range Pickers */}
      <Stack direction="row" justifyContent="space-between">
      <Stack >
        <Box display="flex" alignItems="center" sx={{ mt: 2, ml: 2, mr: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(startOfDay(newValue))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "200px", margin: "normal" }}
                />
              )}
            />
            <Box mx={2}> - </Box>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(startOfDay(newValue))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "200px", margin: "normal" }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>

        {/* Cards */}
        <Box>
        <Stack direction="column">
          <Stack direction="row" spacing={4} alignItems="center" sx={{ p: 2 }}>
            {/* Pass Rate Card */}
            <Card
              sx={{
                width: 250,
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Typography variant="h6" align="center">
                  Pass Rate (%)
                </Typography>
                <Typography variant="h4" color="primary" align="center">
                  {passedPercentage.toFixed(2)}%
                </Typography>
              </CardContent>
            </Card>

            {/* Failed Rate Card */}
            <Card
              sx={{
                width: 250,
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Typography variant="h6" align="center">
                  Failed Rate (%)
                </Typography>
                <Typography variant="h4" color="primary" align="center">
                  {failedPercentage.toFixed(2)}%
                </Typography>
              </CardContent>
            </Card>
          </Stack>

          <Stack direction="row" spacing={4} alignItems="center" sx={{ p: 2 }}>
            {/* Rejected Rate Card */}
            <Card
              sx={{
                width: 250,
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Typography variant="h6" align="center">
                Total Campaigns
                </Typography>
                <Typography variant="h4" color="primary" align="center">
                  {totalCampaigns}
                </Typography>
              </CardContent>
            </Card>

            {/* Total Evaluations Card */}
            <Card
              sx={{
                width: 250,
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Typography variant="h6" align="center">
                  Total Evaluations
                </Typography>
                <Typography variant="h4" color="primary" align="center">
                  {totalEvaluations}
                </Typography>
              </CardContent>
            </Card>
          </Stack>
          
        </Stack>
        </Box>
        </Stack>
        <LineChartt AllEvaluations={validRows} />
      </Stack>
    </div>
  )
}

export default withAuth(CardsManager);
