import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addTeamToCampaign,
  getCampaignTeams,
  removeTeamsFromCampaign,
} from "./State/CampaingsDuck";
import { getTeamsList } from "./State/TeamsDuck";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const CampaignTeamsManagement = () => {
  const [team_id, setTeam_id] = React.useState("");
  const handleChange = (event) => {
    setTeam_id(event.target.value);
  };

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getCampaignTeams(id));
  }, [id, dispatch]);

  const Campaigns = useSelector((state) => state.Campaigns);

  const { campaignTeams } = Campaigns;

  useEffect(() => {
    dispatch(getTeamsList());
  }, [dispatch]);

  const Teams = useSelector((state) => state.Teams);

  const { teamsList } = Teams;

  const handleSubmit = () => {
    dispatch(addTeamToCampaign({ team_id, id }));
    setTeam_id("");
  };

  const campaignTeamList = teamsList.filter((team) => {
    return campaignTeams.some((campaignTeam) => {
      return campaignTeam.team_id === team.id;
    });
  });

  // filter out the teams that are already in the campaign
  const teamsListFiltered = teamsList.filter((team) => {
    return !campaignTeams.some((campaignTeam) => {
      return campaignTeam.team_id === team.id;
    });
  });

  const activeTeams = teamsListFiltered.filter((team) => {
    return team.active === true;
  });

  return (
    <div>
      <Stack spacing={3}>
        <Card sx={{ mb: 2 }} variant="outlined">
          <CardContent>
            <Stack>
              <Typography color="GrayText" variant="caption">
                Add Teams To Campaign
              </Typography>
            </Stack>
          </CardContent>
        </Card>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Select Team To Add
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={team_id}
            label="Select Team To Add"
            onChange={handleChange}>
            {activeTeams.map((team) => (
              <MenuItem key={team.id} value={team.id}>
                {team.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Button sx={{ my: 3 }} onClick={handleSubmit} variant="contained">
        Save
      </Button>

      <TeamsList id={id} list={campaignTeamList} />
    </div>
  );
};

export default CampaignTeamsManagement;

const TeamsList = ({ list, id }) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Teams</StyledTableCell>
            <StyledTableCell align="right">Members</StyledTableCell>
            <StyledTableCell align="right">Add/Remove Members</StyledTableCell>
            <StyledTableCell align="right">Remove Team</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {row?.name}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row?.members?.length}
              </StyledTableCell>
              <StyledTableCell align="right">
                <Button onClick={() => navigate(`/CDA/TeamsEdit/${row.id}`)}>
                  Edit
                </Button>
              </StyledTableCell>
              <StyledTableCell align="right">
                <RemoveTeam id={id} data={row} />
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const RemoveTeam = ({ data, id }) => {
  const dispatch = useDispatch();

  const team_id = data.id;
  const handleSubmit = () => {
    dispatch(removeTeamsFromCampaign({ id, team_id }));
  };

  return (
    <Button onClick={handleSubmit} color="error">
      Remove
    </Button>
  );
};
