import { Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";

const DateRangeSelector = ({ value, setValue }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography sx={{ mb: 1 }} variant="caption">
        Select Date Range
      </Typography>
      <>
        <DateRangePicker
          value={value}
          onChange={(newValue) => setValue(newValue)}
        />
      </>
    </LocalizationProvider>
  );
};

export default DateRangeSelector;
