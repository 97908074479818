import axios from "axios";

const initialState = {
  targets: [],
  loading: false,
  error: null,
  target: null,
  targetLoading: false,
  targetError: null,
  targetSaving: false,
  targetSaveError: null,
  targetSaveSuccess: false,
  targetDeleteError: null,
  targetDeleteSuccess: false,
};

const GET_ALL_TARGETS = "GET_ALL_TARGETS";
const GET_ALL_TARGETS_SUCCESS = "GET_ALL_TARGETS_SUCCESS";
const GET_ALL_TARGETS_ERROR = "GET_ALL_TARGETS_ERROR";

const GET_TARGET = "GET_TARGET";
const GET_TARGET_SUCCESS = "GET_TARGET_SUCCESS";
const GET_TARGET_ERROR = "GET_TARGET_ERROR";

const CREATE_TARGET = "CREATE_TARGET";
const CREATE_TARGET_SUCCESS = "CREATE_TARGET_SUCCESS";
const CREATE_TARGET_ERROR = "CREATE_TARGET_ERROR";

const EDIT_TARGET = "EDIT_TARGET";
const EDIT_TARGET_SUCCESS = "EDIT_TARGET_SUCCESS";
const EDIT_TARGET_ERROR = "EDIT_TARGET_ERROR";

const DELETE_TARGET = "DELETE_TARGET";
const DELETE_TARGET_SUCCESS = "DELETE_TARGET_SUCCESS";
const DELETE_TARGET_ERROR = "DELETE_TARGET_ERROR";

export const RESET_TARGET_DUCK = "RESET_TARGET_DUCK";

const TargetsDuck = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TARGETS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_TARGETS_SUCCESS:
      return {
        ...state,
        loading: false,
        targets: action.payload.data,
      };
    case GET_ALL_TARGETS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_TARGET:
      return {
        ...state,
        targetLoading: true,
        targetError: null,
      };
    case GET_TARGET_SUCCESS:
      return {
        ...state,
        targetLoading: false,
        target: action.payload,
      };
    case GET_TARGET_ERROR:
      return {
        ...state,
        targetLoading: false,
        targetError: action.payload,
      };

    case CREATE_TARGET:
      return {
        ...state,

        targetSaving: true,
        targetSaveError: null,
        targetSaveSuccess: false,
      };
    case CREATE_TARGET_SUCCESS:
      return {
        ...state,
        targetSaving: false,
        targetSaveSuccess: true,
        targets: state.targets.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };
    case CREATE_TARGET_ERROR:
      return {
        ...state,
        targetSaving: false,
        targetSaveError: action.payload,
      };

    case RESET_TARGET_DUCK:
      return {
        ...state,
        targetSaving: false,
        targetSaveError: null,
        targetSaveSuccess: false,
        targetDeleteError: null,
        targetDeleteSuccess: false,
        editTargetSuccess: false,
        targets: [],
      };

    case EDIT_TARGET:
      return {
        ...state,
        editTargetSuccess: false,
      };

    case EDIT_TARGET_SUCCESS:
      return {
        ...state,
        editTargetSuccess: true,
        targets: state.targets.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };
    case EDIT_TARGET_ERROR:
      return {
        ...state,
        editTargetSuccess: false,
      };

    case DELETE_TARGET:
      return {
        ...state,
        targetDeleteSuccess: false,
        targetDeleteError: null,
      };
    case DELETE_TARGET_SUCCESS:
      return {
        ...state,
        targetDeleteSuccess: true,
        targets: state.targets.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };
    case DELETE_TARGET_ERROR:
      return {
        ...state,
        targetDeleteError: action.payload,
      };

    default:
      return state;
  }
};

export default TargetsDuck;

export const getAllTargets = () => async (dispatch, getState) => {
  dispatch({ type: GET_ALL_TARGETS });
  try {
    const { AuthDuck } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/targets`,
      config
    );
    dispatch({ type: GET_ALL_TARGETS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_TARGETS_ERROR, payload: error });
  }
};

export const createTarget = (target) => async (dispatch, getState) => {
  dispatch({ type: CREATE_TARGET });
  try {
    const { AuthDuck } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/callCentre/targets`,
      target,
      config
    );

    dispatch({ type: CREATE_TARGET_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: CREATE_TARGET_ERROR, payload: error });
  }
};

export const editTarget = (id, target) => async (dispatch, getState) => {
  dispatch({ type: EDIT_TARGET });
  try {
    const { AuthDuck } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/callCentre/targets/${id}`,
      target,
      config
    );

    dispatch({ type: EDIT_TARGET_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: EDIT_TARGET_ERROR, payload: error });
  }
};

export const deleteTarget = (id, user_id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_TARGET });
  try {
    const { AuthDuck } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/callCentre/targets/${id}/${user_id}`,
      config
    );

    dispatch({ type: DELETE_TARGET_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: DELETE_TARGET_ERROR, payload: error });
  }
};
