import axios from "axios";

export const QA_QUESTIONS_FETCH_SUCCESS = "QA_QUESTIONS_FETCH_SUCCESS";
export const QA_QUESTIONS_FETCH_FAILURE = "QA_QUESTIONS_FETCH_FAILURE";
export const QA_QUESTIONS_FETCH_REQUEST = "QA_QUESTIONS_FETCH_REQUEST";

export const QA_QUEUE_QUESTION = "QA_QUEUE_QUESTION";
export const QA_QUEUE_QUESTION_SUCCESS = "QA_QUEUE_QUESTION_SUCCESS";
export const QA_QUEUE_QUESTION_FAILURE = "QA_QUEUE_QUESTION_FAILURE";

export const QA_QUESTIONS_CREATE_SUCCESS = "QA_QUESTIONS_CREATE_SUCCESS";
export const QA_QUESTIONS_CREATE_FAILURE = "QA_QUESTIONS_CREATE_FAILURE";
export const QA_QUESTIONS_CREATE_REQUEST = "QA_QUESTIONS_CREATE_REQUEST";

export const QA_QUESTIONS_UPDATE_SUCCESS = "QA_QUESTIONS_UPDATE_SUCCESS";
export const QA_QUESTIONS_UPDATE_FAILURE = "QA_QUESTIONS_UPDATE_FAILURE";
export const QA_QUESTIONS_UPDATE_REQUEST = "QA_QUESTIONS_UPDATE_REQUEST";

export const QA_QUESTIONS_DELETE_SUCCESS = "QA_QUESTIONS_DELETE_SUCCESS";
export const QA_QUESTIONS_DELETE_FAILURE = "QA_QUESTIONS_DELETE_FAILURE";
export const QA_QUESTIONS_DELETE_REQUEST = "QA_QUESTIONS_DELETE_REQUEST";

const ADD_ANSWER_TO_QUESTION_REQUEST = "ADD_ANSWER_TO_QUESTION_REQUEST";
const ADD_ANSWER_TO_QUESTION_SUCCESS = "ADD_ANSWER_TO_QUESTION_SUCCESS";
const ADD_ANSWER_TO_QUESTION_FAILURE = "ADD_ANSWER_TO_QUESTION_FAILURE";

const EDIT_ANSWER_TO_QUESTION_REQUEST = "EDIT_ANSWER_TO_QUESTION_REQUEST";
const EDIT_ANSWER_TO_QUESTION_SUCCESS = "EDIT_ANSWER_TO_QUESTION_SUCCESS";
const EDIT_ANSWER_TO_QUESTION_FAILURE = "EDIT_ANSWER_TO_QUESTION_FAILURE";

const EDIT_QA_QUEUE_QUESTION_REQUEST = "EDIT_QA_QUEUE_QUESTION_REQUEST";
const EDIT_QA_QUEUE_QUESTION_SUCCESS = "EDIT_QA_QUEUE_QUESTION_SUCCESS";
const EDIT_QA_QUEUE_QUESTION_FAILURE = "EDIT_QA_QUEUE_QUESTION_FAILURE";

const CREATE_QA_QUEUE_QUESTION_REQUEST = "CREATE_QA_QUEUE_QUESTION_REQUEST";
const CREATE_QA_QUEUE_QUESTION_SUCCESS = "CREATE_QA_QUEUE_QUESTION_SUCCESS";
const CREATE_QA_QUEUE_QUESTION_FAILURE = "CREATE_QA_QUEUE_QUESTION_FAILURE";

const ADD_ANSWER_TO_QUEUE_QUESTION_REQUEST =
  "ADD_ANSWER_TO_QUEUE_QUESTION_REQUEST";
const ADD_ANSWER_TO_QUEUE_QUESTION_SUCCESS =
  "ADD_ANSWER_TO_QUEUE_QUESTION_SUCCESS";
const ADD_ANSWER_TO_QUEUE_QUESTION_FAILURE =
  "ADD_ANSWER_TO_QUEUE_QUESTION_FAILURE";

const EDIT_ANSWER_TO_QUEUE_QUESTION_REQUEST =
  "EDIT_ANSWER_TO_QUEUE_QUESTION_REQUEST";
const EDIT_ANSWER_TO_QUEUE_QUESTION_SUCCESS =
  "EDIT_ANSWER_TO_QUEUE_QUESTION_SUCCESS";
const EDIT_ANSWER_TO_QUEUE_QUESTION_FAILURE =
  "EDIT_ANSWER_TO_QUEUE_QUESTION_FAILURE";

const QA_QUEUE_QUESTION_REORDER_SUCCESS = "QA_QUESTIONS_CREATE_RESET";
export const QA_QUESTIONS_CREATE_RESET = "QA_QUESTIONS_CREATE_RESET";

const initialState = {
  loading: false,
  error: null,
  questions: [],
  question: {},
  queue_question: [],
  success: false,
  createQuestionSuccess: false,
};

export const qaQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case QA_QUESTIONS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        addAnswerSuccess: false,
      };
    case QA_QUESTIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        questions: action.payload,
      };
    case QA_QUESTIONS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case QA_QUESTIONS_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case QA_QUESTIONS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        createQuestionSuccess: true,
        questions: [...state.questions, { ...action.payload, qa_answers: [] }],
      };
    case QA_QUESTIONS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case QA_QUESTIONS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case QA_QUESTIONS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        editSuccess: true,
        questions: state.questions.map((question) => {
          return question.id === action.payload.id
            ? { ...action.payload }
            : question;
        }),
      };

    case QA_QUESTIONS_UPDATE_FAILURE:
      return {
        ...state,
        editSuccess: false,
        loading: false,
        error: action.payload,
      };
    case QA_QUESTIONS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case QA_QUESTIONS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        question: action.payload,
      };
    case QA_QUESTIONS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case QA_QUEUE_QUESTION:
      return {
        ...state,
        question_updated: false,
        loading: true,
        error: null,
      };
    case QA_QUEUE_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        orderSuccess: false,
        queue_question: action.payload?.sort((a, b) => a.order - b.order),
      };
    case QA_QUEUE_QUESTION_REORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        orderSuccess: true,
        queue_question: action.payload?.sort((a, b) => a.order - b.order),
      };
    case QA_QUEUE_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        orderSuccess: false,
        error: action.payload,
      };

    case CREATE_QA_QUEUE_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        createQuestionSuccess: false,
      };
    case CREATE_QA_QUEUE_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        createQuestionSuccess: true,
        queue_question: [...state.queue_question, action.payload],
      };
    case CREATE_QA_QUEUE_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EDIT_QA_QUEUE_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        question_updated: false,
      };
    case EDIT_QA_QUEUE_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        question_updated: true,
        queue_question: [
          ...state.queue_question.filter(
            (question) => question.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    case EDIT_QA_QUEUE_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        question_updated: false,
      };

    case ADD_ANSWER_TO_QUESTION_REQUEST:
      return {
        ...state,
        addAnswerSuccess: false,

        loading: true,
        error: null,
      };
    case ADD_ANSWER_TO_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        addAnswerSuccess: true,
        error: null,
        questions: state.questions.map((question) => {
          if (question.id === action?.payload?.question_id) {
            return {
              ...question,
              qa_answers: [...question?.qa_answers, action.payload],
            };
          } else {
            return question;
          }
        }),
      };

    case ADD_ANSWER_TO_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EDIT_ANSWER_TO_QUESTION_REQUEST:
      return {
        ...state,
        addAnswerSuccess: false,
      };
    case EDIT_ANSWER_TO_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        addAnswerSuccess: true,
        error: null,
        questions: state.questions.map((question) => {
          if (question.id === action?.payload?.question_id) {
            return {
              ...question,
              qa_answers: question?.qa_answers.map((answer) => {
                if (answer.id === action?.payload?.id) {
                  return action?.payload;
                } else {
                  return answer;
                }
              }),
            };
          } else {
            return question;
          }
        }),
      };
    case EDIT_ANSWER_TO_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_ANSWER_TO_QUEUE_QUESTION_REQUEST:
      return {
        ...state,
        addAnswerSuccess: false,

        loading: true,
        error: null,
      };
    case ADD_ANSWER_TO_QUEUE_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        addAnswerSuccess: true,
        error: null,
        queue_question: state.queue_question.map((question) => {
          if (question.id === action?.payload?.question_id) {
            return {
              ...question,
              qa_queue_question_answers: [
                ...question?.qa_queue_question_answers,
                action.payload,
              ],
            };
          } else {
            return question;
          }
        }),
      };
    case ADD_ANSWER_TO_QUEUE_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case QA_QUESTIONS_CREATE_RESET:
      return {
        ...state,
        editSuccess: false,
        success: false,
        createQuestionSuccess: false,
        addAnswerSuccess: false,
        question_updated: false,
      };

    case EDIT_ANSWER_TO_QUEUE_QUESTION_REQUEST:
      return {
        ...state,
        addAnswerSuccess: false,
      };
    case EDIT_ANSWER_TO_QUEUE_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        addAnswerSuccess: true,
        error: null,
        queue_question: state.queue_question.map((question) => {
          if (question.id === action?.payload?.question_id) {
            return {
              ...question,
              qa_queue_question_answers:
                question?.qa_queue_question_answers.map((answer) => {
                  if (answer.id === action?.payload?.id) {
                    return action?.payload;
                  } else {
                    return answer;
                  }
                }),
            };
          } else {
            return question;
          }
        }),
      };
    case EDIT_ANSWER_TO_QUEUE_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getQaQuestion = () => async (dispatch, getState) => {
  dispatch({
    type: QA_QUESTIONS_FETCH_REQUEST,
  });

  const { AuthDuck } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${AuthDuck.userInfo.token}`,
    },
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/questions`,

      config
    );
    dispatch({
      type: QA_QUESTIONS_FETCH_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: QA_QUESTIONS_FETCH_FAILURE,
      payload: error.message,
    });
  }
};

export const createQaQuestion = (question) => async (dispatch, getState) => {
  dispatch({
    type: QA_QUESTIONS_CREATE_REQUEST,
  });

  const { AuthDuck } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${AuthDuck.userInfo.token}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/qa/questions`,
      question,
      config
    );
    dispatch({
      type: QA_QUESTIONS_CREATE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: QA_QUESTIONS_CREATE_FAILURE,
      payload: error.message,
    });
  }
};

export const updateQaQuestion = (question) => async (dispatch, getState) => {
  dispatch({
    type: QA_QUESTIONS_UPDATE_REQUEST,
  });

  const { AuthDuck } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${AuthDuck.userInfo.token}`,
    },
  };

  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/qa/questions/${question.id}`,
      question,
      config
    );
    dispatch({
      type: QA_QUESTIONS_UPDATE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: QA_QUESTIONS_UPDATE_FAILURE,
      payload: error.message,
    });
  }
};

export const deleteQaQuestion = (id) => async (dispatch, getState) => {
  dispatch({
    type: QA_QUESTIONS_DELETE_REQUEST,
  });

  const { AuthDuck } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${AuthDuck.userInfo.token}`,
    },
  };

  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/qa/questions/${id}`,
      config
    );
    dispatch({
      type: QA_QUESTIONS_DELETE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: QA_QUESTIONS_DELETE_FAILURE,
      payload: error.message,
    });
  }
};

export const getQaQueueQuestions = (id) => async (dispatch, getState) => {
  dispatch({
    type: QA_QUEUE_QUESTION,
  });

  const { AuthDuck } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${AuthDuck.userInfo.token}`,
    },
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/queues/${id}/questions`,

      config
    );
    dispatch({
      type: QA_QUEUE_QUESTION_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: QA_QUEUE_QUESTION_FAILURE,
      payload: error.message,
    });
  }
};
export const createSingleQaQueueQuestion =
  (id, values) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_QA_QUEUE_QUESTION_REQUEST,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/questions`,
        values,
        config
      );
      dispatch({
        type: CREATE_QA_QUEUE_QUESTION_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_QA_QUEUE_QUESTION_FAILURE,
        payload: error.message,
      });
    }
  };

export const reOrderQaQueueQuestions =
  (id, values) => async (dispatch, getState) => {
    dispatch({
      type: QA_QUEUE_QUESTION,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    try {
      const { data } = await axios.patch(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/questions`,
        values,
        config
      );
      dispatch({
        type: QA_QUEUE_QUESTION_REORDER_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: QA_QUEUE_QUESTION_FAILURE,
        payload: error.message,
      });
    }
  };

export const editQaQueueQuestionRequest =
  ({ queue_id, question_id, values }) =>
  async (dispatch, getState) => {
    dispatch({
      type: EDIT_QA_QUEUE_QUESTION_REQUEST,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    try {
      const { data } = await axios.patch(
        `${process.env.REACT_APP_API_URL}/qa/queues/${queue_id}/questions/${question_id}`,
        values,
        config
      );
      dispatch({
        type: EDIT_QA_QUEUE_QUESTION_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_QA_QUEUE_QUESTION_FAILURE,
        payload: error.message,
      });
    }
  };

export const addQuestionToQaQueue =
  (id, question) => async (dispatch, getState) => {
    dispatch({
      type: QA_QUEUE_QUESTION,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/questions`,
        question,
        config
      );
      dispatch({
        type: QA_QUEUE_QUESTION_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: QA_QUEUE_QUESTION_FAILURE,
        payload: error.message,
      });
    }
  };

export const addAnswerToQaQuestion =
  ({ id, answer }) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADD_ANSWER_TO_QUESTION_REQUEST,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/qa/questions/${id}/answer`,
        answer,
        config
      );
      dispatch({
        type: ADD_ANSWER_TO_QUESTION_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: ADD_ANSWER_TO_QUESTION_FAILURE,
        payload: error.message,
      });
    }
  };

export const editQaQuestionAnswer = (answer) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_ANSWER_TO_QUESTION_REQUEST,
  });

  const { AuthDuck } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${AuthDuck.userInfo.token}`,
    },
  };

  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/qa/questions/${answer.id}/answer`,
      answer,
      config
    );
    dispatch({
      type: EDIT_ANSWER_TO_QUESTION_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: EDIT_ANSWER_TO_QUESTION_FAILURE,
      payload: error.message,
    });
  }
};

export const addAnswerToQaQueueQuestion =
  ({ queue_id, question_id, values }) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADD_ANSWER_TO_QUEUE_QUESTION_REQUEST,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/qa/queues/${queue_id}/questions/${question_id}/answers`,
        values,
        config
      );
      dispatch({
        type: ADD_ANSWER_TO_QUEUE_QUESTION_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: ADD_ANSWER_TO_QUEUE_QUESTION_FAILURE,
        payload: error.message,
      });
    }
  };

export const editQaQueueQuestionAnswer =
  ({ queue_id, question_id, values }) =>
  async (dispatch, getState) => {
    dispatch({
      type: EDIT_ANSWER_TO_QUEUE_QUESTION_REQUEST,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/qa/queues/${queue_id}/questions/${question_id}/answers/${values.id}`,
        values,
        config
      );
      dispatch({
        type: EDIT_ANSWER_TO_QUEUE_QUESTION_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_ANSWER_TO_QUEUE_QUESTION_FAILURE,
        payload: error.message,
      });
    }
  };
