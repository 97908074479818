import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import AddDispositionsDialog from "./Components/Dispositions/AddDispositionsDialog";
import DispositionsList from "./Components/Dispositions/DispositionsList";
import DispositionDetailsList from "./Components/Dispositions/DispositionDetailsList";
import AddDispositionOptions from "./Components/Dispositions/AddDispositionOption";
import { useDispatch, useSelector } from "react-redux";
import {
  getDispositionDetailsList,
  getDispositionListList,
  getDispositions,
  RESET_DISPOSITIONS,
} from "./State/DispostionsDuck";
import DispositionListTable from "./Components/Dispositions/DispositionListTable";
import AddDispositionList from "./Components/Dispositions/AddDispositionList";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";

const Dispositions = () => {
  const __window = window;

  const [select, setSelect] = React.useState(
    __window.location.search === "?1" ? 1 : 0
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: RESET_DISPOSITIONS });
    dispatch(getDispositionListList());
    dispatch(getDispositionDetailsList());
    dispatch(getDispositions());
  }, [dispatch]);

  const Dispositions = useSelector((state) => state.Dispositions);

  const { dispositions, disposition_details, disposition_list, editList } =
    Dispositions;

  const [viewDeactivated, setViewDeactivated] = React.useState(false);

  let activeList = dispositions.filter(
    (disposition) => disposition.active === true
  );

  let inactiveList = dispositions.filter(
    (disposition) => disposition.active === false
  );

  let activeDispositionDetails = disposition_details.filter(
    (disposition) => disposition.active === true
  );

  let active_list = disposition_list.filter(
    (disposition) => disposition.active === true
  );

  let inactive_list = disposition_list.filter(
    (disposition) => disposition.active === false
  );

  return (
    <>
      <AlertPopup
        open={editList}
        title="Edit Disposition List"
        message="Disposition List has been updated successfully"
      />
      <Card sx={{ mb: 2 }} variant="outlined">
        <CardContent>
          <Stack>
            <Typography color="GrayText" variant="caption">
              Dispositions are used to categorize the outcome of a call. You can
              create a list of dispositions and assign them to a campaign.
            </Typography>
            <Typography color="GrayText" variant="caption">
              Lists are used to group dispositions together. You can create a
              list of dispositions and assign them to a campaign.
            </Typography>
            <Typography color="GrayText" variant="caption">
              Dispositions can be added to a list by clicking on the "Add
              Dispositions" button.
            </Typography>
          </Stack>
        </CardContent>
      </Card>

      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Card
          variant="outlined"
          sx={{
            width: "100%",
            borderColor: select === 0 ? "warning.main" : "",
          }}>
          <CardActionArea onClick={() => setSelect(0)}>
            <CardHeader title="Dispositions (List)" />
          </CardActionArea>
        </Card>
        <Card
          variant="outlined"
          sx={{
            width: "100%",
            borderColor: select === 1 ? "primary.main" : "",
          }}>
          <CardActionArea onClick={() => setSelect(1)}>
            <CardHeader title="Add Dispositions" />
          </CardActionArea>
        </Card>
        <Card
          variant="outlined"
          sx={{
            width: "100%",
            borderColor: select === 2 ? "secondary.main" : "",
          }}>
          <CardActionArea onClick={() => setSelect(2)}>
            <CardHeader title="Dispositions Details (preset)" />
          </CardActionArea>
        </Card>
      </Stack>

      {select === 0 && (
        <>
          <Stack sx={{ mb: 3 }}>
            <AddDispositionList />
          </Stack>

          <DispositionListTable
            disposition_list={active_list}
            activeDispositions={activeList}
            activeDispositionDetails={activeDispositionDetails}
          />

          <Stack sx={{ my: 3 }}>
            <Button onClick={() => setViewDeactivated(!viewDeactivated)}>
              View inactive List
            </Button>
          </Stack>
          {viewDeactivated && (
            <>
              <DispositionListTable
                disposition_list={inactive_list}
                activeDispositions={activeList}
                activeDispositionDetails={activeDispositionDetails}
              />
            </>
          )}
        </>
      )}
      {select === 1 && (
        <>
          <Stack sx={{ my: 3 }}>
            <AddDispositionsDialog />
          </Stack>
          <Card sx={{ mb: 2 }} variant="outlined">
            <CardContent>
              <Stack>
                <Typography color="GrayText" variant="caption">
                  Dispositions needs to be added to a list to be used in a
                  campaign.
                </Typography>
                <Typography color="GrayText" variant="caption">
                  - Dispositions can have a child disposition (Disposition
                  detail). For example, if you have a disposition called "Not
                  Interested" you can have a child disposition called "Not
                  Interested - Price" and another child disposition called "Not
                  Interested - Product".
                </Typography>
                <Typography color="GrayText" variant="caption">
                  - Disposition Details can be added by expanding the parent
                  disposition and clicking on the "Add Disposition Detail"
                  button.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <DispositionsList
            dispositions={activeList}
            activeDispositionDetails={activeDispositionDetails}
          />
          <Stack sx={{ my: 3 }}>
            <Button onClick={() => setViewDeactivated(!viewDeactivated)}>
              View inactive dispositions
            </Button>
          </Stack>

          {viewDeactivated && (
            <>
              <DispositionsList
                activeDispositionDetails={activeDispositionDetails}
                dispositions={inactiveList}
              />
            </>
          )}
        </>
      )}
      {select === 2 && (
        <>
          <Stack sx={{ mb: 3 }}>
            <AddDispositionOptions />
          </Stack>
          <Card sx={{ mb: 2 }} variant="outlined">
            <CardContent>
              <Stack>
                <Typography color="GrayText" variant="caption">
                  This is a list of preset disposition details that can be
                  assigned to dispositions.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <DispositionDetailsList active={true} />
          <Stack sx={{ my: 3 }}>
            <Button onClick={() => setViewDeactivated(!viewDeactivated)}>
              View inactive disposition Details
            </Button>
          </Stack>

          {viewDeactivated && (
            <>
              <DispositionDetailsList active={false} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Dispositions;
