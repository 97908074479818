import React, { useEffect } from "react";
import { Button, Grid, LinearProgress, Stack } from "@mui/material";
import { Box } from "@mui/system";
import {
  EditUserRequest,
  getUsersOfModule,
} from "../../../Redux/Ducks/AdminUserManagement";
import { useDispatch, useSelector } from "react-redux";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import * as yup from "yup";
import { Form, Formik } from "formik";
import SelectWrapper from "../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import { getAllDepartments } from "../../../Redux/Payspace/Actions/managerActions";
import AddEmailCC from "./AddEmailCC";

const UserForm = ({ id, User, success, error, loading }) => {
  const dispatch = useDispatch();

  const payspace = useSelector((state) => state.payspace);
  const { departments } = payspace?.department;

  const AdminUsers = useSelector((state) => state.AdminUsers);
  const { usersOfModule } = AdminUsers;

  useEffect(() => {
    dispatch(getAllDepartments());
    dispatch(getUsersOfModule("call-center-manager"));
  }, [dispatch]);

  const Managers =
    usersOfModule &&
    usersOfModule[0]?.Modules_users?.map((user) => {
      return user.User;
    });

  // check if user has call center user module
  const callCentreUser = User?.Modules_users?.map((module) => {
    return module?.Module?.name;
  }).find((module) => {
    return module === "call-center-user";
  })
    ? true
    : false;

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Stack>
        <Formik
          initialValues={{
            name: User?.name || "",
            email: User?.email || "",
            surname: User?.surname || "",
            contact_number: User?.contact_number || "",
            department: User?.department || "",
            manager: User?.manager || "",
            employee_no: User?.employee_no || "",
            id_number: User?.id_number || "",
            cc_notifications: User?.cc_notifications || "",
          }}
          enableReinitialize={true}
          validationSchema={yup.object({
            name: yup.string().required("Name is required"),
            surname: yup.string().required("Surname is required"),
            email: yup
              .string()
              .email("Invalid email")
              .required("Email is required"),
            contact_number: yup
              .string()
              .min(9, "Too Short")
              .max(11, "Too Long")
              .required("Contact number is required"),
            manager: yup.string().when("department", {
              is: (department) =>
                callCentreUser === true && Managers.length > 0,
              then: yup.string().required("Manager is required"),
            }),
            employee_no: yup.string().required("Employee number is required"),
            id_number: yup
              .string()
              .min(13, "Too Short")
              .max(13, "Too Long")
              .required("ID number is required"),
            // cc_notifications: yup
            //   .string()
            //   .required("CC Notifications is required")
            //   .email("Invalid email"),
          })}
          onSubmit={(values) => {
            console.log(values.department);
            values.email = values.email.toLowerCase();
            dispatch(EditUserRequest({ id, values }));
          }}
        >
          {({ setFieldValue, errors, values }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper name="name" label="Name" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper name="surname" label="Surname" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper name="email" label="Email" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name="contact_number"
                      label="Contact Number"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name="employee_no"
                      label="Employee Number"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper name="id_number" label="ID Number" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AddEmailCC
                      setFieldValue={setFieldValue}
                      initialEmails={User?.cc_notifications || ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {departments && departments?.length > 0 && (
                      <SelectWrapper
                        name="department"
                        label="Department"
                        options={departments?.map((dep) => {
                          return {
                            value: dep.id,
                            label: dep.department_name,
                          };
                        })}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {Managers && Managers.length > 0 && callCentreUser && (
                      <SelectWrapper
                        name="manager"
                        label="Team Leader"
                        options={Managers?.map((manager) => {
                          return {
                            value: manager.id,
                            label: `${manager.name} ${manager.surname}`,
                          };
                        })}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {JSON.stringify(errors)}
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </Button>
              </Form>
            );
          }}
        </Formik>

        <Box>
          <AlertPopup
            severity="success"
            open={success}
            message="Updated successfully"
          />
          <AlertPopup
            severity="error"
            open={error}
            message="Error updating user"
          />
          <AlertPopup severity="error" open={error}>
            error
          </AlertPopup>
        </Box>
      </Stack>
    </>
  );
};

export default UserForm;
