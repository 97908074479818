import axios from "axios";

const initialState = {
  user: null,
  loading: false,
  error: null,
  success: null,
};

const GET_PROFILE = "GET_PROFILE";
const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

const EDIT_PROFILE_REQUEST = "EDIT_PROFILE_REQUEST";
const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
const EDIT_PROFILE_FAILURE = "EDIT_PROFILE_FAILURE";

const UserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        user: action.payload,
      };
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };

    case EDIT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: true,
        user: action.payload,
      };
    case EDIT_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        editSuccess: false,
      };

    default:
      return state;
  }
};
export default UserProfileReducer;

export const getProfile = () => async (dispatch, getState) => {
  dispatch({ type: GET_PROFILE });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/profile`,
      config
    );
    dispatch({ type: GET_PROFILE_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: GET_PROFILE_FAILURE, payload: err.response.data });
  }
};

export const editProfile = (values) => async (dispatch, getState) => {
  dispatch({ type: EDIT_PROFILE_REQUEST });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/profile`,
      values,
      config
    );
    dispatch({ type: EDIT_PROFILE_SUCCESS, payload: data.data });
  } catch (err) {
    dispatch({ type: EDIT_PROFILE_FAILURE, payload: err.response.data });
  }
};
