import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DisableQaTeam from "./DisableQaTeam";
import EnableQaTeam from "./EnableQaTeam";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const QaTeamsList = ({ list }) => {
  const navigate = useNavigate();

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Manage Team</StyledTableCell>
              <StyledTableCell align="right">Disable/Activate</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list.length > 0 &&
              list?.map((row) => {
                return (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate(`/CDA/QATeams/${row.id}`)}>
                        Manage Team
                      </Button>
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      {row.active ? (
                        <DisableQaTeam team={row} />
                      ) : (
                        <EnableQaTeam team={row} />
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default QaTeamsList;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
