import { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@mui/material";


import OEBasicInfo from './BasicInfo'
import OEAddressInfo from './AddressInfo'
import OETaxInfo from './TaxInfo'
import OEBankInfo from './BankInfo'
import OEAttachments from './Attachments'
import OEFinish from './Finish'
import OEHROnboarding from './HROnboarding'

import withAuth from "../../../Hoc/withAuth";
import { useNavigate, useLocation } from 'react-router-dom'
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
};

const OERegister = ({ modules }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const urlEmployeeId = new URLSearchParams(search).get("employee_id");
  let permissions = 'read/write'
  let disabled = false
  let employee_id = localStorage.getItem("employeeId");

  if(urlEmployeeId !== employee_id){
    localStorage.removeItem("employeeId");
    localStorage.setItem("employeeId", urlEmployeeId);
    employee_id = localStorage.getItem("employeeId");
  } 

  if (urlEmployeeId) {
    if(modules?.includes('oe-hr')){
      permissions = 'read/write'
    }else{
      permissions = 'read'
      localStorage.setItem("employeeId", urlEmployeeId);
      employee_id = urlEmployeeId;
    }
  } else {
    if (!employee_id) {
      navigate("/otp");
    }
  }

  if(permissions === 'read'){
    disabled = true
  }


  // get employee information
  const { data } = useQuery(
    ["employeeInfo"],
    async () =>
      await fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/employees/${employee_id}`,
      ), 
      {
        refetchOnWindowFocus: true,
      }
  );

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabChange = (newTabValue) => {
    setValue(newTabValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Employee Form Tabs"
        >
            <Tab label="Basic Information" />
            <Tab label="Address Information" />
            <Tab label="Tax Information" />
            <Tab label="Bank Information" />
            <Tab label="Attachments" />
            { !modules?.includes('oe-hr') && <Tab label="Finish" /> }
            { modules?.includes('oe-hr') && <Tab label="HR Onboarding" /> }
        </Tabs>
      </Box>

        <TabPanel value={value} index={0}>
          <OEBasicInfo
            employee_id={employee_id}
            BasicInfo={data?.data}
            submitted={data?.data?.completed}
            handleTabChange={handleTabChange}
            disabled={disabled}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OEAddressInfo
            employee_id={employee_id}
            AddressInfo={data?.data?.Addresses || []}
            submitted={data?.data?.completed}
            handleTabChange={handleTabChange}
            disabled={disabled}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <OETaxInfo
            employee_id={employee_id}
            TaxInfo={data?.data?.TaxInfo}
            submitted={data?.data?.completed}
            handleTabChange={handleTabChange}
            disabled={disabled}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <OEBankInfo
            employee_id={employee_id}
            BankInfo={data?.data?.BankInfo}
            submitted={data?.data?.completed}
            handleTabChange={handleTabChange}
            disabled={disabled}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <OEAttachments
            employee_id={employee_id}
            Attachments={data?.data?.Attachements}
            submitted={data?.data?.completed}
            handleTabChange={handleTabChange}
            disabled={disabled}
          />
        </TabPanel>
    
        { !modules?.includes('oe-hr') && <TabPanel value={value} index={5}>
          <OEFinish value={value} index={5}
            employee_id={employee_id}
            completed={data?.data?.completed}
            submitted={data?.data?.completed}
            disabled={disabled}
          />
        </TabPanel> } 

        { modules?.includes('oe-hr') && 
          <TabPanel value={value} index={5}>
            <OEHROnboarding
              employee_id={employee_id}
              submitted={data?.data?.completed}
              InternalInfo={data?.data?.InternalInformation}
              handleTabChange={handleTabChange}
              EmployeeInfo={data?.data}
              disabled={disabled} />
          </TabPanel> }  
    </Box>
  );
}

export default withAuth(OERegister)