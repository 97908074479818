import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const SetNewAgent = ({ teams, user, setUser, disabled }) => {
  const handleChange = (event) => {
    setUser("user_id", event.target.value);
  };

  const teamMembers = [];
  teams?.forEach((team) => {
    return (
      team?.qa_team?.qa_teams_users?.length > 0 &&
      teamMembers.push(...team?.qa_team?.qa_teams_users)
    );
  });

  let memberSet = new Set();

  const uniqueMembers = teamMembers.filter((member) => {
    const duplicate = memberSet.has(member.user_id);
    memberSet.add(member.user_id);
    return !duplicate;
  });

  return (
    <FormControl sx={{ my: 2 }} fullWidth>
      <InputLabel id="demo-simple-select-label">Select User</InputLabel>
      <Select
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={user}
        label="Select User"
        onChange={handleChange}>
        {uniqueMembers.map((team) => (
          <MenuItem key={team.user_id} value={team?.User?.id}>
            {team?.User?.name} {team?.User?.surname}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SetNewAgent;
