import { Button, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import React from "react";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import AddTaskTwoToneIcon from "@mui/icons-material/AddTaskTwoTone";
import { CreateSurveyAnswer } from "../../State/SurveysDuck";

export default function CreateAnswers({ question_id }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      answer: "",
      description: "",
      value: "",
      active: true,
      survey_campaigns_questions_id: question_id,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      answer: Yup.string().required("A Answer is required"),

      value: Yup.string().required("A Value is required"),
    }),

    onSubmit: async (values) => {
      dispatch(CreateSurveyAnswer(values));
    },
  });

  const handleClose = () => {
    setOpen(false);

    window.location.reload();
  };

  return (
    <div>
      <Tooltip title={"Add answers"}>
        <IconButton onClick={handleClickOpen} color="primary" edge="end">
          <AddTaskTwoToneIcon />
        </IconButton>
      </Tooltip>

      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Create Answer</DialogTitle>

        <form onSubmit={formik.handleSubmit}>
          <Stack sx={{ p: 2 }}>
            <TextField
              margin="normal"
              label="Answer"
              name="answer"
              value={formik.values.answer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.answer && formik.errors.answer ? true : false
              }
              helperText={formik.touched.answer && formik.errors.answer}
            />
            <TextField
              margin="normal"
              label="Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && formik.errors.description
                  ? true
                  : false
              }
              helperText="optional *"
            />
            <TextField
              margin="normal"
              label="Value"
              name="value"
              value={formik.values.value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.value && formik.errors.value ? true : false}
              helperText={formik.touched.value && formik.errors.value}
            />
          </Stack>
          <Stack>
            <Button sx={{ mt: 4 }} type="submit" variant="contained">
              Create
            </Button>
            <Button color="inherit" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </form>
      </Dialog>
    </div>
  );
}
