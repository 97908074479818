import { Card, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { RadioQuestionComponent, TextFieldWrapper } from "./FormComponents";

const SectionComponent = ({
  data,
  section,
  sectionTitle,
  sectionTotal,
  disabled,
}) => {
  return (
    <>
      {data?.map((question, index) => {
        return (
          <React.Fragment key={index}>
            {question.type === "radio" && (
              <Stack key={index}>
                <RadioQuestionComponent
                  disabled={disabled}
                  section={section}
                  index={index}
                  question={question}
                />
              </Stack>
            )}
            <Divider />

            {question.type === "text" && (
              <TextFieldWrapper
                disabled={disabled}
                section={section}
                key={index}
                index={index}
                question={question}
              />
            )}
          </React.Fragment>
        );
      })}
      {sectionTotal && (
        <Card variant="outlined" sx={{ p: 1, mt: 2 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={6}>
            <Typography variant="body1">{sectionTitle} Total</Typography>

            <Typography variant="body1">{sectionTotal}%</Typography>
          </Stack>
        </Card>
      )}
    </>
  );
};

export default SectionComponent;
