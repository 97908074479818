import { Stack, Grid } from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AddEmployeeModal from "../modals/AddEmployeeModal";

import withAuth from "../../../Hoc/withAuth";
import EmployeeTable from "../Components/EmployeeTable";


const OECEmployee = () => {

  return (
    <>
      <Stack spacing={3} sx={{ mb: 5 }}>
        <Grid item xs={12} md={12}>
            <AddEmployeeModal
              text={"Add Employee"}
              startIcon={<PersonAddAlt1Icon />}
              color="secondary"
            />
        </Grid>
        

        <div style={{ height: "70vh", width: "100%", mt: 3 }}>
          <EmployeeTable module="ceo" />
        </div>
      </Stack>
    </>
  );
};

export default withAuth(OECEmployee);
