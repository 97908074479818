import * as React from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { editDispositionDetails } from "../../State/DispostionsDuck";

export default function ActivateOrDisableDispositionDetail({
  disposition_id,
  data,
}) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    data.active = !data.active;
    dispatch(
      editDispositionDetails({
        dis_id: disposition_id,
        detail_id: data.id,
        dis: data,
      })
    );
    setOpen(false);
  };

  return (
    <div>
      <Button
        color={!data.active ? "success" : "error"}
        onClick={handleClickOpen}>
        {!data.active ? "Activate" : "Disable"}
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          {!data.active ? "Activate" : "Disable"} this Disposition Detail?{" "}
          <br />
        </DialogTitle>
        <DialogTitle>{data?.name}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color={!data.active ? "success" : "error"}
            onClick={handleSubmit}>
            {!data.active ? "Activate" : "Disable"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
