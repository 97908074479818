import { Box, Card, CardActionArea, Grid, Typography } from "@mui/material";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const QaMain = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const Paths = [
    {
      name: "Queue Level Reports",
      location: "QueueReports",
    },
    {
      name: "Campaign level Reports",
      location: "CampaignQaReports",
    },
    {
      name: "Call Center Agent level Reports",
      location: "AgentQaReport",
    },
    {
      name: "QA Agent level Reports",
      location: "QaAgentReport",
    },
    {
      name: "Sales vs QA Report",
      location: "SalesVsQaReports",
    },
    {
      name: "Incomplete Evaluations by QA Agent",
      location: "IncompleteEvaluationsReport",
    },
  ];

  const currentPath = pathname.split("/")[3];

  return (
    <Box sx={{ mt: 4 }}>
      <Grid container>
        {Paths.map((path, index) => {
          return (
            <Grid key={index} item xs={4}>
              <Card
                variant={currentPath === path ? "outlined" : "elevation"}
                sx={
                  currentPath === path.location
                    ? { borderStyle: "solid", borderColor: "primary.main" }
                    : null
                }
              >
                <CardActionArea
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    py: 3,
                  }}
                  onClick={() => {
                    navigate(`${path.location}`);
                  }}
                >
                  <Typography color="textPrimary">{path.name}</Typography>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Outlet />
    </Box>
  );
};

export default QaMain;
