import { useTheme } from "@emotion/react";
import { Card, CardActionArea, Typography } from "@mui/material";

import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const FeatureCard = ({ title, link, Icon, admin, company }) => {
  const navigate = useNavigate();

  const theme = useTheme();

  return (
    <>
      <Card
        sx={
          admin
            ? { border: "solid 1px grey" }
            : company === "CDA Solutions"
            ? { border: `solid 1px ${theme.palette.secondary.main}` }
            : company === "RMA"
            ? {
                border: `solid 1px ${theme.palette.primary.main}`,
              }
            : {
                border: `solid 1px grey`,
              }
        }>
        <CardActionArea
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            py: 5,
          }}
          onClick={() => {
            navigate(`${link}`);
          }}>
          <Icon sx={{ fontSize: 60, mb: 1 }} />
          <Typography color="textPrimary">{title}</Typography>
        </CardActionArea>
      </Card>
    </>
  );
};

export default FeatureCard;

FeatureCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  admin: PropTypes.bool,
  company: PropTypes.string,
};
