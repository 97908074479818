import axios from "axios";

const initialState = {
    smsProviders: {},
    loading: false,
    error: false,
}

/**
 * SYNC ACTIONS
 * 
 **/

/**
 * THUNK ACTIONS
 * 
 **/

const GET_SMS_PROVIDERS = "GET_SMS_PROVIDERS"
const GET_SMS_PROVIDERS_SUCESSS = "GET_SMS_PROVIDERS_SUCESSS"
const GET_SMS_PROVIDERS_ERROR = "GET_SMS_PROVIDERS_ERROR"

const smsProviderReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_SMS_PROVIDERS: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_SMS_PROVIDERS_SUCESSS: {
            return {
                ...state,
                smsProviders: action.payload,
            };
        }
        case GET_SMS_PROVIDERS_ERROR: {
            return {
                ...state,
                error: true,
            };
        }
        default: {
            return state;
        }
    }
}

export default smsProviderReducer;

export const getSmsProviders = () =>
    async (dispatch, getState) => {
        dispatch({ type: GET_SMS_PROVIDERS });
        
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/callCentre/sms_providers`);
            dispatch({ type: GET_SMS_PROVIDERS_SUCESSS, payload: response.data });

        } catch(error) {
            dispatch({ type: GET_SMS_PROVIDERS_ERROR });
        }
    };
