import { Stack, Snackbar, Alert, Card } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../../Components/Bits/PageHeader";
import {
  createNewEmployee,
  getCompanyEmployees,
  updateEmployee,
} from "../../../Redux/Ducks/AdminCompniesDuck";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import Box from "@mui/material/Box";

import SelectWrapper from "../../../Components/FormComponents/Select";
import SwitchWrapper from "../../../Components/FormComponents/SwitchWrapper";
import styled from "@emotion/styled";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

const StyledBox = styled(Box)(({ theme }) => ({
  height: "100vh",
  width: "100%",
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    color: "#1a3e72",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${
      theme.palette.mode === "dark" ? 0 : 0.1
    })`,
    color: theme.palette.error.main,
  },
}));

const Employees = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyEmployees());
  }, [dispatch]);

  const Companies = useSelector((state) => state.Companies);

  const { companyEmployees } = Companies;

  const rows = companyEmployees?.map((employee) => {
    return {
      id: employee.id,
      first_name: employee.first_name,
      last_name: employee.last_name,
      job_title: employee.job_title,
      drink_type: employee.drink_type || "",
      gym_membership: employee.gym_membership,
    };
  });

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      // Make the HTTP request to save in the backend

      dispatch(updateEmployee(newRow));
      setSnackbar({ children: "User successfully saved", severity: "success" });
      return newRow;
    },
    [dispatch]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "first_name",
      headerName: "First name",
      width: 130,
      editable: true,
    },
    {
      field: "last_name",
      headerName: "Last name",
      editable: true,
    },
    {
      field: "job_title",
      headerName: "job_title",
      width: 200,
      editable: true,
    },
    {
      field: "gym_membership",
      headerName: "gym_membership",
      width: 130,
      editable: true,
      type: "boolean",
    },
    {
      field: "drink_type",
      headerName: "Drink Type",
      type: "singleSelect",
      valueOptions: drinkTypes,
      width: 160,
      editable: true,
    },
  ];

  const countDrinkTypes = () => {
    let drinks = drinkTypes.map((drink) => {
      return {
        value: 0,
        label: drink,
      };
    });
    companyEmployees.forEach((employee) => {
      if (employee.drink_type) {
        drinks.forEach((drink) => {
          if (drink.label === employee.drink_type) {
            drink.value++;
          }
        });
      }
    });
    return drinks;
  };

  return (
    <div>
      <PageHeader
        title="Employees"
        subheader="All Employees"
        breadcrumbs={[
          { title: "Home", link: "/home" },
          { title: "Employees", link: "/employees" },
        ]}
      />

      <Stack my={4}>
        <AddNewEmployee />

        <Card>
          {countDrinkTypes().map((drink, index) => {
            return (
              <div key={index}>
                {drink.label} : {drink.value}
              </div>
            );
          })}
        </Card>
      </Stack>

      <StyledBox>
        <DataGridPremium
          editMode="row"
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          experimentalFeatures={{ newEditingApi: true }}
          rows={rows}
          columns={columns}
          pageSize={100}
          components={{ Toolbar: GridToolbar }}
          sortModel={[
            {
              field: "first_name",
              sort: "asc",
            },
          ]}
        />
      </StyledBox>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
};

export default Employees;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddNewEmployee = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add New Employee
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}>
        <DialogTitle>{"Add New Employee"}</DialogTitle>
        <DialogContent>
          <>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                job_title: "",
                drink_type: "",
                gym_membership: false,
              }}
              onSubmit={(values) => {
                dispatch(createNewEmployee(values));
              }}>
              {({ values }) => (
                <Form>
                  <Stack spacing={2} sx={{ my: 2 }}>
                    <TextfieldWrapper name="first_name" label="First Name" />
                    <TextfieldWrapper name="last_name" label="Last Name" />
                    <TextfieldWrapper name="job_title" label="Job Title" />
                    <SelectWrapper
                      name="drink_type"
                      label="Drink Type"
                      options={drinkTypes.map((drink) => ({
                        value: drink,
                        label: drink,
                      }))}
                    />
                    <SwitchWrapper
                      name="gym_membership"
                      label="Gym Membership"
                    />
                    <DialogActions>
                      <Button color="inherit" onClick={handleClose}>
                        cancel
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={handleClose}>
                        Save
                      </Button>
                    </DialogActions>
                  </Stack>
                </Form>
              )}
            </Formik>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const drinkTypes = [
  "Savanna",
  "Beer",
  "Wine",
  "TEA",
  "Storm",
  "NON Alcoholic",
  "Flying Fish",
  "Coke",
  "Strong bow",
];
