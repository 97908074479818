import { Card, Stack} from "@mui/material";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import BarManager from "./BarManager";
import PageHeader from "../../../../../../../../Components/Bits/PageHeader";
import withAuth from "../../../../../../../../Hoc/withAuth";
import PieChartt from "../Statistics/PieChartt";
import CardsManager from "./CardsManager";


const StatisticsManager = ({user}) => {

    const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
    
      const { data: CampaignData = [] } = useQuery(
        ["getAllEvaluations"],
        async () => {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/QAInbound/user/manager`,
            config
          );
          return response?.data?.data || [];
        },
        {
          onError: (error) => {
            console.error("Error fetching evaluations:", error);
          },
        }
      );

    const validRows = CampaignData || [];

    console.log("validRows", validRows)

    const allEvaluations = validRows?.flatMap(campaign => campaign.campaign);

      
      
     
      
      

  return (
    <div>
    <PageHeader
      title="Inbound QA Campaign & Evaluation Statistics"
      subheader="View Campaign & Evaluation Statistics"
    />
    
    <Card variant="outlined" sx ={{mb: 2}}>
    <CardsManager user={user} validRows={allEvaluations} CampaignRows={validRows}/>
    </Card>
    <Card variant="outlined">
    <Stack direction="row" justifyContent="space-between">
    <BarManager validRows={validRows}/>
    <PieChartt validRows={allEvaluations} />
    </Stack>
    </Card>
   
    </div>
  )
}

export default withAuth(StatisticsManager);