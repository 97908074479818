import { CircularProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import MainAppContainer from "../../Components/Containers/MainAppContainer";
import UserProfileForm from "./components/UserProfileForm";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from "../../Redux/Ducks/UserProfileDuck";
import { Box } from "@mui/system";

const Profile = () => {
  const dispatch = useDispatch();

  const { user, error, loading, editSuccess } = useSelector(
    (state) => state.Profile
  );

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  return (
    <MainAppContainer>
      <Typography variant="h4" sx={{ my: 4 }} align="center">
        Profile
      </Typography>
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && user && (
        <UserProfileForm
          User={user}
          success={editSuccess}
          error={error}
          loading={loading}
        />
      )}
    </MainAppContainer>
  );
};

export default Profile;
