import { Container, DialogContent } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCompanies } from "../../../Redux/Ducks/AdminCompniesDuck";
import AddUserForm from "../components/AddUserForm";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import { useNavigate } from "react-router-dom";

const AddNewUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  const AdminUsers = useSelector((state) => state.AdminUsers);
  const { addUserSuccess, User } = AdminUsers;

  useEffect(() => {
    if (addUserSuccess) {
      setTimeout(() => {
        navigate(`/EditUser/${User.id}`);
      }, 2000);
    }
  }, [addUserSuccess, User, navigate]);

  return (
    <div>
      <Container>
        <DialogContent>
          <AddUserForm user={User} />
          <AlertPopup
            open={addUserSuccess}
            severity="success"
            message="User Added Successfully Redirecting"
          />
        </DialogContent>
      </Container>
    </div>
  );
};

export default AddNewUser;
