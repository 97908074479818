import axios from "axios";

const initialState = {
  qaTeamsList: [],
  qaTeamsListLoading: false,
  qaTeamsListError: null,
  qaTeamsListSuccess: false,
  QaTeam: {},
  editTeamSuccess: false,
  editTeamError: null,
};

const GET_QA_TEAMS_REQUEST = "GET_QA_TEAMS_REQUEST";
const GET_QA_TEAMS_SUCCESS = "GET_QA_TEAMS_SUCCESS";
const GET_QA_TEAMS_FAILURE = "GET_QA_TEAMS_FAILURE";

const CREATE_QA_TEAM_REQUEST = "CREATE_QA_TEAM_REQUEST";
const CREATE_QA_TEAM_SUCCESS = "CREATE_QA_TEAM_SUCCESS";
const CREATE_QA_TEAM_FAILURE = "CREATE_QA_TEAM_FAILURE";

const UPDATE_QA_TEAM_REQUEST = "UPDATE_QA_TEAM_REQUEST";
const UPDATE_QA_TEAM_SUCCESS = "UPDATE_QA_TEAM_SUCCESS";
const UPDATE_QA_TEAM_FAILURE = "UPDATE_QA_TEAM_FAILURE";

const GET_QA_TEAM_BY_ID = "GET_QA_TEAM_BY_ID";
const GET_QA_TEAM_BY_ID_SUCCESS = "GET_QA_TEAM_BY_ID_SUCCESS";
const GET_QA_TEAM_BY_ID_FAILURE = "GET_QA_TEAM_BY_ID_FAILURE";

const ADD_USERS_TO_QA_TEAM = "ADD_USERS_TO_QA_TEAM";
const ADD_USERS_TO_QA_TEAM_SUCCESS = "ADD_USERS_TO_QA_TEAM_SUCCESS";
const ADD_USERS_TO_QA_TEAM_FAILURE = "ADD_USERS_TO_QA_TEAM_FAILURE";

const REMOVE_USER_FROM_QA_TEAM = "REMOVE_USER_FROM_QA_TEAM";
const REMOVE_USER_FROM_QA_TEAM_SUCCESS = "REMOVE_USER_FROM_QA_TEAM_SUCCESS";
const REMOVE_USER_FROM_QA_TEAM_FAILURE = "REMOVE_USER_FROM_QA_TEAM_FAILURE";

const UPDATE_TEAM_USER = "UPDATE_TEAM_USER";
const UPDATE_TEAM_USER_SUCCESS = "UPDATE_TEAM_USER_SUCCESS";
const UPDATE_TEAM_USER_FAILURE = "UPDATE_TEAM_USER_FAILURE";

export const RESET_QA_TEAMS = "RESET_QA_TEAMS";

const QaTeamsDuck = (state = initialState, action) => {
  switch (action.type) {
    case GET_QA_TEAMS_REQUEST:
      return {
        ...state,
        qaTeamsListLoading: true,
        qaTeamsListError: null,
        qaTeamsListSuccess: false,
      };

    case GET_QA_TEAMS_SUCCESS:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: null,
        qaTeamsList: action.payload,
      };
    case GET_QA_TEAMS_FAILURE:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: action.payload,
        qaTeamsListSuccess: false,
      };

    case CREATE_QA_TEAM_REQUEST:
      return {
        ...state,
        qaTeamsListLoading: true,
        qaTeamsListError: null,
        qaTeamsListSuccess: false,
      };
    case CREATE_QA_TEAM_SUCCESS:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: null,
        qaTeamsListSuccess: true,
        qaTeamsList: [...state.qaTeamsList, action.payload],
      };
    case CREATE_QA_TEAM_FAILURE:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: action.payload,
        qaTeamsListSuccess: false,
      };
    case UPDATE_QA_TEAM_REQUEST:
      return {
        ...state,
        qaTeamsListLoading: true,
        qaTeamsListError: null,
        qaTeamsListSuccess: false,
      };
    case UPDATE_QA_TEAM_SUCCESS:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: null,
        qaTeamsListSuccess: true,
        qaTeamsList: state.qaTeamsList.map((team) =>
          team.id === action.payload.id ? action.payload : team
        ),
      };
    case UPDATE_QA_TEAM_FAILURE:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: action.payload,
        qaTeamsListSuccess: false,
      };

    case GET_QA_TEAM_BY_ID:
      return {
        ...state,
        qaTeamsListLoading: true,
        qaTeamsListError: null,
        qaTeamsListSuccess: false,
      };
    case GET_QA_TEAM_BY_ID_SUCCESS:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: null,

        QaTeam: action.payload,
      };
    case GET_QA_TEAM_BY_ID_FAILURE:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: action.payload,
        qaTeamsListSuccess: false,
      };

    case ADD_USERS_TO_QA_TEAM:
      return {
        ...state,
        qaTeamsListLoading: true,
        qaTeamsListError: null,
        qaTeamsListSuccess: false,
      };
    case ADD_USERS_TO_QA_TEAM_SUCCESS:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: null,
        qaTeamsListSuccess: true,
        QaTeam: {
          ...state.QaTeam,
          qa_teams_users: [...action.payload],
        },
      };
    case ADD_USERS_TO_QA_TEAM_FAILURE:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: action.payload,
        qaTeamsListSuccess: false,
      };
    case REMOVE_USER_FROM_QA_TEAM:
      return {
        ...state,
        qaTeamsListLoading: true,
        qaTeamsListError: null,
        qaTeamsListSuccess: false,
      };
    case REMOVE_USER_FROM_QA_TEAM_SUCCESS:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: null,
        qaTeamsListSuccess: true,
        QaTeam: {
          ...state.QaTeam,
          qa_teams_users: action.payload,
        },
      };
    case REMOVE_USER_FROM_QA_TEAM_FAILURE:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: action.payload,
        qaTeamsListSuccess: false,
      };

    case UPDATE_TEAM_USER:
      return {
        ...state,
        qaTeamsListLoading: true,
        qaTeamsListError: null,
        qaTeamsListSuccess: false,
      };
    case UPDATE_TEAM_USER_SUCCESS:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: null,
        qaTeamsListSuccess: true,
        QaTeam: {
          ...state.QaTeam,
          qa_teams_users: [
            ...state.QaTeam.qa_teams_users.map((user) =>
              user.user_id === action.payload.user_id ? action.payload : user
            ),
          ],
        },
      };
    case UPDATE_TEAM_USER_FAILURE:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: action.payload,
        qaTeamsListSuccess: false,
      };

    case RESET_QA_TEAMS:
      return {
        ...state,
        qaTeamsListLoading: false,
        qaTeamsListError: null,
        qaTeamsListSuccess: false,
      };

    default:
      return state;
  }
};

export default QaTeamsDuck;

export const createQaTeam = (qaTeam) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_QA_TEAM_REQUEST,
  });

  const { AuthDuck } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${AuthDuck.userInfo.token}`,
    },
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/qa/teams`,
      qaTeam,
      config
    );
    dispatch({
      type: CREATE_QA_TEAM_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_QA_TEAM_FAILURE,
      payload: error.message,
    });
  }
};

export const updateQaTeam = (qaTeam) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_QA_TEAM_REQUEST,
  });

  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/qa/teams/${qaTeam.id}`,
      qaTeam,
      config
    );
    dispatch({
      type: UPDATE_QA_TEAM_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_QA_TEAM_FAILURE,
      payload: error.message,
    });
  }
};

export const getQaTeams = () => async (dispatch, getState) => {
  dispatch({
    type: GET_QA_TEAMS_REQUEST,
  });

  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/teams`,
      config
    );
    dispatch({
      type: GET_QA_TEAMS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_QA_TEAMS_FAILURE,
      payload: error.message,
    });
  }
};

export const getQaTeamById = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_QA_TEAM_BY_ID,
  });

  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/teams/${id}`,
      config
    );
    dispatch({
      type: GET_QA_TEAM_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_QA_TEAM_BY_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const addUserToQaTeams =
  ({ values }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_USERS_TO_QA_TEAM,
      });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/qa/teams/${values.teamId}/users/${values.user_id}`,
        { languages: values.languages },
        config
      );
      dispatch({
        type: ADD_USERS_TO_QA_TEAM_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: ADD_USERS_TO_QA_TEAM_FAILURE,
        payload: error.message,
      });
    }
  };

export const removeUserFromQaTeams =
  ({ teamId, userId }) =>
  async (dispatch, getState) => {
    dispatch({
      type: REMOVE_USER_FROM_QA_TEAM,
    });

    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/qa/teams/${teamId}/users/${userId}`,
        config
      );
      dispatch({
        type: REMOVE_USER_FROM_QA_TEAM_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: REMOVE_USER_FROM_QA_TEAM_FAILURE,
        payload: error.message,
      });
    }
  };

export const updateTeamUser = (values) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_TEAM_USER,
  });

  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/qa/teams/${values.teamId}/users/${values.user_id}`,
      { languages: values.languages },
      config
    );
    dispatch({
      type: UPDATE_TEAM_USER_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_TEAM_USER_FAILURE,
      payload: error.message,
    });
  }
};
