import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import SaveIcon from "@mui/icons-material/Save";
import { addRecordingToEvaluation } from "../QAManagement/State/QaDuck";
import { useDispatch } from "react-redux";

const UploadRecording = ({ id, recordingAdded }) => {
  const [recording, setRecording] = React.useState(null);

  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setRecording(file);
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append("file", recording);


    dispatch(addRecordingToEvaluation(id, formData));
    setRecording(null);
  };

  return (
    <Box>
      {recording ? (
        <Box
          sx={{
            p: 2,
            borderColor: "secondary.main",
            borderWidth: "2px",
            borderStyle: "dashed",
          }}>
          <Typography variant="span">{recording?.name}</Typography>
          <Button
            startIcon={<SaveIcon />}
            sx={{ px: 5, py: 2 }}
            size="large"
            color="secondary"
            variant="contained"
            onClick={handleSave}>
            Save
          </Button>
        </Box>
      ) : (
        <label htmlFor="contained-button-file">
          <Input
            accept=".mp3,audio/*"
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <Button
            variant="outlined"
            sx={{ px: 5, py: 2 }}
            startIcon={<MusicVideoIcon />}
            fullWidth
            component="span">
            Upload
          </Button>
        </label>
      )}
    </Box>
  );
};

export default UploadRecording;

const Input = styled("input")({
  display: "none",
});
