import React from "react";
import { useState, useEffect } from "react";
import SelectWrapper from "../../../Components/FormComponents/Select";
import axios from "axios";

const ScoreCardSelector = ({ name, id, initialValue }) => {
  const [scoreCards, setScoreCards] = useState([]);
  const [initial, setInitial] = useState(initialValue);
  

  useEffect(() => {
    const fetchScoreCards = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/qa/scorecard`
        );
        const options = response.data.data.map((scoreCard) => {
          return {
            label: scoreCard.name,
            value: scoreCard.id,
          };
        });
        setScoreCards(options);
        setInitial(initialValue);
      } catch (error) {
        console.log(error);
      }
    };
    fetchScoreCards();
  }, [initialValue]);

  return (
    <SelectWrapper
      label="Score Card"
      name={name}
      options={scoreCards}
      value={initial}
    />
  );
};

export default ScoreCardSelector;
