import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { Alert, CircularProgress } from "@mui/material";
import axios from "axios";

const validationSchema = yup.object({
  otp: yup.string().required("Enter OTP"),
});

export default function VerifyOTP() {
  let navigate = useNavigate();
  let employee_id = localStorage.getItem("tempUserId");

  if(!employee_id){
    navigate('/otp')
  }

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const formik = useFormik({
    initialValues: {
      otp: "",
      employee_id: employee_id,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/onboarding/employees/otp/verify/`,
          values
        );

        localStorage.removeItem("tempUserId");
        localStorage.removeItem("employeeId");
        localStorage.setItem("employeeId", data?.userInfo?.BasicInfo.id);
        const success = data.success

        if(success === true){
          navigate("/register");
        }else{
          setError('Invalid Otp. Please try again')
        }

      } catch (error) {
        setLoading(false);
        setError(error);
      }
    },
  })

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        Verify OTP
      </Typography>

      <Box noValidate sx={{ mt: 5 }}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="otp"
            label="One Time Pin"
            name="otp"
            autoComplete="otp"
            autoFocus
            value={formik.values.otp}
            onChange={formik.handleChange}
            error={formik.touched.otp && Boolean(formik.errors.otp)}
            helperText={formik.touched.otp && formik.errors.otp}
          />

          {loading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          )}
        </form>

        {error && (
          <Alert severity="error"> {JSON.stringify(error?.message)}</Alert>
        )}
      </Box>
    </Box>
  );
}
