import axios from "axios";

const initialState = {
  questionnaires: [],
  questionnaire: {},
  loading: false,
  error: null,
};

const GET_QUESTIONNAIRES_BY_COMPANY_ID = "GET_QUESTIONNAIRES_BY_COMPANY_ID";
const GET_QUESTIONNAIRES_BY_COMPANY_ID_SUCCESS =
  "GET_QUESTIONNAIRES_BY_COMPANY_ID_SUCCESS";
const GET_QUESTIONNAIRES_BY_COMPANY_ID_FAILURE =
  "GET_QUESTIONNAIRES_BY_COMPANY_ID_FAILURE";

const GET_QUESTIONNAIRES_BY_ID = "GET_QUESTIONNAIRES_BY_ID";
const GET_QUESTIONNAIRES_BY_ID_SUCCESS = "GET_QUESTIONNAIRES_BY_ID_SUCCESS";
const GET_QUESTIONNAIRES_BY_ID_FAILURE = "GET_QUESTIONNAIRES_BY_ID_FAILURE";

const CREATE_QUESTIONNAIRE = "CREATE_QUESTIONNAIRE";
const CREATE_QUESTIONNAIRE_SUCCESS = "CREATE_QUESTIONNAIRE_SUCCESS";
const CREATE_QUESTIONNAIRE_FAILURE = "CREATE_QUESTIONNAIRE_FAILURE";
const EDIT_QUESTIONNAIRE_QUESTION_SUCCESS =
  "EDIT_QUESTIONNAIRE_QUESTION_SUCCESS";
const EDIT_QUESTIONNAIRE = "EDIT_QUESTIONNAIRE";
const EDIT_QUESTIONNAIRE_SUCCESS = "EDIT_QUESTIONNAIRE_SUCCESS";

const EDIT_QUESTIONNAIRE_FAILURE = "EDIT_QUESTIONNAIRE_FAILURE";

const DELETE_QUESTIONNAIRE = "DELETE_QUESTIONNAIRE";
const DELETE_QUESTIONNAIRE_SUCCESS = "DELETE_QUESTIONNAIRE_SUCCESS";
const DELETE_QUESTIONNAIRE_FAILURE = "DELETE_QUESTIONNAIRE_FAILURE";

const CREATE_QUESTIONNAIRE_QUESTION_SUCCESS =
  "CREATE_QUESTIONNAIRE_QUESTION_SUCCESS";

const DELETE_QUESTIONNAIRE_QUESTION = "DELETE_QUESTIONNAIRE_QUESTION";

const CREATE_QUESTION_ANSWER = "CREATE_QUESTION_ANSWER";

const EDIT_QUESTION_ANSWER = "EDIT_QUESTION_ANSWER";
const DELETE_QUESTION_ANSWER = "DELETE_QUESTION_ANSWER";

const ADD_QUESTIONNAIRE_USERS = "ADD_QUESTIONNAIRE_USERS";

const EDIT_QUESTIONNAIRE_USERS = "EDIT_QUESTIONNAIRE_USERS";

const DELETE_QUESTIONNAIRE_USERS = "DELETE_QUESTIONNAIRE_USERS";

const QUESTIONNAIRES_DUCK_RESET = "QUESTIONNAIRES_DUCK_RESET";

export const RESET_MESSAGES = "RESET_MESSAGES";

export const RESET_QUESTIONNAIRE_DUCK = "RESET_QUESTIONNAIRE_DUCK";

const QuestionnairesDuck = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONNAIRES_BY_COMPANY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_QUESTIONNAIRES_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        questionnaires: action.payload,
        loading: false,
        error: null,
      };
    case GET_QUESTIONNAIRES_BY_COMPANY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_QUESTIONNAIRE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        questionnaire: action.payload,
        questionnaireCreated: true,
        loading: false,
        error: null,
      };

    case EDIT_QUESTIONNAIRE_QUESTION_SUCCESS:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questionnaire_questions: [
            ...state.questionnaire.questionnaire_questions.map((question) => {
              if (question.id === action.payload.id) {
                question = action.payload;
              }
              return question;
            }),
          ],
        },
        loading: false,
        error: null,
      };

    case CREATE_QUESTIONNAIRE_QUESTION_SUCCESS:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questionnaire_questions: [
            ...state.questionnaire.questionnaire_questions,
            action.payload,
          ],
        },
        loading: false,
        error: null,
      };

    case EDIT_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        questionnaire: action.payload,
        updateEditQuestionnaire: true,
        loading: false,
        error: null,
      };

    case CREATE_QUESTION_ANSWER:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questionnaire_questions: [
            ...state.questionnaire.questionnaire_questions.map((question) => {
              if (question.id === action.payload.questions_id) {
                question.questionnaire_answers = [
                  ...question.questionnaire_answers,
                  action.payload,
                ];
              }
              return question;
            }),
          ],
        },
        loading: false,
        error: null,
      };

    case EDIT_QUESTION_ANSWER:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questionnaire_questions: [
            ...state.questionnaire.questionnaire_questions.map((question) => {
              if (question.id === action.payload.questions_id) {
                question.questionnaire_answers = [
                  ...question.questionnaire_answers.map((answer) => {
                    if (answer.id === action.payload.id) {
                      answer = action.payload;
                    }
                    return answer;
                  }),
                ];
              }
              return question;
            }),
          ],
        },
        loading: false,
        error: null,
      };

    case DELETE_QUESTION_ANSWER:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questionnaire_questions: [
            ...state.questionnaire.questionnaire_questions.map((question) => {
              if (question.id === action.payload.questions_id) {
                question.questionnaire_answers = [
                  ...question.questionnaire_answers.filter((answer) => {
                    return answer.id !== action.payload.id;
                  }),
                ];
              }
              return question;
            }),
          ],
        },
        loading: false,
        error: null,
      };

    case ADD_QUESTIONNAIRE_USERS:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questionnaire_users: [
            ...state.questionnaire.questionnaire_users,
            action.payload,
          ],
        },
        loading: false,
        error: null,
      };

    case EDIT_QUESTIONNAIRE_USERS:
      return {
        ...state,
        editUserSuccess: true,
        loading: false,
        error: null,
      };

    case DELETE_QUESTIONNAIRE_USERS:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questionnaire_users: [
            ...state.questionnaire.questionnaire_users.filter(
              (user) => user.id !== action.payload.id
            ),
          ],
        },
        loading: false,
        error: null,
      };

    case DELETE_QUESTIONNAIRE_QUESTION:
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
      };

    case CREATE_QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        createQuestionnaireSuccess: true,
      };
    case EDIT_QUESTIONNAIRE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case EDIT_QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_QUESTIONNAIRE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        questionnaire: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case QUESTIONNAIRES_DUCK_RESET:
      return {
        ...state,
        questionnaires: [],
        questionnaire: {},
        loading: false,
        error: null,
      };

    case GET_QUESTIONNAIRES_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_QUESTIONNAIRES_BY_ID_SUCCESS:
      return {
        ...state,
        questionnaire: action.payload,
        loading: false,
        error: null,
      };
    case GET_QUESTIONNAIRES_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_MESSAGES:
      return {
        ...state,
        success: false,
        error: null,
        updateEditQuestionnaire: false,
        questionnaireCreated: false,
        editUserSuccess: false,
      };

    case RESET_QUESTIONNAIRE_DUCK:
      return {
        questionnaires: [],
        questionnaire: {},
        loading: false,
        error: null,
        success: false,
      };

    default:
      return state;
  }
};

export default QuestionnairesDuck;

export const getCompanyQuestionnaires = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_QUESTIONNAIRES_BY_COMPANY_ID });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/questionnaire/${id}`,
      config
    );
    dispatch({
      type: GET_QUESTIONNAIRES_BY_COMPANY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_QUESTIONNAIRES_BY_COMPANY_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const createQuestionnaire =
  (questionnaire) => async (dispatch, getState) => {
    dispatch({ type: CREATE_QUESTIONNAIRE });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/questionnaire`,
        questionnaire,
        config
      );
      dispatch({
        type: CREATE_QUESTIONNAIRE_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_QUESTIONNAIRE_FAILURE,
        payload: error.message,
      });
    }
  };

export const editQuestionnaire =
  (id, questionnaire) => async (dispatch, getState) => {
    dispatch({ type: EDIT_QUESTIONNAIRE });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/questionnaire/${id}`,
        questionnaire,
        config
      );
      dispatch({
        type: EDIT_QUESTIONNAIRE_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_QUESTIONNAIRE_FAILURE,
        payload: error.message,
      });
    }
  };

export const getQuestionnaireById = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_QUESTIONNAIRES_BY_ID });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/questionnaire/${id}/campaign`,
      config
    );
    dispatch({
      type: GET_QUESTIONNAIRES_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_QUESTIONNAIRES_BY_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const CreateQuestionForQuestionnaire =
  (question) => async (dispatch, getState) => {
    dispatch({ type: CREATE_QUESTIONNAIRE });
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/questionnaire/question`,
        question,
        config
      );
      dispatch({
        type: CREATE_QUESTIONNAIRE_QUESTION_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_QUESTIONNAIRE_FAILURE,
        payload: error.message,
      });
    }
  };

export const CreateQuestionAnswer =
  (question) => async (dispatch, getState) => {
    dispatch({ type: CREATE_QUESTIONNAIRE });
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/questionnaire/answer`,
        question,
        config
      );
      dispatch({
        type: CREATE_QUESTION_ANSWER,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_QUESTIONNAIRE_FAILURE,
        payload: error.message,
      });
    }
  };

export const EditQuestionnaireAnswerReq =
  (id, values) => async (dispatch, getState) => {
    dispatch({ type: CREATE_QUESTIONNAIRE });
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/questionnaire/answer/${id}`,
        values,
        config
      );
      dispatch({
        type: EDIT_QUESTION_ANSWER,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_QUESTIONNAIRE_FAILURE,
        payload: error.message,
      });
    }
  };

export const DeleteQuestionnaireAnswerReq =
  (id) => async (dispatch, getState) => {
    dispatch({ type: CREATE_QUESTIONNAIRE });
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/questionnaire/answer/${id}`,
        config
      );
      dispatch({
        type: DELETE_QUESTION_ANSWER,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_QUESTIONNAIRE_FAILURE,
        payload: error.message,
      });
    }
  };

export const editQuestionnaireQuestion =
  (id, question) => async (dispatch, getState) => {
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/questionnaire/question/${id}`,
        question,
        config
      );
      dispatch({
        type: EDIT_QUESTIONNAIRE_QUESTION_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_QUESTIONNAIRE_FAILURE,
        payload: error.message,
      });
    }
  };

export const deleteQuestionnaireQuestion =
  (id) => async (dispatch, getState) => {
    dispatch({ type: DELETE_QUESTIONNAIRE });
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/questionnaire/question/${id}`,
        config
      );
      dispatch({
        type: DELETE_QUESTIONNAIRE_QUESTION,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_QUESTIONNAIRE_FAILURE,
        payload: error.message,
      });
    }
  };

export const createQuestionnaireUsers =
  (users) => async (dispatch, getState) => {
    dispatch({ type: CREATE_QUESTIONNAIRE });
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/questionnaire/users`,
        users,
        config
      );
      dispatch({
        type: ADD_QUESTIONNAIRE_USERS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_QUESTIONNAIRE_FAILURE,
        payload: error.message,
      });
    }
  };

export const deleteQuestionnaireUser = (id) => async (dispatch, getState) => {
  dispatch({ type: DELETE_QUESTIONNAIRE });
  try {
    const { AuthDuck } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/questionnaire/users/${id}`,
      config
    );
    dispatch({
      type: DELETE_QUESTIONNAIRE_USERS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_QUESTIONNAIRE_FAILURE,
      payload: error.message,
    });
  }
};

export const editQuestionnaireUser =
  (id, user) => async (dispatch, getState) => {
    dispatch({ type: EDIT_QUESTIONNAIRE });
    try {
      const { AuthDuck } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/questionnaire/users/${id}`,
        user,
        config
      );
      dispatch({
        type: EDIT_QUESTIONNAIRE_USERS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_QUESTIONNAIRE_FAILURE,
        payload: error.message,
      });
    }
  };
