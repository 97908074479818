import { Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import FeatureCard from "../../Components/Containers/FeatureCard";
import withAuth from "../../Hoc/withAuth";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const RmaDashBoard = ({ user, companyDetails }) => {
  if (companyDetails?.company === "RMA" || user.userType === "admin") {
    return (
      <div>
        <Stack sx={{ my: 4 }}>
          <Typography variant="h5">RMA</Typography>
          <Divider />
        </Stack>
        <Grid container spacing={1} mb={1}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
            <FeatureCard
              company="RMA"
              Icon={UploadFileIcon}
              title="EXCEL Uploader"
              link="/RMA/FileUploader"
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  return null;
};

export default withAuth(RmaDashBoard);
