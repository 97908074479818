import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import withAuth from "../../Hoc/withAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanies,
  RESET_COMPANY_STATE,
} from "../../Redux/Ducks/AdminCompniesDuck";
import MainAppContainer from "../../Components/Containers/MainAppContainer";
import { useNavigate } from "react-router-dom";
import AddCompanyDialog from "./components/AddCompanyDialog";
import { Box } from "@mui/system";
import AddModuleOptions from "./components/AddModuleOptions";
import AlertPopup from "../../Components/Bits/AlertPopup";

const CompaniesManagement = ({ user }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCompanies());
    dispatch({ type: RESET_COMPANY_STATE });
  }, [dispatch]);

  const Companies = useSelector((state) => state.Companies);

  const { allCompanies, moduleRemoved, moduleAdded } = Companies;

  return (
    <MainAppContainer>
      <AlertPopup
        open={moduleRemoved}
        severity="success"
        message="The module has been removed"
      />
      <AlertPopup
        open={moduleAdded}
        severity="success"
        message="The module has been added"
      />
      <Typography sx={{ mb: 4 }} variant="h4" align="center">
        Companies Management
      </Typography>

      <Box display="flex" justifyContent="center" sx={{ mb: 4 }}>
        <AddCompanyDialog />
      </Box>
      <Box display="flex" justifyContent="center" sx={{ mb: 4 }}>
        <AddModuleOptions />
      </Box>

      <Grid container spacing={1}>
        {allCompanies.map((company) => (
          <Grid key={company.id} item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Card sx={{ width: "100%" }}>
              <CardHeader
                title={<Avatar>{company?.name.split("")[0]}</Avatar>}
                action={
                  <Chip
                    variant="outlined"
                    color={company.status === "active" ? "primary" : "error"}
                    label={company?.status}
                  />
                }
              />
              <CardContent>
                <Typography variant="span">{company.name}</Typography>
              </CardContent>

              <Stack
                mx={1}
                mb={2}
                direction="row"
                justifyContent="space-between">
                <Button onClick={() => navigate(`/Company/${company.id}`)}>
                  Edit
                </Button>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Button
        onClick={() => {
          navigate("/Employees");
        }}>
        CDAEmployees
      </Button>
    </MainAppContainer>
  );
};

export default withAuth(CompaniesManagement);
