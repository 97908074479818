import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import LogoDark from "../../assets/TheNodeDark.png";
import LogoLight from "../../assets/TheNodeLight.png";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { SignInButton } from "./SignInButton";
import {
  AUTH_ERROR,
  AUTH_REQUEST,
  LOGIN,
} from "../../Redux/ActionNames/AllActions";
import axios from "axios";
import { Alert, CircularProgress } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export default function SignIn() {
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        dispatch({
          type: AUTH_REQUEST,
        });

        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/login`,
          {
            email: values.email.toLowerCase(),
            password: values.password,
          }
        );

        dispatch({
          type: LOGIN,
          payload: data,
        });
        setLoading(false);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({ ...data, isLoggedIn: true })
        );
        navigate(`/`);
      } catch (error) {
        setLoading(false);
        setError(error);
        dispatch({ type: AUTH_ERROR, payload: error });
      }
    },
  });

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  return (
    <>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <img
          style={{ width: "40%" }}
          src={theme.palette.mode === "light" ? LogoDark : LogoLight}
          alt=""
        />

        {/* <Box mt={5}>
          <SignInButton />
        </Box> */}
        <Box noValidate sx={{ mt: 5 }}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />

            {loading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <Button
                id="signIn"
                // onClick={handleSubmit}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Sign In
              </Button>
            )}
          </form>

          {error && (
            <Alert severity="error">
              {" "}
              {JSON.stringify(error?.response?.data?.message)}
            </Alert>
          )}

          <Grid container>
            <Grid item xs>
              <Link
                style={{ textDecoration: "none" }}
                to="/ForgottenPassword"
                variant="body2">
                <Button color="info">
                  {" "}
                  <Typography color="textPrimary">
                    Forgot password?
                  </Typography>{" "}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Box>
    </>
  );
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="textPrimary" align="center" {...props}>
      {"Copyright © "}
      <Link to="https://cdasolutions.co.za"> CDA Solutions</Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
