import { Button, Grid } from "@mui/material";
import React, { useState } from "react";

import CreateAnswers from "./CreateAnswers";
import CreateQuestions from "./CreateQuestions";
import { useNavigate } from "react-router-dom";
import MainAppContainer from "../../../../../../../Components/Containers/MainAppContainer";

const SurveyQuestionsAnswers = () => {
  const navigate = useNavigate();

  const [position, setPosition] = useState(0);

  return (
    <MainAppContainer>
      <Button
        variant="outlined"
        color="inherit"
        onClick={() => {
          navigate(-1);
        }}>
        Back
      </Button>
      <Grid container display="flex" justifyContent="center">
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          {position === 0 ? (
            <CreateQuestions setPosition={setPosition} />
          ) : (
            <CreateAnswers setPosition={setPosition} />
          )}
        </Grid>
      </Grid>
    </MainAppContainer>
  );
};

export default SurveyQuestionsAnswers;
