import React, { Suspense } from "react";
import {
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Table,
  Chip,
} from "@mui/material";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../../../Hoc/withAuth";

const parseData = (DataType) => (data) =>
  new DataType(data).toLocaleDateString();

const dateMonthYear = parseData(Date);

const RenderTableHeader = ({ headers }) => {
  return (
    <TableHead>
      <TableRow>
        {headers.map((key, index) => {
          return (
            <TableCell component="th" key={index} align={"left"}>
              {key}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const RenderTableBody = ({ rows }) => {
  return (
    <TableBody>
      {rows.map((row) => {
        return (
          <TableRow key={row.id}>
            <TableCell component="td" scope="row">
              {row.id}
            </TableCell>
            <TableCell component="td" scope="row">
              {row.order_id}
            </TableCell>
            <TableCell component="td" scope="row">
              <Chip label={row.state} color={stateColor(row.state)} />
            </TableCell>
            <TableCell component="td" scope="row">
              {row.partner_id}
            </TableCell>
            <TableCell component="td" scope="row">
              <Chip
                label={row.current_state}
                color={stateColor(row.current_state)}
              />
            </TableCell>
            <TableCell component="td" scope="row">
              {row.state_reason}
            </TableCell>
            <TableCell component="td" scope="row">
              {row.cancel_user_id}
            </TableCell>
            <TableCell component="td" scope="row">
              {dateMonthYear(row.createdAt)}
            </TableCell>
            <TableCell component="td" scope="row">
              {row.platform}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

function SaleOrderHistoryTable({ id, columns, platform, user }) {
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getSaleOrderHistoryById = useQuery(
    [`getSaleOrderHistory`, id],
    async () => {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/${platform}/sales_orders/${id}/history`,
        config
      );

      return data;
    }
  );

  const { data, isLoading, isError, error } = getSaleOrderHistoryById;

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error: {error.message} </div>;
  } else {
    return (
      <Suspense fallback={<SaleOrderHistorySuspense />}>
        <TableContainer component={Paper}>
          <Table>
            <RenderTableHeader headers={columns} />
            <RenderTableBody rows={data?.data?.data} />
          </Table>
        </TableContainer>
      </Suspense>
    );
  }
}

export default withAuth(SaleOrderHistoryTable);

function SaleOrderHistorySuspense() {
  return <div>Loading History Data</div>;
}

const stateColor = (state) => {
  switch (state) {
    case "draft":
      return "primary";
    case "sent":
      return "secondary";
    case "cancel":
      return "error";
    case "done":
      return "success";
    default:
      return "default";
  }
};
