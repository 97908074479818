import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AddNewCompany } from "../../../Redux/Ducks/AdminCompniesDuck";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Stack } from "@mui/material";

export default function AddCompanyDialog() {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const [company, setCompany] = useState("");

  const Companies = useSelector((state) => state.Companies);
  const { CreateCompanySuccess, addCompanyError } = Companies;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(
      AddNewCompany({
        name: company,
        status: "active",
      })
    );
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Add Company
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Company</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This is where you can add a new company to the system.
          </DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            id="name"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            label="Company Name"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <Stack>
          {CreateCompanySuccess ? (
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Close
            </Button>
          ) : (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}>
                Submit
              </Button>
            </>
          )}
          {addCompanyError && <Alert severity="error">{addCompanyError}</Alert>}
        </Stack>
      </Dialog>
    </div>
  );
}
