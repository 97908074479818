import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";

import { DialogContent, Typography } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { editClient, RESET_CLIENT_EDIT } from "../../State/ClientsDuck";

export default function DeleteClient({ client }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({ type: RESET_CLIENT_EDIT });
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button color="error" onClick={handleClickOpen}>
        Delete Client
      </Button>
      <Dialog
        sx={{ border: "3px solid red" }}
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}>
        <DialogTitle>Delete Client</DialogTitle>

        <Formik
          initialValues={{
            id: client.id,
            name: client.name,
            active: false,
            updated_reason: "Edited Client",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            active: Yup.boolean().required("Active is required"),
          })}
          onSubmit={(values) => {
            dispatch(editClient(values));
            setTimeout(() => {
              handleClose();
            }, 1000);
          }}>
          {() => (
            <Form>
              <DialogContent>
                <Typography variant="span">
                  Are you sure you want to delete this client?
                </Typography>
                <Typography variant="h4" align="center">
                  {client.name}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" variant="contained" color="error">
                  Delete
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
