import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useFormik } from "formik";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { createClientCampaign } from "../StateManager/Campaigns/CRMCampaignDuck";
import { DatePicker } from "@mui/lab";
import { LocalizationProvider } from '@mui/x-date-pickers'
import DateAdapter from "@mui/lab/AdapterDateFns";
import { enZA } from "date-fns/locale";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import NotesForms from "../components/NotesForms";

export default function CreateClientCampaignForm({ id }) {
  const [open, setOpen] = React.useState(false);

  const [campaign_notes, setCampaignNotes] = React.useState([]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      campaign_name: "",
      lead_type: "",
      campaign_start_date: null,
      campaign_status: "",
      expected_duration: "",
      campaign_notes: campaign_notes,
      client_id: id,
    },
    validationSchema: yup.object({
      campaign_name: yup.string().required("Required"),
      lead_type: yup.string().required("Required"),
      campaign_start_date: yup.date().required("Required"),
      campaign_status: yup.string().required("Required"),
      expected_duration: yup.number().required("Required"),
      client_id: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      dispatch(createClientCampaign(values));
      handleClose();
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
        Create Campaign
      </Button>
      <Dialog maxWidth="lg" fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle>CreateClientContact</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <TextField
              margin="normal"
              label="Campaign Name"
              name="campaign_name"
              onChange={formik.handleChange}
              value={formik.values.campaign_name}
              error={formik.errors.campaign_name ? true : false}
              helperText={formik.errors.campaign_name}
              fullWidth
            />

            <FormControl margin="normal" fullWidth>
              <InputLabel id="lead_type_label">Lead Type</InputLabel>
              <Select
                labelId="lead_type_labell"
                id="lead_type_select"
                label="Lead Type"
                name="lead_type"
                onChange={formik.handleChange}
                value={formik.values.lead_type}
                error={formik.errors.lead_type ? true : false}>
                <MenuItem value="Affinity">Affinity</MenuItem>
                <MenuItem value="Hotlead">Hotlead</MenuItem>
                <MenuItem value="Coldlead">Coldlead</MenuItem>
                <MenuItem value="Crossell">Crossell</MenuItem>
                <MenuItem value="Upgrade">Upgrade</MenuItem>
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={DateAdapter} locale={enZA}>
              <FormControl margin="normal" fullWidth>
                <DatePicker
                  views={["month", "year"]}
                  margin="normal"
                  label="Join Date"
                  name="join_date"
                  onChange={(value) =>
                    formik.setFieldValue("campaign_start_date", value)
                  }
                  value={formik.values.campaign_start_date}
                  error={formik.errors.campaign_start_date ? true : false}
                  helperText={formik.errors.campaign_start_date}
                  variant="inline"
                  inputVariant="outlined"
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            </LocalizationProvider>
            <TextField
              margin="normal"
              label="Campaign Status"
              name="campaign_status"
              onChange={formik.handleChange}
              value={formik.values.campaign_status}
              error={formik.errors.campaign_status ? true : false}
              helperText={formik.errors.campaign_status}
              fullWidth
            />
            <TextField
              margin="normal"
              label="Expected Duration"
              name="expected_duration"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.expected_duration}
              error={formik.errors.expected_duration ? true : false}
              helperText={formik.errors.expected_duration}
              fullWidth
            />

            <NotesForms
              campaign_notes={campaign_notes}
              setCampaignNotes={setCampaignNotes}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit">
              Create Campaign
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
