import React from "react";

import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import { Form, Formik } from "formik";
import SelectWrapper from "../../../../../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";
import DOBPicker from "../../../../../../../Components/FormComponents/DobPicker";
import { AddNewLeadRequest } from "../../State/LeadsDuck";
import * as Yup from "yup";

const AddNewLeadDialog = ({ id, activeLeadSources }) => {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="warning" onClick={handleClickOpen}>
        Add New Lead
      </Button>
      <Dialog maxWidth="xl" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Add New Lead</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              campaign_id: id,
              source: "",
              external_ref: "",
              old_system_id: "",
              system_id: "",
              title: "",
              initials: "",
              first_name: "",
              surname: "",
              id_no: "",
              dob: new Date(),
              gender: "",
              marital_status: "",
              mobile_no: "",
              home_no: "",
              work_no: "",
              email: "",
              addr1: "",
              addr2: "",
              postal_code: "",
              addr1_res: "",
              addr2_res: "",
              postal_code_res: "",
              acc_holder_name: "",
              acc_type: "",
              branch_code: "",
              bank: "",
              full_note: "",
              platform: "",
            }}
            validationSchema={Yup.object({
              source: Yup.string().required("Required"),
              mobile_no: Yup.string().required("Required"),
            })}
            onSubmit={(values) => {
              dispatch(AddNewLeadRequest(values));
              handleClose();
            }}>
            {() => {
              return (
                <Form>
                  <Stack sx={{ mt: 3 }} spacing={1}>
                    <Divider />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <SelectWrapper
                          label="source"
                          name="source"
                          options={activeLeadSources.map((source) => {
                            return {
                              value: source.id,
                              label: source.name,
                            };
                          })}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextfieldWrapper name="mobile_no" label="Mobile No " />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper name="home_no" label=" Home No" />
                      </Grid>
                      <Grid item xs={2}>
                        <TextfieldWrapper label="title" name="title" />
                      </Grid>
                      <Grid item xs={5}>
                        <TextfieldWrapper label="initials" name="initials" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          label="First Name"
                          name="first_name"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper label="Surname" name="surname" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper label="Id Number" name="id_no" />
                      </Grid>
                      <Grid item xs={6}>
                        <DOBPicker name="dob" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper name="gender" label="Gender" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="marital_status"
                          label="Marital Status "
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextfieldWrapper name="work_no" label="Work No" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper name="email" label="Email" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="addr1"
                          label=" Address Line 1"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="addr2"
                          label=" Address Line 2"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="postal_code"
                          label="Postal Code"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper name="addr1_res" label="addr1_res" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper name="addr2_res" label="addr2_res" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper name="addr3_res" label="addr3_res" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="postal_code_res"
                          label="postal_code_res"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="acc_holder_name"
                          label="acc_holder_name"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper name="acc_no" label="acc_no" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper name="acc_type" label="acc_type" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="branch_code"
                          label="branch_code"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper name="bank" label="bank" />
                      </Grid>

                      <Grid item xs={6}>
                        <TextfieldWrapper
                          label="External Ref"
                          name="external_ref"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          label="Old System id "
                          name="old_system_id"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper label="system_id" name="system_id" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper name="full_note" label="full_note" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper name="platform" label="platform" />
                      </Grid>
                    </Grid>

                    <Divider />
                  </Stack>
                  <DialogActions sx={{ mt: 4 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddNewLeadDialog;
