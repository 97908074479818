import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import withAuth from "../../../../../../Hoc/withAuth";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AgentCaptureLead = ({ user, campaign_id, captureNewLead }) => {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="secondary" variant="contained" onClick={handleClickOpen}>
        Capture New Lead
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Capture New Lead
            </Typography>
            <Button color="warning" onClick={handleClose}>
              Cancel
            </Button>
          </Toolbar>
        </AppBar>

        {/* Name, Surname, Id Number, Phone Number */}

        <Grid spacing={2} container display="flex" justifyContent="center">
          <Grid item xs={6}>
            <Formik
              initialValues={{
                campaign_id: campaign_id,
                external_ref: "",
                first_name: "",
                surname: "",
                id_no: "",
                mobile_no: "",
                work_no: "",
                email: "",
                source: `Captured By user ${user.email}`,
                platform: "NODE",
                note1: "",
                note2: "",
              }}
              validationSchema={Yup.object({
                first_name: Yup.string().required("Required"),
                surname: Yup.string().required("Required"),
                id_no: Yup.string().required("Required"),
                mobile_no: Yup.string().required("Required"),
              })}
              onSubmit={(values) => {
                captureNewLead.mutate(values, {
                  onSuccess: (data) => {
                    navigate(
                      `/CDA/CampaignLeads/Lead/${data?.data?.data?._id}/lead`
                    );
                  },
                });
              }}>
              {() => {
                return (
                  <Form>
                    <Grid
                      spacing={2}
                      sx={{ mt: 2 }}
                      container
                      display="flex"
                      justifyContent="center">
                      <Grid item xs={12}>
                        <Typography align="center" variant="h6">
                          Capture Lead for Campaign {campaign_id}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextfieldWrapper
                          name="external_ref"
                          label="Reference Number"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextfieldWrapper name="first_name" label="Name" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextfieldWrapper name="surname" label="Surname" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextfieldWrapper
                          name="id_no"
                          label="Id Number"
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextfieldWrapper
                          name="mobile_no"
                          label="Mobile Number"
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextfieldWrapper
                          name="work_no"
                          label="Work Number"
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextfieldWrapper
                          name="email"
                          label="Email Address"
                          type="email"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextfieldWrapper
                          name="note1"
                          label="Note 1"
                          type="text"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextfieldWrapper
                          name="note2"
                          label="Note 2"
                          type="text"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{ mt: 2, mb: 5 }}
                          type="submit">
                          Submit
                        </Button>
                        <Button color="warning" onClick={handleClose}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default withAuth(AgentCaptureLead);
