import {
  Alert,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import React, { useCallback, useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CreateQueueEvaluation,
  getQaQueueCampaigns,
  getQaQueueTeams,
} from "./State/QaDuck";
import { getQaTeams } from "./State/QaTeamsDuck";
import {
  availableDispositionsOptions,
  getCampaignDispositions,
  getCampaignTeams,
} from "../CampaignsManagement/State/CampaingsDuck";
import SelectWrapper from "../../../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../../../Components/FormComponents/TextfieldWrapper";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import {
  RESET_LEAD_SALE_EVAL,
  getEvalSaleByUniId,
} from "../QaAgent/State/AgentEvaluationsDuck";
import withAuth from "../../../../../Hoc/withAuth";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import { useNavigate } from "react-router-dom";

const CreateNewEvaluation = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [idActiveSale, setIdActiveSale] = React.useState(false);
  const [activeUniqueId, setActiveUniqueId] = React.useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (idActiveSale && activeUniqueId) {
      dispatch(getEvalSaleByUniId(activeUniqueId));

      setIdActiveSale(false);
    }
  }, [idActiveSale, activeUniqueId, dispatch]);

  useEffect(() => {
    dispatch({ type: RESET_LEAD_SALE_EVAL });
    dispatch(getQaTeams());
    dispatch(getQaQueueTeams(id));
    dispatch(getQaQueueCampaigns(id));
  }, [dispatch, id]);

  const Qa = useSelector((state) => state.Qa);
  const { qaQueueTeams, linkedCampaigns, CreateQEvalSuccess } = Qa;

  const { AgentEvaluations } = useSelector((state) => state.QAAgent);
  const { leadAndSale, leadAndSaleError } = AgentEvaluations;

  let activeQaTeams = qaQueueTeams.filter((team) => team.active === true);

  const [selectedCampaign, setSelectedCampaign] = React.useState("");
  useEffect(
    () => setSelectedCampaign(leadAndSale?.cm_lead?.cc_campaign?.id || ""),
    [leadAndSale]
  );

  const GetCampaignDispo = useCallback(() => {
    if (selectedCampaign) {
      dispatch(getCampaignDispositions(selectedCampaign));
      dispatch(availableDispositionsOptions(selectedCampaign));
      dispatch(getCampaignTeams(selectedCampaign));
    }
  }, [dispatch, selectedCampaign]);

  useEffect(() => {
    GetCampaignDispo();
  }, [GetCampaignDispo]);

  const teamMembers = [];
  activeQaTeams?.forEach((team) => {
    return (
      team?.qa_team?.qa_teams_users?.length > 0 &&
      teamMembers.push(...team?.qa_team?.qa_teams_users)
    );
  });

  const Campaigns = useSelector((state) => state.Campaigns);

  const { availableDispositions: disList, campaignTeams } = Campaigns;

  const [campaignTeamsList, setCampaignTeamsList] = React.useState([]);

  useEffect(() => {
    if (campaignTeams && campaignTeams.length > 0) {
      setCampaignTeamsList(
        campaignTeams.map((team) => team.cc_team.cc_teams_users)
      );
    }
  }, [campaignTeams]);

  let { cc_disposition_lists } = disList;

  // Get Dispositions out of list

  const availableDispositions = [];

  cc_disposition_lists &&
    cc_disposition_lists?.map((cc_disposition_lists) => {
      return availableDispositions.push(
        ...cc_disposition_lists?.cc_dispositions
      );
    });

  const findDisposition = (id) => {
    return availableDispositions?.find((disposition) => disposition?.id === id);
  };

  return (
    <div>
      <PageHeader
        title="Create New Evaluation"
        subheader="Create a new evaluation for this queue"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
        ]}
      />

      <AlertPopup
        open={CreateQEvalSuccess}
        message="Evaluation Created Successfully"
        severity="success"
      />

      <Formik
        initialValues={{
          uni_id: leadAndSale?.reference_no || "",
          disposition_id:
            (leadAndSale?.cm_sales_dispositions?.length > 0 &&
              leadAndSale?.cm_sales_dispositions[0]?.disposition_id) ||
            "",
          disposition_detail_id:
            (leadAndSale?.cm_sales_dispositions?.length > 0 &&
              leadAndSale?.cm_sales_dispositions[0]?.disposition_detail_id) ||
            "",
          queue_id: id,
          user_id: "",
          agent_id: leadAndSale?.User?.id || "",
          stage: "",
          note1: "",
          note2: "",
          note3: "",
          note4: "",
          note5: "",
          updated_reason: "Manually Created Evaluation",
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          uni_id: Yup.string().required("Required"),
          disposition_id: Yup.string().required("Required"),

          queue_id: Yup.string().required("Required"),
          user_id: Yup.string().required("Required"),
          agent_id: Yup.string().required("Required"),
          stage: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          dispatch(CreateQueueEvaluation(id, values));

          setTimeout(
            () => navigate(`/CDA/QaQueuesSetup/${id}/Evaluations`),
            2000
          );
        }}>
        {({ values, setFieldValue }) => (
          <Form>
            <Card>
              <List dense subheader={<ListSubheader>Evaluation</ListSubheader>}>
                {" "}
              </List>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack direction="row">
                      <TextField
                        fullWidth
                        label="Unique Identifier (Reference Number)"
                        name="uni_id"
                        variant="outlined"
                        value={values.uni_id}
                        onChange={(event) => {
                          setFieldValue("uni_id", event.target.value);
                          setActiveUniqueId(event.target.value);
                        }}
                      />
                      {/* :( */}
                      <Button
                        onClick={() => {
                          setIdActiveSale(!idActiveSale);
                        }}>
                        Find Sale By Unique ID (Reference Number)
                      </Button>
                    </Stack>
                    {leadAndSaleError && (
                      <Alert severity="error">
                        No Sale Found with that Unique ID (Reference Number)
                      </Alert>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={2}>
                      <FormControl fullWidth>
                        <InputLabel id="select_campaign_label">
                          Select Campaign
                        </InputLabel>
                        <Select
                          labelId="select_campaign_label"
                          id="select_campaign"
                          value={selectedCampaign}
                          label="Select Campaign"
                          onChange={(event) => {
                            setSelectedCampaign(event.target.value);
                          }}>
                          {linkedCampaigns?.map((campaign) => {
                            return (
                              <MenuItem
                                key={campaign.campaign_id}
                                value={campaign.campaign_id}>
                                {campaign.cc_campaign.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <SelectDisposition
                        availableDispositions={availableDispositions}
                      />
                      {findDisposition(values?.disposition_id)
                        ?.cc_disposition_details &&
                        findDisposition(values?.disposition_id)
                          ?.cc_disposition_details?.length > 0 && (
                          <SelectDispositionDetail
                            availableDispositionsDetails={
                              findDisposition(values?.disposition_id)
                                ?.cc_disposition_details
                            }
                          />
                        )}
                    </Stack>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={2}>
                      <SelectWrapper
                        name="user_id"
                        label="Select User (Person doing the evaluation)"
                        fullWidth
                        variant="outlined"
                        options={teamMembers.map((team) => {
                          return {
                            value: team.user_id,
                            label: team?.User?.name,
                          };
                        })}
                      />

                      <Grid item xs={12}>
                        {campaignTeamsList && campaignTeamsList[0] && (
                          <SelectWrapper
                            name="agent_id"
                            label="Agent (Person Being Evaluated)"
                            fullWidth
                            variant="outlined"
                            options={campaignTeamsList[0]?.map((team) => {
                              return {
                                value: team.user_id,
                                label: team?.User?.name,
                              };
                            })}
                          />
                        )}
                      </Grid>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <SelectWrapper
                      name="stage"
                      label="stage"
                      fullWidth
                      variant="outlined"
                      options={[
                        { value: "New", label: "New" },
                        { value: "Re-evaluate", label: "Re-evaluate" },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldWrapper
                      name="note1"
                      label="Note 1"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldWrapper
                      name="note2"
                      label="Note 2"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldWrapper
                      name="note3"
                      label="Note 3"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextfieldWrapper
                      name="note4"
                      label="Note 4"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Stack sx={{ mt: 2 }}>
              <Button fullWidth variant="contained" type="submit">
                Save
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withAuth(CreateNewEvaluation);

const SelectDisposition = ({ availableDispositions }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const handleChange = (e) => {
    let id = e.target.value;

    setFieldValue("disposition_detail_id", "");
    setFieldValue("disposition_id", id);
  };

  return (
    <FormControl error={errors["disposition_id"] ? true : false} fullWidth>
      <InputLabel id="disposition_label_id">Disposition</InputLabel>
      <Select
        labelId="disposition_label_id"
        id="disposition_id"
        value={values["disposition_id"]}
        label="Disposition"
        onChange={handleChange}>
        {availableDispositions?.map((disposition) => (
          <MenuItem key={disposition.id} value={disposition.id}>
            {disposition.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const SelectDispositionDetail = ({ availableDispositionsDetails }) => {
  const { values, setFieldValue, setErrors, errors } = useFormikContext();

  const [dispositionDetail, setDispositionDetail] = React.useState(
    values["disposition_detail_id"]
  );

  useEffect(() => {
    if (!dispositionDetail && availableDispositionsDetails.length > 0) {
      setErrors({
        ...errors,
        disposition_detail_id: "Disposition Detail is required",
      });
    }
  }, [setErrors, errors, dispositionDetail, availableDispositionsDetails]);

  const handleChange = (e) => {
    let id = e.target.value;
    setDispositionDetail(id);

    setFieldValue("disposition_detail_id", id);
  };

  return (
    <FormControl
      error={errors["disposition_detail_id"] ? true : false}
      fullWidth>
      <InputLabel id="disposition_detail_id_label">
        disposition_detail_id
      </InputLabel>
      <Select
        labelId="disposition_detail_id_label"
        id="disposition_detail_id"
        value={dispositionDetail}
        label="disposition_detail_id"
        onChange={handleChange}>
        {availableDispositionsDetails.map((disposition) => (
          <MenuItem key={disposition.id} value={disposition.id}>
            {disposition.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
