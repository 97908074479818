import { Alert, Box, LinearProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import DateRangeSelector from "../../../../../../Components/FormComponents/DateRangeSelector";
import withAuth from "../../../../../../Hoc/withAuth";

const CampaignQaReports = ({ user }) => {
  const { token } = user;
  const [value, setValue] = React.useState([
    dayjs().startOf("month"),
    dayjs().endOf("month"),
  ]);
  const { data, isLoading, refetch, isRefetching } = useQuery(
    ["GetQaStats"],
    () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/reports/QaReports/campaigns?startDate=${value[0]}&endDate=${value[1]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  );

  useEffect(() => {
    refetch();
  }, [value, refetch]);

  let campaigns = data?.data?.data;

  const columns = [
    {
      field: "campaignName",
      headerName: "Campaign Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "startDate",
      headerName: "From",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD-MMM-YYYY");
      },
    },
    {
      field: "endDate",
      headerName: "To",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD-MMM-YYYY");
      },
    },
    {
      field: "totalEvaluations",
      headerName: "Total Evaluations",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "averagePercentage",
      headerName: "Average Percentage",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "completedEvaluations",
      headerName: "Completed Evaluations",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "CompletedEvaluationsPercentage",
      headerName: "Percentage of Completed Evaluations",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "FailedEvaluation",
      headerName: "Failed Evaluations",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "totalFailedPercentage",
      headerName: "Percentage of Failed Evaluations",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "TotalEvaluationDispute",
      headerName: "Total Evaluation Dispute",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "TotalEvaluationBusy",
      headerName: "Total Evaluation Busy",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "TotalEvaluationRe_evaluate",
      headerName: "Total Evaluation Re-evaluate",
      flex: 1,
      minWidth: 200,
    },
  ];

  return (
    <Stack sx={{ mt: 3 }}>
      <DateRangeSelector value={value} setValue={setValue} />

      {(isRefetching || isLoading) && <LinearProgress />}

      <Box sx={{ height: "60vh", mt: 4, width: "80vw" }}>
        {campaigns && campaigns.length > 0 ? (
          <DataGridPremium
            rows={campaigns}
            columns={columns}
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: {
                sx: {
                  backgroundColor: "background.paper",
                },
              },
            }}
          />
        ) : (
          <Alert severity="info">No Data Found</Alert>
        )}
      </Box>
    </Stack>
  );
};

export default withAuth(CampaignQaReports);
