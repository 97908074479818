import React from "react";
import Button from "@mui/material/Button";
import { RestoreFromTrash } from "@mui/icons-material";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import { useDispatch, useSelector } from "react-redux";
import { RestoreUserRequest } from "../../../Redux/Ducks/AdminUserManagement";
import { CircularProgress } from "@mui/material";
export default function RestoreUser({ id }) {
  const dispatch = useDispatch();

  const AdminUsers = useSelector((state) => state.AdminUsers);
  const { restoreUserError, restoreUserLoading } = AdminUsers;

  const handleRestore = () => {
    dispatch(RestoreUserRequest({ id }));
  };

  return (
    <div>
      {restoreUserLoading ? (
        <CircularProgress />
      ) : (
        <Button color="secondary" onClick={handleRestore}>
          <RestoreFromTrash />
        </Button>
      )}
      <AlertPopup
        severity="error"
        message="User Failed to Restore"
        open={restoreUserError}
      />
    </div>
  );
}
