import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import {
  assignCompanyModuleToUser,
  ASSIGN_COMPANY_MODULES_RESET,
  getCompanyModules,
} from "../../../Redux/Ducks/AdminCompniesDuck";
import { GetSingleUser } from "../../../Redux/Ducks/AdminUserManagement";

import RemoveModule from "./DeleteModule";

const AssignCompanyModules = ({ id, user_id, User }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetForm();
    setOpen(false);
  };

  const Companies = useSelector((state) => state.Companies);

  const {
    companyModules,
    assignCompanyModulesSuccess,
    assignCompanyModulesError,
  } = Companies;

  const [selectedModule, setSelectedModule] = useState("");

  useEffect(() => {
    dispatch(getCompanyModules(id));
  }, [dispatch, id]);

  const resetForm = () => {
    setSelectedModule("");
    dispatch(GetSingleUser(user_id));
    dispatch({ type: ASSIGN_COMPANY_MODULES_RESET });
  };

  const handleAssignCompanyModules = () => {
    dispatch(
      assignCompanyModuleToUser({
        user_id,
        module_id: selectedModule,
        user_type: "user",
      })
    );
    resetForm();
  };

  return (
    <>
      <Button sx={{ mb: 2 }} variant="contained" onClick={handleClickOpen}>
        Assign Module to User
      </Button>
      <Stack direction="row" spacing={2}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "background.paper" }}>
                <TableCell>Module name</TableCell>
                <TableCell>Description</TableCell>

                <TableCell align="right">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {User?.Modules_users &&
                User?.Modules_users?.map((module) => {
                  let module_id = module?.module_id;
                  let module_name = companyModules?.find(
                    (module) => module.id === module_id
                  )?.name;

                  return (
                    <TableRow
                      key={module.module_id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      user_id={user_id}
                      module_id={module_id}
                      name={module_name}>
                      <TableCell component="th" scope="row">
                        {module_name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        This is the module Description
                      </TableCell>
                      <TableCell component="th" align="right" scope="row">
                        <RemoveModule
                          module_id={module_id}
                          user_id={user_id}
                          id={id}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Assign Module to User</DialogTitle>
        <DialogContent>
          <Stack mt={4}>
            <FormControl fullWidth>
              <InputLabel id="ModuleSelector">Module</InputLabel>
              <Select
                labelId="ModuleSelector"
                id="ModuleSelector"
                value={selectedModule}
                label="Module"
                onChange={(event) => setSelectedModule(event.target.value)}>
                {companyModules?.map((module) => (
                  <MenuItem key={module.id} value={module.id}>
                    {module.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Stack mt={2}>
              <Box>
                <Button
                  variant="contained"
                  onClick={handleAssignCompanyModules}>
                  Submit
                </Button>
              </Box>
            </Stack>

            <AlertPopup
              severity="success"
              message="Assigned Module Successfully"
              open={assignCompanyModulesSuccess}
            />
            <AlertPopup
              severity="error"
              message="Failed to assign module"
              open={assignCompanyModulesError}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssignCompanyModules;
