import * as React from "react";
import {
  Button,
  LinearProgress,
  Stack,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Alert,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CreateQuestionGroupForm from "../components/CreateQuestionGroupForm";
import SelectExistingQuestionGroup from "../components/SelectExistingQuestionGroup";
import EditQuestionGroup from "../components/EditQuestionGroup";
import DeActivateQuestionGroup from "../components/DeActivateQuestionGroup";
import axios from "axios";
import { useParams } from "react-router-dom";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";
import CreateQuestion from "../components/CreateQuestion";
import QuestionList from "./QuestionList";

import DeActivateQuestions from "../components/DeActivateQuestions";

export default function QuestionsGroupsMain() {
  const { id, groupId } = useParams();
  const [viewInactive, setViewInactive] = React.useState(false);
  const queryClient = useQueryClient();
  //Queries for handling question groups

  const getActiveQuestionGroups = useQuery({
    queryKey: ["getActiveQuestionGroups"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/group/?active=true&campaignId=${id}`
      ),
  });

  getActiveQuestionGroups.data?.data?.data.sort((a, b) => a.order - b.order);

  const getInactiveQuestionGroups = useQuery({
    queryKey: ["getInactiveQuestionGroups"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/group/?active=false`
      ),
    enabled: viewInactive,
  });

  const createQuestionGroups = useMutation({
    mutationFn: async (data) =>
      await axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/group`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["getActiveQuestionGroups"]);
    },
  });

  const editQuestionGroups = useMutation({
    mutationFn: async (data) =>
      await axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/group/${data.id}`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["getActiveQuestionGroups"]);
    },
  });

  const deactivateQuestionGroups = useMutation({
    mutationFn: async (data) =>
      await axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/group/${data.id}?active=false`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["getActiveQuestionGroups"]);
      queryClient.invalidateQueries(["getInactiveQuestionGroups"]);
    },
  });

  const activeGroups = getActiveQuestionGroups?.data?.data?.data || [];
  const inactiveGroups = getInactiveQuestionGroups?.data?.data?.data || [];
  //------------------------------------------------------------------------------------------------------------------

  //Queries for handling questions

  const createQuestion = useMutation({
    mutationFn: async (data) =>
      await axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${data.id}/question/?active=true`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["getActiveQuestionGroups"]);
    },
  });

  const editQuestions = useMutation({
    mutationFn: async (data) => {
      return await axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/question/${data.id}`,
        data
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getActiveQuestionGroups"]);
    },
  });

  const deactivateQuestions = useMutation({
    mutationFn: async (data) => {
      return await axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/question/${data.id}`,
        data
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getActiveQuestionGroups"]);
      queryClient.invalidateQueries(["getDeactiveGroupQuestions"]);
    },
  });

  //------------------------------------------------------------------------------------------------------

  const getAllQuestionGroupsByCampaignSpecific = useQuery({
    queryKey: ["getAllQuestionGroupsByCampaignSpecific"],

    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/select?active=true&campaign_specific=false`
      ),
  });

  let selectGroups =
    getAllQuestionGroupsByCampaignSpecific?.data?.data?.data || [];

  const createSelectedQuestionGroup = useMutation({
    mutationFn: async (data) =>
      await axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${id}/select`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["getActiveQuestionGroups"]);
      queryClient.invalidateQueries(["getAllQuestionGroupsByCampaignSpecific"]);
    },
  });

  //--------------------------------------------------------------------------------
  

  //Loading status when fetching data

  if (getActiveQuestionGroups.isLoading) {
    return <LinearProgress />;
  }
 // return
  return (
    <div>
      {getActiveQuestionGroups.isError && (
        <Alert severity="error">Error occurred fetching question groups</Alert>
      )}

      <Stack sx={{ mb: 3, mt: 3 }} direction="row" justifyContent="flex-start">
        <CreateQuestionGroupForm createQuestionGroups={createQuestionGroups} />

        <SelectExistingQuestionGroup
          activeGroups={activeGroups}
          createSelectedQuestionGroup={createSelectedQuestionGroup}
          selectGroups={selectGroups}
          groupId={groupId}
        />
      </Stack>
      <Stack sx={{ mb: 4 }}>
        {activeGroups.map((group) => {
          const calculateTotalWeight = (groupId) => {
            return group.question
              .filter(
                (question) =>
                  question.group_id === groupId &&
                  question.question_type === "Score"
              )
              .reduce((sum, question) => sum + question.value, 0);
          };

          return (
            <Card key={group.id} variant="outlined" sx={{ mb: 3 }}>
              <CardHeader
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{group.name}</span>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>{group.group_type} Group</span>
                      <CreateQuestion
                        createQuestion={createQuestion}
                        group={group}
                      />

                      <CardActions disableSpacing>
                        <EditQuestionGroup
                          editQuestionGroups={editQuestionGroups}
                          group={group}
                        />
                        <DeActivateQuestionGroup
                          deactivateQuestionGroups={deactivateQuestionGroups}
                          group={group}
                        />
                      </CardActions>
                    </div>
                  </div>
                }
              />
              <CardContent>
                <QuestionList activeGroups={activeGroups} group={group}  editQuestions={editQuestions} deactivateQuestions={deactivateQuestions}/>
                <Stack
                  sx={{
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ flexGrow: 1 }} component="div">
                    {group.group_type === "Score" && (
                      <Card
                        sx={{
                          display: "inline-flex",
                          px: 2,
                          py: 1,
                          borderRadius: 1,
                          color: (theme) => theme.palette.primary.main,
                        }}
                      >
                        Total Weight: {calculateTotalWeight(group.id)}
                      </Card>
                    )}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <InactiveQuestions
                    group={group}
                    deactivateQuestions={deactivateQuestions}
                  />
                </Stack>
              </CardContent>
            </Card>
          );
        })}

        <Button
          sx={{ my: 2 }}
          fullWidth
          color="error"
          variant={viewInactive ? "contained" : "outlined"}
          onClick={() => setViewInactive(!viewInactive)}
        >
          {viewInactive ? "View Active Groups" : "View Inactive Groups"}
        </Button>

        {viewInactive && (
          <Stack>
            {inactiveGroups.map((group) => (
              <Card key={group.id} variant="outlined" sx={{ mb: 3 }}>
                <CardHeader
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{group.name}</span>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CardActions disableSpacing>
                          <DeActivateQuestionGroup
                            deactivateQuestionGroups={deactivateQuestionGroups}
                            group={group}
                          />
                        </CardActions>
                      </div>
                    </div>
                  }
                />
              </Card>
            ))}
          </Stack>
        )}
      </Stack>

      <AlertPopup
        open={createQuestionGroups.isError}
        severity="error"
        message="Error creating question group"
      />
      <AlertPopup
        open={editQuestionGroups.isError}
        severity="error"
        message="Error editing question group"
      />
      <AlertPopup
        open={deactivateQuestionGroups.isError}
        severity="error"
        message="Error deactivating question group"
      />
      <AlertPopup
        open={createQuestionGroups.isSuccess}
        severity="success"
        message="Question group created successfully"
      />
      <AlertPopup
        open={editQuestionGroups.isSuccess}
        severity="success"
        message="Question group updated successfully"
      />
      <AlertPopup
        open={deactivateQuestionGroups.isSuccess}
        severity="success"
        message="Question group deactivated successfully"
      />

      <AlertPopup
        open={createQuestion.isError}
        severity="error"
        message="Error creating question"
      />
      <AlertPopup
        open={editQuestions.isError}
        severity="error"
        message="Error editing question"
      />
      <AlertPopup
        open={deactivateQuestions.isError}
        severity="error"
        message="Error deactivating question"
      />
      <AlertPopup
        open={createQuestion.isSuccess}
        severity="success"
        message="Question created successfully"
      />
      <AlertPopup
        open={editQuestions.isSuccess}
        severity="success"
        message="Question updated successfully"
      />
      <AlertPopup
        open={deactivateQuestions.isSuccess}
        severity="success"
        message="Question deactivated successfully"
      />
    </div>
  );
}

//------------------------------------------------------------------------------
//Get Inactive

const InactiveQuestions = ({ group, deactivateQuestions }) => {
  const [viewInactiveQuestions, setViewInactiveQuestions] =
    React.useState(false);

  const getDeactiveGroupQuestions = useQuery({
    queryKey: ["getDeactiveGroupQuestions", group.id],

    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${group.campaign_id}/group/${group.id}/question?active=false`
      ),
  });

  let data = getDeactiveGroupQuestions?.data?.data?.data;

  return (
    <>
      <Button
        color="error"
        sx={{ flexGrow: 1, color: "error" }}
        variant={viewInactiveQuestions ? "outlined" : "text"}
        onClick={() => setViewInactiveQuestions(!viewInactiveQuestions)}
      >
        {viewInactiveQuestions
          ? "View Active Questions"
          : "View Inactive Questions"}
      </Button>

      {viewInactiveQuestions && (
        <>
          {data && data.length > 0 ? (
            data.map((question) => (
              <Card
                key={question.id}
                sx={{
                  borderRadius: 1,
                  p: 1,
                  mt: 1,
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={20} sx={{ flexGrow: 1 }}>
                  {question.name}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <DeActivateQuestions
                    deactivateQuestions={deactivateQuestions}
                    question={question}
                  />
                </Stack>
              </Card>
            ))
          ) : (
            <Typography>No Inactive Questions</Typography>
          )}
        </>
      )}
    </>
  );
};

