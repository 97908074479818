import MainAppContainer from "../../../Components/Containers/MainAppContainer"
import PageHeader from "../../../Components/Bits/PageHeader"
import TabRouter from "../../../Components/Navigation/TabRouter"
import { Stack } from "@mui/material"
import { Outlet, useNavigate } from 'react-router-dom'
import withAuth from "../../../Hoc/withAuth"

const OnboardingCEO = ({ user, modules }) => {
    const navigate = useNavigate()

    if(!modules?.includes("oe-ceo")){
        navigate('/')
    }
    
    const paths = [
        {
            name: 'Employees',
            location: 'employees',
        },
        {
            name: 'Requisition',
            location: ['requisition', 'AddRequisition'],
        },
        {
            name: 'Positions',
            location: 'positions',
        },
        {
            name: 'Departments',
            location: 'departments',
        },
    ]
    
    return (
   
            <MainAppContainer maxWidth="xl" disableGutters>
                <PageHeader
                    title="Onboarding Employee"
                    subheader="CEO"
                    breadcrumbs={[
                    {
                        title: "Dashboard",
                        href: "/",
                    },
                    {
                        title: "Onboarding Employee - CEO",
                        href: "/onboarding/ceo",
                    },
                    ]}
                />
                
                <TabRouter paths={paths} />

                <Stack sx={{ mt: 4}}>
                    <Outlet />
                </Stack>
            </MainAppContainer>
   
    )
}

export default withAuth(OnboardingCEO)