import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styled from "@emotion/styled";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  editDispositionInList,
  EditLinkedDetail,
  linkDetailToDispositionInList,
  RESET_DISPOSITIONS,
} from "../../State/DispostionsDuck";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Form, Formik } from "formik";
import SelectWrapper from "../../../../../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";

export default function ListDispositions({
  dispositions,
  activeDispositionDetails,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: RESET_DISPOSITIONS });
  }, [dispatch]);

  const Dispositions = useSelector((state) => state.Dispositions);

  const { addedDisposition, detailAdded, detailRemoved } = Dispositions;

  return (
    <>
      {" "}
      <AlertPopup
        open={addedDisposition}
        message="Disposition added"
        severity="info"
      />
      <AlertPopup
        open={detailAdded}
        message="Disposition Detail added"
        severity="success"
      />
      <AlertPopup
        open={detailRemoved}
        message="Disposition Detail Removed"
        severity="info"
      />
      <TableContainer
        sx={{
          borderWidth: 1,
          borderColor: "primary.main",
        }}
        component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>Disposition</StyledTableCell>
              <StyledTableCell align="right">_type</StyledTableCell>
              <StyledTableCell align="right">Active</StyledTableCell>
              <StyledTableCell align="right">Remove/Activate</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dispositions
              ?.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
              .map((row) => {
                return (
                  <Row
                    key={row.name}
                    row={row}
                    activeDispositionDetails={activeDispositionDetails}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function Row(props) {
  const { row, activeDispositionDetails } = props;
  const [open, setOpen] = React.useState(false);

  // filter used Disposition Details from activeDispositionDetails

  let activeDetails = row.cc_disposition_details.filter(
    (detail) => detail.active === true
  );

  let inactiveDetails = row.cc_disposition_details.filter(
    (detail) => detail.active === false
  );

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.type}</TableCell>
        <TableCell align="right">
          <Chip
            label={row?.active ? "Active" : "Disabled"}
            variant="outlined"
            color={row?.active ? "secondary" : "error"}
          />
        </TableCell>
        <TableCell align="right">
          <ActivateOrDisableDispositionInList data={row} />
        </TableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell
          sx={{ backgroundColor: "background.default" }}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ my: 1 }}>
                <Typography variant="body2" gutterBottom component="div">
                  Disposition Options
                </Typography>
                <LinkDispositionDetails
                  disposition={row}
                  disposition_details={activeDispositionDetails}
                />
              </Stack>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Is Active</StyledTableCell>
                    <StyledTableCell align="right">Remove</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeDetails?.map((detail) => {
                    return (
                      <TableRow key={detail?.id}>
                        <StyledTableCell component="th" scope="row">
                          {detail?.name}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <Chip
                            label={detail?.active ? "Active" : "Disabled"}
                            variant="outlined"
                            color={detail?.active ? "default" : "warning"}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          component="th"
                          scope="row">
                          <ActivateOrDisableDispositionDetailInList
                            disposition_id={row.id}
                            data={detail}
                          />
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                  {inactiveDetails?.map((detail) => {
                    return (
                      <TableRow
                        sx={{
                          backgroundColor: "background.paper",
                        }}
                        key={detail?.id}>
                        <StyledTableCell component="th" scope="row">
                          {detail?.name}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <Chip
                            label={detail?.active ? "Active" : "Disabled"}
                            variant="outlined"
                            color={detail?.active ? "default" : "warning"}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          component="th"
                          scope="row">
                          <ActivateOrDisableDispositionDetailInList
                            disposition_id={row.id}
                            data={detail}
                          />
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const LinkDispositionDetails = ({ disposition, disposition_details }) => {
  const newDis = disposition_details?.filter(
    (dispositionDetail) =>
      disposition?.cc_disposition_details
        ?.map((dis) => dis.id)
        ?.indexOf(dispositionDetail?.id) === -1
  );

  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="secondary" onClick={handleClickOpen}>
        Add Detail to Disposition
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle> Add New Detail to Disposition</DialogTitle>
        <Formik
          initialValues={{
            disposition_id: disposition.id || "",
            name: "",
            active: true,
            update_reason: "Created new disposition detail",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Required"),
          })}
          onSubmit={(values) => {
            console.log(values);
            dispatch(
              linkDetailToDispositionInList({
                disposition_id: values.disposition_id,
                values,
              })
            );

            handleClose();
          }}>
          {({ values }) => {
            return (
              <Form>
                <DialogContent>
                  <Stack spacing={2}>
                    <SelectWrapper
                      name="name"
                      label="Select Existing Disposition Detail"
                      options={newDis.map((detail) => {
                        return {
                          value: detail.name,
                          label: detail.name,
                        };
                      })}
                    />
                    <TextfieldWrapper
                      name="name"
                      label="Create new Disposition Detail"
                      type="text"
                    />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};

const ActivateOrDisableDispositionDetailInList = ({ disposition_id, data }) => {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    data.active = !data.active;
    dispatch(
      EditLinkedDetail({
        dis_id: disposition_id,
        detail_id: data.id,
        dis: data,
      })
    );
    setOpen(false);
  };

  return (
    <div>
      <Button
        color={data.active ? "success" : "error"}
        onClick={handleClickOpen}>
        {!data.active ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          {!data.active ? "Activate" : "Disable"} this Disposition Detail?{" "}
          <br />
        </DialogTitle>
        <DialogTitle>{data?.name}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color={!data.active ? "success" : "error"}
            onClick={() => handleSubmit()}>
            {!data.active ? "Activate" : "Disable"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ActivateOrDisableDispositionInList = ({ disposition_id, data }) => {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    data.active = !data.active;
    dispatch(editDispositionInList(data));
    setOpen(false);
  };

  return (
    <div>
      <Button
        color={data.active ? "success" : "error"}
        onClick={handleClickOpen}>
        {!data.active ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          {!data.active ? "Activate" : "Disable"} this Disposition? <br />
        </DialogTitle>
        <DialogTitle>{data?.name}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color={!data.active ? "success" : "error"}
            onClick={() => handleSubmit()}>
            {!data.active ? "Activate" : "Disable"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
