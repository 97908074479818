import { Button, LinearProgress, Stack, Chip } from "@mui/material";
import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "./State/CampaingsDuck";

import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

const Campaigns = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [rowsPerPage] = React.useState(1000);
  const [page] = React.useState(0);

  useEffect(() => {
    dispatch(getCampaigns({ rowsPerPage, page }));
  }, [dispatch, rowsPerPage, page]);

  const Campaigns = useSelector((state) => state.Campaigns);
  const { campaigns, loading } = Campaigns;

  const link = (id) => {
    navigate(`/CDA/ManageCampaign/${id}/edit/Campaign`);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "campaignName", headerName: "Campaign Name", width: 250 },
    { field: "client", headerName: "Client", width: 200 },
    { field: "campaignType", headerName: "Campaign Type", width: 130 },
    {
      field: "manageLeads",
      headerName: "Manage Leads",
      width: 180,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="warning"
          onClick={() =>
            navigate(`/CDA/ManageCampaign/${params.row.id}/edit/LeadsManage`)
          }>
          Manage Leads
        </Button>
      ),
    },
    {
      field: "manageCampaign",
      headerName: "Manage Campaign",
      width: 180,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => link(params.row.id)}>
          Manage Campaign
        </Button>
      ),
    },
    {
      field: "state",
      headerName: "State",
      width: 180,
      renderCell: (params) => (
        <Chip
          label={params.row.state}
          variant="outlined"
          color={
            params.row.state === "stopped"
              ? "error"
              : params.row.state === "draft"
              ? "info"
              : "success"
          }
        />
      ),
    },
  ];

  const rows =
    campaigns?.rows && campaigns?.rows?.length > 0
      ? campaigns?.rows?.map((campaign) => {
          return {
            id: campaign.id,

            campaignName: campaign.name,

            client: campaign.cc_client?.name,

            campaignType: campaign.campaign_type,

            manageLeads: campaign.id,

            manageCampaign: campaign.id,

            state: campaign.state,
          };
        })
      : [];

  return (
    <div>
      <Stack sx={{ mb: 3 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate("/CDA/CallCenter/CreateCampaign")}>
          Create Campaign
        </Button>
      </Stack>

      {loading && <LinearProgress />}
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGridPremium
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
        />
      </div>
    </div>
  );
};

export default Campaigns;
