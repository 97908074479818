import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LinearProgress, Button, Typography, Alert } from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import axios from "axios";

const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
};

const EmployeeTable = (props) => {
  const navigate = useNavigate();

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const columns = [
    {
      field: "firstname",
      headerName: "First Name",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "position",
      headerName: "Job Title",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "manager",
      headerName: "Manager",
      width: 140,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cell_number",
      headerName: "Phone Number",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "department",
      headerName: "Department",
      width: 170,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "approve",
      headerName: "Status",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const approve = params.value;

        if (approve === true) {
          return <Typography color="success.main">Completed</Typography>;
        } else {
          return <Typography color="error.main">Not Completed</Typography>;
        }
      },
    },
    {
      field: "id",
      headerName: "Actions",
      width: 125,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() =>
            navigate(
              `/register?employee_id=${params.row.id}`
            )
          }
        >
          View
        </Button>
      ),
    },
  ];

  const { isLoading, isError, data, error } = useQuery(
    [`get${props.module}Employees`],
    async () =>
      fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/employees/`,
        config
      )
  );

  if (isLoading && !isError && !data) return <LinearProgress />;

  if (isError) return <Alert severity="error">{error.message}</Alert>;

  return (
    <>
      
        <div style={{ height: "70vh", width: "100%", mt: 3 }}>
          <DataGridPremium
            rows={data?.data?.map((employee) => ({
              id: employee?.id,
              firstname: employee?.firstname,
              lastname: employee?.lastname,
              position: employee?.Requisition?.Position?.job_title,
              manager: `${employee?.Requisition?.User?.name} ${employee?.Requisition?.User?.surname}`,
              cell_number: employee?.cell_number,
              approve: employee?.completed,
              department: employee?.Requisition?.Department?.department_name,
            }))}
            density="comfortable"
            columns={columns}
            slots={{ toolbar: GridToolbar }}
          />
        </div>
      
    </>
  );
};

export default EmployeeTable;
