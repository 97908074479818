import React from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Alert, CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  RemoveModuleRequest,
  REMOVE_MODULE_RESET,
} from "../../../Redux/Ducks/AdminCompniesDuck";
import { GetSingleUser } from "../../../Redux/Ducks/AdminUserManagement";
import AlertPopup from "../../../Components/Bits/AlertPopup";

export default function RemoveModule({ module_id, user_id }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const Companies = useSelector((state) => state.Companies);
  const { removeModuleLoading, removeModuleError, removeModuleSuccess } =
    Companies;

  const handleClickOpen = () => {
    setOpen(true);
  };

  let id = user_id;

  const handleClose = () => {
    dispatch(GetSingleUser(id));
    dispatch({ type: REMOVE_MODULE_RESET });
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(RemoveModuleRequest({ module_id, user_id }));
    setTimeout(() => {
      handleClose();
    }, 1000);
  };

  return (
    <div>
      <Button color="error" onClick={handleClickOpen}>
        Remove Module
      </Button>
      <Dialog
        sx={{ border: "5px solid red" }}
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}>
        <DialogTitle> Remove Module</DialogTitle>
        <DialogContent>
          <Typography align="center" color="textPrimary" variant="h6">
            Are you sure you want to Remove this Module ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {removeModuleSuccess ? "close" : "cancel"}
          </Button>

          {removeModuleLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Button variant="outlined" color="error" onClick={handleDelete}>
                Confirm
              </Button>
            </>
          )}

          {removeModuleError && (
            <Alert severity="error">
              Error Occurred! Refresh the page and try again
            </Alert>
          )}
        </DialogActions>
        <AlertPopup
          message="Removed Successfully"
          open={removeModuleSuccess}
          severity="success"
        />
      </Dialog>
    </div>
  );
}
