import axios from "axios";
import {
  GET_ADMIN_USERS,
  GET_ADMIN_USERS_SUCCESS,
  GET_ADMIN_USERS_FAILURE,
  GET_ADMIN_USER,
  GET_ADMIN_USER_SUCCESS,
  GET_ADMIN_USER_FAILURE,
  ADMIN_EDIT_USER,
  ADMIN_EDIT_USER_SUCCESS,
  ADMIN_EDIT_USER_FAILURE,
  RESET_ADMIN_USER_STATE,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  ADD_USER_ROLE,
  ADD_USER_ROLE_SUCCESS,
  ADD_USER_ROLE_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_RESET,
  RESTORE_USER,
  RESTORE_USER_SUCCESS,
  RESTORE_USER_FAILURE,
  POPULATE_USER,
  GET_USERS_OF_MODULE,
  GET_USERS_OF_MODULE_SUCCESS,
  GET_USERS_OF_MODULE_FAILURE,
} from "../ActionNames/AllActions";

// // Language: javascript
// Path: src\Redux\Ducks\AdminUserManagement.js
// Compare this snippet from src\Redux\Ducks\AuthDuck.js:
//
// //THIS IS THE REDUCER

const initialState = {
  allUsers: [],
  User: {},
  usersOfModule: [],
};

const AdminUserManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_OF_MODULE:
      return {
        ...state,
        usersOfModule: [],
        modulesLoading: true,
      };
    case GET_USERS_OF_MODULE_SUCCESS:
      return {
        ...state,
        modulesLoading: false,
        usersOfModule: action.payload,
      };
    case GET_USERS_OF_MODULE_FAILURE:
      return {
        ...state,
        modulesLoading: false,
        usersOfModule: [],
      };

    case GET_ADMIN_USERS:
      return {
        ...state,
        allUsers: [],
      };
    case GET_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case GET_ADMIN_USERS_FAILURE:
      return {
        ...state,
        allUsers: [],
      };
    case GET_ADMIN_USER:
      return {
        ...state,
      };
    case GET_ADMIN_USER_SUCCESS:
      return {
        ...state,
        User: action.payload,
      };
    case GET_ADMIN_USER_FAILURE:
      return {
        ...state,
        User: {},
      };
    case ADMIN_EDIT_USER:
      return {
        ...state,
      };
    case ADMIN_EDIT_USER_SUCCESS:
      return {
        ...state,
        User: action.payload,
        success: true,
      };
    case ADMIN_EDIT_USER_FAILURE:
      return {
        ...state,
        User: {},
      };

    case ADD_USER:
      return {
        ...state,
        addUserSuccess: false,
        addUserError: false,
        addUserLoading: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        User: action.payload,
        addUserSuccess: true,
        addUserLoading: false,
        success: true,
      };
    case ADD_USER_FAILURE:
      return {
        ...state,
        User: {},
        addUserLoading: false,
        addUserError: true,
      };
    case ADD_USER_ROLE:
      return {
        ...state,
        addUserRoleSuccess: false,
        addUserRoleError: false,
        addUserRoleLoading: true,
      };
    case ADD_USER_ROLE_SUCCESS:
      return {
        ...state,
        User: action.payload,
        addUserRoleSuccess: true,
        addUserRoleLoading: false,
      };
    case ADD_USER_ROLE_FAILURE:
      return {
        ...state,
        addUserLoading: false,
        addUserRoleError: true,
      };

    case POPULATE_USER:
      return {
        ...state,
        User: action.payload,
      };

    case DELETE_USER:
      return {
        ...state,
        deleteUser: false,
        deleteUserError: false,
        deleteUserLoading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        allUsers: action.payload,
        deleteUser: true,
        deleteUserError: false,
        deleteUserLoading: false,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        deleteUser: false,
        deleteUserError: true,
        deleteUserLoading: false,
      };
    case DELETE_USER_RESET:
      return {
        ...state,
        deleteUser: false,
        deleteUserError: false,
        deleteUserLoading: false,
      };
    case RESTORE_USER:
      return {
        ...state,
        restoreUser: false,
        restoreUserError: false,
        restoreUserLoading: true,
      };
    case RESTORE_USER_SUCCESS:
      return {
        ...state,
        allUsers: action.payload,
        restoreUser: true,
        restoreUserError: false,
        restoreUserLoading: false,
      };
    case RESTORE_USER_FAILURE:
      return {
        ...state,
        restoreUser: false,
        restoreUserError: true,
        restoreUserLoading: false,
      };
    case RESET_ADMIN_USER_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default AdminUserManagementReducer;

export const getUsersOfModule = (module) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_USERS_OF_MODULE,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin//UserOfModules/${module}`,
      config,
    );

    dispatch({
      type: GET_USERS_OF_MODULE_SUCCESS,
      payload: data.data,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: GET_USERS_OF_MODULE_FAILURE,
      payload: error.response.data.message,
    });
  }
};

export const GetAllUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ADMIN_USERS,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/users`,
      config,
    );

    dispatch({
      type: GET_ADMIN_USERS_SUCCESS,
      payload: data.content,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: GET_ADMIN_USERS_FAILURE,
      payload: error.response.data.message,
    });
  }
};

export const GetSingleUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ADMIN_USER,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/users/${id}`,
      config,
    );

    dispatch({
      type: GET_ADMIN_USER_SUCCESS,
      payload: data.content,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: GET_ADMIN_USER_FAILURE,
      payload: error.response.data.message,
    });
  }
};

export const EditUserRequest =
  ({ id, values }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_EDIT_USER,
      });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/users/${id}`,
        values,
        config,
      );

      dispatch({
        type: ADMIN_EDIT_USER_SUCCESS,
        payload: data.content,
      });

      // Storing the User Info in the local storage
    } catch (error) {
      dispatch({
        type: ADMIN_EDIT_USER_FAILURE,
        payload: error.response.data.message,
      });
    }
  };

export const AddUserRequest = (values) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_USER,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/users`,
      values,
      config,
    );

    dispatch({
      type: ADD_USER_SUCCESS,
      payload: data.user,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: ADD_USER_FAILURE,
      payload: error.response.data.message,
    });
  }
};

export const AddUserRole =
  ({ user_id, type }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_USER_ROLE,
      });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/userType`,
        { user_id, type },
        config,
      );

      dispatch({
        type: ADD_USER_ROLE_SUCCESS,
        payload: data.data,
      });

      // Storing the User Info in the local storage
    } catch (error) {
      dispatch({
        type: ADD_USER_ROLE_FAILURE,
        payload: error.response.data.message,
      });
    }
  };

export const EditUserRole =
  ({ user_id, type }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_USER_ROLE,
      });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/userType`,
        { user_id, type },
        config,
      );

      dispatch({
        type: ADD_USER_ROLE_SUCCESS,
        payload: data.data,
      });

      // Storing the User Info in the local storage
    } catch (error) {
      dispatch({
        type: ADD_USER_ROLE_FAILURE,
        payload: error.response.data.message,
      });
    }
  };

export const DeleteUserRequest =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_USER,
      });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/users/${id}`,
        config,
      );

      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: data.allUsers,
      });

      // Storing the User Info in the local storage
    } catch (error) {
      dispatch({
        type: DELETE_USER_FAILURE,
        payload: error.response.data.message,
      });
    }
  };

export const RestoreUserRequest =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: RESTORE_USER,
      });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.patch(
        `${process.env.REACT_APP_API_URL}/admin/users/${id}/restore`,
        {},
        config,
      );

      dispatch({
        type: RESTORE_USER_SUCCESS,
        payload: data.allUsers,
      });

      // Storing the User Info in the local storage
    } catch (error) {
      dispatch({
        type: RESTORE_USER_FAILURE,
        payload: error.response.data.message,
      });
    }
  };
