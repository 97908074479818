import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSalaryDeductionsList,
  getSalaryDeductionsList,
  RESET_SALARY_DEDUCTIONS_STATE,
} from "../StateManagers/SalaryDeductionsDuck";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SalaryDeductionFileUploader from "./components/SalaryDeductionFileUploader";

const SalaryDeductionsList = () => {
  const dispatch = useDispatch();

  const [document, setDocument] = useState([]);

  const [uploaded, setUploaded] = useState(false);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    dispatch({ type: RESET_SALARY_DEDUCTIONS_STATE });

    dispatch(getSalaryDeductionsList());
  }, [dispatch]);

  const SalaryDeductionsDuck = useSelector(
    (state) => state.SalaryDeductionsDuck
  );
  const {
    salaryDeductionsList,
    salaryDeductionsLoading,
    salaryDeductionsError,
    salaryDeductionsSuccess,
  } = SalaryDeductionsDuck;

  useEffect(() => {
    setDocument(salaryDeductionsList);
  }, [salaryDeductionsList]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [search, setSearch] = React.useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const [displayList, setDisplayList] = useState(document);

  useEffect(() => {
    if (search !== "") {
      const searchList = document.filter((item) => {
        return (
          item?.company_name?.toLowerCase().includes(search.toLowerCase()) ||
          item.id.toString().includes(search)
        );
      });
      setDisplayList(searchList);
    } else {
      setDisplayList(document);
    }
  }, [search, document]);

  const uploadList = () => {
    dispatch(addSalaryDeductionsList(document));
  };

  if (salaryDeductionsLoading) {
    return (
      <Stack display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Container>
      <Stack display="flex" justifyContent="center">
        {!uploaded ? (
          <SalaryDeductionFileUploader
            setUploaded={setUploaded}
            document={document}
            setDocument={setDocument}
            setErrors={setErrors}
            errors={errors}
          />
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ my: 2 }}>
              <Alert severity="info">Document Loaded </Alert>
              {salaryDeductionsSuccess ? (
                <>
                  <Alert severity="success">Uploaded Success </Alert>
                </>
              ) : (
                <>
                  {errors && errors.length > 0 ? (
                    <Alert severity="error">
                      {errors.map((error) => (
                        <div>{error}</div>
                      ))}
                    </Alert>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          uploadList();
                        }}>
                        Save New List
                      </Button>
                    </>
                  )}
                </>
              )}
            </Stack>
          </>
        )}
      </Stack>

      {errors && errors.length > 0 && (
        <Stack display="flex" justifyContent="center">
          {errors.map((error, index) => {
            return (
              <Alert key={index} severity="error">
                {error}
              </Alert>
            );
          })}
        </Stack>
      )}
      {salaryDeductionsError && errors.length > 0 && (
        <Stack display="flex" justifyContent="center">
          <Alert severity="error">{salaryDeductionsError}</Alert>
        </Stack>
      )}

      <Stack my={3}>
        <TextField
          name="search"
          label="Search"
          variant="outlined"
          onChange={handleSearch}
          fullWidth
        />
      </Stack>

      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
            <Typography variant="h4">Salary Deductions List</Typography>
          </Stack>

          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "80vh" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Account</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Deduction Allowed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{row?.id}</TableCell>
                          <TableCell>{row?.company_name}</TableCell>
                          <TableCell>
                            {row?.deduction_allowed ? (
                              <Chip label="Allowed" color="secondary" />
                            ) : (
                              <Chip label="Not" color="error" />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={document.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SalaryDeductionsList;
