export const StageColors = (stage) => {
  switch (stage) {
    case "New":
      return "primary";
    case "Complete":
      return "secondary";
    case "Fail":
      return "error";
    case "Cancelled":
      return "warning";
    case "Busy":
      return "warning";
    case "Re-evaluate":
      return "primary";
    case "Re-evaluate Dispute":
      return "primary";
    case "Dispute":
      return "error";
    case "Dispute Rejected":
      return "warning";
    case "Dispute Cancelled":
      return "error";
    case "Dispute Re-evaluate":
      return "";
    case "Expired":
      return "default";
    case "Completed":
      return "secondary";
    default:
      return "info";
  }
};

export const StageActionDescription = (stage) => {
  switch (stage) {
    case "New":
      return "New Evaluation";
    case "Complete":
      return "Evaluation Completed";
    case "Fail":
      return "Evaluation Failed";
    case "Cancelled":
      return "Evaluation Cancelled";
    case "Busy":
      return "Evaluation Busy";
    case "Re-evaluate":
      return "Send Evaluation to be Re-evaluate";
    case "Re-evaluate Dispute":
      return "Re-evaluate Evaluation has been requested by the Agent";
    case "Dispute":
      return "Agent has Disputed the results of the Evaluation";
    case "Dispute Rejected":
      return "Qa Agent has Rejected the Dispute";
    case "Completed":
      return "Evaluation Completed";
    default:
      return "Evaluation Completed";
  }
};
