import {
  Alert,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCampaigns } from "./State/CampaingsDuck";
import { getCampaignLeads } from "./State/LeadsDuck";

import LeadsDataGrid from "./Components/Leads/LeadsDataGrid";

const LeadsAdmin = () => {
  const dispatch = useDispatch();

  const Campaigns = useSelector((state) => state.Campaigns);
  const { campaigns } = Campaigns;

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  useEffect(() => {
    dispatch(getCampaigns({ rowsPerPage: 50, page: 0 }));
  }, [dispatch, rowsPerPage, page]);

  const [selectedCampaign, setSelectedCampaign] = React.useState("");

  const handleChange = (event) => {
    setSelectedCampaign(event.target.value);
  };

  useEffect(() => {
    if (selectedCampaign) {
      dispatch(getCampaignLeads(selectedCampaign, rowsPerPage, page));
    }
  }, [dispatch, selectedCampaign, rowsPerPage, page]);

  const Leads = useSelector((state) => state.Leads);

  const { campaignLeads, loading } = Leads;

  // Avoid a layout jump when reaching the last page with empty rows.

  const handleChangeRowsPerPage = (event) => {
    console.log(event);
    // setRowsPerPage(parseInt(event.target.value));
    // setPage(0);
  };

  //Data Grid Rows

  return (
    <Container maxWidth="xl" disableGutters>
      {loading ? (
        <Stack justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select Campaign</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCampaign}
            label="Select Campaign"
            onChange={handleChange}>
            {campaigns &&
              campaigns?.rows?.map((campaign, index) => (
                <MenuItem key={index} value={campaign.id}>
                  {campaign.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      <Stack mt={3}>
        {campaignLeads.rows && campaignLeads.rows.length > 0 ? (
          <LeadsDataGrid
            columnBuffer={2}
            columnThreshold={2}
            total={campaignLeads.count}
            loading={loading}
            data={campaignLeads.rows}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        ) : (
          <Alert severity="info">No Leads Found</Alert>
        )}
      </Stack>
    </Container>
  );
};

export default LeadsAdmin;
