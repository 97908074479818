import {
  Alert,
  Button,
  Card,
  CardActionArea,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import withAuth from "../../../../../Hoc/withAuth";
import ContentItem from "../QaAgent/Components/ContentItem";
import { StageColors } from "../QaAgent/Components/StageColors";
import EditSale from "./components/EditSale";
import NewSale from "./components/NewSale";
import { getLead } from "./State/AgentSalesReducer";
import { getSaleByLead } from "./State/SalesAgentCampaignsDuck";

const LeadSales = ({ user }) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getLead(id));
    dispatch(getSaleByLead(id));
  }, [dispatch, id]);

  const salesAgent = useSelector((state) => state.salesAgent);

  const { leadSales, saleCreated, saleUpdated, error } = salesAgent;

  const AgentSales = useSelector((state) => state.AgentSales);

  const { lead } = AgentSales;

  const salesUsers = leadSales?.map((sale) => sale?.User?.id);

  const Campaigns = useSelector((state) => state.Campaigns);

  const { availableDispositions: disList } = Campaigns;

  let { cc_disposition_lists } = disList;

  const availableDispositions = [];

  cc_disposition_lists &&
    cc_disposition_lists?.map((cc_disposition_lists) => {
      return availableDispositions.push(
        ...cc_disposition_lists?.cc_dispositions
      );
    });

  const findDisposition = (id) => {
    return availableDispositions?.find((disposition) => disposition.id === id);
  };

  return (
    <div>
      <AlertPopup open={saleUpdated} severity="info" message="Sale Updated" />

      <AlertPopup
        open={saleCreated}
        severity="success"
        message="Sale Created"
      />

      {error && (
        <Alert severity="error">
          {" "}
          {error?.data &&
            error?.data?.error?.errors?.length > 0 &&
            error?.data?.error?.errors?.map((err, index) => {
              return (
                <Typography key={index} variant="body2">
                  {err.message}
                </Typography>
              );
            })}
        </Alert>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between">
            <Stack direction="row">
              {salesUsers.length === 0 ||
              salesUsers.includes(user?.id) ||
              user?.userType === "admin" ? (
                <NewSale lead={lead} user={user} />
              ) : (
                <Button variant="contained" color="inherit" disabled>
                  Sale Already Created
                </Button>
              )}
            </Stack>
          </Stack>
        </Grid>

        {leadSales && leadSales.length > 0 ? (
          leadSales.map((sale, index) => {
            return (
              <Grid sx={{ mt: 2 }} item xs={4} key={index}>
                <Card>
                  <List dense subheader={<ListSubheader>Sale</ListSubheader>}>
                    <Divider />

                    <ListItem
                      secondaryAction={
                        <Chip
                          variant="outlined"
                          color={
                            sale.status === "pending"
                              ? "error"
                              : sale.status === "completed"
                              ? "success"
                              : sale.status === "staging"
                              ? "warning"
                              : sale.status === "cancelled"
                              ? "default"
                              : "info"
                          }
                          label={sale.status}
                        />
                      }>
                      <ListItemText
                        primary={
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary">
                              Sales Agent
                            </Typography>
                          </>
                        }
                        secondary={
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body1"
                              color="text.primary">
                              {sale?.User?.name}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    <Divider />
                    <Stack>
                      {user?.id === sale?.User?.id ||
                      user?.userType === "admin" ? (
                        <EditSale sale={sale} user={user} />
                      ) : (
                        <Typography
                          variant="caption"
                          align="center"
                          color="textSecondary">
                          You can only edit your own sales
                        </Typography>
                      )}
                    </Stack>
                    <Divider />
                    <ContentItem
                      title="Reference No"
                      value={sale.reference_no}
                    />
                    <ContentItem title="premium" value={sale.premium} />
                    <ContentItem
                      title="Debit Date"
                      value={dayjs(sale.debit_date).format("DD/MM/YYYY")}
                    />
                    <ContentItem title="Note 1" value={sale.note1} />
                    <ContentItem title="Note 2" value={sale.note2} />
                    {sale?.cm_sales_dispositions?.map((dis) => {
                      let findDetail = findDisposition(
                        dis?.disposition_id
                      )?.cc_disposition_details.find(
                        (item) => item.id === dis?.disposition_detail_id
                      )?.name;

                      return (
                        <Stack key={dis?.id}>
                          <ContentItem
                            title="Disposition"
                            value={findDisposition(dis?.disposition_id)?.name}
                          />
                          {findDetail && (
                            <ContentItem
                              title="Disposition Detail"
                              value={findDetail}
                            />
                          )}
                        </Stack>
                      );
                    })}
                  </List>
                  <Divider />
                  <Evaluation user={user} uni_id={sale?.id} />
                  <Divider />
                </Card>
              </Grid>
            );
          })
        ) : (
          <Stack
            sx={{
              mt: 5,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}>
            <Typography variant="h6" color="text.secondary">
              No Sales
            </Typography>
          </Stack>
        )}
      </Grid>
    </div>
  );
};

export default withAuth(LeadSales);

const Evaluation = ({ uni_id, user }) => {
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getEvals = useQuery([`getSaleEvalsByRef${uni_id}`], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/qa/evaluations/${uni_id}/unique`,
      config
    );
  });

  if (getEvals.isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Typography
        sx={{ mt: 1 }}
        align="center"
        variant="body2"
        color="text.secondary">
        Evaluations
      </Typography>
      {getEvals?.data?.data?.data &&
        getEvals?.data?.data?.data.length > 0 &&
        getEvals?.data?.data?.data.map((val, index) => {
          return (
            <Card key={index} sx={{ m: 1, p: 0 }}>
              <Tooltip title="View Evaluation" placement="top" arrow>
                <CardActionArea
                  onClick={() => navigate(`/CDA/QaAgentHome/${val.id}`)}>
                  <Stack>
                    <Typography
                      sx={{ my: 1 }}
                      align="center"
                      variant="body2"
                      color="text.secondary">
                      Date {new Date(val.updatedAt).toLocaleDateString()}
                    </Typography>
                    <Chip
                      color={StageColors(val.stage)}
                      label={`Stage : ${val.stage}`}
                    />
                  </Stack>
                </CardActionArea>
              </Tooltip>
            </Card>
          );
        })}
    </>
  );
};
