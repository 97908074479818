import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";
import withAuth from "../../../../../../../Hoc/withAuth";

const AllowAgentToCaptureLeads = ({ id, current, user }) => {
  const queryClient = useQueryClient();

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const agentCaptureLeadQuery = useMutation(
    ["agentCaptureLeadQuery", id],
    (data) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/campaignSettings`,
        data,
        config
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["currentCampaignSettings", id]);
      },
    }
  );

  const [checked, setChecked] = React.useState(current?.agent_capture_lead);

  const handleChange = (event) => {
    setChecked(event.target.checked);

    let data = {
      agent_capture_lead: event.target.checked,
    };

    if (current?.id !== undefined) {
      data.id = current.id;
    } else {
      data.campaign_id = id;
    }

    agentCaptureLeadQuery.mutate(data);
  };

  return (
    <>
      <AlertPopup
        open={agentCaptureLeadQuery.isError}
        severity="error"
        message="Something went wrong"
      />

      <AlertPopup
        open={agentCaptureLeadQuery.isSuccess}
        severity="success"
        message="Successfully updated"
      />

      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={handleChange}
            name="agent_capture_lead"
          />
        }
        label="Allow Agent To Capture Leads"
      />
    </>
  );
};

export default withAuth(AllowAgentToCaptureLeads);
