import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import Slide from "@mui/material/Slide";

import { DialogTitle, Grid, Stack } from "@mui/material";
import EditContactForm from "./EditContactForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditCompanyContactDialog({
  rmaSurveyCompany: contact,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth color="warning" onClick={handleClickOpen}>
        Edit Contact
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <DialogTitle>Edit Contact</DialogTitle>
        <Stack sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xl={12} xs={12}>
              <EditContactForm rmaSurveyCompany={contact} />
              <Button color="inherit" fullWidth onClick={handleClose}>
                {" "}
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Dialog>
    </div>
  );
}
