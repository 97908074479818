import axios from "axios";

const GET_SCHEMAS = "GET_SCHEMAS";
const GET_SCHEMAS_SUCCESS = "GET_SCHEMAS_SUCCESS";
const GET_SCHEMAS_FAILURE = "GET_SCHEMAS_FAILURE";

const ADD_SCHEMAS = "ADD_SCHEMAS";
const ADD_SCHEMAS_SUCCESS = "ADD_SCHEMAS_SUCCESS";
const ADD_SCHEMAS_FAILURE = "ADD_SCHEMAS_FAILURE";

const DELETE_SCHEMAS = "DELETE_SCHEMAS";
const DELETE_SCHEMAS_SUCCESS = "DELETE_SCHEMAS_SUCCESS";
const DELETE_SCHEMAS_FAILURE = "DELETE_SCHEMAS_FAILURE";

const EDIT_SCHEMAS = "EDIT_SCHEMAS";
const EDIT_SCHEMAS_SUCCESS = "EDIT_SCHEMAS_SUCCESS";
const EDIT_SCHEMAS_FAILURE = "EDIT_SCHEMAS_FAILURE";

const defaultState = {
  schemas: [],
  isLoading: false,
  isLoaded: false,
  isError: false,
  error: null,
};

export default function AllSchemas(state = defaultState, action) {
  switch (action.type) {
    case GET_SCHEMAS:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        error: null,
      };
    case GET_SCHEMAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        error: null,
        schemas: action.payload,
      };
    case GET_SCHEMAS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: true,
        error: action.payload,
      };
    case ADD_SCHEMAS:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        error: null,
      };
    case ADD_SCHEMAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        error: null,
        schemas: action.payload,
      };
    case ADD_SCHEMAS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: true,
        error: action.payload,
      };
    case DELETE_SCHEMAS:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        error: null,
      };
    case DELETE_SCHEMAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        error: null,
        schemas: action.payload,
      };
    case DELETE_SCHEMAS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: true,
        error: action.payload,
      };
    case EDIT_SCHEMAS:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        error: null,
      };
    case EDIT_SCHEMAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        error: null,
        schemas: action.payload,
      };
    case EDIT_SCHEMAS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: true,
        error: action.payload,
      };
    default:
      return state;
  }
}

export const getSchemas = () => async (dispatch, getState) => {
  dispatch({ type: GET_SCHEMAS });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/RMA/schemes`
    );
    dispatch({ type: GET_SCHEMAS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_SCHEMAS_FAILURE, payload: error.message });
  }
};
