import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getCompletedSurveys,
  getSurveyUser,
} from "../../State/SurveyUsersDuck";
import MainAppContainer from "../../../../../../../Components/Containers/MainAppContainer";
import { Grid, List, Typography } from "@mui/material";
import AddSurveyCompany from "./AddSurveyCompany";
import EditCompanyContacts from "./EditCompanyContacts";

import CompletedUserWSurveys from "./CompletedUserWSurveys";
import {
  getRmaSurveyCompanies,
  getSurveyById,
  getSurveyQuestions,
} from "../../State/SurveysDuck";

const CompletedSurvey = () => {
  const dispatch = useDispatch();

  const { id, companyId } = useParams();

  const Surveys = useSelector((state) => state.Surveys);
  const { loading, rmaSurveyCompany } = Surveys;

  // Get the survey and questions by id
  useEffect(() => {
    dispatch(getSurveyById(id));
    dispatch(getSurveyQuestions(id));
    dispatch(getRmaSurveyCompanies(companyId));
    dispatch(getSurveyUser());
    dispatch(getCompletedSurveys());
  }, [dispatch, id, companyId]);

  const UserSurveys = useSelector((state) => state.UserSurveys);
  const { completedSurveysList } = UserSurveys;

  // getUserCompleted Surveys

  const getUserCompletedSurveys = (Contact_Name) => {
    const userCompletedSurveys = completedSurveysList.filter(
      (survey) => survey.survey_contact_person === Contact_Name
    );
    return userCompletedSurveys;
  };

  console.log(completedSurveysList);

  if (loading) return <div>...LOADING</div>;

  return (
    <MainAppContainer>
      <Typography align="center" sx={{ my: 4 }} variant="h4">
        Completed Surveys
      </Typography>

      <Grid container>
        <Grid item xs={12}>
          <List>
            {rmaSurveyCompany &&
              rmaSurveyCompany.map((company, index) => {
                return (
                  <CompletedUserWSurveys
                    key={index}
                    company={company}
                    getUserCompletedSurveys={getUserCompletedSurveys}
                  />
                );
              })}
          </List>

          <AddSurveyCompany
            rmaSurveyCompany={rmaSurveyCompany && rmaSurveyCompany[0]}
          />
          <EditCompanyContacts
            rmaSurveyCompany={rmaSurveyCompany && rmaSurveyCompany}
          />
        </Grid>
      </Grid>
    </MainAppContainer>
  );
};

export default CompletedSurvey;
