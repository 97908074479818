import React from "react";
import { FormControl, MenuItem, Select, InputLabel, Grid } from "@mui/material";

import { useQuery } from "@tanstack/react-query";
import withAuth from "../../../../Hoc/withAuth";
import axios from "axios";

const PlatFormDropDown = ({ platform, handlePlatFormChange, user }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getPlatformThunk = useQuery([`getPlatform`], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/global/platforms`,
      config
    );
  });

  const { data, isLoading, isSuccess, error, isError } = getPlatformThunk;

  // const [selectedPlatform] = React.useState(platform);

  const platFormSelector = (data) =>
    data &&
    data.data.data.filter((item) => item.status === true).map((item) => item);

  // const handlePlatFormSelect = (event) => {
  //     // onChange(event.target.value);
  //     setSelect edPlatform(event.target.value);
  // };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error: {error.message} </div>;
  } else if (isSuccess) {
    return (
      <Grid>
        <FormControl fullWidth>
          <InputLabel id="sales_platform_select">Sales Platform</InputLabel>
          <Select
            id="sales_platform_select"
            value={platform}
            label="Sales Platform"
            onChange={handlePlatFormChange}>
            {data &&
              platFormSelector(data).map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    );
  } else {
    return <div>Unplanned error has occurred</div>;
  }
};

export default withAuth(PlatFormDropDown);
