import React from "react";
import { useFormikContext, useField } from "formik";
import { Box, Grid, Input, Slider, Typography } from "@mui/material";
import PropTypes from "prop-types";

const SliderWrapper = ({ name, label, inputs, ...otherProps }) => {
  const [field, mata] = useField(name);
  const { values, setFieldValue } = useFormikContext();

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  const handleChange = (event, newValue) => {
    setFieldValue(name, newValue);
  };

  const handleInputChange = (event) => {
    setFieldValue(
      name,
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleBlur = () => {
    if (values[name] < inputs.min) {
      setFieldValue(name, inputs.min);
    } else if (values[name] > inputs.max) {
      setFieldValue(name, inputs.max);
    }
  };

  return (
    <Box>
      <Typography id="input-slider" gutterBottom>
        {label}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            value={typeof values[name] === "number" ? values[name] : 0}
            onChange={handleChange}
            aria-labelledby="input-slider"
            marks
            min={inputs.min}
            color={
              values[name] < 6
                ? "error"
                : values[name] < 9
                ? "warning"
                : "success"
            }
            max={inputs.max}
          />
        </Grid>
        <Grid item>
          <Input
            value={typeof values[name] === "number" ? values[name] : 0}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 0,
              min: inputs.min,
              max: inputs.max,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SliderWrapper;

SliderWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
