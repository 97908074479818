import { Alert, Chip, Button, Stack, Typography } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import withAuth from "../../../../../Hoc/withAuth";
import { StageColors } from "../QaAgent/Components/StageColors";
import { useNavigate } from "react-router-dom";

const AgentEvaluations = ({ user }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const [viewCompleted, setViewCompleted] = useState(false);

  const [completedEvals, setCompletedEvals] = useState([]);
  const [otherEvals, setOtherEvals] = useState([]);

  const navigate = useNavigate();

  const getAgentEvaluations = useQuery(["getMyEvaluations"], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/qa/agent/evaluations/${user.id}`,
      config
    );
  });

  useEffect(() => {
    if (getAgentEvaluations?.data?.data?.data) {
      const completedEvals = [];
      const otherEvals = [];

      getAgentEvaluations?.data?.data?.data?.forEach((evaluation) => {
        if (
          evaluation.stage === "Complete" ||
          (evaluation?.stage_reason_history?.length > 0 &&
            evaluation?.stage_reason_history[0]?.stage === "Reinstated")
        ) {
          completedEvals.push(evaluation);
        } else {
          otherEvals.push(evaluation);
        }
      });

      setCompletedEvals(completedEvals);
      setOtherEvals(otherEvals);
    }
  }, [getAgentEvaluations?.data?.data?.data]);

  let columns = [
    {
      field: "View",
      headerName: "View",
      width: 130,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              navigate(`/CDA/QaAgentHome/${params.row.id}`);
            }}>
            View
          </Button>
        );
      },
    },
    { field: "uni_id", headerName: "Uni ID", width: 200 },
    {
      field: "campaignname",
      headerName: "QA Campaign Name",
      width: 150,
      valueGetter: (params) => {
        return params.row.qa_queue?.queue_name;
      },
    },

    {
      field: "stage",
      headerName: "Stage",
      width: 150,
      renderCell: (params) => {
        if (
          params?.row?.stage_reason_history?.length > 0 &&
          params?.row?.stage_reason_history[0]?.stage === "Reinstated"
        )
          return (
            <Chip color={StageColors(params.row.stage)} label="Reinstated" />
          );

        return (
          <Chip
            color={StageColors(params.row.stage)}
            label={params.row.stage}
          />
        );
      },
    },

    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 200,
      valueGetter: (params) => {
        return new Date(params.row.updatedAt).toLocaleString();
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      valueGetter: (params) => {
        return new Date(params.row.createdAt).toLocaleString();
      },
    },
  ];

  return (
    <>
      <Typography variant="caption" align="right">
        Completed evaluation is Blow this list
      </Typography>
      <Stack sx={{ height: "90vh" }}>
        {otherEvals && otherEvals.length > 0 ? (
          <DataGridPremium rows={otherEvals} columns={columns} />
        ) : (
          <Alert severity="info">No Evaluations Found</Alert>
        )}
      </Stack>

      <Stack sx={{ my: 2 }}>
        <Button
          onClick={() => {
            setViewCompleted(!viewCompleted);
          }}>
          {viewCompleted
            ? "View Other Evaluations"
            : "View Completed Evaluations"}
        </Button>
      </Stack>

      {viewCompleted && (
        <Stack sx={{ height: "90vh" }}>
          {completedEvals && completedEvals.length > 0 ? (
            <DataGridPremium rows={completedEvals} columns={columns} />
          ) : (
            <Alert severity="info">No Evaluations Found</Alert>
          )}
        </Stack>
      )}
    </>
  );
};

export default withAuth(AgentEvaluations);
