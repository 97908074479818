import axios from "axios";

const initialState = {
  teamsList: [],
  team: {},
  teamUsers: [],
  editTeamSuccess: false,
  error: null,
  loading: false,
  success: false,
};

const GET_TEAMS = "GET_TEAMS";
const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
const GET_TEAMS_FAIL = "GET_TEAMS_FAIL";

const CREATE_TEAM = "CREATE_TEAM";
const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
const CREATE_TEAM_FAIL = "CREATE_TEAM_FAIL";

const EDIT_TEAM = "EDIT_TEAM";
const EDIT_TEAM_SUCCESS = "EDIT_TEAM_SUCCESS";
const EDIT_TEAM_FAIL = "EDIT_TEAM_FAIL";

const DELETE_TEAM = "DELETE_TEAM";
const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";

const GET_TEAM_USERS = "GET_TEAM_USERS";
const GET_TEAM_USERS_SUCCESS = "GET_TEAM_USERS_SUCCESS";
const GET_TEAM_USERS_FAIL = "GET_TEAM_USERS_FAIL";

const GET_TEAM_BY_ID = "GET_TEAM_BY_ID";
const GET_TEAM_BY_ID_SUCCESS = "GET_TEAM_BY_ID_SUCCESS";
const GET_TEAM_BY_ID_FAIL = "GET_TEAM_BY_ID_FAIL";

const ADD_USER_TO_TEAM = "ADD_USER_TO_TEAM";
const ADD_USER_TO_TEAM_SUCCESS = "ADD_USER_TO_TEAM_SUCCESS";
const ADD_USER_TO_TEAM_FAIL = "ADD_USER_TO_TEAM_FAIL";

const REMOVE_USER_FROM_TEAM = "REMOVE_USER_FROM_TEAM";
const REMOVE_USER_FROM_TEAM_SUCCESS = "REMOVE_USER_FROM_TEAM_SUCCESS";
const REMOVE_USER_FROM_TEAM_FAIL = "REMOVE_USER_FROM_TEAM_FAIL";

export const RESET_TEAM = "RESET_TEAM";

const TeamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAMS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
        addNewTeamSuccess: false,
        addUserToTeamSuccess: false,
        removeUserFromTeamSuccess: false,
      };
    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        teamsList: action.payload,
      };
    case GET_TEAMS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        teamsList: [],
        team: {},
      };
    case CREATE_TEAM:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        addNewTeamSuccess: true,
        error: null,
        teamsList: [...state.teamsList, action.payload],
      };
    case CREATE_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        teamsList: [],
        team: {},
      };

    case EDIT_TEAM:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case EDIT_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        editTeamSuccess: true,
        teamsList: state.teamsList.map((team) => {
          if (team.id === action.payload.id) {
            return action.payload;
          }
          return team;
        }),
      };
    case EDIT_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        teamsList: [],
        team: {},
      };

    case GET_TEAM_BY_ID:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_TEAM_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        team: action.payload,
      };
    case GET_TEAM_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        team: {},
      };

    case DELETE_TEAM:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        teamsList: state.teamsList.filter((team) => team.id !== action.payload),
        team: {},
      };
    case DELETE_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        teamsList: [],
        team: {},
      };

    case ADD_USER_TO_TEAM:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case ADD_USER_TO_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        addUserToTeamSuccess: true,
        error: null,
        teamUsers: [...state.teamUsers, action.payload],
      };
    case ADD_USER_TO_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        team: {},
      };

    case GET_TEAM_USERS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
        teamUsers: [],
      };
    case GET_TEAM_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        teamUsers: action.payload,
      };
    case GET_TEAM_USERS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        teamUsers: [],
      };

    case REMOVE_USER_FROM_TEAM:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case REMOVE_USER_FROM_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        removeUserFromTeamSuccess: true,
        error: null,
        teamUsers: state.teamUsers.filter(
          (user) => user.user_id !== action.payload.id
        ),
      };
    case REMOVE_USER_FROM_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        teamUsers: [],
      };

    case RESET_TEAM:
      return {
        ...state,
        editTeamSuccess: false,
        addNewTeamSuccess: false,
        addUserToTeamSuccess: false,
        removeUserFromTeamSuccess: false,
      };

    default:
      return state;
  }
};

export default TeamsReducer;

export const getTeamsList = () => async (dispatch, getState) => {
  dispatch({
    type: GET_TEAMS,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/teams`,
      config
    );
    dispatch({
      type: GET_TEAMS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_TEAMS_FAIL,
      payload: err.response,
    });
  }
};

export const createTeam = (team) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_TEAM,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/callCentre/teams`,
      team,
      config
    );
    dispatch({
      type: CREATE_TEAM_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_TEAM_FAIL,
      payload: err.response,
    });
  }
};

export const getTeamUsers = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_TEAM_USERS,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/teams/${id}/users`,
      config
    );
    dispatch({
      type: GET_TEAM_USERS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_TEAM_USERS_FAIL,
      payload: err.response,
    });
  }
};

export const getTeamById = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_TEAM_BY_ID,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/teams/${id}`,
      config
    );
    dispatch({
      type: GET_TEAM_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_TEAM_BY_ID_FAIL,
      payload: err.response,
    });
  }
};

export const addUsertoTeam = (values) => async (dispatch, getState) => {
  dispatch({
    type: ADD_USER_TO_TEAM,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/callCentre/teams/${values.teamId}/users`,
      { user_id: values.user_id },
      config
    );

    dispatch({
      type: ADD_USER_TO_TEAM_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_USER_TO_TEAM_FAIL,
      payload: err.response,
    });
  }
};

export const removeUserFromTeam = (values) => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_USER_FROM_TEAM,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/callCentre/teams/${values.teamId}/users/${values.userId}`,
      config
    );

    dispatch({
      type: REMOVE_USER_FROM_TEAM_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: REMOVE_USER_FROM_TEAM_FAIL,
      payload: err.response,
    });
  }
};

export const editTeam =
  ({ id, dis }) =>
  async (dispatch, getState) => {
    dispatch({
      type: EDIT_TEAM,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/callCentre/teams/${id}`,
        dis,
        config
      );

      dispatch({
        type: EDIT_TEAM_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: EDIT_TEAM_FAIL,
        payload: err.response,
      });
    }
  };
