import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import withAuth from "../../../../Hoc/withAuth";
import { Outlet } from "react-router-dom";
import axios from "axios";
import { Formik, Form } from "formik";
import { Button, Card, CardContent, Grid, Stack } from "@mui/material";
import * as Yup from "yup";
import TextfieldWrapper from "../../../../Components/FormComponents/TextfieldWrapper";

import PageHeader from "../../../../Components/Bits/PageHeader";
import SaleOrderStatusDropDown from "./SaleOrderStatusDropDown";
import SaleOrderHistoryTable from "./SaleOrderHistoryTable";
import AlertPopup from "../../../../Components/Bits/AlertPopup";

function SaleOrderModal({ user }) {
  const { id } = useParams();

  const location = useLocation();

  const { data, platform } = location?.state;

  const [saleOrder, setUpdateSaleOrderSuccess] = useState({
    ...data,
    old_state: data?.state,
    cancel_user_id: user?.id,
    platform: platform,
  });

  const [editState, setEditState] = useState(false);

  const maskField = true;

  const init = {
    id: "",
    state: "",
    partner_id: "",
    user_id: "",
    campaign_id: "",
    create_uid: "",
    write_uid: "",
    validity_date: "",
    origin: "",
    company_id: "",
    amount_total: "",
    platform: platform,
    __last_update: "",
    state_reason: "",
  };

  const historyTableColumns = [
    "Id",
    "OrderId",
    "State",
    "Company",
    "Current State",
    "Change Reason",
    "Cancelled By",
    "Cancelled Date",
    "Platform",
  ];

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const queryClient = useQueryClient();

  const mutateSaleOrder = useMutation({
    mutationFn: async (values) => {
      return await axios.patch(
        `${process.env.REACT_APP_API_URL}/callCentre/${platform}/sales_orders/${id}`,
        values,
        config
      );
    },
    onSuccess: (data) => {
      setEditState(!editState);
      // console.log(data.data.data.current.data["0"])

      queryClient.invalidateQueries(`getSaleOrder`);
      queryClient.invalidateQueries(`getSaleOrderHistory`);

      const { state } = data?.data?.data?.current.data["0"];

      setUpdateSaleOrderSuccess({ ...saleOrder, state: state });
    },
  });

  return (
    <>
      <PageHeader
        title="Sale Order Management Home"
        subheader="You may view or cancel your sale orders here."
        breadcrumbs={[
          {
            title: "Home",
            href: "/",
          },
          {
            title: `Sale Order ${id}`,
            href: `/${id}`,
          },
        ]}
      />

      <AlertPopup
        open={mutateSaleOrder.isSuccess}
        message={"Sale Order Updated"}
        severity={"success"}
      />

      <AlertPopup
        open={mutateSaleOrder.isError}
        message={"Sale Order Update Failed"}
        severity={"error"}
      />

      <Formik
        initialValues={{ ...saleOrder, state_reason: "" }}
        enableReinitialize={true}
        onSubmit={(values, { resetForm }) => {
          mutateSaleOrder.mutate(values);

          resetForm({ values: { ...init } });
        }}
        validationSchema={Yup.object().shape({
          platform: Yup.string().required("Required"),
          cancel_user_id: Yup.string().required("Required"),
          state: Yup.string().required("Required"),
          state_reason: Yup.string().required("Required"),
        })}>
        {({ isSubmitting }) => {
          return (
            <>
              <Form>
                <Card>
                  <CardContent>
                    <Stack spacing={2} sx={{ my: 2 }}>
                      <Button
                        sx={{ mb: 2 }}
                        color={editState ? "inherit" : "warning"}
                        variant={editState ? "contained" : "outlined"}
                        onClick={() => setEditState(!editState)}>
                        {editState ? "cancel edit" : "Edit"}
                      </Button>

                      {editState ? (
                        <>
                          <SaleOrderStatusDropDown name="state" label="State" />
                          <TextfieldWrapper
                            name="state_reason"
                            label="Reason for change"
                          />
                          <Button
                            sx={{ mb: 2 }}
                            variant="contained"
                            color="secondary"
                            type={"submit"}
                            disabled={isSubmitting}>
                            Update
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="id"
                          label="Sale Order ID"
                          disabled={true}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="partner_id"
                          label="Partner"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="user_id"
                          label="User"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="campaign_id"
                          label="Campaign"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="create_uid"
                          label="Created By"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="write_uid"
                          label="Written By"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="validity_date"
                          label="Validity Date"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="origin"
                          label="Origin"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="company_id"
                          label="Company"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="amount_total"
                          label="Amount Total"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="platform"
                          label="Platform"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextfieldWrapper
                          name="__last_update"
                          label="Last Update"
                          disabled={true}
                        />
                      </Grid>
                      {maskField ? (
                        <></>
                      ) : (
                        <>
                          <Grid item xs={6}>
                            <TextfieldWrapper
                              name="cancel_user_id"
                              label="Cancelled By"
                              disabled={true}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextfieldWrapper
                              name="old_state"
                              label="Old State"
                              disabled={true}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Form>
            </>
          );
        }}
      </Formik>
      <Outlet />

      <Grid container spacing={6}>
        <Grid item xs={12} marginTop={"2rem"}>
          <SaleOrderHistoryTable
            platform={platform}
            id={id}
            columns={historyTableColumns}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default withAuth(SaleOrderModal);
