import { Card, CardActionArea, CardHeader, Grid } from "@mui/material";
import React, { useEffect } from "react";
import MainAppContainer from "../../../Components/Containers/MainAppContainer";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getClientsByUser } from "../StateManager/Clients/ClientsDuck";
import CreateClientForm from "./CreateClientForm";
import PageHeader from "../../../Components/Bits/PageHeader";

const ClientsList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientsByUser());
  }, [dispatch]);

  const navigate = useNavigate();

  const ClientsDuck = useSelector((state) => state.ClientsDuck);

  const { clients, loading, error } = ClientsDuck;

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  return (
    <MainAppContainer>
      <PageHeader
        title="Clients"
        subheader="View your clients"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "CRM",
            href: `/crm`,
          },
          {
            title: "Clients",
            href: `/CRM/clients/clientsList`,
          },
        ]}
      />

      <CreateClientForm />
      <Grid container sx={{ mt: 2 }} spacing={1}>
        {clients.map((client) => (
          <Grid item xl={12} xs={12} key={client.id}>
            <Card py={2}>
              <CardActionArea
                onClick={() =>
                  navigate(`/crm/clients/clientView/${client.id}`)
                }>
                <CardHeader
                  title={client.company_name}
                  subheader={client.company_business}
                />
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </MainAppContainer>
  );
};

export default ClientsList;
