import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import MainAppContainer from "../../Components/Containers/MainAppContainer";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import { readNotification } from "../../Redux/Ducks/NotificationDuck";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
const Notification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Notifications = useSelector((state) => state.Notifications);
  const { notifications } = Notifications;

  const clearNotification = (id) => {
    dispatch(readNotification(id));
  };

  return (
    <MainAppContainer>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography sx={{ py: 4 }} variant="h4" align="center">
            Notification
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" spacing={2}>
        {notifications &&
          notifications.map((note, index) => {
            return (
              <Grid item xl={12} md={12} sm={12} xs={12} key={note.id}>
                <Alert
                  sx={{ width: "100%", m: 0 }}
                  severity={note.type}
                  action={
                    <Button
                      onClick={() => clearNotification(note.id)}
                      color="inherit"
                      variant="outlined"
                      endIcon={<DeleteTwoToneIcon />}
                      size="large">
                      Clear
                    </Button>
                  }>
                  <AlertTitle>{note?.title}</AlertTitle>
                  <Stack spacing={2}>
                    <Typography variant="body2">{note.message}</Typography>
                    <Typography variant="body2">
                      {`${new Date(note?.createdAt).toLocaleDateString(
                        "en-ZA"
                      )} ${new Date(note?.createdAt).toLocaleTimeString(
                        "en-ZA"
                      )}  `}
                    </Typography>
                    {note.link && (
                      <Button onClick={() => navigate(`${note.link}`)}>
                        {note.link}
                      </Button>
                    )}
                  </Stack>
                </Alert>
              </Grid>
            );
          })}
      </Grid>
    </MainAppContainer>
  );
};

export default Notification;
