import * as React from "react";

import Box from "@mui/material/Box";
import QuestionnaireForm from "./QuestionnaireForm";

import withAuth from "../../../../../../Hoc/withAuth";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";

const CreateQuestionnaire = ({ companyDetails }) => {
  let companyId = companyDetails.companyId;

  const navigate = useNavigate();
  const QuestionnairesDuck = useSelector((state) => state.QuestionnairesDuck);

  const { questionnaireCreated } = QuestionnairesDuck;

  React.useEffect(() => {
    if (questionnaireCreated) {
      setTimeout(() => {
        navigate("/CDA/questionnaireAdmin");
      }, 1000);
    }
  }, [questionnaireCreated, navigate]);

  return (
    <Box>
      <QuestionnaireForm company={companyId} />
      {questionnaireCreated && (
        <AlertPopup
          message="Created Successfully"
          severity="success"
          open={questionnaireCreated}
        />
      )}
    </Box>
  );
};

export default withAuth(CreateQuestionnaire);
