import {
  Chip,
  LinearProgress,
  Stack,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import styled from "@emotion/styled";
import { getAllSales } from "./State/SalesDuck";

export default function Sales() {
  const { id } = useParams();

  const navigate = useNavigate();

  let rows = [];
  const [page, setPage] = React.useState(0);

  const dispatch = useDispatch();

  const sales = useSelector((state) => state.sales);
  const { allSales, loading } = sales;

  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  useEffect(() => {
    dispatch(getAllSales({ rowsPerPage, page }));
  }, [dispatch, id, rowsPerPage, page]);

  // Avoid a layout jump when reaching the last page with empty rows.

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  if (allSales) {
    rows = allSales.rows;
  }

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - rows.length) : 0;

  return (
    <>
      <Stack direction="row" sx={{ mb: 2 }}>
        <TextField fullWidth id="standard-basic" label="Search Leads" />
      </Stack>

      <>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Lead Id</StyledTableCell>
              <StyledTableCell>Agent</StyledTableCell>
              <StyledTableCell align="right">Premium</StyledTableCell>
              <StyledTableCell align="right">Status</StyledTableCell>
              <StyledTableCell align="right">reference_no</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              rows?.map((row) => (
                <TableRow
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/CDA/CampaignLeads/Lead/${row.lead_id}`)
                  }
                  key={row.id}>
                  <TableCell component="th" scope="row">
                    {row?.lead_id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.User?.name}
                  </TableCell>

                  <TableCell align="right">{row?.premium}</TableCell>
                  <TableCell align="right">
                    <Chip
                      variant="outlined"
                      color={
                        row?.status?.toLowerCase() === "pending"
                          ? "error"
                          : row.status === "completed"
                          ? "success"
                          : row.status === "staging"
                          ? "warning"
                          : row.status === "cancelled"
                          ? "default"
                          : "info"
                      }
                      label={row.status}
                    />
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="right">
                    {row?.reference_no}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography gutterBottom> Loading...</Typography>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                </TableCell>
              </TableRow>
            )}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} align="center" />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <TablePagination
                rowsPerPageOptions={[20, 35, 50]}
                colSpan={5}
                count={allSales.count || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
      </>
    </>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
