import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";
import { DialogContent, Stack } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createDispositionList } from "../../State/DispostionsDuck";

export default function AddDispositionList() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button
        fullWidth
        variant="contained"
        color="warning"
        onClick={handleClickOpen}>
        Add New Disposition List
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle> Add New Disposition List</DialogTitle>
        <Formik
          initialValues={{
            name: "",
            active: true,
            updated_reason: "Created New Disposition",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),

            active: Yup.boolean().required("Active is required"),
          })}
          onSubmit={(values) => {
            dispatch(createDispositionList(values));
            setTimeout(() => {
              handleClose();
            }, 1000);
          }}>
          {() => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <TextfieldWrapper label="Disposition List Name" name="name" />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button color="inherit" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  Create
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
