import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  LinearProgress,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import React from "react";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import withAuth from "../../../../../Hoc/withAuth";
import ContentItem from "./Components/ContentItem";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import DateRangeSelector from "../../../../../Components/FormComponents/DateRangeSelector";

const AgentResults = ({ user }) => {
  const { token } = user;

  let currentUser = `${user?.name} ${user?.surname}`;

  const [value, setValue] = React.useState([
    dayjs().startOf("month"),
    dayjs().endOf("month"),
  ]);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { data, isLoading, isRefetching, isError, error } = useQuery(
    ["getAgents", value],
    () => {
      // format value[0] to disregard gmt offset
      const start = dayjs(value[0])
        .tz("Africa/Johannesburg")
        .format("YYYY-MM-DD");
      // format value[1] to disregard gmt offset
      const end = dayjs(value[1])
        .tz("Africa/Johannesburg")
        .format("YYYY-MM-DD");
      return axios.get(
        `${process.env.REACT_APP_API_URL}/reports/QaReports/QaAgentReports?startDate=${start}&endDate=${end}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  // console.log("AgentData", data?.data?.data);

  let res = data?.data?.data
    .map((data, index) => {
      return {
        ...data,
        id: index + 1,
      };
    })
    .filter((item) => item.qa_agent === currentUser)[0];

  return (
    <Container>
      <PageHeader
        title="Agent Results"
        subheader="View Qa Agent results tracking"
      />

      <Grid container>
        <Grid item xs={12}>
          <DateRangeSelector value={value} setValue={setValue} />

          {(isRefetching || isLoading) && <LinearProgress />}

          {isError && (
            <Alert severity="error">
              {error?.response?.data?.message || "Something went wrong"}
            </Alert>
          )}
        </Grid>
      </Grid>

      <Card sx={{ mt: 2 }}>
        <CardHeader title={`Agent Results - ${currentUser}`} />
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <ContentItem
                title="Total Evaluations"
                value={res?.evaluations || 0}
              />
              <ContentItem
                title="Complete Evaluations"
                value={res?.complete || 0}
              />
              <ContentItem
                title="Incomplete Evaluations"
                value={res?.incomplete || 0}
              />
              <ContentItem title="Failed Evaluations" value={res?.fail || 0} />
            </Grid>
            <Grid item xs={6}>
              <ContentItem
                title="Previous Failures"
                value={res?.previous_fail || 0}
              />
              <ContentItem
                title="Disputes Rejected"
                value={res?.dispute_rejected || 0}
              />
              <ContentItem
                title="Evaluation Hours"
                value={
                  res?.evaluation_hours &&
                  Number(res?.evaluation_hours)?.toFixed(2)
                }
              />
              <ContentItem
                title="Talk Time"
                value={res?.talktime && Number(res?.talktime)?.toFixed(2)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default withAuth(AgentResults);
