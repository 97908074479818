import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Chip, List, Stack } from "@mui/material";
import { StyledListItem } from "../../../../../../Components/Containers/StyledListItem";

const QuestionnaireHistory = ({ questionnaire }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header">
          <Typography sx={{ width: "33%", flexShrink: 0 }}>History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {questionnaire.questionnaire_responses.map((response) => (
              <StyledListItem key={response.id}>
                <Stack
                  key={response?.id}
                  display="flex"
                  spacing={5}
                  direction="row"
                  justifyContent="center"
                  alignContent="center">
                  <Chip
                    variant="outlined"
                    color={response?.passed === true ? "secondary" : "warning"}
                    label={response?.passed ? "Passed" : "Failed"}
                  />
                  <Chip label={`${response?.score}%`} />
                  <Typography>
                    Date : {new Date(response?.startTime).toLocaleString()}
                  </Typography>
                </Stack>
              </StyledListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default QuestionnaireHistory;
