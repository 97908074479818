import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useFormik } from "formik";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { addClientContact } from "../StateManager/Clients/ClientsDuck";
import { Grid } from "@mui/material";

export default function CreateClientContactForm({ id }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      contact_name: "",
      contact_position: "",
      cell_no1: "",
      cell_no2: "",
      landline_no: "",
      landline_no2: "",
      contact_email: "",
      contact_department: "",
      contact_notes: "",
      client_id: id,
    },
    validationSchema: yup.object({
      contact_name: yup.string().required("Required"),
      contact_position: yup.string().required("Required"),
      client_id: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      dispatch(addClientContact(values));
      handleClose();
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create Client Contact
      </Button>
      <Dialog maxWidth="lg" fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle>CreateClientContact</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xl={6} md={6} sm={6} xs={6}>
                <TextField
                  margin="normal"
                  label="Contact Name"
                  name="contact_name"
                  onChange={formik.handleChange}
                  value={formik.values.contact_name}
                  error={formik.errors.contact_name ? true : false}
                  helperText={formik.errors.contact_name}
                  fullWidth
                />
                <TextField
                  margin="normal"
                  id="name"
                  label="Email Address"
                  type="email"
                  fullWidth
                  onChange={formik.handleChange}
                  name="contact_email"
                  error={formik.errors.contact_email ? true : false}
                  helperText={formik.errors.contact_email}
                />

                <TextField
                  margin="normal"
                  id="name"
                  label="Contact Position"
                  type="text"
                  fullWidth
                  onChange={formik.handleChange}
                  name="contact_position"
                  error={formik.errors.contact_position ? true : false}
                  helperText={formik.errors.contact_position}
                />
                <TextField
                  margin="normal"
                  id="name"
                  label="Contact Department"
                  type="text"
                  fullWidth
                  onChange={formik.handleChange}
                  name="contact_department"
                  error={formik.errors.contact_department ? true : false}
                  helperText={formik.errors.contact_department}
                />
              </Grid>
              <Grid item xl={6} md={6} sm={6} xs={6}>
                <TextField
                  margin="normal"
                  id="name"
                  label="Cell No 1"
                  type="text"
                  fullWidth
                  onChange={formik.handleChange}
                  name="cell_no1"
                  error={formik.errors.cell_no1 ? true : false}
                  helperText={formik.errors.cell_no1}
                />
                <TextField
                  margin="normal"
                  id="name"
                  label="Cell No 2"
                  type="text"
                  fullWidth
                  onChange={formik.handleChange}
                  name="cell_no2"
                  error={formik.errors.cell_no2 ? true : false}
                  helperText={formik.errors.cell_no2}
                />
                <TextField
                  margin="normal"
                  id="name"
                  label="Landline No"
                  type="text"
                  fullWidth
                  onChange={formik.handleChange}
                  name="landline_no"
                  error={formik.errors.landline_no ? true : false}
                  helperText={formik.errors.landline_no}
                />
                <TextField
                  margin="normal"
                  id="name"
                  label="Landline No 2"
                  type="text"
                  fullWidth
                  onChange={formik.handleChange}
                  name="landline_no2"
                  error={formik.errors.landline_no2 ? true : false}
                  helperText={formik.errors.landline_no2}
                />
              </Grid>
            </Grid>

            <TextField
              margin="normal"
              id="name"
              multiline
              rows={4}
              label="Notes"
              type="text"
              fullWidth
              onChange={formik.handleChange}
              name="contact_notes"
              error={formik.errors.contact_notes ? true : false}
              helperText={formik.errors.contact_notes}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit">
              Create contact
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
