import axios from "axios";

export const getAllManagers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_ALL_MANAGER_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/payspace/managers`,
      config,
    );

    dispatch({ type: "GET_ALL_MANAGER_SUCCESS", payload: data.managers });
  } catch (err) {
    dispatch({ type: "GET_ALL_MANAGER_FAIL" });
  }
};

export const addPosition = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "ADD_POSITION_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/payspace/managers/addposition`,
      formData,
      config,
    );

    dispatch({
      type: "ADD_POSITION_SUCCESS",
      payload: { position: data.positionAdded, message: data.message },
    });
  } catch (err) {
    console.log(err.response.data.validationErrors);
    if (err.response.data.hasOwnProperty("validationErrors")) {
      dispatch({
        type: "ADD_POSITION_FAIL",
        payload: { validationErrors: err.response.data.validationErrors },
      });
    }
  }
};

export const getPositions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_POSITION_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/payspace/managers/positions/all`,
      config,
    );

    // console.log(data);

    // order data by position job_title
    data.positions.sort((a, b) => {
      if (a.job_title < b.job_title) {
        return -1;
      }
      if (a.job_title > b.job_title) {
        return 1;
      }
      return 0;
    });

    dispatch({
      type: "GET_POSITION_SUCCESS",
      payload: data.positions,
    });
  } catch (err) {
    console.log(err);
  }
};

export const editPosition = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "EDIT_POSITION_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.put(
      `${process.env.REACT_APP_API_URL}/payspace/managers/editposition/${formData.id}`,
      formData,
      config,
    );

    dispatch({ type: "EDIT_POSITION_SUCCESS" });
    dispatch(getPositions());
  } catch (err) {
    dispatch({ type: "EDIT_POSITION_FAIL" });
  }
};

export const approvePosition = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "APPROVE_POSITION_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.put(
      `${process.env.REACT_APP_API_URL}/payspace/managers/approvePosition/${formData.id}`,
      formData,
      config,
    );

    await dispatch(getPositions());

    dispatch({ type: "APPROVE_POSITION_SUCCESS" });
  } catch (err) {
    dispatch({ type: "APPROVE_POSITION_FAIL" });
  }
};

export const rejectPosition = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "REJECT_POSITION_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.put(
      `${process.env.REACT_APP_API_URL}/payspace/managers/rejectPosition/${formData.id}`,
      formData,
      config,
    );

    await dispatch(getPositions());

    dispatch({ type: "REJECT_POSITION_SUCCESS" });
  } catch (err) {
    dispatch({ type: "REJECT_POSITION_FAIL" });
  }
};

export const deletePosition = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "DELETE_POSITION_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.delete(
      `${process.env.REACT_APP_API_URL}/payspace/managers/deletePosition/${id}`,
      config,
    );

    await dispatch(getPositions());

    dispatch({ type: "DELETE_POSITION_SUCCESS" });
  } catch (err) {
    console.log(err);
    dispatch({ type: "DELETE_POSITION_FAIL" });
  }
};

export const saveEmployeeInternalInfo =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: "SAVE_EMPLOYEE_INTERNAL_INFO_REQUEST" });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}/payspace/managers/internalInfo`,
        formData,
        config,
      );

      dispatch({
        type: "SAVE_EMPLOYEE_INTERNAL_INFO_SUCCESS",
      });
      // dispatch(getEmployeeById(formData.employee_id))
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.hasOwnProperty("validationErrors")) {
        dispatch({
          type: "SAVE_EMPLOYEE_INTERNAL_INFO_FAIL",
          payload: { validationErrors: err?.response?.data?.validationErrors },
        });
      }
    }
  };

export const editEmployeeInternalInfo =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: "EDIT_EMPLOYEE_INTERNAL_INFO_REQUEST" });

      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/payspace/managers/internalInfo/${formData.employee_id}`,
        formData,
        config,
      );

      dispatch({
        type: "EDIT_EMPLOYEE_INTERNAL_INFO_SUCCESS",
      });
      // dispatch(getEmployeeById(formData.employee_id))
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.hasOwnProperty("validationErrors")) {
        dispatch({
          type: "EDIT_EMPLOYEE_INTERNAL_INFO_FAIL",
          payload: { validationErrors: err?.response?.data?.validationErrors },
        });
      }
    }
  };

export const getEmployeeInternalInfo = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_EMPLOYEE_INTERNAL_INFO_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/payspace/managers/internalInfo/${id}`,
      config,
    );

    dispatch({
      type: "GET_EMPLOYEE_INTERNAL_INFO_SUCCESS",
      payload: data.internalInfo,
    });
    // dispatch(getEmployeeById(formData.employee_id))
  } catch (err) {
    console.log(err);
    if (err?.response?.data?.hasOwnProperty("validationErrors")) {
      dispatch({
        type: "SAVE_EMPLOYEE_ATTACHMENT_FAIL",
        payload: { validationErrors: err?.response?.data?.validationErrors },
      });
    }
  }
};

export const getAllDepartments = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_ALL_DEPARTMENT_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/payspace/managers/departments/all`,
      config,
    );

    dispatch({
      type: "GET_ALL_DEPARTMENT_SUCCESS",
      payload: data.departments,
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: "GET_ALL_DEPARTMENT_FAIL" });
  }
};

export const addDepartment = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "SAVE_DEPARTMENT_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    formData.manager_node_id =
      formData.manager_node_id === "" ? null : formData.manager_node_id;

    await axios.post(
      `${process.env.REACT_APP_API_URL}/payspace/managers/departments`,
      formData,
      config,
    );
    dispatch(getAllDepartments());
    dispatch({
      type: "SAVE_DEPARTMENT_SUCCESS",
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: "SAVE_DEPARTMENT_FAIL" });
  }
};

export const editDepartment = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "EDIT_DEPARTMENT_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.put(
      `${process.env.REACT_APP_API_URL}/payspace/managers/departments/${formData.departmentId}`,
      formData,
      config,
    );
    dispatch(getAllDepartments());
    dispatch({
      type: "EDIT_DEPARTMENT_SUCCESS",
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: "EDIT_DEPARTMENT_FAIL" });
  }
};

export const addRequisition = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "SAVE_REQUISITION_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.post(
      `${process.env.REACT_APP_API_URL}/payspace/managers/addRequisition/`,
      formData,
      config,
    );
    // dispatch(getAllDepartments());
    dispatch({
      type: "SAVE_REQUISITION_SUCCESS",
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: "SAVE_REQUISITION_FAIL" });
  }
};

export const getAllRequisitions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_ALL_REQUISITION_REQUEST" });

    const { AuthDuck } = getState();

    const userModules = AuthDuck.userInfo.user.Modules_users;

    const modules = [];

    for (const module of userModules) {
      modules.push(module.Module.name);
    }

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      // `${process.env.REACT_APP_API_URL}/payspace/managers/requisitions/${AuthDuck.userInfo.user.id}?modules=${modules}`,
      // TODO fix auth
      `${process.env.REACT_APP_API_URL}/onboarding/requisitions`,
      config,
    );

    console.log(data);
    dispatch({
      type: "GET_ALL_REQUISITION_SUCCESS",
      payload: {
        requisitions: data.requisitions,
        availableRequisitions: data.availableRequisitions,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: "GET_ALL_REQUISITION_FAIL" });
  }
};

export const getRequisitionById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_REQUISITION_BY_ID_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/payspace/managers/addRequisition/${id}`,
      config,
    );
    dispatch({
      type: "GET_REQUISITION_BY_ID_SUCCESS",
      payload: data.requisition,
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: "GET_REQUISITION_BY_ID_FAIL" });
  }
};

export const editRequisition = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "EDIT_REQUISITION_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.put(
      `${process.env.REACT_APP_API_URL}/payspace/managers/addRequisition/${formData.requisitionId}`,
      formData,
      config,
    );
    await dispatch(getAllRequisitions());
    dispatch({
      type: "EDIT_REQUISITION_SUCCESS",
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: "EDIT_REQUISITION_FAIL" });
  }
};

export const approveRequisition = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "APPROVE_REQUISITION_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.put(
      `${process.env.REACT_APP_API_URL}/payspace/managers/approveRequisition/${id}`,
      {},
      config,
    );
    await dispatch(getAllRequisitions());
    dispatch({
      type: "APPROVE_REQUISITION_SUCCESS",
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: "APPROVE_REQUISITION_FAIL" });
  }
};

export const rejectRequisition = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "REJECT_REQUISITION_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.put(
      `${process.env.REACT_APP_API_URL}/payspace/managers/rejectRequisition/${formData.id}`,
      formData,
      config,
    );
    await dispatch(getAllRequisitions());
    dispatch({
      type: "REJECT_REQUISITION_SUCCESS",
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: "REJECT_REQUISITION_FAIL" });
  }
};

export const deleteRequisition = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "DELETE_REQUISITION_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.delete(
      `${process.env.REACT_APP_API_URL}/payspace/managers/requisitions/${id}`,
      config,
    );
    await dispatch(getAllRequisitions());
    dispatch({
      type: "DELETE_REQUISITION_SUCCESS",
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: "DELETE_REQUISITION_FAIL" });
  }
};

export const disableDepartment = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "DELETE_DEPARTMENT_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.delete(
      `${process.env.REACT_APP_API_URL}/payspace/managers/departments/${id}`,
      config,
    );
    await dispatch(getAllDepartments());
    dispatch({
      type: "DELETE_DEPARTMENT_SUCCESS",
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: "EDIT_REQUISITION_FAIL" });
  }
};

export const enableDepartment = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "ENABLE_DEPARTMENT_REQUEST" });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    await axios.put(
      `${process.env.REACT_APP_API_URL}/payspace/managers//enaableDepartment/${id}`,
      config,
    );
    await dispatch(getAllDepartments());
    dispatch({
      type: "ENABLE_DEPARTMENT_SUCCESS",
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: "ENABLE_REQUISITION_FAIL" });
  }
};

export const resetPositionState = () => (dispacth) => {
  dispacth({ type: "RESET_POSITION_STATE" });
};

export const resetDepartmentState = () => (dispacth) => {
  dispacth({ type: "RESET_DEPARTMENT_STATE" });
};
