import React from "react";
import { Outlet } from "react-router-dom";

import PageHeader from "../../../../../Components/Bits/PageHeader";

const TeamLeaderLanding = () => {
  return (
    <>
      <PageHeader
        title="Team Leader Landing"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Call Center Team Leader",
            href: "/CDA/CallCenter/TeamLeader",
          },
        ]}
      />

      <Outlet />
    </>
  );
};

export default TeamLeaderLanding;
