import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { DialogContent } from "@mui/material";

import { useDispatch } from "react-redux";

import SelectLanguagesComp from "../../../../../../../Components/FormComponents/SelectLanguagesComp";
import { updateTeamUser } from "../../State/QaTeamsDuck";

export default function EditTeamUser({ teamUser }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button color="warning" onClick={handleClickOpen}>
        Edit
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Edit Team User</DialogTitle>

        <Formik
          initialValues={{
            teamId: teamUser?.team_id,
            user_id: teamUser?.user_id,
            languages: teamUser?.languages,
          }}
          onSubmit={(values) => {
            dispatch(updateTeamUser(values));
            handleClose();
          }}>
          {({ values }) => (
            <Form>
              <DialogContent>
                <SelectLanguagesComp name="languages" />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Save</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
