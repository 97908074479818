import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeamsList, RESET_TEAM } from "./State/TeamsDuck";
import AddNewTeamDialog from "./Components/Teams/AddNewTeamDialog";
import TeamsList from "./Components/Teams/TeamsList";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";

const TeamsManagement = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamsList());
    dispatch({ type: RESET_TEAM });
  }, [dispatch]);

  const Teams = useSelector((state) => state.Teams);

  const { teamsList, addNewTeamSuccess, editTeamSuccess, loading } = Teams;

  const [viewInactive, setViewInactive] = useState(false);

  if (loading) {
    return (
      <Stack justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack>
      <Card sx={{ mb: 2 }} variant="outlined">
        <CardContent>
          <Stack>
            <Typography color="GrayText" variant="caption">
              Teams Management Page allows you to manage teams.
            </Typography>
            <Typography color="GrayText" variant="caption">
              To add the team to the campaign. navigate to the campaign and add
              the team. By selecting the team Card.
            </Typography>
          </Stack>
        </CardContent>
      </Card>

      <AddNewTeamDialog />
      <Stack my={3}>
        <TeamsList active={true} list={teamsList} />
      </Stack>
      <Button onClick={() => setViewInactive(!viewInactive)}>
        View inactive Teams
      </Button>
      {viewInactive && (
        <Stack mt={3}>
          <TeamsList active={false} list={teamsList} />
        </Stack>
      )}

      <AlertPopup
        open={addNewTeamSuccess}
        severity="info"
        message="New Team Added"
      />
      <AlertPopup
        open={editTeamSuccess}
        severity="info"
        message="Teams Updated"
      />
    </Stack>
  );
};

export default TeamsManagement;
