import * as React from "react";
import { styled } from "@mui/system";

const Root = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: 26,
  borderRadius: 2,
}));

const Value = styled("div")({
  position: "absolute",
  lineHeight: "24px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const Bar = styled("div")(({ valueInPercent }) => ({
  height: "100%",
  backgroundColor:
    valueInPercent > 70
      ? "#088208a3"
      : valueInPercent >= 30
      ? "#efbb5aa3"
      : "#f44336",
  maxWidth: `${valueInPercent}%`,
}));

const ProgressBar = React.memo(function ProgressBar(props) {
  const { value } = props;
  const valueInPercent = value;

  return (
    <Root>
      <Value>{`${valueInPercent.toLocaleString()} %`}</Value>
      <Bar valueInPercent={valueInPercent} />
    </Root>
  );
});

export function RenderProgress(params) {
  return <ProgressBar value={Number(params.value)} />;
}
