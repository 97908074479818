import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import withAuth from "../../../Hoc/withAuth";
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import SelectWrapper from "../../../Components/FormComponents/Select";
import AlertPopup from "../../../Components/Bits/AlertPopup";

const EditPositionModal = ({ user, position, text, modules }) => {
  const queryClient = useQueryClient()
  
  const [mutationError, setMutationError] = useState('')
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const mutation = useMutation({
    mutationFn: (values) => {
      if (values.id) {
        // delete
        return axios.put(
          `${process.env.REACT_APP_API_URL}/onboarding/positions/disable/${values.id}`,
          values,
          config
        );
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["getPositions"]);
    },
    onError: async (error) => { 
      setMutationError(error);
    }
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitHandler = (values) => {
    mutation.mutate(values);
    setTimeout(() => handleClose(), 2000)
  };

  let textColor = "error"
  let dialogCaption = 'Disable Position'
  let successText = 'Position enabled successfully!'
  
  if(text === "Enable"){
    textColor = "success"
    dialogCaption = 'Enable Position'
    successText = 'Position disabled successfully!'
  }

  return (
    <Stack spacing={0}>
      <Button color={textColor} variant="outlined" onClick={handleClickOpen}>
        {text}
      </Button>

      

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="responsive-dialog-title"
        sx={{ borderRadius: 0 }}
      >
      {mutation.isLoading && (
        <AlertPopup open={true} severity={"info"} message={"Updating Position..."} />
      )}

      { mutation.isSuccess && (
        <AlertPopup open={true} message={successText} />
      )}

      { mutationError && (
        <AlertPopup open={true} message={mutationError} severity={"error"} />
      ) }
        <DialogTitle id="responsive-dialog-title">{dialogCaption}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              id: position?.id || "",
              job_title: position?.job_title || "",
              is_active: position?.is_active || false
            }}
            validationSchema={yup.object().shape({
              job_title: yup.string().required("Job title required"),
              is_active: yup
                .string()
                .required("Active state is required"),
            })}
            onSubmit={onSubmitHandler}
            enableReinitialize={true}
          >
            {(values, errors) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <TextfieldWrapper
                        name={"job_title"}
                        label="Job Title"
                        sx={{ mt: 2 }}
                      />
                    </Grid>

                    
                    <Grid item xs={12} md={12}>
                        <SelectWrapper
                          name="is_active"
                          label="Select Active State"
                          options={[
                            { value: true, label: "Active" },
                            { value: false, label: "Inactive" },
                          ]}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Box textAlign="end">
                        <Button
                          autoFocus
                          onClick={handleClose}
                          variant="contained"
                        >
                          Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        {modules.includes("oe-ceo") && (
                          <Button
                            type="submit"
                            color="secondary"
                            autoFocus
                            variant="contained"
                            disabled={!position?.approved}
                          >
                            Save
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default withAuth(EditPositionModal);
