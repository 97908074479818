import { Box, Container, LinearProgress, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import VBCcaptureForm from "./Components/VBCcaptureForm";
import withAuth from "../../../../../Hoc/withAuth";
import { DataGridPremium } from "@mui/x-data-grid-premium";

const Main = ({ user }) => {
  const token = user.token;

  const GetVBCTargets = useQuery({
    queryKey: ["GetVBCTargets"],
    queryFn: async () => {
      return await axios.get(`${process.env.REACT_APP_API_URL}/VBC/vbc`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    enabled: !!token,
  });

  let rows = GetVBCTargets?.data?.data?.data;

  let columns = [
    {
      field: "ave_customer_service_rating",
      headerName: "Average Customer Service Rating",
    },
    {
      field: "case_resolution",
      headerName: "Case Resolution",
    },
    {
      field: "date",
      headerName: "Date",
    },
    {
      field: "mip_per_agent_per_sale",
      headerName: "MIP Per Agent Per Sale",
    },
    {
      field: "qa_score",
      headerName: "QA Score",
    },
    {
      field: "response_sla",
      headerName: "Response SLA",
    },
    {
      field: "resolve_sla",
      headerName: "Resolve SLA",
    },
    {
      field: "sales_per_agent",
      headerName: "Sales Per Agent",
    },
  ];

  if (GetVBCTargets.isLoading) {
    return <LinearProgress />;
  }

  return (
    <Container>
      <PageHeader
        title="Capture Targets"
        subheader="Capture Targets"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Capture Targets",
            href: "/CDA/VBCreports",
          },
        ]}
      />

      <Stack sx={{ mb: 3 }} direction="row" justifyContent="space-between">
        <VBCcaptureForm token={token} />
      </Stack>

      <Box sx={{ height: "60vh", width: "100%" }}>
        {rows && rows?.length > 0 && (
          <DataGridPremium columns={columns} rows={rows} />
        )}
      </Box>
    </Container>
  );
};

export default withAuth(Main);
