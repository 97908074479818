import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useFormikContext } from "formik";
import { Info } from "@mui/icons-material";
import React from "react";


export const RadioQuestionComponent = ({
  question,
  index,
  section,
  disabled,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleSetValues = (value, index) => {
    const newQuestions = [...values.questions[section]];
    newQuestions[index].value = value;

    let newData = {
      ...values.questions,
      [section]: newQuestions,
    };
    setFieldValue("questions", newData);
  };

  return (
    <FormControl>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={4}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <FormLabel id={`radio-label-${section}-${index}`}>
            {question.title}
          </FormLabel>
          <Tooltip title={question.toolTipText}>
            <Info style={{ fontSize: "15px", color: "gray" }} />
          </Tooltip>
        </Stack>
        <RadioGroup
          value={question.value}
          onChange={(e) => handleSetValues(e.target.value, index)}
          row
          aria-labelledby={`radio-label-${section}-${index}`}
          name={`radio-group-${section}-${index}`}>
          {Array(5)
            .fill()
            .map((_, value) => {
              return (
                <FormControlLabel
                  key={value}
                  disabled={disabled}
                  value={value}
                  control={<Radio />}
                  label={value}
                />
              );
            })}
        </RadioGroup>
      </Stack>
    </FormControl>
  );
};

export const TextFieldWrapper = ({ question, index, section, disabled }) => {
  const { values, setFieldValue } = useFormikContext();

  const newQuestions = [...values?.questions[section]];

  const [questionValue, setQuestionValue] = React.useState(
    newQuestions[index].value
  );

  const handleSetValues = (value, index) => {
    newQuestions[index].value = value;

    let newData = {
      ...values.questions,
      [section]: newQuestions,
    };
    setFieldValue("questions", newData);
  };
  return (
    <TextField
      fullWidth
      multiline
      disabled={disabled}
      rows={3}
      margin="normal"
      label={question.title}
      name={`text-field-${section}-${index}`}
      value={questionValue}
      onChange={(e) => setQuestionValue(e.target.value)}
      onBlur={() => handleSetValues(questionValue, index)}
    />
  );
};
