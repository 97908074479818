import React from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Button, Chip } from "@mui/material";
import DeleteTeamDialog from "./DeleteTeamDialog";
import ActiveTeam from "./ActiveTeam";

const TeamsList = ({ list, active }) => {
  const navigate = useNavigate();

  const Teams = list.filter((team) => team.active === active);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Teams</StyledTableCell>
            <StyledTableCell align="center">Members</StyledTableCell>
            <StyledTableCell align="right">Add/Remove Members</StyledTableCell>
            <StyledTableCell align="right">
              {" "}
              {active ? "Remove" : "Activate"}
            </StyledTableCell>
            {/* <StyledTableCell align="right">Delete</StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {Teams.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {row?.name}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Chip label={row?.cc_teams_users?.length || 0} />
              </StyledTableCell>
              <StyledTableCell align="right">
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => navigate(`/CDA/TeamsEdit/${row.id}`)}>
                  Manage Team
                </Button>
              </StyledTableCell>

              <StyledTableCell align="right">
                {row.active ? (
                  <DeleteTeamDialog data={row} />
                ) : (
                  <ActiveTeam data={row} />
                )}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamsList;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
