import { Alert, Box, LinearProgress, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import React from "react";
import withAuth from "../../../../../../Hoc/withAuth";

const SalesVsQaReports = ({ user }) => {
  const { token } = user;

  const { data, isLoading, isRefetching } = useQuery(
    ["GetQaStats"],
    async () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/reports/QaReports/SalesVsQAStageReport`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
  );

  // add id key to each object in the array
  // let campaigns = data?.data?.data.map((item, index) => {
  //   console.log(item);
  //   console.log(index);
  //   return { ...item, id: index + 1 };
  // });

  const columns = [
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      flex: 1,
      minWidth: 350,
    },
    {
      field: "sales",
      headerName: "Total Sales",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "not_loaded",
      headerName: "Not Loaded",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "incomplete",
      headerName: "Incomplete",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "complete",
      headerName: "Complete",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "completePercentage",
      headerName: "% Completed",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "fail",
      headerName: "Failed",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "bypass",
      headerName: "Bypassed",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "dispute_rejected",
      headerName: "Dispute - Rejected",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "technically_unverifiable",
      headerName: "Technically Unverifiable",
      flex: 1,
      minWidth: 100,
    },
    // {
    //   field: "notLoadedPercentage",
    //   headerName: "% Not Loaded",
    //   flex: 1,
    //   minWidth: 100,
    // },
    // {
    //   field: "incompletePercentage",
    //   headerName: "% Incomplete",
    //   flex: 1,
    //   minWidth: 100,
    // },

    // {
    //   field: "failPercentage",
    //   headerName: "% Failed",
    //   flex: 1,
    //   minWidth: 100,
    // },
    // {
    //   field: "reEvaluatePercentage",
    //   headerName: "% Re-Evaluate",
    //   flex: 1,
    //   minWidth: 100,
    // },
  ];

  // creates rows from the data based on the columns
  const campaigns = data?.data?.data?.map((item, index) => {
    return {
      id: index + 1,
      campaign_name: item.campaign_name,
      sales: item.sales,
      not_loaded: item.not_loaded,
      incomplete:
        Number(item.new) +
        Number(item.busy) +
        Number(item.re_evaluate) +
        Number(item.dispute),
      new: item.new,
      busy: item.busy,
      re_evaluate: item.re_evaluate,
      complete: item.complete,
      completePercentage: item.completePercentage,
      fail: item.fail,
      bypass: item.bypass,
      dispute: item.dispute,
      dispute_rejected: item.dispute_rejected,
      technically_unverifiable: item.technically_unverifiable,
      // notLoadedPercentage: item.notLoadedPercentage,
      // incompletePercentage: item.incompletePercentage,
      // failPercentage: item.failPercentage,
      // reEvaluatePercentage: item.reEvaluatePercentage,
    };
  });

  return (
    <Stack sx={{ mt: 3 }}>
      {(isRefetching || isLoading) && <LinearProgress />}

      <Box sx={{ mt: 4 }}>
        <Grid container>
          {campaigns && campaigns.length > 0 ? (
            <div
              style={{
                height: 750,
                width: "94vw",
              }}
            >
              <DataGridPremium
                slots={{
                  toolbar: GridToolbar,
                }}
                rows={campaigns}
                columns={columns}
                pagination
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterExcludeHiddenColumns: true,
                    },
                  },
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 25]}
              />
            </div>
          ) : (
            <Alert severity="info">No Data Found</Alert>
          )}
        </Grid>
      </Box>
    </Stack>
  );
};

export default withAuth(SalesVsQaReports);
