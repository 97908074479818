import { Card, CardActionArea, Grid, Typography } from "@mui/material";
import React from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import MainAppContainer from "../../../../../Components/Containers/MainAppContainer";
import withAuth from "../../../../../Hoc/withAuth";
import SubjectIcon from "@mui/icons-material/Subject";
import GroupsIcon from "@mui/icons-material/Groups";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ElevatorIcon from "@mui/icons-material/Elevator";

const QaConsole = ({ user, modules }) => {
  const navigate = useNavigate();

  if (
    modules &&
    modules.includes("call-center-admin") &&
    !user.userType === "admin"
  ) {
    navigate("/");
  }

  const { pathname } = useLocation();

  const currentPath = pathname.split("/")[3];

  const Paths = [
    {
      name: "Queues",
      location: "QaQueues",
      icon: <SubjectIcon />,
    },
    {
      name: "All Evaluations",
      location: "AllEvaluations",
      icon: <ElevatorIcon />,
    },
    {
      name: "Global Teams",
      location: "QATeams",
      icon: <GroupsIcon />,
    },
    {
      name: "Global Questions",
      location: "QaAllQuestions",
      icon: <QuestionMarkIcon />,
    },
    {
      name: "Call Center Campaign Linking",
      location: "CampaignLinking",
      icon: <QuestionMarkIcon />,
    },
  ];

  return (
    <MainAppContainer maxWidth="xl">
      <PageHeader
        title="QA Management"
        subheader="Manage QA Campaigns Campaigns"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Manage Call Center",
            href: "/CDA/QaConsole",
          },
        ]}
      />

      <Grid container spacing={1}>
        <Grid item xs={2}>
          {Paths.map((path, index) => {
            return (
              <Grid key={index} item xs={12}>
                <Card
                  sx={
                    currentPath === path.location
                      ? { borderStyle: "solid", borderColor: "secondary.main" }
                      : null
                  }>
                  <CardActionArea
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      py: 5,
                    }}
                    onClick={() => {
                      navigate(`${path.location}`);
                    }}>
                    <Typography color="textPrimary">{path.name}</Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={10}>
          <Outlet />
        </Grid>
      </Grid>
    </MainAppContainer>
  );
};

export default withAuth(QaConsole);
