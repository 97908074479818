import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { Button, Chip } from "@mui/material";

import EnableDisableQTeam from "./EnableDisableQTeam";

const QueuesTeamsList = ({ activeQaTeams, inactiveQATeams }) => {
  const navigate = useNavigate();

  const [showActive, setShowActive] = React.useState(false);

  return (
    <>
      <TableContainer sx={{ mt: 4 }} component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Active</StyledTableCell>
              <StyledTableCell>Edit</StyledTableCell>
              <StyledTableCell align="right">Disable/Activate</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeQaTeams &&
              activeQaTeams.length > 0 &&
              activeQaTeams?.map((row) => {
                return (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row?.qa_team?.name}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Chip
                        label={row?.qa_team?.active ? "Active" : "Inactive"}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Button
                        color="secondary"
                        onClick={() =>
                          navigate(`/CDA/QATeams/${row?.qa_team?.id}`)
                        }>
                        Edit Team Members
                      </Button>
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      <EnableDisableQTeam team={row} />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}

            <StyledTableRow>
              <StyledTableCell
                align="center"
                colSpan={5}
                component="th"
                scope="row">
                <Button
                  color="inherit"
                  onClick={() => {
                    setShowActive(!showActive);
                  }}>
                  {showActive ? "Show Inactive" : "Show Active"}
                </Button>
              </StyledTableCell>
            </StyledTableRow>

            {showActive &&
              inactiveQATeams &&
              inactiveQATeams.length > 0 &&
              inactiveQATeams?.map((row) => {
                return (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row?.qa_team?.name}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Chip
                        label={row?.qa_team?.active ? "Active" : "Inactive"}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Button
                        onClick={() =>
                          navigate(`/CDA/QATeams/${row?.qa_team?.id}`)
                        }>
                        Edit Team
                      </Button>
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      <EnableDisableQTeam team={row} />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default QueuesTeamsList;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
