import { Box } from "@mui/system";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import React, { useState } from "react";
import {
  Grid,
  Stack,
  Button,
  Card,
  Typography,
  Divider,
  Tooltip,
  Snackbar,
  Alert,
  LinearProgress,
  CardContent,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
// import { v4 as uuidv4 } from "uuid";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../../../Components/FormComponents/TextfieldWrapper";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import withAuth from "../../../../../Hoc/withAuth";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const calculateWorkingDays = () => {
  const publicHolidays = [
    "2023-01-01", // New Year's Day
    "2023-03-21", // Human Rights Day
    "2023-04-07", // Good Friday (varies each year)
    "2023-04-10", // Family Day (varies each year, day after Easter Sunday)
    "2023-04-27", // Freedom Day
    "2023-05-01", // Workers' Day
    "2023-06-16", // Youth Day
    "2023-08-09", // National Women's Day
    "2023-09-24", // Heritage Day
    "2023-12-16", // Day of Reconciliation
    "2023-12-25", // Christmas Day
    "2023-12-26", // Day of Goodwill
  ];

  const currentMonthStart = dayjs().startOf("month");
  const currentMonthEnd = dayjs().endOf("month");

  let workingDays = 0;

  for (
    let d = currentMonthStart;
    d.isSameOrBefore(currentMonthEnd);
    d = d.add(1, "day")
  ) {
    const isWeekend = d.day() === 0 || d.day() === 6;
    const isPublicHoliday = publicHolidays.includes(d.format("YYYY-MM-DD"));

    if (!isWeekend && !isPublicHoliday) {
      workingDays++;
    }
  }

  return workingDays;
};

const GlobalCampaignTargets = ({ user }) => {
  const token = user.token;

  const GetTargetDefaults = useQuery(["GetTargetDefaults"], async () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/campaignTargetDefaults`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  });

  let targetDefaults = GetTargetDefaults?.data?.data?.data[0];

  const postTargetDefaults = useMutation(
    ["postTargetDefaults"],
    async (data) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/campaignTargetDefaults`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  );

  const [campaigns, setCampaigns] = useState([]);

  const [filterOptions] = useState("draft");

  const [rows, setRows] = useState([]);

  const getCampaigns = useQuery(
    ["getCampaigns"],
    async () => {
      let S = "";
      let P = 1000;
      let PA = 0;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns?pageNumber=${PA}&limit=${P}${S}`,
        config
      );
      return data;
    },
    {
      onSuccess: (data) => {
        let filteredCampaigns = data?.data?.rows?.filter(
          (campaign) => campaign?.state === filterOptions
        );

        let campaigns = filteredCampaigns?.map((campaign) => {
          return {
            id: campaign?.id,
            campaign_id: campaign?.id,

            name: campaign?.name,
            Client: campaign?.cc_client?.name,
            bi_calc_type: campaign?.bi_calc_type,
            // dayjs Get Start of month
            expectedStartDate: new Date(
              dayjs(campaign?.expectedStartDate).startOf("month")
            ),
            workingDaysPerMonth:
              targetDefaults?.workingDays || calculateWorkingDays(),
            standardCampaignHoursPerDay:
              targetDefaults?.standardCampaignHoursPerDay || 6,
            leadsPerAgentPerDay: 0,
            averagePlannedLeadsPerDay: 0,
            actualCarryOverLeadsPriorMonth: 0,
            plannedLeadsMonth: 0,
            touchesPerLeadPerDay: 1,
            contactRatio: 0,
            contactsPerMonth: 0,
            salesPerContactRatio: 0,
            targetSales: 0,
            targetSalesPerLeadRatio: 0,
            targetSalesPerHour: 1,
            targetHours: 0,
            forecastAveragePremium: 0,
            pricingModelXp: campaign.bi_calc_rate,
            pricingModelAPI: 0,
            incomePerDialingHour: "",
            forecastTheoreticalBusinessIncome: 0,
            proportionTheoreticalBusinessIncome: 0,
            forecastTheoreticalBIFromXP: 0,
            forecastBusinessIncomePerSale: 0,
            ciPerSale: campaign?.ci_calc_rate,
            forecastCommissionIncome: 0,
            qaRate: targetDefaults?.qaRate || 70,
            qaTarget: targetDefaults?.qaRate || 70,
          };
        });

        setCampaigns(campaigns);
      },
    }
  );

  let campaignTargets = useQuery(
    ["getCampaignTargets"],
    async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/callCentre/campaignTargets`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    {
      enabled: getCampaigns.isSuccess,
      onSuccess: (data) => {
        console.log(data);

        let CampaignsWithData = campaigns?.map((campaign) => {
          let campaignData = data?.data?.find(
            (target) => target.campaign_id === campaign.campaign_id
          );

          if (campaignData) {
            return calculateRowData({
              ...campaignData,
              month: campaign?.month
                ? new Date(dayjs(campaign?.month).startOf("month"))
                : new Date(dayjs().startOf("month")),
              expectedStartDate: new Date(
                dayjs(campaign?.expectedStartDate).startOf("month")
              ),
            });
          } else {
            return calculateRowData(campaign);
          }
        });
        setRows(CampaignsWithData);
      },
      onError: (error) => {
        console.error("Error fetching campaign targets:", error);
        // Handle error state or display an error message as needed
      },
    }
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
    },
    {
      field: "name",
      headerName: "Campaign Name",
      width: 280,
    },
    {
      field: "Client",
      headerName: "Client",
      width: 200,
    },
    {
      field: "month",
      headerName: "Month of Target",
      width: 200,
      editable: true,
      type: "date",
      format: "MM/yyyy",
      valueGetter: (params) => {
        return params.value;
      },
    },
    {
      field: "bi_calc_type",
      headerName: "BI Calc Type",
      width: 200,
    },
    {
      field: "expectedStartDate",
      headerName: "Expected Start Date",
      type: "date",
      width: 180,
    },
    {
      field: "workingDaysPerMonth",
      headerName: "Working Days Per Month",
      width: 130,
      editable: true,

      type: "number",
    },
    {
      field: "standardCampaignHoursPerDay",
      headerName: "Standard Campaign Hours Per Day",
      width: 280,
      type: "number",
      default: targetDefaults?.standardCampaignHoursPerDay || 6,
    },
    {
      field: "leadsPerAgentPerDay",
      headerName: "Leads Per Agent Per Day",
      width: 180,
      editable: true,
      default: 0,
      type: "number",
      aggregation: true,
    },
    {
      field: "averagePlannedLeadsPerDay",
      headerName: "Average Planned Leads Per Day",
      width: 280,
      editable: true,
      type: "number",
      default: 0,
      aggregation: true,
    },
    {
      field: "actualCarryOverLeadsPriorMonth",
      headerName: "Actual Carry Over Leads Prior Month",
      width: 280,
      editable: true,
      type: "number",
      default: 0,
    },
    {
      field: "plannedLeadsMonth",
      headerName: "Planned Leads Month",
      width: 280,
      aggregation: true,
      type: "number",
      default: 0,
    },
    {
      field: "touchesPerLeadPerDay",
      headerName: "Touches Per Lead Per Day",
      width: 280,
      type: "number",
      aggregation: true,
      default: 0,
      editable: true,
    },
    {
      field: "contactRatio",
      headerName: "Contact Ratio %",
      type: "number",
      width: 280,
      default: 0,
      aggregation: true,
      editable: true,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `${Number(value.toFixed(2))} %`;
      },
    },
    {
      field: "contactsPerMonth",
      headerName: "Contacts Per Month",
      type: "number",
      width: 280,
      default: 0,
      aggregation: true,
    },
    {
      field: "salesPerContactRatio",
      headerName: "Target Sales Per Contact Ratio %",
      type: "number",
      width: 280,
      editable: true,
      default: 0,
      aggregation: true,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `${Number(value.toFixed(2))} %`;
      },
    },
    {
      field: "targetSales",
      headerName: "Target Sales",
      type: "number",
      width: 280,
      default: 0,
    },
    {
      field: "targetSalesPerLeadRatio",
      headerName: "Target Sales Per Lead Ratio %",
      type: "number",
      width: 280,
      default: 0,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `${Number(value.toFixed(2))} %`;
      },
    },
    {
      field: "targetSalesPerHour",
      headerName: "Target Sales Per Hour",
      type: "number",
      width: 280,
      default: 0,
      aggregation: true,
      editable: true,
    },
    {
      field: "targetHours",
      headerName: "Target Hours",
      type: "number",
      width: 280,
      default: 0,
    },
    {
      field: "forecastAveragePremium",
      type: "number",
      headerName: "Forecast Average Premium Excl. vat",
      width: 280,
      default: 0,
      editable: true,
      aggregation: true,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `R ${Number(value.toFixed(2))}`;
      },
    },
    {
      field: "pricingModelXp",
      headerName: "Pricing Model x P",
      type: "number",
      width: 280,
      editable: true,
      default: 0,
      aggregation: true,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `R ${Number(value.toFixed(2))}`;
      },
    },

    {
      field: "incomePerDialingHour",
      headerName: "Income Per Dialing Hour",
      type: "number",
      width: 280,
      editable: true,
      default: 0,
      aggregation: true,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `R ${Number(value.toFixed(2))}`;
      },
    },
    {
      field: "pricingModelAPI",
      headerName: "Pricing Model % API",
      width: 280,
      type: "number",
      editable: true,
      default: 0,
      aggregation: true,
    },
    {
      field: "forecastBusinessIncomePerSale",
      headerName: "Forecast Business Income Per Sale",
      type: "number",
      editable: true,
      aggregation: true,
      width: 280,
      default: 0,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `R ${Number(value.toFixed(2))}`;
      },
    },
    {
      field: "forecastBusinessIncomePerHour",
      headerName: "Forecast Business Income Per Hour",
      type: "number",
      width: 280,
      default: 0,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `R ${Number(value.toFixed(2))}`;
      },
    },
    {
      field: "forecastTheoreticalBusinessIncome",
      type: "number",
      headerName: "Forecast Theoretical Business Income",
      width: 280,
      default: 0,
      aggregation: "sum",
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `R ${Number(value.toFixed(2))}`;
      },
    },
    {
      field: "proportionTheoreticalBusinessIncome",
      headerName: "Proportion Theoretical Business Income",
      type: "number",
      default: 0,
      valueGetter: (params) =>
        params.api.getCellValue(
          params.id,
          "forecastTheoreticalBusinessIncome",
          "sum"
        ),

      width: 280,

      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `R ${Number(value.toFixed(2))}`;
      },
    },
    {
      field: "forecastTheoreticalBIFromXP",
      type: "number",
      headerName: "Forecast Theoretical BI From x P",

      width: 220,
      default: 0,

      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `${Number(value.toFixed(2))} %`;
      },
    },
    {
      field: "ciPerSale",
      headerName: "CI Per Sale",
      type: "number",
      editable: true,
      width: 150,
      aggregation: true,
      default: 0,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `R ${Number(value.toFixed(2))}`;
      },
    },
    {
      field: "forecastCommissionIncome",
      headerName: "Forecast Commission Income",
      type: "number",
      width: 200,
      default: 0,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `R ${Number(value.toFixed(2))}`;
      },
    },
    {
      field: "qaRate",
      headerName: "QA Rate",
      type: "number",
      width: 120,
      default: 0,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return value + "%";
      },
    },
    {
      field: "qaTarget",
      type: "number",
      headerName: "QA Target",
      default: 0,
      width: 130,
    },
  ];

  const calculateRowData = (newRow) => {
    // Calculate fields that have no dependencies
    const plannedLeadsMonth =
      newRow.averagePlannedLeadsPerDay * newRow.workingDaysPerMonth +
      newRow.actualCarryOverLeadsPriorMonth;

    // Calculate fields dependent on `plannedLeadsMonth`
    const contactsPerMonth = plannedLeadsMonth * (newRow.contactRatio / 100);

    // Calculate fields dependent on `contactsPerMonth`
    const targetSales = parseFloat(
      (newRow.salesPerContactRatio / 100) * contactsPerMonth
    );

    // Calculate fields dependent on `targetSales`
    const targetSalesPerLeadRatio = parseFloat(
      (targetSales / plannedLeadsMonth) * 100
    );
    const targetHours = parseFloat(targetSales / newRow.targetSalesPerHour);
    let forecastBusinessIncomePerSale = 0;
    let forecastBusinessIncomePerHour = 0;
    let forecastTheoreticalBusinessIncome = 0;
    let forecastTheoreticalBIFromXP = 0;

    // forecast business income per sale
    if (newRow.bi_calc_type === "average premium") {
      forecastBusinessIncomePerSale = parseFloat(
        (newRow.forecastAveragePremium * newRow.pricingModelXp).toFixed(2)
      );

      forecastTheoreticalBusinessIncome = parseFloat(
        targetSales * forecastBusinessIncomePerSale
      );

      forecastTheoreticalBIFromXP = forecastTheoreticalBusinessIncome;
    } else if (newRow.bi_calc_type === "hours") {
      forecastBusinessIncomePerSale = newRow.forecastBusinessIncomePerSale;
      forecastBusinessIncomePerHour = parseFloat(
        (newRow.plannedLeadsMonth / 10) * 120
      );

      forecastTheoreticalBusinessIncome = parseFloat(
        Number(targetSales) * Number(forecastBusinessIncomePerSale) +
          Number(forecastBusinessIncomePerHour)
      );
    } else if (newRow.bi_calc_type === "cost per sale") {
      forecastBusinessIncomePerSale = newRow.forecastBusinessIncomePerSale;
      forecastBusinessIncomePerHour = parseFloat(
        (newRow.plannedLeadsMonth / 10) * 120
      ).toFixed(2);

      forecastTheoreticalBusinessIncome = parseFloat(
        targetSales * forecastBusinessIncomePerSale +
          forecastBusinessIncomePerHour
      ).toFixed(2);
    }

    let qaTarget = newRow?.qaRate * targetSales;

    let forecastCommissionIncome = targetSales * newRow.ciPerSale;

    let incomePerDialingHour = 0;

    if (newRow.bi_calc_type === "hours") {
      incomePerDialingHour = parseFloat((newRow.plannedLeadsMonth / 10) * 120);
    } else if (newRow.bi_calc_type === "cost per sale") {
      incomePerDialingHour = parseFloat((newRow.plannedLeadsMonth / 10) * 120);
    } else {
      incomePerDialingHour = parseFloat(
        newRow.forecastAveragePremium * newRow.pricingModelXp
      );
    }

    const updatedRow = {
      ...newRow,
      plannedLeadsMonth,
      contactsPerMonth,
      targetSales,
      targetSalesPerLeadRatio,
      targetHours,
      forecastBusinessIncomePerSale,
      forecastBusinessIncomePerHour: Number(forecastBusinessIncomePerHour),
      forecastTheoreticalBusinessIncome: Number(
        forecastTheoreticalBusinessIncome
      ),
      forecastTheoreticalBIFromXP,
      qaTarget,
      forecastCommissionIncome,
      incomePerDialingHour,
    };

    return updatedRow;
  };
  // Handler for row selection
  const handleRowSelected = (params) => {
    const selectedIDs = new Set(params.selectionModel);
    setSelectedRows(rows?.filter((row) => selectedIDs.has(row.id)));
  };

  // useEffect(() => {
  //   if (filteredCampaigns) {
  //     // const rows = filteredCampaigns?.map((campaign) => {
  //     //   return calculateRowData({
  //     //     id: campaign?.id,
  //     //     name: campaign?.name,
  //     //     Client: campaign?.cc_client?.name,
  //     //     bi_calc_type: campaign?.bi_calc_type,
  //     //     // dayjs Get Start of month
  //     //     expectedStartDate: new Date(
  //     //       dayjs(campaign?.expectedStartDate).startOf("month")
  //     //     ),
  //     //     workingDaysPerMonth:
  //     //       targetDefaults?.workingDays || calculateWorkingDays(),
  //     //     standardCampaignHoursPerDay:
  //     //       targetDefaults?.standardCampaignHoursPerDay || 6,
  //     //     leadsPerAgentPerDay: 0,
  //     //     averagePlannedLeadsPerDay: 0,
  //     //     actualCarryOverLeadsPriorMonth: 0,
  //     //     plannedLeadsMonth: 0,
  //     //     touchesPerLeadPerDay: 1,
  //     //     contactRatio: 0,
  //     //     contactsPerMonth: 0,
  //     //     salesPerContactRatio: 0,
  //     //     targetSales: 0,
  //     //     targetSalesPerLeadRatio: 0,
  //     //     targetSalesPerHour: 1,
  //     //     targetHours: 0,
  //     //     forecastAveragePremium: 0,
  //     //     pricingModelXp: campaign.bi_calc_rate,
  //     //     pricingModelAPI: 0,
  //     //     incomePerDialingHour: "",
  //     //     forecastTheoreticalBusinessIncome: 0,
  //     //     proportionTheoreticalBusinessIncome: 0,
  //     //     forecastTheoreticalBIFromXP: 0,
  //     //     forecastBusinessIncomePerSale: 0,
  //     //     ciPerSale: campaign?.ci_calc_rate,
  //     //     forecastCommissionIncome: 0,
  //     //     qaRate: targetDefaults?.qaRate || 70,
  //     //     qaTarget: targetDefaults?.qaRate || 70,
  //     //   });
  //     // });

  //     setRows(filteredCampaigns);
  //   }
  // }, [filteredCampaigns, targetDefaults]);

  // useEffect(() => {
  //   if (filterOptions) {
  //     setFilteredCampaigns(
  //       campaigns?.rows?.filter((campaign) => campaign?.state === filterOptions)
  //     );
  //   }
  // }, [campaigns, filterOptions]);

  // let campaignTargets = useQuery(
  //   ["getCampaignTargets"],
  //   async () => {
  //     return axios.get(
  //       `${process.env.REACT_APP_API_URL}/callCentre/campaignTargets`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //   },
  //   {
  //     onSuccess: (data) => {
  //       if (data?.data?.data?.length === 0) {
  //         return setRows(
  //           filteredCampaigns?.map((campaign) => {
  //             return calculateRowData({
  //               id: campaign?.id,
  //               campaign_id: campaign?.id,
  //               name: campaign?.name,
  //               Client: campaign?.cc_client?.name,
  //               bi_calc_type: campaign?.bi_calc_type,
  //               // dayjs Get Start of month
  //               expectedStartDate: new Date(
  //                 dayjs(campaign?.expectedStartDate).startOf("month")
  //               ),
  //               workingDaysPerMonth:
  //                 targetDefaults?.workingDays || calculateWorkingDays(),
  //               standardCampaignHoursPerDay:
  //                 targetDefaults?.standardCampaignHoursPerDay || 6,
  //               leadsPerAgentPerDay: 0,
  //               averagePlannedLeadsPerDay: 0,
  //               actualCarryOverLeadsPriorMonth: 0,
  //               plannedLeadsMonth: 0,
  //               touchesPerLeadPerDay: 1,
  //               contactRatio: 0,
  //               contactsPerMonth: 0,
  //               salesPerContactRatio: 0,
  //               targetSales: 0,
  //               targetSalesPerLeadRatio: 0,
  //               targetSalesPerHour: 1,
  //               targetHours: 0,
  //               forecastAveragePremium: 0,
  //               pricingModelXp: campaign.bi_calc_rate,
  //               pricingModelAPI: 0,
  //               incomePerDialingHour: "",
  //               forecastTheoreticalBusinessIncome: 0,
  //               proportionTheoreticalBusinessIncome: 0,
  //               forecastTheoreticalBIFromXP: 0,
  //               forecastBusinessIncomePerSale: 0,
  //               ciPerSale: campaign?.ci_calc_rate,
  //               forecastCommissionIncome: 0,
  //               qaRate: targetDefaults?.qaRate || 70,
  //               qaTarget: targetDefaults?.qaRate || 70,
  //             });
  //           })
  //         );
  //       }

  //       let resData = data?.data?.data?.map((target) => {
  //         return filteredCampaigns?.map((campaign) => {
  //           if (campaign.id === target.campaign_id) {
  //             return calculateRowData({
  //               ...target,
  //               expectedStartDate: new Date(
  //                 dayjs(campaign?.expectedStartDate).startOf("month")
  //               ),
  //             });
  //           } else {
  //             return calculateRowData({
  //               id: campaign?.id,
  //               campaign_id: campaign?.id,
  //               name: campaign?.name,
  //               Client: campaign?.cc_client?.name,
  //               bi_calc_type: campaign?.bi_calc_type,
  //               // dayjs Get Start of month
  //               expectedStartDate: new Date(
  //                 dayjs(campaign?.expectedStartDate).startOf("month")
  //               ),
  //               workingDaysPerMonth:
  //                 targetDefaults?.workingDays || calculateWorkingDays(),
  //               standardCampaignHoursPerDay:
  //                 targetDefaults?.standardCampaignHoursPerDay || 6,
  //               leadsPerAgentPerDay: 0,
  //               averagePlannedLeadsPerDay: 0,
  //               actualCarryOverLeadsPriorMonth: 0,
  //               plannedLeadsMonth: 0,
  //               touchesPerLeadPerDay: 1,
  //               contactRatio: 0,
  //               contactsPerMonth: 0,
  //               salesPerContactRatio: 0,
  //               targetSales: 0,
  //               targetSalesPerLeadRatio: 0,
  //               targetSalesPerHour: 1,
  //               targetHours: 0,
  //               forecastAveragePremium: 0,
  //               pricingModelXp: campaign.bi_calc_rate,
  //               pricingModelAPI: 0,
  //               incomePerDialingHour: "",
  //               forecastTheoreticalBusinessIncome: 0,
  //               proportionTheoreticalBusinessIncome: 0,
  //               forecastTheoreticalBIFromXP: 0,
  //               forecastBusinessIncomePerSale: 0,
  //               ciPerSale: campaign?.ci_calc_rate,
  //               forecastCommissionIncome: 0,
  //               qaRate: targetDefaults?.qaRate || 70,
  //               qaTarget: targetDefaults?.qaRate || 70,
  //             });
  //           }
  //         });
  //       });
  //       setRows(...resData);
  //     },
  //   }
  // );

  const sortedRows = rows;

  // const SaveData = () => {
  //   console.log(rows);
  // };

  // const handleFilterCampaigns = (e) => {
  //   setFilterOptions(e.target.value);
  //   getCampaigns.refetch();
  // };

  const [snackbar, setSnackbar] = React.useState(null);

  const sendData = useMutation(
    ["sendData"],
    async (data) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/campaignTargets`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        console.log(data);
        setSnackbar({
          children: "User successfully saved",
          severity: "success",
        });
      },
      onError: (error) => {
        handleProcessRowUpdateError(error);
      },
    }
  );

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  const handleProcessRowUpdate = React.useCallback(
    (newRow) => {
      const updatedRow = calculateRowData(newRow); // Reuse the calculation logic
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

      sendData.mutate(updatedRow);

      return updatedRow;
    },
    [rows, sendData]
  );

  console.log(rows);

  if (campaignTargets?.isLoading && getCampaigns.isLoading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <Card sx={{ mb: 2 }} variant="outlined">
        <CardContent>
          <Stack>
            <Typography color="GrayText" variant="caption">
              <strong>NOTE:</strong> Editing Campaign target will affect the
              Stats displays for all users.
            </Typography>
            <Typography color="GrayText" variant="caption">
              Changes to the Data Grid will save automatically. Notice the save
              message at the bottom of the screen.
            </Typography>
            <Typography color="GrayText" variant="caption">
              Only the highlighted fields are editable.
            </Typography>
          </Stack>
        </CardContent>
      </Card>

      {/* <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between">
        <Button variant="contained" onClick={SaveData}>
          Save Data
        </Button>
      </Stack>
      <Box sx={{ minWidth: 120, mb: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Filter Campaigns
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterOptions}
            label="Filter Campaigns"
            onChange={handleFilterCampaigns}>
            <MenuItem value="draft">Draft</MenuItem>
            <MenuItem value="running">Running</MenuItem>
            <MenuItem value="stopped">Stopped</MenuItem>
            <MenuItem value="paused">Paused</MenuItem>
          </Select>
        </FormControl>
      </Box> */}

      <Box
        sx={{
          maxWidth: "100%",
          mb: 3,
          "& .edit": {
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "primary.dark",
            backgroundColor: "divider",
          },
          "& .forecastTheoreticalBusinessIncome": {
            backgroundColor: "divider",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "warning.main",
          },

          "& .noForecastBusinessIncomePerSale": {
            backgroundColor: "divider",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "error.main",
            background: "#805252",
          },
        }}>
        {rows && rows.length > 0 && (
          <DataGridPremium
            autoHeight
            getCellClassName={(params) => {
              if (params.colDef.editable && params.value === undefined) {
                return "noForecastBusinessIncomePerSale";
              }

              if (params.colDef.editable) {
                return "edit";
              }

              if (params.field === "forecastTheoreticalBusinessIncome") {
                return "forecastTheoreticalBusinessIncome";
              }
            }}
            disableColumnReorder={true}
            editMode="row"
            loading={campaignTargets.isLoading}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            experimentalFeatures={{ newEditingApi: true }}
            rows={rows}
            processRowUpdate={handleProcessRowUpdate}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            initialState={{
              pinnedColumns: { left: ["name"] },
              aggregation: {
                model: {
                  standardCampaignHoursPerDay: "avg",

                  leadsPerAgentPerDay: "avg",
                  salesPerContactRatio: "avg",
                  contactRatio: "avg",
                  ciPerSale: "avg",
                  averagePlannedLeadsPerDay: "sum",
                  actualCarryOverLeadsPriorMonth: "sum",
                  targetSalesPerHour: "sum",
                  forecastAveragePremium: "sum",
                  touchesPerLeadPerDay: "avg",
                  pricingModelXp: "avg",
                  incomePerDialingHour: "sum",
                  pricingModelAPI: "avg",
                  forecastBusinessIncomePerSale: "avg",
                  contactsPerMonth: "sum",
                  plannedLeadsMonth: "sum",
                  forecastBusinessIncomePerHour: "sum",
                  forecastTheoreticalBusinessIncome: "sum",
                  forecastCommissionIncome: "sum",
                  workingDaysPerMonth: "avg",
                },
              },
            }}
            onSelectionModelChange={handleRowSelected}
            getRowClassName={(params) =>
              selectedRows.find((row) => row.id === params.id)
                ? "selected-row"
                : ""
            }
          />
        )}
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <Grid container spacing={2} sx={{ mb: 10 }}>
        <Grid item xs={6}>
          <CampaignStats
            GetTargetDefaults={targetDefaults}
            postTargetDefaults={postTargetDefaults}
          />
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6">info</Typography>

            <InfoItem
              toolTipText={`Campaigns in the ${filterOptions} state`}
              title="Campaigns (no.)"
              value={sortedRows?.length}
            />
            <InfoItem
              title="x P campaigns (no.)"
              toolTipText={
                "Campaigns that are calculated using the average premium"
              }
              value={
                sortedRows?.filter(
                  (campaign) => campaign.bi_calc_type === "average premium"
                )?.length
              }
            />
            <InfoItem
              title="API campaigns (no.)"
              toolTipText={
                "Campaigns that are calculated using the cost per sale"
              }
              value={
                sortedRows?.filter(
                  (campaign) => campaign.bi_calc_type === "cost per sale"
                )?.length
              }
            />
            <InfoItem
              toolTipText={`Agents required = total planned leads for the month / average leads per agent per day / number of working days.`}
              title="Agents Required"
              value={sortedRows
                ?.reduce((acc, row) => {
                  return (
                    acc +
                    row.plannedLeadsMonth /
                      row.leadsPerAgentPerDay /
                      row.workingDaysPerMonth
                  );
                }, 0)
                .toFixed(2)}
            />

            <InfoItem
              toolTipText="Agents deficit (no.) = agents required - current agents"
              title="Agents Deficit (no.)"
              value={
                sortedRows
                  ?.reduce((acc, row) => {
                    return (
                      acc +
                      row.plannedLeadsMonth /
                        row.leadsPerAgentPerDay /
                        row.workingDaysPerMonth
                    );
                  }, 0)
                  .toFixed(2) - targetDefaults?.totalAgents
              }
            />
            <InfoItem
              toolTipText="Revenue per seat = forecast BI / current agents."
              title="Revenue per seat"
              value={`R ${
                sortedRows?.reduce((acc, row) => {
                  return acc + row.forecastTheoreticalBusinessIncome;
                }, 0) / targetDefaults?.totalAgents
              }`}
              // Add Return from Defaults with current agents count
            />
            <InfoItem
              toolTipText="Sales deficit (no.) = target sales - actual sales"
              title="Sales deficit (no.)"
              value={"N/A For This ?"}
            />
            <InfoItem
              toolTipText="Sales deficit (Rands) = sales deficit (no.) * forecast average premium"
              title="Sales deficit (Rands)"
              value={"N/A For This ?"}
            />
          </Card>
        </Grid>
      </Grid>
      <AlertPopup
        open={postTargetDefaults.isSuccess}
        message="Target Posted Successfully"
        severity={"success"}
      />
      <AlertPopup
        open={postTargetDefaults.isError}
        message="Error Posting Targets"
        severity={"error"}
      />
    </div>
  );
};

export default withAuth(GlobalCampaignTargets);

// Define your data as an array of objects

const CampaignStats = ({ postTargetDefaults, GetTargetDefaults }) => {
  const data = GetTargetDefaults;

  const FormFields = [
    {
      formName: "workingDays",
      name: "Working days (no.)",
    },
    { formName: "totalAgents", name: "Total agents" },

    { formName: "agentsInTraining", name: "Agents in training" },
    {
      formName: "standardCampaignHoursPerDay",
      name: "Standard Campaign Hours Per Day",
    },
    {
      formName: "possibleResignations",
      name: "Possible Resignations",
    },
    { formName: "qaRate", name: "QA Rate" },
  ];

  return (
    <>
      <Formik
        initialValues={{
          id: data?.id || "",
          workingDays: data?.workingDays || calculateWorkingDays(),
          standardCampaignHoursPerDay: data?.standardCampaignHoursPerDay || "",
          totalAgents: data?.totalAgents || "",
          agentsInTraining: data?.agentsInTraining || "",
          possibleResignations: data?.possibleResignations || "",
          qaRate: data?.qaRate || "",
          month: data?.month || dayjs(),
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          postTargetDefaults.mutate(values);
        }}>
        {({ dirty }) => {
          return (
            <Form>
              <Card sx={{ p: 2 }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6">Global Captures</Typography>
                  <Typography variant="caption">
                    Calc. days {calculateWorkingDays()}
                  </Typography>
                </Stack>
                <Divider sx={{ my: 1 }} />
                <Stack spacing={1}>
                  {dirty && (
                    <Button variant="contained" type="submit">
                      SAVE
                    </Button>
                  )}
                  <Grid container spacing={1}>
                    {FormFields.map((row, index) => {
                      return (
                        <Grid key={index} item xs={6}>
                          <TextfieldWrapper
                            size="small"
                            name={row.formName}
                            label={row.name}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Stack>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const InfoItem = ({ title, value, toolTipText }) => {
  return (
    <>
      <Divider sx={{ my: 1 }} />
      <Grid container>
        <Grid item xs={6}>
          <Stack direction="row" spacing={2}>
            <Typography variant="body2">{title}</Typography>
            {toolTipText && <HelperIcon toolTipText={toolTipText} />}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{value}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

const HelperIcon = ({ toolTipText }) => {
  return (
    <>
      {toolTipText && (
        <Tooltip title={toolTipText}>
          <Info style={{ fontSize: "15px", color: "aqua" }} />
        </Tooltip>
      )}
    </>
  );
};
