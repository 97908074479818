import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack, Autocomplete, TextField  } from "@mui/material";

export default function LinkCampaignModal({ campaigns, addCampaign, existingCampaigns }) {
  const [open, setOpen] = React.useState(false);

  const [selectedCampaign, setSelectedCampaign] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {

    addCampaign.mutate({campaign_id:selectedCampaign.id})
    setOpen(false);

  };

  const filteredCampaigns = campaigns.filter(
    (campaign) => !existingCampaigns.includes(campaign.name)
  );



  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add Call Center Campaign
      </Button>
      <Dialog
      maxWidth="md"
      fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add Call Center Campaign
        </DialogTitle>
        <DialogContent>
          <Stack sx={{my: 2}}>
            <Autocomplete
              id="campaign-autocomplete"
              freeSolo
              options={filteredCampaigns}
              getOptionLabel={(option) => option.name || ""}
              onChange={(event, newValue) => setSelectedCampaign(newValue)}
              renderInput={(params) => <TextField {...params} label="Search" />}
              sx={{ width: "101%", ml: -2 }}
            />
          </Stack>

          <Stack sx={{mt: 2}} direction="row" justifyContent="flex-end">
            <Button onClick={handleClose}>Close</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
