import { useTheme } from "@emotion/react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { useState } from "react";
import * as yup from "yup";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";

import AssignmentIcon from '@mui/icons-material/Assignment';


import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from 'axios'

const AddPositionModal = ({ modules }) => {
  const queryClient = useQueryClient()

  const [open, setOpen] = useState(false);
  const [mutationError, setMutationError] = useState('')
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  let isCeo = modules?.includes("oe-ceo") ? modules?.includes("oe-ceo") : "";

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const mutation = useMutation({
    mutationFn: (values) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/onboarding/positions/`,
        values,
        config
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getPositions']);
    },
    onError: async (error) => {
      setMutationError(error.response.data.message);
    },
  });
  
  const savePosition = (formData) => {
    mutation.mutate(formData);
    setTimeout(() => {
      handleClose();
    }
    , 2000);
  };
  

  return (
    <Stack spacing={0}>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<AssignmentIcon />}
        onClick={handleClickOpen}
        fullWidth
        sx={{ height: "100%" }}
      >
        Add Position
      </Button>
      <Stack>
        
          

        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          fullWidth
          aria-labelledby="responsive-dialog-title"
          sx={{ borderRadius: 0 }}
        >
          {mutation.isLoading && (
            <AlertPopup open={true} severity={"info"} message={"Saving Position..."} />
          )}

          { mutation.isSuccess && (
            <AlertPopup open={true} message={"Position added successfully!"} />
          )}

          { mutationError && (
            <AlertPopup open={true} message={mutationError} severity={"error"} />
          ) }
          <DialogTitle id="responsive-dialog-title">Add Position</DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                isCeo: isCeo || false,
                job_title: "",
                rem_channel_grade: "",
                job_description_url: "",
                job_advertisment: "",
                performance_contract: "",
              }}
              validationSchema={yup.object().shape({
                job_title: yup.string().required("Job title required"),
                rem_channel_grade: yup.string().when("isCeo", {
                  is: true,
                  then: yup.string().required("Rem channel grade required"),
                }),
                job_description_url: yup
                  .string()
                  .required("please upload job description"),
                job_advertisment: yup
                  .string()
                  .required("please upload job advertisment"),
                performance_contract: yup
                  .string()
                  .required("please upload performance contract"),
              })}
            >
              {({
                values,
                errors,
                handleSubmit,
                setFieldValue,
                getFieldMeta,
              }) => {
                return (
                  <Form encType="multipart/form-data" id="form">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} mt={1}>
                        <TextfieldWrapper
                          name={"job_title"}
                          placeholder={"Job Title"}
                          label="Job Title"
                        />
                      </Grid>
                      {modules?.includes("oe-ceo") && (
                        <>
                          <Grid item xs={12} md={12}>
                            <TextfieldWrapper
                              name={"rem_channel_grade"}
                              placeholder={"Remuneration Grade"}
                              label="Rem Grade"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} md={12}>
                        <InputLabel>Job Description</InputLabel>
                        <TextField
                          name={"job_description_url"}
                          id="url"
                          type="file"
                          fullWidth
                          onChange={(e) =>
                            setFieldValue("job_description_url", "placeholder")
                          }
                          helperText={
                            getFieldMeta("job_description_url").error &&
                            "Please upload job description"
                          }
                          error={
                            Boolean(
                              getFieldMeta("job_description_url").error,
                            ) || false
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <InputLabel>Job Advertisement</InputLabel>
                        <TextField
                          name={"job_advertisment"}
                          id="url"
                          type="file"
                          fullWidth
                          onChange={(e) =>
                            setFieldValue("job_advertisment", "placeholder")
                          }
                          helperText={
                            getFieldMeta("job_description_url").error &&
                            "Please upload job advertisment"
                          }
                          error={
                            Boolean(getFieldMeta("job_advertisment").error) ||
                            false
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <InputLabel>Performance Contract</InputLabel>
                        <TextField
                          name={"performance_contract"}
                          id="url"
                          type="file"
                          fullWidth
                          onChange={(e) =>
                            setFieldValue("performance_contract", "placeholder")
                          }
                          helperText={
                            getFieldMeta("performance_contract").error &&
                            "Please upload performance contract"
                          }
                          error={
                            Boolean(
                              getFieldMeta("performance_contract").error,
                            ) || false
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Stack direction="row" justifyContent="end" spacing={2}>
                          <Button
                            autoFocus
                            onClick={handleClose}
                            variant="contained"
                          >
                            Cancel
                          </Button>

                          <Button
                            variant="contained"
                            sx={{ width: 170 }}
                            color="secondary"
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default form submission behavior

                              if (
                                values.job_title === "" ||
                                values.job_description_url === "" ||
                                values.performance_contract === "" ||
                                values.job_advertisment === ""
                              ) {
                                handleSubmit();
                              } else if (
                                modules?.includes("oe-ceo") &&
                                (values.job_title === "" ||
                                  values.job_description_url === "" ||
                                  values.performance_contract === "" ||
                                  values.job_advertisment === "")
                              ) {
                                handleSubmit();
                              } else {
                                const formData = new FormData(document.querySelector("#form"));
                                savePosition(formData);
                              }
                            }}
                          >
                            Save
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default AddPositionModal;
