import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDispositionListList } from "./State/DispostionsDuck";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useParams, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  RESET_CAMPAIGNS,
  RemoveDispositionListFromCampaign,
  addDispositionToCampaign,
  getCampaignDispositions,
} from "./State/CampaingsDuck";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";

const CampaignDispositionManage = () => {
  const [dispositionsList, setDispositionsList] = React.useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch({
      type: RESET_CAMPAIGNS,
    });
    dispatch(getDispositionListList());
    dispatch(getCampaignDispositions(id));
  }, [dispatch, id]);

  const Campaigns = useSelector((state) => state.Campaigns);

  const { campaignDispositions, removedList, addedSuccess } = Campaigns;

  const Dispositions = useSelector((state) => state.Dispositions);

  const { disposition_list } = Dispositions;

  const handleSubmit = () => {
    dispatch(addDispositionToCampaign(dispositionsList, id));
    setDispositionsList("");
  };

  // filter DispositionList to show in Select

  let FilteredList = disposition_list?.filter(
    (disposition) =>
      campaignDispositions.cc_disposition_lists
        ?.map((dispList) => dispList.name)
        ?.indexOf(disposition.name) === -1
  );

  return (
    <>
      <AlertPopup
        open={removedList}
        message="Disposition List Removed Successfully"
        severity="warning"
      />
      <AlertPopup
        open={addedSuccess}
        message="Disposition List Added Successfully"
        severity="success"
      />

      <Card sx={{ mb: 2 }} variant="outlined">
        <CardContent>
          <Stack>
            <Typography color="GrayText" variant="caption">
              Allocate Disposition List to this campaign.
            </Typography>
            <Typography color="GrayText" variant="caption">
              NB: If no disposition list is allocated to the campaign, the
              campaign will not be able to make sales.
            </Typography>
          </Stack>
        </CardContent>
      </Card>

      <Stack>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Select Disposition List
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={dispositionsList}
            label="Select Disposition List"
            onChange={(event) => setDispositionsList(event.target.value)}>
            {FilteredList?.map((dispositionList) => {
              return (
                <MenuItem key={dispositionList.id} value={dispositionList.id}>
                  {dispositionList.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Stack direction="row" justifyContent="space-between">
          <Button sx={{ mt: 3 }} variant="contained" onClick={handleSubmit}>
            Add
          </Button>
          <Button
            sx={{ mt: 3 }}
            variant="outlined"
            onClick={() => {
              navigate("/CDA/callCenter/dispositions");
            }}>
            Manage Dispositions ?
          </Button>
        </Stack>
      </Stack>

      <Stack sx={{ mt: 4 }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>Disposition</StyledTableCell>
              <StyledTableCell align="right">_type</StyledTableCell>
              <StyledTableCell align="right">Remove/Activate</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaignDispositions.cc_disposition_lists &&
              campaignDispositions.cc_disposition_lists.length > 0 &&
              campaignDispositions.cc_disposition_lists
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((row) => (
                  <ListRow
                    key={row.name}
                    row={row}
                    activeDispositionDetails={
                      campaignDispositions.cc_disposition_lists
                    }
                  />
                ))}
          </TableBody>
        </Table>
      </Stack>
    </>
  );
};

export default CampaignDispositionManage;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function ListRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  // filter used Disposition Details from activeDispositionDetails

  const { id } = useParams();

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.name}
        </TableCell>
        <TableCell align="right">{row.type}</TableCell>
        <TableCell align="right">
          <DeleteListFromCampaign id={id} row={row} />
        </TableCell>
      </TableRow>

      {/* Dispositions Row  */}
      <TableRow>
        <StyledTableCell
          sx={{ backgroundColor: "background.default" }}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ my: 1 }}>
                <Typography variant="body2" gutterBottom component="div">
                  Disposition Options
                </Typography>
              </Stack>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Is Active</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.cc_dispositions?.map((disposition) => {
                    return <Row2 key={disposition.id} row={disposition} />;
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
}
function Row2(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  // filter used Disposition Details from activeDispositionDetails

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.name}
        </TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>
          {/* <RemoveDispositionFromCampaign id={id} row={row} /> */}
          <Chip
            label={row.active ? "Active" : "Inactive"}
            color={row.active ? "success" : "error"}
          />
        </TableCell>
      </TableRow>

      {/* Dispositions Row  */}
      <TableRow>
        <StyledTableCell
          sx={{ backgroundColor: "background.paper" }}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ my: 1 }}>
                <Typography variant="body2" gutterBottom component="div">
                  Disposition Options
                </Typography>
              </Stack>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Is Active</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.cc_disposition_details?.map((disposition) => {
                    return (
                      <>
                        <TableRow key={disposition?.id}>
                          <StyledTableCell component="th" scope="row">
                            {disposition?.nam}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <Chip
                              label={disposition?.act ? "Active" : "Disabled"}
                              variant="outlined"
                              color={disposition?.act ? "default" : "warning"}
                            />
                          </StyledTableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
}

function DeleteListFromCampaign({ row, id }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(RemoveDispositionListFromCampaign({ id, list_id: row?.id }));

    setOpen(false);
  };

  return (
    <div>
      <Button color="error" onClick={handleClickOpen}>
        Remove List
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Remove Disposition List</DialogTitle>

        <DialogContent>
          <Typography variant="body2" gutterBottom>
            Are you sure you want to remove this disposition list from this
            campaign?
          </Typography>
          <Typography variant="h5" gutterBottom>
            {row?.name}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="error" onClick={handleSubmit}>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
