import { Container, Grid } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getQaEvaluationById } from "../../QAManagement/State/QaDuck";

const EditEvaluation = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQaEvaluationById(id));
  }, [dispatch, id]);

  const Qa = useSelector((state) => state.Qa);
  const { queueEvaluation } = Qa;

  return (
    <Container>
      <Formik
        initialValues={{
          name: queueEvaluation.name,
          description: queueEvaluation.description,
          questions: queueEvaluation.questions,
        }}
        onSubmit={(values) => {
          console.log(values);
        }}>
        {() => {
          return (
            <Grid container>{JSON.stringify(queueEvaluation, null, 2)}</Grid>
          );
        }}
      </Formik>
    </Container>
  );
};

export default EditEvaluation;
