import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { deleteTarget } from "../State/TargetsDuck";
import { IconButton, Stack } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { Form, Formik } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";
import DateSelectorWrapper from "../../../../../../Components/FormComponents/DateSelectorWrapper";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";

export default function DeleteTarget({ target }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDelete = () => {
    dispatch(deleteTarget(target.id, target.user_id));
  };

  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        color="error"
        aria-label="delete target"
        component="label">
        <DeleteIcon />
      </IconButton>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Delete Target</DialogTitle>
        <Formik
          initialValues={{
            user_id: target.user_id || "",
            date_from: dayjs(target.date_from) || "",
            date_to: dayjs(target.date_to) || "",
            sales_target: target.sales_target || "",
            ci_target: target.ci_target || "",
            scr_target: target.scr_target || "",
            hours_target: target.hours_target || "",
            pending_target: target.pending_target || "",
            manager_id: target.manager_id || "",
          }}
          validationSchema={Yup.object({
            user_id: Yup.string().required("Required"),
            manager_id: Yup.string().required(
              "User does not have a manager Contact Admin to assign a manager"
            ),
            sales_target: Yup.number().required("Required"),
            ci_target: Yup.number().required("Required"),
            scr_target: Yup.number().required("Required"),
            hours_target: Yup.number().required("Required"),
            pending_target: Yup.number().required("Required"),
          })}
          onSubmit={(values) => {
            values.date_from = values.date_from.format("YYYY-MM-DD");
            values.date_to = values.date_to.format("YYYY-MM-DD");

            handleClose();
          }}>
          {({ errors }) => {
            return (
              <Form>
                <Stack sx={{ pt: 2, px: 3 }} spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <DateSelectorWrapper
                      disabled
                      name="date_from"
                      label="Date From"
                    />
                    <DateSelectorWrapper
                      disabled
                      name="date_to"
                      label="Date To"
                    />
                  </Stack>
                  <TextfieldWrapper
                    disabled
                    type="number"
                    name="sales_target"
                    label="Sales Target"
                  />
                  <TextfieldWrapper
                    disabled
                    type="number"
                    name="ci_target"
                    label="CI Target"
                  />
                  <TextfieldWrapper
                    disabled
                    type="number"
                    name="scr_target"
                    label="SCR Target"
                  />
                  <TextfieldWrapper
                    disabled
                    type="number"
                    name="hours_target"
                    label="Hours Target"
                  />
                  <TextfieldWrapper
                    disabled
                    name="pending_target"
                    label="Pending Target"
                  />
                </Stack>
              </Form>
            );
          }}
        </Formik>
        <>
          <Button
            onClick={() => {
              onDelete();
            }}
            color="error"
            variant="contained">
            Delete
          </Button>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </>
      </Dialog>
    </div>
  );
}
