import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

import DeleteClientContact from "./DeleteClientContact";
import EditClientContact from "./EditClientContact";

const ClientsContactList = ({ clientContacts }) => {
  console.log(clientContacts);

  return (
    <Table sx={{ my: 4 }}>
      <TableHead sx={{ bgcolor: "background.paper" }}>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Cell</TableCell>
          <TableCell>Cell 2</TableCell>
          <TableCell>landline </TableCell>
          <TableCell>landline 2</TableCell>
          <TableCell>Position</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {clientContacts &&
          clientContacts?.map((clientContact) => (
            <TableRow key={clientContact.id}>
              <TableCell>{clientContact?.contact_name}</TableCell>
              <TableCell>{clientContact?.contact_email}</TableCell>
              <TableCell>{clientContact?.cell_no1}</TableCell>
              <TableCell>{clientContact?.cell_no2}</TableCell>
              <TableCell>{clientContact?.landline_no}</TableCell>
              <TableCell>{clientContact?.landline_no2}</TableCell>
              <TableCell>{clientContact?.contact_position}</TableCell>
              <TableCell>
                <Stack spacing={1} direction="row">
                  <EditClientContact contact={clientContact} />
                  <DeleteClientContact contact={clientContact} />
                </Stack>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default ClientsContactList;
