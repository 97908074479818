import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { useDispatch } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { CreateQuestionForQuestionnaire } from "../State/QuestionnairsDuck";

export default function CreateQuestionForm({ questionnaireCampaign }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      campaign_id: questionnaireCampaign.id,
      company_id: questionnaireCampaign.company_id,
      question: "",
      question_type: "radio",
    },
    validationSchema: Yup.object({
      question: Yup.string().required("A Question is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      setTimeout(() => {
        resetForm();
        handleClose();
      }, 1000);

      dispatch(CreateQuestionForQuestionnaire(values));
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth variant="outlined" onClick={handleClickOpen}>
        Add Question
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <>
            <Stack sx={{ p: 4 }}>
              <Typography variant="h4" align="center" my={4}>
                Create Question
              </Typography>

              <TextField
                margin="normal"
                label="Question"
                name="question"
                multiline
                rows={4}
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.question && formik.errors.question
                    ? true
                    : false
                }
                helpertext={formik.touched.question && formik.errors.question}
              />
            </Stack>
            <Stack>
              <Button sx={{ mt: 4 }} type="submit" variant="contained">
                Submit
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </Stack>
          </>
        </form>
      </Dialog>
    </div>
  );
}
