import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  QA_QUESTIONS_CREATE_RESET,
  updateQaQuestion,
} from "./State/QaQuestionsDuck";
import { Button, Chip, LinearProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { DialogContent, Stack } from "@mui/material";
import * as Yup from "yup";
import SwitchWrapper from "../../../../../Components/FormComponents/SwitchWrapper";
import TextfieldWrapper from "../../../../../Components/FormComponents/TextfieldWrapper";
import SelectWrapper from "../../../../../Components/FormComponents/Select";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SliderWrapper from "../../../../../Components/FormComponents/SliderWrapper";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import EditAnswer from "./Components/QuestionsAnswers/EditAnswer";
import {
  DataGridPremium,
  GridToolbar,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-premium";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../../../../Hoc/withAuth";

const QaAllQuestions = ({ user }) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { token } = user;

  const getAllQaQuestions = useQuery(["AllQaQuestions"], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/qa/questions`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  });

  const AddQaAnswer = useMutation(
    ["addAnswer"],
    (values) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/qa/questions/${values.id}/answer`,
        values.answer,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("AllQaQuestions");
      },
    }
  );

  const createQaQuestionMutation = useMutation(
    ["createQaQuestion"],
    (values) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/qa/questions`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("AllQaQuestions");
      },
    }
  );

  useEffect(() => {
    dispatch({ type: QA_QUESTIONS_CREATE_RESET });
  }, [dispatch]);

  const qaQuestionsAll = useSelector((state) => state.qaQuestionsAll);

  const {
    createQuestionSuccess,
    addAnswerSuccess,
    error,
    editSuccess,
    loading,
  } = qaQuestionsAll;

  const columns = [
    {
      field: "question",
      headerName: "Question",
      width: 600,
    },
    {
      field: "group",
      headerName: "Group",
      width: 150,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 150,
      renderCell: (params) => <EditButton row={params.row} />,
    },

    {
      field: "answers",
      headerName: "Answers",
      width: 150,
      renderCell: (params) => {
        params.row.qa_answers &&
          params.row.qa_answers.length > 0 &&
          params.row.qa_answers.map((answer) => {
            return (
              <>
                <div>{answer.answer}</div>
                <div>{answer.value}</div>
              </>
            );
          });
      },
    },
  ];

  const getDetailPanelContent = React.useCallback(
    ({ row }) => {
      return (
        <Stack
          sx={{
            p: 2,
            height: "100%",
            boxSizing: "border-box",
            position: "sticky",
            left: 0,
          }}
          direction="column">
          <AddAnswerToQuestion question={row} AddQaAnswer={AddQaAnswer} />
          <Table
            sx={{ p: 1, backgroundColor: "background.paper" }}
            size="small"
            aria-label="purchases">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Is Active</StyledTableCell>
                <StyledTableCell>Value</StyledTableCell>
                <StyledTableCell align="right">Remove</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row?.qa_answers &&
                row.qa_answers.length > 0 &&
                row.qa_answers.map((detail) => (
                  <TableRow key={detail?.id}>
                    <StyledTableCell component="th" scope="row">
                      {detail?.answer}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Chip
                        label={detail?.active ? "Active" : "Disabled"}
                        variant="outlined"
                        color={detail?.active ? "default" : "warning"}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {detail.value * 10}
                    </StyledTableCell>

                    <StyledTableCell align="right" component="th" scope="row">
                      <EditAnswer answer={detail} />
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Stack>
      );
    },
    [AddQaAnswer]
  );

  function exceljsPreProcess({ workbook, worksheet }) {
    workbook.created = new Date(); // Add metadata
    worksheet.name = "Monthly Results"; // Modify worksheet name

    // Write on first line the date of creation
    worksheet.getCell("A1").value = `Values from the`;
    worksheet.getCell("A2").value = new Date();
  }

  function exceljsPostProcess({ worksheet }) {
    // Add a text after the data
    worksheet.addRow(); // Add empty row

    const newRow = worksheet.addRow();
    newRow.getCell(1).value = "Those data are for internal use only";
  }

  const excelOptions = {
    exceljsPreProcess,
    exceljsPostProcess,
  };

  return (
    <div>
      <Stack mb={2}>
        <AddNewQuestion createQaQuestionMutation={createQaQuestionMutation} />
      </Stack>

      <AlertPopup
        open={createQuestionSuccess}
        message="Question Created Successfully"
      />
      <AlertPopup
        open={addAnswerSuccess}
        message="
        Answer Added Successfully"
      />
      <AlertPopup
        open={error}
        severity="error"
        message={JSON.stringify(error)}
      />
      <AlertPopup
        open={editSuccess}
        severity="success"
        message={"Question Updated Successfully"}
      />

      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {getAllQaQuestions?.data?.data?.data &&
            getAllQaQuestions?.data?.data?.data.length > 0 && (
              <DataGridPremium
                slots={{ toolbar: GridToolbar }}
                slotProps={{ toolbar: { excelOptions } }}
                autoHeight
                pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                getDetailPanelHeight={() => "auto"}
                getDetailPanelContent={getDetailPanelContent}
                getRowId={(row) => row.id}
                rows={getAllQaQuestions?.data?.data?.data}
                columns={columns}
              />
            )}
        </>
      )}
    </div>
  );
};

export default withAuth(QaAllQuestions);

const EditButton = ({ row }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>Edit</Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Edit QA Queue Question</DialogTitle>

        <Formik
          initialValues={{
            id: row.id,
            question: row.question,
            group: row.group,
            auto_fail: row.auto_fail,
            call_back_request: row.call_back_request,
            compliance: row.compliance,
            product_knowledge: row.product_knowledge,
            professionalism: row.professionalism,
            sales_skills: row.sales_skills,
            tcf: row.tcf,
            updated_reason: "Update Question",
          }}
          validationSchema={Yup.object({
            question: Yup.string().required("Question is required"),
            group: Yup.string().required("Group is required"),
          })}
          onSubmit={(values) => {
            dispatch(updateQaQuestion(values));
            setTimeout(() => {
              handleClose();
            }, 1000);
          }}>
          {() => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <TextfieldWrapper
                    name="question"
                    label="Question"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                  />
                  <SelectWrapper
                    name="group"
                    label="Group"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                    options={[
                      { value: "Call", label: "Call" },
                      { value: "Client Info", label: "Client Info" },
                      { value: "Confirmation", label: "Confirmation" },
                      { value: "Opinion", label: "Opinion" },
                      { value: "Amendment", label: "Amendment" },
                      { value: "Query", label: "Query" },
                      { value: "Quote", label: "Quote" },
                    ]}
                  />

                  <SwitchWrapper name="auto_fail" label="Auto Fail" />
                  <SwitchWrapper
                    name="call_back_request"
                    label="Call Back Request"
                  />
                  <SwitchWrapper name="compliance" label="Compliance" />
                  <SwitchWrapper
                    name="product_knowledge"
                    label="Product Knowledge"
                  />
                  <SwitchWrapper
                    name="professionalism"
                    label="Professionalism"
                  />
                  <SwitchWrapper name="sales_skills" label="Sales Skills" />
                  <SwitchWrapper name="tcf" label="tcf Skills" />
                </Stack>
              </DialogContent>
              <Button fullWidth variant="contained" type="submit">
                Save
              </Button>
              <Button fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const AddNewQuestion = ({ createQaQuestionMutation }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Add New Question
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Create New Question</DialogTitle>

        <Formik
          initialValues={{
            question: "",
            group: "",
            auto_fail: false,
            call_back_request: false,
            compliance: false,
            product_knowledge: false,
            professionalism: false,
            sales_skills: false,
            tcf: false,
            updated_reason: "Created New Question",
          }}
          validationSchema={Yup.object({})}
          onSubmit={(values) => {
            createQaQuestionMutation.mutate({ values });

            setTimeout(() => {
              handleClose();
            }, 1000);
          }}>
          {() => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <TextfieldWrapper
                    name="question"
                    label="Question"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                  />
                  <SelectWrapper
                    name="group"
                    label="Group"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    required
                    options={[
                      { value: "Call", label: "Call" },
                      { value: "Client Info", label: "Client Info" },
                      { value: "Confirmation", label: "Confirmation" },
                      { value: "Opinion", label: "Opinion" },
                      { value: "Amendment", label: "Amendment" },
                      { value: "Query", label: "Query" },
                      { value: "Quote", label: "Quote" },
                    ]}
                  />

                  <SwitchWrapper name="auto_fail" label="Auto Fail" />
                  <SwitchWrapper
                    name="call_back_request"
                    label="Call Back Request"
                  />
                  <SwitchWrapper name="compliance" label="Compliance" />
                  <SwitchWrapper
                    name="product_knowledge"
                    label="Product Knowledge"
                  />
                  <SwitchWrapper
                    name="professionalism"
                    label="Professionalism"
                  />
                  <SwitchWrapper name="sales_skills" label="Sales Skills" />
                  <SwitchWrapper name="tcf" label="tcf Skills" />
                </Stack>
              </DialogContent>
              <Button variant="contained" fullWidth type="submit">
                Create
              </Button>
              <Button fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

const AddAnswerToQuestion = ({ question, AddQaAnswer }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        sx={{ mb: 1 }}
        variant="outlined"
        color="secondary"
        onClick={handleClickOpen}>
        Add New Answer
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Create New Question Answer</DialogTitle>

        <Formik
          initialValues={{
            question_id: question?.id,
            answer_type: "",
            answer: "",
            value: 0,
            active: true,
            updated_reason: "Created New Answer",
          }}
          validationSchema={Yup.object({
            answer_type: Yup.string().required("Required"),
            answer: Yup.string().required("Required"),
          })}
          onSubmit={(values) => {
            values.value = values?.value / 10;
            AddQaAnswer.mutate(values);
            handleClose();
          }}>
          {({ values }) => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <TextfieldWrapper
                    name="answer"
                    label="answer"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  />
                  <SliderWrapper
                    inputs={{
                      min: 0,
                      max: 10,
                    }}
                    name="value"
                    label="Value"
                    min={0}
                    max={1}
                  />
                  <SelectWrapper
                    name="answer_type"
                    label="answer_type"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    options={[
                      { value: "standard", label: "standard" },
                      { value: "rating", label: "rating" },
                    ]}
                  />
                </Stack>
              </DialogContent>
              <Button variant="contained" fullWidth type="submit">
                Save
              </Button>
              <Button fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
