import React, { useState } from 'react';
import { Stack, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';

const InboundSetToDone = ({ user, updateEvaluation, evaluation }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const newHistoryEntry = {
      status: "Done",
      createdBy: user.email,
      date: new Date().toISOString(),
      start_time: evaluation?.start_time,
      end_time: evaluation?.end_time,
    };

    const updatedHistory = [...(evaluation.history || []), newHistoryEntry];

    const data = {
      status: "Done",
      history: updatedHistory,
    };
    updateEvaluation.mutate(data);
    handleClose();
  };

  const isDisabled = evaluation.status !== "Failed";

  return (
    <Stack>
      <Button
        variant="outlined"
        color="secondary"
        sx={{ height: "50px" }}
        onClick={handleClickOpen}
        disabled={isDisabled}
      >
        Set Evaluation to Done
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure you want to set this Evaluation to Done?"}</DialogTitle>
        <DialogContent>
          <Stack
            direction="row"
            spacing={1}
            sx={{ mt: 1 }}
            justifyContent="flex-end"
          >
            <Button onClick={handleClose}>Disagree</Button>
            <Button sx={{ mt: 1 }} color="secondary" onClick={handleSubmit}>
              Agree
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default InboundSetToDone;
