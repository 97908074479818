import axios from "axios";

const initialState = {
  campaignResults: [],
  campaignResult: {},
  loading: false,
  error: null,
};

const GET_CAMPAIGN_RESULTS = "GET_CAMPAIGN_RESULTS";
const GET_CAMPAIGN_RESULTS_SUCCESS = "GET_CAMPAIGN_RESULTS_SUCCESS";
const GET_CAMPAIGN_RESULTS_FAILURE = "GET_CAMPAIGN_RESULTS_FAILURE";

const GET_CAMPAIGN_RESULT_DETAILS = "GET_CAMPAIGN_RESULT_DETAILS";
const GET_CAMPAIGN_RESULT_DETAILS_SUCCESS =
  "GET_CAMPAIGN_RESULT_DETAILS_SUCCESS";
const GET_CAMPAIGN_RESULT_DETAILS_FAILURE =
  "GET_CAMPAIGN_RESULT_DETAILS_FAILURE";

const GET_CAMPAIGN_RESULT = "GET_CAMPAIGN_RESULT";
const GET_CAMPAIGN_RESULT_SUCCESS = "GET_CAMPAIGN_RESULT_SUCCESS";
const GET_CAMPAIGN_RESULT_FAILURE = "GET_CAMPAIGN_RESULT_FAILURE";

export const RESET_QUESTIONNAIRE_RESULT_DUCK =
  "RESET_QUESTIONNAIRE_RESULT_DUCK";

const QuestionnaireResultsDuck = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_RESULTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CAMPAIGN_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignResults: action.payload,
      };
    case GET_CAMPAIGN_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_CAMPAIGN_RESULT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CAMPAIGN_RESULT_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignResult: action.payload,
      };
    case GET_CAMPAIGN_RESULT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_CAMPAIGN_RESULT_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CAMPAIGN_RESULT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignResult: action.payload,
      };
    case GET_CAMPAIGN_RESULT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_QUESTIONNAIRE_RESULT_DUCK:
      return {
        campaignResults: [],
        campaignResult: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default QuestionnaireResultsDuck;

export const getQuestionnaireCampaignResults = (campaignId) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_CAMPAIGN_RESULTS });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/questionnaireResults/${campaignId}`,
        config
      );

      dispatch({ type: GET_CAMPAIGN_RESULTS_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({
        type: GET_CAMPAIGN_RESULTS_FAILURE,
        payload: error.response.data.message,
      });
    }
  };
};

export const getQuestionnaireCampaignResult = (campaignId) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_CAMPAIGN_RESULT });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/questionnaireResults/end/${campaignId}`,
        config
      );

      dispatch({ type: GET_CAMPAIGN_RESULT_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({
        type: GET_CAMPAIGN_RESULT_FAILURE,
        payload: error.response.data.message,
      });
    }
  };
};

export const getQuestionnaireDetails = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_CAMPAIGN_RESULT_DETAILS });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/questionnaireResults/details/${id}`,
        config
      );

      dispatch({
        type: GET_CAMPAIGN_RESULT_DETAILS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CAMPAIGN_RESULT_DETAILS_FAILURE,
        payload: error.response.data.message,
      });
    }
  };
};
