import React, { useEffect } from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTeamById, getTeamUsers } from "./State/TeamsDuck";
import withAuth from "../../../../../Hoc/withAuth";
import AddUsersToTeamDialog from "./Components/Teams/AddUsersToTeamDialog";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import RemoveUserFromTeam from "./Components/Teams/RemoveUserFromTeam";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import { getUsersOfModule } from "../../../../../Redux/Ducks/AdminUserManagement";
import { DataGridPremium } from "@mui/x-data-grid-premium";
// import { getTeamsList } from "../../../StateManager/Campaigns/TeamsDuck";

const TeamsEdit = ({ companyDetails }) => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamById(id));
    dispatch(getTeamUsers(id));
  }, [dispatch, id, companyDetails]);

  useEffect(() => {
    dispatch(getUsersOfModule("call-center-user"));
  }, [dispatch]);

  const AdminUsers = useSelector((state) => state.AdminUsers);
  const { usersOfModule } = AdminUsers;

  const CallCenterUsers =
    usersOfModule &&
    usersOfModule[0]?.Modules_users.map((item) => {
      return {
        id: item?.user_id,
        name: item?.User?.name,
        surname: item?.User?.surname,
        email: item?.User?.email,
      };
    });

  const Teams = useSelector((state) => state.Teams);
  const { teamUsers, team, removeUserFromTeamSuccess, addUserToTeamSuccess } =
    Teams;

  // filterOut the users that are already in the team
  const filteredUsers =
    usersOfModule &&
    CallCenterUsers?.filter((user) => {
      return !teamUsers?.some((teamUser) => teamUser.user_id === user.id);
    });

  return (
    <div>
      <>
        <PageHeader
          title={`Team ${team.name}`}
          subheader="EDIT TEAM"
          breadcrumbs={[
            {
              title: "Dashboard",
              href: "/",
            },
            {
              title: "Team Management",
              href: "/CDA/callCenter/teams",
            },
            {
              title: "Edit Teams",
              href: `/CDA/TeamsEdit/${team.id}`,
            },
          ]}
        />

        <Card sx={{ mb: 2 }} variant="outlined">
          <CardContent>
            <Stack>
              <Typography color="GrayText" variant="caption">
                Users needs to have the "call center user" module assigned to be
                able to be added to a team.
              </Typography>
              <Typography color="GrayText" variant="caption">
                If you cannot find the user you are looking for, please contact
                your administrator to assign the "call center user" module to
                the user.
              </Typography>
            </Stack>
          </CardContent>
        </Card>

        <Stack sx={{ mb: 4 }}>
          <AddUsersToTeamDialog users={filteredUsers} teamId={id} />
        </Stack>

        <DataGridPremium
          // getRowId={(row) => row.email}
          rows={teamUsers}
          columns={[
            { field: "id", headerName: "ID", width: 70 },
            {
              field: "name",
              headerName: "Name",
              width: 130,

              valueGetter: (params) => {
                return params.row?.User?.name;
              },
            },
            {
              field: "surname",
              headerName: "Surname",
              width: 130,
              valueGetter: (params) => {
                return params.row?.User?.surname;
              },
            },
            {
              field: "email",
              headerName: "Email",
              width: 230,
              valueGetter: (params) => {
                return params.row?.User?.email;
              },
            },
            {
              field: "remove",
              headerName: "Remove",
              width: 230,
              renderCell: (params) => (
                <RemoveUserFromTeam user={params.row} teamId={id} />
              ),
            },
          ]}
        />

        <AlertPopup
          open={removeUserFromTeamSuccess}
          severity="info"
          message="User Removed"
        />
        <AlertPopup
          open={addUserToTeamSuccess}
          severity="success"
          message="User Added"
        />
      </>
    </div>
  );
};

export default withAuth(TeamsEdit);
