import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import PageHeader from "../../../../../../../Components/Bits/PageHeader";
import withAuth from "../../../../../../../Hoc/withAuth";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

const EvaluationStats = ({ user }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const { data: CampaignData = [] } = useQuery(
    ["getAllEvaluations"],
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/user/manager`,
        config
      );
      return response?.data?.data || [];
    },
    {
      onError: (error) => {
        console.error("Error fetching evaluations:", error);
      },
    }
  );

  const Stats = CampaignData.map((campaign) => {
    const statusCounts = {
      new: 0,
      busy: 0,
      completed: 0,
      failed: 0,
      reEvaluation: 0,
      disputed: 0,
    };

    campaign.campaign?.forEach((evaluation) => {
      switch (evaluation.status?.toLowerCase()) {
        case "new":
          statusCounts.new++;
          break;
        case "busy":
          statusCounts.busy++;
          break;
        case "completed":
          statusCounts.completed++;
          break;
        case "failed":
        case "done":
          statusCounts.failed++;
          break;
        case "re-evaluation requested":
          statusCounts.reEvaluation++;
          break;
        case "dispute requested":
          statusCounts.disputed++;
          break;
        default:
          break;
      }
    });

    const totalEvaluated =
      statusCounts.completed +
      statusCounts.failed +
      statusCounts.reEvaluation +
      statusCounts.disputed +
      statusCounts.new +
      statusCounts.busy;

    const passRate =
      totalEvaluated > 0
        ? ((statusCounts.completed / totalEvaluated) * 100).toFixed(2)
        : 0;

    const failRate =
      totalEvaluated > 0
        ? ((statusCounts.failed / totalEvaluated) * 100).toFixed(2)
        : 0;

    return {
      id: campaign.id,
      name: campaign.name,
      createdAt: new Date(campaign.createdAt).toLocaleDateString(),
      updatedAt: new Date(campaign.updatedAt).toLocaleDateString(),
      new: statusCounts.new,
      busy: statusCounts.busy,
      completed: statusCounts.completed,
      failed: statusCounts.failed,
      reEvaluations: statusCounts.reEvaluation,
      disputed: statusCounts.disputed,
      totalevals: totalEvaluated,
      passRate: Number(passRate),
      failRate: Number(failRate),
    };
  });

  const columns = [
    {
      field: "name",
      headerName: "Campaign name",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "From",
      width: 120,
    },
    {
      field: "updatedAt",
      headerName: "To",
      width: 120,
    },
    {
      field: "totalevals",
      headerName: "Total Evaluations",
      width: 120,
    },
    {
      field: "new",
      headerName: "New",
      width: 120,
    },
    {
      field: "busy",
      headerName: "Busy",
      width: 120,
    },
    {
      field: "completed",
      headerName: "Completed",
      width: 120,
    },
    {
      field: "failed",
      headerName: "Failed",
      width: 120,
    },
    {
      field: "reEvaluations",
      headerName: "Re-evaluations ",
      width: 120,
    },
    {
      field: "disputed",
      headerName: "Disputes",
      width: 120,
    },
    {
      field: "passRate",
      headerName: "Pass Rate(%)",
      width: 150,

      valueFormatter: (params) => {
        return `${params.value}%`;
      },
    },
    {
      field: "failRate",
      headerName: "Fail Rate(%)",
      width: 150,

      valueFormatter: (params) => {
        return `${params.value}%`;
      },
    },
  ];

  return (
    <Stack>
      <PageHeader
        title="QA Agent Results"
        subheader="View Qa Agent results tracking"
      />
      <DataGridPremium
        slots={{
          toolbar: GridToolbar,
        }}
        getRowId={(row) => row.id}
        rows={Stats}
        columns={columns}
      />
    </Stack>
  );
};

export default withAuth(EvaluationStats);
