import axios from "axios";

const campaignQueuesInitial = {
  campaignQueuesList: [],
  campaignQueuesLoading: false,
  campaignQueuesError: null,
  campaignQueuesSuccess: null,
};

const GET_CAMPAIGN_QA_QUEUES = "GET_CAMPAIGN_QA_QUEUES";
const GET_CAMPAIGN_QA_QUEUES_SUCCESS = "GET_CAMPAIGN_QA_QUEUES_SUCCESS";
const GET_CAMPAIGN_QA_QUEUES_ERROR = "GET_CAMPAIGN_QA_QUEUES_ERROR";

const EDIT_CAMPAIGN_QA_QUEUE = "EDIT_CAMPAIGN_QA_QUEUE";
const EDIT_CAMPAIGN_QA_QUEUE_SUCCESS = "EDIT_CAMPAIGN_QA_QUEUE_SUCCESS";
const EDIT_CAMPAIGN_QA_QUEUE_ERROR = "EDIT_CAMPAIGN_QA_QUEUE_ERROR";

const ADD_CAMPAIGN_QA_QUEUE = "ADD_CAMPAIGN_QA_QUEUE";
const ADD_CAMPAIGN_QA_QUEUE_SUCCESS = "ADD_CAMPAIGN_QA_QUEUE_SUCCESS";
const ADD_CAMPAIGN_QA_QUEUE_ERROR = "ADD_CAMPAIGN_QA_QUEUE_ERROR";

const DELETE_CAMPAIGN_QA_QUEUE = "DELETE_CAMPAIGN_QA_QUEUE";
const DELETE_CAMPAIGN_QA_QUEUE_SUCCESS = "DELETE_CAMPAIGN_QA_QUEUE_SUCCESS";
const DELETE_CAMPAIGN_QA_QUEUE_ERROR = "DELETE_CAMPAIGN_QA_QUEUE_ERROR";

export const RESET_CAMPAIGN_QA_QUEUES = "RESET_CAMPAIGN_QA_QUEUES";

const campaignQueuesReducer = (state = campaignQueuesInitial, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_QA_QUEUES:
      return {
        ...state,
        campaignQueuesLoading: true,
        campaignQueuesError: null,
        campaignQueuesSuccess: null,
      };
    case GET_CAMPAIGN_QA_QUEUES_SUCCESS:
      return {
        ...state,
        campaignQueuesList: action.payload,
        campaignQueuesLoading: false,
        campaignQueuesError: null,
      };
    case GET_CAMPAIGN_QA_QUEUES_ERROR:
      return {
        ...state,
        campaignQueuesLoading: false,
        campaignQueuesError: action.campaignQueuesError,
        campaignQueuesSuccess: null,
      };
    case EDIT_CAMPAIGN_QA_QUEUE:
      return {
        ...state,
        campaignQueuesLoading: true,
        campaignQueuesError: null,
        campaignQueuesSuccess: null,
      };
    case EDIT_CAMPAIGN_QA_QUEUE_SUCCESS:
      return {
        ...state,
        campaignQueuesList: [
          ...state.campaignQueuesList.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          }),
        ],

        campaignQueuesLoading: false,
        campaignQueuesError: null,
        campaignQueuesSuccess: true,
      };
    case EDIT_CAMPAIGN_QA_QUEUE_ERROR:
      return {
        ...state,
        campaignQueuesLoading: false,
        campaignQueuesError: action.campaignQueuesError,
        campaignQueuesSuccess: null,
      };
    case ADD_CAMPAIGN_QA_QUEUE:
      return {
        ...state,
        campaignQueuesLoading: true,
        campaignQueuesError: null,
        campaignQueuesSuccess: null,
      };
    case ADD_CAMPAIGN_QA_QUEUE_SUCCESS:
      return {
        ...state,
        campaignQueuesList: [...state.campaignQueuesList, action.payload],
        campaignQueuesLoading: false,
        campaignQueuesError: null,
        campaignQueuesSuccess: true,
      };
    case ADD_CAMPAIGN_QA_QUEUE_ERROR:
      return {
        ...state,
        campaignQueuesLoading: false,
        campaignQueuesError: action.campaignQueuesError,
        campaignQueuesSuccess: null,
      };
    case DELETE_CAMPAIGN_QA_QUEUE:
      return {
        ...state,
        campaignQueuesLoading: true,
        campaignQueuesError: null,
        campaignQueuesSuccess: null,
      };
    case DELETE_CAMPAIGN_QA_QUEUE_SUCCESS:
      return {
        ...state,
        campaignQueuesLoading: false,
        campaignQueuesError: null,
        campaignQueuesSuccess: true,
      };
    case DELETE_CAMPAIGN_QA_QUEUE_ERROR:
      return {
        ...state,
        campaignQueuesLoading: false,
        campaignQueuesError: action.campaignQueuesError,
        campaignQueuesSuccess: null,
      };
    case RESET_CAMPAIGN_QA_QUEUES:
      return {
        ...state,
        campaignQueuesLoading: false,
        campaignQueuesError: null,
        campaignQueuesSuccess: null,
      };

    default:
      return state;
  }
};

export default campaignQueuesReducer;

export const getQaCampaignQueues =
  ({ id }) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_CAMPAIGN_QA_QUEUES,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/queues`,

        config
      );
      dispatch({
        type: GET_CAMPAIGN_QA_QUEUES_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_CAMPAIGN_QA_QUEUES_ERROR,
        payload: err.response,
      });
    }
  };

export const editQaCampaignQueue = (values) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_CAMPAIGN_QA_QUEUE,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${values.campaign_id}/queues/${values.queue_id}`,
      { active: values.active },
      config
    );
    dispatch({
      type: EDIT_CAMPAIGN_QA_QUEUE_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_CAMPAIGN_QA_QUEUE_ERROR,
      payload: err.response,
    });
  }
};

export const addQaCampaignQueue =
  ({ id, campaignQueue }) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADD_CAMPAIGN_QA_QUEUE,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/queues`,
        campaignQueue,
        config
      );
      dispatch({
        type: ADD_CAMPAIGN_QA_QUEUE_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: ADD_CAMPAIGN_QA_QUEUE_ERROR,
        payload: err.response,
      });
    }
  };

export const deleteQaCampaignQueue =
  ({ id }) =>
  async (dispatch, getState) => {
    dispatch({
      type: DELETE_CAMPAIGN_QA_QUEUE,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/callCentre/qaQueues/${id}`,
        config
      );
      dispatch({
        type: DELETE_CAMPAIGN_QA_QUEUE_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: DELETE_CAMPAIGN_QA_QUEUE_ERROR,
        payload: err.response,
      });
    }
  };
