import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MainAppContainer from "../Containers/MainAppContainer";
import { useSelector } from "react-redux";
import withAuth from "../../Hoc/withAuth";
import { useNavigate } from "react-router-dom";
import { SignOutButton } from "../../Pages/Auth/SignOutButton";
import NodeIcon from "../../assets/LogoIcon2.png";
import { Link, Stack, Tooltip } from "@mui/material";
import NotificationsPanel from "../Notifications/NotificationsPanel";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import WbSunnyTwoToneIcon from "@mui/icons-material/WbSunnyTwoTone";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import DrawerMain from "./Drawer/DrawerMain";
import PatchNotesModal from "../PatchNotes/PatchNotesModal";

const MainAppBar = ({
  children,

  modules,
  companyDetails,
  setTheme,
  currentTheme,
}) => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        sx={{ border: "none", borderRadius: 0 }}
        position="fixed"
        color="default"
        open={open}>
        <Toolbar>
          {userInfo?.token && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                ...(open && { display: "none" }),
              }}>
              <MenuIcon />
            </IconButton>
          )}

          {userInfo?.token && (
            <>
              <Link to="/">
                <IconButton>
                  <img height="40" width="40" alt="Node Icon" src={NodeIcon} />
                </IconButton>
              </Link>

              <Typography variant="h6" noWrap>
                Node
              </Typography>
            </>
          )}

          <Box sx={{ mx: "auto" }}></Box>

          <Stack direction="row" spacing={2}>
            <Tooltip title="Set Theme">
              {currentTheme === false ? (
                <IconButton
                  sx={{ color: "text.primary" }}
                  onClick={() => setTheme(!currentTheme)}>
                  <DarkModeIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="warning"
                  onClick={() => setTheme(!currentTheme)}>
                  <WbSunnyTwoToneIcon />
                </IconButton>
              )}
            </Tooltip>

            {userInfo?.token && (
              <>
                <PatchNotesModal />
                <NotificationsPanel />
                <Tooltip title="Profile">
                  <IconButton
                    color="inherit"
                    onClick={() => navigate("/profile")}>
                    <PersonOutlineTwoToneIcon />
                  </IconButton>
                </Tooltip>

                <SignOutButton />
              </>
            )}
          </Stack>
        </Toolbar>
      </AppBar>

      {userInfo?.token && (
        <DrawerMain
          open={open}
          handleDrawerClose={handleDrawerClose}
          theme={theme}
          setOpen={setOpen}
        />
      )}

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <MainAppContainer>{children}</MainAppContainer>
      </Box>
    </Box>
  );
};

export default withAuth(MainAppBar);

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    width: `calc(100% - ${60}px)`,

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
