import { Button, Chip, Container, Stack } from "@mui/material";
import React from "react";
import withAuth from "../../../../../Hoc/withAuth";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAgentEvaluations } from "./State/AgentEvaluationsDuck";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { StageColors } from "./Components/StageColors";
import CreateEvaluationsFromLeadSale from "../QAManagement/Components/Evaluations/CreateEvaluationsFromLeadSale";
import dayjs from "dayjs";

const QaAgentHome = ({ user }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAgentEvaluations({ id: user.id }));
  }, [user, dispatch]);

  const AgentEvaluations = useSelector(
    (state) => state.QAAgent.AgentEvaluations,
  );

  const { evaluations } = AgentEvaluations;

  const [completedEvaluations, setCompletedEvals] = React.useState([]);
  const [otherEvaluations, setOtherEvals] = React.useState([]);

  useEffect(() => {
    if (evaluations) {
      const completedEvals = [];
      const otherEvals = [];
      const priorityEvals = [];
      // set limit on otherEvals
      const limit = 15;

      evaluations?.forEach((evaluation) => {
        if (
          [
            "Complete",
            "Fail",
            "Dispute Rejected",
            "Technically Unverifiable",
          ].includes(evaluation.stage)
        ) {
          completedEvals.push(evaluation);
        } else if (["Dispute", "Rejected"].includes(evaluation.stage)) {
          priorityEvals.push(evaluation);
        }
        // ignore cancelled and bypassed evaluations
        else if (!["Cancelled", "Bypass"].includes(evaluation.stage)) {
          otherEvals.push(evaluation);
        }
      });

      // sort by q_priority desc and then by createdAt asc
      otherEvals.sort((a, b) => {
        if (a.q_priority === b.q_priority) {
          return dayjs(a.createdAt) - dayjs(b.createdAt);
        }
        return b.q_priority - a.q_priority;
      });
      // console.log("otherEvals", otherEvals);
      // limit the otherEvals
      // setOtherEvals(otherEvals.slice(0, limit));

      // combine priorityEvals and otherEvals
      setOtherEvals([...priorityEvals, ...otherEvals.slice(0, limit)]);

      setCompletedEvals(completedEvals);
    }
  }, [evaluations]);

  const [viewCompleted, setViewCompleted] = React.useState(false);

  const columns = [
    {
      field: "View",
      headerName: "View",
      width: 130,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              navigate(
                `/CDA/StartEvaluation/${params.row.id}/${params.row.uni_id}`,
              );
            }}
          >
            View
          </Button>
        );
      },
    },
    { field: "uni_id", headerName: "Uni ID", width: 100 },
    { field: "note1", headerName: "Note 1", width: 100 },
    {
      field: "campaignname",
      headerName: "QA Campaign Name",
      width: 250,
      valueGetter: (params) => {
        return params.row.qa_queue?.queue_name;
      },
    },

    {
      field: "stage",
      headerName: "Stage",
      width: 100,
      renderCell: (params) => {
        return (
          <Chip
            color={StageColors(params.row.stage)}
            label={params.row.stage}
          />
        );
      },
    },
    {
      field: "CallbiResults",
      headerName: "Callbi Results",
      width: 150,
      renderCell: (params) => {
        return (
          <Chip
            color={StageColors(params.row?.callbi_result?.status)}
            label={params.row?.callbi_result?.status}
          />
        );
      },
    },

    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 200,

      valueGetter: (params) => {
        return dayjs(params.row.updatedAt).format("DD-MMM-YYYY HH:mm");
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      valueGetter: (params) => {
        return dayjs(params.row.createdAt).format("DD-MMM-YYYY HH:mm");
      },
    },
  ];

  return (
    <Container maxWidth="xl">
      <PageHeader
        title="Agent Evaluations"
        subheader="List of Evaluations"
        breadcrumbs={[
          { title: "Home", href: "/" },
          { title: "Qa Agent Home", href: "/CDA/QaAgentHome" },
        ]}
      />
      <Stack
        sx={{ mb: 2 }}
        direction="row"
        spacing={2}
        justifyContent="space-between"
      >
        <CreateEvaluationsFromLeadSale />

        <Button
          startIcon={<QueryStatsIcon />}
          onClick={() => {
            navigate("/CDA/AgentResults");
          }}
        >
          View Stats
        </Button>
      </Stack>
      <Stack sx={{ height: "100vh" }}>
        <DataGridPremium
          slots={{
            toolbar: GridToolbar,
          }}
          rows={otherEvaluations}
          columns={columns}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "updatedAt", sort: "asc" }],
            },
          }}
        />
      </Stack>
      <Stack>
        <Button
          onClick={() => {
            setViewCompleted(!viewCompleted);
          }}
        >
          View completed Evaluations
        </Button>
      </Stack>
      {viewCompleted && (
        <Stack sx={{ height: "100vh" }}>
          <DataGridPremium
            rows={completedEvaluations}
            columns={columns}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Stack>
      )}
    </Container>
  );
};

export default withAuth(QaAgentHome);
