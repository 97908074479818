import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../../../Components/Bits/PageHeader";

import { getClientById } from "./State/ClientsDuck";

const ViewClient = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientById(id));
  }, [id, dispatch]);

  const Clients = useSelector((state) => state.Clients);

  const { client, loading } = Clients;

  if (loading) {
    return (
      <Stack justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <div>
      <PageHeader
        title={client?.name}
        subheader="View Client"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Manage Call Center",
            href: "/CDA/callCenter/clients",
          },
          {
            title: "Manage Client",
            href: `/CDA/ViewClient/${client?.id}`,
          },
        ]}
      />
      <Stack sx={{ mb: 3 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate("/CDA/CampaignManagement/new")}>
          Create Campaign
        </Button>
      </Stack>

      <List list={client?.cc_campaigns} />
    </div>
  );
};

export default ViewClient;

const List = ({ list }) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Campaign Name</StyledTableCell>

            <StyledTableCell>Campaign type</StyledTableCell>
            <StyledTableCell align="right">Edit</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list &&
            list.length > 0 &&
            list?.map((row) => {
              return (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>

                  <StyledTableCell>{row.campaign_type}</StyledTableCell>
                  <StyledTableCell align="right">
                    <Button
                      onClick={() => {
                        navigate(`/CDA/ManageCampaign/${row.id}/edit/Campaign`);
                      }}
                      color="warning"
                      size="small">
                      Edit
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
