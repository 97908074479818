import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchTeamMembers = (campaignId) =>
  axios.get(`${API_URL}/QAInbound/admin/teammembers/${campaignId}/team`);

export const updateTeamMemberStatus = (campaignId, userId, active) =>
  axios.put(`${API_URL}/QAInbound/admin/teammembers/${campaignId}/team/${userId}`, { active });

export const addTeamMember = (campaignId, data) =>
  axios.post(`${API_URL}/QAInbound/admin/teammembers/${campaignId}/team`, data);

export const fetchDeactivatedTeamMembers = (campaignId) =>
  axios.get(`${API_URL}/QAInbound/admin/teammembers/${campaignId}/team/deactivated`);

