import axios from "axios";

const initialState = {
  isLoading: null,
  message: null,
  absents: [],
  error: {},
};

export const RESET_ABSENT_STATE = "RESET_ABSENT_STATE";

const GET_ALL_ABSENTS_REQUEST = "GET_ALL_ABSENTS_REQUEST";
const GET_ALL_ABSENTS_SUCCESS = "GET_ALL_ABSENTS_SUCCESS";
const GET_ALL_ABSENTS_FAIL = "GET_ALL_ABSENTS_FAIL";

const ADD_ABSENT_REQUEST = "ADD_ABSENT_REQUEST";
const ADD_ABSENT_SUCCESS = "ADD_ABSENT_SUCCESS";
const ADD_ABSENT_FAIL = "ADD_ABSENT_FAIL";

const EDIT_ABSENT_REQUEST = "EDIT_ABSENT_REQUEST";
const EDIT_ABSENT_SUCCESS = "EDIT_ABSENT_SUCCESS";
const EDIT_ABSENT_FAIL = "EDIT_ABSENT_FAIL";

const absentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ABSENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: null,
        error: {
          addAbsent: null,
        },
      };
    case ADD_ABSENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: "Absent captured",
        absents: [...state.absents, action.payload.absent],
        error: {
          addAbsent: false,
        },
      };
    case ADD_ABSENT_FAIL:
      return {
        ...state,
        isLoading: false,
        message: "Error capturing absent",
        error: {
          addAbsent: true,
        },
      };
    case EDIT_ABSENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: null,
        error: {
          editAbsent: null,
        },
      };
    case EDIT_ABSENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: "Absent editted",
        error: {
          editAbsent: false,
        },
      };
    case EDIT_ABSENT_FAIL:
      return {
        ...state,
        isLoading: false,
        message: "Error editting absent",
        error: {
          editAbsent: true,
        },
      };
    case GET_ALL_ABSENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: null,
        error: {
          getAbsents: null,
        },
      };
    case GET_ALL_ABSENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: "Absents fetched",
        error: {
          getAbsents: false,
        },
        absents: action.payload.absents,
      };
    case GET_ALL_ABSENTS_FAIL:
      return {
        ...state,
        isLoading: false,
        message: "Error fetching absents",
        error: {
          getAbsents: true,
        },
      };
    case RESET_ABSENT_STATE:
      return {
        ...state,
        error: {},
        message: null,
        isLoading: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default absentsReducer;

export const captureAbsent = (formData) => async (dispatch, getState) => {
  dispatch({ type: ADD_ABSENT_REQUEST });
  try {
    const { AuthDuck } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/callCentre/absents`,
      formData,
      config,
    );
    dispatch({ type: ADD_ABSENT_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: ADD_ABSENT_FAIL, payload: error });
  }
};

export const editAbsent = (formData) => async (dispatch, getState) => {
  dispatch({ type: EDIT_ABSENT_REQUEST });
  try {
    const { AuthDuck } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/callCentre/absents/${formData.id}`,
      formData,
      config,
    );
    dispatch({ type: EDIT_ABSENT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: EDIT_ABSENT_FAIL, payload: error });
  }
};

export const getAbsents = () => async (dispatch, getState) => {
  dispatch({ type: GET_ALL_ABSENTS_REQUEST });
  try {
    const { AuthDuck } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/absents`,
      config,
    );
    dispatch({ type: GET_ALL_ABSENTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_ABSENTS_FAIL, payload: error });
  }
};
