import { Button, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import SelectWrapper from "../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import * as yup from "yup";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import LocationAutoComplete from "../../../Components/FormComponents/LocationAutoComplete";
import { useState } from "react";
import { useMutation, useQueryClient } from '@tanstack/react-query'
import withAuth from "../../../Hoc/withAuth";
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export const provinces = [
  {
    value: "Eastern Cape",
    label: "Eastern Cape",
  },
  {
    value: "Free State",
    label: "Free State",
  },
  {
    value: "Gauteng",
    label: "Gauteng",
  },
  {
    value: "KwaZulu-Natal",
    label: "KwaZulu-Natal",
  },
  {
    value: "Limpopo",
    label: "Limpopo",
  },
  {
    value: "Mpumalanga",
    label: "Mpumalanga",
  },
  {
    value: "Northern Cape",
    label: "Northern Cape",
  },
  {
    value: "North West",
    label: "North West",
  },
  {
    value: "Western Cape",
    label: "Western Cape",
  },
];

const OEAddressInfo = ({ employee_id, AddressInfo, submitted, modules, handleTabChange, disabled }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();
  const [mutationError, setMutationError] = useState("");
  const [successMessage, setSuccessMessage] = useState('Address Information saved successfully')

  const switchTab = (newTabIndex) => {
    handleTabChange(newTabIndex);
  };

  if(!employee_id) {
    navigate('/otp')
  }

  const [locationOption, setLocationOption] = useState([]);

  const mutation = useMutation({
    mutationFn: (values) => {
      if(values.address_id){
        setSuccessMessage('Address Information updated successfully')
        return axios.put(
          `${process.env.REACT_APP_API_URL}/onboarding/employees/addressInfo/${values.address_id}`,
          { ...values }
        );
      }else{
        return axios.post(
          `${process.env.REACT_APP_API_URL}/onboarding/employees/addressInfo/`,
          { ...values }
        );
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["employeeInfo"]);
    },
    onError: async (error) => {
      setMutationError(error.response.data.message);
    }
  })

  const onSaveAddressInfoHandler = (values) => {
    mutation.mutate(values)
  }

  const warningText =
    "It is your responsibility to ensure that the information you provide below is accurate and complete.  Address entered should be your address of residence in Gauteng, South Africa.";

  return (
    <Box component="div">
      <Stack spacing={2} mt={2} mb={2}>
        <Typography variant="span">ADDRESS INFORMATION</Typography>

        <Typography color={"error"} fontSize={15}>
          {warningText}
        </Typography>
      </Stack>
      
      {mutation.isLoading && (
        <AlertPopup open={true} severity={"info"} message={"Saving Address Information..."} />
      )}
      
      {mutation.isSuccess && (
        <AlertPopup open={true} message={successMessage} />
      )}

      { mutationError && (
            <AlertPopup open={true} message={mutationError} severity={"error"} />
          ) }
      <>
        <Formik
          initialValues={{
            employee_id,
            address_id: AddressInfo[0]?.id || "",
            address_type: AddressInfo[0]?.postal || "physical",
            unit_number: AddressInfo[0]?.unit_number || "",
            complex_name: AddressInfo[0]?.complex_name || "",
            street_name:
              (locationOption.length !== 0 &&
                locationOption[0]
                  .split(" ")
                  .slice(1, locationOption[0].split(" ").length)
                  .join(" ")) ||
              AddressInfo[0]?.street_name ||
              "",
            suburb:
              (locationOption && locationOption[1]) ||
              AddressInfo[0]?.suburb ||
              "",
            city:
              (locationOption && locationOption[2]) ||
              AddressInfo[0]?.city ||
              "",
            postal_code: AddressInfo[0]?.postal_code || "",
            province: AddressInfo[0]?.province || "Gauteng",
            country: AddressInfo[0]?.country || "South Africa",
            street_number:
              locationOption[0]?.split(" ")[0] ||
              AddressInfo[0]?.street_number ||
              "",
          }}
          validationSchema={yup.object().shape({
            address_type: yup.string().required("Address type required"),
            street_name: yup.string().required("Street name required"),
            street_number: yup
              .string()
              .matches(/^\d+$/, {
                message: "Street number should be only numbers",
              })
              .required("Street name required"),
            suburb: yup.string().required("Suburb name required"),
            city: yup.string().required("City name required"),
            postal_code: yup
              .string()
              .min(4, "Postal Code should be atleast 4 digits")
              .max(6, "Postal Code cannot have more than 6 digits")
              .matches(/^\d+$/, {
                message: "Postal Code should be only numbers",
              })
              .required("Postal Code is required"),
            province: yup.string().required("Province name required"),
            country: yup.string().required("Country name required"),
          })}
          onSubmit={(values) => {
            onSaveAddressInfoHandler(values)
          }}
          enableReinitialize={true}
        >
          {({ values, errors, setFieldValue, initialValues, handleSubmit }) => {
            return (
              <Form
                onSubmit={(jsEvent) => {
                  const { name, value } = jsEvent.nativeEvent.submitter;
                  setFieldValue(name, value);

                  return handleSubmit(jsEvent);
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <LocationAutoComplete
                      setLocationOption={setLocationOption}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name={"unit_number"}
                      placeholder={"Unit Number"}
                      label="Unit Number"
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name={"complex_name"}
                      placeholder={"Complex Name"}
                      label="Complex Name"
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name={"street_number"}
                      placeholder={"Street Number"}
                      label="Street Number"
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name={"street_name"}
                      placeholder={"Street Name"}
                      label="Street Name"
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name={"suburb"}
                      placeholder={"Suburb"}
                      label="Suburb"
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name={"postal_code"}
                      placeholder={"Street Code"}
                      label="Street Code"
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name={"city"}
                      placeholder={"City"}
                      label="City"
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectWrapper
                      name={"province"}
                      label="Province"
                      options={provinces}
                      autoComplete={"off"}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextfieldWrapper
                      name={"country"}
                      label="Country"
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box component={"div"} textAlign="center">
                      { !AddressInfo[0] ? (
                        <Stack
                          direction={"row"}
                          justifyContent="center"
                          spacing={2}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              width: 170,
                            }}
                            color="primary"
                            onClick={() => switchTab(0)}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              width: 170,
                            }}
                            disabled={disabled}
                            color="primary"
                          >
                            Save
                          </Button>
                          <Button
                            variant="contained"
                            type="submit"
                            name="submitAction"
                            value="3"
                            sx={{
                              width: 170,
                            }}
                            color="primary"
                            onClick={() => switchTab(2)}
                          >
                            Next
                          </Button>
                        </Stack>
                      ) : (
                        AddressInfo[0] && (
                          <Stack
                            direction={"row"}
                            spacing={2}
                            justifyContent="center"
                          >
                            <Button
                              variant="contained"
                              sx={{
                                width: 170,
                              }}
                              color="primary"
                              onClick={() => switchTab(0)}
                            >
                              Back
                            </Button>
                            <Button
                              variant="contained"
                              type="submit"
                              name="submitAction"
                              value="0"
                              disabled={disabled}
                              sx={{
                                width: 170,
                              }}
                              color="primary"
                            >
                              Update
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                width: 170,
                              }}
                              color="primary"
                              onClick={() => switchTab(2)}
                            >
                              Next
                            </Button>
                          </Stack>
                        )
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </>
    </Box>
  );
};

export default withAuth(OEAddressInfo);
