import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Container } from "@mui/system";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";
import AddPatchNotes from "./AddPatchNotes";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../Hoc/withAuth";
import AlertPopup from "../Bits/AlertPopup";
import dayjs from "dayjs";
import SlateDisplay from "../FormComponents/SlateDisplay";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PatchNotesModal = ({ user }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  };

  let isAdmin = user.userType === "admin" ? true : false;

  const [open, setOpen] = React.useState(false);

  const getPatchNotes = useQuery({
    queryKey: ["patchNotes"],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/PatchNotes`,
        config
      );
      return response.data;
    },
  });

  let PatchNotesData = getPatchNotes?.data?.data;

  const createPatchNotes = useMutation({
    mutationFn: async (values) => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/PatchNotes`,
        values,
        config
      );
      return response.data;
    },
    onSuccess: () => {
      getPatchNotes.refetch();
    },
  });

  const deletePatchNotes = useMutation({
    mutationFn: async (id) => {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/PatchNotes/${id}`,
        config
      );
      return response.data;
    },
    onSuccess: () => {
      getPatchNotes.refetch();
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (getPatchNotes.isLoading) {
    return <CircularProgress />;
  }

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen}>
        <SpeakerNotesIcon />
      </IconButton>

      <AlertPopup
        open={createPatchNotes.isSuccess}
        message="Patch notes added Success"
        severity="success"
      />
      <AlertPopup
        open={deletePatchNotes.isSuccess}
        message="Patch notes deleted Success"
        severity="success"
      />

      <AlertPopup
        open={createPatchNotes.isError}
        message="Patch notes Create Failed"
        severity="error"
      />
      <AlertPopup
        open={deletePatchNotes.isError}
        message="Patch notes delete failed"
        severity="error"
      />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Patch Notes
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <Container sx={{ mt: 4 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end">
                {isAdmin && (
                  <AddPatchNotes createPatchNotes={createPatchNotes} />
                )}
              </Stack>
              <Typography variant="h4">Patch Notes</Typography>
            </Grid>
            <Grid item xs={12}>
              {PatchNotesData &&
                PatchNotesData?.map((note) => {
                  return (
                    <Card key={note.id}>
                      <CardHeader
                        title={note.title}
                        subheader={dayjs(note.date).format("DD-MMM-YYYY")}
                        action={
                          isAdmin && (
                            <IconButton
                              color="error"
                              onClick={() => {
                                deletePatchNotes.mutate(note.id);
                              }}>
                              <DeleteForeverIcon />
                            </IconButton>
                          )
                        }
                      />
                      <CardContent>
                        <Typography variant="h6">
                          Version: {note.version}
                        </Typography>
                        <Typography variant="body1">
                          {note.description}
                        </Typography>

                        <SlateDisplay content={note.content} />
                      </CardContent>
                    </Card>
                  );
                })}
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </React.Fragment>
  );
};
export default withAuth(PatchNotesModal);
