import styled from "@emotion/styled";
import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import DisableAnswer from "./DisableAnswer";
import EditQaQueueAnswer from "./EditQaQueueAnswer";

const AnswersTable = ({ answers, queue_id, question_id, editAnswer }) => {
  const [view, setView] = React.useState(false);

  const [enabledAnswers, setEnabledAnswers] = React.useState([]);
  const [disabledAnswers, setDisabledAnswers] = React.useState([]);

  React.useEffect(() => {
    const enabled = answers.filter((answer) => answer.active === true);
    const disabled = answers.filter((answer) => answer.active === false);
    setEnabledAnswers(enabled);
    setDisabledAnswers(disabled);
  }, [answers]);

  console.log("enabledAnswers", enabledAnswers);

  return (
    <>
      <Table size="small" aria-label="question_answers">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name </StyledTableCell>
            <StyledTableCell>Is Active</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Value</StyledTableCell>
            <StyledTableCell>Edit</StyledTableCell>
            <StyledTableCell>Disable</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {enabledAnswers &&
            enabledAnswers.length > 0 &&
            enabledAnswers.map((detail) => {
              return (
                <TableRow key={detail?.id}>
                  <StyledTableCell component="th" scope="row">
                    {detail?.answer}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Chip
                      label={detail?.active ? "Active" : "Disabled"}
                      variant="outlined"
                      color={detail?.active ? "default" : "warning"}
                    />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {detail?.data_capture ? (
                      <Chip
                        label="Data Capture"
                        variant="outlined"
                        color="default"
                      />
                    ) : (
                      <Chip
                        label={detail?.answer_type}
                        variant="outlined"
                        color="default"
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {detail?.answer_type === "standard" ? (
                      <Chip
                        label={detail?.value * 10}
                        variant="outlined"
                        color={
                          detail?.value * 10 >= 9
                            ? "secondary"
                            : detail?.value * 10 >= 6 && detail?.value * 10 <= 8
                            ? "warning"
                            : "error"
                        }
                      />
                    ) : (
                      <Chip label="1 - 10" variant="outlined" color="default" />
                    )}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <EditQaQueueAnswer
                      editAnswer={editAnswer}
                      queue_id={queue_id}
                      question_id={question_id}
                      answer={detail}
                    />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <DisableAnswer
                      editAnswer={editAnswer}
                      queue_id={queue_id}
                      question_id={question_id}
                      answer={detail}
                    />
                  </StyledTableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      {view ? (
        <Button onClick={() => setView(!view)}>Hide</Button>
      ) : (
        <Button onClick={() => setView(!view)}>View Disabled Answers</Button>
      )}

      {view && (
        <Table
          sx={{
            backgroundColor: "background.default",
            border: "1px solid red",
          }}
          size="small"
          aria-label="question_answers">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name </StyledTableCell>
              <StyledTableCell>Is Active</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Value</StyledTableCell>
              <StyledTableCell>Edit</StyledTableCell>
              <StyledTableCell>Disable</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {disabledAnswers &&
              disabledAnswers.length > 0 &&
              disabledAnswers.map((detail) => {
                return (
                  <TableRow key={detail?.id}>
                    <StyledTableCell component="th" scope="row">
                      {detail?.answer}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Chip
                        label={detail?.active ? "Active" : "Disabled"}
                        variant="outlined"
                        color={detail?.active ? "default" : "warning"}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Chip
                        label={detail?.answer_type}
                        variant="outlined"
                        color="default"
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {detail?.answer_type === "standard" ? (
                        <Chip
                          label={detail?.value}
                          variant="outlined"
                          color="default"
                        />
                      ) : (
                        <Chip
                          label="1 - 10"
                          variant="outlined"
                          color="default"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <EditQaQueueAnswer
                        editAnswer={editAnswer}
                        queue_id={queue_id}
                        question_id={question_id}
                        answer={detail}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <DisableAnswer
                        editAnswer={editAnswer}
                        queue_id={queue_id}
                        question_id={question_id}
                        answer={detail}
                      />
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default AnswersTable;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
