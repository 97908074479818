import { Card, CardActions, CardHeader } from "@mui/material";
import React from "react";
import RemoveModule from "../DeleteModule";

const CompanyModuleCard = ({ name, id, user_id, module_id }) => {
  return (
    <Card sx={{border: '1px dashed grey'}}>
      <CardHeader title={name} />
      <CardActions>
        <RemoveModule module_id={module_id} user_id={user_id} id={id} />
      </CardActions>
    </Card>
  );
};

export default CompanyModuleCard;
