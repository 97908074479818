import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { createQuestionnaireUsers } from "../State/QuestionnairsDuck";

import { enZA } from "date-fns/locale";

import * as Yup from "yup";
import { useFormik } from "formik";
import { CreateNotification } from "../../../../../../Redux/Ducks/NotificationDuck";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function AddUsersToQuestionnaire({ users, campaign_id }) {
  const [open, setOpen] = React.useState(false);

  const [selectUser, setSelectUser] = React.useState([]);
  const [complete_by, setCompleteBy] = React.useState(new Date());

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeUser = (event) => {
    setSelectUser(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      campaign_id: campaign_id,
      user_id: selectUser,
      complete_by: complete_by,
    },
    validationSchema: Yup.object({
      user_id: Yup.string().required("A User is required"),
      complete_by: Yup.string().required("A date is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      dispatch(createQuestionnaireUsers(values));
      let notification = {
        user_id: values.user_id,
        title: "Questionnaire",
        message: "You have been added to a questionnaire",
        type: "info",
        link: `/CDA/UserQuestionnaires`,
      };

      dispatch(CreateNotification(notification));

      setTimeout(() => {
        resetForm();
        handleClose();
        window.location.reload();
      }, 1000);
    },
  });

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   let values = {
  //     user_id: selectUser,
  //     campaign_id: campaign_id,
  //     complete_by: complete_by,
  //   };
  //   // dispatch(createQuestionnaireUsers(values));
  //   console.log(values);
  //   handleClose();
  // };

  return (
    <div>
      <Button variant="outlined" fullWidth onClick={handleClickOpen}>
        Add Users To Questionnaire
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle> Add Users To Questionnaire</DialogTitle>
        <>
          <form onSubmit={formik.handleSubmit}>
            <Stack sx={{ p: 3 }}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="select_user">Select User</InputLabel>
                <Select
                  labelId="select_user"
                  id="user_select"
                  label="Select User"
                  name="userId"
                  value={selectUser}
                  onChange={handleChangeUser}>
                  <MenuItem value=""> </MenuItem>
                  {users?.map((user) => (
                    <MenuItem key={user?.user_id} value={user?.user_id}>
                      {user?.User?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={enZA}>
                <FormControl margin="normal" fullWidth>
                  <DatePicker
                    margin="normal"
                    label="Complete test by"
                    name="complete_by"
                    value={complete_by}
                    onChange={(date) => setCompleteBy(date)}
                    variant="inline"
                    inputVariant="outlined"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </LocalizationProvider>
            </Stack>
            <Stack>
              <Button type="submit" variant="contained">
                Save
              </Button>
              <Button color="inherit" onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </form>
        </>
      </Dialog>
    </div>
  );
}
