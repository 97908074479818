import React, { useState } from "react";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { Alert, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../../../../Hoc/withAuth";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { format, startOfDay } from "date-fns";
import { useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const formatTimeToHMS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return `${hours}h ${minutes}m ${remainingSeconds}s`;
};

const formatTimeToHMS2 = (seconds) => {
  const hours = Math.floor(seconds);
  const minutes = Math.floor((seconds % 1) * 60);
  const remainingSeconds = Math.floor(((seconds % 1) * 60 % 1) * 60);
  return `${hours}h ${minutes}m ${remainingSeconds}s`;
};

const EvaluationUserStats = ({ user }) => {
  const { id } = useParams();
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(startOfDay(new Date()));

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const { data: AllEvaluations, isLoading } = useQuery(
    ["getAllEvaluations", startDate, endDate],
    async () => {
      const url = new URL(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/evaluations/${id}/AgentEvals`
      );

      if (startDate && endDate) {
        url.searchParams.append("startDate", format(startDate, "yyyy-MM-dd"));
        url.searchParams.append("endDate", format(endDate, "yyyy-MM-dd"));
      }

      const response = await axios.get(url.toString(), config);
      return response?.data?.data || [];
    },
    {
      enabled: !!startDate && !!endDate,
    }
  );


  const columns = [
    {
      field: "agent_name",
      headerName: "Agent Name",
      width: 150,
    },

    {
      field: "createdAt",
      headerName: "From",
      width: 150,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "updatedAt",
      headerName: "To",
      width: 150,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "evaluations",
      headerName: "Total Evaluations",
      width: 100,
    },
    {
      field: "new",
      headerName: "New",
      width: 100,
    },
    {
      field: "busy",
      headerName: "Busy",
      width: 100,
    },
    {
      field: "completed",
      headerName: "Completed",
      width: 100,
    },
    {
      field: "failed",
      headerName: "Failed",
      width: 100,
    },
    {
      field: "reEvaluations",
      headerName: "Re-evaluations/Disputes",
      width: 120,
    },
    {
      field: "passrate",
      headerName: "Pass Rate(%)",
      width: 100
    },
    {
      field: "failrate",
      headerName: "Fail Rate(%)",
      width: 100
    },
    {
      field: "evaluationHours",
      headerName: "Evaluation Time",
      width: 150,

      valueFormatter: (params) => formatTimeToHMS2(params.value),
    },
    {
      field: "listeningTime",
      headerName: "Listening Time",
      width: 150,

      valueFormatter: (params) => formatTimeToHMS(params.value),
    },
  ];

  return (
    <Stack spacing={2} mt={2}>
      <Box display="flex" alignItems="center" mb={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
            renderInput={(params) => <TextField {...params} />}
            slotProps={{
              textField: {
                fullWidth: true,
                margin: "normal",
              },
            }}
          />
          <Box mx={2}>to</Box>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={setEndDate}
            renderInput={(params) => <TextField {...params} />}
            slotProps={{
              textField: {
                fullWidth: true,
                margin: "normal",
              },
            }}
          />
        </LocalizationProvider>
      </Box>

      {isLoading ? (
        <LinearProgress />
      ) : AllEvaluations.length > 0 ? (
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPremium
            rows={AllEvaluations}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
            disableSelectionOnClick
            density="compact"
          />
        </div>
      ) : (
        <Alert severity="info">No Data Found</Alert>
      )}
    </Stack>
  );
};

export default withAuth(EvaluationUserStats);
