import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import SubmitModal from "../Components/SubmitModal";
import {
  finishEvaluation,
  getEvaluationResults,
  saveEvaluation,
} from "../State/AgentEvaluationsDuck";

const EvalQuestionList = ({ questions, Evaluation }) => {
  const [answers, setAnswers] = React.useState([]);

  const [isFinished, setIsFinished] = React.useState(false);

  const dispatch = useDispatch();

  const setQuestionAnswersCallBack = React.useCallback(() => {
    if (questions && questions.length > 0)
      setAnswers(
        questions.map((item, index) => {
          let results = item?.qa_evaluation_results[0];

          return {
            evaluation_id: Evaluation.id,
            question_id: item.id,
            comment: results?.comment,
            value: results?.answer_id || "",
            data_capture_error: results?.data_capture_error,
            data_capture_fix_values: results?.data_capture_fix_values,
            isAnswered: results?.value ? true : false,
            qa_agent_fix_values: results?.qa_agent_fix_values,
            answer_id: item?.answer_id,
            questionsAnswers: item?.qa_queue_question_answers.filter(
              (answer) => answer.active === true
            ),
          };
        })
      );
  }, [questions, Evaluation]);

  useEffect(() => {
    setQuestionAnswersCallBack();
  }, [setQuestionAnswersCallBack]);

  const handleAdminAnswerChange = (event, answer) => {
    let foundAnswer = answer.questionsAnswers.find(
      (item) => item.id === event.target.value
    );

    setAnswers((prev) => {
      return prev.map((item) =>
        item.question_id === event.target.name
          ? {
              ...item,
              value: event.target.value,
              isAnswered: true,
              data_capture_error: foundAnswer.data_capture ? true : false,
              qa_agent_fix_values: foundAnswer.qa_agent_fix_values
                ? false
                : null,
              data_capture_fix_values: null,
            }
          : item
      );
    });
  };

  const QaAgentMarkDataCaptureFix = (question_id, value) => {
    setAnswers((prev) => {
      return prev.map((item) =>
        item.question_id === question_id
          ? {
              ...item,
              qa_agent_fix_values: value,
            }
          : item
      );
    });
  };

  const saveCommentOnBlur = (data) => {
    setAnswers(
      answers.map((item) =>
        item.question_id === data.question_id
          ? { ...item, comment: data.comment }
          : item
      )
    );
  };
  const saveDataCaptureChanges = (data) => {
    setAnswers(
      answers.map((item) =>
        item.question_id === data.questionId
          ? { ...item, data_capture_fix_values: data.data_capture_fix_values }
          : item
      )
    );
  };

  useEffect(() => {
    if (answers.length > 0) {
      let isFinished = answers.every((answer) => answer.isAnswered);
      setIsFinished(isFinished);
    }
  }, [answers]);

  const handleSave = () => {
    let newAnswers = answers?.map((item) => {
      let selected = item.questionsAnswers.find(
        (answer) => answer.id === item.value
      );
      return {
        ...item,
        answer_id: selected?.id,
        value: selected?.value,
      };
    });

    dispatch(
      saveEvaluation({
        answers: newAnswers,
        current_session: Evaluation.current_session_id,
      })
    );

    dispatch(getEvaluationResults(Evaluation.id));
  };

  const handleFinish = () => {
    let newAnswers = answers?.map((item) => {
      let selected = item.questionsAnswers.find(
        (answer) => answer.id === item.value
      );

      return {
        ...item,
        answer_id: selected?.id,
        value: selected?.value,
      };
    });

    const sendData = {
      answers: newAnswers,
      evaluation: {
        id: Evaluation.id,
        status: "Complete",
      },
    };

    dispatch(finishEvaluation(Evaluation.id, sendData));
  };

  return (
    <>
      {questions
        ?.sort((a, b) => a?.order - b?.order)
        ?.map((question, index) => {
          let answer = answers.find(
            (answer) => answer.question_id === question.id
          );

          return (
            <Card key={index}>
              <CardHeader
                title={question.question}
                action={
                  <Chip
                    label={answer?.isAnswered ? "Answered" : "Not Answered"}
                    color={answer?.isAnswered ? "secondary" : "info"}
                    variant="outlined"
                  />
                }
              />
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    {answer && (
                      <Stack spacing={2}>
                        <Grid item xs={12}>
                          <RadioGroup
                            onChange={(event) => {
                              handleAdminAnswerChange(event, answer);
                            }}
                            value={answer?.value || ""}
                            aria-labelledby="demo-radio-buttons-group-label"
                            name={question.id}>
                            {question.qa_queue_question_answers
                              .filter((answer) => answer.active === true)
                              .map((answerOption, index) => {
                                return (
                                  <React.Fragment key={answerOption.id}>
                                    <FormControlLabel
                                      id={answerOption.id}
                                      value={answerOption.id}
                                      control={<Radio />}
                                      labelPlacement="end"
                                      label={answerOption.answer}
                                    />

                                    {/* {answerOption.data_capture &&
                                      answerOption.id === answer?.value && (
                                        <>
                                          <DataCaptureComp
                                            history={
                                              answer?.data_capture_fix_values
                                            }
                                            handleSave={saveDataCaptureChanges}
                                            questionId={question.id}
                                          />
                                        </>
                                      )} */}
                                  </React.Fragment>
                                );
                              })}
                          </RadioGroup>
                        </Grid>

                        {answer?.value &&
                          Number(
                            question?.qa_queue_question_answers.find(
                              (item) => item.id === answer?.value
                            ).value
                          ) >= 0.4 && (
                            <>
                              {answer?.data_capture_error && (
                                <>
                                  <DataCaptureComp
                                    history={answer?.data_capture_fix_values}
                                    handleSave={saveDataCaptureChanges}
                                    questionId={question.id}
                                  />

                                  {answer?.qa_agent_fix_values ? (
                                    <>
                                      <Alert severity="success">
                                        Data Capture Fixed
                                      </Alert>
                                      <Button
                                        variant="outlined"
                                        color="warning"
                                        onClick={() =>
                                          QaAgentMarkDataCaptureFix(
                                            question.id,
                                            false
                                          )
                                        }>
                                        Unmark Data Capture Fixed
                                      </Button>
                                    </>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      color="error"
                                      onClick={() =>
                                        QaAgentMarkDataCaptureFix(
                                          question.id,
                                          true
                                        )
                                      }>
                                      Mark Data Capture Fixed
                                    </Button>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        {/* 
<Button
                                      variant="outlined"
                                      color="error"
                                      onClick={() =>
                                        QaAgentMarkDataCaptureFix(
                                          question.id,
                                          true
                                        )
                                      }>
                                      Mark Data Capture Fix
                                    </Button> */}

                        {/* {answer?.qa_agent_fix_values && (
                          <DataCaptureComp
                            history={answer?.data_capture_fix_values}
                            handleSave={saveDataCaptureChanges}
                            questionId={question.id}
                          />
                        )} */}

                        <CommentComp
                          history={answer?.comment}
                          handleSave={saveCommentOnBlur}
                          questionId={question.id}
                        />
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <Stack direction="row">
                {/* {answer.value <= 0.5 && answer.qa_queues_question.auto_fail && (
                <Chip variant="outlined" label="Auto Fail" color="error" />
              )} */}
                {question.call_back_request && (
                  <Chip
                    variant="outlined"
                    label="call back request"
                    color="warning"
                  />
                )}
                {question.compliance && (
                  <Chip variant="outlined" label="compliance" />
                )}
                {question.product_knowledge && (
                  <Chip variant="outlined" label="product_knowledge" />
                )}
                {question.professionalism && (
                  <Chip variant="outlined" label="professionalism" />
                )}
                {question.sales_skills && (
                  <Chip variant="outlined" label="sales_skills" />
                )}
                {question.data_capture && (
                  <Chip variant="outlined" label="Data Capture" />
                )}
                {question.tcf && <Chip variant="outlined" label="tcf" />}
              </Stack>
            </Card>
          );
        })}
      <Box
        sx={{
          border: 1,
          mt: 10,
          backgroundColor: "background.paper",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          bottom: 0,
          borderRadius: 2,
          p: 1,
          width: "60vw",
        }}>
        {/* <PlayerCard /> */}
        {isFinished ? (
          <SubmitModal
            answers={answers}
            questions={questions}
            handleFinish={handleFinish}
          />
        ) : (
          // <Button
          //   fullWidth
          //   onClick={handleFinish}
          //   variant="contained"
          //   color="primary">
          //   Finish
          // </Button>
          <Button
            fullWidth
            onClick={handleSave}
            variant="contained"
            color="secondary">
            Save Progress
          </Button>
        )}
      </Box>
    </>
  );
};

export default EvalQuestionList;

const CommentComp = ({ handleSave, questionId, history }) => {
  const [comment, setComment] = React.useState({
    question_id: questionId,
    comment: history ? history : "",
  });

  const handleCommentChange = (event) => {
    setComment({
      ...comment,
      comment: event.target.value,
    });
  };

  return (
    <TextField
      id={`Comment${questionId}`}
      label="Comment"
      variant="outlined"
      value={comment.comment}
      onChange={(event) => handleCommentChange(event)}
      onBlur={() => {
        handleSave(comment);
      }}
      multiline
      rows={4}
    />
  );
};

const DataCaptureComp = ({ handleSave, questionId, history }) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Formik
          initialValues={{
            questionId: questionId,
            data_capture_fix_values: history
              ? history
              : [
                  {
                    fieldName: "",
                    from: "",
                    to: "",
                  },
                ],
          }}
          onSubmit={(values) =>
            setTimeout(() => {
              // alert(JSON.stringify({ values, ...data }, null, 2));

              handleSave(values);
            }, 500)
          }>
          {({ values, submitForm }) => {
            return (
              <Form>
                <FieldArray
                  name="data_capture_fix_values"
                  render={(arrayHelpers) => (
                    <div>
                      {values.data_capture_fix_values &&
                      values.data_capture_fix_values.length > 0 ? (
                        values.data_capture_fix_values.map((friend, index) => {
                          return (
                            <div key={index}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <TextfieldWrapper
                                    label="Field Name"
                                    margin="dense"
                                    name={`data_capture_fix_values.${index}.fieldName`}
                                    helperText="Enter the field name e.g. first name"
                                    onBlur={() => {
                                      submitForm();
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextfieldWrapper
                                    label="From"
                                    margin="dense"
                                    name={`data_capture_fix_values.${index}.from`}
                                    helperText="Enter the value to be replaced e.g. John"
                                    onBlur={() => {
                                      submitForm();
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextfieldWrapper
                                    label="To"
                                    margin="dense"
                                    name={`data_capture_fix_values.${index}.to`}
                                    helperText="Enter the value to replace with e.g. Jane"
                                    onBlur={() => {
                                      submitForm();
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between">
                                    <Button
                                      type="button"
                                      color="success"
                                      onClick={() =>
                                        arrayHelpers.insert(index, {
                                          fieldName: "",
                                          from: "",
                                          to: "",
                                        })
                                      } // insert an empty string at a position
                                    >
                                      Add a new set +
                                    </Button>
                                    <Button
                                      type="button"
                                      color="error"
                                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                    >
                                      Remove Current Set -
                                    </Button>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </div>
                          );
                        })
                      ) : (
                        <Button
                          type="button"
                          onClick={() => arrayHelpers.push("")}>
                          {/* show this when user has removed all friends from the list */}
                          Add a data capture fix values
                        </Button>
                      )}

                      <Stack direction="row" justifyContent="flex-end">
                        {/* <Button variant="contained" type="submit">
                          Save
                        </Button> */}
                      </Stack>
                    </div>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
};

// question.qa_queue_question_answers
