import React from "react";
import TeamList from "./Components/TeamList";

const TeamsMain = () => {
  return (
    <div>
      <TeamList />
    </div>
  );
};

export default TeamsMain;
