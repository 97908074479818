import { useTheme } from "@emotion/react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import withAuth from "../../../Hoc/withAuth";
import MultiLineTextFieldWrapper from "../Components/MultiLineTextFieldWrapper";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const RejectRequisitionModal = ({ user, models, requisition }) => {
  const queryClient = useQueryClient()
  
  const [mutationError, setMutationError] = useState("");
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const mutation = useMutation({
    mutationFn: (values) => {
      if (values.type === "reject") {
        // reject requisition
        return axios.put(
          `${process.env.REACT_APP_API_URL}/onboarding/requisitions/reject/${values.id}`,
          { reject_reason: values.body},
          config
        );
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["getCEORequisitions"]);
    },
    onError: async (error) =>{
     setMutationError(error.response.data.message)
}
  });

  const onRejectRequisitionHandler = (id, body, reqType = "reject", actions) => {
    mutation.mutate({ id, body: body.reject_reason, type: reqType });
    setTimeout(() => handleClose(), 2000);
  };

  return (
    <Stack spacing={0}>
      <Button color="error" variant="outlined" onClick={handleClickOpen}>
        Reject
      </Button>

      {requisition?.approved === false && (
        <Typography color="error">
          Rejected : {requisition?.reject_reason}
        </Typography>
      )}

      

      <Stack>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          fullWidth
          aria-labelledby="responsive-dialog-title"
          sx={{ borderRadius: 0 }}
        >
          {mutation.isLoading && (
            <AlertPopup open={true} message={"Updating Requisition..."} severity={"info"} />
          )}

          { mutation.isSuccess && (
            <AlertPopup open={true} message={"Requisition Updated!"} />
          )}

          { mutationError && (
                <AlertPopup open={true} message={mutationError} severity={"error"} />
          )}
        
          <DialogTitle id="responsive-dialog-title">
            Reject Requisition
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                id: requisition.id,
                reject_reason: "",
              }}
              validationSchema={yup.object().shape({
                reject_reason: yup
                  .string()
                  .required("Please provide a reason for your reject"),
              })}
              onSubmit={(values) => {
                onRejectRequisitionHandler(requisition?.id, values);
              }}
              enableReinitialize={true}
            >
              {(values, errors) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          value={requisition?.Position?.job_title}
                          disabled
                          label="Job Title"
                          fullWidth
                          sx={{ mt: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          value={
                            requisition?.User?.name +
                            " " +
                            requisition?.User?.surname
                          }
                          disabled
                          label="Manager"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MultiLineTextFieldWrapper
                          name={"reject_reason"}
                          label="Reject Reason"
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Stack direction="row" justifyContent="end" spacing={2}>
                          <Button
                            autoFocus
                            onClick={handleClose}
                            variant="contained"
                            color="secondary"
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="error"
                            autoFocus
                            variant="contained"
                          >
                            Reject
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default withAuth(RejectRequisitionModal);
