import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useNavigate } from "react-router-dom";
import { Stack, TextField } from "@mui/material";

const AgentCampaignList = ({ list }) => {
  const [filterCampaign, setFilterCampaign] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setFilterCampaign(list);
  }, [list]);

  const handleSearch = (e) => {
    const search = e.target.value;
    const filtered = list.filter((campaign) => {
      return (
        campaign.name.toLowerCase().includes(search.toLowerCase()) ||
        campaign.id.toString().toLowerCase().includes(search.toLowerCase())
      );
    });
    setFilterCampaign(filtered);
  };

  // const handleSearchCampaign = (e) => {
  //   const filteredCampaign = list
  //     .filter((campaign) => {
  //       return campaign.name
  //         .toLowerCase()
  //         .includes(e.target.value.toLowerCase());
  //     })
  //     .map((campaign) => {
  //       return campaign;
  //     })
  //     .sort((a, b) => {
  //       return a.name.localeCompare(b.name);
  //     })
  //     .reverse();
  //   setFilterCampaign(filteredCampaign);
  // };

  return (
    <>
      <Stack direction="row" sx={{ mb: 2 }}>
        <TextField
          fullWidth
          id="standard-basic"
          label="Search Campaign"
          onChange={handleSearch}
        />
      </Stack>

      <>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Campaign Name</StyledTableCell>

              <StyledTableCell align="right">Campaign type</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterCampaign &&
              filterCampaign?.length > 0 &&
              filterCampaign?.map((row) => {
                return (
                  <TableRow
                    hover
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate(`/CDA/CampaignLeads/${row.id}`)}
                    key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      {row.campaign_type}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </>
    </>
  );
};

export default AgentCampaignList;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
