import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  useMediaQuery,
  LinearProgress,
  Alert
  
} from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import SelectWrapper from "../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import axios from 'axios'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import withAuth from "../../../Hoc/withAuth";
import AlertPopup from "../../../Components/Bits/AlertPopup";

const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
}

const AddEditDepartmentModal = ({ department, text, modules,  ...otherProps }) => {
  const queryClient = useQueryClient()

  const [mutationError, setMutationError] = useState('')
  const [mutationSuccessMessage, setMutationSuccessMessage] = useState('Department saved successfully')
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  let disabled = false

  if(modules?.includes('oe-manager') || modules?.includes('oe-hr')){
    disabled = true
  }

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const mutation = useMutation({
    mutationFn: (values) => {
      if(values.departmentId){
        setMutationSuccessMessage('Department updated successfully')
        return axios.put(`${process.env.REACT_APP_API_URL}/onboarding/departments/${values.departmentId}`, values, config)
      }else{
        //insert
        return axios.post(`${process.env.REACT_APP_API_URL}/onboarding/departments`, values, config)
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['getDepartments'])
    },
    onError: async (error) =>{
     setMutationError(error.response.data.message)
}
  })

  const onSubmitHandler = (values) => {
    mutation.mutate(values)
    setTimeout(() => {
      handleClose();
    }
    , 2000);
  }

  const { isLoading, isError, data, error } = useQuery(
    ["getManagers"],
    async () =>
      fetchData(`${process.env.REACT_APP_API_URL}/onboarding/managers`, config)
  );

  if (isLoading) {  
    return <LinearProgress />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }
  
  return (
    <Stack spacing={0}>
      <Button variant="outlined" onClick={handleClickOpen}>
        {text}
      </Button>

      

      <Stack spacing={1}>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          fullWidth
          aria-labelledby="responsive-dialog-title"
          sx={{ borderRadius: 0 }}
        >
      {mutation.isLoading && (
        <AlertPopup open={true} severity={"info"} message={"Department Position..."} />
      )}

      { mutation.isSuccess && (
        <AlertPopup open={true} message={mutationSuccessMessage} />
      )}

      { mutationError && (
            <AlertPopup open={true} message={mutationError} severity={"error"} />
      ) }
          <DialogTitle id="responsive-dialog-title">
            { modules.includes('oe-ceo') &&  department && "Edit Department" }
            { modules.includes('oe-hr') && 'View Department' }
            { modules.includes('oe-manager') && 'View Department'}
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                departmentId: department?.id || "",
                department_name: department?.department_name || "",
                manager_node_id: department?.manager_node_id || "",
              }}
              validationSchema={yup.object().shape({
                department_name: yup
                  .string()
                  .required("Please enter department name"),
                manager_node_id: yup.string().required("Please select manager"),
              })}
              onSubmit={ (values) => {onSubmitHandler(values)} } 
              enableReinitialize={true}
            >
              {({ values, errors, resetForm }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                        <TextfieldWrapper
                          name={"department_name"}
                          label="Department Name"
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <SelectWrapper
                          name={"manager_node_id"}
                          label="Select Department Manager"
                          options={data.managers.map(manager => { return { value: manager.id, label: manager.name } })}
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Box textAlign="end">
                          <Button
                            autoFocus
                            onClick={handleClose}
                            color="secondary"
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          { modules?.includes("oe-ceo") && <Button type="submit" autoFocus>
                            Save
                          </Button> }
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default withAuth(AddEditDepartmentModal);
