import React, { useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { DialogContent, Typography } from "@mui/material";
import * as Yup from "yup";
import { editLeadSource } from "../../State/LeadsDuck";

export default function DeleteLeadSource({ lead }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const toggleViewInactiveLeadSources = (values) => {
    dispatch(editLeadSource(values));
    setTimeout(() => {
      handleClose();
    }, 1000);
    handleClose();
  };

  return (
    <div>
      <Button
        color={lead.active ? "error" : "secondary"}
        onClick={handleClickOpen}
      >
        {lead.active ? <DeleteIcon /> : <RestoreFromTrashIcon />}
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          {lead.active ? "Activate" : "Deactivate"} Lead Source
        </DialogTitle>

        <Formik
          initialValues={{
            id: lead.id,
            name: lead.name,
            active: lead.active,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            active: Yup.boolean().required("Active is required"),
          })}
          onSubmit={(values) => {
            values = {
              ...values,
              active: !values.active,
              updated_reason: values.active
                ? "Deactivated Lead Source"
                : "Activated Lead Source",
            };
            toggleViewInactiveLeadSources(values);
          }}
        >
          {() => (
            <Form>
              <DialogContent>
                <Typography variant="span">
                  Are you sure you want to{" "}
                  {lead.active ? "DEACTIVATE" : "ACTIVATE"} {lead.name}?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color={lead.active ? "error" : "secondary"}
                >
                  {lead.active ? "Deactivate" : "Activate"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
