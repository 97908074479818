import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import MainAppContainer from "../../Components/Containers/MainAppContainer";
import { useNavigate } from "react-router-dom";

// import notfound from "./components/404.svg";
// import notFoundDark from "./components/ForDark.svg";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <MainAppContainer>
      <Grid container>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          {/* <Box
            sx={{
              mt: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}>
            <img
              height="300"
              src={theme.palette.mode === "light" ? notfound : notFoundDark}
              alt="404"
            />
          </Box> */}
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <Typography sx={{ mt: 10, mb: 5 }} variant="h1" align="left">
            404
          </Typography>
          <Typography sx={{ mb: 5 }} variant="h4" align="left">
            UH OH! You're lost.
          </Typography>
          <Typography variant="body1" align="left">
            The page you are looking for does not exist. How you got here is a
            mystery. But you can click the button below to go back to the
            homepage.
          </Typography>
          <Button
            sx={{ mt: 5 }}
            variant="outlined"
            onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </Grid>
      </Grid>
    </MainAppContainer>
  );
};

export default PageNotFound;
