import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import DateSelectorWrapper from "../../../../../Components/FormComponents/DateSelectorWrapper";
import SelectWrapper from "../../../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../../../Components/FormComponents/TextfieldWrapper";
import {
  getTeamsList,
  RESET_TEAM,
} from "../CampaignsManagement/State/TeamsDuck";
import { editAbsent } from "./state";

const EditAbsenteeismModal = ({ position, text, absenteeism }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [teamName, setTeamName] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const Teams = useSelector((state) => state.Teams);
  const { teamsList } = Teams;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getTeamsList());
    dispatch({ type: RESET_TEAM });
  }, [dispatch]);

  useEffect(() => {
    teamsList?.forEach((teamList) => {
      teamList.cc_teams_users.forEach((user) => {
        if (user.user_id === absenteeism?.user_id) {
          setTeamName(teamList.name);
        }
      });
    });
  }, [absenteeism, teamsList]);

  return (
    <Stack>
      <Button color="secondary" variant="contained" onClick={handleClickOpen}>
        {text}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="responsive-dialog-title"
        sx={{ borderRadius: 0 }}>
        <DialogTitle id="responsive-dialog-title">
          Edit {`${absenteeism?.User?.name}'s`} Absenteeism
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              id: absenteeism?.id || "",
              team_name: (teamName && teamName) || "",
              team_user: absenteeism?.User?.name,
              date_from: absenteeism?.date_from || "",
              date_to: absenteeism?.date_to || "",
              update_reason: absenteeism?.update_reason || "",
              absent_type: absenteeism?.absent_type || "",
            }}
            validationSchema={yup.object().shape({
              update_reason: yup.string().required("Please provide reason"),
            })}
            onSubmit={(values) => {
              dispatch(editAbsent(values));
            }}
            enableReinitialize={true}>
            {(values, errors) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <TextfieldWrapper
                        name={"team_name"}
                        label="Team"
                        sx={{ mt: 2 }}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextfieldWrapper
                        name={"team_user"}
                        label="User"
                        sx={{ mt: 2 }}
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <DateSelectorWrapper name="date_from" label="Date From" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <DateSelectorWrapper name="date_to" label="Date To" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <SelectWrapper
                        name="absent_type"
                        label="Absent Type"
                        options={[
                          { value: "sick", label: "Sick" },
                          { value: "annual", label: "Annual" },
                          { value: "unpaid", label: "Unpaid" },
                          {
                            value: "unpaid_sick",
                            label: "Unpaid Sick",
                          },
                          { value: "maternity", label: "Maternity" },
                          { value: "paternity", label: "Paternity" },
                          { value: "compassionate", label: "Compassionate" },
                          { value: "study", label: "Study" },
                          { value: "other", label: "Other" },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextfieldWrapper
                        name="update_reason"
                        label="Reason for absentism"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box textAlign="end">
                        <Button
                          autoFocus
                          onClick={handleClose}
                          variant="contained">
                          Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                          type="submit"
                          color="secondary"
                          autoFocus
                          variant="contained">
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default EditAbsenteeismModal;
