import axios from "axios";

const initalState = {
  clientsList: [],
  client: {},
  editSuccess: null,
  addSuccess: null,
  loading: false,
  error: null,
  success: false,
};

const GET_CLIENTS = "GET_CLIENTS";
const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL";

const GET_CLIENT_BY_ID = "GET_CLIENT_BY_ID";
const GET_CLIENT_BY_ID_SUCCESS = "GET_CLIENT_BY_ID_SUCCESS";
const GET_CLIENT_BY_ID_FAIL = "GET_CLIENT_BY_ID_FAIL";

const ADD_CLIENT = "ADD_CLIENT";
const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
const ADD_CLIENT_FAIL = "ADD_CLIENT_FAIL";

const EDIT_CLIENT = "EDIT_CLIENT";
const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";
const EDIT_CLIENT_FAIL = "EDIT_CLIENT_FAIL";

export const RESET_CLIENT_EDIT = "RESET_CLIENT_EDIT";

const ClientsReducer = (state = initalState, action) => {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
        clientsList: [],
        client: {},
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        clientsList: action.payload,
        client: {},
      };
    case GET_CLIENTS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        clientsList: [],
        client: {},
      };
    case ADD_CLIENT:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,

        client: {},
      };
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clientsList: [...state.clientsList, action.payload],
        addSuccess: true,
        client: {},
      };
    case ADD_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        clientsList: [],
        client: {},
      };
    case EDIT_CLIENT:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,

        client: {},
      };
    case EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        editSuccess: true,
        clientsList: [
          ...state.clientsList.map((client) => {
            if (client.id === action.payload.id) {
              return action.payload;
            }
            return client;
          }),
        ],
        client: {},
      };
    case EDIT_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        clientsList: [],
        client: {},
      };

    case GET_CLIENT_BY_ID:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
        client: {},
      };
    case GET_CLIENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        client: action.payload,
      };
    case GET_CLIENT_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        client: {},
      };

    case RESET_CLIENT_EDIT:
      return {
        ...state,
        editSuccess: false,
        success: false,
        addSuccess: false,
      };
    default:
      return state;
  }
};

export default ClientsReducer;

export const getClientsList = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CLIENTS,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/clients`,
      config
    );
    dispatch({
      type: GET_CLIENTS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CLIENTS_FAIL,
      payload: err.response,
    });
  }
};

export const addClient = (client) => async (dispatch, getState) => {
  dispatch({
    type: ADD_CLIENT,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/callCentre/clients`,
      client,
      config
    );
    dispatch({
      type: ADD_CLIENT_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_CLIENT_FAIL,
      payload: err.response,
    });
  }
};

export const editClient = (client) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_CLIENT,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/callCentre/clients/${client.id}`,
      client,
      config
    );
    dispatch({
      type: EDIT_CLIENT_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_CLIENT_FAIL,
      payload: err.response,
    });
  }
};

export const getClientById = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_CLIENT_BY_ID,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/clients/${id}`,
      config
    );
    dispatch({
      type: GET_CLIENT_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CLIENT_BY_ID_FAIL,
      payload: err.response,
    });
  }
};
