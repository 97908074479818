import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { editQaQuestionAnswer } from "../../State/QaQuestionsDuck";
import SelectWrapper from "../../../../../../../Components/FormComponents/Select";
import SliderWrapper from "../../../../../../../Components/FormComponents/SliderWrapper";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";

const EditAnswer = ({ answer }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        Edit Answer
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Edit Answer</DialogTitle>
        <Formik
          initialValues={{
            id: answer.id,
            question_id: answer?.question_id,
            answer_type: answer?.answer_type,
            answer: answer?.answer,
            value: +answer?.value || 0,
            active: true,
            updated_reason: "Edited Answer",
          }}
          validationSchema={Yup.object({
            answer_type: Yup.string().required("Required"),
            answer: Yup.string().required("Required"),
            value: Yup.number().required("Required"),
          })}
          onSubmit={(values) => {
            values.value = values.value / 10;

            dispatch(editQaQuestionAnswer(values));
          }}>
          {({ values }) => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <TextfieldWrapper
                    name="answer"
                    label="answer"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  />
                  <SliderWrapper
                    inputs={{
                      min: 1,
                      max: 10,
                    }}
                    value={values.value}
                    name="value"
                    label="Value"
                    min={0}
                    max={1}
                  />
                  <SelectWrapper
                    name="answer_type"
                    label="answer_type"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    options={[
                      { value: "standard", label: "standard" },
                      { value: "rating", label: "rating" },
                    ]}
                  />
                </Stack>
              </DialogContent>
              <Button variant="contained" fullWidth type="submit">
                Save
              </Button>
              <Button fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default EditAnswer;
