import { Grid, LinearProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import ReactAudioPlayer from "react-audio-player";

const ListScoreCardRecordings = ({ id, user }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  // /api/v1/callCentre/callrecordings/:id/recording

  const getCallRecordings = useQuery([`CallRecordings${id}`], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/callrecordings/${id}/recording`,
      config
    );
  });

  if (getCallRecordings.isRefetching || getCallRecordings.isLoading)
    return (
      <div>
        <LinearProgress />
      </div>
    );

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {getCallRecordings.data?.data?.data?.map((recording, index) => {
          console.log(recording);
          return (
            <Grid item xs={6} key={index}>
              <Stack>
                <ReactAudioPlayer
                  style={{
                    width: "100%",
                  }}
                  src={`${process.env.REACT_APP_API_URL}/callCentre/callrecordings/${recording.storage_id}/recording/download`}
                  controls
                />

                <Typography variant="caption" component="div">
                  {recording?.file_name}
                </Typography>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ListScoreCardRecordings;
