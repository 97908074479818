import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";

const DisableAnswer = ({ answer, editAnswer }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        color={answer?.active ? "error" : "primary"}
        onClick={handleClickOpen}>
        {answer?.active ? "disable Answer" : "Enable Answer"}
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          {answer?.active ? "Disable Answer" : "Enable Answer"}
        </DialogTitle>
        <Formik
          initialValues={{
            id: answer.id,
            ...answer,
          }}
          validationSchema={Yup.object({
            answer_type: Yup.string().required("Required"),
            answer: Yup.string().required("Required"),
            value: Yup.number().required("Required"),
          })}
          onSubmit={(values) => {
            values.active = !values.active;
            editAnswer.mutate(values);
          }}>
          {({ values }) => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <TextfieldWrapper
                    name="answer"
                    label="answer"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  />
                </Stack>
              </DialogContent>
              <Button
                color={answer?.active ? "error" : "primary"}
                variant="contained"
                fullWidth
                type="submit">
                {answer?.active ? "disable Answer" : "Enable Answer"}
              </Button>
              <Button fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default DisableAnswer;
