import {
  Button,
  // FormControl,
  // InputLabel,
  LinearProgress,
  // MenuItem,
  // Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import {
  getAllTargets,
  RESET_TARGET_DUCK,
} from "../CallCenterTargets/State/TargetsDuck";
import { useNavigate } from "react-router-dom";
import { getAbsents, RESET_ABSENT_STATE } from "./state";
import dayjs from "dayjs";
import EditAbsenteeismModal from "./EditAbsenteeismModal";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import withAuth from "../../../../../Hoc/withAuth";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const Attendance = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Absenteeism = useSelector((state) => state.Absenteeism);
  const { isLoading, absents } = Absenteeism;

  // const yesterdayAbsents = absents?.filter((absent) => {
  //   const date = new Date().toDateString().split(" ").slice(1).join("/");
  //   const endDate = new Date(absent.createdAt)
  //     .toDateString()
  //     .split(" ")
  //     .slice(1)
  //     .join("/");
  //   console.log(dayjs(date).diff(dayjs(endDate)));
  //   if (dayjs(date).diff(dayjs(endDate)) !== 0) {
  //     // eslint-disable-next-line array-callback-return
  //     return;
  //   }
  //   return absent;
  // });

  const todayAbsents = absents?.filter((absent) => {
    const date = new Date().toDateString().split(" ").slice(1).join("/");
    const endDate = new Date(absent.createdAt)
      .toDateString()
      .split(" ")
      .slice(1)
      .join("/");
    if (dayjs(date).diff(dayjs(endDate)) !== 0) {
      // eslint-disable-next-line array-callback-return
      return;
    }
    return absent;
  });

  useEffect(() => {
    dispatch({
      type: RESET_TARGET_DUCK,
    });
    dispatch({ type: RESET_ABSENT_STATE });
    dispatch(getAbsents());
    dispatch(getAllTargets());
  }, [dispatch]);

  let columns = [
    {
      field: "employee_no",
      headerName: "Employee No",
      width: 130,
    },
    { field: "name", headerName: "Name", width: 130 },
    { field: "surname", headerName: "Surname", width: 130 },
    {
      field: "manager",
      headerName: "Manager",
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <UserDetails absent={params.row} user={user} />
          </>
        );
      },
    },
    { field: "date_from", headerName: "From", width: 200 },
    { field: "date_to", headerName: "To", width: 200 },
    { field: "update_reason", headerName: "Reason", width: 330 },
    { field: "createdAt", headerName: "Created At", width: 330 },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        return (
          <EditAbsenteeismModal text="Edit" absenteeism={params.row.data} />
        );
      },
    },
  ];

  const rows = absents.map((absent) => {
    return {
      id: absent.id,
      employee_no: absent?.User?.employee_no,
      name: absent?.User?.name,
      surname: absent?.User?.surname,
      manager: absent?.User?.manager,
      date_from: new Date(absent.date_from).toDateString(),
      date_to: new Date(absent.date_to).toDateString(),
      update_reason: absent.update_reason,
      data: absent,
      createdAt: new Date(absent.createdAt).toDateString(),
    };
  });

  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <div>
      <Stack alignItems="end" mb={2} width="100%">
        <Button
          variant="contained"
          onClick={() => navigate("/CDA/CallCenter/CaptureAttendance")}>
          Capture Absenteeism
        </Button>
      </Stack>
      <Stack alignItems="center" mb={2}>
        <Typography variant="span">Today</Typography>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">From</StyledTableCell>
              <StyledTableCell align="center">To</StyledTableCell>
              <StyledTableCell align="center">Reason</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {todayAbsents?.map((row) => {
              return (
                <TableRow key={row.id}>
                  <StyledTableCell align="center">
                    {row?.User?.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(row.date_from).toDateString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(row.date_to).toDateString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.update_reason}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <EditAbsenteeismModal text="Edit" absenteeism={row} />
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" justifyContent="center" mb={2} mt={4}>
        {/* <FormControl sx={{ width: "30%" }}>
          <InputLabel id="demo-simple-select-label">Filter by</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={age}
            label="Filter by"
            // onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl> */}
        <Typography variant="span">All Absents</Typography>
      </Stack>

      <DataGridPremium
        getRowId={(row) => row.id}
        rows={rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
      />
      {/* 
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">From</StyledTableCell>
              <StyledTableCell align="center">To</StyledTableCell>
              <StyledTableCell align="center">Reason</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {absents.map((row) => {
              return (
                <TableRow key={row.id}>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(row.date_from).toDateString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(row.date_to).toDateString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.update_reason}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <EditAbsenteeismModal text="Edit" absenteeism={row} />
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer> */}
    </div>
  );
};

export default withAuth(Attendance);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.default,
    // color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

const UserDetails = ({ user, absent }) => {
  let module = "call-center-manager";

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getUsers = useQuery([`getUsersOfModule${module}`], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/admin/UserOfModules/${module}`,
      config
    );
  });

  let managers = getUsers?.data?.data?.data[0]?.Modules_users;

  let manager = managers?.find((manager) => {
    return manager.user_id === absent?.manager;
  });

  return <>{manager?.User?.name}</>;
};
