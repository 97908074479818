import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid, Button } from "@mui/material";
import { Formik, Form } from "formik";

import { editCampaignPlan } from "../../State/CampaignPlansDuck";
import PageHeader from "../../../../../../../Components/Bits/PageHeader";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";

import withAuth from "../../../../../../../Hoc/withAuth";

const AddCampaignPlan = () => {
    const { client_id } = useParams();
    const dispatch = useDispatch();

    return (
        <div>
            <PageHeader 
                title="Add Campaign Plan"
                breadcrumbs={[
                  {
                    title: "Dashboard",
                    href: "/",
                  },
                  {
                    title: "Manage Call Center",
                    href: "/CDA/callCenter",
                  },
                  {
                    title: "Client Campaign Plans",
                    href: "/CDA/callCenter/Plans",
                  },{
                    title: "Manage Plan",
                    href: `/CDA/callCenter/Plans/${client_id}`,
                  }]
            }/>
            <Formik 
                initialValues={{
                    start: "",
                    end: "",
                    targeted_sales: "",
                    hours_to_target: "",
                    contact_ratio: "",
                    sales_ratio: "",
                    sales_per_hour: "",
                    average_premium: "",
                    planned_leads: "",
                    leads_per_day: "",
                    leads_remaining: "",
                    client_id: client_id
                }}
                enableReinitialize={true}
                onSubmit={(values) =>
                    dispatch(editCampaignPlan(values))
                }>
                {() => {
                    return(
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={6}> 
                                    <TextfieldWrapper
                                        name="start" 
                                        label="Start Date"
                                        disabled={false} />
                                </Grid>
                                <Grid item xs={6}> 
                                    <TextfieldWrapper
                                        name="end" 
                                        label="End Date"
                                        disabled={false} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextfieldWrapper
                                    name="targeted_sales"
                                    label="Targeted Sales"
                                     disabled={false} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextfieldWrapper
                                        name="hours_to_target"
                                        label="Hours to Target"
                                        disabled={false} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextfieldWrapper
                                        name="contact_ratio"
                                        label="Contact Ratio"
                                        type="number"
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextfieldWrapper
                                        name="sales_ratio"
                                        label="Sales Ratio"
                                        type="number"
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextfieldWrapper
                                        name="sales_per_hour"
                                        label="Sales per Hour"
                                        type="number"
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextfieldWrapper
                                        name="average_premium"
                                        label="Average Premium"
                                        type="number"
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextfieldWrapper
                                        name="planned_leads"
                                        label="Planned Leads"
                                        type="number"
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextfieldWrapper 
                                        name="leads_per_day" 
                                        label="Leads per Day"
                                        type="number" 
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextfieldWrapper
                                        name="leads_remaining"
                                        label="Leads Remaining"
                                        disabled={false}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextfieldWrapper
                                        name="total_bi"
                                        label="Total BI"
                                        type="number"
                                        disabled={false}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextfieldWrapper
                                    name="agents_required"
                                    label="Agents Required"
                                    type="number"
                                    disabled={false}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextfieldWrapper
                                    multiline
                                    name="updated_reason"
                                    label="Updated Reason"
                                    type="text"
                                    disabled={false}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                    <Button type="submit" fullWidth variant="contained">
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}}
            </Formik>
        </div>
    )
}

export default withAuth(AddCampaignPlan);
