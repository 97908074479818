import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Stack,
  IconButton,
  Collapse,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ViewPastEvaluations = ({ evaluation }) => {
  const [openPastEvaluation, setOpenPastEvaluation] = React.useState(false);

  const handleOpenNotesClick = () => {
    setOpenPastEvaluation(!openPastEvaluation);
  };

  const latestEvaluation = evaluation?.history?.[evaluation.history.length - 1];
  const remainingEvaluations = evaluation?.history;

  const renderEvaluationItem = (item, index) => {
    const startTime = new Date(item.start_time);
    const endTime = new Date(item.end_time);
    const timeDiffMs = endTime - startTime;
    const timeDiffSec = timeDiffMs / 1000;

    return (
      <Card
        key={item.id || index}
        variant="outlined"
        sx={{
          bgcolor: "background.paper",
          boxShadow: 1,
          mb: 1,
        }}
      >
        <CardContent>
          <Typography variant="subtitle1">
            Status: <strong>{item.status}</strong>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Created By: {item.createdBy}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Date: {new Date(item.date).toLocaleString()}
          </Typography>

          {(item.status !== "Re-Evaluation Requested" ||
            item.status !== "Dispute Requested" ) && (
              <Typography variant="body2" color="textSecondary">
                Time Tracking: {timeDiffSec.toFixed(0)} seconds
              </Typography>
            )}

          {(item.status === "Re-Evaluation Requested" ||
            item.status === "Dispute Requested") && (
            <Typography variant="body2" color="textSecondary">
              Reason: {item.reason}
            </Typography>
          )}

          {(item.status === "Evaluation Moved") && (
            <Typography variant="body2" color="textSecondary">
              Move History: <strong>{item.reason}</strong>
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <Card>
        <CardContent>
          <Typography fontSize={20} sx={{ mb: 1 }}>
            Latest Evaluation
          </Typography>
          {latestEvaluation ? (
            renderEvaluationItem(latestEvaluation, 0)
          ) : (
            <Typography variant="body2" color="textSecondary">
              No latest evaluation available.
            </Typography>
          )}
        </CardContent>

        {remainingEvaluations?.length > 0 && (
          <>
            <CardContent>
              <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Typography fontSize={20}>View All Past Evaluations</Typography>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={handleOpenNotesClick}
                >
                  {openPastEvaluation ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </Stack>
            </CardContent>

            <Collapse in={openPastEvaluation}>
              <CardContent>
                <Stack>
                  {remainingEvaluations.map((item, index) =>
                    renderEvaluationItem(item, index + 1)
                  )}
                </Stack>
              </CardContent>
            </Collapse>
          </>
        )}
      </Card>
    </div>
  );
};

export default ViewPastEvaluations;
