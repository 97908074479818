import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { DialogContent, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { removeUserFromQaTeams, RESET_QA_TEAMS } from "../../State/QaTeamsDuck";

export default function RemoveUserFromTeam({ teamUser }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({ type: RESET_QA_TEAMS });
    setOpen(false);
  };

  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(
      removeUserFromQaTeams({
        userId: teamUser.user_id,
        teamId: teamUser.team_id,
      })
    );
    handleClose();
  };

  return (
    <div>
      <Button color="error" onClick={handleClickOpen}>
        Delete User
      </Button>
      <Dialog
        sx={{ border: "3px solid red" }}
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}>
        <DialogTitle> Delete User</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="h6" gutterBottom>
              {teamUser?.User?.name}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => handleDelete()}
            variant="contained"
            color="error"
            type="submit">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
