import {
  Autocomplete,
  Button,
  Checkbox,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getDispositionDetailsList,
  getDispositionListById,
  getDispositions,
  PostAddDispositionsToDispositionList,
} from "./State/DispostionsDuck";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ListDispositions from "./Components/Dispositions/ListDispositions";

const EditDispositionList = () => {
  const dispatch = useDispatch();

  const __window = window;

  const { id } = useParams();

  const navigate = useNavigate();

  const [dispositionsList, setDispositionsList] = React.useState([]);

  useEffect(() => {
    dispatch(getDispositionListById(id));
    dispatch(getDispositionDetailsList());
    dispatch(getDispositions());
  }, [dispatch, id]);

  const Dispositions = useSelector((state) => state.Dispositions);

  const { dispositions, dispositionList, disposition_details } = Dispositions;

  let activeDispositionDetails = disposition_details.filter(
    (disposition) => disposition.active === true
  );

  const handleSubmit = () => {
    const values = dispositionsList?.map((disposition) => {
      return {
        ...disposition,
        disposition_id: disposition.id,
        list_id: id,
      };
    });

    dispatch(PostAddDispositionsToDispositionList(id, values));
    setDispositionsList([]);
    __window.location.reload();
  };

  // get active dispositions

  // filtering list in other to exclude and already existing disposition on the same list by name

  let CurrentDispositions = dispositions?.filter(
    (disposition) =>
      dispositionList.cc_dispositions
        ?.map((dispList) => dispList.name)
        ?.indexOf(disposition.name) === -1
  );

  let fixedOptions = [];

  return (
    <Container>
      <PageHeader
        title="Edit Disposition List"
        subtitle="Edit Disposition List"
        breadcrumbs={[
          { title: "Call Center Admin", href: "/CDA/callCenter/campaigns" },
          {
            title: "Disposition Lists",
            href: "/CDA/callCenter/dispositions",
          },
          { title: "Edit Disposition List", href: "#" },
        ]}
      />

      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Stack>
            <Autocomplete
              multiple
              id="checkboxes-select-dispositions"
              options={CurrentDispositions.sort((a, b) =>
                a.name.localeCompare(b.name)
              )}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setDispositionsList([
                  ...fixedOptions,
                  ...newValue.filter(
                    (option) => fixedOptions.indexOf(option) === -1
                  ),
                ]);
              }}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Dispositions"
                  placeholder="Select Dispositions"
                  helperText="Add Existing (preset) disposition to list"
                />
              )}
            />
            <Stack direction="row" justifyContent="flex-end">
              <Button
                color="inherit"
                onClick={() => navigate(`/CDA/callCenter/dispositions?1`)}>
                Manage Dispositions?
              </Button>
            </Stack>
            <Button variant="contained" onClick={() => handleSubmit()}>
              Add Dispositions
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Typography sx={{ mb: 1 }} variant="h6">
            Current List Dispositions
          </Typography>
          <Typography gutterBottom variant="caption" color="error">
            Editing this list will Edit it for all campaigns that use it
          </Typography>

          {dispositionList?.cc_dispositions && (
            <ListDispositions
              activeDispositionDetails={activeDispositionDetails}
              dispositions={dispositionList?.cc_dispositions}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditDispositionList;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
