import { Chip } from "@mui/material";
import React from "react";

const UserTypeChips = ({ user }) => {
  return (
    <>
      <Chip
        variant="outlined"
        sx={{ p: 2 }}
        color={
          user?.user_type?.type === "admin"
            ? "primary"
            : user?.user_type?.type === "super"
            ? "error"
            : user?.user_type?.type === "super_admin"
            ? "warning"
            : "secondary"
        }
        label={user?.user_type?.type}
      />
    </>
  );
};

export default UserTypeChips;
