import { Grid } from "@mui/material";
import ReportWidget from "./ReportWidget";
import MainAppContainer from "../../../../../Components/Containers/MainAppContainer";

const AgentReports = () => {
  const Reports = [
    {
      title: "Agent",
      url: "https://app.powerbi.com/reportEmbed?reportId=9b387bec-025f-45af-905e-5f58ace07b75&autoAuth=true&ctid=152d51c7-d4c6-4d3b-9bf6-a6a62eb4d037",
      description: "Agent Report",
    },
  ];

  return (
    <MainAppContainer>
      <Grid>
        {Reports.map((report, index) => {
          return (
            <ReportWidget
              key={index}
              title={report.title}
              url={report.url}
              description={report.description}
            />
          );
        })}
      </Grid>
    </MainAppContainer>
  );
};

export default AgentReports;
