import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Chip,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  // IconButton,
  // Slide,
  Stack,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { useDispatch } from "react-redux";
// import { deleteEvaluation } from "../../State/QaEvaluations";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import UserProfile from "../Teams/UserProfile";

const QueueEvaluationsList = ({ queueEvaluations, id }) => {
  const navigate = useNavigate();

  const [viewCompleted, setViewCompleted] = React.useState(false);

  let completedEvaluations = [];
  let otherEvaluations = [];

  queueEvaluations.forEach((item) => {
    if (item.stage === "Complete") {
      completedEvaluations.push(item);
    } else {
      otherEvaluations.push(item);
    }
  });

  const columns = [
    {
      field: "View Evaluation",
      headerName: "view",
      width: 200,

      renderCell: (params) => {
        return (
          <Button
            onClick={() =>
              navigate(
                `/CDA/QaQueuesSetup/${
                  params?.row?.queue_id ? params.row.queue_id : id
                }/Evaluations/${params.row.id}`,
              )
            }
          >
            View Evaluation
          </Button>
        );
      },
    },

    { field: "uni_id", headerName: "Uni ID", width: 100 },
    { field: "note1", headerName: "Note 1", width: 100 },
    {
      field: "stage",
      headerName: "Stage",
      width: 130,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.stage}
            color={
              params.row.stage === "Complete"
                ? "secondary"
                : params.row.stage === "Busy"
                ? "warning"
                : params.row.stage === ("New" || "Re-evaluate")
                ? "primary"
                : params.row.stage === "Fail"
                ? "error"
                : "info"
            }
          />
        );
      },
    },

    {
      field: "QA Agent",
      headerName: "QA Agent",
      width: 130,

      renderCell: (params) => {
        // if params.row.user_id is null, return "Not allocation"
        if (!params.row.user_id) {
          return "";
        }
        return <UserProfile userId={params.row.user_id} />;
      },
    },
  ];

  return (
    <>
      <Stack my={2} sx={{ height: "90vh" }}>
        <DataGridPremium
          slots={{
            toolbar: GridToolbar,
          }}
          getRowId={(row) => row.id}
          rows={otherEvaluations}
          columns={columns}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Stack>
      <Stack>
        <Button onClick={() => setViewCompleted(!viewCompleted)}>
          View Completed Evaluations
        </Button>
      </Stack>
      {viewCompleted && (
        <Stack my={2} sx={{ height: "90vh" }}>
          <DataGridPremium
            slots={{
              toolbar: GridToolbar,
            }}
            getRowId={(row) => row.id}
            rows={completedEvaluations}
            columns={columns}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Stack>
      )}
    </>
  );
};

export default QueueEvaluationsList;

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// function DeleteEvaluation({ data }) {
//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const dispatch = useDispatch();

//   const handleDelete = () => {
//     dispatch(deleteEvaluation({ id: data.id }));
//     setOpen(false);
//   };

//   return (
//     <div>
//       <IconButton onClick={handleClickOpen} aria-label="delete">
//         <DeleteIcon color="error" fontSize="inherit" />
//       </IconButton>
//       <Dialog
//         sx={{
//           border: "5px solid red",
//         }}
//         open={open}
//         maxWidth="md"
//         fullWidth
//         TransitionComponent={Transition}
//         keepMounted
//         onClose={handleClose}
//         aria-describedby="alert-dialog-slide-description">
//         <DialogTitle>
//           {data.uni_id} - {data.stage}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-slide-description">
//             Are you sure you want to delete this evaluation?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>cancel</Button>
//           <Button variant="contained" color="error" onClick={handleDelete}>
//             Delete
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }
