const iState = {
  errors: {
    addEmployeeError: null,
  },
  isLoading: null,
  validationErrors: null,
  message: null,
  employees: null,
  searchedEmployees: null,
};

const employeeReducer = (state = iState, action) => {
  switch (action.type) {
    case "ADD_NEW_EMPLOYEE_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_NEW_EMPLOYEE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Employee Added Successfully",
        errors: {
          addEmployeeError: false,
        },
      };
    case "ADD_NEW_EMPLOYEE_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          addEmployeeError: true,
        },
        validationErrors: action.payload.validationErrors,
      };
    case "SAVE_NEW_EMPLOYEE_REQUEST":
      return {
        ...state,
        isLoading: true,
        validationErrors: null,
        errors: {
          saveEmployeeError: null,
        },
      };
    case "SAVE_NEW_EMPLOYEE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Information saved successfully",
        errors: {
          saveEmployeeError: false,
        },
        validationErrors: null,
      };
    case "SAVE_NEW_EMPLOYEE_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          saveEmployeeError: true,
        },
        validationErrors: action.payload.validationErrors,
      };
    case "SAVE_TAX_INFO_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {
          saveTaxInfoError: null,
        },
      };
    case "SAVE_TAX_INFO_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Tax information saved successfully",
        errors: {
          saveTaxInfoError: false,
        },
      };
    case "SAVE_TAX_INFO_FAIL":
      return {
        ...state,
        isLoading: true,
        errors: {
          saveTaxInfoError: true,
        },
      };
    case "EDIT_TAX_INFO_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {
          editTaxInfoError: null,
        },
      };
    case "EDIT_TAX_INFO_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Tax information saved successfully",
        errors: {
          editTaxInfoError: false,
        },
      };
    case "EDIT_TAX_INFO_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          editTaxInfoError: true,
        },
      };
    case "SAVE_NEW_EMPLOYEE_BANK_REQUEST":
      return {
        ...state,
        isLoading: true,
        validationErrors: null,
        errors: {
          saveEmployeeError: null,
        },
      };
    case "SAVE_NEW_EMPLOYEE_BANK_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Bank information saved successfully",
        errors: {
          saveBankError: false,
        },
        validationErrors: null,
      };
    case "SAVE_NEW_EMPLOYEE_BANK_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          saveBankError: true,
        },
        validationErrors: action.payload.validationErrors,
      };
    case "SAVE_NEW_EMPLOYEE_ADDRESS_REQUEST":
      return {
        ...state,
        isLoading: true,
        validationErrors: null,
        errors: {
          saveEmployeeError: null,
        },
      };
    case "SAVE_NEW_EMPLOYEE_ADDRESS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Address information saved successfully",
        errors: {
          saveAddressError: false,
        },
        validationErrors: null,
      };
    case "SAVE_NEW_EMPLOYEE_ADDRESS_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          saveAddressError: true,
        },
        validationErrors: action.payload.validationErrors,
      };
    case "GET_ALL_EMPLOYEES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ALL_EMPLOYEES_SUCCESS":
      return {
        ...state,
        isLoading: false,
        employees: action.payload,
      };
    case "GET_ALL_EMPLOYEES_FAIL":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_ALL_EMPLOYEES_BYID_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ALL_EMPLOYEES_BYID_SUCCESS":
      return {
        ...state,
        isLoading: false,
        employeeInfo: action.payload,
      };
    case "GET_ALL_EMPLOYEES_BYID_FAIL":
      return {
        ...state,
        isLoading: false,
      };
    case "EDIT_EMPLOYEE_ADDRESS_REQUEST":
      return {
        ...state,
        isLoading: true,
        validationErrors: null,
        errors: {
          editEmployeeAddressError: null,
        },
      };
    case "EDIT_EMPLOYEE_ADDRESS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Address updated successfully",
        errors: {
          editEmployeeAddressError: false,
        },
        validationErrors: null,
      };
    case "EDIT_EMPLOYEE_ADDRESS_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          editEmployeeAddressError: true,
        },
        validationErrors: action.payload.validationErrors,
      };
    case "EDIT_EMPLOYEE_BANKINFO_REQUEST":
      return {
        ...state,
        isLoading: true,
        validationErrors: null,
        errors: {
          editEmployeeBankError: null,
        },
      };
    case "EDIT_EMPLOYEE_BANKINFO_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Bakn info updated successfully",
        errors: {
          editEmployeeBankError: false,
        },
        validationErrors: null,
      };
    case "EDIT_EMPLOYEE_BANKINFO_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          editEmployeeBankError: true,
        },
        validationErrors: action.payload.validationErrors,
      };

    case "SAVE_EMPLOYEE_ATTACHMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
        validationErrors: null,
        message: null,
        errors: {
          docSavedError: null,
        },
      };
    case "SAVE_EMPLOYEE_ATTACHMENT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Document saved successfully",
        errors: {
          docSavedError: false,
        },
        validationErrors: null,
      };
    case "SAVE_EMPLOYEE_ATTACHMENT_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          docSavedError: true,
        },
        validationErrors: action.payload.validationErrors,
      };

    case "GET_EMPLOYEE_ATTACHMENTS_REQUEST":
      return {
        ...state,
        isLoading: true,
        validationErrors: null,
        errors: {
          docSavedError: null,
        },
        message: null,
      };

    case "GET_EMPLOYEE_ATTACHMENTS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        validationErrors: null,
        documents: action.payload.documents,
        count: action.payload.count,
      };
    case "GET_EMPLOYEE_ATTACHMENTS_FAIL":
      return {
        ...state,
        isLoading: false,
      };

    case "DELETE_EMPLOYEE_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          deleteEmployeeError: null,
        },
      };
    case "DELETE_EMPLOYEE_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Employee deleted successfully",
        errors: {
          deleteEmployeeError: false,
        },
      };
    case "DELETE_EMPLOYEE_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          deleteEmployeeError: true,
        },
      };

    case "DELETE_EMPLOYEE_ATTACHMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          docDeleteError: null,
        },
      };
    case "DELETE_EMPLOYEE_ATTACHMENT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Document deleted successfully",
        errors: {
          docDeleteError: false,
        },
      };
    case "DELETE_EMPLOYEE_ATTACHMENT_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          docDeleteError: true,
        },
      };

    case "GET_EMPLOYEE_OTP_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: {
          getOTPError: null,
        },
        message: null,
      };
    case "GET_EMPLOYEE_OTP_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          getOTPError: false,
        },
        employee_id: action.payload,
        message: "OTP was sent via SMS",
      };
    case "GET_EMPLOYEE_OTP_FAIL":
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        errors: {
          getOTPError: true,
        },
      };
    case "VERIFY_EMPLOYEE_OTP_REQUEST":
      return {
        ...state,
        isLoading: true,
        validationErrors: null,
        errors: {
          verifyOTPError: null,
        },
        message: null,
      };
    case "VERIFY_EMPLOYEE_OTP_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          verifyOTPError: false,
        },
        message: "OTP verified",
      };
    case "VERIFY_EMPLOYEE_OTP_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          verifyOTPError: true,
        },
        message: action.payload,
      };
    case "RESET_STATE_DEFAULT":
      return {
        ...state,
        isLoading: null,
        validationErrors: null,
        errors: {},
        message: null,
      };

    default:
      return {
        ...state,
        isLoading: false,
      };
  }
};

export default employeeReducer;
