import React, { useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Alert, CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DeleteUserRequest } from "../../../Redux/Ducks/AdminUserManagement";

export default function DeleteUser({ id, name }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const AdminUsers = useSelector((state) => state.AdminUsers);
  const { deleteUserError, deleteUserLoading } = AdminUsers;

  const [toDelete, setToDelete] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(DeleteUserRequest({ id }));
  };

  return (
    <div>
      <Button color="error" onClick={handleClickOpen}>
        <DeleteIcon />
      </Button>
      <Dialog
        sx={{ border: "5px solid red" }}
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <Typography align="center" color="textPrimary" variant="h6">
            Are you sure you want to delete ?
          </Typography>
          <Typography variant="h5" color="primary" align="center">
            {name}
          </Typography>
          <Typography variant="body1">
            Enter the name of the user to confirm.
          </Typography>

          <TextField
            autoFocus
            margin="normal"
            value={toDelete}
            onChange={(e) => setToDelete(e.target.value)}
            error={name !== toDelete}
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          {deleteUserLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                disabled={name !== toDelete}
                variant="outlined"
                color="error"
                onClick={handleDelete}
              >
                Confirm
              </Button>
            </>
          )}

          {deleteUserError && <Alert severity="error">{deleteUserError}</Alert>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
