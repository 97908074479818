import axios from "axios";

const initialState = {
  campaigns: [],
  loading: false,
  error: null,
  campaignLeadsList: [],
  leadSales: [],
  saleCreated: false,
  agentSales: [],
  saleUpdated: false,
  saleQA: [],
};

const GET_SALES_AGENT_CAMPAIGNS = "GET_SALES_AGENT_CAMPAIGNS";
const GET_SALES_AGENT_CAMPAIGNS_SUCCESS = "GET_SALES_AGENT_CAMPAIGNS_SUCCESS";
const GET_SALES_AGENT_CAMPAIGNS_FAIL = "GET_SALES_AGENT_CAMPAIGNS_FAIL";

const AGENT_CAMPAIGN_LEADS = "AGENT_CAMPAIGN_LEADS";
const AGENT_CAMPAIGN_LEADS_SUCCESS = "AGENT_CAMPAIGN_LEADS_SUCCESS";
const AGENT_CAMPAIGN_LEADS_FAIL = "AGENT_CAMPAIGN_LEADS_FAIL";

const GET_SALE_BY_LEAD = "GET_SALE_BY_LEAD";
const GET_SALE_BY_LEAD_SUCCESS = "GET_SALE_BY_LEAD_SUCCESS";
const GET_SALE_BY_LEAD_FAIL = "GET_SALE_BY_LEAD_FAIL";

const ADD_NEW_SALE = "ADD_NEW_SALE";
const ADD_NEW_SALE_SUCCESS = "ADD_NEW_SALE_SUCCESS";
const ADD_NEW_SALE_FAIL = "ADD_NEW_SALE_FAIL";

const UPDATE_SALE = "UPDATE_SALE";
const UPDATE_SALE_SUCCESS = "UPDATE_SALE_SUCCESS";
const UPDATE_SALE_FAIL = "UPDATE_SALE_FAIL";

const GET_SALES_AGENT_SALES = "GET_SALES_AGENT_SALES";
const GET_SALES_AGENT_SALES_SUCCESS = "GET_SALES_AGENT_SALES_SUCCESS";
const GET_SALES_AGENT_SALES_FAIL = "GET_SALES_AGENT_SALES_FAIL";

const GET_SALE_QA = "GET_SALE_QA";
const GET_SALE_QA_SUCCESS = "GET_SALE_QA_SUCCESS";
const GET_SALE_QA_FAIL = "GET_SALE_QA_FAIL";

export const RESET_SALES_AGENT = "RESET_SALES_AGENT";

const salesAgentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALES_AGENT_CAMPAIGNS:
      return {
        ...state,
        loading: true,
      };
    case GET_SALES_AGENT_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: action.payload,
      };
    case GET_SALES_AGENT_CAMPAIGNS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AGENT_CAMPAIGN_LEADS:
      return {
        ...state,
        loading: true,
      };
    case AGENT_CAMPAIGN_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignLeadsList: action.payload,
      };
    case AGENT_CAMPAIGN_LEADS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        campaignLeadsList: [],
      };

    case GET_SALE_BY_LEAD:
      return {
        ...state,
        loading: true,
      };
    case GET_SALE_BY_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        leadSales: action.payload,
      };
    case GET_SALE_BY_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        leadSales: [],
      };
    case ADD_NEW_SALE:
      return {
        ...state,
        loading: true,
        saleCreated: false,
      };
    case ADD_NEW_SALE_SUCCESS:
      return {
        ...state,
        loading: false,
        leadSales: [...state.leadSales, action.payload],
        saleCreated: true,
      };
    case ADD_NEW_SALE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        saleCreated: false,
      };
    case UPDATE_SALE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SALE_SUCCESS:
      return {
        ...state,
        loading: false,
        saleUpdated: true,
        leadSales: state.leadSales.map((sale) => {
          if (sale.id === action.payload.id) {
            return action.payload;
          }
          return sale;
        }),
      };
    case UPDATE_SALE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_SALES_AGENT_SALES:
      return {
        ...state,
        loading: true,
      };
    case GET_SALES_AGENT_SALES_SUCCESS:
      return {
        ...state,
        loading: false,
        agentSales: action.payload,
      };
    case GET_SALES_AGENT_SALES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_SALES_AGENT:
      return {
        ...state,
        loading: false,
        error: null,
        saleCreated: false,
        saleUpdated: false,
        saleQA: [],
      };

    case GET_SALE_QA:
      return {
        ...state,
        loading: true,
      };
    case GET_SALE_QA_SUCCESS:
      return {
        ...state,
        loading: false,
        saleQA: action.payload,
      };
    case GET_SALE_QA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default salesAgentReducer;

export const getSalesAgentCampaigns = () => async (dispatch, getState) => {
  dispatch({
    type: GET_SALES_AGENT_CAMPAIGNS,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/salesman/campaigns`,

      config
    );
    dispatch({
      type: GET_SALES_AGENT_CAMPAIGNS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SALES_AGENT_CAMPAIGNS_FAIL,
      payload: err.response,
    });
  }
};
export const getAgentCampaignsLeads =
  (id, limit, page, values) => async (dispatch, getState) => {
    dispatch({
      type: AGENT_CAMPAIGN_LEADS,
    });
    try {
      const { AuthDuck } = getState();

      let S = values ? "&search=" + values.search : "";

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/leads?pageNumber=${page}&limit=${limit}${S}`,

        config
      );
      dispatch({
        type: AGENT_CAMPAIGN_LEADS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: AGENT_CAMPAIGN_LEADS_FAIL,
        payload: err.response,
      });
    }
  };

export const getSaleByLead = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_SALE_BY_LEAD,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/salesman/leads/${id}/sales`,

      config
    );
    dispatch({
      type: GET_SALE_BY_LEAD_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SALE_BY_LEAD_FAIL,
      payload: err.response,
    });
  }
};

export const addNewSale =
  ({ id, values }) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADD_NEW_SALE,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/salesman/leads/${id}/sales`,
        values,
        config
      );
      dispatch({
        type: ADD_NEW_SALE_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: ADD_NEW_SALE_FAIL,
        payload: err.response,
      });
    }
  };

export const UpdateSale =
  ({ values }) =>
  async (dispatch, getState) => {
    dispatch({
      type: UPDATE_SALE,
    });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/salesman/sales/${values.id}`,
        values,
        config
      );
      dispatch({
        type: UPDATE_SALE_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_SALE_FAIL,
        payload: err.response,
      });
    }
  };

export const getSalesAgentSales = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_SALES_AGENT_SALES,
  });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/salesman/sales/agents/${id}`,

      config
    );
    dispatch({
      type: GET_SALES_AGENT_SALES_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SALES_AGENT_SALES_FAIL,
      payload: err.response,
    });
  }
};

export const getSaleQa = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_SALE_QA });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/evaluations/${id}/sale`,
      config
    );

    dispatch({
      type: GET_SALE_QA_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SALE_QA_FAIL,
      payload: err.response,
    });
  }
};
