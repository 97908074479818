import React from 'react';
import { PieChart} from '@mui/x-charts/PieChart';
import { Stack,  } from '@mui/material';

const PieChartt = ({ validRows }) => {

  
  // Initialize status counts
  const statusCounts = {
    New: 0,
    Failed: 0,
    Completed: 0,
    Busy: 0,
    Done: 0,
    'Re-Evaluation Requested': 0,
  };

  // Count occurrences of each status
  validRows.forEach((row) => {
    if (statusCounts[row.status] !== undefined) {
      statusCounts[row.status] += 1;
    }
  });

  const Status = [
    { label: 'New', value: statusCounts.New, color: '#FF6384' },
    { label: 'Failed', value: statusCounts.Failed, color: '#36A2EB' },
    { label: 'Completed', value: statusCounts.Completed, color: '#FFCE56' },
    { label: 'Busy', value: statusCounts.Busy, color: '#4BC0C0' },
    { label: 'Done', value: statusCounts.Done, color: '#9966FF' },
    { label: 'Dispute Requested', value: statusCounts['Dispute Requested'], color: '#FF7D85' },
    { label: 'Re-Evaluation Requested', value: statusCounts['Re-Evaluation Requested'], color: '#FF9F40' },
  ];

  // Normalize function if needed for specific percentage adjustments
  const normalize = (v, v2) => Number.parseFloat(((v * v2) ).toFixed(2));

  const data = [
    ...Status.map((v) => ({
      ...v,
      label: v.label,
      value: normalize(v.value, 1),
    })),
  ];

  return (
    <Stack direction="row" sx={{mr: 15}}>
    <PieChart
      series={[
        {
          data: data,
          arcLabel: (item) => `${item.label}: ${item.value}`, 
          arcLabelMinAngle: 35,
          arcLabelRadius: '60%', 
          highlightScope: { fade: 'global', highlight: 'item' },
          faded: { innerRadius: 40, additionalRadius: -40, color: 'gray' },
        },
      ]}
      
      height={450}
      width={450}
      slotProps={{ legend: { hidden: true } }}
     
      
    />
     {/* Custom Legend */}
     <div style={{ marginTop: "100px" }}>
     {Status.map((status) => (
       <div key={status.label} style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
         <div
           style={{
             width: '15px',
             height: '15px',
             backgroundColor: status.color,
             marginRight: '5px',
           }}
         ></div>
         <span>{status.label}</span>
       </div>
     ))}
   </div>
 </Stack>
  );
};

export default PieChartt;


