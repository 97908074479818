import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTargets } from "./State/TargetsDuck";
import { Button, Container, LinearProgress, Stack } from "@mui/material";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";

const TargetsMain = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTargets());
  }, [dispatch]);

  const place = pathname.split("/")[4];

  const Targets = useSelector((state) => state.Targets);

  const { loading, targetSaveSuccess, editTargetSuccess, targetDeleteSuccess } =
    Targets;

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Container>
      <AlertPopup
        open={targetSaveSuccess}
        severity="info"
        message="Target Saved"
      />
      <AlertPopup
        open={editTargetSuccess}
        severity="info"
        message="Target edited"
      />
      <AlertPopup
        open={targetDeleteSuccess}
        severity="error"
        message="Target Deleted"
      />
      <PageHeader
        title="Targets"
        subheader="Call Center Targets"
        breadcrumbs={[
          { title: "CallCenter", href: "/CDA/CallCenter" },
          { title: "Targets", href: "/CDA/CallCenter/Targets" },
        ]}
      />
      <Stack direction="row" spacing={2}>
        {place === "AddNewTargets" && (
          <Button onClick={() => navigate(-1)}>Back</Button>
        )}
        <Button
          variant={place === "AddNewTargets" ? "contained" : "outlined"}
          color="primary"
          onClick={() => {
            navigate("AddNewTargets");
          }}>
          Set New agent Target
        </Button>
      </Stack>
      <Stack sx={{ mt: 2 }}>
        <Outlet />
      </Stack>
    </Container>
  );
};

export default TargetsMain;
