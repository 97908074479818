import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Container, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import DateSelectorWrapper from "../../../../../../Components/FormComponents/DateSelectorWrapper";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VBCcaptureForm = ({ token }) => {
  const [open, setOpen] = React.useState(false);

  const queryClient = useQueryClient();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const PostVBCTarget = useMutation(async (values) => {
    return await axios.post(
      `${process.env.REACT_APP_API_URL}/VBC/vbc`,
      values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  });

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        VBC Capture Targets
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              VBC Capture Targets
            </Typography>
            <Button
              autoFocus
              color="inherit"
              variant="outlined"
              onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Container>
          <Formik
            initialValues={{
              date: new Date(),
              sales_per_agent: "",
              mip_per_agent_per_sale: "",
              response_sla: "",
              resolve_sla: "",
              qa_score: "",
              ave_customer_service_rating: "",
              case_resolution: "",
            }}
            validationSchema={Yup.object({
              date: Yup.date().required("Required"),
              sales_per_agent: Yup.number().required("Required"),
              mip_per_agent_per_sale: Yup.number().required("Required"),
              response_sla: Yup.number().required("Required"),
              resolve_sla: Yup.number().required("Required"),
              qa_score: Yup.number().required("Required"),
              ave_customer_service_rating: Yup.number().required("Required"),
              case_resolution: Yup.number().required("Required"),
            })}
            onSubmit={(values) => {
              PostVBCTarget.mutate(values, {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["GetVBCTargets"],
                  });
                  setTimeout(() => {
                    handleClose();
                  }, 1000);
                },
              });
            }}>
            {() => {
              return (
                <Form>
                  <Grid
                    spacing={2}
                    container
                    sx={{
                      mt: 10,
                    }}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{ ml: 2, flex: 1 }}
                        variant="h6"
                        component="div">
                        VBC Capture Targets
                      </Typography>
                    </Grid>
                    <Grid item xs={6} xl={6}>
                      <DateSelectorWrapper
                        name="date"
                        label="Date"
                        views={["month"]}
                      />
                    </Grid>
                    <Grid item xs={6} xl={6}>
                      <TextfieldWrapper
                        name="sales_per_agent"
                        label="Sales Per Agent"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6} xl={6}>
                      <TextfieldWrapper
                        name="mip_per_agent_per_sale"
                        label="MIP Per Agent Per Sale"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6} xl={6}>
                      <TextfieldWrapper
                        name="response_sla"
                        label="Response SLA"
                        type="number"
                      />
                    </Grid>

                    <Grid item xs={6} xl={6}>
                      <TextfieldWrapper
                        name="resolve_sla"
                        label="Resolve SLA"
                        type="number"
                      />
                    </Grid>

                    <Grid item xs={6} xl={6}>
                      <TextfieldWrapper
                        name="qa_score"
                        label="QA Score"
                        type="number"
                      />
                    </Grid>

                    <Grid item xs={6} xl={6}>
                      <TextfieldWrapper
                        name="ave_customer_service_rating"
                        label="Ave Customer Service Rating"
                        type="number"
                      />
                    </Grid>

                    <Grid item xs={6} xl={6}>
                      <TextfieldWrapper
                        name="case_resolution"
                        label="Case Resolution"
                        type="number"
                      />
                    </Grid>

                    <Grid item xs={12} xl={12}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary">
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>

          <Grid container></Grid>
        </Container>
      </Dialog>
      <AlertPopup
        open={PostVBCTarget.isSuccess}
        message="VBC Target Captured"
        severity="success"
      />
      <AlertPopup
        open={PostVBCTarget.isError}
        message="Failed to Capture VBC Target"
        severity="error"
      />
    </React.Fragment>
  );
};

export default VBCcaptureForm;
