import * as React from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
  Switch,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik, Field } from "formik";
import { Stack } from "@mui/system";

const EditQuestions = ({ editQuestions, question }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        disabled={!question.active}
        color={question.active ? "primary" : "primary"}
      >
        Edit
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle> QA Question </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              id: question.id || "",
              name: question.name || "",
              description: question.description || "",
              question_type: question.question_type || "",
              group_id: question.group_id,
              value: question.value || 0,
              notification_group: question.notification_group || false,
              auto_fail: question.auto_fail || false,
              active: question.active,
            }}
            enableReinitialize={true}
            onSubmit={(values) => {
              console.log(values);
              editQuestions.mutate(values);
              handleClose();
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Stack sx={{ py: 2 }} spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel>Question Type</InputLabel>
                    <Field
                      as={Select}
                      name="question_type"
                      label="Question Type"
                      value={values.question_type}
                      disabled
                      onChange={(event) =>
                        setFieldValue("question_type", event.target.value)
                      }
                    >
                      <MenuItem value="Score">Score Group</MenuItem>
                      <MenuItem value="Text Input">Text Input Group</MenuItem>
                      <MenuItem value="Yes/No">Yes / No Group</MenuItem>
                    </Field>
                  </FormControl>

                  {values.question_type === "Text Input" && (
                    <>
                      <TextfieldWrapper name="name" label="Name" />
                      <TextfieldWrapper
                        name="description"
                        label="Description"
                      />
                    </>
                  )}

                  {values.question_type === "Score" && (
                    <>
                      <TextfieldWrapper name="name" label="Name" />
                      <TextfieldWrapper
                        name="description"
                        label="Description"
                      />
                      <TextfieldWrapper
                        name="value"
                        type="number"
                        step={5}
                        min={0}
                        label="Weight (Number*)"
                      />
                    </>
                  )}

                  {values.question_type === "Yes/No" && (
                    <>
                      <TextfieldWrapper name="name" label="Name" />
                      <TextfieldWrapper
                        name="description"
                        label="Description"
                      />

                      {/* Auto Fail Section */}
                      <Box
                        sx={{
                          border: "0.1px solid #ccc",
                          borderRadius: "5px",
                          padding: "16px",
                        }}
                      >
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          Mark as an Auto Fail question
                        </Typography>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ alignItems: "center" }}
                        >
                          <Typography>No</Typography>
                          <Switch
                            checked={values.auto_fail}
                            onChange={(event) =>
                              setFieldValue("auto_fail", event.target.checked)
                            }
                          />
                          <Typography>Yes</Typography>
                        </Stack>
                        <Typography variant="caption" color="textSecondary">
                          If the question is marked as Auto Fail, a failed
                          status for the evaluation will be updated if the
                          question answer is no.
                        </Typography>
                      </Box>

                      {/* Notification Group Section */}
                      <Box
                        sx={{
                          border: "0.1px solid #ccc",
                          borderRadius: "5px",
                          padding: "16px",
                          mt: 2,
                        }}
                      >
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          Mark as notification Question
                        </Typography>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ alignItems: "center" }}
                        >
                          <Typography>No</Typography>
                          <Switch
                            checked={values.notification_group}
                            onChange={(event) =>
                              setFieldValue(
                                "notification_group",
                                event.target.checked
                              )
                            }
                          />
                          <Typography>Yes</Typography>
                        </Stack>
                        <Typography variant="caption" color="textSecondary">
                          If the question is failed, a notification will be sent
                          to the marked Team Leaders/Managers (whoever is in the
                          notification fields).
                        </Typography>
                      </Box>
                    </>
                  )}
                </Stack>

                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" type="submit" color="secondary">
                    Save
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default EditQuestions;
