import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { Form, Formik } from "formik";
import TextfieldWrapper from "./Components/FormComponents/TextfieldWrapper";
import PageHeader from "./Components/Bits/PageHeader";
import DateSelectorWrapper from "./Components/FormComponents/DateSelectorWrapper";
import RadioGroupWrapper from "./Components/FormComponents/RadioGroupWrapper";

const GoogleVision = () => {
  const [document, setDocument] = useState("");
  const [returnData, setReturnData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setDocument(event.target.files[0]);
  };

  const handleUpload = async () => {
    const data = new FormData();
    data.append("file", document);
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/googleDocument`,
      {
        method: "POST",
        body: data,
      }
    );

    const result = await response.json();
    setReturnData(result);
    setLoading(false);
  };

  return (
    <Container>
      <PageHeader
        title="Google Vision"
        subheader="Upload a document and get the text from it"
      />

      {!document ? (
        <label htmlFor="contained-button-file">
          <Input
            accept="application/pdf"
            id="contained-button-file"
            type="file"
            autoFocus
            onChange={handleFileChange}
          />
          <Button
            variant="contained"
            size="large"
            sx={{ px: 5, py: 2 }}
            startIcon={<FileOpenIcon />}
            component="span">
            Upload
          </Button>
        </label>
      ) : (
        <Button color="warning" onClick={() => setDocument("")}>
          Clear?
        </Button>
      )}

      {document && !returnData && (
        <>
          {loading ? (
            <Button
              variant="contained"
              color="secondary"
              disabled
              onClick={handleUpload}>
              Loading
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleUpload}>
              Submit
            </Button>
          )}
        </>
      )}

      <Grid container>
        {/* <Grid item xs={6}>
            {returnData?.data?.map((item, index) => {
              return (
                <Stack direction="row" key={index}>
                  <ContentItem
                    title={item.type}
                    value={item.mentionText}
                    confidence={item.confidence}
                  />
                </Stack>
              );
            })}
          </Grid> */}
        <Grid item xs={12}>
          {returnData?.data && <ContentForm data={returnData?.data} />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default GoogleVision;

const Input = styled("input")({
  display: "none",
});

const ContentForm = ({ data }) => {
  const findItem = (type) => {
    return data?.find((item) => item.type === type);
  };

  console.log(findItem("IdNumber"));

  return (
    <Formik
      initialValues={{
        IdNumber: findItem("IdNumber")?.mentionText || "",
        SURNAME: findItem("SURNAME")?.mentionText || "",
        firstNames: findItem("firstNames")?.mentionText || "",
        dateJoined: findItem("dateJoined")?.mentionText || "",
        gender: findItem("gender")?.mentionText || "",
        employeeNumber: findItem("employeeNumber")?.mentionText || "",
        employer: findItem("employer")?.mentionText || "",
        maritalStatus: findItem("maritalStatus")?.mentionText || "",
        emailAddress: findItem("emailAddress")?.mentionText || "",
        cellNumber: findItem("cellNumber")?.mentionText || "",
        telephoneNumber: findItem("telephoneNumber")?.mentionText || "",
        postalAddress: findItem("postalAddress")?.mentionText || "",
        postalCode: findItem("postalCode")?.mentionText || "",
        physicalAddress: findItem("physicalAddress")?.mentionText || "",

        spouseNameAndSurname1:
          findItem("spouseNameAndSurname1")?.mentionText || "",
        spouseIdNumberOrDateOfBirth1:
          findItem("spouseIdNumberOrDateOfBirth1")?.mentionText || "",
        spouseRelationship1: findItem("spouseRelationship1")?.mentionText || "",
        spouseNameAndSurname2:
          findItem("spouseNameAndSurname2")?.mentionText || "",
        spouseIdNumberOrDateOfBirth2:
          findItem("spouseIdNumberOrDateOfBirth2")?.mentionText || "",
        spouseRelationship2: findItem("spouseRelationship2")?.mentionText || "",

        childNameAndSurname1:
          findItem("childNameAndSurname1")?.mentionText || "",
        childIdNumberOrDateOfBirth1:
          findItem("childIdNumberOrDateOfBirth1")?.mentionText || "",
        childRelationship1: findItem("childRelationship1")?.mentionText || "",

        childNameAndSurname2:
          findItem("childNameAndSurname2")?.mentionText || "",
        childIdNumberOrDateOfBirth2:
          findItem("childIdNumberOrDateOfBirth2")?.mentionText || "",
        childRelationship2: findItem("childRelationship2")?.mentionText || "",

        childNameAndSurname3:
          findItem("childNameAndSurname3")?.mentionText || "",
        childIdNumberOrDateOfBirth3:
          findItem("childIdNumberOrDateOfBirth3")?.mentionText || "",
        childRelationship3: findItem("childRelationship3")?.mentionText || "",

        childNameAndSurname4:
          findItem("childNameAndSurname4")?.mentionText || "",
        childIdNumberOrDateOfBirth4:
          findItem("childIdNumberOrDateOfBirth4")?.mentionText || "",
        childRelationship4: findItem("childRelationship4")?.mentionText || "",

        physicalCode: findItem("physicalCode")?.mentionText || "",
        bebeficiaryName: findItem("bebeficiaryName")?.mentionText || "",
        beneficarySurname: findItem("beneficarySurname")?.mentionText || "",
        beneficaryIdNumber: findItem("beneficaryIdNumber")?.mentionText || "",
        beneficaryRelationship:
          findItem("beneficaryRelationship")?.mentionText || "",

        extendedNameAndSurname1:
          findItem("extendedNameAndSurname1")?.mentionText || "",
        extendedOrDateOfBirth1:
          findItem("extendedOrDateOfBirth1")?.mentionText || "",
        optionExtended1: findItem("optionExtended1")?.mentionText || "",

        extendedNameAndSurname2:
          findItem("extendedNameAndSurname2")?.mentionText || "",
        extendedOrDateOfBirth2:
          findItem("extendedOrDateOfBirth2")?.mentionText || "",
        optionExtended2: findItem("optionExtended2")?.mentionText || "",

        extendedNameAndSurname3:
          findItem("extendedNameAndSurname3")?.mentionText || "",
        extendedOrDateOfBirth3:
          findItem("extendedOrDateOfBirth3")?.mentionText || "",
        optionExtended3: findItem("optionExtended3")?.mentionText || "",

        extendedNameAndSurname4:
          findItem("extendedNameAndSurname4")?.mentionText || "",
        extendedOrDateOfBirth4:
          findItem("extendedOrDateOfBirth4")?.mentionText || "",
        optionExtended4: findItem("optionExtended4")?.mentionText || "",
      }}
      enableReinitialize={true}
      validateOnChange={false}
      onSubmit={(values) => {
        console.log(values);
      }}>
      {({ values }) => (
        <Form>
          <Card>
            <CardHeader
              title="1."
              subheader="Personal Details of Principal Member"
            />

            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextfieldWrapper name="IdNumber" label="Id Number" />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper name="SURNAME" label="Surname" />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper name="firstNames" label="First Names" />
                </Grid>

                <Grid item xs={6}>
                  <DateSelectorWrapper name="dateJoined" label="Date Joined" />
                </Grid>

                <Grid item xs={12}>
                  <RadioGroupWrapper
                    row
                    name="maritalStatus"
                    label="Marital Status"
                    options={[
                      {
                        label: "Single",
                        value: "single",
                      },
                      {
                        label: "Married",
                        value: "married",
                      },
                      {
                        label: "Divorced",
                        value: "divorced",
                      },
                      {
                        label: "Widowed",
                        value: "widowed",
                      },
                      {
                        label: "Domestic Partner",
                        value: "domestic partner",
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RadioGroupWrapper
                    row
                    name="gender"
                    label="Gender"
                    options={[
                      {
                        label: "Male",
                        value: "M",
                      },
                      {
                        label: "Female",
                        value: "F",
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="employeeNumber"
                    label="Employee Number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper name="employer" label="Employer" />
                </Grid>

                <Grid item xs={12}>
                  <TextfieldWrapper name="emailAddress" label="Email Address" />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper name="cellNumber" label="Cell Number" />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="telephoneNumber"
                    label="Telephone Number"
                  />
                </Grid>
                <Grid item xs={10}>
                  <TextfieldWrapper
                    name="postalAddress"
                    multiline
                    label="Postal Address"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextfieldWrapper name="postalCode" label="Postal Code" />
                </Grid>
                <Grid item xs={10}>
                  <TextfieldWrapper
                    name="physicalAddress"
                    multiline
                    label="Physical Address"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextfieldWrapper name="physicalCode" label="Postal Code" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card>
            <CardHeader
              title="2."
              subheader="Spouses' Details (if applicable)"
            />

            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="spouseNameAndSurname1"
                    label="Spouse Name and Surname"
                  />
                </Grid>

                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="spouseIdNumberOrDateOfBirth1"
                    label="Spouse Id Number Or DOB"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextfieldWrapper
                    name="spouseRelationship1"
                    label="Relationship"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="spouseNameAndSurname2"
                    label="Spouse Name and Surname"
                  />
                </Grid>

                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="spouseIdNumberOrDateOfBirth2"
                    label="Spouse Id Number Or DOB"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextfieldWrapper
                    name="spouseRelationship2"
                    label="Relationship"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              title="3."
              subheader="Children Under 21 (if applicable)"
            />

            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="childNameAndSurname1"
                    label="Child Name and Surname"
                  />
                </Grid>

                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="childIdNumberOrDateOfBirth1"
                    label="Child Id Number Or DOB"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextfieldWrapper
                    name="childRelationship1"
                    label="Child Relationship"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="childNameAndSurname2"
                    label="Child Name and Surname"
                  />
                </Grid>

                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="childIdNumberOrDateOfBirth2"
                    label="Child Id Number Or DOB"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextfieldWrapper
                    name="childRelationship2"
                    label="Child Relationship"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="childNameAndSurname3"
                    label="Child Name and Surname"
                  />
                </Grid>

                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="childIdNumberOrDateOfBirth3"
                    label="Child Id Number Or DOB"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextfieldWrapper
                    name="childRelationship3"
                    label="Child Relationship"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="childNameAndSurname4"
                    label="Child Name and Surname"
                  />
                </Grid>

                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="childIdNumberOrDateOfBirth4"
                    label="Child Id Number Or DOB"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextfieldWrapper
                    name="childRelationship4"
                    label="Child Relationship"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card>
            <CardHeader title="4." subheader="Beneficiary Nomination " />

            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="bebeficiaryName"
                    label="Bebeficiary Name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="beneficarySurname"
                    label="Beneficary Surname"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="beneficaryIdNumber"
                    label="Beneficary Id Number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="beneficaryRelationship"
                    label="Beneficary Relationship"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card>
            <CardHeader
              title="5."
              subheader="Extended Family Members (if applicable)"
            />

            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="extendedNameAndSurname1"
                    label="Name and Surname"
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextfieldWrapper
                    name="extendedOrDateOfBirth1"
                    label="Extended Id Number Or DOB"
                  />
                </Grid>
                <Grid item xs={3}>
                  <RadioGroupWrapper
                    row
                    name="optionExtended1"
                    label="Option"
                    options={[
                      {
                        label: "A",
                        value: "A",
                      },
                      {
                        label: "B",
                        value: "B",
                      },
                      {
                        label: "C",
                        value: "C",
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="extendedNameAndSurname2"
                    label="Name and Surname"
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextfieldWrapper
                    name="extendedOrDateOfBirth2"
                    label="Extended Id Number Or DOB"
                  />
                </Grid>
                <Grid item xs={3}>
                  <RadioGroupWrapper
                    row
                    name="optionExtended2"
                    label="Option"
                    options={[
                      {
                        label: "A",
                        value: "A",
                      },
                      {
                        label: "B",
                        value: "B",
                      },
                      {
                        label: "C",
                        value: "C",
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="extendedNameAndSurname3"
                    label="Name and Surname"
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextfieldWrapper
                    name="extendedOrDateOfBirth3"
                    label="Extended Id Number Or DOB"
                  />
                </Grid>
                <Grid item xs={3}>
                  <RadioGroupWrapper
                    row
                    name="optionExtended3"
                    label="Option"
                    options={[
                      {
                        label: "A",
                        value: "A",
                      },
                      {
                        label: "B",
                        value: "B",
                      },
                      {
                        label: "C",
                        value: "C",
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextfieldWrapper
                    name="extendedNameAndSurname4"
                    label="Name and Surname"
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextfieldWrapper
                    name="extendedOrDateOfBirth4"
                    label="Extended Id Number Or DOB"
                  />
                </Grid>
                <Grid item xs={3}>
                  <RadioGroupWrapper
                    row
                    name="optionExtended4"
                    label="Option"
                    options={[
                      {
                        label: "A",
                        value: "A",
                      },
                      {
                        label: "B",
                        value: "B",
                      },
                      {
                        label: "C",
                        value: "C",
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Button variant="contained" color="secondary" type="submit">
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};
