import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getQuestionnaireCampaignResults } from "./State/QuestionnaireResultsDuck";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

const QuestionnaireResults = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const QuestionnaireResultsDuck = useSelector(
    (state) => state.QuestionnaireResultsDuck
  );
  const { campaignResults, loading, error } = QuestionnaireResultsDuck;

  useEffect(() => {
    dispatch(getQuestionnaireCampaignResults(id));
  }, [dispatch, id]);

  const rows2 = campaignResults?.map((response, index) => {
    return {
      id: index,
      user: response?.questionnaire_user?.User?.name,
      user_id: response?.questionnaire_user?.User?.id_number,
      user_employee_id: response?.questionnaire_user?.User?.employee_no,
      user_department: response?.questionnaire_user?.User?.department,
      campaign_name: response?.questionnaire_campaign?.name,
      campaign_type: response?.questionnaire_campaign?.type,
      TakenAt: `${new Date(response.startTime).toLocaleDateString("en-ZA")}`,
      passed: response?.passed ? "Pass" : "Fail",
      view_survey: response,
    };
  });

  const renderDetailsButton = (response) => {
    return (
      <strong>
        <Button
          color="primary"
          size="small"
          onClick={() => {
            navigate(
              `/CDA/ViewQuestionnaireDetails/${response.row.view_survey.id}`
            );
          }}>
          View Survey
        </Button>
      </strong>
    );
  };

  const columns2 = [
    {
      field: "user",
      headerName: "User",
      width: 120,
    },
    {
      field: "user_id",
      headerName: "User Id Number",
      width: 150,
    },
    {
      field: "user_employee_id",
      headerName: "Employee Id",
      width: 100,
    },
    {
      field: "user_department",
      headerName: "Department",
      width: 100,
    },
    {
      field: "campaign_name",
      headerName: "Q/C Name",
      width: 130,
    },
    {
      field: "campaign_type",
      headerName: "Q/C Type",
      width: 130,
    },
    {
      field: "TakenAt",
      headerName: "Taken At",
      width: 130,
    },
    {
      field: "passed",
      headerName: "Passed",
      width: 60,
    },
    {
      field: "view_survey",
      headerName: "View Survey",
      renderCell: renderDetailsButton,
      width: 120,
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PageHeader
        title="Questionnaire Campaign Results"
        subheader="View your results"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Questionnaires Admin",
            href: `/CDA/questionnaireAdmin`,
          },
          {
            title: "Questionnaire Results",
            href: `CDA/QuestionnaireResults/${id}`,
          },
        ]}
      />

      <div style={{ height: 1000 }}>
        <DataGridPremium
          rows={rows2}
          columns={columns2}
          pageSize={50}
          rowsPerPageOptions={[50]}
          components={{ Toolbar: GridToolbar }}
          checkboxSelection
        />
      </div>
      <AlertPopup severity="error" message={error} open={error} />
    </>
  );
};

export default QuestionnaireResults;
