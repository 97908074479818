import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { Alert, CircularProgress } from "@mui/material";

import axios from "axios";

const validationSchema = yup.object({
  cell_number: yup
    .string()
    .min(10, "Cell number should be 10 digits")
    .max(11, "Cell number have more than 10 digits")
    .matches(/^\d+$/, {
      message: "Cell number should be only numbers",
    })
    .required("Cell number is required"),
});

export default function GetOTP() {
  const { search } = useLocation();
  const cell_number = search.split("=")[1] || "";

  let navigate = useNavigate();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const formik = useFormik({
    initialValues: {
      cell_number: cell_number || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/onboarding/employees/otp/${values.cell_number}`
        );

        if(data.id){
          setLoading(false);
          localStorage.setItem("tempUserId", data.id);
          navigate("/otp/verify");
        }else{
          setError("Something went wrong");
        }

      } catch (error) {
        setLoading(false);
        setError(error);
      }
    },
  });

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        GET OTP
      </Typography>

      <Box noValidate sx={{ mt: 5 }}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="cell_number"
            label="Cell Number"
            name="cell_number"
            autoComplete="cell_number"
            autoFocus
            value={formik.values.cell_number}
            onChange={formik.handleChange}
            error={
              formik.touched.cell_number && Boolean(formik.errors.cell_number)
            }
            helperText={formik.touched.cell_number && formik.errors.cell_number}
          />

          {loading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          )}
        </form>

        {error && (
          <Alert severity="error"> {JSON.stringify(error?.message)}</Alert>
        )}
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Box>
  );
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="textPrimary" align="center" {...props}>
      {"Copyright © "}
      <Link to="https://cdasolutions.co.za"> CDA Solutions</Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
