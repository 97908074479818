import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import { Button, LinearProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import withAuth from "../../../../../Hoc/withAuth";
import { Question } from "./Components/Questions";
import ScoreCardForm from "./Components/ScoreCardForm";
import { useNavigate } from "react-router-dom";

const ViewScoreCard = ({ user }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  useEffect(() => {
    localStorage.removeItem(`ScoreCard${id}`);
  }, [id]);

  const getScoreCard = useQuery([`scoreCards${id}`], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/scorecard/results/${id}`,
      config
    );
  });

  if (getScoreCard.isLoading) {
    return <LinearProgress />;
  }

  if (getScoreCard.isError) {
    return (
      <AlertPopup open={true} message="Error Fetching data" severity="error" />
    );
  }

  const firstTableData = getScoreCard?.data?.data?.data?.cc_score_card_results;
  const secondTableData = getScoreCard?.data?.data?.data?.cc_score_card_totals;
  let introduction_skills = secondTableData[0]?.introduction_skills;
  let presentation_skills = secondTableData[0]?.presentation_skills;
  let use_of_questions = secondTableData[0]?.use_of_questions;
  let objection_handling = secondTableData[0]?.objection_handling;
  let closing_skills = secondTableData[0]?.closing_skills;
  let sales_skill_score = secondTableData[0]?.sales_skill_score;
  let total_score = secondTableData[0]?.total_score;

  let combineQuestionsAndAnswers = (firstTableData, Question) => {
    let combineQuestionsAndAnswers = {};
    let keys = Object.keys(Question).length;
    for (let i = 0; i < keys; i++) {
      combineQuestionsAndAnswers[Object.keys(Question)[i]] = Question[
        Object.keys(Question)[i]
      ].map((question) => {
        let questionData = firstTableData.find(
          (item) => item.question_names === question.title
        );
        return {
          ...question,
          value: questionData?.question_results,
        };
      });
    }
    return combineQuestionsAndAnswers;
  };

  let Data = combineQuestionsAndAnswers(firstTableData, Question);

  return (
    <Formik initialValues={{ questions: { ...Data } }}>
      {(props) => (
        <Form>
          <Button
            onClick={() => {
              navigate("/CDA/CallCenter/Coaching/ListOfScoreCards");
            }}
            sx={{ my: 3 }}
            variant="outlined"
            color="inherit">
            Back to Score Card List
          </Button>
          <ScoreCardForm
            disabled={true}
            values={props.values}
            user={user}
            id={id}
            scores={{
              introduction_skills,
              presentation_skills,
              use_of_questions,
              objection_handling,
              closing_skills,
              sales_skill_score,
              total_score,
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default withAuth(ViewScoreCard);
