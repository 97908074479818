import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import MainAppContainer from "../../../../../../../Components/Containers/MainAppContainer";
import { Grid } from "@mui/material";
import EditContactForm from "./EditContactForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditCompanyContacts({ rmaSurveyCompany }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        color="inherit"
        onClick={handleClickOpen}>
        Edit Company Contacts
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Company Contacts
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <MainAppContainer>
          <Grid sx={{ mt: 4 }} container spacing={2}>
            {rmaSurveyCompany &&
              rmaSurveyCompany.map((contact, index) => {
                return (
                  <Grid key={index} item xl={12} xs={12}>
                    <EditContactForm rmaSurveyCompany={contact} />
                  </Grid>
                );
              })}

            <Grid item xl={12} xs={12}></Grid>
          </Grid>
        </MainAppContainer>
      </Dialog>
    </div>
  );
}
