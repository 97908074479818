import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import withAuth from "../../../../../../../Hoc/withAuth";

const UserProfile = ({ user, userId }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getUser = useQuery({
    queryKey: ["agentEvaluations", userId],
    queryFn: () =>
      axios.get(`${process.env.REACT_APP_API_URL}/profile/${userId}`, config),
  });

  let userData = getUser.data?.data?.data;

  return `${userData?.name} ${userData?.surname}`;
};

export default withAuth(UserProfile);
