import * as React from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Collapse,
  Stack,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";

export default function SelectExistingQuestionGroup({
  selectGroups,
  createSelectedQuestionGroup,
  activeGroups,
}) {
  const [open, setOpen] = React.useState(false);
  const [openQuestions, setOpenQuestions] = React.useState({});
  const [selectedGroups, setSelectedGroups] = React.useState(new Set());
  const [filteredGroups, setFilteredGroups] = React.useState([]);
  const { id } = useParams();

  React.useEffect(() => {
    const activeGroupNames = new Set(activeGroups.map((group) => group.name));
    const selectedGroupNames = new Set(
      Array.from(selectedGroups).map((group) => group.name)
    );

    setFilteredGroups(
      selectGroups.filter(
        (group) =>
          !group.campaign_specific &&
          group.campaign_id !== id &&
          !activeGroupNames.has(group.name) &&
          !selectedGroupNames.has(group.name)
      )
    );
  }, [id, activeGroups, selectedGroups, selectGroups]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedGroups(new Set());
  };

  const handleOpenQuestionClick = (groupId) => {
    setOpenQuestions((prevState) => ({
      ...prevState,
      [groupId]: !prevState[groupId],
    }));
  };

  const handleSelectGroup = (group) => {
    setSelectedGroups((prevState) => {
      const newSet = new Set(prevState);
      if (newSet.has(group.id)) {
        newSet.delete(group.id);
      } else {
        newSet.add(group.id);
      }
      return newSet;
    });
  };

  const handleSubmit = (values) => {
    const selectedGroupsArray = Array.from(selectedGroups).map((groupId) => {
      const group = selectGroups.find((g) => g.id === groupId);
      return {
        ...group,
        campaign_id: id,
        question: group.question.map((question) => ({
          ...question,
        })),
      };
    });

    console.log("Submitted Values:", {
      ...values,
      selectedGroups: selectedGroupsArray,
    });

    createSelectedQuestionGroup.mutate({
      ...values,
      selectedGroups: selectedGroupsArray,
    });

    setSelectedGroups(new Set());
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen}>Select Existing Question Group</Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Select Existing QA Question Group</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ selectedGroups: [] }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Stack sx={{ py: 2 }} spacing={2}>
                  {(() => {
                    const uniqueNames = new Set();
                    return filteredGroups
                      .filter((group) => {
                        if (uniqueNames.has(group.name)) {
                          return false;
                        }
                        uniqueNames.add(group.name);
                        return true;
                      })
                      .map((group) => (
                        <Card key={group.id}>
                          <CardContent
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="h6">{group.name}</Typography>

                            <Box sx={{ display: "flex", gap: 1 }}>
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  handleOpenQuestionClick(group.id)
                                }
                              >
                                {openQuestions[group.id] ? "Collapse" : "View"}
                              </Button>
                              <Button
                                variant="contained"
                                color={
                                  selectedGroups.has(group.id)
                                    ? "secondary"
                                    : "primary"
                                }
                                type="button"
                                onClick={() => handleSelectGroup(group)}
                              >
                                {selectedGroups.has(group.id)
                                  ? "Deselect"
                                  : "Select"}
                              </Button>
                            </Box>
                          </CardContent>

                          <Collapse in={openQuestions[group.id]}>
                            <CardContent>
                              {group.question && group.question.length > 0 ? (
                                <Stack spacing={1}>
                                  {group.question.map((question) => (
                                    <Card key={question.id}>
                                      <CardContent
                                        sx={{
                                          gap: 1,
                                          padding: 0,
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            alignItems: "center",
                                            display: "flex",
                                            ml: 1,
                                          }}
                                        >
                                          {question.name}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                  ))}
                                </Stack>
                              ) : (
                                <Typography>No questions available</Typography>
                              )}
                            </CardContent>
                          </Collapse>
                        </Card>
                      ));
                  })()}
                </Stack>
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={selectedGroups.size === 0}
                  >
                    Submit
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
