import axios from "axios";

const GET_TEMPLATES = "GET_TEMPLATES";
const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
const GET_TEMPLATES_FAILURE = "GET_TEMPLATES_FAILURE";

const GET_SINGLE_TEMPLATE = "GET_SINGLE_TEMPLATE";
const GET_SINGLE_TEMPLATE_SUCCESS = "GET_SINGLE_TEMPLATE_SUCCESS";
const GET_SINGLE_TEMPLATE_FAILURE = "GET_SINGLE_TEMPLATE_FAILURE";

const ADD_TEMPLATES = "ADD_TEMPLATES";
const ADD_TEMPLATES_SUCCESS = "ADD_TEMPLATES_SUCCESS";
const ADD_TEMPLATES_FAILURE = "ADD_TEMPLATES_FAILURE";

const DELETE_TEMPLATES = "DELETE_TEMPLATES";
const DELETE_TEMPLATES_SUCCESS = "DELETE_TEMPLATES_SUCCESS";
const DELETE_TEMPLATES_FAILURE = "DELETE_TEMPLATES_FAILURE";

const EDIT_TEMPLATES = "EDIT_TEMPLATES";
const EDIT_TEMPLATES_SUCCESS = "EDIT_TEMPLATES_SUCCESS";
const EDIT_TEMPLATES_FAILURE = "EDIT_TEMPLATES_FAILURE";

export const RESET_TEMPLATES = "RESET_TEMPLATES";

const defaultState = {
  templates: [],
  template: {},
  isLoading: false,
  isLoaded: false,
  isError: false,
  error: null,
};

export default function AllTemplates(state = defaultState, action) {
  switch (action.type) {
    case GET_TEMPLATES:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        error: null,
      };
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        error: null,
        templates: action.payload,
      };
    case GET_TEMPLATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: true,
        error: action.payload,
      };

    case GET_SINGLE_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        error: null,
      };
    case GET_SINGLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        error: null,
        template: action.payload,
      };
    case GET_SINGLE_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: true,

        error: action.payload,
      };

    case ADD_TEMPLATES:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        error: null,
      };
    case ADD_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        addTemplateSuccess: true,
        error: null,
        template: { ...action.payload },
      };
    case ADD_TEMPLATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        delete: false,
        isError: true,
        error: action.payload,
      };
    case DELETE_TEMPLATES:
      return {
        ...state,
        isLoading: true,
        deleted: false,
        isLoaded: false,
        isError: false,
        error: null,
      };
    case DELETE_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        deleted: true,
        isError: false,
        error: null,
        templates: state.templates.filter((item) => item.id !== action.payload),
      };
    case DELETE_TEMPLATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        deleted: false,
        isLoaded: false,
        isError: true,
        error: action.payload,
      };
    case EDIT_TEMPLATES:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isError: false,
        error: null,
      };
    case EDIT_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isError: false,
        error: null,
        templates: state.templates.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case EDIT_TEMPLATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: true,
        error: action.payload,
      };

    case RESET_TEMPLATES:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: false,
        error: null,
        deleted: false,
        addTemplateSuccess: false,
      };

    default:
      return state;
  }
}

export const GetTemplates = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TEMPLATES,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/RMA/template`,
      config
    );

    dispatch({
      type: GET_TEMPLATES_SUCCESS,
      payload: data.data,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: GET_TEMPLATES_FAILURE,
      payload: error,
    });
  }
};

export const GetSingleTemplate = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_SINGLE_TEMPLATE,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/RMA/template/${id}`,
      config
    );

    dispatch({
      type: GET_SINGLE_TEMPLATE_SUCCESS,
      payload: data.data,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: GET_SINGLE_TEMPLATE_FAILURE,
      payload: error,
    });
  }
};

export const addNewTemplate = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_TEMPLATES,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/RMA/template`,
      formData,
      config
    );

    dispatch({
      type: ADD_TEMPLATES_SUCCESS,
      payload: data.data,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: ADD_TEMPLATES_FAILURE,
      payload: error,
    });
  }
};

export const deleteTemplate = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_TEMPLATES,
    });

    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/RMA/template/${id}`,
      config
    );

    dispatch({
      type: DELETE_TEMPLATES_SUCCESS,
      payload: data.data,
    });

    // Storing the User Info in the local storage
  } catch (error) {
    dispatch({
      type: DELETE_TEMPLATES_FAILURE,
      payload: error,
    });
  }
};
