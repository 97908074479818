import { Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import FeatureCard from "../../Components/Containers/FeatureCard";
import BusinessIcon from "@mui/icons-material/Business";
import withAuth from "../../Hoc/withAuth";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import CelebrationIcon from "@mui/icons-material/Celebration";
import CDADashboard from "./CDADashboard";
import RmaDashBoard from "./RmaDashBoard";
import WelcomeCard from "./Components/WelcomeCard";

const AdminDash = ({ user, companyDetails }) => {
  if (user.userType === "admin") {
    return (
      <>
        <WelcomeCard user={user} companyDetails={companyDetails} />
        <CDADashboard />
        <RmaDashBoard />
        <Stack sx={{ my: 4 }}>
          <Typography variant="h5">Admin</Typography>
          <Divider />
        </Stack>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <FeatureCard
              admin={true}
              Icon={SupervisedUserCircleIcon}
              title="User Management"
              link="/UserManagement"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <FeatureCard
              admin={true}
              Icon={BusinessIcon}
              title="Companies Management"
              link="/CompaniesManagement"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <FeatureCard
              admin={true}
              Icon={CelebrationIcon}
              title="Employees Party Planning"
              link="/Employees"
            />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(AdminDash);
