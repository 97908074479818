import React, { useEffect } from "react";
import MainAppContainer from "../../../../../../../Components/Containers/MainAppContainer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Select,
  Stack,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  TextField,
} from "@mui/material";
import SurveyQuestion from "./SurveyQuestion";
import SurveyRadioQuestion from "./SurveyRadioQuestion";
import SurveySlider from "./SurveySlider";
// import RmaSurveyCompanyForm from "./RmaSurveyCompanyForm";
import withAuth from "../../../../../../../Hoc/withAuth";
import {
  completeSurvey,
  getCompletedSurveys,
  getSurveyUser,
} from "../../State/SurveyUsersDuck";
// import AddSurveyCompany from "./AddSurveyCompany";
// import CompletedUserWSurveys from "./CompletedUserWSurveys";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";
import SurveyTextField from "./SurveyTextField";
import {
  // getRmaSurveyCompanies,
  getSurveyById,
  getSurveyQuestions,
  // getSurveyResponsesByCompanyId,
  getSurveyContacts,
} from "../../State/SurveysDuck";
import AddSurveyContact from "./AddSurveyContact";

// Survey questions are in the form of an array of objects
// Each object has a question, type, and id
// The question type is either "radio" or "slider"
// The question type is used to determine which component to use

const TakeSurvey = () => {
  // Get the survey id from the url
  // const { id, companyId } = useParams();
  const { id, contactId } = useParams();

  const [formErrors, setFormErrors] = React.useState([]);

  const [campaignError, setCampaignError] = React.useState([]);

  const dispatch = useDispatch();

  // Get the survey and questions by id
  useEffect(() => {
    dispatch(getSurveyById(id));
    dispatch(getSurveyQuestions(id));
    // dispatch(getRmaSurveyCompanies(companyId));
    dispatch(getSurveyUser());
    dispatch(getCompletedSurveys());
    // dispatch(getSurveyResponsesByCompanyId(companyId));
    dispatch(getSurveyContacts(id));
  }, [dispatch, id]);
  // }, [dispatch, id, companyId]);

  // Get the survey from the redux store
  const Surveys = useSelector((state) => state.Surveys);
  const {
    loading,
    surveyCampaign,
    surveyQuestions,
    // rmaSurveyCompany,
    // companySurveys,
    contactUpdateSuccess,
    contactAdded,
    surveyContacts,
  } = Surveys;

  // console.log(Surveys);

  const UserSurveys = useSelector((state) => state.UserSurveys);
  const { surveyUser, completedSurveySuccess } = UserSurveys;

  const [QuestionAnswers, setQuestionAnswers] = React.useState([]);

  const [selected, setSelected] = React.useState("");
  const [startSurvey, setStartSurvey] = React.useState(false);

  // set Survey questions to useState
  useEffect(() => {
    if (surveyQuestions?.length > 0) {
      setQuestionAnswers(
        surveyQuestions.map((question) => ({
          question_id: question.id,
          answer_id: "",
          _value: null,
          // required: true,
          required: question.question_dependencies ? false : true,
          dependency_question_id: question?.question_dependencies?.question_id,
          dependency_answer_id: question?.question_dependencies?.answer_id,
        })),
      );
    }
  }, [surveyQuestions]);

  const [data, setData] = React.useState({
    Alt_No: "",
    COID_Claims: "",
    COID_Payout: "",
    Company_Name: "",
    Contact_Name: "",
    Designation: "",
    Email: "",
    Member_No: "",
    Mobile_No: "",
    No_of_employees: "",
    Premium: "",
    note1: "",
    note2: "",
    note3: "",
  });

  // Call Post to Api with redux action (SurveyUsersDuck)
  const handleSubmit = (event) => {
    setFormErrors([]);
    setCampaignError([]);

    // set variable questionPayload to QuestionAnswers where the answer_id has a value
    const questionPayload = QuestionAnswers.filter(
      (question) => question.answer_id !== "",
    );

    const payload = {
      survey_user_id: surveyUser?.id,
      survey_contact_id: selected,
      survey_note_1: data.note1,
      survey_note_2: data.note2,
      survey_note_3: data.note3,
      survey_campaign_id: id,
      QuestionAnswers: questionPayload,
    };

    let errors = [];

    // Validation
    // if (data.company === "") {
    //   setCampaignError([...campaignError, "Company is required"]);

    //   return;
    // }
    // if (data.contact === "") {
    //   setCampaignError([...campaignError, "Contact is required"]);
    //   return;
    // }

    if (data.name === "") {
      setCampaignError([...campaignError, "Contact is required"]);
      return;
    }

    // setQuestionAnswers((prev) => {
    //   if (!prev) {
    //     return prev;
    //   }
    //   prev.map((answer) => {
    //     if (answer.required) {
    //       return answer;
    //     }
    //     const dependentQuestion = surveyQuestions.find(
    //       (question) =>
    //         question.id === answer.question_id &&
    //         question.question_dependencies,
    //     );
    //     if (!dependentQuestion) {
    //       return answer;
    //     }
    //     const dependentAnswer = prev.find(
    //       (answer) =>
    //         answer.question_id ===
    //         dependentQuestion.question_dependencies.question_id,
    //     );
    //     if (
    //       dependentAnswer.answer_id ===
    //       dependentQuestion.question_dependencies.answer_id.toString()
    //     ) {
    //       return { ...answer, required: true };
    //     }
    //     return answer;
    //   });
    // });

    QuestionAnswers.forEach((answer) => {
      if (answer._value < 1 && answer.answer_id === "" && answer.required) {
        errors.push({ question_id: answer.question_id });
        return;
      }
      if (
        answer.answer_id === "" &&
        answer.required === false &&
        answer.dependency_question_id &&
        answer.dependency_answer_id
      ) {
        const dependentQuestion = QuestionAnswers.find(
          (answer2) =>
            answer2.question_id === answer.dependency_question_id &&
            answer2.answer_id === answer.dependency_answer_id.toString(),
        );
        if (dependentQuestion) {
          errors.push({ question_id: answer.question_id });
          return;
        }
      }
    });

    // QuestionAnswers.forEach((answer) => {
    //   if (answer.answer_id === 10000 && answer.notes === null) {
    //     errors.push({ question_id: answer.question_id });
    //     return;
    //   }
    // });

    setFormErrors(errors);

    // console.log(payload);

    if (errors.length === 0 && campaignError.length === 0) {
      dispatch(completeSurvey(payload));
      setTimeout(() => {
        window.location.reload();
      }, 500);

      return;
    }
  };

  let selectedContactId = "";
  // if surveyContacts and contactId is set, set the selected value of selected to the contactId
  if (surveyContacts.length > 0 && contactId && selected === "") {
    // console.log("surveyContacts", surveyContacts);
    selectedContactId =
      surveyContacts &&
      surveyContacts?.find((contact) => contact.id.toString() === contactId);
    if (selectedContactId) {
      setSelected(selectedContactId.id);
    }
  }

  const selectedContact =
    surveyContacts.length > 0 && selectedContactId
      ? selectedContactId
      : surveyContacts &&
        surveyContacts?.find((contact) => contact.id === selected);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <MainAppContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center" gutterBottom>
            Survey: {surveyCampaign?.name}
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            Description: {surveyCampaign?.description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Stack sx={{ p: 2 }}>
              <AddSurveyContact SurveyId={id} />
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Survey Contact
            </InputLabel>
            <Select
              label="Select Survey Contact"
              labelId="demo-simple-select-label"
              value={selected}
              onChange={(event) => setSelected(event.target.value)}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {surveyContacts &&
                surveyContacts.map((contact) => (
                  <MenuItem key={contact.id} value={contact.id}>
                    {contact.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {startSurvey ? (
          <Grid item xs={12}>
            <Card sx={{ mt: 4 }}>
              <CardHeader
                title={`Contact: ${selectedContact?.name}`}
                action={
                  <Stack>
                    {
                      // if selectedContact has a link, display the button else disable it
                      selectedContact?.link ? (
                        <a
                          href={`${selectedContact?.link}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button variant="outlined" color="secondary">
                            Open 1Stream
                          </Button>
                        </a>
                      ) : (
                        <Button variant="outlined" color="secondary" disabled>
                          Open 1Stream
                        </Button>
                      )
                    }
                  </Stack>
                }
              />
              <CardContent>
                <Typography variant="h6" align="center">
                  {selectedContact?.name}
                </Typography>
                <Typography variant="caption" align="center">
                  Add notes to Survey
                </Typography>
                <Stack>
                  <TextField
                    margin="normal"
                    name="note1"
                    multiline
                    maxRows={4}
                    label="Note 1"
                    value={data?.note1}
                    onChange={handleChange}
                  />
                  <TextField
                    margin="normal"
                    name="note2"
                    label="Note 2"
                    multiline
                    maxRows={4}
                    value={data?.note2}
                    onChange={handleChange}
                  />
                  <TextField
                    margin="normal"
                    name="note3"
                    label="Note 3"
                    multiline
                    maxRows={4}
                    value={data?.note3}
                    onChange={handleChange}
                  />
                </Stack>
              </CardContent>
            </Card>

            <Stack spacing={2} sx={{ mt: 3 }}>
              <Button
                color="inherit"
                onClick={() => setStartSurvey(!startSurvey)}
              >
                Cancel Survey
              </Button>
              {selectedContact &&
                surveyQuestions &&
                surveyQuestions
                  .sort((a, b) => a.orderId - b.orderId)
                  .map((question, index) => {
                    if (question.question_type === "radio") {
                      const dependencyMet =
                        !question.question_dependencies ||
                        (question.question_dependencies &&
                          QuestionAnswers.find(
                            (answer) =>
                              answer.question_id ===
                              question.question_dependencies.question_id,
                          ).answer_id ===
                            question.question_dependencies.answer_id.toString());

                      return (
                        dependencyMet && (
                          <Card
                            sx={formErrors.map((error) => {
                              if (error.question_id === question.id) {
                                return { border: "1px solid red" };
                              }
                              return {};
                            })}
                            key={index}
                          >
                            <CardContent>
                              <div>
                                <SurveyRadioQuestion
                                  questionNumber={index + 1}
                                  setQuestionAnswers={setQuestionAnswers}
                                  question={question}
                                  QuestionAnswers={QuestionAnswers}
                                />
                              </div>
                            </CardContent>
                          </Card>
                        )
                      );
                    }
                    if (question.question_type === "slider") {
                      const dependencyMet =
                        !question.question_dependencies ||
                        (question.question_dependencies &&
                          QuestionAnswers.find(
                            (answer) =>
                              answer.question_id ===
                              question.question_dependencies.question_id,
                          ).answer_id ===
                            question.question_dependencies.answer_id.toString());

                      return (
                        dependencyMet && (
                          <Card
                            key={index}
                            sx={formErrors.map((error) => {
                              if (error.question_id === question.id) {
                                return { border: "1px solid red" };
                              }
                              return {};
                            })}
                          >
                            <CardContent>
                              <div>
                                <SurveySlider
                                  questionNumber={index + 1}
                                  QuestionAnswers={QuestionAnswers}
                                  setQuestionAnswers={setQuestionAnswers}
                                  question={question}
                                />
                              </div>
                            </CardContent>
                          </Card>
                        )
                      );
                    }
                    if (question.question_type === "text") {
                      return (
                        <Card
                          key={index}
                          sx={formErrors.map((error) => {
                            if (error.question_id === question.id) {
                              return { border: "1px solid red" };
                            }
                            return {};
                          })}
                        >
                          <CardContent>
                            <div>
                              <SurveyTextField
                                questionNumber={index + 1}
                                QuestionAnswers={QuestionAnswers}
                                setQuestionAnswers={setQuestionAnswers}
                                question={question}
                              />
                            </div>
                          </CardContent>
                        </Card>
                      );
                    }
                    return (
                      <div key={index}>
                        <SurveyQuestion question={question} />
                        <Divider />
                      </div>
                    );
                  })}

              {formErrors.length > 0 && (
                <>
                  {formErrors.map((error) => (
                    <Alert key={error.question_id} severity="error">
                      Question{" "}
                      {surveyQuestions.find(
                        (question) => question.id === error.question_id,
                      ).orderId + 1}{" "}
                      is required
                    </Alert>
                  ))}
                </>
              )}

              {campaignError.length > 0 && (
                <div>
                  <h3>Errors</h3>
                  <ul>
                    {campaignError.map((error, index) => (
                      <li key={index}>{JSON.stringify(error)}</li>
                    ))}
                  </ul>
                </div>
              )}

              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Stack>
          </Grid>
        ) : (
          <>
            {(selectedContact || contactId) && (
              <Button
                sx={{ my: 4 }}
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => setStartSurvey(!startSurvey)}
              >
                Start Survey
              </Button>
            )}{" "}
          </>
        )}
      </Grid>
      <AlertPopup
        severity="success"
        message="Survey Submitted"
        open={completedSurveySuccess}
      />
      <AlertPopup
        severity="success"
        message="Contact Updated!"
        open={contactUpdateSuccess}
      />
      <AlertPopup
        severity="success"
        message="Contact Created!"
        open={contactAdded}
      />
    </MainAppContainer>
  );
};

export default withAuth(TakeSurvey);
