import { Alert, Button, LinearProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import CreateCampaignForm from "./components/CreateCampaignForm";
import DeActivateCampaign from "./components/DeActivateCampaign";

const InboundAdminHome = () => {
  // get our campaigns

  const [viewInactive, setViewInactive] = React.useState(false);

  const getInboundCampaigns = useQuery({
    queryKey: ["getInboundCampaigns"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin?active=true`
      ),
  });
  const getInactiveCampaigns = useQuery({
    queryKey: ["getInactiveCampaigns"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin?active=false`
      ),
    enabled: viewInactive,
  });

  const navigate = useNavigate();

  const createInboundCampaign = useMutation({
    mutationFn: async (data) =>
      await axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin`,
        data
      ),
    onSuccess: () => {
      getInboundCampaigns.refetch();
    },
  });

  const EditInboundCampaign = useMutation({
    mutationFn: async (data) =>
      await axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/${data.id}`,
        data
      ),
    onSuccess: () => {
      getInboundCampaigns.refetch();
    },
  });

  const rows = getInboundCampaigns?.data?.data?.data || [];
  const rows2 = getInactiveCampaigns?.data?.data?.data || [];

  

  const columns = [
    {
      field: "View",
      headerName: "View",

      renderCell: (params) => (
        <Button
          onClick={() => {
            navigate(`/CDA/inbound/admin/${params.row.id}/ViewInboundCampaign`);
          }}>
          View
        </Button>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
    {
      field: "type",
      headerName: "Type",
      width: 200,
    },
    {
      field: "callbi_scorecard",
      headerName: "Callbi Scorecard",
      width: 200,
    },
    {
      field: "active",
      headerName: "Active",
      width: 500,

      renderCell: (params) => (
        <DeActivateCampaign
          editInboundCampaign={EditInboundCampaign}
          campaign={params.row}
        />
      ),
    },
  ];

  if (getInboundCampaigns.isLoading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <PageHeader
        title="QA Inbound Admin"
        subheader="Manage your QA Inbound campaigns"
        breadcrumbs={[
          { title: "Home", href: "/" },
          { title: "QA Inbound Admin", href: "/CDA/inbound/admin" },
        ]}
      />

      {getInboundCampaigns.isError && (
        <Alert severity="error">Error occurred fetching campaigns</Alert>
      )}

      <Stack sx={{ mb: 4 }} direction="row" justifyContent="flex-end">
        <CreateCampaignForm createInboundCampaign={createInboundCampaign} />
      </Stack>
      <Stack sx={{ height: "60vh" }}>
        <DataGridPremium
          getRowId={(row) => row.id}
          rows={rows}
          columns={columns}
        />
      </Stack>

      <Button
        sx={{ my: 2 }}
        fullWidth
        color="error"
        variant={viewInactive ? "contained" : "outlined"}
        onClick={() => setViewInactive(!viewInactive)}>
        {viewInactive ? "View Active" : "View Inactive"}
      </Button>

      {viewInactive && (
        <Stack sx={{ height: "60vh" }}>
          <DataGridPremium
            getRowId={(row) => row.id}
            rows={rows2}
            columns={columns}
          />
        </Stack>
      )}

      <AlertPopup
        open={createInboundCampaign.isError}
        severity="error"
        message="Error creating campaign"
      />
      <AlertPopup
        open={EditInboundCampaign.isError}
        severity="error"
        message="Error editing campaign"
      />
      <AlertPopup
        open={createInboundCampaign.isSuccess}
        severity="success"
        message="Campaign created successfully"
      />
      <AlertPopup
        open={EditInboundCampaign.isSuccess}
        severity="success"
        message="Campaign updated successfully"
      />
    </div>
  );
};

export default InboundAdminHome;
