import {
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";

import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { CreateSurveyRequest } from "../../State/SurveysDuck";
import withAuth from "../../../../../../../Hoc/withAuth";
import MainAppContainer from "../../../../../../../Components/Containers/MainAppContainer";

const CreateSurvey = ({ companyDetails }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const Surveys = useSelector((state) => state.Surveys);
  const { loading, success, error } = Surveys;

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      company_id: companyDetails.companyId,
      client: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("A Name is required"),
      description: Yup.string().required("A Description is required"),
      client: Yup.string().required("A Client is required"),
    }),
    onSubmit: async (values) => {
      dispatch(CreateSurveyRequest(values));
    },
  });

  const navigateOnSuccess = () => {
    setTimeout(() => {
      navigate("/CDA/surveyAdmin");
    }, 500);
  };

  return (
    <MainAppContainer>
      <Grid container>
        <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => navigate(-1)}>
            Back{" "}
          </Button>
          <Typography variant="h4" align="center" my={4}>
            Create Survey
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <Stack>
              <TextField
                margin="normal"
                label="Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && formik.errors.name ? true : false}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                margin="normal"
                label="Description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.description && formik.errors.description
                    ? true
                    : false
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />

              <TextField
                margin="normal"
                label="Client"
                name="client"
                value={formik.values.client}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.client && formik.errors.client ? true : false
                }
                helperText={formik.touched.client && formik.errors.client}
              />

              {loading ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled
                  style={{ marginTop: "20px" }}>
                  Loading...
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "20px" }}>
                  Create Survey
                </Button>
              )}

              {error && <Alert severity="error">{JSON.stringify(error)}</Alert>}
              {success && <Alert severity="success">Survey Created</Alert>}
              {success && navigateOnSuccess()}
            </Stack>
          </form>
        </Grid>
      </Grid>
    </MainAppContainer>
  );
};

export default withAuth(CreateSurvey);
