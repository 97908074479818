import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as yup from "yup";
import { useFormik } from "formik";
import withAuth from "../../../Hoc/withAuth";
import { useDispatch } from "react-redux";
import { CreateClient } from "../StateManager/Clients/ClientsDuck";

const CreateClientForm = ({ user }) => {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      company_name: "",
      industry_segment: "",
      industry_sub_segment: "",
      company_business: "",
      possible_opportunities: "",
      company_size: "",
      user_id: user.id,
    },
    validationSchema: yup.object({
      company_name: yup.string().required("Required"),
      industry_segment: yup.string().required("Required"),
      industry_sub_segment: yup.string().required("Required"),
      company_business: yup.string().required("Required"),
      possible_opportunities: yup.string().required("Required"),
      company_size: yup.string().required("Required"),
      user_id: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      dispatch(CreateClient(values));
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create New Client
      </Button>
      <Dialog maxWidth="md" fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle> Create New Client</DialogTitle>

        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <TextField
              margin="normal"
              label="Company Name"
              name="company_name"
              onChange={formik.handleChange}
              value={formik.values.company_name}
              error={formik.errors.company_name ? true : false}
              helperText={formik.errors.company_name}
              fullWidth
            />
            <TextField
              margin="normal"
              label="Industry Segment"
              name="industry_segment"
              onChange={formik.handleChange}
              value={formik.values.industry_segment}
              error={formik.errors.industry_segment ? true : false}
              helperText={formik.errors.industry_segment}
              fullWidth
            />
            <TextField
              margin="normal"
              label="Industry Sub Segment"
              name="industry_sub_segment"
              onChange={formik.handleChange}
              value={formik.values.industry_sub_segment}
              error={formik.errors.industry_sub_segment ? true : false}
              helperText={formik.errors.industry_sub_segment}
              fullWidth
            />
            <TextField
              margin="normal"
              label="Company Business"
              name="company_business"
              onChange={formik.handleChange}
              value={formik.values.company_business}
              error={formik.errors.company_business ? true : false}
              helperText={formik.errors.company_business}
              fullWidth
            />
            <TextField
              margin="normal"
              label="Possible Opportunities"
              name="possible_opportunities"
              onChange={formik.handleChange}
              value={formik.values.possible_opportunities}
              error={formik.errors.possible_opportunities ? true : false}
              helperText={formik.errors.possible_opportunities}
              fullWidth
            />
            <TextField
              margin="normal"
              label="Company Size"
              name="company_size"
              onChange={formik.handleChange}
              value={formik.values.company_size}
              error={formik.errors.company_size ? true : false}
              helperText={formik.errors.company_size}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit">
              Create Client
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default withAuth(CreateClientForm);
