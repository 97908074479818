import { Chip, LinearProgress, Paper, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import withAuth from "../../../../../Hoc/withAuth";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Stack } from "@mui/system";

import {
  DataGridPremium,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-premium";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { RenderProgress } from "./RenderProgress";

const DevelopmentReports = ({ user }) => {
  const getAllData = useQuery(["getAllData"], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/jira?currentEpic=1&currentTasks=1`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
  });

  function groupByProject(array) {
    return array?.reduce((grouped, item) => {
      const key = item.project;
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(item);
      return grouped;
    }, {});
  }

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );

  return (
    <>
      <Typography align="center" variant="h6">
        Jira Reports
      </Typography>

      <Typography align="center" variant="caption">
        Will add filters soon
      </Typography>

      {getAllData.isLoading ? (
        <LinearProgress />
      ) : (
        <Stack sx={{ mb: 10, mt: 2 }}>
          {getAllData?.data?.data?.data &&
            getAllData?.data?.data?.data?.length > 0 &&
            Object.entries(groupByProject(getAllData?.data?.data?.data)).map(
              (item, index) => {
                console.log(item[1]);
                return (
                  <Stack key={index}>
                    <Accordion
                      expanded={expanded === item[0]}
                      onChange={handleChange(item[0])}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Typography sx={{ width: "33%", flexShrink: 0 }}>
                          {item[0]}
                        </Typography>
                        <Typography sx={{ color: "text.secondary" }}>
                          Click to expand
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box spacing={2} sx={{ width: "100%" }}>
                          <DataGridPremium
                            getDetailPanelHeight={() => "auto"}
                            autoHeight
                            rows={item[1]}
                            pinnedColumns={{
                              left: [GRID_DETAIL_PANEL_TOGGLE_FIELD],
                            }}
                            getDetailPanelContent={getDetailPanelContent}
                            sx={{
                              "& .MuiDataGrid-detailPanel": {
                                overflow: "visible",
                              },
                            }}
                            columns={columns}
                          />
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                );
              }
            )}
        </Stack>
      )}
    </>
  );
};

export default withAuth(DevelopmentReports);

function DetailPanelContent({ row: rowProp }) {
  const apiRef = useGridApiContext();
  const [width, setWidth] = React.useState(() => {
    const dimensions = apiRef.current.getRootDimensions();
    return dimensions.viewportInnerSize.width;
  });

  const handleViewportInnerSizeChange = React.useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions();
    setWidth(dimensions.viewportInnerSize.width);
  }, [apiRef]);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent(
      "viewportInnerSizeChange",
      handleViewportInnerSizeChange
    );
  }, [apiRef, handleViewportInnerSizeChange]);

  return (
    <Stack
      sx={{
        py: 2,
        height: "100%",
        boxSizing: "border-box",
        position: "sticky",
        left: 0,
        width,
      }}
      direction="column">
      <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1 }}>
        <Stack direction="column" spacing={1}>
          <Typography variant="h6">{`Epic #${rowProp.id}`}</Typography>
          <DataGridPremium
            autoHeight
            density="compact"
            columns={[
              {
                field: "type",
                headerName: "Type",

                width: 100,
                editable: false,
                renderCell: (params) => {
                  return (
                    <Typography
                      color={
                        params.value === "Epic"
                          ? "success"
                          : params.value === "Story"
                          ? "primary"
                          : params.value === "Task"
                          ? "default"
                          : params.value === "Sub-Task"
                          ? "default"
                          : "error"
                      }>
                      {params.value}
                    </Typography>
                  );
                },
              },
              {
                field: "summary",
                headerName: "Name",
                minWidth: 400,
                editable: false,
              },
              {
                field: "status",
                headerName: "Status",

                width: 100,
                renderCell: (params) => {
                  return (
                    <Chip
                      color={
                        params.value === "In Progress"
                          ? "success"
                          : params.value === "Done"
                          ? "success"
                          : params.value === "To Do"
                          ? "default"
                          : params.value === "Backlog"
                          ? "default"
                          : "error"
                      }
                      label={params.value}
                    />
                  );
                },
              },
              {
                field: "assignee",
                headerName: "Assignee",

                minWidth: 200,
                editable: false,
              },
              {
                field: "priority",
                headerName: "Priority",

                width: 100,
                editable: false,
                renderCell: (params) => {
                  return (
                    <Chip
                      color={
                        params.value === "Highest"
                          ? "error"
                          : params.value === "Medium"
                          ? "warning"
                          : "success"
                      }
                      variant="outlined"
                      label={params.value}
                    />
                  );
                },
              },
              {
                field: "due_date",
                headerName: "Due Date",
                minWidth: 200,
                editable: false,
                valueGetter: (params) => {
                  if (params.row.due_date === null) {
                    return "Not Set";
                  }
                  return new Date(params.row.due_date).toLocaleDateString();
                },
              },
              {
                field: "adjusted_due_date",
                headerName: "Adjusted Due Date",
                minWidth: 200,
                editable: false,
                valueGetter: (params) => {
                  if (params.row.adjusted_due_date === null) {
                    return "Not Set";
                  }

                  return new Date(
                    params.row.adjusted_due_date
                  ).toLocaleDateString();
                },
              },
            ]}
            rows={rowProp.tasks}
            sx={{ flex: 1 }}
            hideFooter
          />
        </Stack>
      </Paper>
    </Stack>
  );
}

const columns = [
  {
    field: "type",
    headerName: "type",
    minWidth: 100,
    editable: false,
  },
  {
    field: "summary",
    headerName: "Name",

    minWidth: 300,
    editable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    editable: false,
    renderCell: (params) => {
      return (
        <Chip
          color={
            params.value === "In Progress"
              ? "success"
              : params.value === "Done"
              ? "success"
              : params.value === "To Do"
              ? "default"
              : params.value === "Backlog"
              ? "default"
              : "error"
          }
          label={params.value}
        />
      );
    },
  },
  {
    field: "Progress",
    headerName: "Progress",

    width: 300,
    renderCell: (params) => {
      return (
        <RenderProgress
          value={(
            (params.row.completeCount / params.row.taskCount) *
            100
          ).toFixed(2)}
        />
      );
    },
  },

  {
    field: "priority",
    headerName: "Priority",
    width: 130,
    editable: false,
    renderCell: (params) => {
      return (
        <Chip
          color={
            params.value === "Highest"
              ? "error"
              : params.value === "Medium"
              ? "warning"
              : "success"
          }
          variant="outlined"
          label={params.value}
        />
      );
    },
  },
  {
    field: "due_date",
    headerName: "Due Date",

    width: 130,
    editable: false,
    valueGetter: (params) => {
      if (params.row.due_date === null) {
        return "Not Set";
      }
      return new Date(params.row.due_date).toLocaleDateString();
    },
  },
  {
    field: "adjusted_due_date",
    headerName: "Adjusted Due Date",

    width: 130,
    editable: false,
    valueGetter: (params) => {
      if (params.row.adjusted_due_date === null) {
        return "Not Set";
      }

      return new Date(params.row.adjusted_due_date).toLocaleDateString();
    },
  },
  {
    field: "uat_date",
    headerName: "UAT Date",
    minWidth: 200,
    editable: false,
    valueGetter: (params) => {
      if (params.row.uat_date === null) {
        return "Not Set";
      }

      return new Date(params.row.uat_date).toLocaleDateString();
    },
  },
];
