import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import FeatureCard from "../../../../../Components/Containers/FeatureCard";
import MainAppContainer from "../../../../../Components/Containers/MainAppContainer";
import DynamicFormTwoToneIcon from "@mui/icons-material/DynamicFormTwoTone";
import withAuth from "../../../../../Hoc/withAuth";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  editQuestionnaire,
  getCompanyQuestionnaires,
} from "./State/QuestionnairsDuck";
import { Box } from "@mui/system";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PageHeader from "../../../../../Components/Bits/PageHeader";

const Questionnaire = ({ companyDetails }) => {
  const dispatch = useDispatch();

  const [showDisabled, setShowDisabled] = React.useState(false);

  useEffect(() => {
    dispatch(getCompanyQuestionnaires(companyDetails.companyId));
  }, [dispatch, companyDetails]);

  const QuestionnairesDuck = useSelector((state) => state.QuestionnairesDuck);

  const { questionnaires, loading, error } = QuestionnairesDuck;

  let enabledQuestionnaires = [];
  let disabledQuestionnaires = [];

  questionnaires?.forEach((element) => {
    if (element.status) {
      enabledQuestionnaires.push(element);
    } else {
      disabledQuestionnaires.push(element);
    }
  });

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItem="center">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItem="center">
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <MainAppContainer>
      <PageHeader
        title="Questionnaires Admin"
        subheader="Manage Company questionnaires"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Questionnaire Admin",
            href: "/CDA/questionnaireAdmin",
          },
        ]}
      />
      <Grid container>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <FeatureCard
            company="CDA Solutions"
            Icon={DynamicFormTwoToneIcon}
            title="Create Questionnaire"
            link="/CDA/questionnaire/Create"
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 3 }}>
        {enabledQuestionnaires.map((questionnaire, index) => (
          <QuestionnaireCard questionnaire={questionnaire} key={index} />
        ))}
      </Grid>

      <Grid container sx={{ mt: 4 }}>
        <Button
          sx={{ mb: 4 }}
          fullWidth
          color="inherit"
          variant="outlined"
          onClick={() => setShowDisabled(!showDisabled)}>
          {showDisabled
            ? "Hide Disabled Questionnaires"
            : "Show Disabled Questionnaires"}
        </Button>

        {showDisabled && (
          <>
            {disabledQuestionnaires.map((questionnaire, index) => (
              <QuestionnaireCard questionnaire={questionnaire} key={index} />
            ))}
          </>
        )}
      </Grid>
    </MainAppContainer>
  );
};

export default withAuth(Questionnaire);

const QuestionnaireCard = ({ questionnaire }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const disableQuestionnaire = (questionnaire) => {
    dispatch(
      editQuestionnaire(questionnaire.id, {
        ...questionnaire,
        status: false,
      })
    );
    window.location.reload();
  };

  const enableQuestionnaire = (questionnaire) => {
    dispatch(
      editQuestionnaire(questionnaire.id, {
        ...questionnaire,
        status: true,
      })
    );
    window.location.reload();
  };

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Card>
        <CardHeader
          title={questionnaire.name}
          subheader={questionnaire.description}
          action={
            <>
              {questionnaire?.status ? (
                <Tooltip title="Disable" aria-label="Disable">
                  <Chip
                    label="Active"
                    variant="outlined"
                    color="primary"
                    onClick={() => disableQuestionnaire(questionnaire)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Enable" aria-label="Disable">
                  <Chip
                    label="Disabled"
                    variant="outlined"
                    color="error"
                    onClick={() => enableQuestionnaire(questionnaire)}
                  />
                </Tooltip>
              )}
            </>
          }
        />
        <CardContent>
          <Stack mt={3} direction="row" justifyContent="space-between">
            <Button
              variant="contained"
              onClick={() =>
                navigate(`/CDA/questionnaire/${questionnaire.id}`)
              }>
              View Questionnaire
            </Button>
            <Button
              sx={{ marginLeft: "auto" }}
              color="warning"
              startIcon={<QueryStatsIcon />}
              onClick={() =>
                navigate(`/CDA/QuestionnaireResults/${questionnaire.id}`)
              }>
              View Results
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};
