import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const QaHistoryTable = ({ questions }) => {
  return (
    <>
      {questions?.map((question, index) => {
        return (
          <Card key={index}>
            <CardHeader title={question.question} />
            <CardContent>
              <RadioGroup
                // onChange={handleAdminAnswerChange}
                value={question?.qa_evaluation_results[0]?.value}
                disabled
                aria-labelledby="demo-radio-buttons-group-label"
                name={question.id}>
                {question.qa_queue_question_answers.map((answer, index) => {
                  return (
                    <React.Fragment key={answer.id}>
                      <FormControlLabel
                        id={answer.id}
                        value={answer.value}
                        control={<Radio />}
                        label={answer.answer}
                      />
                    </React.Fragment>
                  );
                })}
              </RadioGroup>
              <Stack>
                <Typography variant="h6" gutterBottom color="GrayText">
                  Comment
                </Typography>
                <Typography variant="body1">
                  {question?.qa_evaluation_results[0]?.comment}
                </Typography>
              </Stack>
              <Divider sx={{ my: 1 }} />
              <Stack direction="row">
                {/* {answer.value <= 0.5 && answer.qa_queues_question.auto_fail && (
                <Chip variant="outlined" label="Auto Fail" color="error" />
              )} */}
                {question.call_back_request && (
                  <Chip
                    variant="outlined"
                    label="call back request"
                    color="warning"
                  />
                )}
                {question.auto_fail && (
                  <Chip variant="outlined" label="Auto Fail" color="warning" />
                )}
                {question.compliance && (
                  <Chip variant="outlined" label="compliance" />
                )}
                {question.product_knowledge && (
                  <Chip variant="outlined" label="product_knowledge" />
                )}
                {question.professionalism && (
                  <Chip variant="outlined" label="professionalism" />
                )}
                {question.sales_skills && (
                  <Chip variant="outlined" label="sales_skills" />
                )}
                {question.tcf && <Chip variant="outlined" label="tcf" />}
              </Stack>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};

export default QaHistoryTable;
