import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/material";
import withAuth from "../../../../../../Hoc/withAuth";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import SelectUserComponent from "../../../../../../Components/Bits/SelectUserComponent";

const CreateNewCoachingScoreCard = ({ user }) => {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addScoreCard = useMutation({
    mutationFn: (data) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/scorecard`,
        data
      );
    },
  });

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Create New Score Card
      </Button>
      <Dialog maxWidth="md" fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle>New Score Card</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              agent_id: "",
              team_lead_id: "",
              user: "",
            }}
            onSubmit={(values) => {
              let data = {
                agent_id: values.user.id,
                team_lead_id: user.id,
              };

              addScoreCard.mutate(data, {
                onSuccess: (data) => {
                  navigate(
                    `/CDA/CallCenter/Coaching/NewScoreCardForm/${data.data.data.id}?isNew=true`
                  );
                },
              });
              handleClose();
            }}>
            {() => {
              return (
                <Form>
                  <Stack sx={{ my: 4 }} spacing={2}>
                    <SelectUserComponent
                      module="call-center-user"
                      label="Select User"
                      name="user"
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color="primary" variant="contained" type="submit">
                      Continue
                    </Button>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withAuth(CreateNewCoachingScoreCard);
