import { Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Formik, Form } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import withAuth from "../../../../../Hoc/withAuth";
import { Question } from "./Components/Questions";
import ScoreCardForm from "./Components/ScoreCardForm";
import { PersistFormikValues } from "formik-persist-values";

const NewScoreCardForm = ({ user }) => {
  const { id } = useParams();

  const navigate = useNavigate();

  let config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  // const ValidationSchema = Yup.object().shape({
  //   sampleFile: Yup.array()
  //     .min(1, "At least one file is required")
  //     .required("Audio file is required"),
  // });

  const addScoreCard = useMutation({
    mutationFn: async (data) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/scorecardresults`,
        data,
        config
      );
    },
  });

  let LocalStorage = JSON.parse(localStorage.getItem(`${id}`));

  let introduction_skills = 0;
  let presentation_skills = 0;
  let use_of_questions = 0;
  let objection_handling = 0;
  let closing_skills = 0;
  let sales_skill_score = 0;
  let total_score = 0;

  const calculateSectionPercentage = (data) => {
    let section = 0;
    data.forEach((question) => {
      section += parseInt(question.value);
    });

    return (section = ((section / (data.length * 4)) * 100).toFixed(2));
  };

  const handleOnSubmit = async (values) => {
    let data = [
      ...values.questions.section1,
      ...values.questions.section2,
      ...values.questions.section3,
      ...values.questions.objection_handling,
      ...values.questions.closing_skills,
      ...values.questions.section6,
    ];

    let resultsData = data.map((item) => {
      return {
        question_names: item.title,
        question_results: item.value,
        score_card_id: id,
      };
    });

    let totals = {
      score_card_id: id,
      introduction_skills,
      presentation_skills,
      use_of_questions,
      objection_handling,
      closing_skills,
      sales_skill_score,
      total_score,
    };
    addScoreCard.mutate(
      {
        resultsData,
        totals,
      },
      {
        onSuccess: () => {
          // clear item from local storage
          localStorage.removeItem(`ScoreCard${id}`);

          setTimeout(() => {
            navigate(`/CDA/CallCenter/Coaching/ViewScoreCard/${id}`);
          }, 1000);
        },
      }
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          questions: { ...Question },
          sampleFile: [],
          selectedFiles: [],
        }}
        // validationSchema={ValidationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          handleOnSubmit(values);
        }}>
        {(props) => {
          introduction_skills = calculateSectionPercentage(
            props?.values?.questions?.section1
          );

          presentation_skills = calculateSectionPercentage(
            props?.values?.questions?.section2
          );
          use_of_questions = calculateSectionPercentage(
            props?.values?.questions?.section3
          );
          objection_handling = calculateSectionPercentage(
            props?.values?.questions?.objection_handling
          );
          closing_skills = calculateSectionPercentage(
            props?.values?.questions?.closing_skills
          );

          sales_skill_score = (
            (Number(use_of_questions) +
              Number(objection_handling) +
              Number(closing_skills)) /
            3
          ).toFixed(2);

          total_score = (
            (Number(introduction_skills) +
              Number(presentation_skills) +
              Number(use_of_questions) +
              Number(objection_handling) +
              Number(closing_skills)) /
            5
          ).toFixed(2);

          return (
            <Form>
              <ScoreCardForm
                values={props.values}
                user={user}
                id={id}
                scores={{
                  introduction_skills,
                  presentation_skills,
                  use_of_questions,
                  objection_handling,
                  closing_skills,
                  sales_skill_score,
                  total_score,
                }}
              />
              {!LocalStorage && (
                <PersistFormikValues name={id} hashInitials={true} />
              )}

              <AlertPopup
                open={addScoreCard.isSuccess}
                message={"Score Card Submitted Successfully"}
              />
              <AlertPopup
                open={addScoreCard.isError}
                message={"Submission Failed"}
              />
              <Button
                sx={{ mt: 4 }}
                fullWidth
                variant="contained"
                color="primary"
                type="submit">
                Submit
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default withAuth(NewScoreCardForm);
