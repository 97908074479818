import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import { useSelector } from "react-redux";
// import { useEffect } from "react";
// import { getAgentCampaignsLeads } from "./State/SalesAgentCampaignsDuck";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataGridPremium,
  GridLogicOperator,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
// import { Formik, Form } from "formik";
// import TextfieldWrapper from "../../../../../Components/FormComponents/TextfieldWrapper";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import AgentCaptureLead from "./components/AgentCaptureLead";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import withAuth from "../../../../../Hoc/withAuth";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function QuickSearchToolbar() {
  return (
    <Stack direction="row" justifyContent="space-between">
      <CustomToolbar />

      <GridToolbarQuickFilter
        sx={{ m: 2 }}
        variant="outlined"
        quickFilterParser={(searchInput) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
    </Stack>
  );
}

const AgentCampaignLeads = ({ user }) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [page] = React.useState(0);

  // const dispatch = useDispatch();

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  // const salesAgent = useSelector((state) => state.salesAgent);
  // const { campaignLeadsList, loading } = salesAgent;

  const [rowsPerPage] = React.useState(300000);

  // useEffect(() => {
  //   dispatch(getAgentCampaignsLeads(id, rowsPerPage, page));
  // }, [dispatch, id, rowsPerPage, page]);

  let keys = [];

  const getCampaignSettings = useQuery(["getCampaignSettings", id], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/campaignSettings`,

      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
  });

  const getCampaignById = useQuery([`GetCampaignByIid${id}`], () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
  });

  console.log("getCampaignById", getCampaignById);

  const getLeads = useQuery(
    [`getLeads${id}`],
    () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/leads?pageNumber=${page}&limit=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
    },

    {
      enabled: userInfo.token ? true : false,
    }
  );

  const captureNewLead = useMutation(["captureLead"], (values) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/callCentre/campaigns/${id}/leads`,
      values,
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
  });

  if (!getLeads.isLoading) {
    keys = Object.keys(getLeads?.data?.data?.data?.rows[0] || {});
  }

  const VISIBLE_FIELDS = [
    "_id",
    "external_ref",
    "first_name",
    "surname",
    "mobile_no",
    "id_no",
  ];

  let columns = keys?.map((key) => {
    return {
      field: key,
      headerName: key,
      hide: !VISIBLE_FIELDS.includes(key),
      width: 130,
    };
  });

  // get only VISIBLE_FIELDS from columns

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    () => {
      const model = {};
      columns.forEach((column) => {
        model[column.field] = !column.hide;
      });
      return model;
    }
  );

  const handleRowClick = (params) => {
    navigate(`/CDA/CampaignLeads/Lead/${params.row._id}/lead`);
  };

  if (getLeads.isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <Container>
      <PageHeader
        title="Campaign Leads"
        subheader="Campaign Leads"
        breadcrumbs={[{ title: "Campaign Leads", href: "/" }]}
      />

      <AlertPopup
        open={captureNewLead.isError}
        severity="error"
        message="Something went wrong"
      />

      <AlertPopup
        open={captureNewLead.isSuccess}
        severity="success"
        message="Successfully updated"
      />

      <Typography variant="h6">
        {getCampaignById?.data?.data?.data?.name}
      </Typography>

      <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
        {getCampaignSettings.isFetched &&
          getCampaignSettings?.data?.data?.data?.agent_capture_lead && (
            <AgentCaptureLead
              campaign_id={id}
              captureNewLead={captureNewLead}
            />
          )}
      </Stack>

      {!getLeads?.isLoading > 0 && (
        <div style={{ height: "300vh", width: "100%" }}>
          {getLeads?.data?.data?.data?.rows?.length > 0 ? (
            <DataGridPremium
              onRowClick={handleRowClick}
              rows={getLeads?.data?.data?.data?.rows || []}
              columns={columns}
              loading={getLeads.isLoading}
              slots={{ toolbar: QuickSearchToolbar }}
              getRowId={(row) => row._id}
              columnVisibilityModel={columnVisibilityModel}
              initialState={{
                ...getLeads?.data?.data?.data?.rows.initialState,
                filter: {
                  ...getLeads?.data?.data?.data?.rows.initialState?.filter,
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLogicOperator.Or,
                  },
                },
              }}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
            />
          ) : (
            <h1>No Leads</h1>
          )}
        </div>
      )}
    </Container>
  );
};

export default withAuth(AgentCampaignLeads);
