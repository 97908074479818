import styled from "@emotion/styled";

import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";

const AddCallRecording = ({ user, id }) => {
  const [recording, setRecording] = React.useState(null);

  const queryClient = useQueryClient();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setRecording(file);
  };

  let config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const postRecording = useMutation({
    mutationFn: (data) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/callCentre/callrecordings/${id}/recording`,
        data,
        config
      );
    },
    onSuccess: (data) => {
      console.log(data);
      // navigate(`/CDA/CallCenter/Coaching/ScoreCard/${id}`);
    },
  });

  const handleSave = () => {
    const formData = new FormData();
    formData.append("file", recording);
    console.log(...formData);
    postRecording.mutate(formData, {
      onSuccess: (data) => {
        //    invalidate get call recordings query [`CallRecordings${id}`]
        queryClient.invalidateQueries({ queryKey: [`CallRecordings${id}`] });
      },
    });

    setRecording(null);
  };

  return (
    <Box>
      <AlertPopup
        open={postRecording.isError}
        severity="error"
        message={postRecording.error?.response?.data?.message}
      />

      <AlertPopup
        open={postRecording.isSuccess}
        severity="success"
        message="Recording Uploaded Successfully"
      />

      {recording ? (
        <Box
          sx={{
            p: 2,
            borderColor: "secondary.main",
            borderWidth: "2px",
            borderStyle: "dashed",
          }}>
          <Typography variant="span">{recording?.name}</Typography>
          <Button
            startIcon={<SaveIcon />}
            sx={{ px: 5, py: 2 }}
            size="large"
            color="secondary"
            variant="contained"
            onClick={handleSave}>
            Save
          </Button>
        </Box>
      ) : (
        <label htmlFor="contained-button-file">
          <Input
            accept=".mp3,audio/*"
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <Button
            variant="outlined"
            sx={{ px: 5, py: 2 }}
            startIcon={<MusicVideoIcon />}
            fullWidth
            component="span">
            Upload New Call Recording
          </Button>
        </label>
      )}
    </Box>
  );
};

const Input = styled("input")({
  display: "none",
});

export default AddCallRecording;
