import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  TextField,
  Button,
  Collapse,
  Box,
  List,
  ListItem,
  ListItemText,
  Modal,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import GridDeleteIcon from "@mui/icons-material/Delete";
import withAuth from "../../../../../../Hoc/withAuth";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: 220,
  display: "flex",
  flexDirection: "column",
};

const Notes = ({ evaluation, updateEvaluation, user }) => {
  const [notes, setNotes] = useState("");
  const [openNotes, setOpenNotes] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleNotes = () => {
    const currentNotes = evaluation.notes ? evaluation.notes : [];
    const evalData = {
      id: evaluation.id,
      notes: [
        ...currentNotes,
        {
          id: uuidv4(),
          note: notes,
          date: new Date(),
          createdBy: user.email,
        },
      ],
    };
    updateEvaluation.mutate(evalData);
    setNotes("");
    setModalOpen(false)
  };

  const handleOpenNotesClick = () => {
    setOpenNotes(!openNotes);
  };

  const handleDeleteNote = (index) => {
    const updatedNotes = evaluation.notes.filter((_, i) => i !== index);
    const evalData = {
      id: evaluation.id,
      notes: updatedNotes,
    };
    updateEvaluation.mutate(evalData);
  };

  return (
    <Card sx={{ mt: 1 }} variant="outlined">
      <CardHeader title={`Evaluation ${evaluation.unique_id}`} />

      <CardContent>

        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Box sx={modalStyle}>
            <Stack spacing={1} direction="column" sx={{ mb: 4}}>
            <Typography variant="body1" fontSize={20} sx={{ mb: 4 }}>
                Add Notes
              </Typography>
              <TextField
                label="Enter Notes"
                fullWidth
                multiline
                rows={2}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
              <Stack direction="row" justifyContent="flex-end">
              <Button onClick={() => setModalOpen(false)}>Cancel</Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNotes}
              >
                Save
              </Button>
              </Stack>
            </Stack>
          </Box>
        </Modal>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Card sx={{ flexGrow: 1 }}>
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px",
              }}
            >
              <Typography variant="body1" fontSize={20}>
                General Notes
              </Typography>
              <Stack direction="row">
              
              <Button variant="outlined" onClick={handleOpenNotesClick}>
                {openNotes ? "Collapse Notes" : "View Notes"}
              </Button>
              <Button
                variant="contained"
                fontSize={20}
                sx={{ml:1}}
                onClick={() => setModalOpen(true)}
              >
                Add Note
              </Button>
              </Stack>
            </CardContent>

            {openNotes && (
              <CardContent>
                <Collapse in={openNotes}>
                  <Stack spacing={1}>
                    {evaluation?.notes?.length > 0 ? (
                      evaluation.notes.map((note, index) => (
                        <Box
                          key={note.id}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <List
                            sx={{
                              py: 0,
                              width: "100%",
                              borderRadius: 2,
                              border: "1px solid",
                              borderColor: "divider",
                              backgroundColor: "background.paper",
                            }}
                          >
                            <ListItem>
                              <ListItemText
                                primary={`Note ${index + 1}: ${note?.note}`}
                              />
                              <GridDeleteIcon
                                color="primary"
                                sx={{ cursor: "pointer", ml: 2 }}
                                onClick={() => handleDeleteNote(index)}
                              />
                            </ListItem>
                          </List>
                        </Box>
                      ))
                    ) : (
                      <Typography>No notes available</Typography>
                    )}
                  </Stack>
                </Collapse>
              </CardContent>
            )}
          </Card>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default withAuth(Notes);
