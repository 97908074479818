import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import SelectWrapper from "../../../../../../../Components/FormComponents/Select";
import DateSelectorWrapper from "../../../../../../../Components/FormComponents/DateSelectorWrapper";
import { addCampaignLeadSourceByCampaignId } from "../../State/CampaignLeadSourceDuck";
import { getLeadSourceList } from "../../State/LeadsDuck";
import { useEffect } from "react";

const AddNewCampaignLeadSource = ({ id, campaignLeadSources }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const Leads = useSelector((state) => state.Leads);
  const { allLeadSources, success } = Leads;

  useEffect(() => {
    dispatch(getLeadSourceList());
  }, [dispatch]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // remove inactive lead sources
  let filteredLeadSources = [];
  if (success && allLeadSources.length > 0) {
    filteredLeadSources = allLeadSources.filter((lead_source) => {
      return lead_source.active && lead_source.api_flag;
    });

    // remove lead sources already added to campaign by checking lead_source in campaignLeadSources
    if (campaignLeadSources && campaignLeadSources.length > 0) {
      filteredLeadSources = filteredLeadSources.filter((lead_source) => {
        return !campaignLeadSources.some(
          (campaignLeadSource) =>
            campaignLeadSource.LeadSource.id === lead_source.id,
        );
      });
    }

    // order allLeadSources by updatedAt desc
    filteredLeadSources.sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });
  }

  const validationSchema = Yup.object().shape({
    lead_source: Yup.string().required("Lead source is required"),
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date().required("End date is required"),
    // start_date: Yup.date().when("lead_source", {
    //   is: (value) => {
    //     const selectedLeadSource = filteredLeadSources.find(
    //       (lead) => lead.id === value,
    //     );
    //     return selectedLeadSource && selectedLeadSource.api_flag;
    //   },
    //   then: Yup.date().required("Start date is required"),
    // }),
    // end_date: Yup.date().when("lead_source", {
    //   is: (value) => {
    //     const selectedLeadSource = filteredLeadSources.find(
    //       (lead) => lead.id === value,
    //     );
    //     return selectedLeadSource && selectedLeadSource.api_flag;
    //   },
    //   then: Yup.date().required("End date is required"),
    // }),
  });

  // const DateSelectors = ({ errors, touched }) => {
  //   const { values } = useFormikContext();
  //   const selectedLeadSource = filteredLeadSources.find(
  //     (lead_source) => lead_source.id === values.lead_source,
  //   );
  //   return selectedLeadSource && selectedLeadSource.api_flag ? (
  //     <>
  //       <DateSelectorWrapper label="Start Date" name="start_date" />
  //       {errors.start_date && touched.start_date ? (
  //         <div style={{ color: "red" }}>Invalid start date specified</div>
  //       ) : null}
  //       <DateSelectorWrapper label="End Date" name="end_date" />
  //       {errors.end_date && touched.end_date ? (
  //         <div style={{ color: "red" }}>Invalid end date specified</div>
  //       ) : null}
  //     </>
  //   ) : null;
  // };

  return (
    <div>
      <Button variant="contained" color="info" onClick={handleClickOpen}>
        Add API Source
      </Button>
      <Dialog maxWidth="x2" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Add Lead Source to Campaign</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              lead_source: "",
              start_date: null,
              end_date: null,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              // Convert dates to South African timezone (UTC+2)
              const formatDateToSA = (date) => {
                if (!date) return null;
                const saDate = new Date(date);
                saDate.setHours(saDate.getHours() + 2); // Add 2 hours for SA timezone
                return saDate.toISOString().split("T")[0]; // Get YYYY-MM-DD format
              };

              const formattedValues = {
                ...values,
                start_date: formatDateToSA(values.start_date),
                end_date: formatDateToSA(values.end_date),
              };
              // console.log(id, formattedValues);
              dispatch(addCampaignLeadSourceByCampaignId(id, formattedValues));
              setTimeout(() => {
                handleClose();
              }, 1000);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <Stack sx={{ mt: 3 }} spacing={1}>
                  <SelectWrapper
                    label="Lead Source"
                    name="lead_source"
                    options={
                      success &&
                      filteredLeadSources.map((lead_source) => ({
                        value: lead_source.id,
                        label: lead_source.name,
                      }))
                    }
                  />
                  {errors.lead_source && touched.lead_source ? (
                    <div style={{ color: "red" }}>{errors.lead_source}</div>
                  ) : null}
                  <Divider />
                  <DateSelectorWrapper label="Start Date" name="start_date" />
                  {errors.start_date && touched.start_date ? (
                    <div style={{ color: "red" }}>
                      Invalid start date specified
                    </div>
                  ) : null}
                  <DateSelectorWrapper label="End Date" name="end_date" />
                  {errors.end_date && touched.end_date ? (
                    <div style={{ color: "red" }}>
                      Invalid end date specified
                    </div>
                  ) : null}

                  {/* <DateSelectors errors={errors} touched={touched} /> */}
                </Stack>
                <DialogActions sx={{ mt: 4 }}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddNewCampaignLeadSource;
