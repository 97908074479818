import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import { FormControl, Stack, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { editQuestionnaireUser } from "../State/QuestionnairsDuck";
import { DatePicker } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { enZA } from "date-fns/locale";
import DateAdapter from "@mui/lab/AdapterDateFns";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CreateNotification } from "../../../../../../Redux/Ducks/NotificationDuck";

export default function EditQuestionnaireUser({ user }) {
  const [open, setOpen] = React.useState(false);

  const [complete_by, setCompleteBy] = React.useState(new Date());

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let notification = {
    user_id: user.user_id,
    title: "Questionnaire",
    message: "You can now retake the questionnaire",
    type: "info",
    link: `/CDA/UserQuestionnaires`,
  };

  const formik = useFormik({
    initialValues: {
      can_retake: true,
      complete_by: complete_by,
    },
    validationSchema: Yup.object({
      complete_by: Yup.string().required("A date is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      dispatch(editQuestionnaireUser(user.id, values));
      dispatch(CreateNotification(notification));
      setTimeout(() => {
        resetForm();
        handleClose();
        window.location.reload();
      }, 1000);
    },
  });

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   let values = {
  //     user_id: selectUser,
  //     campaign_id: campaign_id,
  //     complete_by: complete_by,
  //   };
  //   // dispatch(createQuestionnaireUsers(values));
  //   console.log(values);
  //   handleClose();
  // };

  return (
    <div>
      <Button fullWidth onClick={handleClickOpen}>
        Allow to retake
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <Typography sx={{ mt: 3 }} variant="h5" align="center">
          Allow <b>{user.User.name}</b> to retake
        </Typography>
        <>
          <form onSubmit={formik.handleSubmit}>
            <Stack sx={{ p: 3 }}>
              <LocalizationProvider dateAdapter={DateAdapter} locale={enZA}>
                <FormControl margin="normal" fullWidth>
                  <DatePicker
                    minDate={new Date()}
                    margin="normal"
                    label="Complete test by"
                    name="complete_by"
                    value={complete_by}
                    onChange={(date) => setCompleteBy(date)}
                    variant="inline"
                    inputVariant="outlined"
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </LocalizationProvider>
            </Stack>
            <Stack>
              <Button type="submit" variant="contained">
                Save
              </Button>
              <Button color="inherit" onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </form>
        </>
      </Dialog>
    </div>
  );
}
