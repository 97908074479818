import axios from "axios";

const initialState = {
    adVoiceSMSHealth: {},
    adVoiceSMSHealthLoading: false,
    adVoiceSMSHealthError: false,
    adVoiceSMSHealthSuccess: false,

    adVoiceWebhooks: {},
    adVoiceWebhooksLoading: false,
    adVoiceWebhooksError: false,
    adVoiceWebhooksSuccess: false,

    campaignAdVoiceSMSes: [],
    campaignAdVoiceSMSLoading: false,
    campaignAdVoiceSMSError: false,
    campaignAdVoiceSMSSuccess: false,

    campaignAdvoiceSMSFiles:{},
    campaignAdVoiceSMSFilesLoading: false,
    campaignAdVoiceSMSFilesError: false,
    campaignAdVoiceSMSFilesSuccess: false,
}

const GET_ADVOICE_SMS_HEALTH = "GET_ADVOICE_SMS_HEALTH";
const GET_ADVOICE_SMS_HEALTH_SUCCESS = "GET_ADVOICE_SMS_HEALTH_SUCCESS";
const GET_ADVOICE_SMS_HEALTH_ERROR = "GET_ADVOICE_SMS_HEALTH_ERROR";

const GET_ADVOICE_WEBHOOKS = "GET_ADVOICE_WEBHOOKS";
const GET_ADVOICE_WEBHOOKS_SUCCESS = "GET_ADVOICE_WEBHOOKS_SUCCESS";
const GET_ADVOICE_WEBHOOKS_ERROR = "GET_ADVOICE_WEBHOOKS_ERROR";

const GET_CAMPAIGN_ADVOICE_SMSES = "GET_CAMPAIGN_ADVOICE_SMSES";
const GET_CAMPAIGN_ADVOICE_SMSES_SUCCESS = "GET_CAMPAIGN_ADVOICE_SMSES_SUCCESS";
const GET_CAMPAIGN_ADVOICE_SMSES_ERROR = "GET_CAMPAIGN_ADVOICE_SMSES_ERROR";

const GET_CAMPAIGN_ADVOICE_SMS_FILES = "GET_CAMPAIGN_ADVOICE_SMS_FILES";
const GET_CAMPAIGN_ADVOICE_SMS_FILES_SUCCESS = "GET_CAMPAIGN_ADVOICE_SMS_FILES_SUCCESS";
const GET_CAMPAIGN_ADVOICE_SMS_FILES_ERROR = "GET_CAMPAIGN_ADVOICE_SMS_FILES_ERROR";

const ADD_CAMPAIGN_ADVOICE_SMS_FILE = "ADD_CAMPAIGN_ADVOICE_SMS_FILE";
const ADD_CAMPAIGN_ADVOICE_SMS_FILE_SUCCESS = "ADD_CAMPAIGN_ADVOICE_SMS_FILE_SUCCESS";
const ADD_CAMPAIGN_ADVOICE_SMS_FILE_ERROR = "ADD_CAMPAIGN_ADVOICE_SMS_FILE_ERROR";


const campaignAdVoiceSMSReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_ADVOICE_SMS_HEALTH: {
            return {
                ...state,
                adVoiceSMSHealthLoading: true,
            };
        }
        case GET_ADVOICE_SMS_HEALTH_SUCCESS: {
            return {
                ...state,
                adVoiceSMSHealth: action.payload,
            };
        }
        case GET_ADVOICE_SMS_HEALTH_ERROR: {
            return {
                ...state,
                adVoiceSMSHealthError: true,
            };
        }
        case GET_ADVOICE_WEBHOOKS: {
            return {
                ...state,
                adVoiceWebhooksLoading: true,
            };
        }
        case GET_ADVOICE_WEBHOOKS_SUCCESS: {
            return {
                ...state,
                adVoiceWebhooks: action.payload,
            };
        }
        case GET_ADVOICE_WEBHOOKS_ERROR: {
            return {
                ...state,
                adVoiceWebhooksError: true,
            };
        }
        case GET_CAMPAIGN_ADVOICE_SMSES: {
            return {
                ...state,
                campaignAdVoiceSMSLoading: true,
            };
        }
        case GET_CAMPAIGN_ADVOICE_SMSES_SUCCESS: {
            return {
                ...state,
                campaignAdVoiceSMSes: action.payload,
            };
        }
        case GET_CAMPAIGN_ADVOICE_SMSES_ERROR: {
            return {
                ...state,
                campaignAdVoiceSMSError: true,
            };
        }
        case GET_CAMPAIGN_ADVOICE_SMS_FILES: {
            return {
                ...state,
                campaignAdVoiceSMSFilesLoading: true,
            };
        }
        case GET_CAMPAIGN_ADVOICE_SMS_FILES_SUCCESS: {
            return {
                ...state,
                campaignAdVoiceSMSFiles: action.payload,
            };
        }
        case GET_CAMPAIGN_ADVOICE_SMS_FILES_ERROR: {
            return {
                ...state,
                campaignAdVoiceSMSFilesError: true,
            };
        }
        // CREATE

        case ADD_CAMPAIGN_ADVOICE_SMS_FILE: {
            return {
                ...state,
                campaignAdVoiceSMSFilesLoading: true,
            };
        }
        case ADD_CAMPAIGN_ADVOICE_SMS_FILE_SUCCESS: {
            return {
                ...state,
                campaignAdVoiceSMSFiles: [...state.campaignAdvoiceSMSFiles, action.payload],
            };
        }
        case ADD_CAMPAIGN_ADVOICE_SMS_FILE_ERROR: {
            return {
                ...state,
                campaignAdVoiceSMSFilesError: true,
            };
        }

        default: {
            return state;
        }
    }
}

export default campaignAdVoiceSMSReducer;

export const getAdvoiceHealth = () => {
    return async (dispatch, getState) => {
        dispatch({ type: GET_ADVOICE_SMS_HEALTH });
        try {
            const response = await axios.get(`http://localhost:8001/v1/health`);
            dispatch({ type: GET_ADVOICE_SMS_HEALTH_SUCCESS, payload: response.data });
        } catch (e) {
            dispatch({ type: GET_ADVOICE_SMS_HEALTH_ERROR });
        }
    }
}


export const getCampaignAdVoiceSMSes = (campaignId) => {
    return async (dispatch, getState) => {
        dispatch({ type: GET_CAMPAIGN_ADVOICE_SMSES });
        try {
            const response = await axios.get(`/api/campaigns/${campaignId}/advoicesms`);
            dispatch({ type: GET_CAMPAIGN_ADVOICE_SMSES_SUCCESS, payload: response.data });
        } catch (e) {
            dispatch({ type: GET_CAMPAIGN_ADVOICE_SMSES_ERROR });
        }
    }
}
export const getAdvoiceWebhooks = () => {
    return async (dispatch, getState) => {
        dispatch({ type: GET_ADVOICE_WEBHOOKS });
        try {
            const response = await axios.get(`http://localhost:8001/webhooks`);
            dispatch({ type: GET_ADVOICE_WEBHOOKS_SUCCESS, payload: response.data });
        } catch (e) {
            dispatch({ type: GET_ADVOICE_WEBHOOKS_ERROR });
        }
    }
}

export const getCampaignAdVoiceSMSFiles = (campaignId) => {
    return async (dispatch, getState) => {
        dispatch({ type: GET_CAMPAIGN_ADVOICE_SMS_FILES });
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/callCentre/campaigns/${campaignId}/sms/files`);
            dispatch({ type: GET_CAMPAIGN_ADVOICE_SMS_FILES_SUCCESS, payload: response.data });
        } catch (e) {
            dispatch({ type: GET_CAMPAIGN_ADVOICE_SMS_FILES_ERROR });
        }
    }
}

export const uploadCampaignAdVoiceSMSFile = (campaignId, formData) => {
    return async (dispatch, getState) => {
        dispatch({ type: ADD_CAMPAIGN_ADVOICE_SMS_FILE });
        try {

            const { AuthDuck } = getState();

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/callCentre/campaigns/${campaignId}/sms/files`, formData, {
                headers: {
                    Authorization: `Bearer ${AuthDuck.userInfo.token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            dispatch({ type: ADD_CAMPAIGN_ADVOICE_SMS_FILE_SUCCESS, payload: response.data.data });
        } catch (e) {
            dispatch({ type: ADD_CAMPAIGN_ADVOICE_SMS_FILE_ERROR });
        }
    }
}