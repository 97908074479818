import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import AuthDuck from "./Ducks/AuthDuck";
import AdminUserManagementReducer from "./Ducks/AdminUserManagement";
import companyReducer from "./Ducks/AdminCompniesDuck";
import notificationReducer from "./Ducks/NotificationDuck";
import UserProfileReducer from "./Ducks/UserProfileDuck";

import SalaryDeductionsDuck from "../Pages/Companies/Santam/StateManagers/SalaryDeductionsDuck";
import ClientsDuck from "../Pages/CRM/StateManager/Clients/ClientsDuck";
import CRMCampaignDuck from "../Pages/CRM/StateManager/Campaigns/CRMCampaignDuck";
import campaignsDuck from "../Pages/Companies/CDA/CallCenter/CampaignsManagement/State/CampaingsDuck";
import TeamsReducer from "../Pages/Companies/CDA/CallCenter/CampaignsManagement/State/TeamsDuck";
import DispositionsReducer from "../Pages/Companies/CDA/CallCenter/CampaignsManagement/State/DispostionsDuck";
import ClientsReducer from "../Pages/Companies/CDA/CallCenter/CampaignsManagement/State/ClientsDuck";
import LeadsDuck from "../Pages/Companies/CDA/CallCenter/CampaignsManagement/State/LeadsDuck";
import campaignLeadSourceReducer from "../Pages/Companies/CDA/CallCenter/CampaignsManagement/State/CampaignLeadSourceDuck";
import QaDuck from "../Pages/Companies/CDA/CallCenter/QAManagement/State/QaDuck";
import QaTeamsDuck from "../Pages/Companies/CDA/CallCenter/QAManagement/State/QaTeamsDuck";
import campaignQueuesReducer from "../Pages/Companies/CDA/CallCenter/CampaignsManagement/State/CampaignQueuesDuck";
import { qaQuestionsReducer } from "../Pages/Companies/CDA/CallCenter/QAManagement/State/QaQuestionsDuck";
import salesAgentReducer from "../Pages/Companies/CDA/CallCenter/CallCenterAgents/State/SalesAgentCampaignsDuck";
import AgentSalesReducer from "../Pages/Companies/CDA/CallCenter/CallCenterAgents/State/AgentSalesReducer";
import SalesDuck from "../Pages/Companies/CDA/CallCenter/CampaignsManagement/State/SalesDuck";
import campaignPlansReducer from "../Pages/Companies/CDA/CallCenter/CampaignsManagement/State/CampaignPlansDuck";
import campaignAdVoiceSMSReducer from "../Pages/Companies/CDA/CallCenter/CampaignsManagement/State/CampaignAdVoiceSMSDuck";
import smsProvidersReducer from "../Pages/Companies/CDA/CallCenter/CampaignsManagement/State/SmsProviderDuck";
// RMA
import AllTemplates from "../Pages/Companies/RMA/StateManager/FileUploader/TemplatesDuck";
import AllSchemas from "../Pages/Companies/RMA/StateManager/FileUploader/SchemesDuck";
import FileUpload from "../Pages/Companies/RMA/StateManager/FileUploader/FilesDuck";
import QAAgentReducers from "../Pages/Companies/CDA/CallCenter/QaAgent/State/QAAgentReducers";
import QaEvaluationsReducer from "../Pages/Companies/CDA/CallCenter/QAManagement/State/QaEvaluations";
import paySpaceReducer from "./Payspace";
import TargetsDuck from "../Pages/Companies/CDA/CallCenter/CallCenterTargets/State/TargetsDuck";
import absentsReducer from "../Pages/Companies/CDA/CallCenter/CallCenterAbsenteeism/state";

import QuestionnairesDuck from "../Pages/Companies/CDA/CallCenter/Questionnaires/State/QuestionnairsDuck";
import { UsersQuestionnairesDuck } from "../Pages/Companies/CDA/CallCenter/Questionnaires/State/UsersQuestionnairesDuck";
import QuestionnaireResultsDuck from "../Pages/Companies/CDA/CallCenter/Questionnaires/State/QuestionnaireResultsDuck";
import qaCampaignsReducer from "../Pages/Companies/CDA/CallCenter/QAManagement/State/QACampingsDuck";
import SurveyReportsReducer from "../Pages/Companies/CDA/CallCenter/Surveys/State/SurveyReportsDuck";
import SurveyUsersReducer from "../Pages/Companies/CDA/CallCenter/Surveys/State/SurveyUsersDuck";
import SurveyReducer from "../Pages/Companies/CDA/CallCenter/Surveys/State/SurveysDuck";

const reducer = combineReducers({
  AuthDuck,
  adVoice: campaignAdVoiceSMSReducer,
  smsProvidersReducer,
  Profile: UserProfileReducer,
  Notifications: notificationReducer,
  AdminUsers: AdminUserManagementReducer,
  Companies: companyReducer,
  Clients: ClientsReducer,
  Campaigns: campaignsDuck,
  Teams: TeamsReducer,
  Dispositions: DispositionsReducer,
  Targets: TargetsDuck,
  Absenteeism: absentsReducer,
  Leads: LeadsDuck,
  campaignQueues: campaignQueuesReducer,
  campaignPlans: campaignPlansReducer,
  campaignLeadSourceReducer: campaignLeadSourceReducer,
  Qa: QaDuck,
  QaTeamsDuck: QaTeamsDuck,
  qaQuestionsAll: qaQuestionsReducer,
  QACampaigns: qaCampaignsReducer,
  Surveys: SurveyReducer,
  UserSurveys: SurveyUsersReducer,
  SurveyReports: SurveyReportsReducer,
  QuestionnairesDuck,
  UsersQuestionnairesDuck,
  QuestionnaireResultsDuck,

  SalaryDeductionsDuck,
  ClientsDuck,
  CRMCampaignDuck,
  salesAgent: salesAgentReducer,
  AgentSales: AgentSalesReducer,
  sales: SalesDuck,
  QAEvals: QaEvaluationsReducer,
  // RMA
  Templates: AllTemplates,
  Schemas: AllSchemas,
  FileUploader: FileUpload,
  QAAgent: QAAgentReducers,
  payspace: paySpaceReducer,
});

const middleware = [thunk];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : { access_token: null };

const initialState = {
  AuthDuck: { userInfo: userInfoFromStorage, isLoading: false, error: null },
};

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
const Store = createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware)),
);
export default Store;
