import { useState } from "react";
import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import withAuth from "../../../Hoc/withAuth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const OEAttachments = ({
  employee_id,
  Attachments,
  submitted,
  modules,
  handleTabChange,
  disabled,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [mutationError, setMutationError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  if (!employee_id) {
    navigate("/otp");
  }

  const switchTab = (newTabIndex) => {
    handleTabChange(newTabIndex);
  };

  const [show, setShow] = useState(false);
  const [documentName, setDocumentName] = useState("");

  const filteredAttachments = Attachments?.filter(
    (attachment) => attachment.deleted === false
  );

  let count = filteredAttachments?.length;

  const mutation = useMutation({
    mutationFn: (values) => {
      if (values.operation === "insert") {
        return axios.post(
          `${process.env.REACT_APP_API_URL}/onboarding/employees/attachment/`,
          values.formData
        );
      } else if (values.operation === "delete") {
        return axios.put(
          `${process.env.REACT_APP_API_URL}/onboarding/employees/attachment/${values.document_id}`
        );
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["employeeInfo"]);
    },
    onError: async (error) => {
      setMutationError(error.response.data.message);
    },
  });

  const onSaveAttachments = (formData, operation = "insert") => {
    mutation.mutate({ formData, operation });
    setSuccessMessage("Attachment Uploaded Successfully");
  };

  const onDeleteAttachment = (document_id, operation = "delete") => {
    mutation.mutate({ document_id, operation });
    setSuccessMessage("Attachment Deleted Successfully");
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    formData.append("employee_id", employee_id);

    await onSaveAttachments(formData);
  };

  const docs = [
    {
      value: "ID Document",
      label: "ID Document",
    },
    {
      value: "Passport (for non-South African citizens)",
      label: "Passport (for non-South African citizens)",
    },
    {
      value: "Work Permit (for non-South African citizens)",
      label: "Work Permit (for non-South African citizens)",
    },
    {
      value: "Curriculum Vitae",
      label: "Curriculum Vitae",
    },
    {
      value: "Qualification",
      label: "Qualification",
    },
    {
      value: "Matric",
      label: "Matric",
    },
    {
      value: "Other",
      label: "Other",
    },
    {
      value: "",
      label: "None",
    },
  ];

  const hrDocuments = [
    {
      value: "Signed Job Description",
      label: "Signed Job Description",
    },
    {
      value: "Signed Contract of Employment",
      label: "Signed Contract of Employment",
    },
    {
      value: "Medical Aid Application",
      label: "Medical Aid Application",
    },
    {
      value: "Medical Aid Insurance",
      label: "Medical Aid Insurance",
    },
    {
      value: "Signed Salary Structure",
      label: "Signed Salary Structure",
    },
    {
      value: "Signed New Employee Assignments, Confidentiality & Declarations",
      label: "Signed New Employee Assignments, Confidentiality & Declarations",
    },
    {
      value: "Signed FAIS Non-Advice Declaration",
      label: "Signed FAIS Non-Advice Declaration",
    },
    {
      value: "Signed FAIS Declaration",
      label: "Signed FAIS Declaration",
    },
    {
      value: "Signed Letter of Consent DOFA",
      label: "Signed Letter of Consent DOFA",
    },
    {
      value: "Signed Call Centre Natural Representative Addition",
      label: "Signed Call Centre Natural Representative Addition",
    },
    {
      value: "Mandatory Supervision Agreement",
      label: "Mandatory Supervision Agreement",
    },
    {
      value: "Signed Performance Contract",
      label: "Signed Performance Contract",
    },
    {
      value: "Signed Honesty & Integrity Declaration",
      label: "Signed Honesty & Integrity Declaration",
    },
    {
      value: "TCF Test Results",
      label: "TCF Test Results",
    },
    {
      value: "Complaints Process Test Results",
      label: "Complaints Process Test Results",
    },
    {
      value: "Risk & Fraudulent Training Test Results",
      label: "Risk & Fraudulent Training Test Results",
    },
    {
      value: "YES Affidavit",
      label: "YES Affidavit",
    },
    {
      value: "Unemployed Affidavit",
      label: "Unemployed Affidavit",
    },
  ];

  // order hrDocuments alphabetically
  hrDocuments.sort((a, b) => {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  });

  return (
    <Stack spacing={2}>
      <Stack justifyContent={"center"} mb={2} mt={2}>
        <Typography>ATTACHMENTS</Typography>
      </Stack>

      {mutation.isLoading && (
        <AlertPopup
          open={true}
          severity={"info"}
          message={"Uploading Attachment..."}
        />
      )}

      {mutation.isError && (
        <AlertPopup open={true} message={mutation.error} severity={"error"} />
      )}

      {mutation.isSuccess && (
        <AlertPopup open={true} message={successMessage} />
      )}

      {mutationError && (
        <AlertPopup open={true} message={mutationError} severity={"error"} />
      )}

      <form encType="multipart/form-data" id="form" onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Document Name
              </InputLabel>
              <Select
                fullWidth
                name="name"
                disabled={disabled}
                value={documentName}
                onChange={(e) => {
                  setDocumentName(e.target.value);
                  if (e.target.value === "other") {
                    setShow(true);
                  } else {
                    setShow(false);
                  }
                }}
                label="Document Name">
                {(modules?.includes("oe-hr")
                  ? [...hrDocuments, ...docs]
                  : [...docs]
                ).map((document) => {
                  return (
                    <MenuItem key={document.value} value={document.value}>
                      {document.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {show && (
            <Grid item xs={12} md={6}>
              <TextField
                name={"document_name"}
                disabled={disabled}
                placeholder={"Please specify other"}
                label="Please specify other"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"space-between"}>
              <input type="file" name="file" disabled={disabled} />

              <Button
                variant="contained"
                type="submit"
                disabled={disabled}
                color="primary">
                Save
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      {count > 0 ? (
        <TableContainer sx={{ width: "100%" }} aria-label="simple table">
          <Table>
            <TableHead sx={{ backgroundColor: "background.paper" }}>
              <TableRow>
                <TableCell>Document Name</TableCell>
                <TableCell align="center">File Name</TableCell>
                <TableCell align="center">Date Uploaded</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAttachments?.map((document) => (
                <TableRow
                  key={document.id}
                  // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">{document.name}</TableCell>
                  <TableCell align="center">
                    {document.original_file_name}
                  </TableCell>
                  <TableCell align="center">{document.created_at}</TableCell>
                  <TableCell align="center">
                    <Stack direction={"row"} spacing={2}>
                      <Button variant="outlined" color="primary">
                        <Link
                          disabled={!document.name}
                          sx={{ textDecoration: "none" }}
                          href={`${process.env.REACT_APP_API_URL}/onboarding/employees/download/${document.id}`}
                          download>
                          <Typography
                            variant="button"
                            color={document.name ? "inherit" : "GrayText"}>
                            DOWNLOAD
                          </Typography>
                        </Link>
                      </Button>
                      <Button
                        color="error"
                        variant="outlined"
                        disabled={disabled}
                        onClick={() => onDeleteAttachment(document.id)}>
                        Delete
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <Alert severity="warning">No Documents Uploaded</Alert>
        </>
      )}
      <br />
      <Stack direction={"row"} spacing={2} justifyContent="center">
        {modules?.includes("oe-hr") ? (
          <Stack direction={"row"} justifyContent="center" spacing={2}>
            <Button
              variant="contained"
              sx={{
                width: 180,
              }}
              color="primary"
              onClick={() => switchTab(3)}>
              Back
            </Button>

            <Button
              variant="contained"
              type="submit"
              name="submitAction"
              onClick={() => switchTab(5)}
              sx={{
                width: 170,
              }}
              color="primary">
              Next
            </Button>
          </Stack>
        ) : (
          <Stack direction={"row"} justifyContent="center" spacing={2}>
            <Button
              variant="contained"
              sx={{
                width: 180,
              }}
              color="primary"
              onClick={() => switchTab(3)}>
              Back
            </Button>

            <Button
              variant="contained"
              type="submit"
              name="submitAction"
              onClick={() => switchTab(5)}
              sx={{
                width: 170,
              }}
              color="primary">
              Next
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default withAuth(OEAttachments);
