import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { createQuestion } from "../../State/SurveysDuck";

export default function CreateQuestions({ id }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      survey_campaign_id: id,
      question: "",
      question_type: "",
    },
    validationSchema: Yup.object({
      question: Yup.string().required("A Question is required"),
      question_type: Yup.string().required("A Question Type is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      dispatch(createQuestion(values));
      resetForm();
      setTimeout(() => {
        handleClose();
      }, 500);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth variant="outlined" onClick={handleClickOpen}>
        Add Question
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <>
            <Stack sx={{ p: 4 }}>
              <Typography variant="h4" align="center" my={4}>
                Create Question
              </Typography>

              <TextField
                margin="normal"
                label="Question"
                name="question"
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.question && formik.errors.question
                    ? true
                    : false
                }
                helperText={formik.touched.question && formik.errors.question}
              />

              <FormControl fullWidth margin="normal">
                <InputLabel id="select_type">Select Question Type</InputLabel>
                <Select
                  labelId="select_type"
                  id="select_question_type"
                  label="Select Question Type"
                  name="question_type"
                  value={formik.values.question_type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.question_type && formik.errors.question_type
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched.question_type && formik.errors.question_type
                  }>
                  <MenuItem value="radio"> Multi Select</MenuItem>
                  <MenuItem value="slider">Slider (1- 10)</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack>
              <Button sx={{ mt: 4 }} type="submit" variant="contained">
                Submit
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </Stack>
          </>
        </form>
      </Dialog>
    </div>
  );
}
