import { Card, Stack } from "@mui/material";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import withAuth from "../../../../../../Hoc/withAuth";
import BarGraph from "../../../InboundQA/Admin/Evaluations/components/Statistics/BarGraph";
import PieChartt from "../../../InboundQA/Admin/Evaluations/components/Statistics/PieChartt";
import Cards from "../../../InboundQA/Admin/Evaluations/components/Statistics/Cards";

const AgentInboundStatistics = ({ user }) => {
  const userID = user.id;

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getEvaluationByID = useQuery({
    queryKey: ["getEvaluationByID"],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/evaluations/${userID}`,
        config
      ),
  });

  const validRows = getEvaluationByID?.data?.data?.data || [];
  console.log("validRows", validRows);

  return (
    <div>
      <Card variant="outlined" sx={{ mb: 2 }}>
        <Cards validRows={validRows} user={user} />
      </Card>
      <Card variant="outlined">
        <Stack direction="row" justifyContent="space-between">
          <BarGraph validRows={validRows} />
          <PieChartt validRows={validRows} />
        </Stack>
      </Card>
    </div>
  );
};

export default withAuth(AgentInboundStatistics);
