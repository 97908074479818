import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Stack,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React from "react";
import ContentItem from "./ContentItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StageColors } from "./StageColors";

const CallbiResults = ({ callbi_result }) => {
  return (
    <Card>
      <CardHeader
        title={<Typography variant="h6">Callbi Results</Typography>}
        action={
          <Chip
            label={callbi_result.status}
            color={StageColors(callbi_result.status)}
          />
        }
      />
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <ContentItem
            title="Matched Questions"
            value={callbi_result?.matched}
          />
          <ContentItem
            title="Questions Total Questions"
            value={callbi_result?.questions}
          />
        </Stack>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header">
            Failed Questions
          </AccordionSummary>
          <AccordionDetails>
            {callbi_result?.failed_questions?.map((question, index) => (
              <ContentItem key={index} title={index + 1} value={question} />
            ))}
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default CallbiResults;
