import { Button, Checkbox, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import withAuth from "../../../../../Hoc/withAuth";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const AddExistingQuestionToQueue = ({ user }) => {
  const { id } = useParams();

  const getAllQuestions = useQuery(
    {
      queryKey: ["qaQuestions"],
      queryFn: () =>
        axios.get(`${process.env.REACT_APP_API_URL}/qa/questions`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }),
    },
    {
      enabled: !!user.token,
    }
  );

  const QaQueueQuestions = useQuery(
    {
      queryKey: ["getQaQueueQuestions", id],
      queryFn: () =>
        axios.get(
          `${process.env.REACT_APP_API_URL}/qa/queues/${id}/questions`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        ),
    },
    {
      enabled: !!id || !!user.token,
    }
  );

  const postQuestions = useMutation(
    (questions) =>
      axios.post(
        `${process.env.REACT_APP_API_URL}/qa/queues/${id}/questions`,
        questions,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      ),
    {
      onSuccess: () => {
        QaQueueQuestions.refetch();
      },
    }
  );

  const [allQuestions, setAllQuestions] = React.useState([]);

  const [queueQuestions, setQueueQuestions] = React.useState([]);

  useEffect(() => {
    if (getAllQuestions.data && getAllQuestions.data.data.data) {
      setAllQuestions(getAllQuestions.data.data.data);
    }
    if (
      QaQueueQuestions &&
      QaQueueQuestions.data &&
      QaQueueQuestions.data.data.data
    ) {
      setQueueQuestions(QaQueueQuestions.data.data.data);
    }
  }, [getAllQuestions, QaQueueQuestions]);

  // filter out questions that are already in the queue
  const filteredQuestions = allQuestions?.filter((question) => {
    return !queueQuestions?.some((q) => q.question === question.question);
  });

  return (
    <div>
      <AlertPopup
        open={postQuestions.isSuccess}
        message="Questions and Answers added successfully"
        severity="success"
      />

      <TransferList
        postQuestions={postQuestions}
        questions={filteredQuestions}
        queue_question={queueQuestions}
      />
    </div>
  );
};

export default withAuth(AddExistingQuestionToQueue);

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function TransferList({ questions, queue_question, postQuestions }) {
  const [checked, setChecked] = React.useState([]);
  const [edited, setEdited] = React.useState(false);

  const { id } = useParams();

  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  useEffect(() => {
    setLeft(questions);
    setRight(queue_question);
  }, [questions, queue_question]);

  const handleSearchLeft = (e) => {
    const value = e.target.value.toLowerCase();
    const filteredQuestions = questions.filter((question) => {
      return question.question.toLowerCase().includes(value);
    });
    setLeft(filteredQuestions);
  };

  const handleSearchRight = (e) => {
    const value = e.target.value.toLowerCase();
    const filteredQuestions = questions.filter((question) => {
      return question.question.toLowerCase().includes(value);
    });
    setLeft(filteredQuestions);
  };

  const leftChecked = intersection(checked, left);
  // const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setEdited(true);
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  // const handleCheckedLeft = () => {
  //   setEdited(true);
  //   setLeft(left.concat(rightChecked));
  //   setRight(not(right, rightChecked));
  //   setChecked(not(checked, rightChecked));
  // };

  const handleSubmit = () => {
    // Filter Out Questions that are already in the queue
    const filteredQuestions = right
      .filter((question) => {
        return !queue_question?.some((q) => q.question === question.question);
      })
      .map((question) => {
        console.log(question.qa_answers);

        delete question.id;

        return {
          ...question,

          queue_id: id,
          qa_queue_question_answers: question.qa_answers.map((answer) => {
            delete answer.id;
            return {
              ...answer,
            };
          }),
        };
      });

    postQuestions.mutate(filteredQuestions);
    setEdited(false);
  };

  const customList = (items, position) => {
    return (
      <>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Search"
          variant="outlined"
          onChange={position === "right" ? handleSearchRight : handleSearchLeft}
        />

        <Paper>
          <List dense component="div" role="list">
            {items?.map((value, index) => {
              const labelId = value.id;

              return (
                <ListItem
                  key={index}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.question} />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </>
    );
  };

  return (
    <>
      {edited && (
        <Button
          sx={{ mb: 3 }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            handleSubmit();
          }}>
          Submit
        </Button>
      )}

      <Grid container spacing={2}>
        <Grid item xs={5}>
          {customList(left)}
        </Grid>
        <Grid item xs={2}>
          <Grid
            sx={{
              position: "sticky",
              top: "30%",
            }}
            container
            direction="column"
            alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right">
              Add To Queue &gt;&gt;
            </Button>
            {/* <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              color="warning"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left">
              remove from queue &lt;&lt;
            </Button> */}
          </Grid>
        </Grid>
        <Grid item xs={5}>
          {customList(right, "right")}
        </Grid>
      </Grid>
    </>
  );
}
