import React from "react";
import PageHeader from "../../../../../../Components/Bits/PageHeader";
import { Stack, Button, Chip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import withAuth from "../../../../../../Hoc/withAuth";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";


const ViewEvaluationCampaignsManager = ({ user }) => {
  const [viewCompleted, setViewCompleted] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const { data: evaluationData = [] } = useQuery(
    ["getAllEvaluations"],
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/evaluations/${id}/manager`,
        config
      );
      return response?.data.data || [];
    },
    {
      onError: (error) => {
        console.error("Error fetching evaluations:", error);
      },
    }
  );

  const validRows =
    evaluationData?.filter((row) => row.campaign_id === id) || [];

  const completedAndFailedEvaluations = validRows.filter(
    (row) => row.status === "Completed" || row.status === "Done"
  );

  const BusyEvals = validRows.filter(
    (row) => row.status !== "Completed" && row.status !== "Done"
  );

  const columns = [
    {
      field: "View",
      headerName: "View",
      renderCell: (params) => {
        const evalID = params.row.id;

        return (
          <Button
            onClick={() => {
              navigate(`/CDA/inbound/manager/${id}/evaluations/${evalID}`);
            }}
          >
            View
          </Button>
        );
      },
    },
    {
      field: "unique_id",
      headerName: "Unique_ID",
      width: 200,
      valueGetter: (params) => ` ${params.row.unique_id}`,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.status}
            color={
              params.row.status === "Completed"
                ? "secondary"
                : params.row.status === "Busy"
                ? "warning"
                : params.row.status === "New" ||
                  params.row.status === "Re-Evaluate"
                ? "primary"
                : params.row.status === "Failed"
                ? "error"
                : "info"
            }
          />
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      valueGetter: (params) => {
        const date = new Date(params.row.createdAt);
        return date.toLocaleDateString();
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 200,
      valueGetter: (params) => {
        const date = new Date(params.row.updatedAt);
        return date.toLocaleDateString();
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title="QA Inbound User"
        subheader="Manage your QA Inbound evaluations"
        breadcrumbs={[
          { title: "Home", href: "/" },
          { title: "QA Inbound Manager", href: "/CDA/inbound/manager" },
        ]}
      />



      <Stack
        sx={{ mb: 2, justifyContent: "space-between" }}
        direction="row"
        spacing={2}
        justifyContent="flex-end"
      ></Stack>
      <Stack sx={{ height: "100vh" }}>
        <DataGridPremium
          slots={{
            toolbar: GridToolbar,
          }}
          columns={columns}
          getRowId={(row) => row.id}
          rows={BusyEvals}
          initialState={{
            sorting: {
              sortModel: [{ field: "updatedAt", sort: "asc" }],
            },
          }}
        />
      </Stack>
      <Stack>
        <Button
          onClick={() => {
            setViewCompleted(!viewCompleted);
          }}
        >
          View Completed Evaluations
        </Button>
      </Stack>

      {viewCompleted && (
        <Stack sx={{ height: "100vh" }}>
          <DataGridPremium
            rows={completedAndFailedEvaluations}
            columns={columns}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Stack>
      )}
    </div>
  );
};

export default withAuth(ViewEvaluationCampaignsManager);
