import { Button, CircularProgress } from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "../CampaignsManagement/State/CampaingsDuck";
import { useNavigate } from "react-router-dom";

const CampaignLinking = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  let rowsPerPage = 1000;
  let page = 0;

  useEffect(() => {
    dispatch(getCampaigns({ rowsPerPage, page }));
  }, [dispatch, rowsPerPage, page]);

  const Campaigns = useSelector((state) => state.Campaigns);
  const { campaigns, loading } = Campaigns;

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "campaignName", headerName: "Campaign Name", width: 250 },
    { field: "client", headerName: "Client", width: 200 },
    { field: "campaignType", headerName: "Campaign Type", width: 130 },
    {
      field: "manageQaQueues",
      headerName: "Manage QA Queues",
      width: 180,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="warning"
          onClick={() =>
            navigate(
              `/CDA/ManageCampaign/${params.row.manageCampaign}/edit/QaQueueManage`
            )
          }>
          Manage QA Queues
        </Button>
      ),
    },
  ];

  const rows =
    campaigns?.rows && campaigns?.rows?.length > 0
      ? campaigns?.rows?.map((campaign) => {
          return {
            id: campaign.id,

            campaignName: campaign.name,

            client: campaign.cc_client?.name,

            campaignType: campaign.campaign_type,

            manageLeads: campaign.id,

            manageCampaign: campaign.id,
          };
        })
      : [];

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <div style={{ height: "70vh", width: "100%" }}>
        <DataGridPremium
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
        />
      </div>
    </div>
  );
};

export default CampaignLinking;
