import axios from "axios";

import { AUTH_ERROR, AUTH_REQUEST, LOGIN } from "../ActionNames/AllActions";

// initial state of this duck
const initialState = {
  userInfo: {
    access_token: null,
    user: null,
    error: null,
    isLoggedIn: false,
  },
  isLoading: false,
  error: null,
};

export const MS_LOGIN_REQUEST = "MS_LOGIN_REQUEST";
export const MS_LOGIN_SUCCESS = "MS_LOGIN_SUCCESS";
export const MS_LOGIN_FAIL = "MS_LOGIN_FAIL";

// This is the Reducer that will be used in the Store.
// It will be used to update the state of the application.
// You dispatch actions to the reducer, and the reducer will update the state.
// NOTE : The cases in the switch statement are the actions that will be dispatched.
// THE ACTIONS MUST BE UNIQUE AND MUST BE CAPITALIZED.
// The reducer will then return the new state to the store.
// The store will then pass the new state to the components.

//THIS IS THE REDUCER
const AuthDuck = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_REQUEST:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          isLoggedIn: true,
        },
        isLoading: true,
      };

    case LOGIN:
      return {
        ...state,
        userInfo: { ...action.payload, isLoggedIn: true },
        isLoading: false,
      };
    case AUTH_ERROR:
      return {
        ...state,
        userInfo: {
          isLoading: false,
          isLoggedIn: false,
        },
        isLoading: false,

        error: action.payload,
      };

    case MS_LOGIN_REQUEST:
      return {
        ...state,
        MsLogin: true,
      };

    case MS_LOGIN_SUCCESS:
      return {
        ...state,
        MsLogin: false,
      };
    case MS_LOGIN_FAIL:
      return {
        ...state,
        MsLogin: false,
      };
    case "LOGOUT":
      return {
        initialState,
      };

    default:
      return state;
  }
};

export default AuthDuck;

// This is the action creator
// You will call this function in the sign-in component to make the request to the server
// And dispatch the action to the above reducer to update the REDUX state

export const LoginRequest =
  ({ body }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: AUTH_REQUEST,
      });

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        body
      );

      dispatch({
        type: LOGIN,
        payload: data,
      });

      // Storing the User Info in the local storage
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({ type: AUTH_ERROR, payload: error?.response?.data?.message });
    }
  };

export const MSLogin = (accessToken) => async (dispatch) => {
  try {
    dispatch({
      type: AUTH_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/auth/msLogin`,
      config
    );

    dispatch({
      type: LOGIN,
      payload: data,
    });
    dispatch({
      type: MS_LOGIN_SUCCESS,
    });

    // Storing the User Info in the local storage
    localStorage.setItem("userInfo", JSON.stringify(data && data));
  } catch (error) {
    dispatch({ type: AUTH_ERROR, payload: error?.response?.data?.message });
    dispatch({ type: MS_LOGIN_FAIL });
  }
};

const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => {
      console.log("Graph", response);
      response.json();
    })
    .catch((error) => console.log(error));
}
