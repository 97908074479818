import {
  Chip,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useTheme } from "@emotion/react";

import DeleteQuestionDialog from "./QuestionDeleteDialog";
import CreateAnswers from "./CreateAnswers";
import EditAnswer from "./EditAnswer";
import DeleteAnswer from "./DeleteAnswer";
import styled from "@emotion/styled";
import EditQuestion from "./EditQuestion";

export const QuestionItem = ({ data, index, moveCard }) => {
  const ref = useRef(null);

  const theme = useTheme();

  let alphabet = "abcdefghijklmnopqrstuvwxyz";

  let alphabetArray = alphabet.split("");

  const [{ handlerId, canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id: data.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const isActive = canDrop && isOver;
  let backgroundColor = "";
  if (isActive) {
    backgroundColor = theme.palette.primary.light;
  } else if (canDrop) {
    backgroundColor = theme.palette.divider;
  }

  const opacity = isDragging ? 0.3 : 1;

  drag(drop(ref));

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div
      ref={ref}
      style={{ opacity, cursor: "move", backgroundColor }}
      data-handler-id={handlerId}>
      <StyledListItem
        style={{
          backgroundColor,
          border: `1px solid ${theme.palette.divider}`,
        }}
        key={index}>
        <ListItemText
          primary={
            <Stack direction="row" spacing={3} justifyContent="space-between">
              <Stack direction="row" spacing={0.5}>
                <Typography>{index + 1}</Typography>
                <Typography>:</Typography>
                <Typography>{data.question}</Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                {data.question_type && (
                  <Chip variant="outlined" label={<> {data.question_type}</>} />
                )}

                {data?.survey_answers?.length > 0 ? (
                  <>
                    <IconButton onClick={handleClick}>
                      <Typography variant="body2">
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </Typography>
                    </IconButton>
                  </>
                ) : (
                  <></>
                )}

                {data.question_type === "radio" && (
                  <CreateAnswers question_id={data.id} />
                )}
                <EditQuestion question={data} />
                <DeleteQuestionDialog id={data.id} question={data.question} />
              </Stack>
            </Stack>
          }
        />
      </StyledListItem>

      <Stack sx={{ mx: 3 }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {data?.survey_answers?.map((answer, index) => (
              <StyledListItem
                key={index}
                secondaryAction={
                  <Stack direction="row" spacing={1}>
                    <EditAnswer answer={answer} />
                    <DeleteAnswer answer={answer.answer} id={answer.id} />
                  </Stack>
                }>
                <ListItemText
                  primary={
                    <Typography>
                      {alphabetArray[index] + ": " + answer.answer}
                    </Typography>
                  }
                />
              </StyledListItem>
            ))}
          </List>
        </Collapse>
      </Stack>
    </div>
  );
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ItemTypes = {
  CARD: "card",
};
