// Get percentage of completed questions

export const getPercentage = (questionnaireCompleted) => {
  const totalQuestions = questionnaireCompleted.length;
  const correct = questionnaireCompleted.filter(
    (question) => question.answer_id === question.correct_answer_id
  ).length;
  const percentage = (correct / totalQuestions) * 100;
  return percentage;
};
