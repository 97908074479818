import {
  Box,
  Chip,
  Collapse,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddTargetToAgent from "./components/AddTargetToAgent";
import EditTargets from "./components/EditTargets";
import DeleteTarget from "./components/DeleteTarget";

const UserList = () => {
  const Targets = useSelector((state) => state.Targets);

  const { targets, loading } = Targets;

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Row</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell align="right">Teams</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {targets.map((row) => {
              return <Row row={row} key={row.id} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserList;

const Row = ({ row }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <StyledTableRow key={row.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            color="primary"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <StyledTableCell component="th" scope="row">
          {row.name}
        </StyledTableCell>
        <StyledTableCell> {row.email}</StyledTableCell>
        <StyledTableCell align="right">
          {" "}
          {row?.cc_teams_users?.map((team) => {
            return <Chip key={team.id} label={team.cc_team.name} />;
          })}
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <StyledTableCell
          sx={{ backgroundColor: "background.default" }}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ my: 2 }}>
                <Typography variant="body2" gutterBottom component="div">
                  Targets
                </Typography>
                <AddTargetToAgent user_id={row.id} manager={row.manager} />
              </Stack>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Date From</StyledTableCell>
                    <StyledTableCell>Date To</StyledTableCell>
                    <StyledTableCell>Sales Target</StyledTableCell>
                    <StyledTableCell>SCR Target</StyledTableCell>
                    <StyledTableCell>Pending Target</StyledTableCell>
                    <StyledTableCell>Hours Target</StyledTableCell>
                    <StyledTableCell align="center">Edit</StyledTableCell>
                    <StyledTableCell align="center">Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.cc_targets
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((target) => {
                      return (
                        <React.Fragment key={target.id}>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              {new Date(target.date_from).toLocaleDateString()}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {new Date(target.date_to).toLocaleDateString()}
                            </StyledTableCell>
                            <StyledTableCell>
                              {target.sales_target}
                            </StyledTableCell>
                            <StyledTableCell>
                              {target.scr_target}
                            </StyledTableCell>
                            <StyledTableCell>
                              {target.pending_target}
                            </StyledTableCell>
                            <StyledTableCell>
                              {target.hours_target}
                            </StyledTableCell>
                            <StyledTableCell>
                              <EditTargets target={target} />
                            </StyledTableCell>
                            <StyledTableCell>
                              <DeleteTarget target={target} />
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
