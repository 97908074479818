import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEvaluationResults } from "./State/AgentEvaluationsDuck";
import { useParams } from "react-router-dom";
import {
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { QuestionsAnswersList } from "./EvaluationResults";
import QaHistoryTable from "../QAManagement/Components/QuestionsAnswers/QaHistoryTable";
import EvaluationNotesList from "./Evaluations/EvaluationNotesList";
import { Stack } from "@mui/system";
import {
  getEvaluationRecordings,
  getQaEvaluationById,
} from "../QAManagement/State/QaDuck";
import MediaPlayerComp from "../../../../../Components/Bits/MediaPlayerComp";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import withAuth from "../../../../../Hoc/withAuth";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import StageChangeComponent from "./Components/StageChangeComponent";
import EvaluationStageHistory from "./Components/EvaluationStageHistory";
import { CreateNotification } from "../../../../../Redux/Ducks/NotificationDuck";

const ViewEvaluation = ({ user }) => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEvaluationResults(id));
    dispatch(getQaEvaluationById(id));
    dispatch(getEvaluationRecordings(id));
  }, [id, dispatch]);

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const updateEval = useMutation(
    (values) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/qa/evaluationUpdate/${id}`,
        values,
        config
      );
    },
    {
      onSuccess: (data) => {
        let notification = {
          title: `Evaluation Updated ${data.data.data.uni_id}`,
          message: `Evaluation Stage Updated to ${data.data.data.stage}`,
          type: "info",
          user_id: data.data.data.user_id,
          link: `/CDA/StartEvaluation/${data.data.data.id}/${data.data.data.uni_id}`,
        };

        dispatch(CreateNotification(notification));

        dispatch(getEvaluationResults(id));
        dispatch(getQaEvaluationById(id));
      },
    }
  );

  const { AgentEvaluations } = useSelector((state) => state.QAAgent);
  const { evaluationsResults } = AgentEvaluations;
  const [totalCallTime, setTotalCallTime] = React.useState(0);

  const Qa = useSelector((state) => state.Qa);
  const { evaluationRecordings } = Qa;

  //   "New",
  //   "Complete",
  //   "Fail",
  //   "Cancelled",
  //   "Busy",
  //   "Re-evaluate",
  //   "Re-evaluated Dispute",
  //   "Dispute",
  //   "Confirmed",

  return (
    <Container maxWidth="xl">
      <PageHeader
        title="Evaluation Results"
        subheader="View the results of the evaluation"
      />

      <AlertPopup
        open={updateEval.isSuccess}
        severity="success"
        message="Evaluation Updated Successfully"
      />
      <AlertPopup
        open={updateEval.isError}
        severity="error"
        message="Evaluation Updated Error"
      />

      <Grid container>
        <>
          {(evaluationsResults?.Evaluation?.stage === "Dispute Rejected" ||
            evaluationsResults?.Evaluation?.stage === "Fail") && (
            <Grid item xs={12}>
              <Divider sx={{ my: 4 }} />

              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack spacing={2}>
                  <Typography>Request A Re-Evaluation</Typography>
                  <StageChangeComponent
                    label="Re-evaluate"
                    updateEval={updateEval}
                    stage_reason_history={
                      evaluationsResults?.Evaluation?.stage_reason_history
                    }
                    toStage="Re-evaluate"
                  />
                </Stack>
                <Stack spacing={2}>
                  <Typography>Request A Re-Evaluation With Dispute</Typography>
                  <StageChangeComponent
                    label="Dispute"
                    updateEval={updateEval}
                    stage_reason_history={
                      evaluationsResults?.Evaluation?.stage_reason_history
                    }
                    toStage="Dispute"
                  />
                </Stack>
              </Stack>
              <Divider sx={{ my: 4 }} />
            </Grid>
          )}
        </>
        <Grid item xs={12}>
          <Typography variant="h6">Stage History</Typography>

          <EvaluationStageHistory
            stage_reason_history={
              evaluationsResults?.Evaluation?.stage_reason_history
            }
          />
        </Grid>
        <Grid item xs={12}>
          <QuestionsAnswersList Evaluation={evaluationsResults} />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 4 }} />
          <CardHeader title="Call Recordings" />
          {evaluationRecordings?.map((recording, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Stack>
                  <MediaPlayerComp
                    recording={recording}
                    totalCallTime={totalCallTime}
                    setTotalCallTime={setTotalCallTime}
                    src={`${process.env.REACT_APP_API_URL}/qa/evaluations/${recording.id}/recording/${recording.id}`}
                  />
                </Stack>
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 4 }} />
          <Typography variant="h6">Notes</Typography>
          <EvaluationNotesList evaluation_id={id} />
          <Divider sx={{ my: 4 }} />
        </Grid>
        <Grid item xs={12}>
          <QaHistoryTable questions={evaluationsResults?.questions} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default withAuth(ViewEvaluation);
