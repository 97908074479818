import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SubmitModal({ answers, questions, handleFinish }) {
  const [open, setOpen] = React.useState(false);

  const QuestionsWithAnswers = questions.map((question, index) => {
    const findAnswer = answers.find(
      (answer) => answer.question_id === question.id
    );

    const findValue = findAnswer.questionsAnswers.find((Answer) => {
      return Answer.id === findAnswer.value;
    });

    return {
      ...question,
      answer: findValue,
      answerValue: findValue?.value,
      value: findValue?.id,
    };
  });

  const filteredQuestions = QuestionsWithAnswers.filter(
    (question) =>
      Number(question.answerValue) < 0.5 && question.auto_fail === true
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const HandleComplete = () => {
    handleFinish();
    handleClose();
  };

  return (
    <React.Fragment>
      <Button fullWidth variant="contained" onClick={handleClickOpen}>
        Complete
      </Button>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>
          {filteredQuestions && filteredQuestions.length > 0
            ? "Questions to Fail"
            : "No questions to fail"}
        </DialogTitle>
        <DialogContent>
          {filteredQuestions && filteredQuestions.length > 0 ? (
            <>
              {filteredQuestions?.map((question, index) => {
                return (
                  <Card key={index}>
                    <CardHeader title={question.question} />
                    <CardContent>
                      <RadioGroup
                        // onChange={handleAdminAnswerChange}
                        value={question?.answerValue}
                        disabled
                        aria-labelledby="demo-radio-buttons-group-label"
                        name={question.id}>
                        {question.qa_queue_question_answers.map(
                          (answer, index) => {
                            return (
                              <React.Fragment key={answer.id}>
                                <FormControlLabel
                                  id={answer.id}
                                  value={answer.value}
                                  control={<Radio />}
                                  label={answer.answer}
                                />
                              </React.Fragment>
                            );
                          }
                        )}
                      </RadioGroup>
                      <Stack>
                        <Typography variant="h6" gutterBottom color="GrayText">
                          Comment
                        </Typography>
                        <Typography variant="body1">
                          {question?.comment}
                        </Typography>
                      </Stack>
                      <Divider sx={{ my: 1 }} />
                      <Stack direction="row">
                        {/* {answer.value <= 0.5 && answer.qa_queues_question.auto_fail && (
                <Chip variant="outlined" label="Auto Fail" color="error" />
              )} */}
                        {question.call_back_request && (
                          <Chip
                            variant="outlined"
                            label="call back request"
                            color="warning"
                          />
                        )}
                        {question.auto_fail && (
                          <Chip
                            variant="outlined"
                            label="Auto Fail"
                            color="warning"
                          />
                        )}
                        {question.compliance && (
                          <Chip variant="outlined" label="compliance" />
                        )}
                        {question.product_knowledge && (
                          <Chip variant="outlined" label="product_knowledge" />
                        )}
                        {question.professionalism && (
                          <Chip variant="outlined" label="professionalism" />
                        )}
                        {question.sales_skills && (
                          <Chip variant="outlined" label="sales_skills" />
                        )}
                        {question.tcf && (
                          <Chip variant="outlined" label="tcf" />
                        )}
                      </Stack>
                    </CardContent>
                  </Card>
                );
              })}
            </>
          ) : (
            <>
              <DialogContentText id="alert-dialog-slide-description">
                No questions to fail
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <Stack
          sx={{ p: 2 }}
          direction="row"
          justifyContent="flex-end"
          spacing={4}>
          <Button
            size="large"
            onClick={handleClose}
            variant="contained"
            color="error">
            Cancel
          </Button>
          <Button
            size="large"
            onClick={HandleComplete}
            variant="contained"
            color="success">
            Agree
          </Button>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
