import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";

import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { createRmaSurveyCompanyContact } from "../../State/SurveysDuck";

export default function AddSurveyCompany({ rmaSurveyCompany }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const [data, setData] = React.useState({});

  const [errors, setErrors] = React.useState([]);

  React.useEffect(() => {
    if (rmaSurveyCompany) {
      setData({
        Alt_No: "",
        COID_Claims: rmaSurveyCompany?.COID_Claims || "",
        COID_Payout: rmaSurveyCompany?.COID_Payout || "",
        Company_Name: rmaSurveyCompany?.Company_Name || "",
        Contact_Name: "",
        Designation: "",
        Email: "",
        Member_No: rmaSurveyCompany?.Member_No || "",
        Mobile_No: "",
        No_of_employees: rmaSurveyCompany?.No_of_employees || "",
        Premium: rmaSurveyCompany?.Premium || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rmaSurveyCompany]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (values) => {
    if (data.Designation === "") {
      setErrors([...errors, "Designation"]);

      return;
    }
    if (data.Contact_Name === "") {
      setErrors([...errors, "Contact_Name"]);
      return;
    }
    if (data.Mobile_No === "") {
      setErrors([...errors, "Mobile_No"]);
      return;
    }

    dispatch(createRmaSurveyCompanyContact(data));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth variant="outlined" onClick={handleClickOpen}>
        Add New Contact
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Add New Contact</DialogTitle>
        <>
          <Typography align="center" variant="h6">
            {data.Company_Name}
          </Typography>

          <Stack sx={{ p: 3 }}>
            <TextField
              fullWidth
              sx={{ m: 1 }}
              margin="normal"
              name="Contact_Name"
              label="Contact Person"
              variant="outlined"
              value={data.Contact_Name}
              onChange={handleChange}
              error={errors.includes("Contact_Name")}
            />
            <TextField
              fullWidth
              sx={{ m: 1 }}
              margin="normal"
              name="Mobile_No"
              label="Contact Number"
              type="number"
              min="10"
              variant="outlined"
              value={data.Mobile_No}
              onChange={handleChange}
              error={errors.includes("Mobile_No")}
            />
            <TextField
              fullWidth
              sx={{ m: 1 }}
              margin="normal"
              name="Alt_No"
              label="Alt Contact Number"
              variant="outlined"
              value={data.Alt_No}
              onChange={handleChange}
            />

            <FormControl fullWidth margin="normal" sx={{ m: 1 }}>
              <InputLabel id="demo-simple-select-label">Designation</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data?.Designation}
                name="Designation"
                label="Designation"
                error={errors.includes("Designation")}
                onChange={handleChange}>
                <MenuItem value="Accountant">Accountant</MenuItem>
                <MenuItem value="Director">Director</MenuItem>
                <MenuItem value="Online User">Online User</MenuItem>
                <MenuItem value="Other Fin Acc">Other Fin Acc</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          {errors.length > 0 && (
            <div>
              <h3>Errors</h3>
              <ul>
                {errors.map((error) => (
                  <li key={error}>{JSON.stringify(error)}</li>
                ))}
              </ul>
            </div>
          )}

          <Stack>
            <Button color="inherit" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Create
            </Button>
          </Stack>
        </>
      </Dialog>
    </div>
  );
}
