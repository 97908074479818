import React from "react";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from "@mui/material";

const DisplayLeadDetails = ({ lead }) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <List
            dense
            sx={{
              width: "100%",
              my: 2,
              bgcolor: "background.paper",
            }}
            subheader={<ListSubheader>Profile</ListSubheader>}>
            <Divider />
            <ContentItem title="ID Number" value={lead.id_no} />

            <Stack direction="row">
              <ContentItem title="Title" value={lead.title} />
              <ContentItem title="Initials" value={lead.initials} />
            </Stack>
            <Stack direction="row">
              <ContentItem title="Gender" value={lead.gender} />
              <ContentItem title="Marital Status" value={lead.marital_status} />
            </Stack>
            <ContentItem title="First Name" value={lead.first_name} />
            <ContentItem title="Last Name" value={lead.surname} />

            <ContentItem
              title="DOB"
              value={
                lead.dob && new Date(lead?.dob).toLocaleDateString("en-ZA")
              }
            />
          </List>
          <List
            dense
            sx={{
              width: "100%",
              my: 2,
              bgcolor: "background.paper",
            }}
            subheader={<ListSubheader>Account</ListSubheader>}>
            <Divider />
            <ContentItem title="Acc Holder Name" value={lead.acc_holder_name} />
            <ContentItem title="Acc No" value={lead.acc_no} />
            <ContentItem title="Acc Type" value={lead.acc_type} />
            <ContentItem title="Bank" value={lead.bank} />
            <ContentItem title="Branch Code" value={lead.branch_code} />
          </List>
          <List
            dense
            sx={{
              width: "100%",
              my: 2,
              bgcolor: "background.paper",
            }}
            subheader={<ListSubheader>Notes</ListSubheader>}>
            <Divider />
            <ContentItem title="Full Note" value={lead.full_note} />
            <ContentItem title={lead.note1_heading} value={lead.note1} />
            <ContentItem title={lead.note2_heading} value={lead.note2} />
            <ContentItem title={lead.note3_heading} value={lead.note3} />
            <ContentItem title={lead.note4_heading} value={lead.note4} />
            <ContentItem title={lead.note5_heading} value={lead.note5} />
            <ContentItem title={lead.note6_heading} value={lead.note6} />
            <ContentItem title={lead.note7_heading} value={lead.note7} />
            <ContentItem title={lead.note8_heading} value={lead.note8} />
            <ContentItem title={lead.note9_heading} value={lead.note9} />
            <ContentItem title={lead.note10_heading} value={lead.note10} />
          </List>
        </Grid>
        <Grid item xs={6}>
          <List
            dense
            sx={{
              width: "100%",
              my: 2,
              bgcolor: "background.paper",
            }}
            subheader={<ListSubheader>Details</ListSubheader>}>
            <Divider />
            <ContentItem title="Mobile No" value={lead.mobile_no} />
            <ContentItem title="Work No" value={lead.work_no} />
            <ContentItem title="Home No" value={lead.home_no} />
            <ContentItem title="Email" value={lead.email} />
          </List>
          <List
            sx={{
              width: "100%",
              my: 2,
              bgcolor: "background.paper",
            }}
            subheader={<ListSubheader>Postal Address</ListSubheader>}>
            <Divider />
            <ContentItem title="Addr 1" value={lead.addr1} />
            <ContentItem title="Addr 2" value={lead.addr2} />
            <ContentItem title="Addr 3" value={lead.addr3} />
            <ContentItem title="Addr 4" value={lead.addr4} />
            <ContentItem title="Addr 5" value={lead.addr5} />
            <ContentItem title="Postal Code" value={lead.postal_code} />
          </List>

          <List
            dense
            sx={{
              width: "100%",
              my: 2,
              bgcolor: "background.paper",
            }}
            subheader={<ListSubheader>Residential Address</ListSubheader>}>
            <Divider />
            <ContentItem title="Addr 1 res" value={lead.addr1_res} />
            <ContentItem title="Addr 2 res" value={lead.addr2_res} />
            <ContentItem title="Addr 3 res" value={lead.addr3_res} />
            <ContentItem title="Addr 4 res" value={lead.addr4_res} />
            <ContentItem title="Addr 5 res" value={lead.addr5_res} />
            <ContentItem title="postal code res" value={lead.postal_code_res} />
          </List>
          <List
            dense
            sx={{
              width: "100%",
              my: 2,
              bgcolor: "background.paper",
            }}
            subheader={<ListSubheader>Other</ListSubheader>}>
            <Divider />
            <ContentItem title="Old System Id" value={lead.old_system_id} />
            <ContentItem title="Source" value={lead.source} />
            <ContentItem title="check_date" value={lead.check_date} />
            <ContentItem title="External Ref" value={lead.external_ref} />
            <ContentItem title="Platform" value={lead.platform} />
            <ContentItem title="Rejection_date" value={lead.rejection_date} />
            <ContentItem
              title="Rejection Reason"
              value={lead.rejection_reason}
            />
            <ContentItem title="Removed Date" value={lead.removed_date} />
            <ContentItem title="Updated At" value={lead.updatedAt} />
            <ContentItem title="Created At" value={lead.createdAt} />
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default DisplayLeadDetails;

const ContentItem = ({ title, value }) => {
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.secondary">
                {title}
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body1"
                color="text.primary">
                {value}
              </Typography>
            </>
          }
        />
      </ListItem>
    </>
  );
};
