import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { editQuestionnaire } from "../State/QuestionnairsDuck";
import { useDispatch } from "react-redux";
import { Box } from "@mui/system";

const EditQuestionnaire = ({ questionnaire }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    name: "",
    description: "",
    client: "",
    question_amount: 0,
    test_time: 0,
    type: "",
    pass_percentage: 0,
  });

  useEffect(() => {
    setValues({
      name: questionnaire.name || "",
      description: questionnaire.description || "",
      client: questionnaire.client || "",
      question_amount: questionnaire.question_amount || 0,
      test_time: questionnaire.test_time || 0,
      type: questionnaire.type || "",
      pass_percentage: questionnaire.pass_percentage || 0,
    });
  }, [questionnaire]);

  const formik = useFormik({
    initialValues: values,
    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
      description: yup.string().required("Description is required"),
      client: yup.string().required("Client is required"),
      question_amount: yup.string().required("Question amount is required"),
      type: yup.string().required("Type is required"),
      test_time: yup.string().required("Test time is required"),
      pass_percentage: yup.string().required("Pass percentage is required"),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(editQuestionnaire(questionnaire.id, values));
    },
  });

  return (
    <Stack sx={{ m: 3 }}>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && formik.errors.name ? true : false}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          fullWidth
          label="Description"
          name="description"
          margin="normal"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.description && formik.errors.description
              ? true
              : false
          }
          helperText={formik.touched.description && formik.errors.description}
        />

        <FormControl margin="normal" fullWidth>
          <InputLabel id="demo-simple-select-label">Test Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Test Type"
            name="type"
            value={formik.values.type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.type && formik.errors.type ? true : false}>
            <MenuItem value="Product">Product</MenuItem>
            <MenuItem value="Policies">Policies</MenuItem>
            <MenuItem value="Compliance">Compliance</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="clientLabel">Select Client</InputLabel>
          <Select
            labelId="clientLabel"
            id="demo-simple-select"
            value={formik.values.client}
            label="Select Client"
            name="client"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.client && formik.errors.client ? true : false}
            helpertext={formik.touched.client && formik.errors.client}>
            <MenuItem value="CDA">CDA</MenuItem>
            <MenuItem value="Santam">Santam</MenuItem>
            <MenuItem value="Hollard">Hollard</MenuItem>
          </Select>
        </FormControl>

        <Box my={2}>
          <Typography gutterBottom>Select Total Number Questions.</Typography>

          <Stack direction="row" spacing={2}>
            <Typography variant="h5">
              {formik.values.question_amount}
            </Typography>

            {formik.values.question_amount ? (
              <Slider
                aria-label="question_amount"
                label="Question amount"
                defaultValue={formik.values.question_amount}
                name="question_amount"
                getAriaValueText={formik.question_amount}
                value={formik.values.question_amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                valueLabelDisplay="auto"
                step={5}
                marks
                min={10}
                max={30}
              />
            ) : (
              <Slider
                aria-label="question_amount"
                label="Question amount"
                defaultValue={formik.values.question_amount}
                value={formik.values.question_amount}
                name="question_amount"
                getAriaValueText={formik.question_amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                valueLabelDisplay="auto"
                step={5}
                marks
                min={10}
                max={30}
              />
            )}
          </Stack>
        </Box>

        <Box my={2}>
          <Typography gutterBottom>
            Select Total Test Time in Minutes
          </Typography>

          <Stack direction="row" spacing={2}>
            <Typography variant="h5">{formik.values.test_time}</Typography>

            {formik.values.test_time ? (
              <Slider
                aria-label="test_time"
                defaultValue={formik.values.test_time}
                value={formik.values.test_time}
                name="test_time"
                getAriaValueText={formik.test_time}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                valueLabelDisplay="auto"
                step={5}
                marks
                min={10}
                max={120}
              />
            ) : (
              <Slider
                aria-label="test_time"
                defaultValue={formik.values.test_time}
                value={formik.values.test_time}
                name="test_time"
                getAriaValueText={formik.test_time}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                valueLabelDisplay="auto"
                step={5}
                marks
                min={10}
                max={120}
              />
            )}
          </Stack>
        </Box>

        <Box my={2}>
          <Typography gutterBottom>Select Pass Mark.</Typography>

          <Stack direction="row" spacing={2}>
            <Typography variant="h5">
              {formik.values.pass_percentage}
            </Typography>

            {formik.values.pass_percentage ? (
              <Slider
                aria-label="pass_percentage"
                label="Select Pass Mark"
                defaultValue={formik.values.pass_percentage}
                name="pass_percentage"
                getAriaValueText={formik.pass_percentage}
                value={formik.values.pass_percentage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                valueLabelDisplay="auto"
                step={5}
                marks
                min={10}
                max={100}
              />
            ) : (
              <Slider
                aria-label="pass_percentage"
                label="Select Pass Mark"
                defaultValue={formik.values.pass_percentage}
                value={formik.values.pass_percentage}
                name="pass_percentage"
                getAriaValueText={formik.pass_percentage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                valueLabelDisplay="auto"
                step={5}
                marks
                min={10}
                max={100}
              />
            )}
          </Stack>
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}>
          Submit
        </Button>
      </form>
    </Stack>
  );
};

export default EditQuestionnaire;
