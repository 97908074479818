import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import axios from "axios";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { Stack } from "@mui/system";
import CreateNewCoachingScoreCard from "./Components/CreateNewCoachingScoreCard";
import { Alert, Button, LinearProgress } from "@mui/material";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import withAuth from "../../../../../Hoc/withAuth";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";

const ListOfScoreCards = ({ user, modules }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isDeleteSuccess, setDeleteSuccess] = useState(null);

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const getScoreCards = useQuery(["scoreCards"], () => {
    if (
      (modules &&
        modules.includes("call-center-manager") &&
        modules.includes("call-center-admin")) ||
      user.userType === "admin"
    ) {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/scorecard?type=admin`,
        config
      );
    } else if (
      (modules && modules.includes("call-center-team-leader")) ||
      user.userType === "admin"
    ) {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/scorecard?type=team_lead`,
        config
      );
    } else if (
      modules &&
      modules.includes("call-center-user") &&
      user.userType === "user"
    ) {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/scorecard?type=agent`,
        config
      );
    }
  });

  const deleteScoreCard = useMutation(
    ["deleteScoreCardById"],
    (id) => {
      return axios.delete(
        `${process.env.REACT_APP_API_URL}/callCentre/scorecard/${id}`,
        config
      );
    },
    {
      onSuccess: () => {
        setDeleteSuccess(null);
        queryClient.invalidateQueries(["scoreCards"], (prevData) =>
          prevData.filter((scorecard) => scorecard.id !== isDeleteSuccess)
        );
      },
    }
  );

  const handleDeleteScoreCard = (scoreCardId) => {
    deleteScoreCard.mutate(scoreCardId);
  };

  const columns = [
    {
      field: "View",
      header: "View",
      width: 130,
      renderCell: (params) => {
        if (params.row.cc_score_card_totals.length > 0) {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                navigate(
                  `/CDA/CallCenter/Coaching/ViewScoreCard/${params.row.id}`
                )
              }>
              View
            </Button>
          );
        } else {
          return (
            <Button
              variant="contained"
              color="warning"
              onClick={() =>
                navigate(
                  `/CDA/CallCenter/Coaching/NewScoreCardForm/${params.row.id}`
                )
              }>
              Continue
            </Button>
          );
        }
      },
    },

    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "Team Leader",
      headerName: "Team Lead",
      width: 200,
      valueGetter: (params) => {
        return params.row.team_lead?.name;
      },
    },
    {
      field: "agent",
      headerName: "agent",
      width: 200,
      valueGetter: (params) => {
        return `${params.row.agent?.name} ${params.row.agent?.surname}`;
      },
    },

    {
      field: "Total",
      headerName: "Total",
      width: 200,
      valueGetter: (params) => {
        if (params?.row?.cc_score_card_totals?.length > 0) {
          return ` ${params?.row?.cc_score_card_totals[0]?.total_score} % `;
        } else {
          return 0;
        }
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      valueGetter: (params) => {
        return `${new Date(
          params.row.createdAt
        ).toLocaleDateString()} - ${new Date(
          params.row.createdAt
        ).toLocaleTimeString()}`;
      },
    },

    ...(modules.includes("call-center-admin") || user.userType === "admin"
      ? [
          {
            field: "Delete",
            header: "Delete",
            width: 130,
            renderCell: (params) => {
              return (
                <Button
                  color="error"
                  onClick={() => handleDeleteScoreCard(params.row.id)}>
                  Delete
                </Button>
              );
            },
          },
        ]
      : []),
  ];

  if (getScoreCards.isLoading) return <LinearProgress />;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          mb: 4,
        }}>
        {(modules.includes("call-center-manager") ||
          modules.includes("call-center-admin") ||
          modules.includes("call-center-team-leader") ||
          user.userType === "admin") && <CreateNewCoachingScoreCard />}
      </Stack>
      <AlertPopup
        open={deleteScoreCard.isSuccess}
        message="Score Card Deleted Successfully"
        severity={"success"}
      />

      {getScoreCards?.data?.data?.data &&
      getScoreCards?.data?.data?.data?.length === 0 ? (
        <Alert severity="info">No Score Cards Found</Alert>
      ) : (
        <Box sx={{ height: "60vh" }}>
          <DataGridPremium
            rows={getScoreCards?.data?.data?.data}
            columns={columns}
            slots={{
              toolbar: GridToolbar,
            }}
          />
        </Box>
      )}
    </>
  );
};

export default withAuth(ListOfScoreCards);
