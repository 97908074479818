import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getClientsList } from "./State/ClientsDuck";
import { useSelector } from "react-redux";
import { createCampaign } from "./State/CampaingsDuck";
import TextfieldWrapper from "../../../../../Components/FormComponents/TextfieldWrapper";
import SelectWrapper from "../../../../../Components/FormComponents/Select";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Slide } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CreateCallCenterCampaign = ({ campaignData }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getClientsList());
  }, [dispatch]);

  const Clients = useSelector((state) => state.Clients);

  const { clientsList } = Clients;

  const Campaigns = useSelector((state) => state.Campaigns);

  const { successCampaignCreate, error } = Campaigns;

  if (successCampaignCreate) {
    setTimeout(() => {
      navigate("/CDA/callCenter/campaigns");
    }, 1000);
  }

  return (
    <>
      <PageHeader
        title="Create Campaign"
        subheader="Create a new campaign"
        breadcrumbs={[
          { title: "Call Center Admin", href: "/CDA/callCenter/campaigns" },

          {
            title: "Create Campaign",
            href: "/CDA/callCenter/CreateCampaign",
          },
        ]}
      />

      <Formik
        initialValues={{
          name: campaignData?.name || "",
          client: campaignData?.client || "",
          client_id: campaignData?.client_id || "",
          campaign_type: campaignData?.campaign_type || "",
          sales_platform: campaignData?.sales_platform || "",
          policy_prefix: campaignData?.policy_prefix || "",
          policy_suffix: campaignData?.policy_suffix || "",
          bi_calc_type: campaignData?.bi_calc_type || "",
          bi_calc_rate: campaignData?.bi_calc_rate || "",
          ci_calc_type: campaignData?.ci_calc_type || "",
          ci_calc_rate: campaignData?.ci_calc_rate || "",
          note_1: campaignData?.note_1 || "",
          note_2: campaignData?.note_2 || "",
          note_3: campaignData?.note_3 || "",
          note_4: campaignData?.note_4 || "",
          note_5: campaignData?.note_5 || "",
          update_reason: "create",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Campaign name is required"),
          client_id: Yup.string().required("Client ID is required"),
          campaign_type: Yup.string().required("Type is required"),
          sales_platform: Yup.string().required("Sales platform is required"),

          bi_calc_type: Yup.string().required("Bi calc type is required"),
          bi_calc_rate: Yup.number().required("Bi calc rate is required"),
          ci_calc_type: Yup.string().required("Ci calc type is required"),
          ci_calc_rate: Yup.number().required("Ci calc rate is required"),
        })}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          dispatch(createCampaign(values));

          setSubmitting(false);
        }}>
        {() => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextfieldWrapper name="name" label="Campaign Name" />
                </Grid>
                <Grid item xs={12}>
                  <SelectWrapper
                    name="client_id"
                    label="Select Client"
                    options={clientsList.map((client) => {
                      return {
                        value: client.id,
                        label: client.name,
                      };
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectWrapper
                    name="campaign_type"
                    label="Campaign Type"
                    options={[
                      { value: "voice-inbound", label: "Voice Inbound" },
                      { value: "voice-outbound", label: "Voice Outbound" },
                      { value: "voice", label: "Voice" },
                      { value: "avm", label: "AVM" },
                      { value: "communication", label: "Communication" },
                      { value: "Other", label: "Other" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectWrapper
                    name="sales_platform"
                    label="Sales Platform"
                    options={[
                      { value: "none", label: "none" },
                      { value: "taskflow", label: "Taskflow" },
                      { value: "mip", label: "MIP" },
                      { value: "node", label: "NODE" },
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="policy_prefix"
                    label="Policy Prefix"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="policy_suffix"
                    label="Policy Suffix"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectWrapper
                    name="bi_calc_type"
                    label="Bi Calc Type"
                    options={[
                      { value: "average premium", label: "Average Premium" },
                      { value: "cost per sale", label: "Cost per sale" },
                      { value: "hours", label: "Hours" },
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="bi_calc_rate"
                    label="Bi Calc Rate"
                    type="number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectWrapper
                    name="ci_calc_type"
                    label="Ci Calc Type"
                    options={[
                      { value: "cost per sale", label: "cost per sale" },
                      { value: "hours", label: "hours" },
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper
                    name="ci_calc_rate"
                    label="Ci Calc Rate"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper name="note_1" label="Note No: 1" />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper
                    multiline
                    name="note_2"
                    label="Note No: 2"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper
                    multiline
                    name="note_3"
                    label="Note No: 3"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper
                    multiline
                    name="note_4"
                    label="Note No: 4"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper
                    multiline
                    name="note_5"
                    label="Note No: 5"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button type="submit" fullWidth variant="contained">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <AlertPopup
        open={successCampaignCreate}
        message="Campaign Created Redirecting =>>>"
      />
      <AlertPopup severity="error" open={error} message="Error Occurred" />
    </>
  );
};

export default CreateCallCenterCampaign;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const AlertPopup = ({ open: isOpen, message, severity }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        TransitionComponent={TransitionDown}
        autoHideDuration={6000}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
