import { useTheme } from "@emotion/react";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import * as yup from "yup";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import SelectWrapper from "../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";

const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
};

const AddEmployeeModal = ({ text, requisitionId, ...otherProps }) => {
  const queryClient = useQueryClient();

  const [mutationError, setMutationError] = useState("");
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClickOpen = () => {
    mutation.reset();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const mutation = useMutation({
    mutationFn: (values) => {
      if (values.type === "newEmployee") {
        // add new employee
        return axios.post(
          `${process.env.REACT_APP_API_URL}/onboarding/employees`,
          values.body,
          config
        );
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["getCEORequisitions"]);
      await queryClient.invalidateQueries(["getHRRequisitions"]);
      await queryClient.invalidateQueries(["getMANAGERRequisitions"]);
    },
    onError: async (error) => {
      if (error.response.status === 422) {
        if (error.response.data.validationErrors.cell_number) {
          setMutationError(error.response.data.validationErrors.cell_number);
        } else {
          setMutationError(error.response.message);
        }
      } else {
        setMutationError(error.response.data.message);
      }
    },
  });

  const onAddNewEmployeeHandler = (values) => {
    mutation.mutate({ body: values, type: "newEmployee" });
    setTimeout(() => handleClose(), 2000);
  };

  // get available requisitions
  const { isLoading, isError, data, error } = useQuery(
    [`getRequisitions`],
    async () =>
      fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/requisitions`,
        config
      )
  );

  if (requisitionId) {
    if (isLoading) return <LinearProgress />;

    if (isError) return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Stack spacing={0}>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        {...otherProps}
        fullWidth>
        {text}
      </Button>

      <Stack spacing={2}>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          fullWidth
          aria-labelledby="responsive-dialog-title"
          sx={{ borderRadius: 0 }}>
          {mutation.isLoading && (
            <AlertPopup
              open={true}
              message={"Adding Employee..."}
              severity={"info"}
            />
          )}

          {mutation.isSuccess && (
            <AlertPopup
              open={true}
              message={"Employee Onboarding Form sent to recruit!"}
            />
          )}

          {mutationError && (
            <AlertPopup
              open={true}
              message={mutationError}
              severity={"error"}
            />
          )}

          {data?.availableRequisitions?.length === 0 ? (
            <Alert color="warning" sx={{ m: 2 }}>
              Currently no active requisitions available
            </Alert>
          ) : requisitionId &&
            !data?.availableRequisitions?.find(
              (option) => option.id === requisitionId
            ) ? (
            <Alert color="warning" sx={{ m: 2 }}>
              This requisition is not available to use, select different
              requisition
            </Alert>
          ) : (
            ""
          )}
          <DialogTitle id="responsive-dialog-title">
            <Typography>Add Employee</Typography>
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                cell_number: "",
                requisition_id: requisitionId || "",
              }}
              validationSchema={yup.object().shape({
                cell_number: yup
                  .string()
                  .matches(/^\d+$/, {
                    message: "Cell number should be only numbers",
                  })
                  .min(10, "Cell number should be 10 digits")
                  .max(10, "Cell number should be 10 digits")
                  .required("Cell number is required"),
                requisition_id: yup
                  .string()
                  .required("Please select requisition"),
              })}
              onSubmit={(values, action) => {
                onAddNewEmployeeHandler(values);
              }}
              enableReinitialize={true}>
              {({ values, errors, resetForm }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                        <TextfieldWrapper
                          name={"cell_number"}
                          label="Cell Number"
                          error={
                            mutation?.error?.response?.data?.validationErrors
                              ?.cell_number && true
                          }
                          helperText={
                            mutation?.error?.response?.data?.validationErrors
                              ?.cell_number
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <SelectWrapper
                          name="requisition_id"
                          label="Select Requisition"
                          options={data?.availableRequisitions.map((req) => {
                            return {
                              value: req?.id,
                              label: req?.Position?.job_title,
                            };
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Stack direction={"row"} justifyContent="end">
                          <Button
                            autoFocus
                            onClick={handleClose}
                            color="secondary">
                            Cancel
                          </Button>
                          <Button type="submit" autoFocus>
                            Save
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default AddEmployeeModal;
