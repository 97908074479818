import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import MainAppContainer from "../../../Components/Containers/MainAppContainer";
import EditModules from "../components/EditModules";

import { useDispatch, useSelector } from "react-redux";
import {
  editCompany,
  getCompany,
} from "../../../Redux/Ducks/AdminCompniesDuck";

import CompanyModuleCard from "../components/containers/CompanyModuleCard";
import DeleteUser from "../components/DeleteUserDialog";
import { useNavigate } from "react-router-dom";

const Company = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompany({ id }));
  }, [id, dispatch]);

  const Companies = useSelector((state) => state.Companies);
  const { company, getCompanyLoading } = Companies;

  const [formData, setFormData] = useState({
    name: "",
    status: "",
  });

  useEffect(() => {
    setFormData({
      name: company?.name || "",
      status: company?.status || "",
    });
  }, [company]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    dispatch(editCompany({ id, formData }));
  };

  return (
    <MainAppContainer>
      <Grid container justifyContent="center">
        {getCompanyLoading && <CircularProgress />}

        <Grid item xs={12} sm={12} md={8} lg={12} xl={12}>
          <Typography variant="h4" align="center">
            {company?.name}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label="Name"
            value={formData.name}
            onChange={handleChange}
          />

          <FormControl margin="normal" fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.status}
              label="Status"
              name="status"
              onChange={handleChange}>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
          <Button sx={{ mt: 2 }} variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider sx={{ my: 4 }} />
          <Box display="flex" justifyContent="center">
            <Button disabled>Add User Company</Button>
          </Box>
        </Grid>

        {company &&
          company?.companies_users?.map((user, index) => {
            return (
              <Grid item xs={3} key={index}>
                <UserCard U={user.User} />{" "}
              </Grid>
            );
          })}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider sx={{ my: 4 }} />
          <Box display="flex" justifyContent="center">
            <EditModules company_id={id} />
          </Box>
        </Grid>
        {company &&
          company?.Modules?.map((module) => {
            return (
              <Grid item xs={3} key={module.id}>
                <CompanyModuleCard name={module.name} />
              </Grid>
            );
          })}
      </Grid>
    </MainAppContainer>
  );
};

export default Company;

const UserCard = ({ U }) => {
  const navigate = useNavigate();

  return (
    <>
      <Card>
        <CardHeader
          title={U?.name}
          action={
            <Box display="flex" justifyContent="flex-end">
              <Chip
                onClick={() => navigate(`/admin/users/${U?.id}`)}
                variant="outlined"
                color={
                  U?.user_type?.type === "admin"
                    ? "primary"
                    : U?.user_type?.type === "super"
                    ? "error"
                    : U?.user_type?.type === "super_admin"
                    ? "warning"
                    : "secondary"
                }
                label={U?.user_type?.type}
              />
            </Box>
          }></CardHeader>
        <Typography align="center">
          {U?.companies_users && U?.companies_users[0]?.company?.name}
        </Typography>
        <CardContent>
          <div>
            <p>{U?.email}</p>
          </div>
        </CardContent>

        <Stack mx={1} mb={2} direction="row" justifyContent="space-between">
          <Button
            onClick={() => {
              navigate(`/EditUser/${U?.id}`);
            }}>
            Edit
          </Button>
          <DeleteUser id={U?.id} name={U?.name} />
        </Stack>
      </Card>
    </>
  );
};
