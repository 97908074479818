import {
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Alert,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CardActions,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Container,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import CreateQuestions from "./CreateQuestions";
import QuestionsList from "./QuestionsList";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import AddUsersToSurvey from "./AddUsersToSurvey";

import DeleteSurveyUser from "./DeleteSurveyUser";
import {
  editSurvey,
  getSurveyById,
  getSurveyQuestions,
  getSurveyUsers,
} from "../../State/SurveysDuck";
import { getCompanyModules } from "../../../../../../../Redux/Ducks/AdminCompniesDuck";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";
import withAuth from "../../../../../../../Hoc/withAuth";

const SurveyItem = ({ companyDetails }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    dispatch(getSurveyById(id));
    dispatch(getSurveyUsers(id));
    dispatch(getSurveyQuestions(id));
    dispatch(getCompanyModules(companyDetails.companyId));
  }, [id, dispatch, companyDetails]);

  const Companies = useSelector((state) => state.Companies);
  const { companyModules } = Companies;

  const moduleUsers = companyModules?.find(
    (module) => module?.name === "survey"
  );

  const Surveys = useSelector((state) => state.Surveys);
  const {
    loading,
    success,
    error,
    surveyCampaign,
    questionCreated,
    surveyQuestions,
    surveyUsers,
  } = Surveys;

  const [values, setValues] = React.useState({
    name: "",
    description: "",
    client: "",
  });

  useEffect(() => {
    setValues({
      name: surveyCampaign?.name || "",
      description: surveyCampaign?.description || "",
      client: surveyCampaign?.client || "",
    });
  }, [surveyCampaign]);

  const formik = useFormik({
    initialValues: values,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("A Name is required"),
      description: Yup.string().required("A Description is required"),
      client: Yup.string().required("A Client is required"),
    }),
    onSubmit: async (values) => {
      dispatch(editSurvey(id, values));
      setOpenEdit(!openEdit);
    },
  });

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => navigate(-1)}>
            Back{" "}
          </Button>

          {openEdit ? (
            <form onSubmit={formik.handleSubmit}>
              <Stack>
                <TextField
                  margin="normal"
                  label="Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.name && formik.errors.name ? true : false
                  }
                  helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                  margin="normal"
                  label="Description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description && formik.errors.description
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />

                <TextField
                  margin="normal"
                  label="Client"
                  name="client"
                  value={formik.values.client}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.client && formik.errors.client ? true : false
                  }
                  helperText={formik.touched.client && formik.errors.client}
                />

                {loading ? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled
                    style={{ marginTop: "20px" }}>
                    Loading...
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "20px" }}>
                    Save
                  </Button>
                )}

                {error && (
                  <Alert severity="error">{JSON.stringify(error)}</Alert>
                )}
                <Button
                  color="inherit"
                  my={4}
                  onClick={() => setOpenEdit(!openEdit)}>
                  Cancel Edit
                </Button>
              </Stack>
            </form>
          ) : (
            <Card sx={{ my: 4 }}>
              <CardHeader
                title={surveyCampaign?.name}
                subheader={surveyCampaign?.client}
                action={
                  <Chip
                    variant="outlined"
                    label={surveyCampaign?.status ? "Active" : "Disabled"}
                    color={surveyCampaign?.status ? "secondary" : "warning"}
                  />
                }
              />
              <CardContent>
                <Typography variant="body1">
                  {surveyCampaign?.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="outlined"
                  my={4}
                  onClick={() => setOpenEdit(!openEdit)}>
                  Edit Survey
                </Button>
              </CardActions>
            </Card>
          )}
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card variant="outlined">
            <CardHeader
              title="Survey Users"
              subheader="Users that have access to this survey"
            />
            <CardContent>
              <AddUsersToSurvey
                survey_id={id}
                users={moduleUsers?.Modules_users}
              />
              <TableContainer sx={{ mt: 4 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>

                      <TableCell>Remove</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {surveyUsers?.map((user) => (
                      <StyledTableRow key={user?.User?.id}>
                        <StyledTableCell>{user?.User?.name}</StyledTableCell>
                        <StyledTableCell>{user?.User?.email}</StyledTableCell>

                        <StyledTableCell>
                          <DeleteSurveyUser
                            id={user?.id}
                            user={user?.User?.name}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid sx={{ mt: 4 }} item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card variant="outlined">
            <CardHeader
              title="Survey Questions"
              subheader="Questions for this survey"
            />
            <CardContent>
              <CreateQuestions id={id} />

              <DndProvider backend={HTML5Backend}>
                {surveyQuestions && <QuestionsList data={surveyQuestions} />}
              </DndProvider>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <AlertPopup
        severity="success"
        message="Campaign Updated"
        open={success}
      />
      <AlertPopup
        severity="success"
        message="Question Created"
        open={questionCreated}
      />
    </Container>
  );
};

export default withAuth(SurveyItem);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
