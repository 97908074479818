import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { useDispatch } from "react-redux";
import { editQuestionAnswer } from "../../State/SurveyUsersDuck";

export default function EditSurveyAnswer({ result }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const [value, setValue] = React.useState({
    question_id: result.survey_campaigns_question.id,
    answer_id: result.answer_id,
    notes: result.note,
  });

  const handleRadioChange = (event) => {
    setValue({
      ...value,
      answer_id: event.target.value,
    });
  };

  const handleNoteChange = (event) => {
    setValue({
      ...value,
      notes: event.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // window.location.reload();
  };

  const handleSubmit = () => {
    console.log(value);
    dispatch(editQuestionAnswer(result.id, value));
    setTimeout(() => {
      handleClose();
    }, 300);
  };

  return (
    <div>
      <Tooltip title="Change Answer">
        <IconButton onClick={handleClickOpen} edge="end" aria-label="comments">
          <EditTwoToneIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change Answer</DialogTitle>

        <Stack sx={{ p: 2 }}>
          <Typography gutterBottom variant="body1">
            Question :{"  "}
            {result.survey_campaigns_question.question}
          </Typography>
          <div>
            {result.answer_id === 10000 ? (
              <Typography color="primary" variant="h6">
                Value : {result._value}
              </Typography>
            ) : (
              <>
                {result.survey_campaigns_question?.survey_answers &&
                  result.survey_campaigns_question?.survey_answers?.map(
                    (answer, index) => {
                      if (answer.id === result.answer_id) {
                        return (
                          <Stack key={index} direction="row" spacing={1}>
                            <Typography variant="span">
                              Current Answer :{" "}
                            </Typography>
                            <Typography color="primary" variant="h6">
                              {answer.answer}
                            </Typography>
                          </Stack>
                        );
                      } else {
                        return null;
                      }
                    }
                  )}
              </>
            )}
          </div>
          <Box my={1}>
            <Typography gutterBottom variant="caption">
              Note : {result?.note}
            </Typography>
          </Box>

          <Stack border={1} p={2}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                <Typography color="primary" variant="h6">
                  {result.survey_campaigns_question?.question}
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                onChange={handleRadioChange}
                name={result.survey_campaigns_question?.question}>
                {result.survey_campaigns_question?.survey_answers.map(
                  (answer) => {
                    return (
                      <FormControlLabel
                        key={answer.id}
                        value={answer?.id}
                        control={<Radio />}
                        label={answer?.answer}
                      />
                    );
                  }
                )}
              </RadioGroup>
            </FormControl>

            <TextField
              margin="normal"
              id="notes"
              label="Notes"
              multiline
              rows={2}
              name="notes"
              value={value.notes}
              onChange={handleNoteChange}
            />
          </Stack>
        </Stack>

        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
