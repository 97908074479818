import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";

const data = [
  { value: 65, label: "A" },
  { value: 35, label: "B" },
];

const size = {
  width: 200,
  height: 100,
};

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 10,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export default function PieChartWithCenterLabel({ label }) {
  return (
    <PieChart series={[{ data, innerRadius: 40 }]} {...size}>
      <PieCenterLabel>{label}</PieCenterLabel>
    </PieChart>
  );
}
