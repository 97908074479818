import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { Stack, Typography } from "@mui/material";

export default function QuestionItem({
  question,
  QuestionAnswers,
  setQuestionAnswers,
  questionNumber,
  index,
}) {
  const [value] = React.useState({
    question_id: question.id,
    answer_id: "Not Answered",
    notes: "",
    isCorrect: false,
  });

  const handleRadioChange = (event) => {
    setQuestionAnswers(
      QuestionAnswers?.map((question) => {
        if (question.question_id === value.question_id) {
          return {
            ...question,
            answer_id: event.target.value,
            isCorrect: event.target.value === question.correct_answer_id,
          };
        } else {
          return question;
        }
      })
    );
  };

  return (
    <Stack my={2}>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          <Typography color="primary" variant="h6">
            {index + 1} : {question?.question}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          onChange={handleRadioChange}
          name={question?.question}>
          {question?.questionnaire_answers.map((answer) => {
            return (
              <FormControlLabel
                key={answer.id}
                value={answer?.id}
                control={<Radio />}
                label={answer?.answer}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}
