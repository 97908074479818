import {
  Stack,
  Typography,
  LinearProgress,
  Alert,
  Button
} from "@mui/material";
import { useSelector } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import withAuth from "../../../Hoc/withAuth";
import axios from "axios";
import { useState } from 'react'
import EditPositionModal from "../modals/EditPositionModal";
import AddPositionModal from "../modals/AddPositionModal";
import RejectPositionModal from "../modals/RejectPositionModal";
import DeletePositionModal from "../modals/DeletePositionModal";
import AlertPopup from "../../../Components/Bits/AlertPopup";

const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
};

const OECPositions = ({ user, modules }) => {
  const queryClient = useQueryClient()

  const [mutationError, setMutationError] = useState('')
  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const mutation = useMutation({
    mutationFn: async (position_id) => {
      return axios.put(`${process.env.REACT_APP_API_URL}/onboarding/positions/${position_id}`, { approved: true, is_active: true }, config)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["getPositions"]);
    },
    onError: async (error) => {
      setMutationError(error.response.data.message);
    }
  })

  const onApprovePositionHandler = async (position_id) => {
    mutation.mutate(position_id)
  }

  const columns = [
    {
      field: "job_title",
      headerName: "Job Title",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "rem_channel_grade",
      headerName: "Rem Channel Grade",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "approved",
      headerName: "Status",
      width: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const approve = params.value;

        if (approve === true) {
          return <Typography color="success.main">Approved</Typography>;
        } else if (approve === false) {
          return <Typography color="error.main">Rejected</Typography>;
        } else {
          return <Typography color="warning.main">Waiting Approval</Typography>;
        }
      },
    },
    {
      field: "is_active",
      headerName: "Visible",
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const isActive = params.value;

        if(isActive){
          return <Typography color="success.main">Yes</Typography>;
        }else{
          return <Typography color="error.main">No</Typography>;
        }
      }
    },
    {
      field: "actions",
      headerAlign: 'center',
      align: 'center',
      headerName: "Actions",
      width: 250,
      renderCell: (params) => {
        const approved = params.value.approved;
        const isActive = params.value.is_active;

        if (approved !== false && approved !== true) {
          return (
            <Stack direction="row" spacing={1}>
              <RejectPositionModal position={params.value} />
              
              <Button color="success" variant="outlined" onClick={() => onApprovePositionHandler(params.row.id)}>
                Approve
              </Button>
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" spacing={1}>
              <EditPositionModal
                position={params.value}
                modules={modules}
                text="View"
              />
              <DeletePositionModal  
                position={params.value}
                modules={modules}
                text={ isActive ? "Disable" : "Enable" } 
              />
            </Stack>
          );
        }
      },
    },
  ];

  const { isLoading, isError, data, error } = useQuery(
    ["getPositions"],
    async () =>
      await fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/positions/`,
        config
      ),
    {
      enabled: true,
    }
  );

  if (isLoading) return <LinearProgress />;

  if (isError) return <Alert severity="error">{error.message}</Alert>;

  return (
    <>
      <Stack spacing={3} sx={{ mb: 3 }}>
        <AddPositionModal modules={modules} />

        {mutation.isLoading && (
          <AlertPopup open={true} severity={"info"} message={"Updating Position..."} />
        )}

        { mutation.isSuccess && (
          <AlertPopup open={true} message={"Position updated successfully!"} />
        )}

        { mutationError && (
          <AlertPopup open={true} message={mutationError} severity={"error"} />
        ) }

        <div style={{ height: "70vh", width: "100%", mt: 3 }}>
          <DataGridPremium
            rows={data.positions.map((position) => {
              return {
                id: position.id,
                job_title: position.job_title,
                rem_channel_grade: position.rem_channel_grade,
                approved: position.approved,
                is_active: position.is_active,
                actions: position,
              };
            })}
            columns={columns}
            density="comfortable"
            slots={{ toolbar: GridToolbar }}
          />
        </div>
      </Stack>
    </>
  );
};

export default withAuth(OECPositions);
