import {
  FormControlLabel,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  InputLabel,
  Tooltip,
  Typography,
  Switch,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import SelectWrapper from "../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import DateSelectorWrapper from "../../../Components/FormComponents/DateSelectorWrapper";

import withAuth from "../../../Hoc/withAuth";
import { useSelector } from "react-redux";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";

const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
};

const OEHROnboarding = ({
  employee_id,
  InternalInfo,
  submitted,
  modules,
  EmployeeInfo,
  handleTabChange,
  disabled,
}) => {
  const queryClient = useQueryClient();
  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };
  const [mutationError, setMutationError] = useState("");
  const [successMessage, setSuccessMessage] = useState(
    "HR Onboarding Information saved successfully!"
  );

  const switchTab = (newTabIndex) => {
    handleTabChange(newTabIndex);
  };

  const yesOrNo = [
    {
      value: true,
      label: "True",
    },
    {
      value: false,
      label: "False",
    },
  ];

  const mutation = useMutation({
    mutationFn: (values) => {
      if (InternalInfo) {
        setSuccessMessage("HR Onboarding Information updated successfully!");
        return axios.put(
          `${process.env.REACT_APP_API_URL}/onboarding/employees/internalInfo/${values.employee_id}`,
          { ...values },
          config
        );
      } else {
        return axios.post(
          `${process.env.REACT_APP_API_URL}/onboarding/employees/internalInfo/`,
          { ...values },
          config
        );
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["EmployeeInfo"]);
    },
    onError: async (error) => {
        if (error.response.status === 422) {
            setMutationError(error.response.data.validationErrors.cell_number)
        }else{
            setMutationError(error.response.data.message)
        }    
    },
  });

  const { data } = useQuery(
    ["getDepartments"],
    async () =>
      await fetchData(
        `${process.env.REACT_APP_API_URL}/onboarding/departments`,
        config
    )
  );

  let orgUnits = [{
    label: 'Sales',
    value: 'Sales'
  }]

  if(data?.departments){
    orgUnits = data?.departments?.map((department) => {
        return {
          value: department?.department_name,
          label: department?.department_name,
        };
      });
  }

  const onSaveEmployeeInternalInfo = (values) => {
    mutation.mutate(values);
  };

  const onSubmitFormHandler = () => {
    mutation.mutate({ employee_id,  form_submitted_status: 'SUBMITTED' })
  }

  const disabledInput = InternalInfo?.form_submitted_status === 'SUBMITTED' ? true : false

  return (
    <Box component="div">
      <Stack spacing={2} mb={2} mt={2}>
        <Typography variant="span">INTERNAL INFORMATION</Typography>
      </Stack>

      {mutation.isLoading && (
        <AlertPopup open={true} message={"Saving Tax Information..."} severity={"info"} />
      )}

      {mutation.isSuccess && (
        <AlertPopup open={true} message={successMessage} />
      )}

      {mutationError && (
        <AlertPopup open={true} message={mutationError} severity={"error"} />
      )}

      <Formik
        initialValues={{
          employee_id,
          employee_number: InternalInfo?.employee_number || "",
          email: InternalInfo?.email || "",
          rem_job_name:
            EmployeeInfo?.Requisition?.Position?.rem_channel_name ||
            InternalInfo?.rem_job_name ||
            "",
          rem_job_grade:
            EmployeeInfo?.Requisition?.Position?.rem_channel_grade ||
            InternalInfo?.Requisition?.Position?.rem_job_grade ||
            "",
          rem_job_code:
            EmployeeInfo?.Requisition?.Position?.rem_channel_code ||
            InternalInfo?.rem_job_code ||
            "",
          fsca_registered: InternalInfo?.fsca_registered || false,
          effective_date: InternalInfo?.effective_date || new Date(),
          pay_automatical: InternalInfo?.pay_automatical || true,
          hours_per_day: InternalInfo?.hours_per_day || "8",
          days_per_month: InternalInfo?.days_per_month || "21.67",
          pay_rate: InternalInfo?.pay_rate || "",
          thirteenth_cheque: InternalInfo?.thirteenth_cheque || false,
          is_annual: InternalInfo?.is_annual || false,
          monday: InternalInfo?.monday || true,
          tuesday: InternalInfo?.tuesday || true,
          wednesday: InternalInfo?.wednesday || true,
          thursday: InternalInfo?.thursday || true,
          friday: InternalInfo?.friday || true,
          saturday: InternalInfo?.saturday || false,
          sunday: InternalInfo?.sunday || false,
          group_join_date: InternalInfo?.group_join_date || "",
          employment_date: InternalInfo?.employment_date || "",
          position_filled_since:
            InternalInfo?.position_filled_since || new Date(),
          org_unit_name: InternalInfo?.org_unit_name || "",
        }}
        validationSchema={yup.object().shape({
          employee_number: yup
            .string()
            .min(6, "Employee number should be 6 characters")
            .max(6, "Employee number should be 6 characters")
            .required("Employee number is required"),
          email: yup
            .string()
            .required("Email is required")
            .email("Invalid email format"),
          fsca_registered: yup.string().required("FSCA is required"),
          pay_rate: yup.string().required("Pay Rate is required"),
          effective_date: yup.string().required("Efective date is required"),
          pay_automatical: yup.string().required("Pay automatical is required"),
          hours_per_day: yup.string().required("Days per month is required"),
          days_per_month: yup.string().required("Hours per day is required"),
          thirteenth_cheque: yup
            .string()
            .required("Thirteenth cheque is required"),
          is_annual: yup.string().required("Is annual is required"),
          group_join_date: yup.string().required("Group join date is required"),
          employment_date: yup.string().required("Employment date is required"),
          position_filled_since: yup
            .string()
            .required("Position filled since date is required"),
          org_unit_name: yup
            .string()
            .required("Organisation unit name is required"),
        })}
        onSubmit={(values) => {
          onSaveEmployeeInternalInfo(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"employee_number"}
                    placeholder={"Employee Number"}
                    label="Employee Number"
                    autoComplete={"off"}
                    disabled={disabledInput}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title="1st 3 letters of the employee’s surname followed by a number (001, 002, 003 etc.) 
                          depending on what employee numbers have already been used. Look for existing employee numbers on PaySpace."
                          >
                            <IconButton color="secondary">
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"email"}
                    placeholder={"Email"}
                    label="Email"
                    autoComplete={"off"}
                    disabled={disabledInput}
                    type="text"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Obtain the email address from IT.">
                            <IconButton color="secondary">
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="fsca_registered"
                    label="FSCA Registered?"
                    options={yesOrNo}
                    disabled={disabledInput}
                    autoComplete={"off"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"pay_rate"}
                    placeholder={"Pay Rate"}
                    disabled={disabledInput}
                    label="Pay Rate"
                    autoComplete={"off"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="pay_automatical"
                    label="Pay Package Automatically"
                    disabled={disabledInput}
                    options={yesOrNo}
                    autoComplete={"off"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"hours_per_day"}
                    placeholder={"Hours Per Day"}
                    disabled={disabledInput}
                    label="Hours Per Day"
                    autoComplete={"off"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper
                    name={"days_per_month"}
                    placeholder={"Days Per Month"}
                    disabled={disabledInput}
                    label="Days Per Month"
                    autoComplete={"off"}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputLabel>Working Days</InputLabel>
                  <Typography fontSize={15}>
                    Indicate the standard working days employed.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack spacing={5} direction={"row"}>
                    <FormControlLabel
                      control={
                        <Switch
                        disabled={disabledInput}
                          checked={values.monday}
                          onChange={(e) =>
                            setFieldValue("monday", !values.monday)
                          }
                        />
                      }
                      label={"Monday"}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.tuesday}
                          disabled={disabledInput}
                          onChange={(e) =>
                            setFieldValue("tuesday", !values.tuesday)
                          }
                        />
                      }
                      label={"Tuesday"}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.wednesday}
                          disabled={disabledInput}
                          onChange={(e) =>
                            setFieldValue("wednesday", !values.wednesday)
                          }
                        />
                      }
                      label={"Wednesday"}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.thursday}
                          disabled={disabledInput}
                          onChange={(e) =>
                            setFieldValue("thursday", !values.thursday)
                          }
                        />
                      }
                      label={"Thursday"}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.friday}
                          disabled={disabledInput}
                          onChange={(e) =>
                            setFieldValue("friday", !values.friday)
                          }
                        />
                      }
                      label={"Friday"}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.saturday}
                          disabled={disabledInput}
                          onChange={(e) =>
                            setFieldValue("saturday", !values.saturday)
                          }
                        />
                      }
                      label={"Saturday"}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.sunday}
                          disabled={disabledInput}
                          onChange={(e) =>
                            setFieldValue("sunday", !values.sunday)
                          }
                        />
                      }
                      label={"Sunday"}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DateSelectorWrapper name="group_join_date" disabled={disabledInput} label="Group Join Date" autoComplete={"off"} inputFormat="dd/MM/yyyy"/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateSelectorWrapper name="employment_date" disabled={disabledInput} label="Employment Date" autoComplete={"off"} inputFormat="dd/MM/yyyy"/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWrapper
                    name="org_unit_name"
                    label="Organisation Unit Name"
                    placeholder={"Organisation Unit Name"}
                    disabled={disabledInput}
                    options={orgUnits}
                    autoComplete={"off"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateSelectorWrapper name="effective_date" disabled={disabledInput} label="Effective Date" autoComplete={"off"} inputFormat="dd/MM/yyyy"/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateSelectorWrapper name="position_filled_since" disabled={disabledInput} label="Position Filled Since?" autoComplete={"off"} inputFormat="dd/MM/yyyy"/>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack spacing={2} direction={"row"} justifyContent="center">
                    <Button
                      variant="contained"
                      sx={{
                        width: 170,
                      }}
                      color="primary"
                      onClick={() => switchTab(4)}
                    >
                      Back
                    </Button>
                    {InternalInfo ? (
                      <Button
                        variant="contained"
                        sx={{
                          width: 170,
                        }}
                        color="primary"
                        type="submit"
                        name="submitAction"
                        value="0"
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          width: 170,
                        }}
                        type="submit"
                        color="primary"
                        name="submitAction"
                        value="0"
                      >
                        Save
                      </Button>
                    )}
                    
                    <Button
                        variant="contained"
                        sx={{
                          width: 170,
                        }}
                        onClick={onSubmitFormHandler}
                        disabled={disabledInput}
                        color="primary"
                        name="submitAction"
                        value="0"
                      >
                        { disabledInput ? 'Submitted' : 'Submit' }
                      </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default withAuth(OEHROnboarding);
