import React from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditClient from "./EditClient";
import DeleteClient from "./DeleteClient";
import { Button, Chip } from "@mui/material";
import ActivateClient from "./ActivateClient";

const ClientsList = ({ list }) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Clients</StyledTableCell>
            <StyledTableCell align="right">Active</StyledTableCell>
            <StyledTableCell align="right">View</StyledTableCell>
            <StyledTableCell align="right">Edit</StyledTableCell>
            <StyledTableCell align="right">Delete</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list?.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {row?.name}
              </StyledTableCell>
              <StyledTableCell align="right">
                <Chip
                  color={row?.active ? "success" : "error"}
                  label={row?.active ? "Active" : "Disabled"}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <Button
                  onClick={() => {
                    navigate(`/CDA/ViewClient/${row.id}`);
                  }}
                  color="primary">
                  View
                </Button>
              </StyledTableCell>
              <StyledTableCell align="right">
                <EditClient client={row} />
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.active ? (
                  <DeleteClient client={row} />
                ) : (
                  <ActivateClient client={row} />
                )}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClientsList;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
