import React from "react";
import { useNavigate } from "react-router-dom";
import { LinearProgress, Stack } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import withAuth from "../../../../Hoc/withAuth";

const ListSaleOrders = ({ filteredRows, isLoading, platform }) => {
  const navigate = useNavigate();

  const columnMeta = [
    "id",
    "state",
    "partner_id",
    "user_id",
    "campaign_id",
    "create_uid",
    "write_uid",
    "create_date",
    "write_date",
    "validity_date",
    "origin",
    "company_id",
    "amount_total",
    "__last_update",
  ];

  const headerMap = new Map();

  columnMeta.forEach((key) =>
    headerMap.set(key, {
      field: key,
      headerName: upperCaseFirstLetter(removeUnderScore(key)),
      hide: !columnMeta.includes(key),
      width: 130,
    })
  );

  console.log("isLoading", isLoading);

  const columns = [...headerMap.values()];

  // const [openCloseModal, setOpenCloseModal] = useState(false)

  // const [saleOrder, setSaleOrder] = useState({})

  const handleClickSaleOrder = (saleOrder) => {
    // This will change to modal
    const { id, row } = saleOrder;
    navigate(`/CDA/SalesOrders/${id}`, {
      state: { data: { ...row }, platform: platform },
    });
  };

  return (
    <div>
      <DataGridPremium
        onRowClick={handleClickSaleOrder}
        pagination
        autoHeight
        rows={filteredRows}
        columns={columns}
        loading={isLoading}
        slots={{
          toolbar: SalesOrderQuickSearchToolBar,
          loadingOverlay: LinearProgress,
        }}
        getRowId={(row) => row.id}
      />
    </div>
  );
};

export default withAuth(ListSaleOrders);

function SaleOrderCustomToolBar() {
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector />
  </GridToolbarContainer>;
}

function SalesOrderQuickSearchToolBar() {
  return (
    <Stack sx={{ m: 1, py: 1 }}>
      <SaleOrderCustomToolBar />
      <GridToolbarQuickFilter
        sx={{ border: 1, borderColor: "primary.main", borderRadius: 1, p: 2 }}
        quickFilterParser={(value) => {
          return value
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "");
        }}
      />
    </Stack>
  );
}

const removeUnderScore = (str) =>
  [...str].includes("_") ? str.replace(/_/g, " ").trim() : str;

const upperCaseFirstLetter = (str) =>
  [str]
    .map((str) =>
      str.includes(" ")
        ? str
            .split(" ")
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join(" ")
        : str[0].toUpperCase() + str.slice(1)
    )
    .join(" ");
