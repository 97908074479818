const iState = {
  error: false,
  isLoading: false,
};

const managerReducer = (state = iState, action) => {
  switch (action.type) {
    case "GET_ALL_MANAGER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ALL_MANAGER_SUCCESS":
      return {
        ...state,
        isLoading: false,
        managersList: action.payload,
      };
    case "GET_ALL_MANAGER_FAIL":
      return {
        ...state,
        isLoading: false,
      };
    case "ADD_MANAGER_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "ADD_MANAGER_SUCCESS":
      return {
        ...state,
        isLoading: false,
        managersList: action.payload,
      };
    case "ADD_MANAGER_FAIL":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default managerReducer;

const initialState = {
  isLoading: null,
  errors: {},
  message: null,
};

export const positionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_POSITION_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        positionAdded: null,
        validationErrors: null,
        errors: {
          addPositionError: true,
        },
      };
    case "ADD_POSITION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        positionAdded: action.payload.position,
        message: action.payload.message,
        errors: {
          addPositionError: false,
        },
      };
    case "ADD_POSITION_FAIL":
      return {
        ...state,
        isLoading: false,
        message: null,
        errors: {
          addPositionError: true,
        },
        validationErrors: action.payload.validationErrors,
      };
    case "GET_POSITION_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        positions: null,
      };
    case "GET_POSITION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        positions: action.payload,
        errors: {
          fetchedError: false,
        },
      };
    case "GET_POSITION_FAIL":
      return {
        ...state,
        isLoading: false,
        message: null,
        errors: {
          fetchedError: true,
        },
      };

    case "EDIT_POSITION_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
      };
    case "EDIT_POSITION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Position edited successfully",
        errors: {
          editPositionError: false,
        },
        editPositionError: false,
      };
    case "EDIT_POSITION_FAIL":
      return {
        ...state,
        isLoading: false,
        message: null,
        errors: {
          editPositionError: true,
        },
        editPositionError: true,
      };

    case "APPROVE_POSITION_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
      };
    case "APPROVE_POSITION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Position approved successfully",
        errors: {
          approvePositionError: false,
        },
      };
    case "APPROVE_POSITION_FAIL":
      return {
        ...state,
        isLoading: false,
        message: null,
        errors: {
          approvePositionError: true,
        },
      };
    case "DELETE_POSITION_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
      };
    case "DELETE_POSITION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Position deleted successfully",
        errors: {
          deletePositionError: false,
        },
      };
    case "DELETE_POSITION_FAIL":
      return {
        ...state,
        isLoading: false,
        message: null,
        errors: {
          rejectPositionError: true,
        },
      };
    case "REJECT_POSITION_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
      };
    case "REJECT_POSITION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Position rejected",
        errors: {
          rejectPositionError: false,
        },
      };
    case "REJECT_POSITION_FAIL":
      return {
        ...state,
        isLoading: false,
        message: "Error happened",
        errors: {
          rejectPositionError: true,
        },
      };
    case "SAVE_EMPLOYEE_INTERNAL_INFO_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          saveInternalError: null,
        },
      };
    case "SAVE_EMPLOYEE_INTERNAL_INFO_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Employee info saved, you can submit the whole form now",
        errors: {
          saveInternalError: false,
        },
      };
    case "SAVE_EMPLOYEE_INTERNAL_INFO_FAIL":
      return {
        ...state,
        isLoading: false,
        message: null,
        errors: {
          saveInternalError: true,
        },
      };
    case "EDIT_EMPLOYEE_INTERNAL_INFO_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          editInternalError: null,
        },
      };
    case "EDIT_EMPLOYEE_INTERNAL_INFO_SUCCESS":
      return {
        ...state,
        isLoading: false,
        message: "Employee info saved, you can submit the whole form now",
        errors: {
          editInternalError: false,
        },
      };
    case "EDIT_EMPLOYEE_INTERNAL_INFO_FAIL":
      return {
        ...state,
        isLoading: false,
        message: null,
        errors: {
          editInternalError: true,
        },
      };
    case "RESET_POSITION_STATE":
      return {
        ...state,
        isLoading: false,
        message: null,
        errors: {},
      };
    default:
      return {
        ...state,
      };
  }
};

const initialDepartmentState = {
  errors: {},
  isLoading: null,
  message: null,
  departments: null,
  requisitions: null,
  requisition: null,
};

export const departmentReducer = (state = initialDepartmentState, action) => {
  switch (action.type) {
    case "GET_ALL_DEPARTMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          ...state.errors,
          getDepartmentsError: null,
        },
      };
    case "GET_ALL_DEPARTMENT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          getDepartmentsError: false,
        },
        departments: action.payload,
      };
    case "GET_ALL_DEPARTMENT_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          getDepartmentsError: true,
        },
      };
    case "SAVE_DEPARTMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          ...state.errors,
          saveDepartmentError: null,
        },
      };
    case "SAVE_DEPARTMENT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          saveDepartmentError: false,
        },
        message: "Department added successfully",
      };
    case "SAVE_DEPARTMENT_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          saveDepartmentError: true,
        },
        message: "Error happened saving department",
      };
    case "EDIT_DEPARTMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          editDepartmentError: null,
        },
      };
    case "EDIT_DEPARTMENT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          editDepartmentError: false,
        },
        message: "Department editted successfully",
      };
    case "EDIT_DEPARTMENT_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          saveDepartmentError: true,
        },
        message: "Error happened editing department",
      };
    case "GET_ALL_REQUISITION_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          getRequisitionsError: null,
        },
      };
    case "GET_ALL_REQUISITION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          getRequisitionsError: false,
        },
        requisitions: action.payload.requisitions,
        availableRequisitions: action.payload.availableRequisitions,
      };
    case "GET_ALL_REQUISITION_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          getRequisitionsError: true,
        },
      };

    case "GET_REQUISITION_BY_ID_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          getRequisitionError: null,
        },
      };
    case "GET_REQUISITION_BY_ID_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          getRequisitionError: false,
        },
        requisition: action.payload,
      };
    case "GET_REQUISITION_BY_ID_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          getRequisitionError: true,
        },
      };

    case "SAVE_REQUISITION_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          addRequisitionError: null,
        },
      };
    case "SAVE_REQUISITION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          addRequisitionError: false,
        },
        message: "Requisition added successfully",
      };
    case "SAVE_REQUISITION_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          addRequisitionError: true,
        },
        message: "Error happened adding requisition",
      };

    case "EDIT_REQUISITION_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          editRequisitionError: null,
        },
      };
    case "EDIT_REQUISITION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          editRequisitionError: false,
        },
        message: "Requisition editted successfully",
      };
    case "EDIT_REQUISITION_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          editRequisitionError: true,
        },
        message: "Error happened editing requisition",
      };
    case "APPROVE_REQUISITION_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          approveRequisitionError: null,
        },
      };
    case "APPROVE_REQUISITION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          approveRequisitionError: false,
        },
        message: "Requisition approved successfully",
      };
    case "APPROVE_REQUISITION_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          approveRequisitionError: true,
        },
        message: "Error happened approving requisition",
      };

      case "REJECT_REQUISITION_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          rejectRequisitionError: null,
        },
      };
    case "REJECT_REQUISITION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          rejectRequisitionError: false,
        },
        message: "Requisition rejected successfully",
      };
    case "REJECT_REQUISITION_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          rejectRequisitionError: true,
        },
        message: "Error happened rejecting requisition",
      };

    case "DELETE_REQUISITION_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          deleteRequisitionError: null,
        },
      };
    case "DELETE_REQUISITION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          deleteRequisitionError: false,
        },
        message: "Requisition deleted successfully",
      };
    case "DELETE_REQUISITION_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          deleteRequisitionError: true,
        },
        message: "Error happened deleting requisition",
      };
    case "DELETE_DEPARTMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          deleteDepartmentError: null,
        },
      };
    case "DELETE_DEPARTMENT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          deleteDepartmentError: false,
        },
        message: "Department deleted successfully",
      };
    case "DELETE_DEPARTMENT_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          deleteDepartmentError: true,
        },
        message: "Error deleting department",
      };

    case "ENABLE_DEPARTMENT_REQUEST":
      return {
        ...state,
        isLoading: true,
        message: null,
        errors: {
          enableDepartmentError: null,
        },
      };
    case "ENABLE_DEPARTMENT_SUCCESS":
      return {
        ...state,
        isLoading: false,
        errors: {
          enableDepartmentError: false,
        },
        message: "Department enabled successfully",
      };
    case "ENABLE_REQUISITION_FAIL":
      return {
        ...state,
        isLoading: false,
        errors: {
          enableDepartmentError: true,
        },
        message: "Error enabling department",
      };
    case "RESET_DEPARTMENT_STATE":
      return {
        ...state,
        errors: {},
        isLoading: null,
        message: null,
        requisition: null,
      };
    default:
      return {
        ...state,
      };
  }
};
