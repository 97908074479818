import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import LogoDark from "../../assets/TheNodeDark.png";
import LogoLight from "../../assets/TheNodeLight.png";
import axios from "axios";
import { Alert, CircularProgress, Stack } from "@mui/material";
import { useTheme } from "@emotion/react";
import * as yup from "yup";
import { useFormik } from "formik";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export default function ForgottenPassword() {
  let theme = useTheme();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [response, setResponse] = React.useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/forgottenPassword`,
          { email: values.email.toLowerCase() }
        );
        setResponse(data.message);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    },
  });

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
      <img
        style={{ width: "40%" }}
        src={theme.palette.mode === "light" ? LogoDark : LogoLight}
        alt=""
      />
      <Typography component="h1" variant="h5">
        Forgotten Password
      </Typography>

      <Box style={{ width: "40%" }} noValidate sx={{ mt: 5 }}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="email"
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          {loading ? (
            <Stack display="flex" justifyContent="center">
              <Alert severity="info">Sending Email Please wait...</Alert>
              <Box mt={1} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            </Stack>
          ) : (
            <>
              {response ? (
                <Alert severity="success">
                  Check Your Email for a reset link.
                </Alert>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}>
                  Reset Password
                </Button>
              )}
            </>
          )}
        </form>

        {error && (
          <Alert severity="error"> {JSON.stringify(error?.message)}</Alert>
        )}
        <Grid container>
          <Grid item xs>
            <Link
              style={{ textDecoration: "none" }}
              to="/sign-in"
              variant="body2">
              <Button>
                <Typography color="textPrimary">Login?</Typography>
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Box>
  );
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {"Copyright © "}
      <Link
        style={{ textDecoration: "none" }}
        color="inherit"
        to="https://cdasolutions.co.za">
        CDA Solutions
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
