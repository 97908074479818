import { Button, Card, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  GetAllQueueEvaluations,
  getQaQueueById,
  getQaQueueCampaigns,
} from "./State/QaDuck";
import QueueEvaluationsList from "./Components/Evaluations/QueueEvaluationsList";

const QueueEvaluations = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllQueueEvaluations(id));
    dispatch(getQaQueueById(id));
    dispatch(getQaQueueCampaigns(id));
  }, [dispatch, id]);

  const Qa = useSelector((state) => state.Qa);
  const { queueEvaluations } = Qa;

  return (
    <div>
      <Card>
        <Stack
          sx={{ border: 1, p: 2 }}
          display="flex"
          justifyContent="center"
          alignContent="center">
          <Typography gutterBottom variant="h6">
            Evaluations
          </Typography>
          <Stack>
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/CDA/Evaluation/CreateNewEvaluation/${id}`);
              }}>
              Create new Evaluation
            </Button>
          </Stack>
        </Stack>
      </Card>

      {queueEvaluations && queueEvaluations.length > 0 && (
        <QueueEvaluationsList id={id} queueEvaluations={queueEvaluations} />
      )}
    </div>
  );
};

export default QueueEvaluations;
