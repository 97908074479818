import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import AddUserType from "./AddUserType";
import { useSelector } from "react-redux";
import AlertPopup from "../../../Components/Bits/AlertPopup";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserTypeDialog({ edit }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AdminUsers = useSelector((state) => state.AdminUsers);
  const { addUserRoleSuccess } = AdminUsers;

  return (
    <div>
      <Button variant="outlined" color="warning" onClick={handleClickOpen}>
        Change User Type (role)
      </Button>

      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{"Change User Type (role)"}</DialogTitle>
        <DialogContent>
          <AddUserType edit={edit} />
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <AlertPopup
        open={addUserRoleSuccess}
        message="Role changed successfully"
        severity="success"
      />
    </div>
  );
}
