import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";
import { DialogContent } from "@mui/material";
import CheckboxWrapper from "../../../../../../../Components/FormComponents/CheckboxWrapper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { addLeadSource } from "../../State/LeadsDuck";

export default function AddLeadSource() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add New Lead Source
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle> Add New Lead Source</DialogTitle>

        <Formik
          initialValues={{
            name: "",
            active: true,
            updated_reason: "Created New Lead Source",
            api_flag: false,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            active: Yup.boolean().required("Active is required"),
          })}
          onSubmit={(values) => {
            dispatch(addLeadSource(values));
            setTimeout(() => {
              handleClose();
            }, 1000);
          }}
        >
          {() => (
            <Form>
              <DialogContent>
                <TextfieldWrapper label="Lead Source Name" name="name" />
                {
                  // add check box for api_flag here, default is false
                }
                <CheckboxWrapper label="API Source?" name="api_flag" />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Save</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
