import { Stack, LinearProgress, Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import withAuth from "../../../Hoc/withAuth";
import axios from 'axios'
import AddEditDepartmentModal from '../modals/AddEditDepartmentModal'


const fetchData = async (url, config) => {
  try {
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
}

const OEMDepartments = ({ user, modules }) => {

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const columns = [
    {
      field: "department_name",
      headerName: "Department",
      width: 350,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "manager",
      headerName: "Manager",
      width: 350,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <AddEditDepartmentModal
            text={"View"}
            modules={modules}
            department={{
              id: params.row.id,
              department_name: params.row.department_name,
              manager_node_id: params.row.actions,
            }}
            color="primary"
          />
        </Stack>
      ),
    },
  ];


  // get departments
 const { isLoading, isError, data, error } = useQuery(
    ["getDepartments"],
    async () => await fetchData(`${process.env.REACT_APP_API_URL}/onboarding/departments`, config),
    {
      enabled: true,
    }
  );
  
  if (isLoading) return <LinearProgress />

  if (isError) return <Alert severity="error">{error.message}</Alert>

  return (
    <>
      <Stack sx={{ mb: 5 }}>
        <div style={{ height: "70vh", width: "100%", mt: 3 }}>
          <DataGridPremium
            rows={data.departments.map((dept) => ({
              id: dept.id,
              department_name: dept.department_name,
              manager: `${dept?.User?.name || ""} ${dept?.User?.surname || ""}`,
              actions: dept?.User?.id,
            }))}
            columns={columns}
            density="comfortable"
            slots={{ toolbar: GridToolbar }}
          />
        </div>
      </Stack>
    </>
  );
};

export default withAuth(OEMDepartments);
