// AUTH ACTIONS
export const LOGIN = "LOGIN";
export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_ERROR = "AUTH_ERROR";

//RMA ACTIONS

//Admin Actions

export const RESET_ADMIN_USER_STATE = "RESET_ADMIN_USER_STATE";

export const GET_ADMIN_USERS = "GET_ADMIN_USERS";
export const GET_ADMIN_USERS_SUCCESS = "GET_ADMIN_USERS_SUCCESS";
export const GET_ADMIN_USERS_FAILURE = "GET_ADMIN_USERS_FAILURE";

export const GET_ADMIN_USER = "GET_ADMIN_USER";
export const GET_ADMIN_USER_SUCCESS = "GET_ADMIN_USER_SUCCESS";
export const GET_ADMIN_USER_FAILURE = "GET_ADMIN_USER_FAILURE";

export const ADMIN_EDIT_USER = "ADMIN_EDIT_USER";
export const ADMIN_EDIT_USER_SUCCESS = "ADMIN_EDIT_USER_SUCCESS";
export const ADMIN_EDIT_USER_FAILURE = "ADMIN_EDIT_USER_FAILURE";

export const ADD_USER_ROLE = "ADD_USER_ROLE";
export const ADD_USER_ROLE_SUCCESS = "ADD_USER_ROLE_SUCCESS";
export const ADD_USER_ROLE_FAILURE = "ADD_USER_ROLE_FAILURE";

//User Actions
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE";

export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const DELETE_USER_RESET = "DELETE_USER_RESET";

export const RESTORE_USER = "RESTORE USER";
export const RESTORE_USER_SUCCESS = "RESTORE USER_SUCCESS";
export const RESTORE_USER_FAILURE = "RESTORE USER_FAILURE";

export const POPULATE_USER = "POPULATE_USER";

export const GET_USERS_OF_MODULE = "GET_USERS_OF_MODULE";
export const GET_USERS_OF_MODULE_SUCCESS = "GET_USERS_OF_MODULE_SUCCESS";
export const GET_USERS_OF_MODULE_FAILURE = "GET_USERS_OF_MODULE_FAILURE";
