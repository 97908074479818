import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import withAuth from "../../../Hoc/withAuth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import MultiLineTextFieldWrapper from "../Components/MultiLineTextFieldWrapper";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import axios from "axios";

const RejectPositionModal = ({ user, models, position }) => {
  const queryClient = useQueryClient();

  const [mutationError, setMutationError] = useState("");
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AuthDuck = useSelector((state) => state.AuthDuck);
  const { userInfo } = AuthDuck;

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const mutation = useMutation({
    mutationFn: (values) => {
      if (values.type === "reject") {
        // reject requisition
        return axios.put(
          `${process.env.REACT_APP_API_URL}/onboarding/positions/reject/${values.id}`,
          values.body,
          config
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getPositions"]);
    },
    onError: (error) => {
      setMutationError(error.response.data.message);
    },
  });

  const onRejectPositionHandler = (id, body, reqType = "reject") => {
    mutation.mutate({ id, body: body.reject_reason, type: reqType });
    handleClose();
  };

  return (
    <Stack spacing={0}>
      <Button color="error" variant="outlined" onClick={handleClickOpen}>
        Reject
      </Button>
      <Stack>
        

        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          fullWidth
          aria-labelledby="responsive-dialog-title"
          sx={{ borderRadius: 0 }}
        >
          {mutation.isLoading && (
          <AlertPopup
            open={true}
            severity={"info"}
            message={"Updating Position..."}
          />
        )}

        {mutation.isError && (
          <AlertPopup open={true} message={mutation.error} severity={"error"} />
        )}

        {mutation.isSuccess && (
          <AlertPopup
            open={true}
            severity={"success"}
            message={"Position updated successfully!"}
          />
        )}

        {mutationError && (
          <AlertPopup open={true} severity={"error"} message={mutationError} />
        )}
          <DialogTitle id="responsive-dialog-title">
            Reject Position
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                id: position?.id || "",
                job_title: position?.job_title || "",
                reject_reason: "",
              }}
              validationSchema={yup.object().shape({
                job_title: yup.string().required("Job title required"),
                reject_reason: yup.string().required("Please provide a reason"),
              })}
              onSubmit={(values) => {
                onRejectPositionHandler(position?.id, values);
              }}
              enableReinitialize={true}
            >
              {(values, errors) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <TextfieldWrapper
                          name={"job_title"}
                          label="Job Title"
                          sx={{ mt: 2 }}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MultiLineTextFieldWrapper
                          name={"reject_reason"}
                          label="Reject Reason"
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Box textAlign="end">
                          <Button
                            autoFocus
                            onClick={handleClose}
                            variant="contained"
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button
                            type="submit"
                            color="error"
                            autoFocus
                            variant="contained"
                          >
                            Reject
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default withAuth(RejectPositionModal);
