import React, { useState } from "react";
import { Button, Stack } from "@mui/material";
import { Formik, Form } from "formik";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const InboundDisputeEvaluation = ({ user, updateEvaluation, evaluation }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

 

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values) => {
    const newHistoryEntry = {
      status: "Dispute Requested",
      reason: values.note,
      createdBy: user.email,
      date: new Date().toISOString(),
      start_time: evaluation?.start_time,
      end_time: evaluation?.end_time,
    };

    const currentEvaluation = evaluation;

    const updatedHistory = [...currentEvaluation.history, newHistoryEntry];

    const data = {
      status: "Dispute Requested",
      history: updatedHistory,
    };
    updateEvaluation.mutate(data);
  };

  const isDisabled = evaluation.status !== "Failed";

  return (
    <Stack>
      <Button
        variant="outlined"
        sx={{ height: "50px" }}
        color="error"
        onClick={handleClickOpen}
        disabled={isDisabled}
      >
        Dispute Evaluation
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Reason for Dispute"}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ note: "" }}
            validationSchema={Yup.object({
              note: Yup.string().required("Reason is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              handleSubmit(values);
              setSubmitting(false);
            }}
          >
            <Form>
              <TextfieldWrapper
                sx={{ mt: 1, minWidth: 500 }}
                name="note"
                label="Enter reason Dispute"
                multiline
                rows={4}
              />
              <Stack
                direction="row"
                spacing={1}
                sx={{ mt: 1 }}
                justifyContent={"flex-end"}
              >
                <Button onClick={handleClose}>Close</Button>
                <Button
                  sx={{ mt: 1 }}
                  color="secondary"
                  type="submit"
                  onClick={handleClose}
                >
                  Submit
                </Button>
              </Stack>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default InboundDisputeEvaluation;
