import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

import React from "react";

const LeadsDataGrid = ({ data, loading }) => {
  // return keys of the first object in the array
  let keys = [];

  if (data.length > 0) {
    keys = Object.keys(data[0]);
  }
  const VISIBLE_FIELDS = ["_id", "first_name", "surname", "mobile_no", "id_no"];

  let columns = keys.map((key) => {
    return {
      field: key,
      headerName: key,
      hide: !VISIBLE_FIELDS.includes(key),
      width: 130,
    };
  });

  return (
    <div style={{ height: "70vh", width: "100%" }}>
      <DataGridPremium
        rows={data}
        columns={columns}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        getRowId={(row) => row._id}
      />
    </div>
  );
};

export default LeadsDataGrid;
