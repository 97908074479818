import { Card, Stack} from "@mui/material";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";

import PageHeader from "../../../../../../../../Components/Bits/PageHeader";
import withAuth from "../../../../../../../../Hoc/withAuth";
import BarGraph from "./BarGraph";
import PieChartt from "./PieChartt";
import Cards from "./Cards";

const Statistics = ({ user }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const { data: evaluationData } = useQuery(
    ["getAllEvaluations"],
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/user/`,
        config
      );
      return response?.data?.data;
    },
    {
      onError: (error) => {
        console.error("Error fetching evaluations:", error);
      },
    }
  );

  const validRows = evaluationData || [];

  // Data transformation to count evaluations

  return (
 <div>
      <PageHeader
        title="QA Agent Results"
        subheader="View QA Agent results tracking"
      />
      <Card variant="outlined" sx ={{mb: 2}}>
      <Cards validRows={validRows}/>
      </Card>
      <Card variant="outlined">
      <Stack direction="row" justifyContent="space-between">
      <BarGraph validRows={validRows} />
      <PieChartt validRows={validRows} />
      </Stack>
      </Card>
     
      </div>
  );
};

export default withAuth(Statistics);
