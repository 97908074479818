import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import withAuth from "../../../../Hoc/withAuth";
// Material
import { styled } from "@mui/material/styles";
import {
  Button,
  Stack,
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  Link,
  TextField,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Box } from "@mui/system";
import FileOpenIcon from "@mui/icons-material/FileOpen";
// Date Picker Components
import { enZA } from "date-fns/locale";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//State
import { getSchemas } from "../StateManager/FileUploader/SchemesDuck";
import { GetTemplates } from "../StateManager/FileUploader/TemplatesDuck";
import {
  getAllMyFiles,
  RESET_FILE_UPLOAD,
  UploadFile,
} from "../StateManager/FileUploader/FilesDuck";

//App Components
import PageHeader from "../../../../Components/Bits/PageHeader";
import MainAppContainer from "../../../../Components/Containers/MainAppContainer";
import AlertPopup from "../../../../Components/Bits/AlertPopup";

const FileUploader = () => {
  const dispatch = useDispatch();

  const [reset, setReset] = useState(false);

  useEffect(() => {
    dispatch(GetTemplates());
    dispatch(getSchemas());
    dispatch(getAllMyFiles());
  }, [dispatch, reset]);

  const [formData, setFormData] = useState({
    template: "",
    company: "",
    policyNumber: "",
  });

  const Templates = useSelector((state) => state.Templates);
  const { templates } = Templates;

  const Schemas = useSelector((state) => state.Schemas);
  const { schemas } = Schemas;

  const FileUploader = useSelector((state) => state.FileUploader);
  const { files, uploadedFile, isError, error } = FileUploader;

  const [document, setDocument] = useState("");

  const handleFileChange = (event) => {
    setDocument(event.target.files[0]);
  };

  const [joinDate, setJoinDate] = useState(dayjs());

  const handleJoinDate = (date) => {
    setJoinDate(date);
  };

  const [astuteOptions, setAstuteOptions] = React.useState("full");

  const handleChange = (event) => {
    setAstuteOptions(event.target.value);
  };

  const handleUpload = () => {
    const schema = schemas.find(
      (schema) => schema.company_name === formData.company
    );

    const data = new FormData();
    data.append("file", document);
    data.append("template_id", templates[0].id);
    data.append("scheme_id", schema.id);
    data.append("join_date", joinDate);
    data.append("astute_options", astuteOptions);
    dispatch(UploadFile({ data }));
    setTimeout(() => {
      handleReset();
    }, 5000);
  };

  const handleReset = () => {
    setFormData({
      template: "",
      company: "",
      policyNumber: "",
    });
    setDocument("");
    dispatch({ type: RESET_FILE_UPLOAD });
    setReset(!reset);
  };

  return (
    <MainAppContainer>
      <PageHeader
        title="File Uploader"
        subheader="Manage & Upload your files here"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "File Uploader",
            href: "/RMA/FileUploader",
          },
        ]}
      />

      <Stack direction="column" alignItems="center" spacing={2}>
        <Stack direction="column" spacing={2}>
          {document?.name ? (
            <Typography color="secondary" variant="h6">
              {document?.name}
            </Typography>
          ) : (
            <>
              <Typography color="primary" variant="h6">
                Click To upload file
              </Typography>
            </>
          )}

          {!document ? (
            <label htmlFor="contained-button-file">
              <Input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                id="contained-button-file"
                type="file"
                autoFocus
                onChange={handleFileChange}
              />
              <Button
                variant="contained"
                size="large"
                sx={{ px: 5, py: 2 }}
                startIcon={<FileOpenIcon />}
                fullWidth
                component="span">
                Upload
              </Button>
            </label>
          ) : (
            <Button color="warning" onClick={() => setDocument("")}>
              Clear?
            </Button>
          )}
        </Stack>

        {schemas && schemas.length > 0 && (
          <Autocomplete
            color="primary"
            freeSolo
            fullWidth
            autoSelect
            id="free-solo-2-demo"
            options={schemas.map((option) => {
              return option.company_name;
            })}
            onChange={(event, newValue) => {
              setFormData({ ...formData, company: newValue });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Scheme"
                margin="normal"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        )}

        <LocalizationProvider dateAdapter={AdapterDayjs} locale={enZA}>
          <FormControl margin="normal" fullWidth>
            <DatePicker
              views={["month", "year"]}
              margin="normal"
              label="Join Date"
              name="join_date"
              value={joinDate}
              onChange={handleJoinDate}
              variant="inline"
              inputVariant="outlined"
              fullWidth
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
        </LocalizationProvider>

        <Stack justifyContent="flex-start" sx={{ width: "100%" }}>
          <FormControl>
            <FormLabel id="astute_options">Astute options</FormLabel>
            <RadioGroup
              aria-labelledby="astute_options"
              name="astute_options"
              value={astuteOptions}
              onChange={handleChange}>
              <FormControlLabel
                value="full"
                control={<Radio />}
                label="Run Astute"
              />
              <FormControlLabel
                value="main"
                control={<Radio />}
                label="Run Astute-Main Member Only"
              />
              <FormControlLabel
                value="skip"
                control={<Radio />}
                label="Skip Astute"
              />
            </RadioGroup>
          </FormControl>
        </Stack>

        {uploadedFile ? (
          <Button onClick={handleReset}>Reset</Button>
        ) : (
          <>
            {joinDate && document && formData.company && astuteOptions && (
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={handleUpload}>
                Submit
              </Button>
            )}
          </>
        )}
      </Stack>

      <Grid sx={{ mt: 4 }} container spacing={1}>
        {files &&
          files.length > 0 &&
          files
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((file) => {
              return (
                <Grid key={file.id ? file.id : Math.random()} item xs={12}>
                  <Card>
                    <CardHeader
                      title={file?.org_file}
                      subheader={
                        <Stack>
                          <Typography variant="body2">
                            Scheme : {file?.rma_scheme?.company_name}
                          </Typography>
                          <Typography variant="body2">
                            policy id : {file?.rma_scheme?.policy_id}
                          </Typography>
                        </Stack>
                      }
                      action={`${new Date(file?.createdAt).toLocaleDateString(
                        "en-ZA"
                      )}`}
                    />

                    <CardContent>
                      <Stack direction="row" spacing={2}>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          component="p">
                          Status:
                        </Typography>
                        <Typography
                          color={
                            file?.status === "pending"
                              ? "primary"
                              : file?.status === "completed"
                              ? "success"
                              : file?.status === "failed"
                              ? "error"
                              : file?.status === "processing"
                              ? "warning"
                              : "textSecondary"
                          }
                          variant="h6"
                          component="p">
                          {file.status}
                        </Typography>
                      </Stack>
                    </CardContent>

                    <>
                      <Box
                        p={2}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between">
                        <Button variant="outlined" color="primary">
                          <Link
                            disabled={!file.file_name}
                            sx={{ textDecoration: "none" }}
                            href={`${process.env.REACT_APP_API_URL}/RMA/fileDownload/${file.file_name}/org`}
                            download>
                            <Typography
                              variant="button"
                              color={file.file_name ? "inherit" : "GrayText"}>
                              Original File
                            </Typography>
                          </Link>
                        </Button>
                        <Button
                          variant="outlined"
                          disabled={!file.cleaned_file}
                          color="secondary">
                          <Link
                            disabled={!file.cleaned_file}
                            sx={{ textDecoration: "none" }}
                            href={`${process.env.REACT_APP_API_URL}/RMA/fileDownload/${file.cleaned_file}/cleaned`}
                            download>
                            <Typography
                              variant="button"
                              color={
                                file.cleaned_file ? "inherit" : "GrayText"
                              }>
                              Processed File
                            </Typography>
                          </Link>
                        </Button>
                        <Button
                          variant="outlined"
                          disabled={!file.exception_file}
                          color="error">
                          <Link
                            sx={{ textDecoration: "none" }}
                            href={`${process.env.REACT_APP_API_URL}/RMA/fileDownload/${file.exception_file}/exceptions`}
                            download>
                            <Typography
                              variant="button"
                              color={
                                file.exception_file ? "inherit" : "GrayText"
                              }>
                              Rejections
                            </Typography>
                          </Link>
                        </Button>
                        <Button
                          variant="outlined"
                          disabled={!file.duplicates_file}
                          color="warning">
                          <Link
                            sx={{ textDecoration: "none" }}
                            href={`${process.env.REACT_APP_API_URL}/RMA/fileDownload/${file.duplicates_file}/duplicates`}
                            download>
                            <Typography
                              variant="button"
                              color={
                                file.duplicates_file ? "inherit" : "GrayText"
                              }>
                              Duplicates file
                            </Typography>
                          </Link>
                        </Button>
                        <Button
                          variant="outlined"
                          disabled={!file.updates_file}
                          color="primary">
                          <Link
                            sx={{ textDecoration: "none" }}
                            href={`${process.env.REACT_APP_API_URL}/RMA/fileDownload/${file.updates_file}/updates`}
                            download>
                            <Typography
                              variant="button"
                              color={
                                file.updates_file ? "inherit" : "GrayText"
                              }>
                              Updates File
                            </Typography>
                          </Link>
                        </Button>
                        <Button
                          variant="outlined"
                          disabled={!file.vopd_file}
                          color="primary">
                          <Link
                            sx={{ textDecoration: "none" }}
                            href={`${process.env.REACT_APP_API_URL}/RMA/fileDownload/${file.vopd_file}/vopd`}
                            download>
                            <Typography
                              variant="button"
                              color={file.vopd_file ? "inherit" : "GrayText"}>
                              VOPD File
                            </Typography>
                          </Link>
                        </Button>
                      </Box>
                    </>
                  </Card>
                </Grid>
              );
            })}
      </Grid>

      <AlertPopup
        open={uploadedFile}
        message="File Uploaded Successfully"
        severity="success"
      />

      <AlertPopup
        open={isError}
        message={JSON.stringify(error)}
        severity="error"
      />
    </MainAppContainer>
  );
};

export default withAuth(FileUploader);

const Input = styled("input")({
  display: "none",
});
