import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { EditRmaSurveyCompanyContact } from "../../State/SurveysDuck";

const EditContactForm = ({ rmaSurveyCompany }) => {
  const dispatch = useDispatch();

  const [data, setData] = React.useState({
    Alt_No: rmaSurveyCompany?.Alt_No || "",
    COID_Claims: rmaSurveyCompany?.COID_Claims || "",
    COID_Payout: rmaSurveyCompany?.COID_Payout || "",
    Company_Name: rmaSurveyCompany?.Company_Name || "",
    Contact_Name: rmaSurveyCompany?.Contact_Name || "",
    Designation: rmaSurveyCompany?.Designation || "",
    Email: rmaSurveyCompany?.Email || "",
    Member_No: rmaSurveyCompany?.Member_No || "",
    Mobile_No: rmaSurveyCompany?.Mobile_No || "",
    No_of_employees: rmaSurveyCompany?.No_of_employees || "",
    Premium: rmaSurveyCompany?.Premium || "",
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (rmaSurveyCompany) {
      setData({
        Alt_No: rmaSurveyCompany?.Alt_No || "",
        COID_Claims: rmaSurveyCompany?.COID_Claims || "",
        COID_Payout: rmaSurveyCompany?.COID_Payout || "",
        Company_Name: rmaSurveyCompany?.Company_Name || "",
        Contact_Name: rmaSurveyCompany?.Contact_Name || "",
        Designation: rmaSurveyCompany?.Designation || "",
        Email: rmaSurveyCompany?.Email || "",
        Member_No: rmaSurveyCompany?.Member_No || "",
        Mobile_No: rmaSurveyCompany?.Mobile_No || "",
        No_of_employees: rmaSurveyCompany?.No_of_employees || "",
        Premium: rmaSurveyCompany?.Premium || "",
        note1: rmaSurveyCompany?.note1 || "",
        note2: rmaSurveyCompany?.note2 || "",
        note3: rmaSurveyCompany?.note3 || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rmaSurveyCompany]);

  const handleSubmit = () => {
    dispatch(EditRmaSurveyCompanyContact(rmaSurveyCompany.id, data));
  };

  return (
    <>
      <Stack direction="row">
        <TextField
          fullWidth
          sx={{ m: 1 }}
          margin="normal"
          name="Contact_Name"
          label="Contact Person"
          value={data?.Contact_Name}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="normal" sx={{ m: 1 }}>
          <InputLabel id="demo-simple-select-label">Designation</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={data?.Designation}
            name="Designation"
            label="Designation"
            onChange={handleChange}>
            <MenuItem value="Accountant">Accountant</MenuItem>
            <MenuItem value="Director">Director</MenuItem>
            <MenuItem value="Online User">Online User</MenuItem>
            <MenuItem value="Other Fin Acc">Other Fin Acc</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
            <MenuItem value="no longer with company">
              No longer with company
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row">
        <TextField
          fullWidth
          sx={{ m: 1 }}
          margin="normal"
          name="Mobile_No"
          label="Contact Number"
          value={data?.Mobile_No}
          onChange={handleChange}
        />
        <TextField
          sx={{ m: 1 }}
          fullWidth
          margin="normal"
          name="Alt_No"
          label="Alt Contact Number"
          value={data?.Alt_No}
          onChange={handleChange}
        />
      </Stack>
      <TextField
        sx={{ m: 1 }}
        fullWidth
        margin="normal"
        name="Email"
        label="Email"
        value={data?.Email}
        onChange={handleChange}
      />

      <Stack sx={{ mt: 4 }}>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button>
      </Stack>
    </>
  );
};

export default EditContactForm;
