import { Container } from "@mui/material";

import React from "react";
import { Outlet } from "react-router-dom";

import PageHeader from "../../../../../Components/Bits/PageHeader";

const CallCenterAbsenteeism = () => {
  return (
    <Container>
      <PageHeader
        title="Attendance"
        subheader="Call Center Attendance"
        breadcrumbs={[
          {
            title: "CallCenterAbsenteeism",
            href: "/CDA/CallCenter/Attendance",
          },
          {
            title: "CallCenterCaptureAttendance",
            href: "/CDA/CallCenter/CaptureAttendance",
          },
        ]}
      />
      <Outlet />
    </Container>
  );
};

export default CallCenterAbsenteeism;
