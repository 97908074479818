import * as React from "react";

import Slider from "@mui/material/Slider";
import {
  Grid,
  Input,
  Stack,
  TextField,
  Typography,
  FormGroup,
  Switch,
  FormControlLabel,
} from "@mui/material";

export default function SurveySlider({
  question,
  setQuestionAnswers,
  QuestionAnswers,
  questionNumber,
}) {
  const [value, setValue] = React.useState({
    question_id: question.id,
    _value: 0,
    notes: "",
  });

  console.log(value);

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(!checked);
    setQuestionAnswers(
      QuestionAnswers?.map((question) => {
        if (question.question_id === value.question_id) {
          return {
            ...question,
            _value: 10000,
            answer_id: 10000,
          };
        }
        return question;
      }),
    );
  };

  const handleSliderChange = (event, newValue) => {
    setValue((value) => ({
      ...value,
      _value: newValue,
    }));
    setQuestionAnswers(
      QuestionAnswers?.map((question) => {
        if (question.question_id === value.question_id) {
          return {
            ...question,
            _value: newValue,
            answer_id: 10000,
          };
        }
        return question;
      }),
    );
  };

  const handleNotesChange = (event) => {
    setValue({
      ...value,
      notes: event.target.value,
    });
    setQuestionAnswers(
      QuestionAnswers?.map((question) => {
        if (question.question_id === value.question_id) {
          return {
            ...question,
            notes: event.target.value,
          };
        }
        return question;
      }),
    );
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 5) {
      setValue(5);
    }
  };

  return (
    <Stack my={2}>
      <Typography color="primary" variant="h6">
        {questionNumber} : {question?.question}
      </Typography>

      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <Slider
            sx={{
              my: 2,
            }}
            value={typeof value._value === "number" ? value._value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            label={question.question}
            disabled={checked}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={5}
          />

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={checked} onChange={handleChange} />}
              label="Not Applicable"
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <Input
            value={value._value}
            size="small"
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
      </Grid>

      <TextField
        margin="normal"
        id="notes"
        label="Notes"
        multiline
        helperText="Required"
        rows={2}
        name="notes"
        value={value.notes}
        onChange={handleNotesChange}
      />
    </Stack>
  );
}
