import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import React from "react";

const RadioGroupWrapper = ({ name, label, options, row, ...otherProps }) => {
  const { values, setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  const configRadio = {
    ...field,
    ...otherProps,
    variant: "outlined",

    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configRadio.error = true;
    configRadio.helperText = meta.error;
  }

  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        {...configRadio}
        row={row ? true : false}
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={values[name]}
        onChange={handleChange}
        name="radio-buttons-group">
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupWrapper;
