import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { AddModuleToCompany } from "../../../Redux/Ducks/AdminCompniesDuck";
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditModules({ company_id }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const Companies = useSelector((state) => state.Companies);

  const { modCompanyModulesSuccess, modCompanyModulesError, moduleOptions } =
    Companies;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    company_id: "",
    status: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const addModule = () => {
    let form = {
      name: formData.name,
      company_id: company_id,
      status: "active",
    };
    dispatch(AddModuleToCompany({ form }));
    setFormData({
      name: "",
      company_id: "",
      status: "",
    });
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>Add Module</Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{"Add Module"}</DialogTitle>
        <DialogContent>
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Select Module</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="name"
              value={formData.name}
              label="Select Module"
              onChange={handleChange}>
              {moduleOptions?.map((option, index) => {
                return (
                  <MenuItem key="index" value={option.name}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            close
          </Button>

          {modCompanyModulesError && (
            <Alert severity="error">{modCompanyModulesError}</Alert>
          )}
          {modCompanyModulesSuccess && (
            <Alert severity="success">Success</Alert>
          )}
          <Button variant="contained" onClick={addModule}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
