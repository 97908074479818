import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { Box } from "@mui/system";
import { Button, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { QuestionItem } from "./QuestionItem";
import { questionOrder } from "../../State/SurveysDuck";

const QuestionsList = ({ id, data, noRefresh }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(data.sort((a, b) => a.orderId - b.orderId));
  }, [data]);

  const dispatch = useDispatch();

  let indexes = [];

  const moveCard = useCallback((dragIndex, hoverIndex, id, index) => {
    setList((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const onSave = () => {
    dispatch(questionOrder(id, indexes));
    if (!noRefresh) {
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }
  };

  return (
    <>
      <Box>
        <Typography my={5} variant="body2" color="textSecondary" align="center">
          Drag and drop to reorder Questions
        </Typography>
        {list.map((card, i) => {
          indexes.push({ id: card.id, order: i });
          return (
            <QuestionItem key={i} index={i} data={card} moveCard={moveCard} />
          );
        })}
        <Stack mt={5} display="flex" justifyContent="center">
          <Typography
            my={2}
            variant="body2"
            color="textSecondary"
            align="center">
            If you Changes the order of the questions you have to SAVE!
          </Typography>
          <Button fullWidth color="inherit" variant="outlined" onClick={onSave}>
            Save Order
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default QuestionsList;
