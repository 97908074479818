import { Typography } from "@mui/material";
import React from "react";

const SlateDisplay = ({ content }) => {
  const renderElement = (element) => {
    switch (element.type) {
      case "numbered-list":
        return (
          <ol>
            {element.children.map((el, index) => (
              <li>{el.children.map(renderText)}</li>
            ))}
          </ol>
        );
      case "block-quote":
        return (
          <Typography variant="body1" align="center">
            {element.children.map(renderText)}
          </Typography>
        );
      case "bulleted-list":
        return (
          <ul>
            {element.children.map((el) => (
              <li>{el.children.map(renderText)}</li>
            ))}
          </ul>
        );

      case "paragraph":
        return (
          <Typography
            variant="body1"
            align={element?.align ? element?.align : "left"}>
            {element.children.map(renderText)}
          </Typography>
        );

      case "heading-one":
        return (
          <Typography
            variant="h2"
            align={element?.align ? element?.align : "left"}>
            {element.children.map(renderText)}
          </Typography>
        );

      case "heading-two":
        return (
          <Typography
            variant="h3"
            align={element?.align ? element?.align : "left"}>
            {element.children.map(renderText)}
          </Typography>
        );

      default:
        return null;
    }
  };

  const renderText = (text) => {
    return text.text;
  };

  return <div>{content.map(renderElement)}</div>;
};

export default SlateDisplay;
