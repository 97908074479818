import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";

const CreateNewNote = ({ evaluation_id, user }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const [addNote, setAddNote] = useState(false);

  const queryClient = useQueryClient();

  const createNote = useMutation((note) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/qa/evaluationNotes/${evaluation_id}`,
      note,
      config
    );
  });

  return (
    <Stack mt={2}>
      <AlertPopup
        open={createNote.isSuccess}
        message="Team added to queue"
        severity="success"
      />
      <AlertPopup
        open={createNote.isError}
        message="Error Creating Notes (Contact Support!)"
        severity="error"
      />

      {addNote ? (
        <>
          <Formik
            initialValues={{
              note: "",
              evaluation_id: evaluation_id,
              user_id: user.id,
            }}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              createNote.mutate(values, {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: [`$evaluation_notes`, evaluation_id],
                  });
                },
              });

              // reset form
              setAddNote(!addNote);
              setSubmitting(false);
            }}>
            {({ values, isSubmitting }) => (
              <Form>
                <TextfieldWrapper name="note" label="Note" multiline rows={4} />
                <Button
                  sx={{ mt: 1 }}
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}>
                  Post Note
                </Button>
              </Form>
            )}
          </Formik>
          <Button onClick={() => setAddNote(!addNote)}>Close</Button>
        </>
      ) : (
        <>
          <Button onClick={() => setAddNote(!addNote)}>Add Note</Button>
        </>
      )}
    </Stack>
  );
};

export default CreateNewNote;
