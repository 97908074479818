import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyResponses } from "../../State/SurveyReportsDuck";
import {
  // Button,
  // Grid,
  Typography,
} from "@mui/material";

// import { useNavigate } from "react-router-dom";
// import FeatureCard from "../../../../../../../Components/Containers/FeatureCard";
// import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

const SurveyResults = () => {
  const dispatch = useDispatch();

  // const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSurveyResponses());
  }, [dispatch]);

  const SurveyReports = useSelector((state) => state.SurveyReports);
  const { allResponses } = SurveyReports;

  console.log("allResponses", allResponses);

  const rows2 = allResponses?.map((response, index) => {
    return {
      id: index,
      response_id: response.id,
      survey_name: response["survey_campaign.name"],
      name: response["surveyContact.name"],
      contact_id: response?.contact_id,
      contact_no: response["surveyContact.phone"],
      survey_user: response["survey_user.User.email"],
      createdAt: `${new Date(response.createdAt).toLocaleDateString("en-ZA")}`,
      question_order:
        response["survey_results.survey_campaigns_question.orderId"],
      question: response["survey_results.survey_campaigns_question.question"],
      answer:
        response["survey_results.survey_campaigns_question.question_type"] ===
        "slider"
          ? response["survey_results._value"]
          : response["survey_results.survey_answer.answer"],
      note: response["survey_results.note"],
      question_type:
        response["survey_results.survey_campaigns_question.question_type"],
      // _value: response["survey_results._value"],
    };
  });

  // const renderDetailsButton = (response) => {
  //   return (
  //     <strong>
  //       <Button
  //         color="primary"
  //         size="small"
  //         onClick={() => {
  //           navigate(
  //             `/CDA/ViewSurvey/${response.row.view_survey.id}/${response.value.survey_user_id}`,
  //           );
  //         }}
  //       >
  //         View Survey
  //       </Button>
  //     </strong>
  //   );
  // };

  const columns2 = [
    {
      field: "survey_name",
      headerName: "Survey Name",
      width: 200,
    },
    {
      field: "response_id",
      headerName: "Response ID",
      width: 100,
    },
    {
      field: "contact_id",
      headerName: "Contact ID",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "contact_no",
      headerName: "Contact No",
      width: 150,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 130,
    },
    {
      field: "survey_user",
      headerName: "Survey User",
      width: 200,
    },
    {
      field: "question",
      headerName: "Question",
      width: 200,
    },
    {
      field: "answer",
      headerName: "Answer",
      width: 200,
    },
    {
      field: "note",
      headerName: "Note",
      width: 200,
    },
    {
      field: "question_type",
      headerName: "Question Type",
      width: 200,
    },
    // {
    //   field: "_value",
    //   headerName: "Value",
    //   width: 100,
    // },
    // {
    //   field: "view_survey",
    //   headerName: "View Survey",
    //   width: 200,
    //   renderCell: renderDetailsButton,
    // },
  ];

  return (
    <>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        Survey Reports
      </Typography>

      {/* <Grid container sx={{ mb: 6 }}>
        <Grid item xs={12}>
          <FeatureCard
            Icon={QueryStatsIcon}
            title="Questions Answers Results"
            link="/CDA/SurveyResults/QuestionsAnswers"
          />
        </Grid>
      </Grid> */}

      <div style={{ height: 1000 }}>
        <DataGridPremium
          rows={rows2}
          columns={columns2}
          pageSize={50}
          rowsPerPageOptions={[50]}
          components={{ Toolbar: GridToolbar }}
          checkboxSelection
          initialState={{
            sorting: {
              sortModel: [{ field: "response_id", sort: "desc" }],
            },
          }}
        />
      </div>
    </>
  );
};

export default SurveyResults;
