import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestRetake } from "../State/UsersQuestionnairesDuck";

const RequestQuestionnaireReset = ({ questionnaireId }) => {
  const dispatch = useDispatch();

  const UsersQuestionnaireDuck = useSelector(
    (state) => state.UsersQuestionnairesDuck
  );

  const { requestRetakeSuccess } = UsersQuestionnaireDuck;

  const handleRequestRetake = () => {
    dispatch(RequestRetake(questionnaireId));
  };

  return (
    <>
      {Number(requestRetakeSuccess?.id) === questionnaireId ? (
        <Button color="secondary" disabled>
          Request Sent
        </Button>
      ) : (
        <Button onClick={handleRequestRetake}>Request Retake</Button>
      )}
    </>
  );
};

export default RequestQuestionnaireReset;
