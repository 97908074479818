import { Box, Card, CardActionArea, Grid, Typography } from "@mui/material";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReportContext } from "./ReportContext";

const ReportMain = () => {
  const { pathname } = useLocation();
  const [report, setReport] = React.useState({});
  const navigate = useNavigate();

  const paths = [
    {
      name: "QA Agent level Reports",
      location: "QaAgentReport",
    },
    {
      name: "Call Center Agent level Reports",
      location: "AgentQaReport",
    },
    {
      name: "Queue Level Reports",
      location: "QueueLevelReport",
    },
  ];

  const currentPath = pathname.split("/").pop();

  return (
    <div>
      <Box sx={{ mt: 4 }}>
        <Grid container>
          {paths.map((path, index) => {
            const isActive = currentPath === path.location;

            return (
              <Grid key={index} item xs={4} p={0.3}>
                <Card
                  variant={isActive ? "outlined" : "elevation"}
                  sx={{
                    borderColor: isActive ? "primary.main" : "transparent",
                    borderWidth: isActive ? 2 : 0,
                  }}
                >
                  <CardActionArea
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      py: 3,
                    }}
                    onClick={() => navigate(path.location)}
                  >
                    <Typography>{path.name}</Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        <ReportContext.Provider value={{ report, setReport }}>
          <Outlet />
        </ReportContext.Provider>
      </Box>
    </div>
  );
};

export default ReportMain;
