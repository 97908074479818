import React, { useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Stack,
  LinearProgress,
  Button,
  ListItemText,
  Checkbox,
  Chip,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Box,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import withAuth from "../../../../../../Hoc/withAuth";
import axios from "axios";
import SaveMovedEvals from "./components/SaveMovedEvals";
import { useParams } from "react-router-dom";
import AlertPopup from "../../../../../../Components/Bits/AlertPopup";
import { fetchTeamMembers } from "../Teams/Services/api";

const MoveEvaluationsMain = ({ user }) => {
  const [selectedUser1, setSelectedUser1] = React.useState(null);
  const [selectedUser2, setSelectedUser2] = React.useState(null);
  const [leftEvaluations, setLeftEvaluations] = React.useState([]);
  const [rightEvaluations, setRightEvaluations] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
  const [searchQueryLeft, setSearchQueryLeft] = React.useState("");
  const [searchQueryRight, setSearchQueryRight] = React.useState("");
  const [duplicateAlertOpen, setDuplicateAlertOpen] = React.useState(false);

  const { id } = useParams();

  const getChipColor = (status) => {
    switch (status) {
      case "New":
        return "primary";
      case "Busy":
        return "warning";
      case "Failed":
        return "error";
      case "Completed":
        return "secondary";
      default:
        return "primary";
    }
  };

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  // Fetch users
  const { data: teamMembers = [] } = useQuery(
    ["teamMembers", id],
    () => fetchTeamMembers(id).then((res) => res?.data?.data),
    { enabled: !!id, refetchOnWindowFocus: false }
  );

  const users = [{ user_type: "All Evaluations", email: "" }, ...teamMembers];

  const allEvaluations = useQuery({
    queryKey: ["getAllEvaluations", id],
    queryFn: async () =>
      await axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Evaluations/${id}/evaluations`,
        config
      ),
    enabled: !!id,
  });

  const get2Evals = useQuery({
    queryKey: ["selectedUser2", selectedUser2, id],
    queryFn: () =>
      axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Evaluations/evaluations/user`,
        {
          ...config,
          params: {
            team_member_email: selectedUser2?.email || null,
            campaign_id: id || null,
          },
        }
      ),
    enabled: !!selectedUser2?.email && !!id,
  });

  const get1Evals = useQuery({
    queryKey: ["selectedUser1", selectedUser1, id],
    queryFn: () =>
      axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Evaluations/evaluations/user`,
        {
          ...config,
          params: {
            team_member_email: selectedUser1?.email || null,
            campaign_id: id || null,
          },
        }
      ),
    enabled: !!selectedUser1?.email && !!id,
  });

  let AllEvaluationsdata = allEvaluations?.data?.data?.data;
  let selectedUser2data = get2Evals?.data?.data?.data
  let selectedUser1data = get1Evals?.data?.data?.data

  const updateEvaluation = useMutation({
    mutationFn: (data) =>
      axios.put(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/evaluations/${id}/update`,
        data,
        config
      ),
    onSuccess: () => {},
  });

  useEffect(() => {
    if (selectedUser1data) {
      setLeftEvaluations(selectedUser1data);
    } else {
      setLeftEvaluations(AllEvaluationsdata);
    }
  }, [selectedUser1, AllEvaluationsdata, selectedUser1data]);

  useEffect(() => {
    if (selectedUser2data) {
      setRightEvaluations(selectedUser2data);
    } else {
      setRightEvaluations(AllEvaluationsdata);
    }
  }, [selectedUser2, AllEvaluationsdata, selectedUser2data]);

  if (teamMembers.isLoading) {
    return <LinearProgress />;
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRightEvaluations((prev) => prev.concat(leftEvaluations));
    setLeftEvaluations([]);
    setChecked([]);
  };

  const handleCheckedRight = () => {
    const evaluationsToMove = leftEvaluations?.filter((evaluation) =>
      checked.includes(evaluation?.id)
    );
    setRightEvaluations((prev) => prev.concat(evaluationsToMove));
    setLeftEvaluations((prev) =>
      prev.filter((evaluation) => !checked.includes(evaluation.id))
    );
    setChecked([]);
  };

  const handleCheckedLeft = () => {
    const evaluationsToMove = rightEvaluations?.filter((evaluation) =>
      checked.includes(evaluation?.id)
    );
    setLeftEvaluations((prev) => prev.concat(evaluationsToMove));
    setRightEvaluations((prev) =>
      prev.filter((evaluation) => !checked.includes(evaluation.id))
    );
    setChecked([]);
  };

  const handleAllLeft = () => {
    setLeftEvaluations((prev) => prev.concat(rightEvaluations));
    setRightEvaluations([]);
    setChecked([]);
  };

  //------------------------------------------------------------------------------

  const handleSaveMovedEvals = async () => {
    const dateNow = new Date().toISOString();

    // Update evaluations for the right side
    const updatedRightEvaluations = rightEvaluations.map((evaluation) => ({
      ...evaluation,
      team_member_id: selectedUser2?.user_id,
      history: [
        ...(evaluation.history || []),
        {
          status: "Evaluation Moved",
          createdBy: user.email,
          date: dateNow,
          start_time: null,
          end_time: null,
          reason: `From ${selectedUser1?.email} - to ${selectedUser2?.email}`,
        },
      ],
    }));

    // Update evaluations for the left side
    const updatedLeftEvaluations = leftEvaluations.map((evaluation) => ({
      ...evaluation,
      team_member_id: selectedUser1?.user_id,
      history: [
        ...(evaluation.history || []),
        {
          status: "Evaluation Moved",
          createdBy: user.email,
          date: dateNow,
          start_time: null,
          end_time: null,
          reason: `From ${selectedUser2?.email} - to ${selectedUser1?.email}`,
        },
      ],
    }));

    const mergedEvaluations = [
      ...updatedRightEvaluations,
      ...updatedLeftEvaluations,
    ];

    const uniqueEvaluations = mergedEvaluations.filter(
      (evaluation, index, self) =>
        index ===
        self.findIndex(
          (e) =>
            e.unique_id === evaluation.unique_id &&
            e.team_member_id === evaluation.team_member_id
        )
    );

    // Proceed with saving only if no duplicates
    if (uniqueEvaluations.length === mergedEvaluations.length) {
      updateEvaluation.mutate({ evaluations: uniqueEvaluations });
      setSelectedUser1(null)
      setSelectedUser2(null)
    } else {
      setDuplicateAlertOpen(true);
      setSelectedUser1(null)
      setSelectedUser2(null)
    }
  };

  const filteredLeftEvaluations = leftEvaluations?.filter((evaluation) =>
    evaluation.unique_id.toLowerCase().includes(searchQueryLeft.toLowerCase())
  );

  const filteredRightEvaluations = rightEvaluations?.filter((evaluation) =>
    evaluation.unique_id.toLowerCase().includes(searchQueryRight.toLowerCase())
  );

  const customList = (items) => (
    <div style={{ maxHeight: 500, overflow: "auto" }}>
      {items?.length > 0 ? (
        items?.map((evaluation) => (
          <Card key={evaluation?.id} sx={{ mb: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CardContent>
                <Checkbox
                  checked={checked.indexOf(evaluation.id) !== -1}
                  onChange={handleToggle(evaluation.id)}
                />
              </CardContent>
              <CardHeader
                title={`Evaluation Unique ID: ${evaluation.unique_id}`}
                titleTypographyProps={{
                  variant: "body2",
                  sx: { fontSize: "1rem" },
                }}
                subheader={`Created At: ${new Date(
                  evaluation.createdAt
                ).toLocaleDateString()}`}
                subheaderTypographyProps={{
                  variant: "body2",
                  sx: { fontSize: "0.75rem" },
                }}
              />
              <Box sx={{ flexGrow: 1 }} />
              <CardContent>
                <Chip
                  variant="outlined"
                  label={evaluation.status}
                  color={getChipColor(evaluation.status)}
                />
              </CardContent>
            </Stack>
          </Card>
        ))
      ) : (
        <Card sx={{ marginBottom: 2 }}>
          <CardContent>
            <ListItemText primary="No evaluations found." />
          </CardContent>
        </Card>
      )}
    </div>
  );

  return (
    <Stack sx={{ mt: 2 }}>
      <Typography fontSize={20}>Move Evaluations Between Users</Typography>
      <SaveMovedEvals
        onSave={handleSaveMovedEvals}
        disabled={!selectedUser1 || !selectedUser2}
      />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{ mt: 2 }}
      >
        <Stack sx={{ width: "40%" }}>
          <Autocomplete
            sx={{ mb: 1 }}
            id="user-autocomplete-1"
            fullWidth
            options={users || []}
            getOptionLabel={(option) =>
              option.user_type === "All Evaluations"
                ? "All Evaluations"
                : `${option.user_type} - ${option.email}`
            }
            onChange={(event, newValue) => {
              if (newValue?.user_type === "All Evaluations") {
                setSelectedUser1(null);
                setSelectedUser1(AllEvaluationsdata);
              } else {
                setSelectedUser1(newValue);
              }
            }}
            isOptionEqualToValue={(option, value) =>
              option.user_type === value.user_type &&
              option.email === value.email
            }
            renderInput={(params) => (
              <TextField {...params} label="Search User Email" />
            )}
            noOptionsText="No team members found"
          />
          {selectedUser1 && (
            <TextField
              label="Search Evaluations"
              value={searchQueryLeft}
              onChange={(e) => setSearchQueryLeft(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
          )}
          {selectedUser1 && customList(filteredLeftEvaluations)}
        </Stack>

        <Stack direction="column" alignItems="center" justifyContent="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={leftEvaluations?.length === 0}
            aria-label="move all right"
          >
            Move All ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={checked.length === 0}
            aria-label="move selected right"
          >
            Move Checked &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={checked.length === 0}
            aria-label="move selected left"
          >
            &lt; Move Checked
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={rightEvaluations?.length === 0}
            aria-label="move all left"
          >
            ≪ Move All
          </Button>
        </Stack>

        <Stack sx={{ width: "40%" }}>
          <Autocomplete
            sx={{ mb: 1 }}
            id="user-autocomplete-2"
            fullWidth
            options={users || []}
            getOptionLabel={(option) =>
              option.user_type === "All Evaluations"
                ? "All Evaluations"
                : `${option.user_type} - ${option.email}`
            }
            onChange={(event, newValue) => {
              if (newValue?.user_type === "All Evaluations") {
                setSelectedUser2(null);
                setSelectedUser2(AllEvaluationsdata);
              } else {
                setSelectedUser2(newValue);
              }
            }}
            isOptionEqualToValue={(option, value) =>
              option.user_type === value.user_type &&
              option.email === value.email
            }
            renderInput={(params) => (
              <TextField {...params} label="Search User Email" />
            )}
            noOptionsText="No team members found"
          />
          {selectedUser2 && (
            <TextField
              label="Search Evaluations"
              value={searchQueryRight}
              onChange={(e) => setSearchQueryRight(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
          )}
          {selectedUser2 && customList(filteredRightEvaluations)}
        </Stack>
      </Stack>

      <AlertPopup
        open={updateEvaluation.isSuccess}
        severity="success"
        message="Moved Evaluations successfully"
      />
      <AlertPopup
        open={updateEvaluation.isError}
        severity="error"
        message="Failed to Move Evaluations"
      />
      <AlertPopup
        open={duplicateAlertOpen}
        severity="error"
        message="Duplicate evaluations found, not saving"
        onClose={() => setDuplicateAlertOpen(false)}
      />
    </Stack>
  );
};

export default withAuth(MoveEvaluationsMain);
