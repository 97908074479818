import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import PageHeader from "../../../../Components/Bits/PageHeader";
import withAuth from "../../../../Hoc/withAuth";
import PlatFormDropDown from "./PlatFormDropDown";

import OneStream from "./OneStream";
import TaskFlow from "./TaskFlow";
import NodeSalesOrders from "./NodeSalesOrders";

const SaleOrderManagementHome = () => {
  const [platform, setPlatform] = useState("");

  const handlePlatFormChange = (event) => setPlatform(event.target.value);

  return (
    <>
      <PageHeader
        title="Sale Order Management Home"
        subheader={`${platform} sale orders list.`}
        breadcrumbs={[
          {
            title: "Home",
            href: "/",
          },
        ]}
      />

      <Grid>
        <PlatFormDropDown
          platform={platform}
          handlePlatFormChange={handlePlatFormChange}
        />
        {platform && platform === "one_stream" ? (
          <OneStream platform={"one_stream"} />
        ) : platform === "taskflow" ? (
          <TaskFlow platform={"taskflow"} />
        ) : platform === "node" ? (
          <NodeSalesOrders platform={"node"} />
        ) : (
          <PlatformSelect />
        )}
      </Grid>
      <Outlet />
    </>
  );
};

export default withAuth(SaleOrderManagementHome);

function PlatformSelect() {
  return (
    <div>
      <Typography variant="h6" sx={{ mt: 2 }}>
        Please select a platform to begin...
      </Typography>
    </div>
  );
}
