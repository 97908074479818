import { Alert, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddUserRequest,
  EditUserRequest,
  getUsersOfModule,
} from "../../../Redux/Ducks/AdminUserManagement";

import { Formik, Form } from "formik";
import * as yup from "yup";
import TextfieldWrapper from "../../../Components/FormComponents/TextfieldWrapper";
import AlertPopup from "../../../Components/Bits/AlertPopup";
import SelectWrapper from "../../../Components/FormComponents/Select";

import { getAllDepartments } from "../../../Redux/Payspace/Actions/managerActions";

const AddUserForm = ({ User }) => {
  const AdminUsers = useSelector((state) => state.AdminUsers);
  const { addUserError, addUserLoading, usersOfModule, modulesLoading } =
    AdminUsers;

  const payspace = useSelector((state) => state.payspace);

  const { departments } = payspace?.department;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDepartments());
    dispatch(getUsersOfModule("call-center-manager"));
  }, [dispatch]);

  if (modulesLoading || !usersOfModule)
    return <Alert severity="info">Loading...</Alert>;

  const Managers =
    usersOfModule &&
    usersOfModule[0]?.Modules_users?.map((user) => {
      return user.User;
    });

  return (
    <>
      <AlertPopup open={addUserError} severity="error">
        Add User Error
      </AlertPopup>

      <Formik
        initialValues={{
          name: User?.name || "",
          surname: User?.surname || "",
          email: User?.email || "",
          department: User?.department || "",
          contact_number: User?.contact_number || "",
          manager: User?.manager || "",
          employee_no: User?.employee_no || "",
          id_number: User?.id_number || "",
        }}
        validationSchema={yup.object({
          name: yup.string().required("Name is required"),
          surname: yup.string().required("Surname is required"),
          email: yup
            .string()
            .email("Invalid email")
            .required("Email is required"),
          contact_number: yup
            .string()
            .min(9, "Too Short")
            .max(11, "Too Long")
            .required("Contact number is required"),
          manager: yup.string().required("Manager is required"),
          id_number: yup.string().required("ID number is required"),
        })}
        onSubmit={(values) => {
          if (User?.id) {
            dispatch(EditUserRequest({ id: User?.id, values }));
          } else {
            dispatch(AddUserRequest(values));
          }
          // dispatch()
        }}>
        <Form>
          <Stack spacing={2} sx={{ my: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextfieldWrapper name="name" label="Name" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextfieldWrapper name="surname" label="Surname" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextfieldWrapper name="email" label="Email" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextfieldWrapper
                  name="contact_number"
                  label="Contact Number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {departments && departments && departments?.length > 0 && (
                  <SelectWrapper
                    name="department"
                    label="Department"
                    options={departments?.map((dep) => {
                      return {
                        value: dep.id,
                        label: dep.department_name,
                      };
                    })}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {Managers && Managers.length > 0 && (
                  <SelectWrapper
                    name="manager"
                    label="Manager"
                    options={Managers?.map((manager) => {
                      return {
                        value: manager.id,
                        label: `${manager.name} ${manager.surname}`,
                      };
                    })}
                  />
                )}

                <Typography variant="caption" color="GrayText">
                  managers are users with the module of call-center-manager
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextfieldWrapper name="employee_no" label="Employee Number" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextfieldWrapper name="id_number" label="ID Number" />
              </Grid>
            </Grid>
            {addUserLoading && <Alert severity="info">Loading...</Alert>}
            <Button type="submit" variant="contained" color="primary">
              Add User
            </Button>
          </Stack>
        </Form>
      </Formik>
    </>

    // <MenuItem value="Directors">Directors</MenuItem>
    // <MenuItem value="Finance">Finance</MenuItem>
    // <MenuItem value="Human Resources">Human Resources</MenuItem>
    // <MenuItem value="Information Systems">Information Systems</MenuItem>
    // <MenuItem value="Marketing">Marketing</MenuItem>
    // <MenuItem value="Operations">Operations</MenuItem>
    // <MenuItem value="Production">Production</MenuItem>
    // <MenuItem value="Outbound Call Centre">Outbound Call Centre</MenuItem>
    // <MenuItem value="Call Centre Service">Call Centre Service</MenuItem>
    // <MenuItem value="Product Development">Product Development</MenuItem>
    // <MenuItem value="Quality & Compliance">Quality & Compliance</MenuItem>
    // <MenuItem value="Sales">Sales</MenuItem>
  );
};

export default AddUserForm;
