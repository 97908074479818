import React, { useState, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Button, LinearProgress, Alert, Stack } from "@mui/material";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import withAuth from "../../../../Hoc/withAuth";
import ListSaleOrders from "./ListSaleOrders";

const OneStream = ({ platform, user }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const [page, setPage] = useState(0);

  const [term, setTerm] = useState("");

  const oneStreamSalesOrders = useQuery(
    [`getOneStreamSaleOrders`, page, term],
    () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/callCentre/one_stream/sales_orders?pageNumber=${page}&limit=1000&term=${term}`,
        config
      );
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: oneStream, isLoading, error, isError } = oneStreamSalesOrders;

  const goToPage = (page) => () => setPage(page);

  const nextPageHandler = goToPage(oneStream?.data?.data?.nextPage);
  const previousPageHandler = goToPage(oneStream?.data?.data?.previousPage);

  const handleSearch = (value) => {
    setTerm(value);
  };

  if (isLoading) {
    return <LinearProgress sx={{ mt: 2 }} />;
  }

  if (isError) {
    return (
      <Alert severity="error" sx={{ my: 2 }}>
        Error: {error?.response?.data?.message}{" "}
      </Alert>
    );
  }

  return (
    <>
      <Stack>
        <Stack sx={{ my: 2 }} direction="row" spacing={2}>
          <Button value={page} onClick={previousPageHandler}>
            Previous
          </Button>
          <Button value={page} onClick={nextPageHandler}>
            Next
          </Button>
        </Stack>
        <Suspense fallback={<FetchingData />}>
          <ListSaleOrders
            handleSearch={handleSearch}
            platform={platform}
            filteredRows={oneStream?.data?.data?.rows}
            isLoading={isLoading}
          />
        </Suspense>
      </Stack>
      <Outlet />
    </>
  );
};

export default withAuth(OneStream);

function FetchingData() {
  return <div>Fetching Sales Orders...</div>;
}
