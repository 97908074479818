import { Typography } from "@mui/material";
import React from "react";

import MainAppContainer from "../../../../../Components/Containers/MainAppContainer";

import withAuth from "../../../../../Hoc/withAuth";
import UserSurveyList from "./Components/SurveyUserComponents/UserSurveyList";

const SurveyUsers = () => {
  return (
    <MainAppContainer>
      <Typography gutterBottom align="center" variant="h4">
        Users Surveys
      </Typography>
      <UserSurveyList />
    </MainAppContainer>
  );
};

export default withAuth(SurveyUsers);
