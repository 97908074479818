import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styled from "@emotion/styled";
import { Chip, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LinkDetailToDisposition from "./LinkDetailToDisposition";
import { RESET_DISPOSITIONS } from "../../State/DispostionsDuck";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";
import DeleteDisposition from "./DeleteDisposition";
import ActivateDisposition from "./ActiveDisposition";
import ActivateOrDisableDispositionDetail from "./ActivateOrDisableDispositionDetail";

export default function DispositionsList({
  dispositions,
  activeDispositionDetails,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: RESET_DISPOSITIONS });
  }, [dispatch]);

  const Dispositions = useSelector((state) => state.Dispositions);

  const { addedDisposition, detailAdded, detailRemoved } = Dispositions;

  return (
    <>
      {" "}
      <AlertPopup
        open={addedDisposition}
        message="Disposition added"
        severity="info"
      />
      <AlertPopup
        open={detailAdded}
        message="Disposition Detail added"
        severity="success"
      />
      <AlertPopup
        open={detailRemoved}
        message="Disposition Detail Removed"
        severity="info"
      />
      <TableContainer
        sx={{
          borderWidth: 1,
          borderColor: "primary.main",
        }}
        component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>Disposition</StyledTableCell>
              <StyledTableCell align="right">_type</StyledTableCell>
              <StyledTableCell align="right">Remove/Activate</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dispositions
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((row) => (
                <Row
                  key={row.name}
                  row={row}
                  activeDispositionDetails={activeDispositionDetails}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function Row(props) {
  const { row, activeDispositionDetails } = props;
  const [open, setOpen] = React.useState(false);

  // filter used Disposition Details from activeDispositionDetails

  let activeDetails = row?.gl_disposition_details?.filter(
    (detail) => detail.active === true
  );

  let inactiveDetails = row?.gl_disposition_details?.filter(
    (detail) => detail.active === false
  );

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.type}</TableCell>
        <TableCell align="right">
          {row.active ? (
            <DeleteDisposition data={row} />
          ) : (
            <ActivateDisposition data={row} />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell
          sx={{ backgroundColor: "background.default" }}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ my: 1 }}>
                <Typography variant="body2" gutterBottom component="div">
                  Disposition Options
                </Typography>
                <LinkDetailToDisposition
                  disposition={row}
                  disposition_details={activeDispositionDetails}
                />
              </Stack>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Is Active</StyledTableCell>
                    <StyledTableCell align="right">Remove</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeDetails?.map((detail) => {
                    return (
                      <TableRow key={detail?.id}>
                        <StyledTableCell component="th" scope="row">
                          {detail?.name}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <Chip
                            label={detail?.active ? "Active" : "Disabled"}
                            variant="outlined"
                            color={detail?.active ? "default" : "warning"}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          component="th"
                          scope="row">
                          <ActivateOrDisableDispositionDetail
                            disposition_id={row.id}
                            data={detail}
                          />
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                  {inactiveDetails?.map((detail) => {
                    return (
                      <TableRow key={detail?.id}>
                        <StyledTableCell component="th" scope="row">
                          {detail?.name}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <Chip
                            label={detail?.active ? "Active" : "Disabled"}
                            variant="outlined"
                            color={detail?.active ? "default" : "warning"}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          component="th"
                          scope="row">
                          <ActivateOrDisableDispositionDetail
                            disposition_id={row.id}
                            data={detail}
                          />
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
