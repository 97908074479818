import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";
import AddCallRecording from "./AddCallRecording";
import ListScoreCardRecordings from "./ListScoreCardRecordings";
import SectionComponent from "./SectionComponent";

const ScoreCardForm = ({ user, id, values, scores, disabled }) => {
  const {
    introduction_skills,
    presentation_skills,
    use_of_questions,
    objection_handling,
    closing_skills,
    sales_skill_score,
    total_score,
  } = scores;

  return (
    <>
      {" "}
      <Card>
        <CardHeader title="Call Recordings" />
        <CardContent>
          <AddCallRecording user={user} id={id} />
          <ListScoreCardRecordings user={user} id={id} />
        </CardContent>
      </Card>
      {/* Section 1 */}
      <Card sx={{ my: 2 }}>
        <CardHeader title="1. Introduction Skills: connection & first impression" />
        <CardContent>
          <SectionComponent
            disabled={disabled}
            data={values?.questions?.section1}
            section="section1"
            sectionTitle="Introduction Skills"
            sectionTotal={introduction_skills}
          />
        </CardContent>
      </Card>
      {/* Section 2 */}
      <Card>
        <CardHeader title="2. Presentation Skills: whats in it for me? WIIFM" />
        <CardContent>
          <SectionComponent
            disabled={disabled}
            data={values?.questions?.section2}
            section="section2"
            sectionTitle="Presentation Skills"
            sectionTotal={presentation_skills}
          />
        </CardContent>
      </Card>
      {/* Section 3 */}
      <Card sx={{ my: 2 }}>
        <CardHeader title="3. Selling Skills: always be closing" />
        <CardContent>
          <Typography gutterBottom variant="h6" color="text.secondary">
            Use of Questions
          </Typography>
          <SectionComponent
            disabled={disabled}
            data={values?.questions?.section3}
            section="section3"
            sectionTitle={"Use of Questions"}
            sectionTotal={use_of_questions}
          />

          <Typography gutterBottom variant="h6" color="text.secondary">
            Overcoming Objections
          </Typography>
          <SectionComponent
            disabled={disabled}
            data={values?.questions?.objection_handling}
            section="objection_handling"
            sectionTitle={"Overcoming Objections"}
            sectionTotal={objection_handling}
          />

          <Typography gutterBottom variant="h6" color="text.secondary">
            Closing
          </Typography>
          <SectionComponent
            disabled={disabled}
            data={values?.questions?.closing_skills}
            section="closing_skills"
            sectionTitle={"Closing"}
            sectionTotal={closing_skills}
          />

          <Card variant="outlined" sx={{ mt: 3 }}>
            <CardContent>
              <Typography variant="h6">
                {" "}
                Sales Skill Total : {sales_skill_score} %
              </Typography>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      <Card sx={{ mt: 3 }}>
        <CardHeader title={`Grand Total ${total_score} %`} />
      </Card>
      <Card sx={{ mt: 3 }}>
        <CardHeader title="Coaching Action Plan - Grow" />
        <CardContent>
          <SectionComponent
            disabled={disabled}
            data={values?.questions?.section6}
            section="section6"
          />
        </CardContent>
      </Card>
    </>
  );
};

export default ScoreCardForm;
