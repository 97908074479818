import React, { useEffect, useState } from "react";
import MainAppContainer from "../../../Components/Containers/MainAppContainer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetSingleUser } from "../../../Redux/Ducks/AdminUserManagement";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import AssignCompanyModules from "../components/AssignCompanyModules";
import UserForm from "../components/UserForm";
import { Box } from "@mui/system";

import AddUserCompany from "../components/AddUserCompany";
import UserTypeDialog from "../components/UserTypeModule";
import UserTypeChips from "../../../Components/Bits/UserTypeChips";
import axios from "axios";
import { getCompanies } from "../../../Redux/Ducks/AdminCompniesDuck";
import cdaLogo from "../../../assets/CDASVG.png";

const EditUser = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanies());
    dispatch(GetSingleUser(id));
  }, [id, dispatch]);

  const AdminUsers = useSelector((state) => state.AdminUsers);

  const { User, loading, success, error } = AdminUsers;

  let company = User?.companies_users?.find((company) => company.company_id);

  const [userCompany, setUserSelectedCompany] = useState("");

  let userType = User?.user_type?.type ? true : false;

  const [loadingReset, setLoadingReset] = useState(false);

  const [response, setResponse] = useState("");

  const [resetError, setError] = useState("");

  const sendPasswordReset = async (event) => {
    try {
      setLoadingReset(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/forgottenPassword`,
        { email: User.email },
      );
      setResponse(data.message);
      setLoadingReset(false);
    } catch (error) {
      setLoadingReset(false);
      setError(error);
    }
  };

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <MainAppContainer>
      <Typography sx={{ mt: 4 }} variant="h4" align="center">
        Edit User
      </Typography>
      <Stack direction="row" mt={2} justifyContent="space-evenly">
        {loadingReset ? (
          <CircularProgress />
        ) : (
          <Button onClick={sendPasswordReset}>Send Password Reset</Button>
        )}
        {resetError && (
          <Alert severity="error">{JSON.stringify(resetError, null, 2)}</Alert>
        )}
        {response && (
          <Alert severity="success">{JSON.stringify(response, null, 2)}</Alert>
        )}
      </Stack>

      <Stack spacing={2}>
        <Box>
          {company ? (
            <Card sx={{ p: 3, maxWidth: 345 }}>
              {company?.company?.name === "CDA Solutions" && (
                <CardMedia component="img" image={cdaLogo} />
              )}
              <CardHeader
                title={company?.company?.name}
                subheader="Company Assigned to User"
              />
            </Card>
          ) : (
            <Stack>
              <Alert severity="error">No company assigned to user</Alert>
              <AddUserCompany
                setUserSelectedCompany={setUserSelectedCompany}
                id={User?.id}
              />
            </Stack>
          )}
        </Box>

        <Stack>
          {User?.user_type?.type ? (
            <Stack direction="row" alignContent="center" spacing={2}>
              <Typography>User Type:</Typography>
              <Box>
                <UserTypeChips user={User} />
              </Box>
              <UserTypeDialog edit={userType} />
            </Stack>
          ) : (
            <>
              <Alert severity="error">No user type</Alert>
              <UserTypeDialog edit={userType} />
            </>
          )}
        </Stack>
      </Stack>

      {company && (
        <Card sx={{ mt: 4 }}>
          <CardHeader
            title="Assign Modules"
            subheader="User will only have access to the modules assigned to their company"
          />

          <CardContent>
            <AssignCompanyModules
              User={User}
              id={company?.company_id || userCompany}
              user_id={id}
            />
          </CardContent>
        </Card>
      )}

      <Card sx={{ mt: 2 }}>
        <CardContent>
          <UserForm
            id={id}
            User={User}
            success={success}
            error={error}
            loading={loading}
          />
        </CardContent>
      </Card>
    </MainAppContainer>
  );
};

export default EditUser;
