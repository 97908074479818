import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";
import DeleteDispositionDetail from "./DeleteDispositionDetail";
import ActivateDispositionDetail from "./ActivateDispositionDetail";
import { useDispatch } from "react-redux";
import { RESET_DISPOSITIONS } from "../../State/DispostionsDuck";

const DispositionDetailsList = ({ active }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: RESET_DISPOSITIONS });
  }, [dispatch]);

  const Dispositions = useSelector((state) => state.Dispositions);

  const { detailsDeleteSuccess, addedDispositionOption, disposition_details } =
    Dispositions;

  let activeDispositionDetails = disposition_details.filter(
    (disposition) => disposition.active === active
  );

  return (
    <>
      <TableContainer
        sx={{
          borderWidth: 1,
          borderColor: "secondary.main",
        }}
        component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>

              <StyledTableCell align="right">Remove/Activate</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeDispositionDetails?.map((row) => {
              console.log(row);
              return (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    {row.active === true ? (
                      <DeleteDispositionDetail data={row} />
                    ) : (
                      <ActivateDispositionDetail data={row} />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertPopup
        open={addedDispositionOption}
        message="Disposition Option Added"
        severity="success"
      />
      <AlertPopup
        open={detailsDeleteSuccess}
        message="Disposition Option Deleted"
        severity="warning"
      />
    </>
  );
};

export default DispositionDetailsList;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
