import { Typography } from "@mui/material";
import React from "react";
import MainAppContainer from "../../Components/Containers/MainAppContainer";
import withAuth from "../../Hoc/withAuth";
import RmaDashBoard from "./RmaDashBoard";
import AdminDash from "./AdminDash";
import { Box } from "@mui/system";

import CDADashboard from "./CDADashboard";
import WelcomeCard from "./Components/WelcomeCard";

const DashBoard = ({ user, companyDetails }) => {
  if (user.userType === "admin") {
    return <AdminDash />;
  }

  const checkCompany = () => {
    if (!companyDetails) {
      return (
        <Box>
          <Typography variant="h6" align="center">
            You are not a part of any company.
          </Typography>
          <Typography variant="body1" align="center">
            Please request to be added to a company from you Admin.
          </Typography>
        </Box>
      );
    }
  };

  return (
    <MainAppContainer>
      <WelcomeCard user={user} companyDetails={companyDetails} />
      {checkCompany()}
      <CDADashboard />
      <RmaDashBoard />
    </MainAppContainer>
  );
};

export default withAuth(DashBoard);
