import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik, Field } from "formik";
import {
  Autocomplete,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
} from "@mui/material";
import AlertPopup from "../../../../../../../Components/Bits/AlertPopup";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { fetchTeamMembers } from "../../Teams/Services/api";
import withAuth from "../../../../../../../Hoc/withAuth";
import { useParams } from "react-router-dom";

const CreateEvaluations = ({ user }) => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();

  //get active team members
  const { data: teamMembers = [] } = useQuery(
    ["teamMembers", id],
    () =>
      fetchTeamMembers(id).then((res) => {
        return res.data.data;
      }),
    { enabled: !!id }
  );

  const Teammembers = teamMembers;

  //get node users
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const allUsers = useQuery({
    queryKey: ["all Users"],
    queryFn: () =>
      axios.get(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/teammembers/inboundAllUsers`,
        config
      ),
  });

  const users = allUsers?.data?.data.data;

  //create evaluation
  const createEvaluation = useMutation({
    mutationFn: async (data) =>
      await axios.post(
        `${process.env.REACT_APP_API_URL}/QAInbound/admin/Evaluations/${id}/evaluations`,
        data,
        config,
        config
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["getAllEvaluations"]);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create Evaluation
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create QA Evaluation</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              call_center_agent: null,
              unique_id: "",
              disposition: "",
              team_member_id: null,
              team_member_type: "",
              evaluation_type: "",
              status: "New",
              campaign_id: id,
            }}
            onSubmit={(values) => {
              const updatedValues = {
                ...values,
                call_center_agent: values.call_center_agent
                  ? values.call_center_agent.id
                  : null,
                team_member_id: values.team_member_id
                  ? values.team_member_id.user_id
                  : null,
              };

              console.log(updatedValues);
              createEvaluation.mutate(updatedValues);
              handleClose();
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <Stack sx={{ py: 2 }} spacing={2}>
                  <Field name="call_center_agent">
                    {({ field }) => (
                      <Autocomplete
                        {...field}
                        options={users || []}
                        getOptionLabel={(option) =>
                          `${option.name} - ${option.surname} - ${option.email}`
                        }
                        onChange={(event, newValue) =>
                          setFieldValue("call_center_agent", newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Call Center Agent"
                            fullWidth
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Field name="unique_id">
                    {({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Unique ID"
                        margin="normal"
                      />
                    )}
                  </Field>

                  <Field name="disposition">
                    {({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Disposition"
                        margin="normal"
                      />
                    )}
                  </Field>

                  <Field name="team_member_id">
                    {({ field }) => (
                      <Autocomplete
                        {...field}
                        options={Teammembers || []}
                        getOptionLabel={(option) =>
                          `${option.user_type} - ${option.email}`
                        }
                        onChange={(event, newValue) => {
                          setFieldValue("team_member_id", newValue);
                          if (newValue) {
                            setFieldValue(
                              "team_member_type",
                              newValue.user_type
                            );
                          } else {
                            setFieldValue("team_member_type", "");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Team Member"
                            fullWidth
                          />
                        )}
                      />
                    )}
                  </Field>

                  <FormControl fullWidth margin="normal">
                    <InputLabel>Evaluation Type</InputLabel>
                    <Field
                      as={Select}
                      name="evaluation_type"
                      label="Evaluation Type"
                    >
                      <MenuItem value="Inbound Call">Inbound Call</MenuItem>
                      <MenuItem value="Outbound Call">Outbound Call</MenuItem>
                    </Field>
                  </FormControl>

                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="secondary" type="submit">
                      Submit
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <AlertPopup
        open={createEvaluation.isError}
        severity="error"
        message="Error creating Evaluation"
      />
      <AlertPopup
        open={createEvaluation.isSuccess}
        severity="success"
        message="Evaluation created successfully"
      />
    </React.Fragment>
  );
};

export default withAuth(CreateEvaluations);
