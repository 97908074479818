import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DialogContent, Stack } from "@mui/material";

import { useDispatch } from "react-redux";
import { linkDispositionDetails } from "../../State/DispostionsDuck";
import SelectWrapper from "../../../../../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../../../../../Components/FormComponents/TextfieldWrapper";

export default function LinkDetailToDisposition({
  disposition,
  disposition_details,
}) {
  const newDis = disposition_details?.filter(
    (dispositionDetail) =>
      disposition?.gl_disposition_details
        ?.map((dis) => dis.id)
        ?.indexOf(dispositionDetail?.id) === -1
  );

  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="secondary" onClick={handleClickOpen}>
        Add Detail to Disposition
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle> Add New Detail to Disposition</DialogTitle>
        <Formik
          initialValues={{
            disposition_id: disposition.id || "",
            name: "",
            active: true,
            update_reason: "Created new disposition detail",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Required"),
          })}
          onSubmit={(values) => {
            console.log(values);
            dispatch(linkDispositionDetails(values));
            handleClose();
          }}>
          {({ values }) => {
            return (
              <Form>
                <DialogContent>
                  <Stack spacing={2}>
                    <SelectWrapper
                      name="name"
                      label="Select Existing Disposition Detail"
                      options={newDis.map((detail) => {
                        return {
                          value: detail.name,
                          label: detail.name,
                        };
                      })}
                    />
                    <TextfieldWrapper
                      name="name"
                      label="Create new Disposition Detail"
                      type="text"
                    />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
}
