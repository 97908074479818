import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ModuleItem = ({ Icon, link, title }) => {
  const navigate = useNavigate();

  return (
    <ListItem
      selected={window.location.pathname === { link }}
      button
      onClick={() => navigate(`${link}`)}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText>
        <Typography>{title}</Typography>
      </ListItemText>
    </ListItem>
  );
};

export default ModuleItem;
