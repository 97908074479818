import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";

import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  ListItemText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { addSurveyUser } from "../../State/SurveysDuck";

export default function AddUsersToSurvey({ users, survey_id }) {
  const [open, setOpen] = React.useState(false);
  const [selectedLanguages, setSelectedLanguages] = React.useState([]);
  const [selectUser, setSelectUser] = React.useState([]);

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const handleChangeUser = (event) => {
    setSelectUser(event.target.value);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLanguages(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      user_id: selectUser,
      survey_campaign_id: survey_id,
      languages: selectedLanguages,
    });
    let values = {
      user_id: selectUser,
      survey_campaign_id: survey_id,
      languages: selectedLanguages,
    };

    dispatch(addSurveyUser(values));
    handleClose();
  };

  return (
    <div>
      <Button variant="outlined" fullWidth onClick={handleClickOpen}>
        Add Users To Survey
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle> Add Users To Survey</DialogTitle>
        <>
          <form onSubmit={handleSubmit}>
            <Stack sx={{ p: 3 }}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="select_user">Select User</InputLabel>
                <Select
                  labelId="select_user"
                  id="user_select"
                  label="Select User"
                  name="userId"
                  value={selectUser}
                  onChange={handleChangeUser}>
                  {users?.map((user) => (
                    <MenuItem key={user?.user_id} value={user?.user_id}>
                      {user?.User?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel id="select_language">Select Languages</InputLabel>
                <Select
                  labelId="select_language"
                  id="selected_language"
                  label="Select Languages"
                  multiple
                  value={selectedLanguages}
                  onChange={handleChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}>
                  {languagesList.map((name) => {
                    return (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={selectedLanguages.indexOf(name) > -1}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
            <Stack>
              <Button type="submit" variant="contained">
                Save
              </Button>
              <Button color="inherit" onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </form>
        </>
      </Dialog>
    </div>
  );
}

const languagesList = ["english", "afrikaans", "xhosa", "zulu"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
