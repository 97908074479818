import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSale } from "../State/SalesAgentCampaignsDuck";
import SelectWrapper from "../../../../../../Components/FormComponents/Select";

import DateSelectorWrapper from "../../../../../../Components/FormComponents/DateSelectorWrapper";

export default function EditSale({ sale, user }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Campaigns = useSelector((state) => state.Campaigns);

  const { availableDispositions: disList } = Campaigns;

  let { cc_disposition_lists } = disList;

  // Get Dispositions out of list

  const availableDispositions = [];

  cc_disposition_lists &&
    cc_disposition_lists?.forEach((cc_disposition_lists) => {
      cc_disposition_lists?.cc_dispositions.forEach((disposition) => {
        if (disposition && disposition.active === true) {
          return availableDispositions.push(disposition);
        }
      });
    });

  const findDisposition = (id) => {
    return availableDispositions?.find((disposition) => disposition?.id === id);
  };

  return (
    <div>
      <Button color="warning" fullWidth onClick={handleClickOpen}>
        EDIT SALE
      </Button>
      <Dialog
        sx={{
          borderColor: "warning.main",
          borderWidth: 2,
          borderStyle: "solid",
        }}
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}>
        <DialogTitle>Update Sale </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              id: sale.id || "",
              lead_id: sale.lead_id || "",
              reference_no: sale.reference_no || "",
              premium: sale.premium,
              debit_date: sale.debit_date || "",
              note1: sale.note1 || "",
              note2: sale.note2 || "",
              agent_id: user.id || "",
              status: sale.status || "",
              disposition_id:
                sale?.cm_sales_dispositions[0]?.disposition_id || "",
              disposition_detail_id:
                sale?.cm_sales_dispositions[0]?.disposition_detail_id || "",
              updated_reason:
                user.userType === ("admin" || "super_admin") ? "" : "N/A",
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              premium: Yup.number().required("Premium is required"),
              agent_id: Yup.string().required("Agent ID is required"),
              updated_reason: Yup.string().required("Reason is required"),
              disposition_id: Yup.string().required("Disposition is required"),
            })}
            onSubmit={(values) => {
              dispatch(UpdateSale({ values }));

              handleClose();
            }}>
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <Stack spacing={2} sx={{ my: 2 }}>
                    <TextfieldWrapper
                      name="premium"
                      label="Premium"
                      type="number"
                      placeholder="Premium"
                    />
                    <SelectDisposition
                      availableDispositions={availableDispositions}
                    />

                    {findDisposition(values?.disposition_id)
                      ?.cc_disposition_details &&
                      findDisposition(values?.disposition_id)
                        ?.cc_disposition_details?.length > 0 && (
                        <SelectDispositionDetail
                          availableDispositionsDetails={
                            findDisposition(values?.disposition_id)
                              ?.cc_disposition_details
                          }
                        />
                      )}

                    <TextfieldWrapper
                      name="reference_no"
                      label="Reference No"
                      type="text"
                      placeholder="reference_no"
                    />

                    <DateSelectorWrapper name="debit_date" label="Debit Date" />
                    <TextfieldWrapper
                      name="note1"
                      label="Note 1"
                      multiline
                      type="text"
                      placeholder="Note 1"
                    />
                    <TextfieldWrapper
                      name="note2"
                      label="Note 2"
                      multiline
                      type="text"
                      placeholder="Note 2"
                    />

                    {user.userType === ("admin" || "super_admin") && (
                      <>
                        {" "}
                        <TextfieldWrapper
                          name="updated_reason"
                          label="Updated Reason"
                          helperText="Reason for updating the sale"
                          multiline
                          type="text"
                          placeholder="Please Provide a reason for updating the sale"
                        />
                        <SelectWrapper
                          name="status"
                          label="Status"
                          options={[
                            { value: "new", label: "new" },
                            { value: "staging", label: "Staging" },
                            { value: "pending", label: "Pending" },
                            { value: "completed", label: "Completed" },
                            { value: "cancelled", label: "Cancelled" },
                          ]}
                        />
                      </>
                    )}

                    <DialogActions>
                      <Button
                        variant="outlined"
                        color="warning"
                        onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button
                        disabled={
                          values.disposition_detail_id === "" &&
                          findDisposition(values?.disposition_id)
                            ?.cc_disposition_details?.length > 0
                        }
                        color="primary"
                        variant="contained"
                        type="submit">
                        Save
                      </Button>
                    </DialogActions>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const SelectDisposition = ({ availableDispositions }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const handleChange = (e) => {
    let id = e.target.value;

    setFieldValue("disposition_detail_id", "");
    setFieldValue("disposition_id", id);
  };

  return (
    <FormControl error={errors["disposition_id"] ? true : false} fullWidth>
      <InputLabel id="disposition_label_id">Disposition</InputLabel>
      <Select
        labelId="disposition_label_id"
        id="disposition_id"
        value={values["disposition_id"]}
        label="Disposition"
        onChange={handleChange}>
        {availableDispositions.map((disposition) => (
          <MenuItem key={disposition.id} value={disposition.id}>
            {disposition.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const SelectDispositionDetail = ({ availableDispositionsDetails }) => {
  const { values, setFieldValue, setErrors, errors } = useFormikContext();

  const [dispositionDetail, setDispositionDetail] = React.useState(
    values["disposition_detail_id"]
  );

  React.useEffect(() => {
    if (!dispositionDetail && availableDispositionsDetails.length > 0) {
      setErrors({
        ...errors,
        disposition_detail_id: "Disposition Detail is required",
      });
    }
  }, [setErrors, errors, dispositionDetail, availableDispositionsDetails]);

  const handleChange = (e) => {
    let id = e.target.value;
    setDispositionDetail(id);

    setFieldValue("disposition_detail_id", id);
  };

  return (
    <FormControl
      error={errors["disposition_detail_id"] ? true : false}
      fullWidth>
      <InputLabel id="disposition_detail_id_label">
        disposition_detail_id
      </InputLabel>
      <Select
        labelId="disposition_detail_id_label"
        id="disposition_detail_id"
        value={dispositionDetail}
        label="disposition_detail_id"
        onChange={handleChange}>
        {availableDispositionsDetails.map((disposition) => (
          <MenuItem key={disposition.id} value={disposition.id}>
            {disposition.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
