import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { useDispatch } from "react-redux";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { editQuestionnaireQuestion } from "../State/QuestionnairsDuck";

export default function EditQuestion({ question }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const [values, setValues] = React.useState({
    question: "",
    question_type: "",
  });

  useEffect(() => {
    setValues({
      question: question?.question,
      question_type: question?.question_type,
    });
  }, [question]);

  const formik = useFormik({
    initialValues: values,
    validationSchema: Yup.object({
      question: Yup.string().required("A Question is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      dispatch(editQuestionnaireQuestion(question.id, values));
      setTimeout(() => {
        handleClose();
      }, 500);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
    <div>
      <Tooltip title={"Edit Question"}>
        <IconButton onClick={handleClickOpen} color="secondary" edge="end">
          <EditTwoToneIcon />
        </IconButton>
      </Tooltip>

      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <>
            <Stack sx={{ p: 4 }}>
              <Typography variant="h6" align="center" my={1}>
                Edit Question
              </Typography>

              <TextField
                multiline
                rows={4}
                margin="normal"
                label="Question"
                name="question"
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.question && formik.errors.question
                    ? true
                    : false
                }
                helperText={formik.touched.question && formik.errors.question}
              />
              {/* 
              <FormControl fullWidth>
                <InputLabel id="select_type">Select Question Type</InputLabel>
                <Select
                  labelId="select_type"
                  id="select_question_type"
                  label="Select Question Type"
                  name="question_type"
                  value={formik.values.question_type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.question_type && formik.errors.question_type
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched.question_type && formik.errors.question_type
                  }>
                  <MenuItem value="radio"> Multi Select</MenuItem>
                  <MenuItem value="textfield">Text Input</MenuItem>
                </Select>
              </FormControl> */}
            </Stack>
            <Stack>
              <Button sx={{ mt: 4 }} type="submit" variant="contained">
                Save
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </Stack>
          </>
        </form>
      </Dialog>
    </div>
  );
}
